import { InstructionsContent } from '../../../../subject/testRunner/screens/basic-views/test-instructions-view';
import { ControlTransition } from '../../../../subject/testRunner/controls/control-transition';
import { IatScreenData } from './iat-screen-data';

export class IatInstructionsData extends IatScreenData {
  content: InstructionsContent;

  constructor(
    content: InstructionsContent,
    public readonly scope = 'test' as 'test' | 'block',
    controlTransitions?: ControlTransition<any, any>[],
  ) {
    super(controlTransitions);
    this.content = content;
  }
}
