import React, { FC } from "react";
import { Podta } from "../types";
import { useScenesCache } from "../../PODT/creator/steps/scenes/scenesCache.hook";
import { Box, LinearProgress, List, ListItem, Stack, Typography } from "@mui/material";
import S3Image from "../../../../components/S3Image";
import { ImageInstanceInput } from "../../../../API";
import PodtaSceneBoxEditor from "../creator/steps/PodtaSceneBoxEditor";

export const PodtaSceneSettingsView: FC<{test: Podta}> = ({test}) => {

  const scenes = test.scenes.scenes;

  const scenesCache = useScenesCache(scenes);

  const [activeIndex, setActiveIndex] = React.useState(0);

  const currentScene = scenes[activeIndex];

  return (scenesCache
      ? (
        <Stack spacing={1}>
          <Typography color="textSecondary">Scenes ({scenes.length})</Typography>
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", paddingBottom: "24px" }}>
            <List style={{ paddingTop: 0, height: 450, overflow: "auto", flex: 1 }} dense>
              {scenes.map((scene, sceneIndex) => (
                <ListItem
                  dense
                  button
                  onClick={() => setActiveIndex(sceneIndex)}
                  selected={sceneIndex === activeIndex}
                >
                  <S3Image
                    fullWidth
                    imgInstance={scene.mediaItem.image?.thumbNail as ImageInstanceInput}
                  />
                </ListItem>
              ))}
            </List>
            <Box sx={{ flex: 3 }}>
              {currentScene.mediaItem.id &&
                <PodtaSceneBoxEditor
                  key={`sceneBoxEditor${activeIndex}`}
                  withMenu={false}
                  active={true}
                  state={scenes[activeIndex]}
                  offscreenCanvas={scenesCache[scenes[activeIndex].mediaItem.id ?? ""]}
                  setBoxes={() => {
                  }}
                  isLockedHeight={false}
                />}
            </Box>
          </div>
        </Stack>
      )
      : <LinearProgress />
  );

}
