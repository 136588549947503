import { SetValidConstraint } from './SetValidConstraint';

export function createConstraint(
  checkValid: (choosen: number, size: number) => boolean,
  name: string,
  describeError?: (choosen: number, size: number) => string,
): SetValidConstraint {
  return {
    checkValid,
    name,
    describeError:
      describeError ??
      ((c, n) =>
        `Picking ${c} out of ${n} is invalid for constraint ${name}: ${checkValid.toString()}`),
  };
}

export const ValidConstraints = {
  // you have to pick max n or less
  // LessEquals: createConstraint((c,n) => c <= n, 'LessEquals', (c,n) => `Picking ${c} out of ${n} does not fulfill c <= n`),
  LessEquals: createConstraint(
    (c, n) => c <= n,
    'LessEquals',
    (c, n) => `You have to pick max ${n} or less`,
  ),
  // you have to pick exactly n
  // EqualsExactly: createConstraint((c,n) => c === n, 'EqualsExactly', (c,n) => `Picking ${c} out of ${n} does not fulfill c === n`),
  EqualsExactly: createConstraint(
    (c, n) => c === n,
    'EqualsExactly',
    (c, n) => `You have to combine exactly ${n} (but combined ${c})`,
  ),
  // you have to pick an amount of stimuli that is divisible by m
  // EqualsTimes: createConstraint((c,n) => c % n === 0, 'EqualsTimes', (c,n) => `Picking ${c} out of ${n} does not fulfill c === m * n`),
  EqualsTimes: createConstraint(
    (c, n) => c % n === 0,
    'EqualsTimes',
    () => `You have to pick an amount of stimuli that is divisible by m`,
  ),
  All: createConstraint(() => true, 'All'),
};
