export class ConstructionDebugger {
  private static instance: ConstructionDebugger;

  static initDebug() {
    ConstructionDebugger.instance = new ConstructionDebugger();
  }

  protected logDebugInformation(
    level: 'info' | 'warn' | 'debug',
    ...messages: any[]
  ) {
    console[level](...messages);
  }
  static logDebugInformation(
    level: 'info' | 'warn' | 'debug',
    ...messages: any[]
  ) {
    if (this.instance) {
      this.instance.logDebugInformation(level, ...messages);
    }
  }
}
