import React, { FC } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { FormikCheckboxField } from '../../../../../components/input/FormikCheckboxField';
import MediaItemsPicker from '../../../../media/picker/MidiaItemsPicker/MediaItemsPicker';

export const ImageMultipleChoiceQuestionComponent: FC<{
  fieldName: string;
}> = ({ fieldName }) => {
  return (
    <Box sx={{ ml: 2 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography color="textSecondary">
          Options
          <Tooltip
            placement={'right'}
            title={
              <>
                The selected images are presented as possible answers to the
                respondent. Please mind the following instructions:
                <ul>
                  <li>
                    This question type is optimized for up to 12 images. Adding
                    more images is possible but not recommended for user
                    experience.
                  </li>
                  <li>
                    All images should have the same size (in terms of
                    dimensions) or at least the same ratio. Otherwise they will
                    be displayed in an unpredictable way.
                  </li>
                  <li>The images should be approximately square</li>
                </ul>
              </>
            }
          >
            <InfoOutlined
              sx={{
                color: 'warning.dark',
                bgcolor: 'white',
              }}
            />
          </Tooltip>
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormikCheckboxField
            name={`${fieldName}.randomized`}
            label={'Randomize options'}
            size="small"
          />
          <Tooltip
            placement={'right'}
            title={
              'Show answer options in randomized order in the horizontal presentation  from left to right.'
            }
          >
            <InfoOutlined
              sx={{
                color: 'warning.dark',
                bgcolor: 'white',
                ml: '-15px',
              }}
            />
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <MediaItemsPicker
          name={`${fieldName}.options`}
          label="Option Images"
          imageMode
        />
      </Box>
    </Box>
  );
};
