export type DimensionName = "vertical" | "horizontal";
export type DimensionNameMap<N> = Record<DimensionName, N>;

export function CreateDimensionMap<O extends string>(...[vertical, horizontal]: [O, O]): DimensionNameMap<O> {
  return {
    vertical,
    horizontal
  };
}

export const DimensionLengthMap = CreateDimensionMap("height", "width");
export type DimensionLengthName = (typeof DimensionLengthMap)[DimensionName];

export const DimensionCoordinateMap = CreateDimensionMap("y", "x");

export type DimensionCoordinateName = (typeof DimensionCoordinateMap)[DimensionName];

export type Coordinate = Record<DimensionCoordinateName, number>;

export type BasicDimension = Record<DimensionLengthName, number>;

export type SizeUnit = "px" | "%" | "em" | "rem" | "vh" | "vw";

export class SizedLength {
  constructor(public value: number, public unit: SizeUnit) {
  }

  static of(value: number, unit: SizeUnit) {
    return new SizedLength(value, unit);
  }

  static px(value: number) {
    return SizedLength.of(value, "px");
  }

  static percent(value: number) {
    return SizedLength.of(value, "%");
  }

  format() {
    return `${this.value}${this.unit}`;
  }

  rounded(): SizedLength {
    return new SizedLength(Math.round(this.value), this.unit);
  }
}

export type AreaSizeType = "max" | "min" | "actual";
const AreaSizeAttributeMap: Record<AreaSizeType, Record<DimensionName, string>> = {
  actual: DimensionLengthMap,
  max: CreateDimensionMap("maxHeight", "maxWidth"),
  min: CreateDimensionMap("minHeight", "minWidth")
};

export class SizedArea implements Record<DimensionLengthName, SizedLength | undefined> {
  constructor(public width: SizedLength | undefined, public height: SizedLength | undefined) {
  }

  static of(width: SizedLength | undefined, height: SizedLength | undefined) {
    return new SizedArea(width, height);
  }

  static px(width: number, height: number) {
    return SizedArea.of(SizedLength.px(width), SizedLength.px(height));
  }

  static dimPx(dim: BasicDimension) {
    return SizedArea.of(SizedLength.px(dim.width), SizedLength.px(dim.height));
  }

  static percent(width: number, height: number) {
    return SizedArea.of(SizedLength.percent(width), SizedLength.percent(height));
  }

  toSizeModeStyle(areaSizeType: AreaSizeType = 'actual') {
    return { [AreaSizeAttributeMap[areaSizeType].horizontal]: this.width?.format?.() ?? 'unset', [AreaSizeAttributeMap[areaSizeType].vertical]: this.height?.format?.() } as const;
  }
  toDim(): DrawableDimension {
    return new DrawableDimension(this.width?.value!, this.height?.value!);
  }

  toStyle(...areaSizeType: AreaSizeType[]) {
    const styles = areaSizeType.length > 0 ? areaSizeType : ['actual'] as AreaSizeType[];
    return styles.map(st => this.toSizeModeStyle(st)).reduce((acc,c) => ({...acc,...c}),{});
  }

  rounded(): SizedArea {
    return new SizedArea(this.width?.rounded() ?? undefined, this.height?.rounded() ?? undefined);
  }
}

export class DrawableDimension {
  constructor(public width: number, public height: number) {
  }
  add(width: number, height: number) {
    return new DrawableDimension(this.width+width, this.height+height);
  }
  toInt() {
    return DrawableDimension.toInt(this);
  }

  diagonal() {
    return Math.sqrt(this.width*this.width + this.height*this.height);
  }

  static readonly NullSize = new DrawableDimension(0,0);
  static copy(other: BasicDimension) {
    return new DrawableDimension(other.width, other.height);
  }

  static extremes(comparator: "min" | "max", ...dimensions: BasicDimension[]): DrawableDimension {
    return new DrawableDimension(Math[comparator](...dimensions.map(d => d.width)), Math[comparator](...dimensions.map(d => d.height)));
  }


  static fromBoundingRect(rect: DOMRect) {
    return new DrawableDimension(rect.width, rect.height);
  }

  static fromOffsettedBoundingRect(rect: DOMRect) {
    return new DrawableDimension(rect.width - Math.abs(rect.left), rect.height - Math.abs(rect.top));
  }

  static multiply(dim: BasicDimension, factor: number) {
    return new DrawableDimension(dim.width * factor, dim.height*factor);
  }

  static toInt(dimension: BasicDimension): DrawableDimension {
    return new DrawableDimension(Math.round(dimension.width), Math.round(dimension.height));
  }
}

export class DrawableMetrics extends DrawableDimension {

  constructor(width: number, height: number, public weightedVerticalCenter?: number, public weightedHorizontalCenter?: number) {
    super(width, height);
  }

  static fromDimension(dimension: BasicDimension) {
    return new DrawableMetrics(dimension.width, dimension.height);
  }

  get weightedCenter(): Coordinate {
    return {
      x: this.weightedHorizontalCenter ?? (this.width / 2),
      y: this.weightedVerticalCenter ?? (this.height / 2)
    };
  }

}

export interface DimensionCollectionReducer {
  reduce(dimensions: BasicDimension[]): BasicDimension;
}

export class DimensionExtremesReducer implements DimensionCollectionReducer {
  constructor(private extreme: 'max' | 'min', private emptyFallback: BasicDimension) {
  }

  reduce(dimensions: BasicDimension[]): BasicDimension {
    return dimensions.length > 0 ? DrawableDimension.extremes(this.extreme,...dimensions) : this.emptyFallback;
  }

  static create(extreme: 'min'|'max', emptyFallback: BasicDimension = DrawableDimension.NullSize) {
    return new DimensionExtremesReducer(extreme, emptyFallback);
  }
  static min(emptyFallback: BasicDimension = DrawableDimension.NullSize) {
    return DimensionExtremesReducer.create('min', emptyFallback);
  }
  static max(emptyFallback: BasicDimension = DrawableDimension.NullSize) {
    return DimensionExtremesReducer.create('max', emptyFallback);
  }
}
export class DimensionCollector {
  private collectedDimensions: BasicDimension[] = [];

  get allDimensions() {
    return this.collectedDimensions;
  }

  collectDimensions(...dimensions: BasicDimension[]) {
    this.collectedDimensions.push(...dimensions);
  }

  reduceCollection(reducer: DimensionCollectionReducer): BasicDimension {
    return reducer.reduce(this.collectedDimensions);
  }
}
