import { Box, Typography } from '@mui/material';
import React from 'react';
import { useRunnerAppTranslator } from '../../state/baseTestContext/app-configuration/translations/translator.hook';
import { translations } from '../../state/baseTestContext/app-configuration/translations/translation-builder';
import { Translations } from '../../state/baseTestContext/app-configuration/translations/app-translations';

export function PostTestView() {
  const screenTranslator = useRunnerAppTranslator(
    Translations.App.Finish.PostTestScreen,
  );
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
    >
      {screenTranslator.message.mapTranslation((msg) => (
        <Typography variant="body1" textAlign="center">
          {msg}
        </Typography>
      ))}
    </Box>
  );
}

export const PostTestViewTranslations = translations.scope('Post Test Screen', {
  message: translations.text('message', {
    de: 'Der Test ist beendet. Sie können das Fenster nun schließen.',
    en: 'Test finished. You may close the window now.',
  }),
});
