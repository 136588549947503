import React, { FC } from 'react';
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { WswAttributeInputComponent } from '../../components/wswAttribute/WswAttributeInputComponent';
import { WswAttributeCombinationInputComponent } from '../../components/attributeCombination/WswAttributeCombinationInputComponent';
import { EditorStepTitle } from '../../../../../components/grid';
import {
  AttributeCombinationInput,
  GroupConstructionInput,
  PickingStrategy,
  WSWAttributeCategory,
  WSWAttributeInput,
  WSWAttributeType,
} from '../../../../../API';
import {
  WswAttributeCombinationSummaryComponent,
  WswAttributeIndicationHoverState,
} from '../../components/attributeCombination/WswAttributeCombinationSummaryComponent';
import * as yup from 'yup';
import { StrategyFactories } from '../../../pickingSet/picking-strategy';
import { useField } from 'formik';
import { InfoOutlined } from '@mui/icons-material';
import InfoMessage from '../../../../../components/InfoMessage';

export const WswAttributeStepSchema = yup.object({
  mainAttribute: yup
    .object()
    .required()
    .shape({
      categories: yup
        .array()
        .nullable()
        .test('valid cats', 'must be valid', function (value) {
          if (this.parent.type === WSWAttributeType.GROUPS) {
            return true;
          }
          if (value === null) {
            return this.createError({
              message: 'invalid state',
              path: this.path,
            });
          }
          const categories = value as WSWAttributeCategory[];
          for (let i = 0; i < categories.length; i++) {
            const curCat = categories[i];

            if (!curCat.name) {
              return this.createError({
                message: 'Required',
                path: `mainAttribute.categories[${i}].name`,
              });
            }
            if (curCat && curCat.mediaSnaps && curCat.mediaSnaps?.length < 1) {
              return this.createError({
                message: 'Required',
                path: `mainAttribute.categories[${i}].mediaSnaps`,
              });
            }
          }
          return true;
        }),
    }),
  criteriaAttribute: yup
    .object()
    .required()
    .shape({
      categories: yup
        .array()
        .nullable()
        .test('valid cats', 'must be valid', function (value) {
          if (this.parent.type === WSWAttributeType.GROUPS) {
            return true;
          }
          if (value === null) {
            return this.createError({
              message: 'invalid state',
              path: this.path,
            });
          }
          const categories = value as WSWAttributeCategory[];
          for (let i = 0; i < categories.length; i++) {
            const curCat = categories[i];

            if (!curCat.name) {
              return this.createError({
                message: 'Required',
                path: `criteriaAttribute.categories[${i}].name`,
              });
            }
            if (curCat && curCat.mediaSnaps && curCat.mediaSnaps?.length < 1) {
              return this.createError({
                message: 'Required',
                path: `criteriaAttribute.categories[${i}].mediaSnaps`,
              });
            }
          }
          return true;
        }),
    }),
  additionalAttribute: yup
    .object()
    .nullable()
    .shape({
      categories: yup
        .array()
        .nullable()
        .test('valid cats', 'must be valid', function (value) {
          if (this.parent.type === WSWAttributeType.GROUPS) {
            return true;
          }
          if (value === null) {
            return this.createError({
              message: 'invalid state',
              path: this.path,
            });
          }
          const categories = value as WSWAttributeCategory[];
          for (let i = 0; i < categories.length; i++) {
            const curCat = categories[i];

            if (!curCat.name) {
              return this.createError({
                message: 'Required',
                path: `additionalAttribute.categories[${i}].name`,
              });
            }
            if (curCat && curCat.mediaSnaps && curCat.mediaSnaps?.length < 1) {
              return this.createError({
                message: 'Required',
                path: `additionalAttribute.categories[${i}].mediaSnaps`,
              });
            }
          }
          return true;
        }),
    }),
  attributeCombinations: yup
    .array()
    .required()
    .test(
      'validCombinations',
      'There are errors in the Attribute Combinations',
      function (items) {
        const currentAttributeCombinations =
          items as AttributeCombinationInput[];
        const mainAttribute: WSWAttributeInput = this.parent.mainAttribute;
        const criteriaAttribute: WSWAttributeInput =
          this.parent.criteriaAttribute;
        const additionalAttribute: WSWAttributeInput | undefined | null =
          this.parent.additionalAttribute;

        function checkStimuliAttributeSums(
          attribute: WSWAttributeInput,
          categoryKey: keyof AttributeCombinationInput,
        ) {
          const categories = attribute.categories!;
          const constraint = StrategyFactories[attribute.pickingStrategy][1];
          return categories.reduce((accC, category, categoryIndex) => {
            const stimuliSum = category.mediaSnaps.length;
            const combinationsSum = currentAttributeCombinations
              .filter((comb) => comb[categoryKey] === categoryIndex)
              .map((comb) => comb.amount)
              .reduce((acc, c) => acc + c, 0);
            return accC && constraint.checkValid(combinationsSum, stimuliSum);
          }, true);
        }

        function checkGroupAttributeSums(
          attribute: WSWAttributeInput,
          categoryKey: keyof AttributeCombinationInput,
          groups: GroupConstructionInput,
        ) {
          const categories = groups.groups;
          const constraint = StrategyFactories[attribute.pickingStrategy][1];
          return categories.reduce((accC, category, categoryIndex) => {
            const stimuliSum = 1;
            const combinationsSum = currentAttributeCombinations
              .filter((comb) => comb[categoryKey] === categoryIndex)
              .map((comb) => comb.amount)
              .reduce((acc, c) => acc + c, 0);
            return accC && constraint.checkValid(combinationsSum, stimuliSum);
          }, true);
        }

        const checkAttributeSums = (
          attribute: WSWAttributeInput,
          categoryKey: keyof AttributeCombinationInput,
        ) => {
          if (attribute.type === WSWAttributeType.STIMULI) {
            return checkStimuliAttributeSums(attribute, categoryKey);
          } else {
            if (!this.parent.groupConstruction) {
              return false;
            }
            return checkGroupAttributeSums(
              attribute,
              categoryKey,
              this.parent.groupConstruction,
            );
          }
        };
        if (!checkAttributeSums(mainAttribute, 'mainCategory')) {
          return false;
        }
        if (
          additionalAttribute &&
          !checkAttributeSums(additionalAttribute, 'additionalCategory')
        ) {
          return false;
        }
        return checkAttributeSums(criteriaAttribute, 'criteriaCategory');
      },
    ),
});

export const WswAttributesStep: FC = () => {
  const [attributeIndicationState, setAttributeIndicationState] =
    React.useState<WswAttributeIndicationHoverState | undefined>();
  const [, { value: groupConstruction }] = useField<
    GroupConstructionInput | undefined
  >('groupConstruction');
  const [, { value: additionalAttribute }] = useField<
    WSWAttributeInput | undefined | null
  >('additionalAttribute');
  const [, { value: mainAttribute }] = useField<
    WSWAttributeInput | undefined | null
  >('mainAttribute');
  const [, { value: criteriaAttribute }] = useField<
    WSWAttributeInput | undefined | null
  >('criteriaAttribute');

  const inconsistentAttributeConfig =
    !groupConstruction?.groups &&
    (additionalAttribute?.type === WSWAttributeType.GROUPS ||
      mainAttribute?.type === WSWAttributeType.GROUPS ||
      criteriaAttribute?.type === WSWAttributeType.GROUPS);
  return (
    <Box>
      <Stack direction="row">
        <EditorStepTitle prefix={3} title="Attributes" />
        <Tooltip
          placement={'right'}
          title={
            'Any type of stimulus (e.g., pictures, behavioral description, adjectives, category label etc.) that you want to present during the trials.'
          }
        >
          <InfoOutlined
            sx={{
              color: 'warning.dark',
              bgcolor: 'white',
              position: 'relative',
            }}
          />
        </Tooltip>
      </Stack>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          xl={6}
          sx={(theme) => ({
            [theme.breakpoints.only('xl')]: {
              borderBottom: 'solid lightgrey 0.5px',
              borderRight: 'solid lightgrey 0.5px',
            },
          })}
        >
          <Box width="80%">
            <WswAttributeInputComponent
              groupConstructionEnabled={Boolean(groupConstruction)}
              fixedAttributeType={WSWAttributeType.STIMULI}
              fixedPickingStrategy={PickingStrategy.PERMUTATION}
              fieldName={'mainAttribute'}
              label={'Main Attribute'}
              toolTip={
                'Target stimulus (text or picture) that will be presented during learning and test phase (e.g., for determining recognition memory, e.g. “Have you seen this person before?”).'
              }
              pickToolTip={
                'Choose the type of stimulus you want to use: pictures or text (once you have chosen a main attribute you will not be able to change it throughout this test or any copies of it).'
              }
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          xl={6}
          sx={(theme) => ({
            [theme.breakpoints.only('xl')]: {
              borderBottom: 'solid lightgrey 0.5px',
            },
          })}
        >
          <Box width="80%">
            <WswAttributeInputComponent
              groupConstructionEnabled={Boolean(groupConstruction)}
              fieldName={'criteriaAttribute'}
              label={'Criteria Attribute'}
              toolTip={
                'Target attribute (text or picture) that will ONLY be presented during learning phase - criteria for recall in test phase (e.g., for determining source memory; e.g., “How did this person behave?” or “Which group does this person belong to?”).'
              }
              pickToolTip={
                'Choose the type of stimulus you want to use: pictures or text (once you have chosen a main attribute you will not be able to change it throughout this test or any copies of it).'
              }
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          xl={6}
          sx={(theme) => ({
            [theme.breakpoints.only('xl')]: {
              borderBottom: 'solid lightgrey 0.5px',
              borderRight: 'solid lightgrey 0.5px',
            },
          })}
        >
          <Box width="80%">
            <WswAttributeInputComponent
              groupConstructionEnabled={Boolean(groupConstruction)}
              fieldName={'additionalAttribute'}
              label={'Additional Attribute'}
              optional
              toolTip={
                'Additional target attribute that will be presented during learning and test phase. Can be applied if you want to pair the main attributes with more than one other attribute (that is more than the criteria attribute; e.g., to investigate biases due to target group membership).'
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} xl={6} />
        {inconsistentAttributeConfig ? (
          <Typography color="error">
            An attribute is configured to use groups, but groups are not
            defined.
          </Typography>
        ) : (
          <>
            <Grid item xs={12}>
              <Box width="80%">
                <WswAttributeCombinationInputComponent
                  fieldName={'attributeCombinations'}
                  attributeIndicationState={attributeIndicationState}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <InfoMessage
                message={
                  'This summary probes whether the assigned frequencies match the number of stimuli for attributes'
                }
              />
              <Box
                width="80%"
                border={'solid lightgrey'}
                borderRadius={1}
                bgcolor={'#f3f3f3'}
              >
                <WswAttributeCombinationSummaryComponent
                  setAttributeIndication={setAttributeIndicationState}
                />
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
