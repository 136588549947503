import React, { FC } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { ConditionalQuestionSet } from '../conditionalQuestionSet/ConditionalQuestionSet';
import StyledNumberInput from '../../../../../components/input/StyledNumberInput';
import { WswVariables } from '../../../text-replacer/textReplacer';
import { OptionalInstructionsScreen } from '../../../../subject/testRunner/screens/OptionalInstructionsScreen';

export const PresentationPhaseInputComponent: FC<{
  fieldName: string;
  label: string;
}> = ({ fieldName }) => {
  return (
    <Box>
      <Stack direction="row" spacing={3} sx={{ mb: 2, width: '100%' }}>
        <Box width={'49%'}>
          <OptionalInstructionsScreen
            fieldName={`${fieldName}.instructionsScreen`}
            checkboxLabel="Show Instructions Screen"
            dialogLabel="Instructions Screen"
            variables={WswVariables}
          />
        </Box>
        <Box width={'49%'}>
          <OptionalInstructionsScreen
            fieldName={`${fieldName}.feedbackScreen`}
            checkboxLabel="Show Feedback Screen"
            dialogLabel="Feedback Screen"
            variables={WswVariables}
          />
        </Box>
      </Stack>

      <Box sx={{ width: '49%', my: 2 }}>
        <StyledNumberInput
          name={`${fieldName}.questionOffsetTimeout`}
          label={'Question fade in'}
          unit="ms"
          // toolTip="Timout used for combination of questions and attributes"
          toolTip="Determines how long an attribute stimulus is presented before the question appears"
        />
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Stack direction="row" spacing={3} sx={{ mb: 2, width: '100%' }}>
        <Box width={'49%'}>
          <ConditionalQuestionSet
            fieldName={`${fieldName}.questionsSet`}
            label={'Questionset'}
            withImageInQuestions={false}
            withConditionalQuestion={true}
            withQuestionTimeout={false}
            withImageMCQuestions={fieldName === 'testPhase'}
          />
        </Box>
      </Stack>
    </Box>
  );
};
