import { ControlTransition } from '../../../../subject/testRunner/controls/control-transition';
import { TestScreenRequest } from '../../../../subject/testRunner/graph/test-screen-request';

export class PodtScreenData implements TestScreenRequest {
  constructor(
    readonly controlTransitions: ControlTransition<any, any>[] = [],
  ) {}
  transiteTo(...controlTransitions: ControlTransition<any, any>[]): this {
    this.controlTransitions.push(...controlTransitions);
    return this;
  }
}
