import React from 'react';
import { Stack } from '@mui/material';
import {
  applyTextStyleToContext,
  TextDrawableBuilder,
  TextMediaStyle,
  unitFontSize,
} from '../../features/subject/testRunner/media/text-drawer';
import { FontFace } from '../../API';
import { MediaDrawable } from '../../features/subject/testRunner/media/MediaData';
import { DrawableMetrics } from '../../features/subject/testRunner/media/drawable-dimensions';
import { IatRunnerConfig } from '../../features/tests/IAT/runner/iat-runner-config';
import {
  loadMockIat,
  loadMockPodt,
  loadMockPodta,
} from '../utils/runner-mocks/load-iat';
import { scaleAndInitializeRunner } from '../utils/runner-mocks/scale-mock-test-ui';
import {
  LoadedPodtaTestInspector,
  LoadedTestInspector,
} from './loaded-iat-test.inspector';
import { NewGQL } from '../../GQL';
import { IatQueries } from '../../features/tests/IAT/loader/loading/iat.queries';
import { PodtQueries } from '../../features/tests/PODT/loader/loading/podt.queries';
import { PodtaQueries } from '../../features/tests/PODTA/loader/loading/podta.queries';
import { PodtaRunnerConfig } from '../../features/tests/PODTA/runner/podta-runner-config';
import { TestType } from '../../features/subject/types';

const exampleTexts = [
  'hallo',
  'ÄmÖüPČ',
  'QuäLÖnd',
  'Krieg',
  'Ähnlich',
  'Oberflächlich',
  'أبيض',
  'أسود',
  "'",
];
const exampleTextStyle = new TextMediaStyle(
  FontFace.ARIAL,
  unitFontSize(25, 'px'),
  'black',
);
/*const exampleBaseline: CanvasTextBaseline = 'top';*/
const allBaselines: CanvasTextBaseline[] = [
  'top',
  'hanging',
  'middle',
  'alphabetic',
  'ideographic',
  'bottom',
];

function buildExampleText(
  text: string,
  processor?: {
    processWeightedDimension: (dim: DrawableMetrics) => void;
    getAllProcessedDimensions: () => DrawableMetrics[];
  },
) {
  const measured = Object.fromEntries(
    allBaselines.map((baseline) => {
      const textMetrics = TextDrawableBuilder.calculateCanvasTextMeasure(
        text,
        exampleTextStyle,
        baseline,
      );
      return [
        baseline,
        {
          metrics: textMetrics,
          drawableMetrics: DrawableMetrics.fromTextDimensions(textMetrics),
        },
      ];
    }),
  ) as Record<
    CanvasTextBaseline,
    { metrics: TextMetrics; drawableMetrics: DrawableMetrics }
  >;
  const drawableBuilder = new TextDrawableBuilder(
    text,
    exampleTextStyle,
    processor,
  );
  const mediaDrawable = drawableBuilder.build();

  return {
    text,
    drawableBuilder,
    measured,
    mediaDrawable,
  };
}

export function CanvasTextMeasurePlayground() {
  const textDrawers = React.useMemo(() => {
    const processor = {
      _cache: [] as DrawableMetrics[],
      getAllProcessedDimensions() {
        return this._cache;
      },
      processWeightedDimension(dim: DrawableMetrics) {
        this._cache.push(dim);
      },
    };
    return exampleTexts.map((t) =>
      buildExampleText(
        t,
        processor as {
          processWeightedDimension: (dim: DrawableMetrics) => void;
          getAllProcessedDimensions: () => DrawableMetrics[];
        },
      ),
    );
  }, []);
  const [baseline, setBaseline] = React.useState<CanvasTextBaseline>('top');
  const [loadedTest, setLoadedTest] =
    React.useState<Awaited<ReturnType<typeof scaleAndInitializeRunner>>>();
  return (
    <div style={{ padding: '2rem' }}>
      <button
        onClick={async () => {
          setLoadedTest(
            await loadMockIat(
              await NewGQL.NO_AUTH_CLIENT.execute(
                IatQueries.Get.create({
                  id: '9e3ee46b-de71-44f6-8d74-3fd4f74074ea',
                }),
              ) /**/,
            ).then((res) => scaleAndInitializeRunner(IatRunnerConfig, res)),
          );
        }}
      >
        Load IAT
      </button>
      <button
        onClick={async () => {
          setLoadedTest(
            await loadMockPodta(
              await NewGQL.NO_AUTH_CLIENT.execute(
                PodtaQueries.Get.create({
                  id: '66ae1002-5f64-4b2e-9d3f-0978d6851b5b',
                }),
              ) /**/,
            ).then((res) => scaleAndInitializeRunner(PodtaRunnerConfig, res)),
          );
        }}
      >
        Load PODTA
      </button>
      <Stack spacing={2}>
        <div>
          {loadedTest &&
            loadedTest.structure.accessor.testContext.testIdentity.testType ===
              TestType.IAT && <LoadedTestInspector loadedTest={loadedTest} />}
          {loadedTest &&
            loadedTest.structure.accessor.testContext.testIdentity.testType ===
              TestType.PODTA && (
              <LoadedPodtaTestInspector loadedTest={loadedTest} />
            )}
        </div>
        <div>
          <label>
            baseline
            <select
              value={baseline}
              onChange={(event) =>
                setBaseline(event.target.value as CanvasTextBaseline)
              }
            >
              {allBaselines.map((b) => (
                <option key={b} value={b}>
                  {b}
                </option>
              ))}
            </select>
          </label>
        </div>
        {textDrawers.map((textDrawer) => (
          <CanvasTestInstance
            key={textDrawer.text}
            baseline={baseline}
            textDrawer={textDrawer}
          />
        ))}
      </Stack>
    </div>
  );
}

const containerCanvasSize = { width: 250, height: 100 };

function CanvasTestInstance({
  textDrawer,
  baseline,
}: {
  textDrawer: ReturnType<typeof buildExampleText>;
  baseline: CanvasTextBaseline;
}) {
  const { text, drawableBuilder, measured, mediaDrawable } = textDrawer;

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2}>
        <MediaDrawerCanvas mediaDrawable={mediaDrawable} />
        <ExperimentalCanvas textDrawer={textDrawer} baseline={baseline} />
        <WeightedExperimentalCanvas
          textDrawer={textDrawer}
          baseline={baseline}
        />
      </Stack>
      <Stack>
        <span>
          Text: "{text}"{' '}
          {`CS ${mediaDrawable.imageSource.width}x${mediaDrawable.imageSource.height} `}
          {`MS ${drawableBuilder.dimension.width}x${drawableBuilder.dimension.height} `}
          {`width: ${measured[baseline].metrics.width.toFixed(3)}`}
        </span>
        <span>
          Text Measure: {`baseline ${baseline} `} <br />
          {`actual: ascent:${measured[baseline].metrics.actualBoundingBoxAscent} descent: ${measured[baseline].metrics.actualBoundingBoxDescent}`}{' '}
          <br />
          {`font: ascent:${measured[baseline].metrics.fontBoundingBoxAscent} descent: ${measured[baseline].metrics.fontBoundingBoxDescent}`}{' '}
          <br />
        </span>
      </Stack>
    </Stack>
  );
}

function MediaDrawerCanvas({
  mediaDrawable,
}: {
  mediaDrawable: MediaDrawable;
}) {
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  React.useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d') as CanvasRenderingContext2D;
    if (!context) {
      return;
    }
    const leftStart = 10;
    const topStart = Math.round(
      containerCanvasSize.height / 2 - mediaDrawable.dimension.height / 2,
    );
    context.clearRect(
      0,
      0,
      containerCanvasSize.width,
      containerCanvasSize.height,
    );
    context.strokeStyle = 'rgba(150,220,150,150)';
    context.strokeRect(
      leftStart,
      topStart,
      mediaDrawable.imageSource.width as number,
      mediaDrawable.imageSource.height as number,
    );
    context.drawImage(
      mediaDrawable.imageSource,
      0,
      0,
      mediaDrawable.imageSource.width as number,
      mediaDrawable.imageSource.height as number,
      leftStart,
      topStart,
      mediaDrawable.imageSource.width as number,
      mediaDrawable.imageSource.height as number,
    );
  }, [mediaDrawable.dimension.height, mediaDrawable.imageSource]);
  return (
    <div>
      <canvas
        ref={canvasRef}
        width={containerCanvasSize.width}
        height={containerCanvasSize.height}
        style={{
          border: '1px solid black',
        }}
      />
    </div>
  );
}

function ExperimentalCanvas({
  textDrawer,
  baseline,
}: {
  textDrawer: ReturnType<typeof buildExampleText>;
  baseline: CanvasTextBaseline;
}) {
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  React.useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d') as CanvasRenderingContext2D;
    if (!context) {
      return;
    }
    //  const measure = textDrawer.measured[baseline];

    const baselineHeight = Math.round(containerCanvasSize.height / 2);
    const leftStart = 10;

    context.clearRect(
      0,
      0,
      containerCanvasSize.width,
      containerCanvasSize.height,
    );
    context.strokeStyle = 'rgba(150,150,150,150)';
    context.strokeRect(
      leftStart,
      baselineHeight,
      textDrawer.drawableBuilder.dimension.width,
      textDrawer.drawableBuilder.dimension.height,
    );
    context.strokeStyle = 'red';
    context.moveTo(0, baselineHeight);
    context.lineTo(containerCanvasSize.width, baselineHeight);
    context.stroke();

    applyTextStyleToContext(context, exampleTextStyle);
    context.textBaseline = baseline;
    context.fillText(textDrawer.text, leftStart, baselineHeight);
  }, [
    baseline,
    textDrawer.drawableBuilder.dimension.height,
    textDrawer.drawableBuilder.dimension.width,
    textDrawer.measured,
    textDrawer.text,
  ]);
  return (
    <div>
      <canvas
        ref={canvasRef}
        width={containerCanvasSize.width}
        height={containerCanvasSize.height}
        style={{
          border: '1px solid black',
        }}
      />
    </div>
  );
}
function WeightedExperimentalCanvas({
  textDrawer,
  baseline,
}: {
  textDrawer: ReturnType<typeof buildExampleText>;
  baseline: CanvasTextBaseline;
}) {
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  React.useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d') as CanvasRenderingContext2D;
    if (!context) {
      return;
    }
    context.fillStyle = 'blue';
    context.clearRect(
      0,
      0,
      containerCanvasSize.width,
      containerCanvasSize.height,
    );
    const containerCenter = Math.round(containerCanvasSize.height / 2);
    const measure = textDrawer.mediaDrawable.dimension;
    const actualRect = {
      top: Math.floor(containerCenter - measure.height / 2),
      left: 10,
      width: measure.width,
      height: measure.height,
    };

    context.strokeStyle = 'rgba(150,150,150,150)';
    context.strokeRect(
      actualRect.left,
      actualRect.top,
      actualRect.width,
      actualRect.height,
    );
    console.log('dim', textDrawer.text, actualRect.height);
    context.strokeStyle = 'red';
    context.moveTo(0, Math.ceil(actualRect.top + measure.weightedCenter.y));
    context.lineTo(
      containerCanvasSize.width,
      Math.ceil(actualRect.top + measure.weightedCenter.y),
    );

    context.stroke();
    applyTextStyleToContext(context, exampleTextStyle);
    context.textBaseline = 'alphabetic';
    context.fillText(
      textDrawer.text,
      actualRect.left,
      Math.ceil(actualRect.top + measure.weightedCenter.y),
    );
  }, [
    baseline,
    textDrawer.drawableBuilder.dimension.height,
    textDrawer.drawableBuilder.dimension.width,
    textDrawer.measured,
    textDrawer.mediaDrawable.dimension,
    textDrawer.text,
  ]);
  return (
    <div>
      <canvas
        key={textDrawer.text + baseline}
        ref={canvasRef}
        width={containerCanvasSize.width}
        height={containerCanvasSize.height}
        style={{
          border: '1px solid black',
        }}
      />
    </div>
  );
}
