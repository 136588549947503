import { SizeState, SizeStateDisplay } from '../scaler/size-state';
import { View } from '../views/view';
import { ViewScaler } from '../scaler/view-scaler';
import { BasicDimension, SizedArea } from '../../../media/drawable-dimensions';
import { DimensionReducer } from '../../dom-measure/dimension-reducer';
import {
  createMeasurableWrapperComponent,
  MeasurableComponent,
} from '../../dom-measure/measurable-component';
import React, { CSSProperties } from 'react';
import { awaitFirst } from '../../../utils/rxjs-utils';
import { ViewInstanceController } from '../screen-instance/view-instance-controller';
import { LayoutBox } from './layout-box';
import { StyleCB } from '../views/render-container-provider';
import { instrumentObject } from '../../../utils/instrument-object';
import { isReallyDefined } from '../../../../utils';
import { MeasurePhases } from '../../measure-phases';

export class BoxHandler {
  sizeState = new SizeState();

  constructor(
    public box: LayoutBox,
    public readonly viewScalerMap: Map<View, ViewScaler>,
  ) {
    this.sizeState.stateDisplay = new SizeStateDisplay('[box]' + box.name, 0);
  }

  maxSize(size: BasicDimension) {
    this.sizeState.maxSize = size;
    this.box.views.forEach((v) => {
      this.viewScalerMap.get(v)!.maxSize(size);
    });
  }

  determineMin() {
    this.sizeState.minSize = DimensionReducer.max().process(
      ...this.box.views.map(
        (v) => this.viewScalerMap.get(v)!.sizeState.minSize,
      ),
    ).currentDimension;
  }

  createZeroSizeComponent(styleCB?: StyleCB): MeasurableComponent {
    const styleWrap = styleCB ?? ((_: string, s: CSSProperties) => s);
    return createMeasurableWrapperComponent<HTMLDivElement>(
      ({ containerRef }) => {
        return (
          <div
            ref={containerRef}
            style={styleWrap('zero', { width: 0, height: 0 })}
          />
        );
      },
    );
  }

  createMeasureComponent(
    phase: MeasurePhases,
    styleCB?: StyleCB,
  ): MeasurableComponent {
    const styleWrap = styleCB ?? ((_: string, s: CSSProperties) => s);
    const measurableComponent =
      createMeasurableWrapperComponent<HTMLDivElement>(({ containerRef }) => {
        return (
          <div
            ref={containerRef}
            style={styleWrap(phase, {
              display: 'block',
              ...SizedArea.px(
                this.sizeState.minSize.width,
                this.sizeState.minSize.height,
              ).toSizeModeStyle('min'),
              width:
                this.box.grows && (phase === 'size' || phase === 'inner')
                  ? '100%'
                  : undefined,
              height:
                this.box.grows && (phase === 'size' || phase === 'inner')
                  ? '100%'
                  : undefined,
            })}
          />
        );
      });
    if (phase === 'size') {
      awaitFirst(
        instrumentObject(
          measurableComponent.sizeSubscriber,
          `box-${this.box.name}`,
        ),
        isReallyDefined,
      ).then((res) => {
        this.sizeState.size = res;
        this.box.views.forEach((v) =>
          this.viewScalerMap.get(v)!.size(this.sizeState.size),
        );
      });
    }
    return measurableComponent;
  }

  createRenderComponent(
    viewControllerMap: Map<View, ViewInstanceController>,
    styleCB?: StyleCB,
  ) {
    const styleWrap = styleCB ?? ((_: string, s: CSSProperties) => s);
    return () => (
      <div
        id={`render-box-outer-container-${this.box.name}`}
        style={styleWrap('render', {
          position: 'relative',
          ...SizedArea.px(
            this.sizeState.size.width,
            this.sizeState.size.height,
          ).toSizeModeStyle(),
        })}
      >
        {this.box.views.map((view) => {
          const key = view.selector.selectorInstance.pathName;
          const ViewComp = viewControllerMap.get(view)!.element;
          return <ViewComp key={key} />;
        })}
      </div>
    );
  }
}
