import { ControlTransition } from '../../../../subject/testRunner/controls/control-transition';
import { WswConstructedGroup } from '../structure/wsw-structure';
import { TestScreenRequest } from '../../../../subject/testRunner/graph/test-screen-request';

export class WswScreenData implements TestScreenRequest {
  constructor(
    public readonly probandGroup = null as WswConstructedGroup | null,
    readonly controlTransitions: ControlTransition<any, any>[] = [],
  ) {}

  transiteTo(...controlTransitions: ControlTransition<any, any>[]): this {
    this.controlTransitions.push(...controlTransitions);
    return this;
  }
}
