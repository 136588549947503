import {
  SequencePhaseBuilder,
  SequenceTaskBuilder,
} from '../../../subject/testRunner/media/sequence-tasks';
import { TestBaseContext } from '../../../subject/testRunner/state/baseTestContext/context/ContextTypes';
import {
  TestResourceAccessor,
  TestResourceLoaderInstance,
} from '../../../subject/testRunner/media/test-resource-loader';
import { Podt } from '../types';
import { PodtMediaPool } from './loading/podt-loader';
import { TestType } from '../../../subject/types';
import { OptionArranger } from '../../../subject/testRunner/media/options-test';
import { createPodtStructure } from './structure/podt-structure';
import { createPodtTree } from './tree/podt-tree';
import { PodtParametersDefinition } from './loading/podt-parameters-definition';
import { LoadPodtMedia } from './loading/load-podt-media';
import { sequenceTree } from '../../../subject/testRunner/graph/tree-sequence/tree-sequence';

export const PodtLoaderSequence = SequencePhaseBuilder.Builder<{
  testContextFactory: () => Promise<TestBaseContext>;
  loader: TestResourceLoaderInstance<Podt>;
}>()
  .start(
    'load',
    SequenceTaskBuilder.Builder<{
      testContextFactory: () => Promise<TestBaseContext>;
      loader: TestResourceLoaderInstance<Podt>;
    }>()
      .start('retrieve', async ({ testContextFactory, loader }) => {
        const testContext = await testContextFactory();
        return {
          testContext,
          accessor: await loader.load(testContext),
        };
      })
      .chainTask('load', ({ accessor }) => ({
        accessor,
        pool: LoadPodtMedia(accessor, TestType.PODT),
      }))
      .chainTask('determine-parameters', ({ accessor, pool }) => ({
        pool,
        accessor,
        parameters: PodtParametersDefinition.createManager(accessor),
      })),
  )
  .chain(
    'arrange',
    SequenceTaskBuilder.Builder<{
      pool: PodtMediaPool;
      accessor: TestResourceAccessor<Podt>;
      parameters: Record<'optionsArranger', OptionArranger>;
    }>()
      .start('arrange', ({ accessor, pool, parameters }) =>
        createPodtStructure(accessor, pool),
      )
      .chainTask('tree', (structure) => ({
        structure,
        tree: createPodtTree(structure),
      }))
      .chainTask('tree-sequence', (data) => ({
        ...data,
        sequence: sequenceTree(data.tree),
      })),
  )
  .createPhases();
