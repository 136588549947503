import { BasicDimension } from '../../../../media/drawable-dimensions';
import {
  QuestionImageContext,
  WswTestQuestionInstance,
} from '../../loader/model/questions/wsw-test-question-instance';
import MediaOffscreenCanvas from '../../../../subject/MediaOffscreenCanvas';
import { DrawableDimension } from '../../../../subject/testRunner/media/drawable-dimensions';
import { displayRatio } from '../../../../subject/testRunner/screens/basic-components/media-instance-components';
import { useMeasureRendererContext } from '../../../../subject/testRunner/screens/dom-measure/measure-renderer.context';
import React from 'react';
import { Box, Typography } from '@mui/material';
import { WSWSelectorTree } from '../wsw-selectors';
import {
  HTMLContentComponent,
  HTMLContentView,
} from '../../../../subject/testRunner/screens/composer/views/html-content-view';

export function createWswQuestionContentView() {
  return HTMLContentView.fixed(
    WSWSelectorTree.rootSelector.phaseQuestions.question,
    createWswQuestionComponent(),
  );
}

function createWswQuestionComponent() {
  const questionImageContext: {
    processSize: (size: BasicDimension | undefined) => void;
    context: QuestionImageContext;
  } = (function () {
    let maxSize: BasicDimension | undefined = undefined;
    let scaledMap = new Map<string, MediaOffscreenCanvas>();
    return {
      processSize: (size) => {
        if (maxSize === undefined) {
          maxSize = size;
        }
      },
      context: {
        maxSize: () => {
          if (maxSize) {
            return maxSize;
          }
          const rect = document.body.getBoundingClientRect();
          maxSize = { width: rect.width, height: rect.height };
          return maxSize;
        },
        scaleImage: (drawable, size) => {
          const identifier = `${drawable.data.identifier}-${size.width}x${size.height}`;
          let result = scaledMap.get(identifier);
          if (!result) {
            result = new MediaOffscreenCanvas(
              DrawableDimension.multiply(size, displayRatio),
            );
            const instanceDim = drawable.drawable.dimension;
            const scaleFactor =
              instanceDim.height > size.height
                ? size.height / instanceDim.height
                : 1;
            const instanceScaledDim = DrawableDimension.multiply(
              instanceDim,
              scaleFactor,
            );
            result.drawImgSourceCenter(
              drawable.drawable.imageSource,
              instanceScaledDim.toInt(),
              displayRatio,
            );
            scaledMap.set(identifier, result);
          }
          return result;
        },
      },
    };
  })();

  const InnerWswQuestionComponent: HTMLContentComponent<
    WswTestQuestionInstance
  > = ({ value, containerRef }) => {
    const measureContext = useMeasureRendererContext();
    const QuestionComponent = React.useMemo(() => {
      questionImageContext.processSize(measureContext?.maxSize);
      return value.createComponent(questionImageContext.context);
    }, [measureContext?.maxSize, value]);
    return (
      <Box
        ref={containerRef}
        sx={{
          padding: '5px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: `${questionImageContext.context.maxSize().width}`,
        }}
      >
        <Typography sx={{ mb: 1 }}>{value.questionText}</Typography>
        <QuestionComponent />
      </Box>
    );
  };

  return InnerWswQuestionComponent;
}
