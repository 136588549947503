import { FullScreenHandle } from 'react-full-screen';
import { Box, Button, Typography } from '@mui/material';
import { delay } from '../../../utils';
import React from 'react';
import { useRunnerAppTranslator } from '../../state/baseTestContext/app-configuration/translations/translator.hook';
import { translations } from '../../state/baseTestContext/app-configuration/translations/translation-builder';
import { Translations } from '../../state/baseTestContext/app-configuration/translations/app-translations';

export function FullscreenPausedView({ handle }: { handle: FullScreenHandle }) {
  const screenTranslator = useRunnerAppTranslator(
    Translations.App.Loading.FullscreenPausedScreen,
  );
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: `${window.innerWidth}px`,
        height: `${window.innerHeight}px`,
        backgroundColor: 'white',
      }}
    >
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {screenTranslator.message.mapTranslation((txt) => (
          <Typography variant="body1">{txt}</Typography>
        ))}
        <Button
          onClick={() => {
            let fullscreenResult = handle.enter();
            if (fullscreenResult === undefined) {
              fullscreenResult = delay(200);
            }
            fullscreenResult.then(() => {
              // ignored because TS assumes this API to exist in any case, but there seem to be browsers that do no provide it at all
              // @ts-ignore
              if (window.screen?.orientation?.lock) {
                window.screen.orientation.lock('landscape').catch(() => {});
              }
            });
          }}
        >
          {screenTranslator.buttonLabelAction.translate().join(' / ')}
        </Button>
      </Box>
    </div>
  );
}

export const FullscreenPausedTranslations = translations.scope(
  'Fullscreen Interrupted Screen',
  {
    message: translations.text('Reactivate Fullscreen Mode', {
      en: 'Please switch back to full screen mode',
      de: 'Bitte wechseln Sie wieder in den Vollbildmodus',
    }),
    buttonLabelAction: translations.text('Confirm/Action Button', {
      en: 'Switch to fullscreen mode',
      de: 'In Vollbildmodus wechseln',
    }),
  },
);
