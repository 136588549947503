import React from 'react';
import { MediaItem } from '../types';
import { MediaItemSnapshotInput, MediaItemSnapshotScope } from '../../../API';
import S3Image from '../../../components/display/S3Image';

export function isMediaItemSnapshot(
  item: MediaItem | MediaItemSnapshotInput,
): item is MediaItemSnapshotInput {
  return (item as any).text !== undefined;
}

export const mediaItemToMediaItemSnapshotInput = ({
  id,
  tags,
  image,
  originalFileName,
}: MediaItem): MediaItemSnapshotInput => {
  return {
    id,
    originalFileName,
    tags: tags?.items.map((it) => (it as any).tag) ?? [],
    scope: MediaItemSnapshotScope.PUBLIC,
    image,
  };
};

export const renderMediaItem = (
  mediaItem?: MediaItem | MediaItemSnapshotInput,
  fullSize?: boolean,
  withFade?: boolean,
  containerScale?: boolean,
) => {
  if (!mediaItem) {
    return <></>;
  }
  if (isMediaItemSnapshot(mediaItem) && mediaItem.text) {
    return <p>{mediaItem.text}</p>;
  }
  if (mediaItem.image) {
    return fullSize ? (
      <S3Image
        withFade={withFade}
        imgInstance={mediaItem.image.fullSize}
        containerScale={containerScale}
      />
    ) : (
      <S3Image
        withFade={withFade}
        imgInstance={mediaItem.image.thumbNail}
        expandedImage={mediaItem.image.fullSize}
        containerScale={containerScale}
      />
    );
  }
};
