import { TranslationKeyValueTypeMap } from './translation-value';

import { StaticTranslationKey } from './translation-key';

export class StaticTranslationScope {
  readonly translationsKeys: StaticTranslationKey<
    keyof TranslationKeyValueTypeMap
  >[] = [];
  get path() {
    return `${this.scopePrefix}${this.scopePrefix ? '.' : ''}${this.key}`;
  }
  constructor(
    public readonly scopePrefix: string,
    public readonly key: string,
    public readonly name: string,
  ) {}

  translationKey<V extends keyof TranslationKeyValueTypeMap>(
    key: string,
    type: V,
    defaults: Record<string, TranslationKeyValueTypeMap[V]>,
    name: string = key,
  ) {
    const translationKey = new StaticTranslationKey(
      `${this.scopePrefix}.${this.name}.${key}`,
      type,
      defaults,
      name,
    );
    this.translationsKeys.push(translationKey);
    return translationKey;
  }

  textTranslation(
    key: string,
    defaults: Record<string, string>,
    name: string = key,
  ) {
    return this.translationKey(key, 'text', defaults, name);
  }

  textArrayTranslation(
    key: string,
    defaults: Record<string, string[]>,
    name: string = key,
  ) {
    return this.translationKey(key, 'textArray', defaults, name);
  }

  static define(scopePrefix: string, key: string, name: string) {
    return new StaticTranslationScope(scopePrefix, key, name);
  }

  subScope(
    scopePrefix: string,
    key: string,
    name: string,
  ): StaticTranslationScope {
    return new StaticTranslationScope(
      `${this.scopePrefix}.${scopePrefix}`,
      key,
      name,
    );
  }
}

export type TranslationScopeMap<
  M extends Record<
    string,
    StaticTranslationKey<any> | TranslationScopeMap<any>
  >,
> = { scope: StaticTranslationScope } & {
  [k in keyof M]: M[k];
};
