import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  SelectProps,
  Tooltip,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { ErrorMessage, useField } from 'formik';
import makeStyles from '@mui/styles/makeStyles';
import { UiInput } from './types';
import { InfoOutlined } from '@mui/icons-material';

type StyledNumberSelectProps = UiInput<number> &
  SelectProps & {
    max?: number;
    divisibleBy?: number;
    toolTip?: string;
    accumulationText?: string;
  };

const StyledNumberSelect: FunctionComponent<StyledNumberSelectProps> = ({
  name,
  divisibleBy,
  value,
  onChange,
  max,
  label,
  disabled,
  accumulationText,
  toolTip,
}) => {
  const classes = useStyles();
  const [, meta, helpers] = useField<typeof value>(name);

  const [oldDivBy, setOldDivBy] = useState(divisibleBy);

  const onChangeTextField = useCallback(
    (ev: React.ChangeEvent<{ name?: string; value: any }>) => {
      const v = Number(ev.target.value);
      if (onChange) {
        onChange(v);
      }
      helpers.setValue(v);
    },
    [helpers, onChange],
  );

  const options = [...Array(max || 20)].map((_value, i) => {
    const index = divisibleBy ? (i + 1) * divisibleBy : i;
    return (
      <option key={`${name}-${index}`} value={index}>
        {index}
      </option>
    );
  });

  useEffect(() => {
    if (divisibleBy && divisibleBy !== oldDivBy) {
      onChangeTextField({
        target: { value: 1 * divisibleBy },
      } as React.ChangeEvent<{ name?: string; value: any }>);
    }
    setOldDivBy(divisibleBy);
  }, [oldDivBy, onChangeTextField, divisibleBy]);

  return (
    <Box position="relative">
      <FormControl fullWidth className={classes.formControl}>
        <InputLabel htmlFor={name}>{label || name}</InputLabel>
        <MuiSelect
          native
          fullWidth
          name={name}
          disabled={disabled}
          value={value || meta.value}
          //@ts-ignore
          onChange={onChangeTextField}
          endAdornment={
            accumulationText && meta.value && divisibleBy
              ? `(${meta.value / divisibleBy} ${accumulationText})`
              : undefined
          }
          inputProps={{
            name,
            id: `select-${name}`,
          }}
          error={meta.touched && meta.error !== undefined}
        >
          {options}
        </MuiSelect>
        {meta.error && (
          <Typography variant="caption" color="error">
            <ErrorMessage name={name} />
          </Typography>
        )}
      </FormControl>
      {toolTip && (
        <Tooltip title={toolTip} placement="left-start">
          <InfoOutlined
            sx={{
              color: 'warning.dark',
              bgcolor: 'white',
              position: 'absolute',
              top: -10,
              right: -10,
            }}
          />
        </Tooltip>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        background: 'white',
        marginLeft: theme.spacing(-0.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(0.5),
      },
    },
  }),
);

export default StyledNumberSelect;
