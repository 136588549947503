import { createTestCrudMap, createTestResultCrudMap } from '../../../../../GQL';
import { Wsw } from '../../types';
import {
  CreateWswResultMutationVariables,
  CreateWSWTestMutationVariables,
  DeleteWswResultMutationVariables,
  DeleteWSWTestMutation,
  GetWswResultQueryVariables,
  GetWSWTestQueryVariables,
  ListWswResultsQueryVariables,
  ListWSWTestsQueryVariables,
  UpdateWswResultMutationVariables,
  UpdateWSWTestMutationVariables,
  WswResult,
  WswResultsByTestIdQueryVariables,
} from '../../../../../API';
import {
  createWswResult,
  createWSWTest,
  deleteWswResult,
  deleteWSWTest,
  updateWswResult,
  updateWSWTest,
} from '../../../../../graphql/mutations';
import {
  getWswResult,
  listWswResults,
  listWSWTests,
  wswResultsByTestId,
} from '../../../../../graphql/queries';
import { getPublicWsw } from '../../../../../graphql/customQueries';

export const WswQueries = createTestCrudMap<Wsw>()((query) => ({
  Get: query.Get<GetWSWTestQueryVariables>(getPublicWsw),
  List: query.List<ListWSWTestsQueryVariables>(listWSWTests),
  Create: query.Mutate<CreateWSWTestMutationVariables>(createWSWTest),
  Update: query.Mutate<UpdateWSWTestMutationVariables>(updateWSWTest),
  Delete: query.Mutate<DeleteWSWTestMutation>(deleteWSWTest),
}));

export const WswResultQueries = createTestResultCrudMap<WswResult>()(
  (query) => ({
    ListByTest:
      query.List<WswResultsByTestIdQueryVariables>(wswResultsByTestId),
    List: query.List<ListWswResultsQueryVariables>(listWswResults),
    Get: query.Get<GetWswResultQueryVariables>(getWswResult),
    Update: query.Mutate<UpdateWswResultMutationVariables>(updateWswResult),
    Delete: query.Mutate<DeleteWswResultMutationVariables>(deleteWswResult),
    Create: query.Mutate<CreateWswResultMutationVariables>(createWswResult),
  }),
);
