import React, { FC } from 'react';
import InitialAmpEditorState, { ampTemplates } from './creator/initialState';
import {
  createAmpAsync,
  deleteAmpAsync,
  deleteAmpResultsAsync,
  fetchAmpResults,
  fetchAmpTest,
  fetchAmpTests,
  updateAmpAsync,
  updateAmpStatusAsync,
} from './redux/amp.thunk';
import AmpGameSettings, {
  GameSettingsSchema,
} from './creator/steps/AmpGameSettings';
import AmpBlockSettings, {
  BlockSettingsSchema,
} from './creator/steps/AmpBlockSettings';
import AmpStimuliSettings, {
  StimuliSettingsSchema,
} from './creator/steps/AmpStimuliSettings';
import { TestType } from '../../subject/types';
import TestAdmin from '../testAdmin/TestAdmin';
import { AmpGameSettingsView } from './view/steps/AmpGameSettingsView';
import { AmpBlockSettingsView } from './view/steps/AmpBlockSettingsView';
import { AmpStimuliSettingsView } from './view/steps/AmpStimuliSettingsView';
import { Amp } from './types';

const AmpAdmin: FC = () => (
  <TestAdmin
    templates={ampTemplates}
    type={TestType.AMP}
    thunks={{
      loadTest: fetchAmpTest,
      loadTests: fetchAmpTests,
      createTest: createAmpAsync,
      updateTest: updateAmpAsync,
      deleteTest: deleteAmpAsync,
      updateTestStatus: updateAmpStatusAsync,
    }}
    results={{
      load: fetchAmpResults,
      deleteItems: deleteAmpResultsAsync,
    }}
    steps={[
      {
        name: 'Test',
        editor: {
          validationSchema: GameSettingsSchema,
          component: AmpGameSettings,
        },
        view: (props) => <AmpGameSettingsView test={props.test as Amp} />,
      },
      {
        name: 'Block',
        editor: {
          validationSchema: BlockSettingsSchema,
          component: AmpBlockSettings,
        },

        view: (props) => <AmpBlockSettingsView test={props.test as Amp} />,
      },
      {
        name: 'Stimuli',
        editor: {
          validationSchema: StimuliSettingsSchema,
          component: AmpStimuliSettings,
        },

        view: (props) => <AmpStimuliSettingsView test={props.test as Amp} />,
      },
    ]}
    editor={{
      initialState: InitialAmpEditorState,
    }}
  />
);

export default AmpAdmin;
