import { PickingSet } from './PickingSet';

export function* newPickN<T>(set: PickingSet<T>, amount: number) {
  for (let i = 0; i < amount; i++) {
    yield set.pick();
  }
  set.validate();
}

export function newPickNCollect<T>(set: PickingSet<T>, amount: number): T[] {
  return Array.from(newPickN(set, amount));
}
