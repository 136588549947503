import React, { FC } from 'react';
import { SvgIconProps } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  color: {
    color: theme.palette.warning.dark,
  },
}));

export const InfoIcon: FC<{ iconProps: SvgIconProps }> = ({ iconProps }) => {
  const classes = useStyles();
  return <InfoOutlined className={classes.color} {...iconProps} />;
};
