import React, { FunctionComponent } from 'react';
import { UiInput } from './types';
import { Box, TextFieldProps, Tooltip, Typography } from '@mui/material';
import StyledTextInputComponent from './StyledTextInput';
import TextStyleDialog from '../TextStyleDialog';
import { Build, InfoOutlined } from '@mui/icons-material';
import { useField } from 'formik';
import { StyleableTextTemplateInput, TextAlignment } from '../../API';
import { createDefaultFontStyle } from '../../features/media/initalMediaItemEditorStates';

type StyleableTextInputProps = UiInput<string> &
  TextFieldProps & {
    toolTip?: string;
    bgcolorField?: string | null;
  };

const StyleableTextInput: FunctionComponent<StyleableTextInputProps> = ({
  name,
  label,
  bgcolorField,
  toolTip,
}) => {
  const [, meta, helpers] = useField<StyleableTextTemplateInput | undefined>(
    name,
  );

  const [, { value: bgcolor }] = useField<string | undefined>(
    bgcolorField || '',
  );

  React.useEffect(() => {
    if (!meta.value?.desktop && meta.value?.fontStyle) {
      helpers.setValue(undefined);
    } else if (meta.value?.desktop && !meta.value?.fontStyle) {
      helpers.setValue({
        desktop: meta.value.desktop,
        fontStyle: createDefaultFontStyle(TextAlignment.CENTER),
      });
    }
  }, [helpers, meta.value?.fontStyle, meta.value?.desktop]);

  return (
    <Box position="relative">
      <StyledTextInputComponent
        name={name + '.desktop'}
        label={label}
        InputProps={{
          endAdornment: (
            <TextStyleDialog label={label} name={name + '.fontStyle'}>
              <Build />
            </TextStyleDialog>
          ),
        }}
      />
      {meta.value?.desktop && (
        <Box
          sx={{
            height: 60,
            justifyContent: 'center',
            border: 1,
            bgcolor,
            borderRadius: '4px',
            borderTop: 'none',
            borderColor: 'rgba(173,167,167,0.6)',
            borderWidth: '0.1px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& button': {
              position: 'absolute',
              top: 0,
              left: 0,
            },
            '& .hidden-text': {
              display: 'none',
            },
            '&:hover .hidden-text': {
              display: 'flex',
            },
          }}
        >
          <Typography
            color="textSecondary"
            variant="caption"
            className="hidden-text"
            sx={{
              position: 'absolute',
              left: 13,
              top: 4,
            }}
          >
            Preview
          </Typography>
          <Typography
            sx={{
              color: meta.value?.fontStyle?.color,
              bgcolor,
            }}
          >
            {meta.value?.desktop}
          </Typography>
        </Box>
      )}
      {toolTip && (
        <Tooltip title={toolTip} placement="left-start">
          <InfoOutlined
            sx={{
              color: 'warning.dark',
              bgcolor: 'white',
              position: 'absolute',
              top: -10,
              right: -10,
            }}
          />
        </Tooltip>
      )}
    </Box>
  );
};

export default StyleableTextInput;
