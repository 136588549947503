import React, { FC } from 'react';
import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { Add, ContentCopy } from '@mui/icons-material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { TestsMetaData, TestType } from '../../../subject/types';
import { BaseTest } from '../../types';

const CreateNewTestButton: FC<{
  testShortName: string;
  templates: BaseTest[];
  onClickTemplate: (template?: BaseTest) => void;
}> = ({ testShortName, templates, onClickTemplate }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        id="new-test-button"
        aria-controls={menuOpen ? 'new-test-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        variant="contained"
        size="small"
        sx={{ ml: 2 }}
        startIcon={<Add />}
        onClick={handleOpenMenu}
      >
        New {testShortName}
      </Button>
      <Menu
        id="new-test-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'new-test-button',
        }}
      >
        <MenuItem
          sx={{ mb: 1 }}
          onClick={() => {
            handleCloseMenu();
            onClickTemplate();
          }}
        >
          <ListItemIcon>
            <Add fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Blank" secondary="Start from scratch" />
        </MenuItem>
        <Typography variant="caption" color="primary" sx={{ pl: 2 }}>
          Templates:
        </Typography>
        <Divider />
        {templates.map((template) => (
          <MenuItem
            key={template.name}
            onClick={() => {
              handleCloseMenu();
              onClickTemplate(template);
            }}
          >
            <ListItemIcon>
              <ContentCopy fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={template.name}
              secondary={template.description}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

interface TestListToolbarProps {
  type: TestType;
  templates: BaseTest[];
  onClickNew: (template?: BaseTest) => void;
}

const TestListToolbar: FC<TestListToolbarProps> = ({
  type,
  templates,
  onClickNew,
}) => {
  return (
    <GridToolbarContainer>
      <CreateNewTestButton
        testShortName={TestsMetaData[type].shortName}
        templates={templates}
        onClickTemplate={onClickNew}
      />
      <GridToolbarFilterButton
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
        placeholder=""
        sx={{ m: 1 }}
      />
      <GridToolbarColumnsButton
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
        placeholder=""
      />
    </GridToolbarContainer>
  );
};

export const TestListToolbarRenderer =
  (
    type: TestType,
    templates: BaseTest[],
    onClickNew: (template?: BaseTest) => void,
  ) =>
  () => (
    <TestListToolbar
      type={type}
      templates={templates}
      onClickNew={onClickNew}
    />
  );
