import React, { FC } from 'react';
import StyledNumberInput from './StyledNumberInput';
import StyledTextInput from './StyledTextInput';

export const BoundaryInput: FC<{ fieldName: string; label: string }> = ({
  fieldName,
  label,
}) => {
  return (
    <StyledTextInput
      size="small"
      name={`${fieldName}.name`}
      label={label}
      InputProps={{
        endAdornment: (
          <StyledNumberInput
            size="small"
            name={`${fieldName}.value`}
            label=""
            variant="standard"
          />
        ),
      }}
    />
  );
};
