import { SelectorNodeMap } from '../selectors/selector-tree';
import { TestScreenScaler } from './scaler/view-scaler';
import { View } from './views/view';
import { ViewDefinition } from './view-definition-collection';

export class TestScreenComposer {
  readonly views: View[];
  readonly mainView: View;

  constructor(views: View[], mainView: View) {
    this.views = views;
    this.mainView = mainView;
    this.mainView.init('main');
  }

  createScreenScaler(selectorMap: SelectorNodeMap) {
    return new TestScreenScaler(this, selectorMap);
  }

  static Create(mainView: ViewDefinition) {
    return new TestScreenComposer(mainView.allViews, mainView.view);
  }
}
