import { View } from '../views/view';
import { ViewScaler } from './view-scaler';

export class ViewScalerOperation {
  constructor(
    public readonly viewScaler: ViewScaler,
    public readonly operation: 'measureMin' | 'measureInner' | 'scale',
  ) {}

  [Symbol.toStringTag]() {
    return this.viewScaler.pathName + '[' + this.operation + ']';
  }

  async perform() {
    await this.viewScaler[this.operation]();
  }

  static of(
    viewScaler: ViewScaler,
    operation: ViewScalerOperation['operation'],
  ) {
    return new ViewScalerOperation(viewScaler, operation);
  }

  static processSizeDependencies(
    view: View,
    viewScalerMap: Map<View, ViewScaler>,
  ): ViewScalerOperation[] {
    return view.grows
      ? [
          ViewScalerOperation.of(viewScalerMap.get(view)!, 'measureInner'),
          ...view.children.flatMap((cv) =>
            ViewScalerOperation.processMinDependencies(cv, viewScalerMap),
          ),
          ViewScalerOperation.of(viewScalerMap.get(view)!, 'scale'),
          ...view.children.flatMap((cv) =>
            ViewScalerOperation.processSizeDependencies(cv, viewScalerMap),
          ),
        ]
      : [
          ViewScalerOperation.of(viewScalerMap.get(view)!, 'measureInner'),
          ViewScalerOperation.of(viewScalerMap.get(view)!, 'scale'),
          ...view.children.flatMap((cv) =>
            ViewScalerOperation.processSizeDependencies(cv, viewScalerMap),
          ),
        ];
  }

  static processMinDependencies(
    view: View,
    viewScalerMap: Map<View, ViewScaler>,
  ): ViewScalerOperation[] {
    return view.grows
      ? [ViewScalerOperation.of(viewScalerMap.get(view)!, 'measureMin')]
      : [
          ...view.children.flatMap((cv) =>
            ViewScalerOperation.processMinDependencies(cv, viewScalerMap),
          ),
          ViewScalerOperation.of(viewScalerMap.get(view)!, 'measureMin'),
        ];
  }
}
