import { createTestCrudMap, createTestResultCrudMap } from '../../../../../GQL';
import { Amp, AmpResult } from '../../types';
import {
  CreateAmpResultMutationVariables,
  CreateAmpTestMutationVariables,
  DeleteAmpResultMutationVariables,
  DeleteAmpTestMutation,
  GetAmpResultQueryVariables,
  GetAmpTestQueryVariables,
  ListAmpResultsQueryVariables,
  ListAmpTestsQueryVariables,
  AmpResultsByTestIdQueryVariables,
  UpdateAmpResultMutationVariables,
  UpdateAmpTestMutationVariables,
} from '../../../../../API';
import {
  createAmpResult,
  createAmpTest,
  deleteAmpResult,
  deleteAmpTest,
  updateAmpResult,
  updateAmpTest,
} from '../../../../../graphql/mutations';
import {
  getAmpResult,
  listAmpResults,
  listAmpTests,
  ampResultsByTestId,
} from '../../../../../graphql/queries';
import { getPublicAmp } from '../../../../../graphql/customQueries';

export const AmpQueries = createTestCrudMap<Amp>()((query) => ({
  Get: query.Get<GetAmpTestQueryVariables>(getPublicAmp),
  List: query.List<ListAmpTestsQueryVariables>(listAmpTests),
  Create: query.Mutate<CreateAmpTestMutationVariables>(createAmpTest),
  Update: query.Mutate<UpdateAmpTestMutationVariables>(updateAmpTest),
  Delete: query.Mutate<DeleteAmpTestMutation>(deleteAmpTest),
}));

export const AmpResultQueries = createTestResultCrudMap<AmpResult>()(
  (query) => ({
    ListByTest:
      query.List<AmpResultsByTestIdQueryVariables>(ampResultsByTestId),
    List: query.List<ListAmpResultsQueryVariables>(listAmpResults),
    Get: query.Get<GetAmpResultQueryVariables>(getAmpResult),
    Update: query.Mutate<UpdateAmpResultMutationVariables>(updateAmpResult),
    Delete: query.Mutate<DeleteAmpResultMutationVariables>(deleteAmpResult),
    Create: query.Mutate<CreateAmpResultMutationVariables>(createAmpResult),
  }),
);
