import {
  RequestTestDownloadLinkMutationVariables,
  TestResultDownloadLinkResponse,
} from '../../../../API';
import { GQLQuery, NewGQL } from '../../../../GQL';
import { TestType } from '../../../subject/types';
import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { requestTestDownloadLink } from '../../../../graphql/mutations';
import { TriggerDownloadButton } from './TriggerDownloadButton';
import { useAuthUserEmail, useIsAdmin } from '../../../../auth/auth.hook';
import { Download, Info, Refresh } from '@mui/icons-material';
import { formatDate } from '../../../../dateUtils';
import Divider from '@mui/material/Divider';
import {
  ResultGeneratorStatus,
  useTestResultSubscription,
} from './TestResultsStatusContext';
import React, { useCallback } from 'react';
import { TextDisplay } from '../../../../components/TextDisplay';

async function downloadResultsFileFromStorage(
  testId: string,
  testType: TestType,
  withDeviceDetails: boolean,
) {
  return NewGQL.DEFAULT_CLIENT.execute(
    GQLQuery.Mutate<
      RequestTestDownloadLinkMutationVariables,
      TestResultDownloadLinkResponse
    >(requestTestDownloadLink).create({
      testId,
      testType,
      withDeviceDetails,
    }),
  ).then((res) => {
    const linkElement = document.createElement('a');
    linkElement.target = '_blank';
    linkElement.href = res.downloadLink;
    linkElement.click();
  });
}

export function DownloadTestResultsWidget({
  testId,
  testType,
}: {
  testId: string;
  testType: TestType;
}) {
  const isAdmin = useIsAdmin();
  const email = useAuthUserEmail();
  const [downloadResultsLoading, setDownloadResultsLoading] =
    React.useState(false);

  const handleDownloadResults = useCallback(
    async (withDetails: boolean) => {
      setDownloadResultsLoading(true);
      await downloadResultsFileFromStorage(testId, testType, withDetails);
      setDownloadResultsLoading(false);
    },
    [testId, testType],
  );

  const [resultGeneratorStatus, setResultGeneratorStatus] =
    React.useState<null | ResultGeneratorStatus>(null);

  const { subscribeTest } = useTestResultSubscription();
  React.useEffect(() => {
    const unsubscribe = subscribeTest(
      testType,
      testId,
      (status: ResultGeneratorStatus) => {
        setResultGeneratorStatus(status);
      },
    );
    return () => {
      unsubscribe();
    };
  }, [subscribeTest, testId, testType]);
  console.log('test status', downloadResultsLoading, resultGeneratorStatus);
  if (downloadResultsLoading || !resultGeneratorStatus) {
    return <CircularProgress size={16} color="inherit" />;
  }

  if (resultGeneratorStatus.status === 'queued') {
    return <Typography>Queued</Typography>;
  }

  if (resultGeneratorStatus.status === 'generating') {
    return (
      <Box textAlign="end">
        <Typography variant="body2">
          Started at {formatDate(resultGeneratorStatus.changed)}
        </Typography>
        <LinearProgress color="inherit" sx={{ marginBottom: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          justifyContent="flex-end"
        >
          <Stack spacing={1}>
            <Box sx={{ whiteSpace: 'noWrap', textAlign: 'end' }}>
              <Typography fontWeight="bold" color="inherit">
                Preparing results
              </Typography>
              <Typography sx={{ whiteSpace: 'noWrap' }}>
                {resultGeneratorStatus.progress.rowsDone} /{' '}
                {resultGeneratorStatus.progress.rowCount}
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'end' }}>
              <Typography fontWeight="bold">Upload</Typography>
              <Typography sx={{ whiteSpace: 'noWrap' }}>
                {(
                  (resultGeneratorStatus?.progress?.uploadProgress ?? 0) /
                  (1024 * 1024)
                ).toFixed(2)}{' '}
                MB
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>
    );
  }

  if (resultGeneratorStatus.status === 'ready') {
    return (
      <Stack spacing={2} textAlign="start" sx={{ width: '100%' }}>
        <Box sx={{ textAlign: 'end' }}>
          <Stack justifyContent="flex-start" spacing={1}>
            <Button
              sx={{ whiteSpace: 'noWrap' }}
              startIcon={
                <Download
                  sx={{ alignSelf: 'flex-start', justifySelf: 'flex-start' }}
                />
              }
              color="inherit"
              onClick={() => handleDownloadResults(false)}
            >
              <Stack sx={{ width: '100%', alignItems: 'flex-start' }}>
                <Typography>Download Results</Typography>
                <Stack sx={{ textAlign: 'left' }} spacing={1}>
                  {resultGeneratorStatus.testStatusEntry?.statistics && (
                    <>
                      <Stack direction="row" spacing={2}>
                        <TextDisplay
                          labelColor="white"
                          valueColor="white"
                          label="Exported at"
                          value={formatDate(resultGeneratorStatus.changed)}
                        />
                        <TextDisplay
                          labelColor="white"
                          valueColor="white"
                          label="Results"
                          value={
                            resultGeneratorStatus.testStatusEntry.statistics
                              .exportedResults
                          }
                        />
                        {isAdmin && (
                          <Tooltip
                            title={`Excluded Duplicates: ${resultGeneratorStatus.testStatusEntry.statistics.processedResultRows - resultGeneratorStatus.testStatusEntry.statistics.exportedResults}`}
                          >
                            <Info fontSize="small" />
                          </Tooltip>
                        )}
                        <TextDisplay
                          label="Test started"
                          labelColor="white"
                          valueColor="white"
                          value={`${
                            resultGeneratorStatus.testStatusEntry.statistics
                              .processedProbandAmount
                          }`}
                        />
                        <TextDisplay
                          label="finished"
                          labelColor="white"
                          valueColor="white"
                          value={`${
                            resultGeneratorStatus.testStatusEntry.statistics
                              .finishedProbandAmount
                          }`}
                        />
                      </Stack>
                    </>
                  )}
                </Stack>
              </Stack>
            </Button>
            {isAdmin && (
              <Button
                sx={{ whiteSpace: 'noWrap' }}
                startIcon={<Download sx={{ alignSelf: 'flex-start' }} />}
                color="inherit"
                onClick={() => handleDownloadResults(true)}
              >
                <Stack alignItems="flex-start" sx={{ width: '100%' }}>
                  <Typography variant="body1">Download</Typography>
                  <Typography variant="caption">
                    results with device details
                  </Typography>
                </Stack>
              </Button>
            )}
          </Stack>
        </Box>
        <Divider sx={{ background: 'white' }} />
        <TriggerDownloadButton
          email={email}
          testType={testType}
          testId={testId}
          icon={<Refresh />}
        >
          Prepare new results
        </TriggerDownloadButton>
      </Stack>
    );
  }
  if (resultGeneratorStatus.status === 'failed') {
    return (
      <Stack>
        <Typography color="error">Failed to generate results</Typography>
        <Typography color="error">{resultGeneratorStatus.error}</Typography>
        <TriggerDownloadButton
          email={email}
          testType={testType}
          testId={testId}
          icon={<Refresh />}
        >
          Retry
        </TriggerDownloadButton>
      </Stack>
    );
  }
  return (
    <Stack>
      <Typography>No results have been generated yet</Typography>
      <TriggerDownloadButton
        email={email}
        testType={testType}
        testId={testId}
        icon={<Refresh />}
      >
        Prepare new results
      </TriggerDownloadButton>
    </Stack>
  );
}
