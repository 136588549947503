import { TestType } from '../../../../subject/types';
import { useTestAdminNav } from '../../utils';
import {
  Avatar,
  Box,
  Button,
  DialogContentText,
  Stack,
  Typography,
} from '@mui/material';
import { ArrowBack, Check, Error } from '@mui/icons-material';
import React from 'react';
import { TestStep } from '../../testEditor/types';

type TestErrorsProps = {
  testId: string;
  errors: boolean[];
  testType: TestType;
  steps: TestStep[];
};

export const TestErrors = ({
  testId,
  errors,
  steps,
  testType,
}: TestErrorsProps) => {
  const { navigateToEditor } = useTestAdminNav(testType);

  return (
    <React.Fragment>
      <DialogContentText>
        Publishing not possible yet, because the following steps have errors.
      </DialogContentText>
      <Stack spacing={2} sx={{ pt: 2 }}>
        {steps.map((s, stepIndex) => (
          <Box width="100%" display="flex">
            <Typography
              sx={{
                width: '20%',
                flexShrink: 0,
                display: 'inline-flex',
                alignItems: 'center',
              }}
            >
              <Avatar
                sx={{
                  height: 24,
                  width: 24,
                  bgcolor: 'primary.main',
                  mr: 2,
                }}
              >
                {stepIndex + 1}
              </Avatar>
              {s.name}
            </Typography>
            {errors[stepIndex] ? (
              <Box display="flex" alignItems="center">
                <Error color="error" />
                <Button
                  startIcon={<ArrowBack />}
                  sx={{ ml: 3 }}
                  size="small"
                  onClick={() => navigateToEditor('edit', testId)}
                >
                  Fix Error
                </Button>
              </Box>
            ) : (
              <Check color="success" />
            )}
          </Box>
        ))}
      </Stack>
    </React.Fragment>
  );
};
