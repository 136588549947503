import { ViewBoxes } from '../../../subject/testRunner/screens/layouting-view';
import { AMPSelectorTree } from './amp-selectors';
import { StimulusCanvasStrategy } from '../../../subject/testRunner/screens/composer/scaler/canvas-strategy';
import { AmpStructure } from '../loader/structure/amp-structure';
import React from 'react';
import { TestInstructionsView } from '../../../subject/testRunner/screens/basic-views/test-instructions-view';
import { OptionsStereotypes } from '../../../subject/testRunner/controls/control-stereotypes';
import { createOptionTipsContainerViewWrapper } from '../../../subject/testRunner/screens/basic-views/option-test-views';
import { TestScreenComposer } from '../../../subject/testRunner/screens/composer/test-screen-composer';
import { ViewDefinitionCollection } from '../../../subject/testRunner/screens/composer/view-definition-collection';
import { CanvasContentView } from '../../../subject/testRunner/screens/composer/views/canvas-content-view';
import { LayoutingView } from '../../../subject/testRunner/screens/composer/views/layouting-view';

function createOptionsContentView(structure: AmpStructure) {
  return LayoutingView.growing({
    selector: AMPSelectorTree.rootSelector.options,
    layout: {
      subComponents: ViewDefinitionCollection.Create({
        stimulus: CanvasContentView.growing(
          AMPSelectorTree.rootSelector.options.stimulus,
          new StimulusCanvasStrategy(),
        ),
        instructions: TestInstructionsView({
          delay:
            structure.accessor.testContext.deviceInfo.mode === 'mobile' ? 3 : 0,
          selector: AMPSelectorTree.rootSelector.options.instructions,
          control:
            structure.accessor.testContext.deviceInfo.mode === 'mobile'
              ? (OptionsStereotypes.Confirm.Control as any)
              : undefined,
        }),
      }),
      boxes: ViewBoxes.Define(
        (builder) =>
          ({
            content: builder.growing('stimulus', 'instructions'),
          }) as const,
      ),
    } as const,
    contentComponent: (containerProvider, boxProvider) => {
      return () => {
        return containerProvider.createContainer(
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ width: '100%', flexGrow: 1 }}>
              {boxProvider.createBox('content')}
            </div>
          </div>,
        );
      };
    },
  });
}

export function createAmpViewMap(structure: AmpStructure) {
  return TestScreenComposer.Create(
    LayoutingView.growing({
      selector: AMPSelectorTree.rootSelector,
      layout: {
        subComponents: ViewDefinitionCollection.Create({
          instructions: TestInstructionsView({
            selector: AMPSelectorTree.rootSelector.instructions,
            control: OptionsStereotypes.Confirm.UI,
          }),
          blockInstructions: TestInstructionsView({
            delay:
              structure.accessor.testContext.deviceInfo.mode === 'mobile'
                ? 3
                : 0,
            selector: AMPSelectorTree.rootSelector.blockInstructions,
            control:
              structure.accessor.testContext.deviceInfo.mode === 'mobile'
                ? (OptionsStereotypes.Confirm.Control as any)
                : undefined,
          }),
          options: createOptionTipsContainerViewWrapper(
            structure.accessor.testContext,
            structure.options,
            AMPSelectorTree.rootSelector.options,
            () => createOptionsContentView(structure),
          ),
        }),
        boxes: ViewBoxes.Define((builder) => ({
          content: builder.growing(
            'instructions',
            'options',
            'blockInstructions',
          ),
        })),
      } as const,
      contentComponent: (containerProvider, boxProvider) => {
        return () => {
          return containerProvider.createContainer(
            <div
              style={{
                width:
                  structure.accessor.testContext.deviceInfo.mode === 'mobile'
                    ? '100%'
                    : structure.accessor.modelData.style.maxWidth + '%',
                height: '100%',
                backgroundColor:
                  structure.accessor.modelData.style.backgroundColour ??
                  'white',
              }}
            >
              {boxProvider.createBox('content')}
            </div>,
            (_, style) => ({
              ...style,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
                structure.accessor.modelData.style.backgroundColour ?? 'white',
            }),
          );
        };
      },
    }),
  );
}
