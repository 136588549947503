import React, { FC } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  createFilterOptions,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import { CreateTagInput } from '../../API';
import { AllInclusive, InfoOutlined } from '@mui/icons-material';
import { Tag } from './types';
import StyledIconButton from '../../components/input/StyledIconButton';
import { useCreateTagMutation, useGetTagsQuery } from './redux/mediaApi';

const filterOptions = createFilterOptions<Tag>({});

interface TagAutoCompleteProps {
  applyOne: (tag: Tag) => void;
  applyAll: (tags: Tag[]) => void;
}

export const TagAutoComplete: FC<TagAutoCompleteProps> = ({
  applyOne,
  applyAll,
}) => {
  const { data, isFetching, isLoading } = useGetTagsQuery();

  const [createTag, { isLoading: isUpdating }] = useCreateTagMutation();

  const allTags: Tag[] = data ?? [];

  const [inputValue, setInputValue] = React.useState('');

  const [selectedValues, setSelectedValue] = React.useState<Tag[]>([]);

  const createNewTag = (args: CreateTagInput) => {
    createTag(args).then((res) => {
      setSelectedValue([...selectedValues, (res as { data: Tag }).data]);
    });
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Autocomplete
        loading={isUpdating || isLoading || isFetching}
        multiple
        value={selectedValues}
        renderInput={(params) => (
          <Box sx={{ position: 'relative' }}>
            <TextField
              {...params}
              label="Chose Tags"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                style: { overflow: 'visible' },
                endAdornment: (
                  <React.Fragment>
                    {inputValue.trim() !== '' &&
                      allTags.every(
                        (t) =>
                          t.name.toLowerCase() !==
                          inputValue.trim().toLowerCase(),
                      ) && (
                        <Button
                          size="small"
                          disabled={isUpdating || isLoading || isFetching}
                          color="primary"
                          variant="contained"
                          style={{ textTransform: 'none' }}
                          onClick={() => createNewTag({ name: inputValue })}
                        >
                          Add {inputValue}
                        </Button>
                      )}
                    {isUpdating || isLoading || isFetching ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
            <Tooltip
              title={'Only create english tag names please.'}
              placement="left-start"
            >
              <InfoOutlined
                sx={{
                  color: 'warning.dark',
                  bgcolor: 'white',
                  position: 'absolute',
                  top: -10,
                  right: -10,
                }}
              />
            </Tooltip>
          </Box>
        )}
        options={allTags}
        renderTags={(value: Tag[], getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={
                <React.Fragment>
                  {option.name}
                  <IconButton
                    size="small"
                    style={{ marginLeft: 8 }}
                    onClick={() => applyOne(option as Tag)}
                  >
                    <AllInclusive fontSize="inherit" />
                  </IconButton>
                </React.Fragment>
              }
              {...getTagProps({ index })}
            />
          ))
        }
        getOptionLabel={(o: Tag) => o.name || 'not found'}
        style={{ width: 300 }}
        inputValue={inputValue}
        onInputChange={(_, v) => {
          setInputValue(v);
        }}
        onChange={(_, values) => {
          setSelectedValue(values as Tag[]);
        }}
        isOptionEqualToValue={(o, v) => o.name === v.name}
        filterOptions={filterOptions}
      />
      {selectedValues.length > 0 && (
        <StyledIconButton
          label="Apply to selection"
          onClick={() => applyAll(selectedValues)}
        >
          <AllInclusive />
        </StyledIconButton>
      )}
    </div>
  );
};
