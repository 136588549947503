import React, { FC } from 'react';
import { Podt } from '../../types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { TextDisplay } from '../../../../../components/TextDisplay';
import MediaItemComponent from '../../../../media/MediaItemComponent';
import { ExpandMore } from '@mui/icons-material';
import MediaItemList from '../../../../media/MediaItemList';

export const PodtStimuliSettingsView: FC<{ test: Podt }> = ({ test }) => {
  const isPodta = test.__typename !== 'PodtTest';
  return (
    <Stack spacing={3}>
      <TextDisplay
        label="Probability Without Replacement"
        value={test.probabilityWithoutReplacement ? 'Yes' : 'No'}
      />
      {test.fixation ? (
        <MediaItemComponent
          label={`Fixation (${test.fixation.interval}ms)`}
          mediaItem={test.fixation.mediaItem}
        />
      ) : (
        <TextDisplay
          label="Fixation"
          value="Fixation not enabled"
          valueProps={{ sx: { fontStyle: 'italic' } }}
        />
      )}
      <Stack direction="row" spacing={3}>
        <TextDisplay
          label="Response Timeout"
          value={test.response.timeout}
          suffix=" ms"
        />
        <TextDisplay
          label="Response Categories Position"
          value={test.style.optionsPosition}
        />
      </Stack>
      <Stack direction="row" spacing={3}>
        <MediaItemComponent
          label={`Response Category A (${isPodta ? 'Left' : 'Weapon'})`}
          mediaItem={test.response.active}
        />
        <MediaItemComponent
          label={`Response Category B (${isPodta ? 'Right' : 'Object'})`}
          mediaItem={test.response.passive}
        />
      </Stack>

      <Box>
        <Typography color="textSecondary">Scenes</Typography>
        <MediaItemList items={test.scenes.scenes.map((s) => s.mediaItem)} />
      </Box>
      <Stack direction="row" spacing={3}>
        <TextDisplay
          label="max # Scenes per Trial"
          value={test.scenes.maxAmountScenesPerTrial}
        />
        <TextDisplay
          label="Min Scene Timeout"
          value={test.scenes.intervalConfig.min}
        />
        <TextDisplay
          label="Max Scene Timeout"
          value={test.scenes.intervalConfig.max}
        />
        <TextDisplay
          label="Stepsize"
          value={test.scenes.intervalConfig.stepSize}
        />
      </Stack>
      <Typography variant={'h5'} color="textSecondary">
        Targets
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {test.targets.map((target, targetIndex) => (
          <Box key={`target-${targetIndex}`} width="49%" mx={1}>
            <Accordion
              defaultExpanded
              // color={error && error.length > 0 ? "red": "white"}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography color="textSecondary">{`Target ${
                    targetIndex + 1
                  }`}</Typography>
                  <Typography variant="h5">{target.label}</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Typography color="textSecondary">Practice Pools</Typography>
                <Typography variant="body2" color="textSecondary">
                  Armed Stimuli Set
                </Typography>
                <MediaItemList
                  items={target.practiceStimuliPool.dangerousStimuli}
                />
                <Typography variant="body2" color="textSecondary">
                  Unarmed Stimuli Set
                </Typography>
                <MediaItemList
                  items={target.practiceStimuliPool.nonDangerousStimuli}
                />
                <Typography color="textSecondary">Test Pools</Typography>
                <Typography variant="body2" color="textSecondary">
                  Armed Stimuli Set
                </Typography>
                <MediaItemList
                  items={target.testStimuliPool.dangerousStimuli}
                />
                <Typography variant="body2" color="textSecondary">
                  Unarmed Stimuli Set
                </Typography>
                <MediaItemList
                  items={target.testStimuliPool.nonDangerousStimuli}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Box>
    </Stack>
  );
};
