import { TranslationKeyValueTypeMap } from './translation-value';
import { LanguageCode, StaticTranslationKey } from './translation-key';
import {
  StaticTranslationScope,
  TranslationScopeMap,
} from './translation-scope';

export type TranslationKeyDescriptor<
  V extends keyof TranslationKeyValueTypeMap,
> = {
  valueType: V;
  defaults: Record<LanguageCode, TranslationKeyValueTypeMap[V]>;
  name: string;
  toTranslationKey(key: string): StaticTranslationKey<V>;
};

export type TranslationRecordDescriptor<
  M extends Record<
    string,
    TranslationKeyDescriptor<any> | TranslationRecordDescriptor<any>
  >,
> = {
  name: string;
  description: string;
  translations: M;
  toScope(
    prefix: string,
    key: string,
  ): TranslationScopeMap<{
    [k in keyof M]: M[k] extends TranslationRecordDescriptor<infer SM>
      ? ReturnType<TranslationRecordDescriptor<SM>['toScope']>
      : M[k] extends TranslationKeyDescriptor<infer SV>
        ? StaticTranslationKey<SV>
        : never;
  }>;
};

function scope<
  M extends Record<
    string,
    TranslationKeyDescriptor<any> | TranslationRecordDescriptor<any>
  >,
>(name: string, childs: M): TranslationRecordDescriptor<M> {
  return {
    name,
    description: name,
    translations: childs,
    toScope(prefix: string, key: string) {
      const scope = StaticTranslationScope.define(prefix, key, name);
      const translations = {} as any;
      for (const key in childs) {
        const child = childs[key];
        if ('toTranslationKey' in child) {
          translations[key] = child.toTranslationKey(scope.path + '.' + key);
        } else {
          translations[key] = child.toScope(scope.path, key);
        }
      }
      return { scope, ...translations };
    },
  };
}

function key<V extends keyof TranslationKeyValueTypeMap>(
  valueType: V,
  name: string,
  defaults: Record<LanguageCode, TranslationKeyValueTypeMap[V]>,
): TranslationKeyDescriptor<V> {
  return {
    valueType,
    name,
    defaults,
    toTranslationKey(key: string) {
      return new StaticTranslationKey(key, valueType, defaults, name);
    },
  };
}

function text(name: string, defaults: Record<LanguageCode, string>) {
  return key('text', name, defaults);
}

function textList(name: string, defaults: Record<LanguageCode, string[]>) {
  return key('textArray', name, defaults);
}

export const translations = {
  scope,
  key,
  text,
  textList,
};
