import {
  BlockType,
  MediaItemSnapshotScope,
  PodtPreparationScreenInput,
  PodtTargetCategoryInput,
  TestStatus,
  TextAlignment,
} from '../../../../API';
import { PodtaCreatorState } from './types';
import {
  createDefaultFontStyle,
  createDefaultStyle,
  createEmptyDefaultFixation,
  createEmptyKeyboardSettings,
  createEmptyMediaItemSnapshot,
  createWeightedStyledTextInput,
} from '../../testAdmin/testEditor/initialEditorStates';
import { Podta } from '../types';

const createEmptyLabeledStimuliPool = (): PodtTargetCategoryInput => ({
  label: '',
  practiceStimuliPool: {
    dangerousStimuli: [],
    nonDangerousStimuli: [],
  },
  testStimuliPool: {
    dangerousStimuli: [],
    nonDangerousStimuli: [],
  },
});

export const getDefaultPreparationScreen = (): PodtPreparationScreenInput => ({
  screen: { desktop: '' },
  interval: 500,
});

const template1: Podta = {
  id: 'template',
  name: 'Avoidance task with Arab and White targets Bilendi 01-2023',
  description:
    'Avoidance task with Arab and White targets (Stimuli Essien et al, 2017)',
  instructions: {
    desktop:
      '<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;">&nbsp;</p>\n<p></p>\n<p style="text-align:center;"><span style="background-color: rgb(255,255,255);font-size: 18px;font-family: Mulish;">Nun wollen wir Sie bitten, an einem kognitiven Test  teilzunehmen.</span></p>\n<p style="text-align:center;"><span style="background-color: rgb(255,255,255);font-size: 18px;font-family: Mulish;">Auf der nächsten Seite wird Ihnen erläutert, worum es bei dem Test im Detail geht.</span><br><span style="font-size: 18px;font-family: Mulish;">Zum Starten drücken Sie bitte auf <strong>Start</strong>.  </span></p>\n',
    mobile:
      '<p style="text-align:center;"><span style="background-color: rgb(255,255,255);font-size: 14px;font-family: Mulish;">Nun wollen wir Sie bitten, an einem kognitiven Test  teilzunehmen.</span></p>\n<p style="text-align:center;"><span style="background-color: rgb(255,255,255);font-size: 14px;font-family: Mulish;">Auf der nächsten Seite wird Ihnen erläutert, worum es bei dem Test im Detail geht.</span></p>\n',
    fontStyle: null,
  },
  keyboard: {
    left: 'KeyA',
    right: 'KeyL',
    confirm: 'Space',
  },
  counterbalancing: true,
  probabilityWithoutReplacement: true,
  interTrialInterval: 2000,
  style: {
    maxWidth: 80,
    backgroundColour: 'white',
    stimulusTextStyle: {
      fontSize: 40,
      color: 'black',
      alignment: 'CENTER',
      fontFace: 'ARIAL',
    },
    mobileStimulusTextStyle: null,
    optionsPosition: 'MIDDLE',
    optionsPositionMobile: 'MIDDLE',
  },
  status: 'LIVE',
  endScreen: {
    desktop:
      '<p></p>\n<p></p>\n<p><br></p>\n<p></p>\n<p><br></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;"> Damit ist der kognitive Test beendet.</span></p>\n<p style="text-align:center;"><span style="color: black;background-color: white;font-size: 18px;font-family: Mulish;">Es folgen nun noch einige weitere Fragen zu verschiedenen Themen.</span> <br><span style="color: black;background-color: white;font-size: 18px;font-family: Mulish;">Drücken Sie dafür bitte auf <strong>Weiter</strong>.</span>&nbsp;</p>\n',
    mobile:
      '<p></p>\n<p style="text-align:center;"><span style="font-size: 14px;font-family: Mulish;"> Damit ist der kognitive Test beendet.</span></p>\n<p style="text-align:center;"><br><span style="color: black;background-color: white;font-size: 14px;font-family: Mulish;">Es folgen nun noch einige weitere Fragen zu verschiedenen Themen.</span> <br><span style="color: black;background-color: white;font-size: 14px;font-family: Mulish;">Drücken Sie dafür bitte auf <strong>Weiter</strong>.</span>&nbsp;</p>\n',
    fontStyle: null,
  },
  response: {
    timeout: 1000,
    fontStyle: {
      fontSize: 30,
      color: 'black',
      alignment: 'CENTER',
      fontFace: 'ARIAL',
    },
    active: {
      id: 'f4493000-fec4-457d-b301-28b54261a56b',
      tags: [],
      text: 'LINKS',
      scope: MediaItemSnapshotScope.PUBLIC,
      image: null,
      originalFileName: null,
    },
    passive: {
      id: 'ed1bc516-d9b9-4188-9677-501bdedced93',
      tags: [],
      text: 'RECHTS',
      scope: MediaItemSnapshotScope.PUBLIC,
      image: null,
      originalFileName: null,
    },
  },
  scenes: {
    maxAmountScenesPerTrial: 4,
    scenes: [
      {
        mediaItem: {
          id: 'a49645b4-ef88-4f72-a13b-d3233ba4841c',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/d1b749d3-9618-494d-b34d-48b2994f845a.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/d1b749d3-9618-494d-b34d-48b2994f845a.jpg',
            },
          },
          originalFileName: 'Hintergrund03.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
      {
        mediaItem: {
          id: 'ba6680c3-9af9-4ab3-aee1-e01edf0128e7',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/be3f7825-f6c8-4ec6-9e3d-8eb9bdb4d3f3.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/be3f7825-f6c8-4ec6-9e3d-8eb9bdb4d3f3.jpg',
            },
          },
          originalFileName: 'Hintergrund15.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
      {
        mediaItem: {
          id: 'ddca9999-68ec-4e33-a7a4-e5535bda159d',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/8a64fc49-e16e-4d34-aa8e-109b6eaf7068.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/8a64fc49-e16e-4d34-aa8e-109b6eaf7068.jpg',
            },
          },
          originalFileName: 'Hintergrund04.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
      {
        mediaItem: {
          id: '5c93dbaa-b9fe-492c-be1f-790215b39dff',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/ad00cb0b-325d-415b-b278-26abce0556fd.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/ad00cb0b-325d-415b-b278-26abce0556fd.jpg',
            },
          },
          originalFileName: 'Hintergrund07.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
      {
        mediaItem: {
          id: 'e138f44a-8a6f-4143-a34a-2ca32e693258',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/3f3a23ee-ae01-4f71-bf83-7b907441afee.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/3f3a23ee-ae01-4f71-bf83-7b907441afee.jpg',
            },
          },
          originalFileName: 'Hintergrund01.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
      {
        mediaItem: {
          id: '24ad8c8a-d886-4411-a985-a5dd63e1af18',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/b7af4d5e-a63f-4d58-b551-06f533a0d312.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/b7af4d5e-a63f-4d58-b551-06f533a0d312.jpg',
            },
          },
          originalFileName: 'Hintergrund17.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
      {
        mediaItem: {
          id: '56e47927-e078-4fb5-a044-7c1733e1994a',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/304eec3c-7181-45c9-9080-03a8646033d0.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/304eec3c-7181-45c9-9080-03a8646033d0.jpg',
            },
          },
          originalFileName: 'Hintergrund14.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
      {
        mediaItem: {
          id: '498eb2b3-d8b2-4220-ba12-d26bd26ff3f1',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/3673a701-9e27-49f4-b259-f9ba4a6ea648.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/3673a701-9e27-49f4-b259-f9ba4a6ea648.jpg',
            },
          },
          originalFileName: 'Hintergrund09.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
      {
        mediaItem: {
          id: '8f1516dd-0bc8-4e85-818c-f23ea4a6cc96',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/0ba7fa71-c174-47ab-818b-cca7c22b0481.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/0ba7fa71-c174-47ab-818b-cca7c22b0481.jpg',
            },
          },
          originalFileName: 'Hintergrund02.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
      {
        mediaItem: {
          id: 'bc3de59c-78ab-4422-97b9-7032b4126eba',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/a41eaa4c-da55-40eb-95d6-2ece9e070830.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/a41eaa4c-da55-40eb-95d6-2ece9e070830.jpg',
            },
          },
          originalFileName: 'Hintergrund11.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
      {
        mediaItem: {
          id: '9b8162f9-c9b1-401c-acee-b9141ba698be',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/5a030a08-5bb7-4b2c-84cb-7b073b567469.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/5a030a08-5bb7-4b2c-84cb-7b073b567469.jpg',
            },
          },
          originalFileName: 'Hintergrund05.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
      {
        mediaItem: {
          id: '419b91fd-759d-4a37-9116-258e869a508a',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/26fc0993-3dfb-4f23-b77c-873894c36b2d.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/26fc0993-3dfb-4f23-b77c-873894c36b2d.jpg',
            },
          },
          originalFileName: 'Hintergrund19.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
      {
        mediaItem: {
          id: '31ada219-b85f-4c70-afdc-bc76dc1af642',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/9c17465f-b7e4-485c-911a-ee4448d49cc5.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/9c17465f-b7e4-485c-911a-ee4448d49cc5.jpg',
            },
          },
          originalFileName: 'Hintergrund18.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
      {
        mediaItem: {
          id: 'e6817241-559d-4ace-9f3c-5d7b2006318c',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/00957017-c6a3-4a7c-9b31-5c8356bbf57b.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/00957017-c6a3-4a7c-9b31-5c8356bbf57b.jpg',
            },
          },
          originalFileName: 'Hintergrund10.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
      {
        mediaItem: {
          id: 'ef6db9ad-4f38-4749-9de1-493c2007d784',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/7e9780d8-478e-4522-aa5b-4f5e985474d7.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/7e9780d8-478e-4522-aa5b-4f5e985474d7.jpg',
            },
          },
          originalFileName: 'Hintergrund16.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
      {
        mediaItem: {
          id: 'e69ce746-e20d-45be-a980-0f6e4fb4b79e',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/4a036c97-79e3-4dcd-aa4b-fa5f63e6f763.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/4a036c97-79e3-4dcd-aa4b-fa5f63e6f763.jpg',
            },
          },
          originalFileName: 'Hintergrund20.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
      {
        mediaItem: {
          id: '7e927556-2187-47b9-bbe4-8d14c8f1a5ad',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/80f3534d-0580-4b1d-98c8-cbd376ea39e0.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/80f3534d-0580-4b1d-98c8-cbd376ea39e0.jpg',
            },
          },
          originalFileName: 'Hintergrund08.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
      {
        mediaItem: {
          id: '033d58c4-a061-4f13-9818-726ac92d5e1d',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/ac5f64ed-88b9-4151-9668-d56bc2ce4f87.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/ac5f64ed-88b9-4151-9668-d56bc2ce4f87.jpg',
            },
          },
          originalFileName: 'Hintergrund06.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
      {
        mediaItem: {
          id: 'cd5feba2-ecaa-4c23-9d20-26d658695b96',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/77024d8b-99fc-4eeb-8c3f-1b130494711e.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/77024d8b-99fc-4eeb-8c3f-1b130494711e.jpg',
            },
          },
          originalFileName: 'Hintergrund13.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
      {
        mediaItem: {
          id: '749cb97c-a10d-4668-b603-925c5a606eb5',
          tags: [],
          text: null,
          scope: MediaItemSnapshotScope.PUBLIC,
          image: {
            fullSize: {
              width: 1284,
              height: 658,
              key: 'fullSize/6013ba94-24f5-446a-b216-5b539539baf5.jpg',
            },
            thumbNail: {
              width: 80,
              height: 40,
              key: 'thumbNail/6013ba94-24f5-446a-b216-5b539539baf5.jpg',
            },
          },
          originalFileName: 'Hintergrund12.jpg',
        },
        boxes: [
          {
            top: 108,
            left: 105,
            width: 350,
            height: 550,
            position: 'LEFT',
          },
          {
            top: 108,
            left: 824,
            width: 350,
            height: 550,
            position: 'RIGHT',
          },
        ],
      },
    ],
    intervalConfig: {
      min: 500,
      max: 1000,
      stepSize: 100,
      __typename: 'PodtSceneIntervalConfig',
    },
  },
  targets: [
    {
      label: 'White',
      practiceStimuliPool: {
        dangerousStimuli: [
          {
            id: '79609db0-1ce3-4266-a188-1287fd521ad5',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
              {
                id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                name: 'practise',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 251,
                height: 800,
                key: 'fullSize/9b57db92-c236-494d-a5fb-ee4db2c5657a.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/9b57db92-c236-494d-a5fb-ee4db2c5657a.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_08.png',
          },
          {
            id: '0aea307f-fe6c-4f07-9b33-17ff5d79864e',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
              {
                id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                name: 'practise',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 251,
                height: 800,
                key: 'fullSize/b9d90749-f7ee-43c3-a6f8-233d22c410c8.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/b9d90749-f7ee-43c3-a6f8-233d22c410c8.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_02.png',
          },
        ],
        nonDangerousStimuli: [
          {
            id: 'f6bedad1-8d5a-4be8-8c2f-8ebb04666f32',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
              {
                id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                name: 'practise',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 251,
                height: 800,
                key: 'fullSize/ff144fc9-fa10-425b-97df-5e8ce6778866.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/ff144fc9-fa10-425b-97df-5e8ce6778866.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_08.png',
          },
          {
            id: 'd0de2b56-f0cd-42ec-9bdd-5fb82dbba350',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
              {
                id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                name: 'practise',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 251,
                height: 800,
                key: 'fullSize/8da8c4f5-5b52-4534-abae-da73caa6ad66.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/8da8c4f5-5b52-4534-abae-da73caa6ad66.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_02.png',
          },
        ],
      },
      testStimuliPool: {
        dangerousStimuli: [
          {
            id: '526cb20e-0d32-4f72-8e12-1f8e6c914ad4',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 265,
                height: 800,
                key: 'fullSize/0350668d-4d2c-4542-bf1e-1b299a8f7c3d.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/0350668d-4d2c-4542-bf1e-1b299a8f7c3d.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_17.png',
          },
          {
            id: '6e07aed9-0b18-4143-ad4a-2e0a15ac3799',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 247,
                height: 800,
                key: 'fullSize/1871a4a4-90e6-4fd3-86b2-708009df2849.png',
              },
              thumbNail: {
                width: 24,
                height: 80,
                key: 'thumbNail/1871a4a4-90e6-4fd3-86b2-708009df2849.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_07.png',
          },
          {
            id: 'e352e51e-d924-49c6-a7ed-4dbc9713cf4a',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 250,
                height: 800,
                key: 'fullSize/bd7cebea-7a32-499c-8e2d-f9b32d684224.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/bd7cebea-7a32-499c-8e2d-f9b32d684224.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_06.png',
          },
          {
            id: '19fa199d-8a9e-4706-999d-6d1838bc0fba',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 261,
                height: 800,
                key: 'fullSize/b6006b1c-0bd6-4452-9d84-3defd8a29a24.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/b6006b1c-0bd6-4452-9d84-3defd8a29a24.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_04.png',
          },
          {
            id: '1164267c-3731-467a-b3b9-74daba0053b0',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 255,
                height: 800,
                key: 'fullSize/c1d2b2e9-519d-482f-a77d-3a539a2287e8.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/c1d2b2e9-519d-482f-a77d-3a539a2287e8.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_03.png',
          },
          {
            id: 'baf13542-be61-467b-8981-ccb1eea00909',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 264,
                height: 800,
                key: 'fullSize/765cc2bd-df3e-4379-802e-b9f63e4a052a.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/765cc2bd-df3e-4379-802e-b9f63e4a052a.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_15.png',
          },
          {
            id: '262a0712-3d96-444b-9b92-036825bf8079',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 301,
                height: 800,
                key: 'fullSize/64fde2a2-737a-4497-aadd-587c04f0cb21.png',
              },
              thumbNail: {
                width: 30,
                height: 80,
                key: 'thumbNail/64fde2a2-737a-4497-aadd-587c04f0cb21.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_22.png',
          },
          {
            id: '3b364118-dd67-49e5-8cc1-f92b928e3bb2',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 254,
                height: 800,
                key: 'fullSize/3778dd76-4b40-41f9-b419-b52d2c23de1b.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/3778dd76-4b40-41f9-b419-b52d2c23de1b.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_25.png',
          },
          {
            id: '6b943cf8-2498-43ae-a5b6-8e13e126a350',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 241,
                height: 800,
                key: 'fullSize/93d82089-f631-4303-a76c-11ac6e07a3c2.png',
              },
              thumbNail: {
                width: 24,
                height: 80,
                key: 'thumbNail/93d82089-f631-4303-a76c-11ac6e07a3c2.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_13.png',
          },
          {
            id: 'd160fbcd-c7d5-4d79-bb3d-a407eac6c871',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 243,
                height: 800,
                key: 'fullSize/3a6fd73e-e22e-4b01-8f2c-71d53e167fff.png',
              },
              thumbNail: {
                width: 24,
                height: 80,
                key: 'thumbNail/3a6fd73e-e22e-4b01-8f2c-71d53e167fff.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_09.png',
          },
          {
            id: '3e5301a6-9655-4543-8416-ef421ac63f7d',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 290,
                height: 800,
                key: 'fullSize/d4076d25-e082-46fd-a578-f5b636ff9235.png',
              },
              thumbNail: {
                width: 29,
                height: 80,
                key: 'thumbNail/d4076d25-e082-46fd-a578-f5b636ff9235.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_05.png',
          },
          {
            id: 'b7dce6f3-cde7-414e-8efe-03e53315a39c',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 248,
                height: 800,
                key: 'fullSize/e3d75bd2-f125-49dc-96f4-4c96ba626c73.png',
              },
              thumbNail: {
                width: 24,
                height: 80,
                key: 'thumbNail/e3d75bd2-f125-49dc-96f4-4c96ba626c73.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_10.png',
          },
          {
            id: '5791ec6f-3718-43df-ab20-7cf6680b77bd',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 281,
                height: 800,
                key: 'fullSize/d3b6e490-0151-4b76-b0c5-8f0a8bafed9d.png',
              },
              thumbNail: {
                width: 28,
                height: 80,
                key: 'thumbNail/d3b6e490-0151-4b76-b0c5-8f0a8bafed9d.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_18.png',
          },
          {
            id: '61261a25-272f-43de-a687-ba0961ed2a8e',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 294,
                height: 800,
                key: 'fullSize/c8263524-ab7a-4068-a9a5-27419db7eada.png',
              },
              thumbNail: {
                width: 29,
                height: 80,
                key: 'thumbNail/c8263524-ab7a-4068-a9a5-27419db7eada.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_21.png',
          },
          {
            id: '6a349005-bba7-49a9-abc0-2329e91f63ca',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 242,
                height: 800,
                key: 'fullSize/8e64675c-8ca9-4ce9-95f8-7db1a6c2f513.png',
              },
              thumbNail: {
                width: 24,
                height: 80,
                key: 'thumbNail/8e64675c-8ca9-4ce9-95f8-7db1a6c2f513.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_14.png',
          },
          {
            id: '37ca8a8a-29a7-4b20-ba05-cb2199fdc22c',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 239,
                height: 800,
                key: 'fullSize/b5e9f9de-4d55-4cca-b146-b31fee038276.png',
              },
              thumbNail: {
                width: 23,
                height: 80,
                key: 'thumbNail/b5e9f9de-4d55-4cca-b146-b31fee038276.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_24.png',
          },
          {
            id: 'dac45084-c231-4427-98d1-60f31f171f4d',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 246,
                height: 800,
                key: 'fullSize/af9d1243-a991-4a8d-932a-6ac052b96cb8.png',
              },
              thumbNail: {
                width: 24,
                height: 80,
                key: 'thumbNail/af9d1243-a991-4a8d-932a-6ac052b96cb8.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_11.png',
          },
          {
            id: 'c0c9f7cc-4d7d-41c0-aaa9-1ce3f9627ad6',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 269,
                height: 800,
                key: 'fullSize/fb315c00-9432-430b-a9d4-7832793816d3.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/fb315c00-9432-430b-a9d4-7832793816d3.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_19.png',
          },
          {
            id: '2901c4bb-068b-4dc3-bc85-c4c3cc0c257b',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 242,
                height: 800,
                key: 'fullSize/76ad188d-9aba-491f-bf6e-fe36f9b6e4bd.png',
              },
              thumbNail: {
                width: 24,
                height: 80,
                key: 'thumbNail/76ad188d-9aba-491f-bf6e-fe36f9b6e4bd.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_01.png',
          },
          {
            id: '78713b99-aeca-4f37-a0c1-b65b86e02915',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 251,
                height: 800,
                key: 'fullSize/cbed801f-1f11-4fba-94fc-c002804467f2.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/cbed801f-1f11-4fba-94fc-c002804467f2.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_02_20.png',
          },
        ],
        nonDangerousStimuli: [
          {
            id: '71d4c140-aacf-4c12-b8bc-e5bb010fe71d',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 255,
                height: 800,
                key: 'fullSize/b40ab6dc-19c1-4c15-bc52-c9204706f558.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/b40ab6dc-19c1-4c15-bc52-c9204706f558.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_03.png',
          },
          {
            id: '6ba29cf6-8ef5-41e6-a69c-746a0e109233',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 261,
                height: 800,
                key: 'fullSize/3dc5a526-1a77-4103-83a7-14e0ea729072.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/3dc5a526-1a77-4103-83a7-14e0ea729072.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_04.png',
          },
          {
            id: '333df582-bfe2-4140-8d47-4e097fbdf486',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 241,
                height: 800,
                key: 'fullSize/0a42bcdf-5a19-482c-8e79-a310ac5348d4.png',
              },
              thumbNail: {
                width: 24,
                height: 80,
                key: 'thumbNail/0a42bcdf-5a19-482c-8e79-a310ac5348d4.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_13.png',
          },
          {
            id: '2529ce2f-e46b-409d-b136-9c114a95e137',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 264,
                height: 800,
                key: 'fullSize/21640b32-7e3d-4f91-884b-bcabba7322f7.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/21640b32-7e3d-4f91-884b-bcabba7322f7.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_15.png',
          },
          {
            id: 'e725e27b-7d38-4b83-b1bd-82a4911a354e',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 247,
                height: 800,
                key: 'fullSize/c5a10834-390e-40c9-b424-e556870edbc1.png',
              },
              thumbNail: {
                width: 24,
                height: 80,
                key: 'thumbNail/c5a10834-390e-40c9-b424-e556870edbc1.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_07.png',
          },
          {
            id: '135b0404-a792-4f28-a8c7-1986750ee818',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 265,
                height: 800,
                key: 'fullSize/2e3f1b5e-2b33-4bc3-9f43-0cd6aabf39ba.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/2e3f1b5e-2b33-4bc3-9f43-0cd6aabf39ba.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_17.png',
          },
          {
            id: '57d0d6f7-7267-4577-b445-6a5911bd82c6',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 301,
                height: 800,
                key: 'fullSize/43bd9674-422e-4135-be73-87a8e18d73d9.png',
              },
              thumbNail: {
                width: 30,
                height: 80,
                key: 'thumbNail/43bd9674-422e-4135-be73-87a8e18d73d9.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_22.png',
          },
          {
            id: '46673420-63f8-46dc-86d4-e2bb6ff0597c',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 254,
                height: 800,
                key: 'fullSize/c89360ff-7fab-409d-94d5-9371405f17c5.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/c89360ff-7fab-409d-94d5-9371405f17c5.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_25.png',
          },
          {
            id: '0c41ecff-5294-4706-a980-6074e2e212be',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 250,
                height: 800,
                key: 'fullSize/cdce2829-e349-4f6e-9390-9fe3ea0d4aa0.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/cdce2829-e349-4f6e-9390-9fe3ea0d4aa0.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_06.png',
          },
          {
            id: '2eec560e-88da-4e31-8a24-3dcd7ddea7cb',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 243,
                height: 800,
                key: 'fullSize/e2013f13-2c91-4735-b6e7-265fa62faf50.png',
              },
              thumbNail: {
                width: 24,
                height: 80,
                key: 'thumbNail/e2013f13-2c91-4735-b6e7-265fa62faf50.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_09.png',
          },
          {
            id: 'e749998e-fefa-41f2-8511-2fa91e098592',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 248,
                height: 800,
                key: 'fullSize/0febc083-039c-4a8f-aff9-8eba29ed4032.png',
              },
              thumbNail: {
                width: 24,
                height: 80,
                key: 'thumbNail/0febc083-039c-4a8f-aff9-8eba29ed4032.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_10.png',
          },
          {
            id: '04b544e2-235f-4507-b054-6be6569d64ee',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 242,
                height: 800,
                key: 'fullSize/a5a8c61f-8a67-48eb-b251-dab0187a2eff.png',
              },
              thumbNail: {
                width: 24,
                height: 80,
                key: 'thumbNail/a5a8c61f-8a67-48eb-b251-dab0187a2eff.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_14.png',
          },
          {
            id: '4a0bbd69-8d94-41bd-8ae8-226cfcb1557b',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 294,
                height: 800,
                key: 'fullSize/48944162-ed0e-4c1b-8ded-4763a283f8de.png',
              },
              thumbNail: {
                width: 29,
                height: 80,
                key: 'thumbNail/48944162-ed0e-4c1b-8ded-4763a283f8de.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_21.png',
          },
          {
            id: 'f3b396dc-8ffb-4bf2-a739-d036a8a725b0',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 251,
                height: 800,
                key: 'fullSize/cfeb6398-444d-4f52-bb5e-d20089f471f9.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/cfeb6398-444d-4f52-bb5e-d20089f471f9.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_20.png',
          },
          {
            id: '455c12f7-2c1a-418c-ad30-bdd124b3c7d9',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 242,
                height: 800,
                key: 'fullSize/2159a6d1-e912-4a4f-9021-725f156171a1.png',
              },
              thumbNail: {
                width: 24,
                height: 80,
                key: 'thumbNail/2159a6d1-e912-4a4f-9021-725f156171a1.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_01.png',
          },
          {
            id: '5ea92b74-f968-426e-9f57-99ce67b78bbc',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 281,
                height: 800,
                key: 'fullSize/801dabcf-88dc-43a8-b2d0-acd736f467c2.png',
              },
              thumbNail: {
                width: 28,
                height: 80,
                key: 'thumbNail/801dabcf-88dc-43a8-b2d0-acd736f467c2.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_18.png',
          },
          {
            id: '8948d6bc-7b2a-484b-b0d1-757ad561e0e4',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 290,
                height: 800,
                key: 'fullSize/da2b5dae-9937-4b78-a867-63f9799e1622.png',
              },
              thumbNail: {
                width: 29,
                height: 80,
                key: 'thumbNail/da2b5dae-9937-4b78-a867-63f9799e1622.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_05.png',
          },
          {
            id: 'c5327d9e-2050-4a28-9947-71c1daaa7c3f',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 246,
                height: 800,
                key: 'fullSize/7893e21f-5607-44c0-bd67-0587aa193bb6.png',
              },
              thumbNail: {
                width: 24,
                height: 80,
                key: 'thumbNail/7893e21f-5607-44c0-bd67-0587aa193bb6.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_11.png',
          },
          {
            id: '3d62a7b8-897d-4e66-9c10-e7945fbe88f5',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 239,
                height: 800,
                key: 'fullSize/85c6b93c-393e-4a86-8b7c-445854788907.png',
              },
              thumbNail: {
                width: 23,
                height: 80,
                key: 'thumbNail/85c6b93c-393e-4a86-8b7c-445854788907.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_24.png',
          },
          {
            id: '48ce5c6d-29c0-451e-8c9e-4b6acb48c93f',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 269,
                height: 800,
                key: 'fullSize/cede9e29-d83d-468b-9c33-22103cf45293.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/cede9e29-d83d-468b-9c33-22103cf45293.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_02_19.png',
          },
        ],
      },
    },
    {
      label: 'Arab',
      practiceStimuliPool: {
        dangerousStimuli: [
          {
            id: '2aad8a4a-1606-4b0e-80a8-133467d8dbef',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
              {
                id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                name: 'practise',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 272,
                height: 800,
                key: 'fullSize/afb42479-d782-44f9-b3b1-1e5317f19e16.png',
              },
              thumbNail: {
                width: 27,
                height: 80,
                key: 'thumbNail/afb42479-d782-44f9-b3b1-1e5317f19e16.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_11.png',
          },
          {
            id: '0f3d58fb-ed2b-43ca-802f-025074a0539f',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
              {
                id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                name: 'practise',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 252,
                height: 800,
                key: 'fullSize/b539f312-95f4-460a-bc37-479c4be44fc6.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/b539f312-95f4-460a-bc37-479c4be44fc6.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_13.png',
          },
        ],
        nonDangerousStimuli: [
          {
            id: '821684f1-50ac-45fc-a4c0-981ccb67fe93',
            tags: [
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
              {
                id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                name: 'practise',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 272,
                height: 800,
                key: 'fullSize/aea1c83f-a605-43f6-b044-e3707f0954c5.png',
              },
              thumbNail: {
                width: 27,
                height: 80,
                key: 'thumbNail/aea1c83f-a605-43f6-b044-e3707f0954c5.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_11.png',
          },
          {
            id: '4cfd5361-77d4-4e8a-a130-5e050747e572',
            tags: [
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
              {
                id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                name: 'practise',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 252,
                height: 800,
                key: 'fullSize/c55b979b-bd98-49a1-9bac-b0b330195575.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/c55b979b-bd98-49a1-9bac-b0b330195575.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_13.png',
          },
        ],
      },
      testStimuliPool: {
        dangerousStimuli: [
          {
            id: 'ceeba746-5312-4576-ac8c-d233cf0cf58a',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 252,
                height: 800,
                key: 'fullSize/600c1109-7712-4bc2-8337-021789784ea7.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/600c1109-7712-4bc2-8337-021789784ea7.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_04.png',
          },
          {
            id: '32b0d715-98f2-467b-b78b-04ed63c89892',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 262,
                height: 800,
                key: 'fullSize/90e6ba80-edb4-4c6b-8494-0b1677feee32.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/90e6ba80-edb4-4c6b-8494-0b1677feee32.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_22.png',
          },
          {
            id: '148ed866-d66b-4549-b03d-b08ca543d3ae',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 268,
                height: 800,
                key: 'fullSize/d1956e3a-4fc6-4f53-8a2a-66d37d466c6a.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/d1956e3a-4fc6-4f53-8a2a-66d37d466c6a.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_01.png',
          },
          {
            id: 'ecfd9074-1ec6-4cf7-a6a5-09d08f1b17de',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 261,
                height: 800,
                key: 'fullSize/d0f6c00f-a4ab-4acf-96b9-b1252a0527a9.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/d0f6c00f-a4ab-4acf-96b9-b1252a0527a9.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_02.png',
          },
          {
            id: '34b7c6e3-edd5-4e85-85e9-9d24cf761977',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 260,
                height: 800,
                key: 'fullSize/0880b62b-eb49-44ab-8780-7ca5dc9607aa.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/0880b62b-eb49-44ab-8780-7ca5dc9607aa.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_14.png',
          },
          {
            id: '961b18ab-8efe-4f50-9473-5bf6c9fad094',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 301,
                height: 800,
                key: 'fullSize/02b83741-5371-4a3e-9750-b8de993f907a.png',
              },
              thumbNail: {
                width: 30,
                height: 80,
                key: 'thumbNail/02b83741-5371-4a3e-9750-b8de993f907a.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_06.png',
          },
          {
            id: 'e389a276-c6d0-44b7-bd7c-16f32a776f4e',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 266,
                height: 800,
                key: 'fullSize/3ec0e831-6f72-4faa-8e81-2f1b08941c99.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/3ec0e831-6f72-4faa-8e81-2f1b08941c99.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_05.png',
          },
          {
            id: 'fd5c5daa-4229-41f0-8852-51dd670be7d0',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 259,
                height: 800,
                key: 'fullSize/1c38d3fc-5947-4a5e-859a-f05bc1797979.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/1c38d3fc-5947-4a5e-859a-f05bc1797979.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_07.png',
          },
          {
            id: '4b118550-e310-4baa-8ef7-a7d4503d0fcf',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 261,
                height: 800,
                key: 'fullSize/e9eea29b-5ead-40d6-83ff-df2f1e4d65e2.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/e9eea29b-5ead-40d6-83ff-df2f1e4d65e2.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_03.png',
          },
          {
            id: '0563ba42-da54-4322-b6c9-088a3c774b1b',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 310,
                height: 800,
                key: 'fullSize/af8384c3-ee93-407a-9758-ad7dd4f36e2c.png',
              },
              thumbNail: {
                width: 31,
                height: 80,
                key: 'thumbNail/af8384c3-ee93-407a-9758-ad7dd4f36e2c.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_15.png',
          },
          {
            id: '2e368b59-28ea-4964-bf51-a80ba92ded33',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 276,
                height: 800,
                key: 'fullSize/91a9b7ff-df6f-4bbd-a8b6-64d2db176981.png',
              },
              thumbNail: {
                width: 27,
                height: 80,
                key: 'thumbNail/91a9b7ff-df6f-4bbd-a8b6-64d2db176981.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_18.png',
          },
          {
            id: '44701eaa-cbaf-4496-822f-342c95048217',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 267,
                height: 800,
                key: 'fullSize/755a345f-8224-49a3-92ff-262ec05f8c08.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/755a345f-8224-49a3-92ff-262ec05f8c08.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_21.png',
          },
          {
            id: 'b28a531a-3198-4abd-bc2c-e6f9c01427b0',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 264,
                height: 800,
                key: 'fullSize/5079fe99-a49a-4480-9cb2-7bffb3f9b191.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/5079fe99-a49a-4480-9cb2-7bffb3f9b191.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_10.png',
          },
          {
            id: 'bf67055b-0234-4e9e-8fd2-d38582daa8a8',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 249,
                height: 800,
                key: 'fullSize/66434ca4-ce68-4eb5-aef2-bd991ca14885.png',
              },
              thumbNail: {
                width: 24,
                height: 80,
                key: 'thumbNail/66434ca4-ce68-4eb5-aef2-bd991ca14885.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_20.png',
          },
          {
            id: '4a5250b3-14bb-4cc0-8c43-5263b8fad649',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 246,
                height: 800,
                key: 'fullSize/e7e828ac-beb8-4fe8-9d05-9d51582ac348.png',
              },
              thumbNail: {
                width: 24,
                height: 80,
                key: 'thumbNail/e7e828ac-beb8-4fe8-9d05-9d51582ac348.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_12.png',
          },
          {
            id: '6a6bcae5-c290-4635-98f0-293f6e0d0d60',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 305,
                height: 800,
                key: 'fullSize/6b2af555-16e5-436c-a13d-746ca71e0b62.png',
              },
              thumbNail: {
                width: 30,
                height: 80,
                key: 'thumbNail/6b2af555-16e5-436c-a13d-746ca71e0b62.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_09.png',
          },
          {
            id: '0273da88-0367-4ac8-b3a0-8e4e817b6187',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 295,
                height: 800,
                key: 'fullSize/61834bb2-1877-4075-8dd2-48c64e022460.png',
              },
              thumbNail: {
                width: 29,
                height: 80,
                key: 'thumbNail/61834bb2-1877-4075-8dd2-48c64e022460.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_08.png',
          },
          {
            id: '552f5320-0e45-4145-89ca-e7cb9f2ed7d9',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 238,
                height: 800,
                key: 'fullSize/cc27ce1e-a7b5-4cc4-9a0f-d700497b7957.png',
              },
              thumbNail: {
                width: 23,
                height: 80,
                key: 'thumbNail/cc27ce1e-a7b5-4cc4-9a0f-d700497b7957.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_16.png',
          },
          {
            id: 'deb575b5-d0a6-4cfc-99e0-80c85e55db0f',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 274,
                height: 800,
                key: 'fullSize/da863428-ed35-4fb4-8423-90c674b21dc1.png',
              },
              thumbNail: {
                width: 27,
                height: 80,
                key: 'thumbNail/da863428-ed35-4fb4-8423-90c674b21dc1.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_17.png',
          },
          {
            id: '2528fa96-5a57-4876-9fff-bc59308169a1',
            tags: [
              {
                id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                name: 'Armed',
              },
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: 'e2958d05-0ea6-460e-9e89-0d00632d8651',
                name: 'Knife',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 258,
                height: 800,
                key: 'fullSize/30f066b7-4674-4f60-b848-f53a4c405c9e.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/30f066b7-4674-4f60-b848-f53a4c405c9e.png',
              },
            },
            originalFileName: 'dpd_fb_arm_kn_ma_01_19.png',
          },
        ],
        nonDangerousStimuli: [
          {
            id: '2f956f29-c690-4137-a73f-859dfee52696',
            tags: [
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 267,
                height: 800,
                key: 'fullSize/61220baa-3c24-48d1-9c67-6f2fb84638c3.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/61220baa-3c24-48d1-9c67-6f2fb84638c3.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_21_corr.png',
          },
          {
            id: '6648319e-b960-4c49-8718-54fd29ec96e3',
            tags: [
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 310,
                height: 800,
                key: 'fullSize/a7c67ca8-18f6-4267-8ea4-9fbe04a673b2.png',
              },
              thumbNail: {
                width: 31,
                height: 80,
                key: 'thumbNail/a7c67ca8-18f6-4267-8ea4-9fbe04a673b2.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_15.png',
          },
          {
            id: '30b0b1be-1ab5-4f3f-94a9-a7bc9ba2885a',
            tags: [
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 261,
                height: 800,
                key: 'fullSize/b623303c-1788-4b68-ab92-feab0cd78b46.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/b623303c-1788-4b68-ab92-feab0cd78b46.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_02.png',
          },
          {
            id: '9585d17e-ec8b-4ee8-ad68-d6431c69eac7',
            tags: [
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 259,
                height: 800,
                key: 'fullSize/41550fa8-c8f1-4197-b68d-9e1d1ec10514.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/41550fa8-c8f1-4197-b68d-9e1d1ec10514.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_07.png',
          },
          {
            id: '8ed0f96f-04e8-4147-8f2c-a5426e52b75e',
            tags: [
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 301,
                height: 800,
                key: 'fullSize/d2b2277e-bd47-4e65-b841-65416997f2d2.png',
              },
              thumbNail: {
                width: 30,
                height: 80,
                key: 'thumbNail/d2b2277e-bd47-4e65-b841-65416997f2d2.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_06.png',
          },
          {
            id: '95b90ad6-112f-4da9-b0dd-f8c1ca0b3e86',
            tags: [
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 266,
                height: 800,
                key: 'fullSize/75deabca-343a-467d-99a3-2a081d8a314b.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/75deabca-343a-467d-99a3-2a081d8a314b.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_05.png',
          },
          {
            id: 'd59dfc13-e75c-4b10-ba33-60621bdc76c7',
            tags: [
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 252,
                height: 800,
                key: 'fullSize/572916e6-bd69-44e2-9269-caa30094ac1d.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/572916e6-bd69-44e2-9269-caa30094ac1d.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_04.png',
          },
          {
            id: '50691dfd-09bf-4d6c-bc24-038828a111cd',
            tags: [
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 261,
                height: 800,
                key: 'fullSize/b2ddcb3d-7bc1-4c81-97e5-80dd3947f251.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/b2ddcb3d-7bc1-4c81-97e5-80dd3947f251.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_03.png',
          },
          {
            id: '97310998-df54-466f-928b-c28deb42f9d8',
            tags: [
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 260,
                height: 800,
                key: 'fullSize/11335d6c-bb81-4765-8c1e-83eaf1d4be4c.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/11335d6c-bb81-4765-8c1e-83eaf1d4be4c.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_14.png',
          },
          {
            id: '06eb4f8d-ee56-4b30-919f-b27a8427743d',
            tags: [
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 262,
                height: 800,
                key: 'fullSize/cec359c8-a58b-4517-ab5e-e50a51d96452.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/cec359c8-a58b-4517-ab5e-e50a51d96452.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_22.png',
          },
          {
            id: 'e4924958-8656-4b79-856a-9d41ff72494a',
            tags: [
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 268,
                height: 800,
                key: 'fullSize/5f301a92-212f-462d-9804-ce5d82adf999.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/5f301a92-212f-462d-9804-ce5d82adf999.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_01.png',
          },
          {
            id: '56ef0020-4ec7-471c-b5de-1f9edb00448b',
            tags: [
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 246,
                height: 800,
                key: 'fullSize/fe9cf92c-99ef-4b4d-838a-cbeabbfb2af1.png',
              },
              thumbNail: {
                width: 24,
                height: 80,
                key: 'thumbNail/fe9cf92c-99ef-4b4d-838a-cbeabbfb2af1.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_12.png',
          },
          {
            id: '5a4c6571-b8e3-44d7-bc37-60fc1262c225',
            tags: [
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 276,
                height: 800,
                key: 'fullSize/ebe2915f-1591-4b7d-89fd-aa6e16a219a0.png',
              },
              thumbNail: {
                width: 27,
                height: 80,
                key: 'thumbNail/ebe2915f-1591-4b7d-89fd-aa6e16a219a0.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_18.png',
          },
          {
            id: 'c492f98b-3613-4513-9f0d-bea55e4e248c',
            tags: [
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 249,
                height: 800,
                key: 'fullSize/9ac503bf-5015-44f7-963b-62c5455e1af7.png',
              },
              thumbNail: {
                width: 24,
                height: 80,
                key: 'thumbNail/9ac503bf-5015-44f7-963b-62c5455e1af7.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_20.png',
          },
          {
            id: '3a6b5aba-cb9b-4af7-a526-aae2b6cc994b',
            tags: [
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 264,
                height: 800,
                key: 'fullSize/d73fb5f8-f558-476d-a2d2-6ef9161cc455.png',
              },
              thumbNail: {
                width: 26,
                height: 80,
                key: 'thumbNail/d73fb5f8-f558-476d-a2d2-6ef9161cc455.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_10.png',
          },
          {
            id: 'ec259201-f0b2-41b7-ad77-ba11fb48718f',
            tags: [
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 305,
                height: 800,
                key: 'fullSize/d0d1410f-7da5-4340-b1bb-a94c1836f73c.png',
              },
              thumbNail: {
                width: 30,
                height: 80,
                key: 'thumbNail/d0d1410f-7da5-4340-b1bb-a94c1836f73c.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_09.png',
          },
          {
            id: 'd5ba2dcf-2c95-4888-9e0d-00e51d75c447',
            tags: [
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 274,
                height: 800,
                key: 'fullSize/d3cba741-d1d0-4ea0-b84c-2b733fedcd00.png',
              },
              thumbNail: {
                width: 27,
                height: 80,
                key: 'thumbNail/d3cba741-d1d0-4ea0-b84c-2b733fedcd00.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_17.png',
          },
          {
            id: 'ec4be2cf-9541-4184-8124-9e956229aeb5',
            tags: [
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 258,
                height: 800,
                key: 'fullSize/b26a7d35-a618-4239-a312-6dba8e437e0d.png',
              },
              thumbNail: {
                width: 25,
                height: 80,
                key: 'thumbNail/b26a7d35-a618-4239-a312-6dba8e437e0d.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_19.png',
          },
          {
            id: '1b6e963d-dd66-431d-8ff8-632955cec4c7',
            tags: [
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 238,
                height: 800,
                key: 'fullSize/bbb3be01-4000-456d-8843-2f896b8a36c0.png',
              },
              thumbNail: {
                width: 23,
                height: 80,
                key: 'thumbNail/bbb3be01-4000-456d-8843-2f896b8a36c0.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_16.png',
          },
          {
            id: '8607b0f4-246f-44f7-a5a9-4a3648aa67a6',
            tags: [
              {
                id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                name: 'Set1',
              },
              {
                id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                name: 'Group 1',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
              },
              {
                id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                name: 'phone',
              },
              {
                id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                name: 'unarmed',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 295,
                height: 800,
                key: 'fullSize/ac094234-e03a-4e09-9134-b2405a01dd78.png',
              },
              thumbNail: {
                width: 29,
                height: 80,
                key: 'thumbNail/ac094234-e03a-4e09-9134-b2405a01dd78.png',
              },
            },
            originalFileName: 'dpd_fb_una_ph_ma_01_08.png',
          },
        ],
      },
    },
  ],
  fixation: {
    interval: 750,
    mediaItem: {
      id: '1336aefe-e4a5-4478-87ab-b5f34d044e89',
      tags: [],
      text: '+',
      scope: MediaItemSnapshotScope.PUBLIC,
      image: null,
      originalFileName: null,
    },
  },
  trialFeedback: {
    correctRejectionText: {
      value: 'Richtige Entscheidung!',
      fontStyle: {
        fontSize: 30,
        color: 'green',
        alignment: 'CENTER',
        fontFace: 'ARIAL',
      },
      weight: 5,
    },
    hitText: {
      value: 'Richtige Entscheidung!',
      fontStyle: {
        fontSize: 30,
        color: 'green',
        alignment: 'CENTER',
        fontFace: 'ARIAL',
      },
      weight: 10,
    },
    missText: {
      value: 'Die Person war bewaffnet!',
      fontStyle: {
        fontSize: 30,
        color: 'red',
        alignment: 'CENTER',
        fontFace: 'ARIAL',
      },
      weight: -40,
    },
    falseAlarmText: {
      value: 'Die Person war unbewaffnet!',
      fontStyle: {
        fontSize: 30,
        color: 'red',
        alignment: 'CENTER',
        fontFace: 'ARIAL',
      },
      weight: -20,
    },
    noResponseText: {
      value: 'Sie waren zu langsam!',
      fontStyle: {
        fontSize: 30,
        color: 'orange',
        alignment: 'CENTER',
        fontFace: 'ARIAL',
      },
      weight: -10,
    },
    pointsFontsize: 40,
  },
  blocks: [
    {
      index: 0,
      name: 'Practice',
      type: 'PRACTICE',
      amountTrials: 4,
      instructions: {
        desktop:
          '<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;"></p>\n<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">In der folgenden Aufgabe müssen Sie Personen ausweichen, die ein <strong>Messer</strong> in der Hand halten, aber nicht ausweichen, wenn die Personen <strong>Handys</strong> in der Hand halten.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wenn eine Person erscheint, müssen Sie so schnell wie möglich entscheiden, ob Sie die Straßenseite "wechseln" oder nicht.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wenn Sie "<strong>A</strong>" drücken (LINKS), entscheiden Sie sich für die linke Straßenseite.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wenn Sie "<strong>L</strong>" drücken (RECHTS), entscheiden Sie sich für die rechte Straßenseite.</span></p>\n<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Sie haben jeweils weniger als eine Sekunde Zeit um zu entscheiden. Je nach Leistung erhalten Sie pro Durchgang Punkte.</span></p>\n<p style="text-align:center;"><span style="color: rgba(0,0,0,0.87);background-color: rgb(255,255,255);font-size: 18px;font-family: Mulish;">Die erste Runde dient zur Übung und hat vier Durchgänge.</span> <span style="font-size: 18px;font-family: Mulish;">Wenn Sie bereit sind, dann drücken sie nun die</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Leertaste.</span></p>\n',
        mobile:
          '<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">In der folgenden Aufgabe müssen Sie Personen ausweichen, die ein <strong>Messer</strong> in der Hand halten, aber nicht ausweichen, wenn die Personen <strong>Handys</strong> in der Hand halten.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wenn eine Person erscheint, müssen Sie </span><span style="color: rgba(0,0,0,0.87);background-color: rgb(255,255,255);font-size: 18px;font-family: Mulish;">so schnell wie möglich </span><span style="font-size: 18px;font-family: Mulish;">entscheiden, ob Sie die Straßenseite "wechseln" oder nicht.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wenn Sie <strong>LINKS</strong> drücken, entscheiden Sie sich für die linke Straßenseite.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wenn Sie <strong>RECHTS</strong> drücken, entscheiden Sie sich für die rechte Straßenseite.</span></p>\n<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Sie haben jeweils weniger als eine Sekunde Zeit um zu entscheiden. Je nach Leistung erhalten Sie pro Durchgang Punkte.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Die erste Runde dient zur Übung und hat vier Durchgänge. Wenn Sie bereit sind, dann fangen Sie jetzt an.</span></p>\n',
        fontStyle: null,
      },
      preparationScreen: {
        interval: 2000,
        screen: {
          desktop:
            '<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;"><span style="font-size: 24px;font-family: Mulish;"><strong>Bereit?</strong></span></p>\n<p style="text-align:center;"><span style="font-size: 24px;font-family: Mulish;"><strong>Dann geht es jetzt los...!</strong></span></p>\n',
          mobile:
            '<p style="text-align:center;"><span style="font-size: 24px;font-family: Mulish;"><strong>Bereit?</strong></span></p>\n<p style="text-align:center;"><span style="font-size: 24px;font-family: Mulish;"><strong>Dann geht es jetzt los...!</strong></span></p>\n',
          fontStyle: null,
        },
      },
      tips: {
        desktop: '',
        mobile: null,
        fontStyle: null,
      },
    },
    {
      index: 1,
      name: 'Test',
      type: 'NON_PRACTICE',
      amountTrials: 80,
      instructions: {
        __typename: 'StyleableTextTemplate',
        desktop:
          '<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;"></p>\n<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Der Übungsdurchgang ist nun beendet.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Zur Erinnerung:</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Entscheiden Sie sich so schnell und akkurat wie möglich für eine Straßenseite, indem Sie "<strong>A</strong>" (LINKS) oder "<strong>L</strong>" (RECHTS) drücken.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wenn die Person eine Waffe hält, müssen Sie die Seite wechseln: Drücken Sie die Taste für die ENTGEGENGESETZTE Straßenseite. </span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wenn die Person ein Handy hält, sollen sie nicht die Seite wechseln: Drücken Sie die Taste für die GLEICHE Straßenseite.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wichtig: Benutzen Sie bitte die Finger beider Hände für die Aufgabe.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wenn Sie bereit sind, dann drücken Sie jetzt die Leertaste.</span></p>\n',
        mobile:
          '<p style="text-align:center;">&nbsp;<span style="font-size: 18px;font-family: Mulish;">Der Übungsdurchgang ist nun beendet.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Zur Erinnerung:</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Entscheiden Sie sich so schnell und akkurat wie möglich für eine Straßenseite, indem Sie LINKS oder RECHTS drücken.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wenn die Person eine Waffe hält, müssen Sie die Seite wechseln: Drücken Sie die Taste für die ENTGEGENGESETZTE Straßenseite. </span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wenn die Person ein Handy hält, sollen sie nicht die Seite wechseln: Drücken Sie die Taste für die GLEICHE Straßenseite.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wichtig: Benutzen Sie bitte die Finger beider Hände für die Aufgabe.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wenn Sie bereit sind, dann fangen Sie jetzt an.</span></p>\n',
        fontStyle: null,
      },
      preparationScreen: {
        interval: 500,
        screen: {
          desktop:
            '<p style="text-align:center;"><span style="font-size: 24px;"> </span></p>\n<p style="text-align:center;"><span style="font-size: 24px;"> </span></p>\n<p style="text-align:center;"><span style="font-size: 24px;"> </span></p>\n<p style="text-align:center;"><span style="font-size: 24px;"> </span></p>\n<p style="text-align:center;"><span style="font-size: 24px;font-family: Mulish;"><strong>Bereit?</strong></span></p>\n<p style="text-align:center;"><span style="font-size: 24px;font-family: Mulish;"><strong>Dann geht es jetzt los!</strong></span></p>\n',
          mobile:
            '<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;"><strong>Bereit?</strong></span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;"><strong>Dann geht es jetzt los!</strong></span></p>\n',
          fontStyle: null,
        },
      },
      tips: {
        desktop: '',
        mobile: null,
        fontStyle: {
          fontSize: 14,
          color: 'black',
          alignment: 'CENTER',
          fontFace: 'ARIAL',
        },
      },
    },
  ],
  showCategoriesAfterInstructions: null,
  startLabel: 'Start',
  nextLabel: 'Next',
  finishLabel: 'Beenden',
  createdAt: '2022-12-23T15:03:09.018Z',
  updatedAt: '2023-01-25T13:19:42.336Z',
} as unknown as Podta;

export const podtaTemplates: Podta[] = [template1];

export const InitialPodtaCreatorState: PodtaCreatorState = {
  startLabel: 'Start',
  nextLabel: 'Next',
  finishLabel: 'Finish',
  name: '',
  description: '',
  scenes: {
    intervalConfig: {
      min: 500,
      max: 1000,
      stepSize: 100,
    },
    maxAmountScenesPerTrial: 4,
    scenes: [],
  },
  targets: [createEmptyLabeledStimuliPool()],
  counterbalancing: true,
  probabilityWithoutReplacement: true,
  fixation: createEmptyDefaultFixation(),
  response: {
    fontStyle: createDefaultFontStyle(TextAlignment.CENTER),
    timeout: 850,
    active: createEmptyMediaItemSnapshot(),
    passive: createEmptyMediaItemSnapshot(),
  },
  interTrialInterval: 3000,
  keyboard: createEmptyKeyboardSettings(),
  instructions: {
    desktop: '',
  },
  trialFeedback: {
    correctRejectionText: createWeightedStyledTextInput(
      'Gute Entscheidung!',
      5,
    ),
    hitText: createWeightedStyledTextInput('Richtig ausgewichen!', 10),
    missText: createWeightedStyledTextInput('Sie wurden verletzt!!!', -40),
    falseAlarmText: createWeightedStyledTextInput(
      'Sie haben die Seite umsonst gewechselt!',
      -20,
    ),
    noResponseText: createWeightedStyledTextInput('Zu langsam', -10),
  },
  blocks: [
    {
      index: 0,
      name: '',
      instructions: {
        desktop: '',
      },
      amountTrials: 20,
      preparationScreen: getDefaultPreparationScreen(),
      type: BlockType.NON_PRACTICE,
    },
  ],
  style: createDefaultStyle(),
  status: TestStatus.DRAFT,
};
