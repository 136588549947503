import { createTestCrudMap, createTestResultCrudMap } from '../../../../../GQL';
import {
  CreateIatResultMutationVariables,
  CreateIatTestMutationVariables,
  DeleteIatResultMutationVariables,
  DeleteIatTestMutation,
  GetIatResultQueryVariables,
  GetIatTestQueryVariables,
  IatResultsByTestIdQueryVariables,
  ListIatResultsQueryVariables,
  ListIatTestsQueryVariables,
  UpdateIatResultMutationVariables,
  UpdateIatTestMutationVariables,
} from '../../../../../API';
import {
  getIatResult,
  iatResultsByTestId,
  listIatResults,
  listIatTests,
} from '../../../../../graphql/queries';
import {
  createIatResult,
  createIatTest,
  deleteIatResult,
  deleteIatTest,
  updateIatResult,
  updateIatTest,
} from '../../../../../graphql/mutations';
import { Iat, IatResult } from '../../types';
import { getPublicIat } from '../../../../../graphql/customQueries';

export const IatQueries = createTestCrudMap<Iat>()((query) => ({
  Get: query.Get<GetIatTestQueryVariables>(getPublicIat),
  List: query.List<ListIatTestsQueryVariables>(listIatTests),
  Create: query.Mutate<CreateIatTestMutationVariables>(createIatTest),
  Update: query.Mutate<UpdateIatTestMutationVariables>(updateIatTest),
  Delete: query.Mutate<DeleteIatTestMutation>(deleteIatTest),
}));

export const IatResultQueries = createTestResultCrudMap<IatResult>()(
  (query) => ({
    ListByTest:
      query.List<IatResultsByTestIdQueryVariables>(iatResultsByTestId),
    List: query.List<ListIatResultsQueryVariables>(listIatResults),
    Get: query.Get<GetIatResultQueryVariables>(getIatResult),
    Update: query.Mutate<UpdateIatResultMutationVariables>(updateIatResult),
    Delete: query.Mutate<DeleteIatResultMutationVariables>(deleteIatResult),
    Create: query.Mutate<CreateIatResultMutationVariables>(createIatResult),
  }),
);
