import { TransitionModifier } from '../graph-state/transition-modifiers';
import { PhaseTreeNode } from './nodes/phase-tree-node';

export interface TestTree {
  readonly modifiers: Record<'default' | string, TransitionModifier>;
  root(modifier?: TransitionModifier): PhaseTreeNode;
}

export class DefaultTestTree implements TestTree {
  constructor(
    public readonly modifiers: Record<string, TransitionModifier>,
    public readonly rootNode: PhaseTreeNode,
  ) {}

  root(modifier?: TransitionModifier): PhaseTreeNode {
    if (modifier && modifier !== this.modifiers.default) {
      throw new Error(`Non default tree nodes not supported yet`);
    }
    return this.rootNode;
  }
}
