import { ControlRequest } from './control-stereotypes';
import { TransitionActionFactory } from '../graph-state/graph-action';
import { StateHolder } from '../graph-state/state-holder';

export interface ControlTransition<RP, Trigger> {
  readonly controlRequest: ControlRequest<RP>;
  readonly actionFactory: TransitionActionFactory<Trigger>;
  readonly predicate?: (stateHolder: StateHolder) => boolean;
}

export class DefaultControlTransition<RP, Trigger>
  implements ControlTransition<RP, Trigger>
{
  constructor(
    public readonly controlRequest: ControlRequest<RP>,
    public readonly actionFactory: TransitionActionFactory<Trigger>,
    public readonly predicate?: (stateHolder: StateHolder) => boolean,
  ) {}
}
