import { useConfirm } from 'material-ui-confirm';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { RepeatOne } from '@mui/icons-material';
import React from 'react';
import { UserListElement } from '../../API';
import { NewGQL } from '../../GQL';
import { resendUserInvitation } from '../../graphql/mutations';
import { enqueueSnackbar } from 'notistack';

export function ResendUserInvitationDialog({
  user,
  onSuccess,
}: {
  user: UserListElement;
  onSuccess: () => void;
}) {
  const confirmHandler = useConfirm();
  const confirmResendUserInvitation = async () => {
    try {
      await confirmHandler({
        title: 'Resend Invitation',
        description: `Are you sure you want to resend the invitation to ${user.email}?`,
      });
      try {
        await NewGQL.DEFAULT_CLIENT.runMutation(resendUserInvitation, {
          email: user.email,
        });
        enqueueSnackbar('User invitation resent', {
          variant: 'success',
        });
        onSuccess();
      } catch (e) {
        console.error('Failed to resend user invitation', e);
        enqueueSnackbar('Failed to resend user invitation', {
          variant: 'error',
        });
      }
    } catch (e) {
      // Dialog cancelled
    }
  };
  return (
    <GridActionsCellItem
      onPointerEnterCapture={() => {}}
      onPointerLeaveCapture={() => {}}
      showInMenu={false}
      placeholder=""
      disabled={user.userStatus !== 'FORCE_CHANGE_PASSWORD'}
      icon={<RepeatOne />}
      label="Resend Invatation"
      onClick={confirmResendUserInvitation}
    />
  );
}
