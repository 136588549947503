import React, { FC } from "react";
import { WSWAttribute } from "../../../../../API";
import { Box, Stack, Typography } from "@mui/material";
import { TextDisplay } from "../../../../../components/TextDisplay";
import { WswAttributeCategoriesView } from "./WswAttributeCategoriesView";
import _ from "lodash";

interface WswAttributesViewProps {
  attribute?: WSWAttribute;
  label: string;
}

export const WswAttributesView: FC<WswAttributesViewProps> = ({ attribute, label }) => {
  return (
    <Box>
      <Typography color="textSecondary">{label}</Typography>
      <Stack spacing={2} direction="row" sx={{pl: 1}}>
        <TextDisplay
          label={"Type"}
          value={_.startCase(_.lowerCase(attribute?.type))}
        />
        <TextDisplay
          label={"Picking Strategy"}
          value={_.startCase(_.lowerCase(attribute?.pickingStrategy))}
        />
      </Stack>
      {attribute?.categories &&
        <WswAttributeCategoriesView attributeCategories={attribute.categories} />
      }
    </Box>
  );
};
