import { Amp } from '../../types';
import { TestResourceAccessor } from '../../../../subject/testRunner/media/test-resource-loader';
import { TextStyleDescriptor } from '../../../../subject/testRunner/media/text-drawer';
import {
  AnnotatedDataPipe,
  MetaDataMediaSelector,
} from '../../../../subject/testRunner/media/drawable/drawable-collector';
import { BlockType, MediaItemSnapshot } from '../../../../../API';
import {
  DrawableMediaInstance,
  transformMediaItemData,
} from '../../../../subject/testRunner/media/MediaData';

export function LoadAmpMedia(accessor: TestResourceAccessor<Amp>) {
  const stimulusFactory = accessor.createDrawableFactory(
    'fullSize',
    TextStyleDescriptor.ofFontStyle(accessor.modelData.style.stimulusTextStyle),
  );
  const attributeFactory = accessor.createDrawableFactory(
    'fullSize',
    TextStyleDescriptor.ofFontStyle(
      accessor.testContext.deviceInfo.mode === 'desktop'
        ? accessor.modelData.attributeCategories.fontStyle
        : { ...accessor.modelData.attributeCategories.fontStyle, fontSize: 15 },
    ),
  );

  return MetaDataMediaSelector.of(accessor.modelData).nestSelectors(
    (subSelector) => {
      return {
        targets: AnnotatedDataPipe.of(
          subSelector()
            .selectRaw('targets')
            .selectMap('category', (d) =>
              d.categories.map((de) => [de, de.label]),
            )
            .select('blockType', {
              practiceStimuliPool: BlockType.PRACTICE,
              testStimuliPool: BlockType.NON_PRACTICE,
            } as const)
            .selectMap('index', (d) =>
              d.map((v, i) => [v, i] as [typeof v, number]),
            )
            .mapValues(
              (v) =>
                stimulusFactory.createInstance(
                  transformMediaItemData(v as MediaItemSnapshot),
                ) as DrawableMediaInstance,
            )
            .collect(),
        ),
        primes: AnnotatedDataPipe.of(
          subSelector()
            .selectRaw('primes')
            .selectMap('category', (d) =>
              d.categories.map((de) => [de, de.label]),
            )
            .select('blockType', {
              practiceStimuliPool: BlockType.PRACTICE,
              testStimuliPool: BlockType.NON_PRACTICE,
            } as const)
            .selectMap('index', (d) =>
              d.map((v, i) => [v, i] as [typeof v, number]),
            )
            .mapValues(
              (v) =>
                stimulusFactory.createInstance(
                  transformMediaItemData(v as MediaItemSnapshot),
                ) as DrawableMediaInstance,
            )
            .collect(),
        ),
        fixation: accessor.modelData.fixation?.mediaItem
          ? stimulusFactory.createInstance(
              transformMediaItemData(
                accessor.modelData.fixation.mediaItem as MediaItemSnapshot,
              ),
            )
          : undefined,
        mask: stimulusFactory.createInstance(
          transformMediaItemData(accessor.modelData.mask as MediaItemSnapshot),
        ),
        attribute: subSelector()
          .selectRaw('attributeCategories')
          .select('variant', {
            categoryA: 'first',
            categoryB: 'second',
          } as const)
          .mapValues((v) =>
            attributeFactory.createInstance(
              transformMediaItemData(v as MediaItemSnapshot),
            ),
          )
          .collectMappedFirst('variant'),
      } as const;
    },
  );
}

export type AmpMediaPool = ReturnType<typeof LoadAmpMedia>;
