import React, { FC } from 'react';
import { Box, Card, IconButton, Tooltip, Typography } from '@mui/material';
import { QuestionType, TestQuestionInput } from '../../../../../API';
import { useField } from 'formik';
import { MultipleChoiceQuestionComponent } from './MultipleChoiceQuestionComponent';
import { Delete, InfoOutlined } from '@mui/icons-material';
import StyledTextInput from '../../../../../components/input/StyledTextInput';
import { ScaleQuestionComponent } from './ScaleQuestionComponent';
import MediaItemPicker from '../../../../media/picker/MediaItemPicker';
import { questionTypeFormatMap } from './utils';
import StyledNumberInput from '../../../../../components/input/StyledNumberInput';
import { FormikNullableCheckbox } from '../../../../../components/input/FormikNullableCheckbox';
import { ImageMultipleChoiceQuestionComponent } from './ImageMultipleChoiceQuestionComponent';

interface TestQuestionInputProps {
  fieldName: string;
  onRemove: () => void;
  index: number;
  withImageInQuestions: boolean;
  withQuestionTimeout: boolean;
}

export const TestQuestionInputComponent: FC<TestQuestionInputProps> = ({
  fieldName,
  onRemove,
  index,
  withImageInQuestions,
  withQuestionTimeout,
}) => {
  const [, { value: testQuestion }, helpers] = useField<
    TestQuestionInput | undefined
  >(fieldName);

  return (
    <Card variant="outlined" sx={{ mb: 2, p: 1 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ pl: 1, pb: '4px' }}>
          Question {index + 1} (
          {testQuestion?.type && questionTypeFormatMap[testQuestion.type]})
        </Typography>
        <IconButton size="small" onClick={onRemove}>
          <Delete fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={{ pl: 2, pt: 0.5 }}>
        <StyledTextInput
          multiline
          size="small"
          id={`test-question-text-${testQuestion?.type}`}
          label={'Question Text'}
          name={`${fieldName}.questionText`}
          InputProps={{
            endAdornment: withImageInQuestions ? (
              <Box>
                <MediaItemPicker
                  name={`${fieldName}.mediaItemSnapshot`}
                  label={'Image'}
                  imageMode
                />
                {testQuestion?.mediaItemSnapshot && withQuestionTimeout && (
                  <StyledNumberInput
                    size="small"
                    sx={{ mt: 1 }}
                    name={`${fieldName}.stimulusTimeout`}
                    label={'Question Fade-In'}
                    placeholder=""
                    unit="ms"
                  />
                )}
              </Box>
            ) : undefined,
          }}
        />
      </Box>
      <Box ml={2} my={2}>
        <StyledNumberInput
          label={'Stimulus Timeout'}
          name={`${fieldName}.stimulusTimeout`}
        />
      </Box>

      {testQuestion?.type === QuestionType.MULTIPLE_CHOICE && (
        <MultipleChoiceQuestionComponent fieldName={`${fieldName}.multiple`} />
      )}
      {testQuestion?.type === QuestionType.IMAGE_MULTIPLE_CHOICE && (
        <ImageMultipleChoiceQuestionComponent
          fieldName={`${fieldName}.imageMultipleChoice`}
        />
      )}
      {testQuestion?.type === QuestionType.SCALE && (
        <ScaleQuestionComponent fieldName={`${fieldName}.scale`} />
      )}
      <Box sx={{ pl: 2, display: 'flex', flexDirection: 'row', pt: 1 }}>
        <FormikNullableCheckbox
          size="small"
          label={'No Answer'}
          value={
            testQuestion?.noAnswer !== null &&
            testQuestion?.noAnswer !== undefined
          }
          onChange={(checked) => {
            if (testQuestion) {
              helpers.setValue({
                ...testQuestion,
                noAnswer: checked ? 'No Answer' : null,
              });
            }
          }}
        />
        <Tooltip
          placement={'right'}
          title={
            'Include and label the option respondents can choose if they refuse to answer to the item.'
          }
        >
          <InfoOutlined
            sx={{
              color: 'warning.dark',
              bgcolor: 'white',
              position: 'relative',
              top: '7px',
              left: '-13px',
            }}
          />
        </Tooltip>
        {testQuestion?.noAnswer !== null &&
          testQuestion?.noAnswer !== undefined && (
            <Box sx={{ flexGrow: 1 }}>
              <StyledTextInput
                sx={{ width: '100%', flexGrow: 1 }}
                size="small"
                fullWidth
                name={`${fieldName}.noAnswer`}
                label="No Answer Label"
              />
            </Box>
          )}
      </Box>
    </Card>
  );
};
