import React, { FC } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useField } from 'formik';
import { MediaItemSnapshotInput, PodtSceneInput } from '../../../API';
import MediaItemList from '../MediaItemList';
import { MediaItemSelectionListDialog } from './MidiaItemsPicker/MediaItemsPicker';

const useStyles = makeStyles((theme) => ({
  stimulusRoot: {
    flex: 1,
    position: 'relative',
    "& .MuiChip-deletable[datatype='image']:nth-child(3)": {
      marginRight: 20,
    },
  },
  stimuliListBtn: {
    position: 'absolute',
    top: theme.spacing(2.5),
    left: 0,
    textAlign: 'left',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      top: 'inherit',
      right: 'inherit',
    },
  },
}));

const ScenesPicker: FC = () => {
  const classes = useStyles();
  const [showTransferList, setShowTransferList] = React.useState(false);

  const [, meta, helpers] = useField<PodtSceneInput[]>('scenes.scenes');

  const saveSelection = (mediaItems: MediaItemSnapshotInput[]) => {
    helpers.setValue(
      mediaItems.map((mediaItem) => {
        const oldItem = meta.value.find(
          (psi) => psi.mediaItem.id === mediaItem.id,
        );
        return {
          mediaItem,
          boxes: oldItem ? oldItem.boxes : [],
        };
      }),
    );
  };

  const removeStimuliFromList = (stimulusId: string) => {
    if (meta.value) {
      helpers.setValue(meta.value.filter((s) => s.mediaItem.id !== stimulusId));
    }
  };

  const mediaItemValue = React.useMemo(() => {
    return meta.value.map((podtScene) => podtScene.mediaItem);
  }, [meta.value]);

  const stimuliList = (
    <Box>
      <Stack alignItems="flex-start">
        <Typography variant="h6" color="textSecondary">
          Scenes
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowTransferList(true)}
          size="small"
        >
          Add Scenes
        </Button>
      </Stack>
      <MediaItemList
        items={meta.value?.map((s) => s.mediaItem) ?? []}
        onDelete={removeStimuliFromList}
        datatype={'image'}
      />
    </Box>
  );
  return (
    <div
      className={classes.stimulusRoot}
      style={{
        borderColor: `${
          meta.touched && meta.error ? 'red' : 'rgba(0,0,0,0.23)'
        }`,
      }}
    >
      {stimuliList}
      <MediaItemSelectionListDialog
        value={mediaItemValue}
        open={showTransferList}
        onClose={() => setShowTransferList(false)}
        title="Scenes"
        onConfirm={(mediaItems) => saveSelection(mediaItems)}
      />
      {meta.error && <Typography color="error">{meta.error}</Typography>}
    </div>
  );
};
export default ScenesPicker;
