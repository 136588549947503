import { ViewScaler } from '../../../subject/testRunner/screens/composer/scaler/view-scaler';
import { CanvasStrategy } from '../../../subject/testRunner/screens/composer/scaler/canvas-strategy';
import { View } from '../../../subject/testRunner/screens/composer/views/view';
import { SelectorNodeMap } from '../../../subject/testRunner/screens/selectors/selector-tree';
import {
  BasicDimension,
  DrawableDimension,
  DrawableMetrics,
  SizedArea,
} from '../../../subject/testRunner/media/drawable-dimensions';
import { ViewInstance } from '../../../subject/testRunner/screens/composer/screen-instance/view-instance';
import React from 'react';
import { Observable, Subject } from 'rxjs';
import { ViewContainerFactory } from '../../../subject/testRunner/screens/view-content-container';
import { DimensionReducer } from '../../../subject/testRunner/screens/dom-measure/dimension-reducer';
import {
  DrawableMediaInstance,
  MediaDrawable,
} from '../../../subject/testRunner/media/MediaData';
import MediaOffscreenCanvas from '../../../subject/MediaOffscreenCanvas';
import { displayRatio } from '../../../subject/testRunner/screens/basic-components/media-instance-components';
import { ColorMediaInstance } from './model/color-media-data';
import { WswSingleStimulus } from './view-data/wsw-question-stimulus';
import { CanvasContentView } from '../../../subject/testRunner/screens/composer/views/canvas-content-view';
import { AbstractView } from '../../../subject/testRunner/screens/composer/views/abstract-view';
import { ViewDefinition } from '../../../subject/testRunner/screens/composer/view-definition-collection';
import { AbstractViewScaler } from '../../../subject/testRunner/screens/composer/scaler/abstract-view-scaler';
import { MeasurableValue } from '../../../subject/testRunner/screens/composer/screen-instance/measurable-value';
import { Selector } from '../../../subject/testRunner/screens/selectors/selector';

export class WswCanvasStrategy implements CanvasStrategy<WswSingleStimulus> {
  minSize(data: WswSingleStimulus[]): BasicDimension {
    return DrawableDimension.NullSize;
  }

  scaleSize(
    maxSize: BasicDimension,
    data: WswSingleStimulus[],
  ): BasicDimension {
    const maxCanvasSize = DimensionReducer.max().process(
      ...data.map(
        (di) => (di.instance as DrawableMediaInstance).drawable.dimension,
      ),
    ).currentDimension;
    return DimensionReducer.min().process(maxSize).process(maxCanvasSize)
      .currentDimension;
  }

  scaler(
    scaleSize: BasicDimension,
    data: WswSingleStimulus[],
  ): { scale: (data: WswSingleStimulus) => MediaDrawable } {
    const imageDrawables = data.map(
      (d) => (d.instance as DrawableMediaInstance).drawable,
    );
    const minDiagonal = Math.min(
      ...imageDrawables.map((td) => td.dimension.diagonal()),
    );
    return {
      scale: (sData) => {
        const mediaCanvas = new MediaOffscreenCanvas(
          DrawableDimension.multiply(scaleSize, displayRatio),
        );
        const instanceDim = (sData.instance as DrawableMediaInstance).drawable
          .dimension;
        const instanceScaleFactor = Math.sqrt(
          (minDiagonal * minDiagonal) /
            (instanceDim.width * instanceDim.width +
              instanceDim.height * instanceDim.height),
        );
        const instanceScaledDim = DrawableDimension.multiply(
          instanceDim,
          instanceScaleFactor,
        );
        const imageInstanceScaleFactor =
          instanceScaledDim.width > scaleSize.width ||
          instanceScaledDim.height > scaleSize.height
            ? Math.min(
                scaleSize.width / instanceScaledDim.width,
                scaleSize.height / instanceScaledDim.height,
              )
            : 1;
        const imageDim = DrawableDimension.multiply(
          instanceScaledDim,
          imageInstanceScaleFactor,
        ).toInt();
        mediaCanvas.drawImgSourceCenter(
          (sData.instance as DrawableMediaInstance).drawable.imageSource,
          imageDim,
          displayRatio,
        );
        return {
          dimension: new DrawableMetrics(mediaCanvas.width, mediaCanvas.height),
          imageSource: mediaCanvas.canvas,
        };
      },
    };
  }
}

export class WswCanvasContentView extends AbstractView {
  constructor(
    grows: boolean,
    selector: Selector<WswSingleStimulus>,
    public canvasStrategy: CanvasStrategy<WswSingleStimulus>,
    public readonly includeGroupBorder: boolean,
    containerFactory?: ViewContainerFactory,
  ) {
    super(grows, selector, containerFactory);
  }

  createViewScaler(
    viewScalerMap: Map<View, ViewScaler>,
    selectorNodeMap: SelectorNodeMap,
  ): ViewScaler {
    return new WswCanvasContentViewScaler(
      viewScalerMap,
      this,
      selectorNodeMap,
      this.includeGroupBorder,
    );
  }

  static growing(
    selector: Selector<WswSingleStimulus>,
    includeGroupBorder: boolean,
    containerFactory?: ViewContainerFactory,
  ) {
    return ViewDefinition.Leaf(
      new WswCanvasContentView(
        true,
        selector,
        new WswCanvasStrategy(),
        includeGroupBorder,
        containerFactory,
      ),
    );
  }

  static fixed(
    selector: Selector<WswSingleStimulus>,
    includeGroupBorder: boolean,
    containerFactory?: ViewContainerFactory,
  ) {
    return ViewDefinition.Leaf(
      new WswCanvasContentView(
        false,
        selector,
        new WswCanvasStrategy(),
        includeGroupBorder,
        containerFactory,
      ),
    );
  }
}

export function GroupIndicationLabel({
  groupIndication,
}: {
  groupIndication: ColorMediaInstance;
}) {
  return (
    <span
      style={{
        position: 'absolute',
        backgroundColor: groupIndication.data.color,
        borderRadius: '3px',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        padding: '5px',
        whiteSpace: 'nowrap',
      }}
    >
      {groupIndication.data.textValue}
    </span>
  );
}

export function GroupIndicationView({
  groupStream,
}: {
  groupStream: Subject<ColorMediaInstance | undefined>;
}) {
  const [groupIndication, setGroupIndication] =
    React.useState<ColorMediaInstance>();
  React.useEffect(() => {
    const sub = groupStream.subscribe((val) => {
      setGroupIndication(val);
    });
    return () => sub.unsubscribe();
  }, [setGroupIndication, groupStream]);
  return groupIndication !== undefined ? (
    <GroupIndicationLabel groupIndication={groupIndication} />
  ) : (
    <></>
  );
}

export class WswCanvasContentViewScaler extends AbstractViewScaler {
  protected canvasStrategy: CanvasStrategy<WswSingleStimulus>;

  constructor(
    viewScalerMap: Map<View, ViewScaler>,
    view: CanvasContentView<WswSingleStimulus>,
    selectorNodeMap: SelectorNodeMap,
    public readonly includeGroupBorder: boolean,
  ) {
    super(viewScalerMap, view, selectorNodeMap);
    this.canvasStrategy = view.canvasStrategy;
  }

  async measureInner(): Promise<ViewScaler> {
    this.sizeState.innerSize = SizedArea.dimPx(
      this.canvasStrategy.scaleSize(
        this.sizeState.size,
        this.selectorNodeMap
          .getNodeMap(this.view.selector)
          .getDisplayDataSet()
          .map((d) => d[1][1]),
      ),
    );
    return this;
  }

  async measureMin(): Promise<ViewScaler> {
    this.sizeState.minSize = this.canvasStrategy.minSize(
      this.selectorNodeMap
        .getNodeMap(this.view.selector)
        .getDisplayDataSet() as any,
    );
    return this;
  }

  async scale(): Promise<ViewScaler> {
    const displayDataSet = this.selectorNodeMap
      .getNodeMap(this.view.selector)
      .getDisplayDataSet();
    const scaleSize = this.includeGroupBorder
      ? this.sizeState.innerSize.toDim().add(-24, -24).toInt()
      : this.sizeState.innerSize.toDim();
    const scaler = this.canvasStrategy.scaler(
      scaleSize,
      displayDataSet.map((dd) => dd[1][1]),
    );
    const dataDrawableMap = new Map(
      displayDataSet.map((dd) => [dd[1][1], scaler.scale(dd[1][1])]),
    );
    this.viewInstance = new ViewInstance(
      this,
      this.selectorNodeMap.getNodeMap(this.view.selector),
      (_, dataStream) => {
        const canvasSize = this.includeGroupBorder
          ? this.sizeState.innerSize.toDim().add(-24, -24).toInt()
          : this.sizeState.innerSize.toDim().toInt();
        return () => {
          const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
          const groupStream = React.useMemo(() => {
            return new Subject<ColorMediaInstance | undefined>();
          }, []);
          React.useEffect(() => {
            (
              dataStream as Observable<MeasurableValue<WswSingleStimulus>>
            ).subscribe({
              next: (value) => {
                groupStream.next(value.value.color ?? undefined);
                const context = canvasRef.current!.getContext('2d')!;
                context.clearRect(
                  0,
                  0,
                  canvasSize.width * displayRatio,
                  canvasSize.height * displayRatio,
                );
                context.drawImage(
                  dataDrawableMap.get(value.value)!.imageSource,
                  0,
                  0,
                ); //, canvasSize.width, canvasSize.height);
                const emptyBorder = this.includeGroupBorder
                  ? '12 px solid transparent'
                  : 'none';
                canvasRef.current!.style.border = value.value.color
                  ? '12px solid ' + value.value.color.data.color
                  : emptyBorder;
                value.measureProcessTime();
              },
            });
          }, [groupStream]);

          return (
            <div style={{ position: 'relative' }}>
              <GroupIndicationView groupStream={groupStream} />
              <canvas
                ref={canvasRef}
                style={{
                  ...this.sizeState.innerSize.rounded().toSizeModeStyle(),
                  backgroundColor: 'transparent',
                }}
                width={canvasSize.width * displayRatio}
                height={canvasSize.height * displayRatio}
              />
            </div>
          );
        };
      },
    );
    return this;
  }
}
