import { createSlice, isAnyOf, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { Podt, PodtResult } from "../types";
import { createInitialTestSliceState } from "../../utils";
import { TestType } from "../../../subject/types";
import {
  createPodtAsync,
  deletePodtAsync,
  fetchPodtResults,
  fetchPodtTest,
  fetchPodtTests,
  updatePodtAsync,
  updatePodtStatusAsync
} from "./podt.thunk";

export const podtSlice = createSlice({
  name: TestType.PODT,
  initialState: createInitialTestSliceState<Podt, PodtResult>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPodtTest.fulfilled, (state, action) => {
        state.tests[action.payload.id] = action.payload;
      })
      .addCase(fetchPodtTests.fulfilled, (state, action) => {
        state.tests = action.payload
          .sort(
            (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          )
          .reduce((result: { [id: string]: Podt }, currentValue: Podt | null) => {
          if (currentValue) {
            result[currentValue.id] = currentValue;
          }
          return result;
        }, {});
      })
      .addCase(fetchPodtResults.fulfilled, (state, action) => {
        state.results[action.payload.testId] = action.payload;
      })
      .addCase(deletePodtAsync.fulfilled, (state, action) => {
        const tests = { ...state.tests };
        delete tests[action.payload];
        state.tests = tests;
      })
      /*.addCase(deletePodtResultsAsync.fulfilled, (state, action) => {*/
        // if (state.results[action.payload.testId]) {
        //   state.results[action.payload.testId].results = state.results[action.payload.testId].results.filter(
        //     (r) => !action.payload.resultsIds.includes(r.id)
        //   );
        //   state.results[action.payload.testId].tableView.results = state.results[
        //     action.payload.testId
        //   ].tableView.results.filter((r) => !action.payload.resultsIds.includes(r.id));
        // }
   /*   })*/
      .addMatcher(
        isAnyOf(createPodtAsync.fulfilled, updatePodtAsync.fulfilled, updatePodtStatusAsync.fulfilled),
        (state, action) => {
          state.tests[action.payload.id] = action.payload;
        }
      )
      .addMatcher(isPending(), (state, action) => {
        if(action.type.toUpperCase().includes(TestType.PODT)) {
          state.isLoading = true;
        }
      })
      .addMatcher(isFulfilled(), (state) => {
        state.isLoading = false;
      })
      .addMatcher(isRejected(), (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  }
});

export default podtSlice.reducer;
