import { AbstractView } from './abstract-view';
import { SelectorNodeMap } from '../../selectors/selector-tree';
import { ViewContainerFactory } from '../../view-content-container';
import { View } from './view';
import { ObjectTransformer } from '../../../media/drawable/drawable-collector';
import { ViewDefinition } from '../view-definition-collection';
import { ViewScaler } from '../scaler/view-scaler';
import { LayoutContentComponentFactory, ViewBoxes } from '../../layouting-view';
import { BoxesDefinition } from '../boxes/boxes-definition';
import { GrowingLayoutViewScaler } from '../scaler/growing-layout-view-scaler';
import { FixedLayoutViewScaler } from '../scaler/fixed-layout-view-scaler';
import { Selector } from '../../selectors/selector';

export class LayoutingView<T extends string> extends AbstractView {
  boxes!: ViewBoxes<T>;

  constructor(
    grows: boolean,
    selector: Selector<any>,
    public boxesFactory: () => ViewBoxes<T>,
    public contentComponent: LayoutContentComponentFactory<T>,
    containerFactory?: ViewContainerFactory,
  ) {
    super(grows, selector, containerFactory);
  }

  protected initView(name: string) {
    this.boxes = this.boxesFactory();
    this.boxes.initBoxes(this);
  }

  protected initChildren(name: string): View[] {
    return this.boxes.boxes.flatMap((b) => {
      const pathNameAccessor = () => this.pathName + ':' + b._name;
      b.init(b._name, {
        get pathName() {
          return pathNameAccessor();
        },
      });
      return b.views;
    });
  }

  static growing<T extends string>(
    layout: {
      selector: Selector<any>;
      layout: BoxesDefinition<any, T>;
      contentComponent: LayoutContentComponentFactory<T>;
    },
    containerFactory?: ViewContainerFactory,
  ) {
    const subViewMap = ObjectTransformer.of(
      layout.layout.subComponents.definitionMap,
    ).mapValues((v) => v.view).result;
    const viewDefinition = ViewDefinition.Group(
      new LayoutingView(
        true,
        layout.selector,
        () => layout.layout.boxes(subViewMap),
        layout.contentComponent,
        containerFactory,
      ),
      layout.layout.subComponents,
    );
    ObjectTransformer.typedEntries(
      layout.layout.subComponents.definitionMap,
    ).forEach(([k, v]) => v.view.init(k, viewDefinition.view));
    return viewDefinition;
  }

  static fixed<T extends string>(
    layout: {
      selector: Selector<any>;
      layout: BoxesDefinition<any, T>;
      contentComponent: LayoutContentComponentFactory<T>;
    },
    containerFactory?: ViewContainerFactory,
  ) {
    const subViewMap = ObjectTransformer.of(
      layout.layout.subComponents.definitionMap,
    ).mapValues((v) => v.view).result;
    const viewDefinition = ViewDefinition.Group(
      new LayoutingView(
        false,
        layout.selector,
        () => layout.layout.boxes(subViewMap),
        layout.contentComponent,
        containerFactory,
      ),
      layout.layout.subComponents,
    );
    ObjectTransformer.typedEntries(
      layout.layout.subComponents.definitionMap,
    ).forEach(([k, v]) => v.view.init(k, viewDefinition.view));
    return viewDefinition;
  }

  createViewScaler(
    viewScalerMap: Map<View, ViewScaler>,
    selectorNodeMap: SelectorNodeMap,
  ): ViewScaler {
    return this.grows
      ? new GrowingLayoutViewScaler(viewScalerMap, selectorNodeMap, this)
      : new FixedLayoutViewScaler(viewScalerMap, selectorNodeMap, this);
  }
}
