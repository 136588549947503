import { ControlOccurrenceProcessor } from '../../../../subject/testRunner/graph-state/graph-action';
import { StateHolder } from '../../../../subject/testRunner/graph-state/state-holder';
import { GQLQueryInstance } from '../../../../../GQL';
import {
  CreatePodtResultMutationVariables,
  PodtBoxInput,
} from '../../../../../API';
import { Podt, PodtResult } from '../../types';
import { PodtStimulus } from '../../creator/podt-picker';
import { PodtBlockPhase } from '../structure/podt-block-structure';
import { PodtOrPodtaStructure } from '../structure/podt-structure';
import { Podta } from '../../../PODTA/types';
import { TestType } from '../../../../subject/types';
import { PodtResultQueries } from '../loading/podt.queries';
import { PodtaResultQueries } from '../../../PODTA/loader/loading/podta.queries';
import { v4 as uuid } from 'uuid';
import { omit } from 'lodash';

const optionsMap = {
  first: 'active',
  second: 'passive',
} as const;
export type ResultFactory = (
  control: ControlOccurrenceProcessor<any>,
  stateHolder: StateHolder,
  type: 'left' | 'right' | undefined,
  points: number,
  earnedPoints: number,
  actionName: string,
) => GQLQueryInstance<CreatePodtResultMutationVariables, PodtResult>;

export function createTrialResultFactory(
  trial: PodtStimulus,
  block: PodtBlockPhase,
  structure: PodtOrPodtaStructure<Podt | Podta>,
  trialIndex: number,
) {
  return (
    control: ControlOccurrenceProcessor<any>,
    stateHolder: StateHolder,
    type: 'left' | 'right' | undefined,
    points: number,
    earnedPoints: number,
    actionName: string,
  ) => {
    return (
      structure.accessor.testContext.testIdentity.testType === TestType.PODT
        ? PodtResultQueries.Create
        : (PodtaResultQueries.Create as unknown as typeof PodtResultQueries.Create)
    ).create({
      input: {
        testId: structure.accessor.modelData.id,
        clientId: uuid(),
        device: omit(structure.accessor.testContext.deviceInfo, 'mode'),
        status: structure.accessor.modelData.status,
        survey: structure.accessor.testContext.logic.resultEntry,
        timing: control.reactionTimeProcessor.toResultTiming(),
        blockIndex: block.blockIndex,
        blockName: block.blockName,
        blockType: block.blockType,
        ...control.occurrence.toResultData(),
        trialIndex: trialIndex,
        counterBalancingGroup:
          structure.config.options.arranger.cbGroup ?? null,
        accumulatedPoints: points,
        earnedPoints,
        reactionLeft: optionsMap[block.options.response!.left.optionVariant],
        reactionRight: optionsMap[block.options.response!.right.optionVariant],
        actionResultName: actionName, // type ? podtResponseMatrix[trial.target.metaData.variant][block.options.getOptionVariant("response", type)!] : podtResponseMatrix["timeout"],
        target: {
          dangerous: trial.target.metaData.variant === 'dangerous',
          category: trial.target.metaData.category,
          mediaItem: trial.target.value.data.toMediaItemSnapshot(),
        },

        scenes: [
          {
            interval: trial.scene.interval,
            scene: {
              mediaItem:
                trial.scene.scene.value.instance.data.toMediaItemSnapshot(),
              boxes: [trial.scene.box.toResultBox() as PodtBoxInput],
            },
          },
          ...trial.emptyScenes.map((eS) => ({
            interval: eS.interval,
            scene: {
              mediaItem: eS.scene.value.instance.data.toMediaItemSnapshot(),
              boxes: [],
            },
          })),
        ],
      },
    });
  };
}
