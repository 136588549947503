import React, { FC } from 'react';
import {
  AttributeCombination,
  WSWAttribute,
  WSWAttributeType,
  WSWGroup,
} from '../../../../../API';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

interface WswAttributeCombinationsViewProps {
  combinations: AttributeCombination[];
  mainAttribute: WSWAttribute;
  criteriaAttribute: WSWAttribute;
  additionalAttribute?: WSWAttribute | null;
  groups?: WSWGroup[];
}

export const WswAttributeCombinationsView: FC<
  WswAttributeCombinationsViewProps
> = ({
  combinations,
  groups,
  mainAttribute,
  criteriaAttribute,
  additionalAttribute,
}) => {
  const mapAttributeValues = React.useCallback(
    (attribute: WSWAttribute): { name: string; index: number | null }[] => {
      if (attribute.type === WSWAttributeType.STIMULI && attribute.categories) {
        return attribute.categories.map((cat, catIndex) => ({
          name: cat.name ?? '',
          index: catIndex,
        }));
      } else if (attribute.type === WSWAttributeType.GROUPS && groups) {
        return groups.map((group, groupIndex) => ({
          name: group.name ?? '',
          index: groupIndex,
        }));
      } else {
        throw new Error('Error mapping Attribute Values');
      }
    },
    [groups],
  );

  const mainAttributeValues = React.useMemo(
    () => mapAttributeValues(mainAttribute),
    [mainAttribute, mapAttributeValues],
  );

  const criteriaAttributeValues = React.useMemo(
    () => [
      ...mapAttributeValues(criteriaAttribute),
      {
        name: 'Distraktor',
        index: null,
      },
    ],
    [criteriaAttribute, mapAttributeValues],
  );

  const additionalAttributeValues = React.useMemo(
    () =>
      additionalAttribute
        ? mapAttributeValues(additionalAttribute)
        : [
            {
              name: '',
              index: null,
            },
          ],
    [additionalAttribute, mapAttributeValues],
  );

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell key="title-cell">
              <Typography variant="h6" color="textSecondary">
                Attribute Combinations
              </Typography>
            </TableCell>
            {mainAttributeValues.map(
              (mainAttributeCat, mainAttributeCatIndex) => (
                <TableCell
                  align="center"
                  key={`main-attribute-cat-${mainAttributeCatIndex}`}
                  colSpan={additionalAttributeValues.length}
                >
                  <b>{mainAttributeCat.name}</b>
                </TableCell>
              ),
            )}
          </TableRow>
          {!!additionalAttribute && (
            <TableRow>
              <TableCell key="empty-cell-2" />
              {mainAttributeValues.flatMap((_, i) =>
                additionalAttributeValues.map(
                  (additionalAttributeCat, additionalAttributeCatIndex) => (
                    <TableCell
                      key={`additional-attribute-cat-${additionalAttributeCatIndex}-${i}`}
                      align="center"
                    >
                      {additionalAttributeCat.name}
                    </TableCell>
                  ),
                ),
              )}
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {criteriaAttributeValues.map((cA, cAI) => (
            <TableRow key={`table-row-${cA}-${cAI}`}>
              <TableCell>{cA.name}</TableCell>
              {mainAttributeValues.flatMap((mAV, mAVi) =>
                additionalAttributeValues.map((aAV, aAVi) => {
                  const findIndex = combinations.findIndex((comb) => {
                    return (
                      comb.mainCategory === mAV.index &&
                      comb.additionalCategory === aAV.index &&
                      cA.index === comb.criteriaCategory
                    );
                  });
                  return (
                    <TableCell
                      align="center"
                      key={`matrices-element-${mAVi}-${aAVi}-${cAI}`}
                    >
                      <Typography>{combinations[findIndex]?.amount}</Typography>
                    </TableCell>
                  );
                }),
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
