import { ResultTimingInput } from '../../../../API';

export class ReactionTimeProcessor {
  public renderStart?: number;
  public renderEnd?: number;
  public controlStart?: number;
  public controlEnd?: number;
  public raceStart?: number;

  constructor(public readonly offset: number) {}

  processTime(
    timeType:
      | 'renderStart'
      | 'renderEnd'
      | 'controlStart'
      | 'controlEnd'
      | 'raceStart',
    time?: number,
  ) {
    if (timeType === 'renderEnd') {
      this.renderEnd = Math.max(this.renderEnd ?? 0, time ?? performance.now());
      return;
    }
    if (this[timeType] !== undefined) {
      throw new Error(
        'reaction time type ' + timeType + ' has already been processed',
      );
    }
    this[timeType] = time ?? performance.now();
  }

  toResultTiming(): ResultTimingInput {
    if (this.controlStart === undefined) {
      throw new Error('No control trigger start has been processed yet');
    }
    return {
      renderStartDelay:
        this.renderStart !== undefined
          ? Math.round(this.renderStart - this.offset)
          : null,
      renderEndDelay:
        this.renderEnd !== undefined
          ? Math.round(this.renderEnd - this.offset)
          : null,
      startTriggerDelay: Math.round(this.controlStart - this.offset),
      controlRaceDelay:
        this.raceStart !== undefined
          ? Math.round(this.raceStart - this.offset)
          : null,
      endTriggerDelay:
        this.controlEnd !== undefined
          ? Math.round(this.controlEnd - this.offset)
          : null,
    };
  }
  static StartProcessor() {
    return new ReactionTimeProcessor(performance.now());
  }
}
