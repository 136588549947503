import { isEqual } from 'lodash';

export type OptionalValue<D> = ['not-set'] | ['value', D];
export type RenderDataType<D> =
  | ['not-set']
  | ['hide']
  | ['display', OptionalValue<D>];

export const Optional = {
  none: <D>() => ['not-set'] as OptionalValue<D>,
  value: <D>(value: D) => ['value', value] as OptionalValue<D>,
  isNone: <D>(data: OptionalValue<D>): data is ['not-set'] =>
    data[0] === 'not-set',
  isValue: <D>(data: OptionalValue<D>): data is ['value', D] =>
    data[0] === 'value',
};
export const DisplayData = {
  none: <D>() => ['not-set'] as RenderDataType<D>,
  hide: <D>() => ['hide'] as RenderDataType<D>,
  display: <D>(data: OptionalValue<D>) =>
    ['display', data] as ['display', OptionalValue<D>],
  displayData: <D>(data: D) => DisplayData.display(Optional.value(data)),
  displayNone: <D>() => DisplayData.display<D>(Optional.none<D>()),
  isNone: (data: RenderDataType<any>): data is ['not-set'] =>
    data[0] === 'not-set',
  isHide: (data: RenderDataType<any>): data is ['hide'] => data[0] === 'hide',
  isDisplay: <D>(
    data: RenderDataType<D>,
  ): data is ['display', OptionalValue<D>] => data[0] === 'display',
  isDisplayNone: <D>(
    data: RenderDataType<D>,
  ): data is ['display', ['not-set']] =>
    data[0] === 'display' && data[1][0] === 'not-set',
  isDisplayData: <D>(
    data: RenderDataType<D>,
  ): data is ['display', ['value', D]] =>
    data[0] === 'display' && data[1][0] === 'value',
  variant: <D>(data: RenderDataType<D>): 'not-set' | 'display' | 'hide' =>
    data[0],
  compare: <D>(data: RenderDataType<D>, other: RenderDataType<D>): boolean => {
    if (DisplayData.variant(data) !== DisplayData.variant(other)) {
      return false;
    }
    if (DisplayData.isDisplayData(data)) {
      if (
        !DisplayData.isDisplayData(other) ||
        !isEqual(data[1][1], other[1][1])
      ) {
        return false;
      }
    } else {
      return !DisplayData.isDisplayData(other);
    }
    return true;
  },
};
