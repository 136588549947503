import { OptionsScreenData } from '../../../../subject/testRunner/screens/basic-views/option-test-views';
import { TipsContent } from '../../../../subject/testRunner/screens/basic-views/tips-content';
import { OptionCategoriesArrangement } from '../../../../subject/testRunner/media/options-test';
import { InstructionsContent } from '../../../../subject/testRunner/screens/basic-views/test-instructions-view';
import { StimulusContent } from '../../../../subject/testRunner/screens/basic-views/stimulus-content';
import { TextMediaStyle } from '../../../../subject/testRunner/media/text-drawer';
import { ControlTransition } from '../../../../subject/testRunner/controls/control-transition';
import { IatScreenData } from './iat-screen-data';
import { IatOptionsType } from '../structure/iat-options-type';

export class IatOptionsData
  extends IatScreenData
  implements OptionsScreenData<IatOptionsType>
{
  tips?: TipsContent;
  options: OptionCategoriesArrangement<IatOptionsType>;
  content: InstructionsContent | StimulusContent | null;

  constructor(
    tips: { content: string; textStyle: TextMediaStyle } | null,
    options: OptionCategoriesArrangement<IatOptionsType>,
    content: InstructionsContent | StimulusContent | null,
    controlTransitions?: ControlTransition<any, any>[],
  ) {
    super(controlTransitions);
    this.tips = tips;
    this.options = options;
    this.content = content;
  }
}
