import { PickingSet } from '../PickingSet';
import { PickingError } from '../error/PickingError';

export class NonRandomPickingSet<T> implements PickingSet<T> {
  private universe: T[];
  constructor(universe: T[]) {
    this.universe = [...universe];
  }
  validate() {
    if (this.universe.length !== 0) {
      throw new PickingError('array not empty');
    }
  }
  pick() {
    if (this.universe.length <= 0) {
      throw new PickingError('array empty');
    }
    return this.universe.shift()!;
  }
}
