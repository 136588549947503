import { BasicDimension } from '../../media/drawable-dimensions';
import React, { PropsWithChildren } from 'react';

export interface MeasureRendererContextType {
  maxSize: BasicDimension;
  measurePhase: boolean;
}

export const MeasureRendererContext =
  React.createContext<MeasureRendererContextType>({
    maxSize: { width: 0, height: 0 },
    measurePhase: false,
  });

export function MeasureRendererContextProvider({
  contextProps,
  children,
}: PropsWithChildren<{ contextProps: MeasureRendererContextType }>) {
  return (
    <MeasureRendererContext.Provider value={contextProps}>
      {children}
    </MeasureRendererContext.Provider>
  );
}

export function useMeasureRendererContext() {
  const context = React.useContext(MeasureRendererContext);
  return context.measurePhase ? context : undefined;
}
