import { BlockType, PickingStrategy } from '../../../../../API';
import { TestOptionCategories } from '../../../../subject/testRunner/media/options-test';
import { IatOptionsType } from './iat-options-type';
import { IatMediaPool } from '../loading/load-iat-media';
import { MindSetRandomUtils } from '../../../pickingSet/picking-utils';
import { newPickNCollect } from '../../../pickingSet/picking-set';
import { createPickingSet } from '../../../pickingSet/picking-strategy';
import { zip } from 'lodash';

import { IatBlockDescriptor } from './iat-block-structure';

export class IatPicker {
  constructor(
    protected pickingStrategy: PickingStrategy,
    protected options: TestOptionCategories<IatOptionsType>,
    protected pool: IatMediaPool['test']['presentations'],
  ) {}

  pickBlock(block: IatBlockDescriptor) {
    const itemSequences = block.options.arrangementVariant.items.map((type) => {
      return MindSetRandomUtils.shuffleArray(
        this.pool
          .select({
            category: [type],
            blockType: BlockType.NON_PRACTICE,
          })
          .collectGrouped(['variant'])
          .map((variantSet) => {
            return newPickNCollect(
              createPickingSet(
                `presentations-${type}`,
                this.pickingStrategy,
                variantSet,
              ),
              block.amountTrials /
                (2 * block.options.arrangementVariant.items.length),
            );
          })
          .flat(),
      );
    });
    return zip(...itemSequences).flat();
  }
}
