import {
  TranslationKeyValueTypeMap,
  translationValueTypeHandlerMap,
} from './translation-value';
import { TranslationSource } from './translation-store';

export type TranslationModifier = 'mobile' | 'desktop';
export type LanguageCode = string | `${string}${`:${TranslationModifier}`}`;

export type TranslationDefault<V extends keyof TranslationKeyValueTypeMap> =
  Record<LanguageCode, TranslationKeyValueTypeMap[V]>;

export class LanguageTranslationMap<
  V extends keyof TranslationKeyValueTypeMap,
> {
  constructor(public readonly defaultMap: TranslationDefault<V>) {}

  getValue(code: LanguageCode): TranslationKeyValueTypeMap[V] | undefined {
    const [lang] = code.split(':');
    return this.defaultMap[code] ?? this.defaultMap[lang];
  }
}

export class StaticTranslationKey<V extends keyof TranslationKeyValueTypeMap> {
  public readonly defaults: LanguageTranslationMap<V>;
  constructor(
    public readonly key: string,
    public readonly type: V,
    defaults: TranslationDefault<V>,
    public readonly name: string,
  ) {
    this.defaults = new LanguageTranslationMap(defaults);
  }

  translate(
    lang: LanguageCode,
    source: TranslationSource,
  ): TranslationKeyValueTypeMap[V] | undefined {
    const value = source(lang, this.key);
    return translationValueTypeHandlerMap[this.type](
      value ?? this.defaults.getValue(lang),
    ) as TranslationKeyValueTypeMap[V];
  }
}
