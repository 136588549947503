import {
  Describable,
  DynamicParameterController,
  ParametersManagerDefinition,
  StaticParameterController,
} from '../../../../subject/testRunner/media/test-arranger';
import { Wsw } from '../../types';
import { WswConstructedGroup } from '../structure/wsw-structure';
import { MappingPickingSet } from '../../../pickingSet/CombinedPickingSet/MappingPickingSet';
import { createPickingSet } from '../../../pickingSet/picking-strategy';
import { PickingStrategy, WSWGroup } from '../../../../../API';
import { NonRandomPickingSet } from '../../../pickingSet/nonRandomPickingSet/NonRandomPickingSet';
import { MindSetRandomUtils } from '../../../pickingSet/picking-utils';
import { weightedSet } from '../../../pickingSet/weightedSet/WeightedSet';

export const WswParametersDefinition = new ParametersManagerDefinition<
  Wsw,
  Record<
    'groups',
    Describable & {
      groups: {
        groups: WswConstructedGroup[];
        groupCondition: {
          type: 'never' | 'before-learning' | 'after-learning';
          group?: WswConstructedGroup;
        };
      } | null;
    }
  >
>({
  groups: (test) =>
    test.modelData.groupConstruction
      ? new DynamicParameterController('group condition', (context) => {
          const colorPicker = new MappingPickingSet(
            test.modelData.groupConstruction!.randomizeGroupColors
              ? createPickingSet(
                  'group-colors',
                  PickingStrategy.PERMUTATION,
                  test.modelData.groupConstruction!.groups,
                )
              : new NonRandomPickingSet(
                  test.modelData.groupConstruction!.groups,
                ),
            (group) => group.color,
          );
          const groups: WswConstructedGroup[] =
            test.modelData.groupConstruction!.groups.map((group, index) => ({
              group: group as WSWGroup,
              index,
              color: colorPicker.pick(),
            }));
          const groupConditionKey = MindSetRandomUtils.choseFromDistribution(
            {
              never: test.modelData.groupConstruction!.conditions.condition1,
              'before-learning':
                test.modelData.groupConstruction!.conditions.condition2,
              'after-learning':
                test.modelData.groupConstruction!.conditions.condition3,
            },
            context.logic.parameterRandom,
          );

          return {
            describe(): string {
              return 'no description provided';
            },
            groups: {
              groups,
              groupCondition:
                groupConditionKey === 'never'
                  ? { type: groupConditionKey }
                  : {
                      type: groupConditionKey,
                      group: MindSetRandomUtils.chooseArrayItem(
                        weightedSet(
                          groups.map((g) => [g, g.group.probability!]),
                        ),
                      ),
                    },
            },
          };
        })
      : (new StaticParameterController('groups', {
          describe(): string {
            return 'no groups';
          },
          groups: null,
        }) as any),
});
