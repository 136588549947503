import { OptionsScreenData } from '../../../../subject/testRunner/screens/basic-views/option-test-views';
import { PodtOptionsType } from '../loading/podt-loader';
import { TipsContent } from '../../../../subject/testRunner/screens/basic-views/tips-content';
import { OptionCategoriesArrangement } from '../../../../subject/testRunner/media/options-test';
import { InstructionsContent } from '../../../../subject/testRunner/screens/basic-views/test-instructions-view';
import { StimulusContent } from '../../../../subject/testRunner/screens/basic-views/stimulus-content';
import { PodtResultContent } from './podt-result-content';
import { LazyStimulusContent } from './lazy-stimulus-content';
import { TextMediaStyle } from '../../../../subject/testRunner/media/text-drawer';
import { ControlTransition } from '../../../../subject/testRunner/controls/control-transition';
import { PodtScreenData } from './podt-screen-data';

export class PodtOptionsData
  extends PodtScreenData
  implements OptionsScreenData<PodtOptionsType>
{
  tips?: TipsContent;
  options: OptionCategoriesArrangement<PodtOptionsType>;
  content:
    | InstructionsContent
    | StimulusContent
    | null
    | PodtResultContent
    | LazyStimulusContent;

  constructor(
    tips: { content: string; textStyle: TextMediaStyle } | null,
    options: OptionCategoriesArrangement<PodtOptionsType>,
    content:
      | InstructionsContent
      | StimulusContent
      | LazyStimulusContent
      | PodtResultContent
      | null,
    controlTransitions?: ControlTransition<any, any>[],
  ) {
    super(controlTransitions);
    this.tips = tips;
    this.options = options;
    this.content = content;
  }
}
