import React, { FC } from "react";
import { ImageInstanceInput, PodtaBoxInput, PodtaSceneInput } from "../../../../../API";
import { List, ListItem } from "@mui/material";
import MediaOffscreenCanvas from "../../../../subject/MediaOffscreenCanvas";
import S3Image from "../../../../../components/S3Image";
import PodtaSceneBoxEditor from "./PodtaSceneBoxEditor";

interface EditableSceneListProps {
  scenes: PodtaSceneInput[];
  scenesCache: { [id: string]: MediaOffscreenCanvas };
  setBoxes: (id: string) => (boxes: PodtaBoxInput[]) => void;
  staticHeight: { isLocked: boolean; value: number };
}

export const PodtaSceneSelection: FC<EditableSceneListProps> = ({ scenes, scenesCache, staticHeight, setBoxes }) => {

  const [activeIndex, setActiveIndex] = React.useState(0);

  const currentScene = scenes[activeIndex];

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <List style={{ paddingTop: 0, height: 450, overflow: "auto" }} dense>
        {scenes.map((scene, sceneIndex) => (
          <ListItem
            dense
            button
            onClick={() => setActiveIndex(sceneIndex)}
            selected={sceneIndex === activeIndex}
          >
            <S3Image
              fullWidth
              imgInstance={scene.mediaItem.image?.thumbNail as ImageInstanceInput}
            />
          </ListItem>
        ))}
      </List>
      <div style={{ marginLeft: 24 }}>
        {currentScene.mediaItem.id &&
          <PodtaSceneBoxEditor
            key={`sceneBoxEditor${activeIndex}`}
            withMenu
            active={true}
            state={scenes[activeIndex]}
            offscreenCanvas={scenesCache[scenes[activeIndex].mediaItem.id ?? ""]}
            setBoxes={setBoxes(currentScene.mediaItem.id)}
            isLockedHeight={staticHeight.isLocked}
          />}
      </div>
    </div>
  );
};
