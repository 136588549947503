import React, {FC} from "react";
import {useField} from "formik";
import { Checkbox, FormControlLabel, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";

export const FormikCheckboxField: FC<{ name: string; label: string; sx?: SxProps<Theme>; size?: "small" }> = (props) => {
  const [, meta, helpers] = useField<boolean | undefined | null>(props.name);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(event.target.checked);
  };

  return (
    <FormControlLabel
      sx={props.sx}
      control={
        <Checkbox
          size={props.size}
          checked={Boolean(meta.value)}
          onChange={handleChange}
        />
      }
      label={props.size === "small" ? <Typography variant="body2">{props.label}</Typography> : <Typography variant="inherit">{props.label}</Typography>}
    />
  )
}
