import { SetupDocumentationStepType, SurveyProvider } from '../../../API';
import { TestType } from '../../subject/types';
import { BaseTest } from '../types';
import { createSurveyToolProvider } from '../../subject/testRunner/state/baseTestContext/context/baseTestContext';
import { createCodeSnippet } from '../../../components/SetupInstructions/utils';
import { Button, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import CodeSnippet from '../../../components/SetupInstructions/CodeSnippet';
import S3Image from '../../../components/S3Image';
import { SetupStepTimelineItem } from './SetupStepTimelineItem';
import StyledDialog from '../../../components/dialog/StyledDialog';

function createSnippetShareLink(
  testType: TestType,
  testUrl: string,
  testName: string,
) {
  return `${window.location.origin}/share-snippet?testType=${encodeURIComponent(testType)}&testUrl=${encodeURIComponent(
    testUrl,
  )}&testName=${encodeURIComponent(testName)}`;
}

export function SurveyProviderInstructionSteps({
  surveyProvider,
  surveyId,
  testType,
  test,
}: {
  surveyProvider: SurveyProvider;
  surveyId: string;
  testType: TestType;
  test: BaseTest;
}) {
  const [imageDialogState, setImageDialogState] = React.useState(false);
  const surveyProviderInstance = createSurveyToolProvider(surveyProvider.slug, {
    surveyProbandId: surveyProvider.snippetConfiguration.probandIdReplacer,
    surveyId: surveyProvider.snippetConfiguration.surveyIdReplacer
      ? surveyId
      : encodeURIComponent(surveyId),
  });
  const testUrl = surveyProviderInstance.createTestUrl(
    { testId: test.id, testType },
    'execution',
    undefined,
    test.language
      ? {
          lang: test.language,
        }
      : {},
  );
  const codeSnippet = createCodeSnippet(testType, testUrl);
  const shareLink = createSnippetShareLink(testType, testUrl, test.name);
  return (
    <>
      {surveyProvider.setupDocumentation.steps.map((step, i) => {
        return (
          <SetupStepTimelineItem
            stepIndex={
              i + (surveyProvider.snippetConfiguration.surveyIdReplacer ? 2 : 1)
            }
            stepContent={
              <Stack spacing={1}>
                <Typography variant="h4">{`Step ${i + 1}`}</Typography>
                <Typography variant="body1" color="text.secondary">
                  {step.textBeforeContent}
                </Typography>
                {step.type === SetupDocumentationStepType.SNIPPET && (
                  <CodeSnippet
                    codeSnippet={codeSnippet}
                    shareLink={shareLink}
                  />
                )}
                {step.type === SetupDocumentationStepType.IMAGE &&
                  !!step.image && (
                    <>
                      <Tooltip title="click on the screenshot to expand it">
                        <Button onClick={() => setImageDialogState(true)}>
                          <S3Image containerScale imgInstance={step.image} />
                        </Button>
                      </Tooltip>
                      <StyledDialog
                        fullScreen
                        open={imageDialogState}
                        onClose={() => setImageDialogState(false)}
                        label={`Step ${
                          i +
                          (surveyProvider.snippetConfiguration.surveyIdReplacer
                            ? 2
                            : 1)
                        }`}
                      >
                        <S3Image containerScale imgInstance={step.image} />
                      </StyledDialog>
                    </>
                  )}
              </Stack>
            }
          />
        );
      })}
    </>
  );
}
