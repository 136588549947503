import { useEffect, useMemo, useState } from "react";
import { PodtaScenesInput } from "../../../../../API";
import { createCache } from "../../../../subject/MediaItemCache";
import MediaOffscreenCanvas from "../../../../subject/MediaOffscreenCanvas";
import { TypeObject } from "../../../../subject/utils";

export function usePodtaScenesCache(scenes: PodtaScenesInput["scenes"]) {

  const mediaCache = useMemo(() => {
    return createCache();
  }, []);

  const [scenesCache, setScenesCache] = useState<{ [id: string]: MediaOffscreenCanvas }>();

  useEffect(() => {
    Promise.all(
      scenes.map((s) =>
        mediaCache.prerender(s.mediaItem, {
          image: {
            thumb: false
          },
          text: {}
        })
      )
    ).then((res) => {
      setScenesCache(
        TypeObject.fromEntries(
          res.map((c) => {
            // mediaItem.id is null | undefined extactly if it represents a text
            return [c.mediaItem.id ?? '', c.offscreenCanvas];
          })
        )
      );
    });
  }, [mediaCache, scenes]);

  return scenesCache;
}
