import {
  DisplayData,
  Optional,
  OptionalValue,
} from '../../../subject/testRunner/screens/selectors/data-wrapper';
import { ArrangementVariant } from '../../../subject/testRunner/media/options-test';

import { SelectorTree } from '../../../subject/testRunner/screens/selectors/selector-tree';
import {
  DrawableMediaHandle,
  DrawableMediaInstance,
} from '../../../subject/testRunner/media/MediaData';
import { InstructionsContent } from '../../../subject/testRunner/screens/basic-views/test-instructions-view';
import { PodtScreenData } from '../loader/view-data/podt-screen-data';
import { PodtOptionsType } from '../loader/loading/podt-loader';
import { defineOptionSideSelectorFactory } from '../../../subject/testRunner/screens/basic-views/option-test-views';
import { TipsContent } from '../../../subject/testRunner/screens/basic-views/tips-content';
import { PodtInstructionsData } from '../loader/view-data/podt-instructions-data';
import { PodtResultContent } from '../loader/view-data/podt-result-content';
import { LazyStimulusContent } from '../loader/view-data/lazy-stimulus-content';
import { PodtOptionsData } from '../loader/view-data/podt-options-data';
import { defineContentSelector } from '../../../subject/testRunner/screens/selectors/content-selector';
import { defineGroupSelector } from '../../../subject/testRunner/screens/selectors/group-selector';

const optionSelectorFactory = defineOptionSideSelectorFactory<
  PodtOptionsType,
  'response'
>(new ArrangementVariant<PodtOptionsType>(['response']));

const podtRootSelector = defineGroupSelector({
  selectGroupData(
    input: PodtScreenData,
  ): OptionalValue<PodtInstructionsData | PodtOptionsData> {
    return input instanceof PodtInstructionsData ||
      input instanceof PodtOptionsData
      ? Optional.value(input)
      : Optional.none();
  },
  children: {
    instructions: defineContentSelector({
      selectRenderData: (input: PodtInstructionsData | PodtOptionsData) => {
        return input instanceof PodtInstructionsData
          ? DisplayData.displayData<InstructionsContent>(input.content)
          : DisplayData.hide<InstructionsContent>();
      },
    }),
    options: defineGroupSelector({
      selectGroupData(
        input: PodtInstructionsData | PodtOptionsData,
      ): OptionalValue<PodtOptionsData> {
        return input instanceof PodtOptionsData
          ? Optional.value(input)
          : Optional.none();
      },
      children: {
        tips: defineContentSelector({
          selectRenderData: (input: PodtOptionsData) => {
            return input.tips
              ? DisplayData.displayData<TipsContent>(input.tips)
              : DisplayData.hide<TipsContent>();
          },
        }),
        stimulus: defineContentSelector({
          selectRenderData: (input: PodtOptionsData) => {
            if (
              !(
                input.content instanceof InstructionsContent ||
                input.content instanceof PodtResultContent ||
                input.content instanceof LazyStimulusContent
              )
            ) {
              return DisplayData.displayData<DrawableMediaInstance | undefined>(
                input.content?.stimulus,
              );
            }
            return DisplayData.hide<DrawableMediaInstance | undefined>();
          },
        }),
        lazyStimulus: defineContentSelector({
          selectRenderData: (input: PodtOptionsData) => {
            if (input.content instanceof LazyStimulusContent) {
              return DisplayData.displayData<DrawableMediaHandle>(
                input.content.handle,
              );
            }
            return DisplayData.hide<DrawableMediaHandle>();
          },
        }),
        instructions: defineContentSelector({
          selectRenderData: (input: PodtOptionsData) => {
            if (
              input.content instanceof InstructionsContent &&
              input.content.nextButton === null
            ) {
              return DisplayData.displayData<InstructionsContent>(
                input.content,
              );
            }
            return DisplayData.hide<InstructionsContent>();
          },
        }),
        instructionsWithButton: defineContentSelector({
          selectRenderData: (input: PodtOptionsData) => {
            if (
              input.content instanceof InstructionsContent &&
              input.content.nextButton !== null
            ) {
              return DisplayData.displayData<InstructionsContent>(
                input.content,
              );
            }
            return DisplayData.hide<InstructionsContent>();
          },
        }),
        result: defineContentSelector({
          selectRenderData: (input: PodtOptionsData) => {
            if (input.content instanceof PodtResultContent) {
              return DisplayData.displayData(input.content);
            }
            return DisplayData.hide<PodtResultContent>();
          },
        }),
        optionsLeft: optionSelectorFactory('left'),
        optionsRight: optionSelectorFactory('right'),
      },
    }),
  },
});
export const PODTSelectorTree = new SelectorTree('podt', podtRootSelector);
