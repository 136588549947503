import { TestBaseContext } from '../state/baseTestContext/context/ContextTypes';
import { FC } from 'react';
import { BaseTest } from '../../../tests/types';
import { TestResourceAccessor } from './test-resource-loader';

export interface Describable {
  describe(): string;

  describeElement?: () => JSX.Element;
}

export type BaseParametersMap = Record<string, Describable>;

export interface ParameterController<E extends Describable> {
  readonly name: string;
  readonly variable: boolean;

  determine(context: TestBaseContext): E;

  component?: FC<{
    value: E;
    updateValue: (value: E) => void;
    variable: boolean;
  }>;
}

export class StaticParameterController<E extends Describable>
  implements ParameterController<E>
{
  readonly variable = false;

  constructor(
    public name: string,
    public value: E,
    public component?: FC<{
      value: E;
      updateValue: (value: E) => void;
      variable: boolean;
    }>,
  ) {}

  determine(context: TestBaseContext): E {
    return this.value;
  }
}

export class DynamicParameterController<E extends Describable>
  implements ParameterController<E>
{
  readonly variable = true;

  constructor(
    public name: string,
    public valueGenerator: (testContext: TestBaseContext) => E,
    public component?: FC<{
      value: E;
      updateValue: (value: E) => void;
      variable: boolean;
    }>,
  ) {}

  determine(context: TestBaseContext): E {
    return this.valueGenerator(context);
  }
}

export type ParameterControllerFactory<
  T extends BaseTest,
  E extends Describable,
> = (test: TestResourceAccessor<T>) => ParameterController<E>;
export type ParameterControllerMap<S extends BaseParametersMap> = {
  [k in keyof S]: S[k] extends Describable ? ParameterController<S[k]> : never;
};
export type ParameterControllerFactoryMap<
  T extends BaseTest,
  S extends BaseParametersMap,
> = {
  [k in keyof S]: S[k] extends Describable
    ? ParameterControllerFactory<T, S[k]>
    : never;
};

export class ParametersManager<S extends BaseParametersMap> {
  constructor(public readonly controllerMap: ParameterControllerMap<S>) {}

  get controllers() {
    return Object.values(this.controllerMap);
  }

  determine(context: TestBaseContext, overrides?: Partial<S>): S {
    return {
      ...(Object.fromEntries(
        Object.entries(this.controllerMap).map(([k, v]) => [
          k,
          (v as ParameterController<Describable>).determine(context),
        ]),
      ) as S),
      ...overrides,
    };
  }
}

export class ParametersManagerDefinition<
  T extends BaseTest,
  S extends BaseParametersMap,
> {
  private definitionMap: ParameterControllerFactoryMap<T, S>;

  constructor(definitionMap: ParameterControllerFactoryMap<T, S>) {
    this.definitionMap = definitionMap;
  }

  createManager(test: TestResourceAccessor<T>): ParametersManager<S> {
    return new ParametersManager<S>(
      Object.fromEntries(
        Object.entries(this.definitionMap).map(
          ([k, v]) =>
            [
              k,
              (v as ParameterControllerFactory<T, Describable>)(test),
            ] as const,
        ),
      ) as unknown as ParameterControllerMap<S>,
    );
  }
}
