import { MindSetRandomUtils } from './picking-utils';
import { Picker } from './Picker';

export interface PickingBehaviour {
  name: string;
  createPicker<T>(universe: T[]): Picker<T>;
}

export const PickingBehaviours = {
  WithRefill: {
    name: 'WithRefill',
    createPicker: <T>(universe: T[]): Picker<T> => {
      let shuffledUniverse = MindSetRandomUtils.shuffleArray(universe);
      return {
        next: () => {
          if (shuffledUniverse.length === 0) {
            shuffledUniverse = MindSetRandomUtils.shuffleArray(universe);
          }
          return shuffledUniverse.pop()!;
        },
      };
    },
  },
  WithReplace: {
    name: 'WithReplace',
    createPicker: <T>(universe: T[]): Picker<T> => {
      return {
        next: () => {
          return MindSetRandomUtils.chooseArrayItem(universe);
        },
      };
    },
  },
};
