import React, { FunctionComponent } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Avatar, Button, Chip, ChipProps, Typography } from '@mui/material';
import { FontFace, MediaItemSnapshotInput } from '../../API';
import { renderMediaItem } from './picker/pickerUtils';
import { mediaItemStyles } from './MediaItemComponent';
import clsx from 'clsx';
import { FontFaceFamilyMap } from '../../components/fontUtils';

const useStyles = makeStyles((theme) => ({
  mediaItemList: {
    position: 'relative',
    // margin: "-0.5%",
    // padding: `${theme.spacing(1)} 0`
  },
  chip: {
    ...(mediaItemStyles as any),
    minHeight: 55,
    width: 55,
    margin: '1%',

    '& .MuiAvatar-root': {
      width: 109,
      height: 109,
      border: '5px solid white',
      overflow: 'hidden',
      background: 'transparent',
      margin: 0,
    },
    '& svg': {
      display: 'inline-block',
      position: 'absolute',
      top: theme.spacing(0.05),
      right: theme.spacing(-0.45),
      mixBlendMode: 'difference!important',
      color: 'white!important',
      opacity: 0.3,
      '&:hover': {
        opacity: 0.6,
      },
    },
    '& .MuiAvatar-root + span': {
      display: 'none',
    },
    '& p.MuiChip-avatar': {
      width: '95%',
      fontSize: '1.1em',
      margin: 0,
      color: theme.palette.text.primary,
    },
    '&:not(.MuiChip-deletable) .MuiChip-label, &:not(.MuiChip-deletable) .MuiSvgIcon-root':
      {
        display: 'none',
      },
  },
  avatar: {
    fontSize: 14,

    '& img': {
      display: 'inline-block',
      borderRadius: '50%',
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.short,
      }),
    },
  },
  imageAvatar: {
    backgroundColor: 'transparent!important',
    '&:hover': {
      overflow: 'visible',
    },
    '&:hover img': {
      maxWidth: 'auto',
      borderRadius: 5,
      boxShadow: theme.shadows[2],
    },
  },
}));

type MediaItemListProps = ChipProps & {
  items: Array<MediaItemSnapshotInput>;
  onDelete?: ((event: any) => void) | undefined;
  datatype?: string;
  fontColor?: string;
  fontSize?: number;
  fontFace?: FontFace;
  bgColor?: string;
};

const MediaItemList: FunctionComponent<MediaItemListProps> = ({
  items,
  datatype,
  onDelete,
  fontColor,
  fontSize,
  fontFace,
  bgColor,
}) => {
  const [showAll, setShowAll] = React.useState(false);
  const displayItems = items?.slice(0, showAll ? undefined : 2);

  const classes = useStyles();

  const avatarItemStyle = {
    fontSize: fontSize,
    fontFace: fontFace ? FontFaceFamilyMap[fontFace] : undefined,
    color: fontColor || 'inherit',
    background: bgColor || 'transparent',
  };

  return (
    <div className={classes.mediaItemList}>
      <Typography
        sx={{ position: 'absolute', right: 0, top: 8, color: 'text.secondary' }}
      >
        {items?.length}
      </Typography>
      {displayItems?.map((mI) => (
        <Chip
          key={mI.id}
          className={classes.chip}
          avatar={
            <Avatar
              className={clsx(classes.avatar, !mI.text && classes.imageAvatar)}
              style={avatarItemStyle}
            >
              {renderMediaItem(mI)}
            </Avatar>
          }
          // onDelete={onDelete ? () => onDelete && onDelete(mI.id) : undefined}
          datatype={datatype}
        />
      ))}
      {items?.length > 2 && (
        <React.Fragment>
          <Button size="small" onClick={() => setShowAll((p) => !p)}>
            {showAll ? 'Show Less' : '... Show All'}
          </Button>
        </React.Fragment>
      )}
    </div>
  );
};

export default MediaItemList;
