import { AbstractViewScaler } from './abstract-view-scaler';
import { BasicDimension } from '../../../media/drawable-dimensions';
import { createIndependentDOMMeasurer } from '../../dom-measure/measure-render';
import { LayoutContentComponentFactory } from '../../layouting-view';
import { BoxMeasureProvider } from '../boxes/box-measure-provider';
import { MeasureContainerProvider } from '../../dom-measure/measure-container-provider';

export abstract class AbstractLayoutViewScaler extends AbstractViewScaler {
  protected abstract contentComponent: LayoutContentComponentFactory<any>;

  protected async measureDOM(
    size: BasicDimension,
    boxProvider: BoxMeasureProvider<any>,
    containerProvider: MeasureContainerProvider,
  ): Promise<BasicDimension> {
    const measurerController = await createIndependentDOMMeasurer(
      this.sizeState.maxSize,
    );
    const Component = this.contentComponent(containerProvider, boxProvider);
    measurerController.renderElement(Component);
    const result = await containerProvider.measure();
    measurerController.close();
    return result;
  }
}
