import { WSWSelectorTree } from '../wsw-selectors';
import React from 'react';
import { HTMLContentView } from '../../../../subject/testRunner/screens/composer/views/html-content-view';

export function createWswGroupIndicationView() {
  return HTMLContentView.fixed(
    WSWSelectorTree.rootSelector.probandGroup,
    ({ value, containerRef }) => {
      return (
        <span
          ref={containerRef}
          style={{
            backgroundColor: value.color,
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            padding: '5px',
            whiteSpace: 'nowrap',
          }}
        >
          {value.group.name}
        </span>
      );
    },
  );
}
