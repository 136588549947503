import React, { FC } from 'react';
import { WSWGroup } from '../../../../../../API';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { TextDisplay } from '../../../../../../components/TextDisplay';
import { ConditionalQuestionSetView } from '../../conditionalQuestionSet/view/ConditionalQuestionSetView';
import { ColorDisplay } from '../../../../../../components/display/ColorDisplay';

interface WswGroupsViewProps {
  groups: WSWGroup[];
}

export const WswGroupsView: FC<WswGroupsViewProps> = (props) => {
  return (
    <Box>
      <Typography color="textSecondary" sx={{ mb: 1 }}>
        Groups
      </Typography>
      <Box sx={{ ml: 1 }}>
        <Grid container spacing={2}>
          {props.groups.map((g, gi) => (
            <Grid item xs={6} key={`group-index-${gi}`}>
              <Accordion
                sx={{
                  border: 'solid',
                  borderColor: g.color,
                  borderWidth: '1px',
                }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Box sx={{ display: 'flex', width: '100%' }}>
                    <Typography sx={{ mr: 3 }}>
                      {gi + 1}. {g.name}
                    </Typography>
                    <ColorDisplay label="" value={g.color} />
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2} direction="row" sx={{ mb: 2 }}>
                    <TextDisplay label="Name" value={g.name} />
                    <TextDisplay label="Probability" value={g.probability} />
                  </Stack>
                  <ConditionalQuestionSetView
                    label="Question Set"
                    conditionalQuestionSet={g.questionSet}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
