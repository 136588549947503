import { useConfirm } from 'material-ui-confirm';
import React, { useEffect, useMemo, useState } from 'react';
import { TestPermissionsProps, TestPermissionType } from './types';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API } from 'aws-amplify';
import {
  getUserEmailsByIds,
  inviteUserToTest,
  removeUserFromTest as removeUserFromTestMutation,
} from '../../../../graphql/mutations';
import { TestType } from '../../../subject/types';
import { RemoveUserFromTestMutationVariables } from '../../../../API';
import { enqueueSnackbar } from 'notistack';

const getEmailsByIds = (
  testId: string,
  testType: TestType,
  userIds: (string | null | undefined)[],
) => {
  return API.graphql({
    query: getUserEmailsByIds,
    variables: {
      testId,
      testType,
      userIds,
    },
  });
};

const removeUserFromTest = (input: RemoveUserFromTestMutationVariables) => {
  return API.graphql({
    query: removeUserFromTestMutation,
    variables: {
      ...input,
    },
  });
};

export function useTestPermissions({
  testType,
  testId,
  ownerId,
  testViewersIds,
  testEditorsIds,
  resultsViewersIds,
  onReloadTest,
}: TestPermissionsProps) {
  const confirm = useConfirm();

  const userIds = useMemo(
    () => [
      ...(testViewersIds ?? []),
      ...(testEditorsIds ?? []),
      ...(resultsViewersIds ?? []),
      ownerId,
    ],
    [ownerId, resultsViewersIds, testEditorsIds, testViewersIds],
  );

  const [loading, setLoading] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);
  const [emailsToAdd, setEmailsToAdd] = React.useState<string[]>([]);
  const [permissionToAdd, setPermissionToAdd] = React.useState<
    TestPermissionType[]
  >([]);

  const [userIdEmailsMap, setUserIdEmailsMap] = useState<
    Record<string, string>
  >({});

  const toggleForm = () => setShowForm(!showForm);
  const clearForm = () => {
    setEmailsToAdd([]);
    setPermissionToAdd([]);
  };
  const closeForm = () => {
    toggleForm();
    clearForm();
  };

  useEffect(() => {
    (
      getEmailsByIds(testId, testType, userIds) as Promise<
        GraphQLResult<{ getUserEmailsByIds: { body: string } }>
      >
    )
      .then((resp) => {
        setUserIdEmailsMap(
          JSON.parse(resp.data?.getUserEmailsByIds.body ?? '{}'),
        );
      })
      .catch((e) => {
        enqueueSnackbar(e.errors[0].message, { variant: 'error' });
      });
  }, [testId, testType, userIds]);

  const handleRemoveEmail = showForm
    ? async (email: string) => {
        confirm({
          description: `Are you sure you want to remove ${email} from the test?`,
          confirmationText: 'Remove user',
          confirmationButtonProps: { color: 'error' },
        })
          .then(async () => {
            const userId = Object.entries(userIdEmailsMap).find(
              ([_, userEmail]) => userEmail === email,
            )?.[0];

            if (userId) {
              setLoading(true);
              await removeUserFromTest({
                testId,
                testType,
                userId,
              });
              (
                getEmailsByIds(testId, testType, userIds) as Promise<
                  GraphQLResult<{ getUserEmailsByIds: { body: string } }>
                >
              )
                .then((resp) => {
                  setUserIdEmailsMap(
                    JSON.parse(resp.data?.getUserEmailsByIds.body ?? '{}'),
                  );
                  setLoading(false);
                  onReloadTest();
                  enqueueSnackbar('User removed from test', {
                    variant: 'success',
                  });
                })
                .catch((e) => {
                  enqueueSnackbar(e.errors[0].message, { variant: 'error' });
                  setLoading(false);
                });
            }
          })
          .catch(() => {});
      }
    : undefined;

  const handleInviteUsers = async () => {
    setLoading(true);
    try {
      await API.graphql({
        query: inviteUserToTest,
        variables: {
          testId,
          testType,
          permissions: permissionToAdd,
          userEmails: emailsToAdd,
        },
      });
      (
        getEmailsByIds(testId, testType, userIds) as Promise<
          GraphQLResult<{ getUserEmailsByIds: { body: string } }>
        >
      )
        .then((resp) => {
          setUserIdEmailsMap(
            JSON.parse(resp.data?.getUserEmailsByIds.body ?? '{}'),
          );
          setLoading(false);
          enqueueSnackbar('User added to test', { variant: 'success' });
          onReloadTest();
        })
        .catch((e) => {
          enqueueSnackbar(e.errors[0].message, { variant: 'error' });
          setLoading(false);
        });
      setEmailsToAdd([]);
    } catch (e: any) {
      enqueueSnackbar(e.errors[0].message, { variant: 'error' });
      setLoading(false);
    }
  };

  return {
    loading,
    showForm,
    toggleForm,
    closeForm,
    handleRemoveEmail,
    handleInviteUsers,
    userIdEmailsMap,
    permissionToAdd,
    setPermissionToAdd,
    emailsToAdd,
    setEmailsToAdd,
  };
}
