import React, { FC } from 'react';
import { useField } from 'formik';
import { Collapse, Grid, Stack, Tooltip, Typography } from '@mui/material';
import {
  GroupConstructionConditionsInput,
  GroupConstructionInput,
} from '../../../../../API';
import { createEmptyWswGroupConstructions } from '../../../testAdmin/testEditor/initialEditorStates';
import { WswGroupsComponent } from './WswGroupsComponent';
import {
  ConditionalQuestionSet,
  ConditionalQuestionSetSchema,
} from '../conditionalQuestionSet/ConditionalQuestionSet';

import { TransitionGroup } from 'react-transition-group';
import * as Yup from 'yup';
import { ConditionsInput } from './ConditionsInput';
import { InfoOutlined } from '@mui/icons-material';
import { WswVariables } from '../../../text-replacer/textReplacer';
import {
  OptionalInstructionsSchema,
  OptionalInstructionsScreen,
} from '../../../../subject/testRunner/screens/OptionalInstructionsScreen';
import { FormikNullableCheckbox } from '../../../../../components/input/FormikNullableCheckbox';
import { FormikCheckboxField } from '../../../../../components/input/FormikCheckboxField';

export const WswGroupConstructionSchema = Yup.object().shape({
  groupConstruction: Yup.object()
    .notRequired()
    .nullable()
    .shape({
      conditions: Yup.mixed().test(
        'validConditions',
        'Sum must be at least 1',
        function (item) {
          if (!item) {
            return false;
          }
          const { condition1, condition2, condition3 } =
            item as GroupConstructionConditionsInput;
          if (!condition3 && !condition2 && !condition1) {
            return false;
          }
          if (condition1 < 0 || condition2 < 0 || condition3 < 0) {
            return this.createError({
              message: 'Cannot contain negative values',
              path: `${this.path}`,
            });
          }
          return condition1 + condition2 + condition3 > 0;
        },
      ),
      instructionsScreen: OptionalInstructionsSchema,
      feedbackScreen: OptionalInstructionsSchema,
      fakeQuestions: ConditionalQuestionSetSchema,
      groups: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string().required('Required'),
            color: Yup.string().required('Required'),
            probability: Yup.number().required(),
            feedbackScreen: OptionalInstructionsSchema,
            questionSet: ConditionalQuestionSetSchema,
          }),
        )
        .min(2, '2 Groups at least'),
    }),
});

export const GroupConstructionInputComponent: FC<{ fieldName: string }> = (
  props,
) => {
  const [, meta, helpers] = useField<GroupConstructionInput | undefined | null>(
    props.fieldName,
  );

  const fieldName = `${props.fieldName}.conditions`;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="inherit"
          color="inherit"
          sx={{ display: 'flex', alignItems: 'center', mb: 2 }}
        >
          Construct Experimental Groups&nbsp;
          <FormikNullableCheckbox
            label={''}
            onChange={(checked) => {
              helpers.setValue(
                checked ? createEmptyWswGroupConstructions() : null,
              );
            }}
            value={Boolean(meta.value)}
          />
          <Tooltip
            placement={'right'}
            title={
              'Click this box if you want to assign targets (and potentially respondents) randomly to artificial groups.'
            }
          >
            <InfoOutlined
              sx={{
                color: 'warning.dark',
                bgcolor: 'white',
                position: 'relative',
                left: '-1em',
              }}
            />
          </Tooltip>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TransitionGroup>
          {Boolean(meta.value) && (
            <Collapse>
              <Grid container spacing={3}>
                <ConditionsInput fieldName={fieldName} />
                <Grid item xs={12}>
                  <OptionalInstructionsScreen
                    fieldName={`${props.fieldName}.instructionsScreen`}
                    checkboxLabel="Show Instruction Screen for Group Construction"
                    dialogLabel="Instructions Screen"
                    variables={WswVariables}
                  />
                </Grid>
                <Grid item xs={12}>
                  <OptionalInstructionsScreen
                    fieldName={`${props.fieldName}.feedbackScreen`}
                    checkboxLabel="Show Feedback Screen for Group Construction"
                    dialogLabel="Feedback Screen"
                    checkboxToolTip={
                      'Show a general feedback to all respondents text after categorization tasks/ questions.'
                    }
                    toolTip={
                      'If you want to set an individual Feedback Screen per group you can do so in Groups-Section below'
                    }
                    variables={WswVariables}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ConditionalQuestionSet
                    fieldName={`${props.fieldName}.fakeQuestions`}
                    label={'Questions before categorization'}
                    withImageInQuestions={true}
                    withConditionalQuestion={false}
                    withQuestionTimeout={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row">
                    <FormikCheckboxField
                      name={`${props.fieldName}.randomizeGroupColors`}
                      label={'Assign colors randomly to groups'}
                    />
                    <Tooltip
                      placement={'right'}
                      title={
                        'You can chose to randomly vary the group-color-pairing between respondents (e.g., respondent 1 sees Group A as the yellow group and Group B as the blue group; respondent 2 sees Group A as the blue group and Group B as the yellow group)'
                      }
                    >
                      <InfoOutlined
                        sx={{
                          color: 'warning.dark',
                          bgcolor: 'white',
                          position: 'relative',
                          top: '7px',
                        }}
                      />
                    </Tooltip>
                  </Stack>
                  <WswGroupsComponent
                    fieldName={`${props.fieldName}.groups`}
                    toolTip={
                      'Please construct at least two groups to use this function.'
                    }
                  />
                </Grid>
              </Grid>
            </Collapse>
          )}
        </TransitionGroup>
      </Grid>
    </Grid>
  );
};
