import { Stack } from '@mui/material';
import { TestType } from '../../subject/types';
import { BaseTest } from '../types';
import { SurveyTestSetupInstructionsIntroduction } from './SurveyTestSetupInstructionsIntroduction';
import { SurveyProviderInstructionsContainer } from './SurveyProviderInstructionsContainer';

export function SurveyTestSetupInstructions({
  testType,
  test,
}: {
  testType: TestType;
  test: BaseTest;
}) {
  return (
    <Stack spacing={1}>
      <SurveyTestSetupInstructionsIntroduction />
      <div>
        <SurveyProviderInstructionsContainer testType={testType} test={test} />
      </div>
    </Stack>
  );
}
