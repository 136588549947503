import { isReallyDefined } from '../subject/utils';

export function wrapScalar<E>(value: E | E[]): E[] {
  return Array.isArray(value) ? value : [value];
}
export class DataSelectorBuilder<T, V> {
  private accessorChain: (data: T) => V[];

  private constructor(accessorChain: (data: T) => V[]) {
    this.accessorChain = accessorChain;
  }

  select<K extends keyof V>(...keys: K[]): DataSelectorBuilder<T, V[K]> {
    return new DataSelectorBuilder<T, V[K]>((d) => {
      const before = this.accessorChain(d);
      return before.flatMap((bV) => keys.map((key) => bV[key]));
    });
  }
  selectFlat<K extends keyof V>(
    ...keys: K[]
  ): DataSelectorBuilder<
    T,
    { [k in K]: V[k] extends (infer E)[] ? E : V[k] }[K]
  > {
    return new DataSelectorBuilder((d) => {
      const before = this.accessorChain(d);
      return before.flatMap((bV) =>
        keys.flatMap((key) => wrapScalar(bV[key])),
      ) as any;
    });
  }
  selectMap<K extends V extends (infer VE)[] ? keyof VE : never>(
    ...keys: K[]
  ): DataSelectorBuilder<
    T,
    {
      [k in K]: V extends (infer VE)[]
        ? VE[k] extends (infer E)[]
          ? E
          : VE[k]
        : never;
    }[K]
  > {
    return new DataSelectorBuilder((d) => {
      const before = this.accessorChain(d);
      return before.flatMap((bV) =>
        (bV as V extends any[] ? V : never).flatMap((bVE) =>
          keys.flatMap((key) =>
            wrapScalar((bVE as V extends (infer VE)[] ? VE : never)[key]),
          ),
        ),
      );
    });
  }
  selectExisting(): DataSelectorBuilder<T, Exclude<V, null | undefined>> {
    return new DataSelectorBuilder<T, Exclude<V, null | undefined>>(
      (d) =>
        this.accessorChain(d).filter(isReallyDefined) as Exclude<
          V,
          null | undefined
        >[],
    );
  }
  collect(...data: T[]) {
    return data.flatMap((d) => this.accessorChain(d));
  }
  static Create<T>() {
    return new DataSelectorBuilder<T, T>((d) => [d]);
  }
}
