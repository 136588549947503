import { Subject } from 'rxjs';
import { GQLQueryInstance } from '../../../../../GQL';
import {
  PreviewResultTable,
  ResultTableColumn,
} from '../../../../tests/IAT/runner/result-preview-table';
import React from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

export function ResultView({
  resultSubject,
  resultPreviewTable,
}: {
  resultSubject: Subject<GQLQueryInstance<any, any>>;
  resultPreviewTable?: ResultTableColumn<any>[];
}) {
  const [results, setResults] = React.useState<GQLQueryInstance<any, any>[]>(
    [],
  );
  const [showResults, setShowResults] = React.useState(false);
  React.useEffect(() => {
    const sub = resultSubject.subscribe({
      next: (value) => setResults((cRes) => [...cRes, value]),
    });
    return () => sub.unsubscribe();
  }, [resultSubject]);
  return (
    <>
      <Button onClick={() => setShowResults(true)}>Show Results</Button>
      {showResults && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
          }}
        >
          <div style={{ position: 'absolute', right: '-20px', top: '20px' }}>
            <IconButton onClick={() => setShowResults(false)}>
              <Close />
            </IconButton>
          </div>
          <Box pt={5}>
            {resultPreviewTable ? (
              <PreviewResultTable
                results={results}
                columns={resultPreviewTable}
              />
            ) : (
              results.map((res) => {
                return <span>{JSON.stringify(res.variables)}</span>;
              })
            )}
          </Box>
        </div>
      )}
    </>
  );
}
