import { BaseTest } from '../features/tests/types';
import { UserRole } from './user-role';
import { AuthUser, useAppAuthenticator } from './app-authenticator.hook';

export function useAuthUserEmail() {
  const { user } = useAppAuthenticator();
  return user?.attributes?.email ?? '';
}

function checkCheckActiveRole(
  userRoles: UserRole[],
  requiredRoles: UserRole[],
) {
  return requiredRoles.some((role) => userRoles.includes(role));
}

export function useCheckUserHasRole(roles: UserRole[]) {
  const { user } = useAppAuthenticator();
  return checkCheckActiveRole(user?.groups ?? [], roles);
}

export function useUserRoles(): UserRole[] {
  const { user } = useAppAuthenticator();

  return user?.groups ?? [];
}

export function useIsAdmin() {
  return useCheckUserHasRole(['admin']);
}

export function useIsAdminOrTestOwner(testOwnerId: string) {
  const { user } = useAppAuthenticator();
  const isAdmin = Boolean(user?.groups?.includes('admin'));
  const isTestOwner = testOwnerId === user?.username;
  return isAdmin || isTestOwner;
}

type UserTestPermissions = {
  canViewTest: boolean;
  canEditTest: boolean;
  canDeleteTest: boolean;
  canViewTestResults: boolean;
};

const allPermissions: UserTestPermissions = {
  canViewTest: true,
  canEditTest: true,
  canDeleteTest: true,
  canViewTestResults: true,
};

const noPermissions: UserTestPermissions = {
  canViewTest: false,
  canEditTest: false,
  canDeleteTest: false,
  canViewTestResults: false,
};

export function getUserTestPermissions(
  user: AuthUser | undefined,
  test: BaseTest | undefined,
): UserTestPermissions {
  // test/user not loaded yet or not found
  if (!test || !user) {
    return noPermissions;
  }
  const userId = user?.username ?? '';
  // is admin
  if (Boolean(user.groups.includes('admin'))) {
    return allPermissions;
  }
  // is test-owner
  if (test.owner === user.username) {
    return allPermissions;
  }
  // is not owner and not admin
  const canViewTest = Boolean(test.viewers?.includes(userId));
  const canEditTest = Boolean(test.editors?.includes(userId));
  return {
    // we decided to conceptually merge the edit-
    // and view-permission into the edit-permission
    // -> this means users who can edit, also can view
    canViewTest: canViewTest || canEditTest,
    canEditTest,
    canDeleteTest: false,
    canViewTestResults: Boolean(test.resultViewers?.includes(userId)),
  };
}

export function useAuthUserTestPermissions(
  test?: BaseTest,
): UserTestPermissions {
  const { user } = useAppAuthenticator();
  return getUserTestPermissions(user, test);
}
