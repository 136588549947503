import React from 'react';
import { NewTestEditorProps } from './types';
import { BaseTest } from '../../types';
import { Form, Formik } from 'formik';
import { TestEditorBar } from './TestEditorBar';
import { Card, CardContent } from '@mui/material';
import { useEditorState } from './editorState.hook';
import { SuccessSnackBar } from './SuccessSnackBar';

export function TestEditor<
  T extends BaseTest,
  C extends { id?: string | null },
  U,
>(props: NewTestEditorProps<T, C, U>) {
  const {
    testId,
    mode,
    testType,
    stepIndex,
    initialValues,
    saveTest,
    activeStep,
    isLoading,
    nextStep,
    prevStep,
    onClickCancel,
    errors,
    setErrors,
    showSuccessBar,
    hideSuccessBar,
    goToStep,
    apiError,
  } = useEditorState<T, C, U>(props);

  const FormStepComponent = activeStep.editor.component;

  const form = React.useMemo(
    () => (
      <Formik<C>
        enableReinitialize
        initialValues={initialValues}
        validationSchema={activeStep.editor.validationSchema}
        onSubmit={(_, formikHelpers) => {
          formikHelpers.setTouched({});
          setErrors([]);
        }}
      >
        <Form>
          <TestEditorBar
            goToStep={goToStep}
            nextStep={nextStep}
            prevStep={prevStep}
            testId={testId}
            type={testType}
            mode={mode}
            errors={errors}
            stepIndex={stepIndex}
            steps={props.steps}
            loading={isLoading}
            onClickCancel={onClickCancel}
            initialValues={initialValues}
            saveTest={saveTest}
            setErrors={setErrors}
          />
          <Card>
            <CardContent>
              <FormStepComponent />
            </CardContent>
          </Card>
        </Form>
      </Formik>
    ),
    [
      FormStepComponent,
      activeStep.editor.validationSchema,
      errors,
      goToStep,
      initialValues,
      isLoading,
      mode,
      nextStep,
      onClickCancel,
      prevStep,
      props.steps,
      saveTest,
      setErrors,
      stepIndex,
      testId,
      testType,
    ],
  );

  return (
    <React.Fragment>
      <SuccessSnackBar
        test={initialValues}
        steps={props.steps}
        showSuccessBar={showSuccessBar}
        onClose={hideSuccessBar}
        severity={apiError ? 'error' : 'success'}
        testType={testType}
      />
      {form}
    </React.Fragment>
  );
}
