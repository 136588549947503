import { PodtBlockPhase } from '../structure/podt-block-structure';
import { TrialFeedback } from '../structure/podt-structure';

const podtResponseMatrix = {
  dangerous: {
    first: 'hitText',
    second: 'missText',
  },
  'non-dangerous': {
    first: 'falseAlarmText',
    second: 'correctRejectionText',
  },
  timeout: 'noResponseText',
} as const;
const podtaResponseMatrix = {
  dangerous: {
    avoid: 'hitText',
    approach: 'missText',
  },
  'non-dangerous': {
    avoid: 'falseAlarmText',
    approach: 'correctRejectionText',
  },
  timeout: 'noResponseText',
} as const;
export type ReactionTypeSource = (
  block: PodtBlockPhase,
  type: 'left' | 'right' | undefined,
  variant: 'dangerous' | 'non-dangerous',
  targetSide?: 'left' | 'right',
) => Exclude<keyof TrialFeedback, 'pointStyle'>;
export const PodtReactionSource: ReactionTypeSource = (block, type, variant) =>
  type === undefined
    ? podtResponseMatrix['timeout']
    : podtResponseMatrix[variant][
        block.options.getOptionVariant('response', type)!
      ];
export const PodtaReactionSource: ReactionTypeSource = (
  block,
  type,
  variant,
  targetSide,
) => {
  if (type === undefined) {
    return podtaResponseMatrix['timeout'];
  }
  if (targetSide === undefined) {
    throw new Error('No target side in PODTA');
  }
  const reactionVariant = type === targetSide ? 'approach' : 'avoid';
  return podtaResponseMatrix[variant][reactionVariant];
};
