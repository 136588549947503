import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import { ControlStereotype } from '../../controls/control-stereotypes';
import { ControlOccurrenceEvent } from '../../controls/control-monitor';
import { TestFinishEvent } from '../../controls/finish-event';
import { RunnerButton } from '../../RunnerButton';
import { HTMLContentView } from '../composer/views/html-content-view';
import { Selector } from '../selectors/selector';

export function InstructionHTMLWrapper({
  instructionsHtml,
  measureRef,
}: {
  instructionsHtml: string;
  measureRef?: MutableRefObject<HTMLDivElement>;
}) {
  return (
    <div
      className="emptyNewLineFilled"
      dangerouslySetInnerHTML={{ __html: instructionsHtml }}
      ref={measureRef}
    />
  );
}

export function InstructionsViewComponent({
  children,
  nextButton,
  delay,
}: PropsWithChildren<{
  delay?: number;
  nextButton?: { control: ControlStereotype<MouseEvent, any>; text: string };
}>) {
  const ref = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    try {
      ref.current!.scrollTo({ top: 0 });
      ref.current!.parentElement!.scrollTo({ top: 0 });
      ref.current!.parentElement!.parentElement!.scrollTo({ top: 0 });
    } catch (e) {
      // Maybe the parent non-null assertions fail in some cases - just ignore
    }
  });
  return (
    <div
      style={{
        display: 'flex',
        padding: '1rem',
        flexDirection: 'column',
        overflow: 'auto',
        height: '100%',
        paddingBottom: 'env(safe-area-inset-bottom)',
      }}
      ref={ref}
    >
      {children}
      {nextButton && (
        <div
          style={{
            width: '100%',
            padding: '1em',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <RunnerButton
            onAction={(e) => {
              const occurrence = nextButton.control.controlOccurred(
                e.map((v) => v.nativeEvent),
              );
              const target = (occurrence.trigger.value.target as HTMLElement)
                .parentElement?.parentElement;
              if (delay !== undefined) {
                setTimeout(
                  () => {
                    target?.dispatchEvent(
                      new ControlOccurrenceEvent(occurrence),
                    );
                  },
                  delay * 1000 + 500,
                );
              } else {
                ControlOccurrenceEvent.dispatchControlOccurrence(occurrence);
              }
              return delay;
            }}
            buttonProps={{
              label: nextButton.text,
            }}
          />
        </div>
      )}
    </div>
  );
}

export function FinishViewComponent({
  children,
  nextButton,
  delay,
}: PropsWithChildren<{ delay?: number; nextButton?: { text: string } }>) {
  return (
    <div style={{ display: 'flex', padding: '1rem', flexDirection: 'column' }}>
      {children}
      {nextButton && (
        <div
          style={{
            width: '100%',
            padding: '1em',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <RunnerButton
            onAction={(e) => {
              const target = (e.value.target as HTMLElement).parentElement
                ?.parentElement;
              if (delay !== undefined) {
                setTimeout(
                  () => {
                    TestFinishEvent.dispatchFinishOccurrence(target!);
                  },
                  delay * 1000 + 500,
                );
              } else {
                TestFinishEvent.dispatchFinishOccurrence(target!);
              }
              return delay;
            }}
            buttonProps={{
              label: nextButton.text,
            }}
          />
        </div>
      )}
    </div>
  );
}

export class InstructionsContent {
  content: JSX.Element | string;
  nextButton: string | null;
  finish?: boolean;
  additionalContent?: FC;

  constructor(
    content: JSX.Element | string,
    nextButton: string | null,
    finish?: boolean,
    additionalContent?: FC,
  ) {
    this.content = content;
    this.nextButton = nextButton;
    this.finish = finish;
    this.additionalContent = additionalContent;
  }
}

export function renderPlainFC(Component: FC) {
  return <Component />;
}

export function TestInstructionsView(config: {
  delay?: number;
  selector: Selector<InstructionsContent>;
  additionalContent?: FC;
  control?: ControlStereotype<MouseEvent, any>;
}) {
  const wrapperFactory: (
    value: InstructionsContent,
    measureRef: MutableRefObject<HTMLDivElement>,
  ) => JSX.Element = (value, measureRef) => {
    if (value.finish) {
      return (
        <FinishViewComponent
          delay={config.delay}
          nextButton={{ text: value.nextButton ?? 'Finish' }}
        >
          {typeof value.content === 'string' ? (
            <InstructionHTMLWrapper
              instructionsHtml={value.content}
              measureRef={measureRef}
            />
          ) : (
            <div ref={measureRef}>{value.content}</div>
          )}
          {value.additionalContent && renderPlainFC(value.additionalContent)}
          {config.additionalContent && renderPlainFC(config.additionalContent)}
        </FinishViewComponent>
      );
    }
    return (
      <InstructionsViewComponent
        delay={config.delay}
        nextButton={
          config.control
            ? { text: value.nextButton ?? 'Next', control: config.control }
            : undefined
        }
      >
        {typeof value.content === 'string' ? (
          <InstructionHTMLWrapper
            instructionsHtml={value.content}
            measureRef={measureRef}
          />
        ) : (
          <div ref={measureRef}>{value.content}</div>
        )}
        {value.additionalContent && renderPlainFC(value.additionalContent)}
        {config.additionalContent && renderPlainFC(config.additionalContent)}
      </InstructionsViewComponent>
    );
  };

  return HTMLContentView.growing(config.selector, ({ value, containerRef }) =>
    wrapperFactory(value, containerRef),
  );
}
