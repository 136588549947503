import React, { FC } from 'react';
import PodtGameSettings, {
  PodtGameSettingsSchema,
} from './creator/steps/PodtGameSettings';
import PodtBlockSettings, {
  PodtBlockSettingsSchema,
} from './creator/steps/PodtBlockSettings';
import PodtStimuliSettings, {
  PodtStimuliSettingsSchema,
} from './creator/steps/PodtStimuliSettings';
import PodtSceneSettings, {
  PodtSceneBoxesValidationSchema,
} from './creator/steps/scenes/PodtSceneSettings';
import {
  createPodtAsync,
  deletePodtAsync,
  deletePodtResultsAsync,
  fetchPodtResults,
  fetchPodtTest,
  fetchPodtTests,
  updatePodtAsync,
  updatePodtStatusAsync,
} from './redux/podt.thunk';
import { InitialPodtCreatorState, podtTemplates } from './creator/initialState';
import { TestType } from '../../subject/types';
import TestAdmin from '../testAdmin/TestAdmin';
import { PodtGameSettingsView } from './view/steps/PodtGameSettingsView';
import { PodtBlockSettingsView } from './view/steps/PodtBlockSettingsView';
import { PodtStimuliSettingsView } from './view/steps/PodtStimuliSettingsView';
import { PodtSceneSettingsView } from './view/steps/PodtSceneSettingsView';
import { Podt } from './types';

const PodtAdmin: FC = () => (
  <TestAdmin
    templates={podtTemplates}
    type={TestType.PODT}
    thunks={{
      loadTest: fetchPodtTest,
      loadTests: fetchPodtTests,
      createTest: createPodtAsync,
      updateTest: updatePodtAsync,
      updateTestStatus: updatePodtStatusAsync,
      deleteTest: deletePodtAsync,
    }}
    results={{
      load: fetchPodtResults,
      deleteItems: deletePodtResultsAsync,
    }}
    steps={[
      {
        name: 'Test',
        editor: {
          component: () => <PodtGameSettings counterbalancing={true} />,
          validationSchema: PodtGameSettingsSchema,
        },
        view: (props) => <PodtGameSettingsView test={props.test as Podt} />,
      },
      {
        name: 'Block',
        editor: {
          component: PodtBlockSettings,
          validationSchema: PodtBlockSettingsSchema,
        },
        view: (props) => <PodtBlockSettingsView test={props.test as Podt} />,
      },
      {
        name: 'Stimuli',
        editor: {
          component: () => <PodtStimuliSettings podta={false} />,
          validationSchema: PodtStimuliSettingsSchema,
        },
        view: (props) => <PodtStimuliSettingsView test={props.test as Podt} />,
      },
      {
        name: 'Scene',
        editor: {
          component: PodtSceneSettings,
          validationSchema: PodtSceneBoxesValidationSchema,
        },
        view: (props) => <PodtSceneSettingsView test={props.test as Podt} />,
      },
    ]}
    editor={{
      initialState: InitialPodtCreatorState,
    }}
  />
);

export default PodtAdmin;
