import { DrawableMediaInstance } from './MediaData';
import { CategoryVariant, OptionsPosition } from '../../../../API';
import { MediaInstancePool } from './media-data-container';
import { TestInterface } from '../../../tests/types';
import {
  Describable,
  DynamicParameterController,
  ParametersManagerDefinition,
  StaticParameterController,
} from './test-arranger';
import { MindSetRandomUtils } from '../../../tests/pickingSet/picking-utils';
import { ObjectTransformer } from './drawable/drawable-collector';
import { CounterBalancingGroup } from '../../../tests/counter-balancing-group';

export type OptionVariant = 'first' | 'second';

export interface OptionArrangement {
  left: OptionVariant;
  right: OptionVariant;
}

export interface OptionArranger extends Describable {
  arrangeOptions(sort: CategoryVariant | null): OptionArrangement | null;
}

export function arrangeTupel(
  ...arrangers: CategoryVariant[]
): OptionArrangement {
  return arrangers.reduce(
    (acc, c) =>
      (c === CategoryVariant.REVERSED
        ? {
            left: acc.right,
            right: acc.left,
          }
        : acc) as OptionArrangement,
    { left: 'first', right: 'second' } as OptionArrangement,
  );
}

export class StandardOptionArranger implements OptionArranger {
  constructor(
    public direction: CategoryVariant,
    public cbGroup?: CounterBalancingGroup,
  ) {}

  arrangeOptions(sort: CategoryVariant | null): OptionArrangement | null {
    if (sort === null) {
      return null;
    }
    return arrangeTupel(sort, this.direction);
  }

  describe(): string {
    return this.direction + (this.cbGroup ? '(' + this.cbGroup + ')' : '');
  }
}

export function createCounterBalancingArranger(
  group: CounterBalancingGroup | null,
): OptionArranger {
  return new StandardOptionArranger(
    group === CounterBalancingGroup.GROUP_B
      ? CategoryVariant.REVERSED
      : CategoryVariant.DEFAULT,
    group ?? undefined,
  );
}

export function DefineOptionsParametersFactory<T extends TestInterface<any>>(
  allowCB: boolean,
): ParametersManagerDefinition<T, Record<'optionsArranger', OptionArranger>> {
  return new ParametersManagerDefinition<
    T,
    Record<'optionsArranger', OptionArranger>
  >({
    optionsArranger: (test) =>
      allowCB && test.modelData.counterbalancing
        ? new DynamicParameterController('options arrangment', (context) => {
            const cbGroup = MindSetRandomUtils.choseFromDistribution(
              {
                [CounterBalancingGroup.GROUP_A]: 1,
                [CounterBalancingGroup.GROUP_B]: 1,
              },
              context.logic.parameterRandom,
            );
            return new StandardOptionArranger(
              cbGroup === CounterBalancingGroup.GROUP_B
                ? CategoryVariant.REVERSED
                : CategoryVariant.DEFAULT,
              cbGroup,
            ) as OptionArranger;
          })
        : new StaticParameterController(
            'options arrangment',
            new StandardOptionArranger(
              CategoryVariant.DEFAULT,
            ) as OptionArranger,
          ),
  });
}

export interface TestOptionInstance<M extends string> {
  optionCategory: M;
  optionVariant: OptionVariant;
  mediaInstance: DrawableMediaInstance;
}

class DefinedOptionCategoryArrangement<M extends string> {
  constructor(
    public left: TestOptionInstance<M>,
    public right: TestOptionInstance<M>,
  ) {}
}

export type OptionCategoryArrangement<M extends string> =
  DefinedOptionCategoryArrangement<M> | null;

export class TestOptionCategory<M extends string>
  implements MediaInstancePool<TestOptionInstance<M>>
{
  first: TestOptionInstance<M>;
  second: TestOptionInstance<M>;

  private arranger: OptionArranger;

  constructor(
    first: TestOptionInstance<M>,
    second: TestOptionInstance<M>,
    arranger: OptionArranger,
  ) {
    this.first = first;
    this.second = second;
    this.arranger = arranger;
  }

  arrange(sort: CategoryVariant | null): OptionCategoryArrangement<M> | null {
    const arrangement = this.arranger.arrangeOptions(sort);
    return arrangement
      ? new DefinedOptionCategoryArrangement<M>(
          this[arrangement.left],
          this[arrangement.right],
        )
      : null;
  }

  get items() {
    return [this.first, this.second];
  }
}

export type OptionCategoriesArrangement<M extends string> = {
  [m in M]: OptionCategoryArrangement<m>;
} & {
  arrangementVariant: ArrangementVariant<M>;
  getOptionVariant: (category: M, ov: 'left' | 'right') => OptionVariant | null;
};

function CreateCategoriesArrangement<M extends string>(map: {
  [m in M]: OptionCategoryArrangement<m>;
}): OptionCategoriesArrangement<M> {
  return {
    ...map,
    arrangementVariant: new ArrangementVariant(
      ObjectTransformer.typedEntries(map)
        .filter(([, v]) => !!v)
        .map(([k]) => k) as M[],
    ),
    getOptionVariant: (category, ov) =>
      map[category]?.[ov]?.optionVariant ?? null,
  };
}

export class ArrangementVariant<M extends string> {
  constructor(public items: M[]) {}

  get name() {
    return this.items.join('-');
  }
}

export type OptionsHorizontalAlignment = 'out' | 'in' | 'center';

export interface OptionsAlignment {
  horizontal: OptionsHorizontalAlignment;
  vertical: OptionsPosition;
}

// Specially for left and right options
export class TestOptionCategories<M extends string>
  implements MediaInstancePool<TestOptionInstance<M>>
{
  categories: { [m in M]: TestOptionCategory<m> };

  constructor(
    categories: { [m in M]: TestOptionCategory<m> },
    public alignment: OptionsAlignment,
    public arranger: StandardOptionArranger,
    public categoryDivider?: DrawableMediaInstance,
  ) {
    this.categories = categories;
  }

  arrange(
    sort: Record<M, CategoryVariant | null>,
  ): OptionCategoriesArrangement<M> {
    return CreateCategoriesArrangement(
      Object.fromEntries(
        Object.entries(this.categories).map(([k, v]) => [
          k,
          (v as TestOptionCategory<M>).arrange(sort[k as M]),
        ]),
      ) as { [m in M]: OptionCategoryArrangement<m> },
    );
  }

  get items() {
    return Object.values(this.categories).flatMap(
      (c) => (c as TestOptionCategory<M>).items,
    );
  }

  get arrangementVariations() {
    const enumerateVariations = (m: M[]): ArrangementVariant<M>[] => {
      if (m.length === 0) {
        return [];
      }
      return [
        new ArrangementVariant<M>([m[0]]),
        ...enumerateVariations(m.slice(1)).flatMap((v) => [
          v,
          new ArrangementVariant<M>([m[0], ...v.items]),
        ]),
      ];
    };
    return enumerateVariations(Object.keys(this.categories) as M[]);
  }
}
