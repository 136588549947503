import { FullScreenHandle } from 'react-full-screen';
import { Box, Button, Typography } from '@mui/material';
import Log from '../../../../logger';
import { delay } from '../../../utils';
import React from 'react';
import { useRunnerAppTranslator } from '../../state/baseTestContext/app-configuration/translations/translator.hook';
import { translations } from '../../state/baseTestContext/app-configuration/translations/translation-builder';
import { Translations } from '../../state/baseTestContext/app-configuration/translations/app-translations';

export function MobileFullscreenSetup({
  handle,
  onFinish,
}: {
  handle: FullScreenHandle;
  onFinish: (fullscreen: boolean, locked: boolean) => void;
}) {
  const screenTranslator = useRunnerAppTranslator(
    Translations.App.Loading.SwitchToFullscreenScreen,
  );
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      {screenTranslator.question.mapTranslation((txt) => (
        <Typography variant="body1" textAlign="center">
          {txt}
        </Typography>
      ))}
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        paddingBottom="env(safe-area-inset-bottom)"
      >
        <Button
          onClick={() => {
            onFinish(false, false);
          }}
        >
          {screenTranslator.buttonLabelNo.translate().join(' / ')}
        </Button>
        <Button
          onClick={() => {
            let fullscreenResult: Promise<any> | undefined = undefined;
            try {
              fullscreenResult = handle.enter();
            } catch (err) {
              Log.error(
                'Caught fullscreen enter error:',
                err instanceof Error ? err : undefined,
              );
            }
            if (fullscreenResult === undefined) {
              fullscreenResult = delay(200);
            }
            fullscreenResult
              .then(() => {
                // ignored because TS assumes this API to exist in any case, but there seem to be browsers that do no provide it at all
                // @ts-ignore
                if (window.screen?.orientation?.lock) {
                  window.screen.orientation
                    .lock('landscape')
                    .then(() => {
                      onFinish(true, true);
                    })
                    .catch(() => {
                      onFinish(true, false);
                    });
                } else {
                  onFinish(true, false);
                }
              })
              .catch((err) => {
                Log.error(
                  'Caught fullscreen enter async error:',
                  err instanceof Error ? err : undefined,
                );
                onFinish(false, false);
              });
          }}
        >
          {screenTranslator.buttonLabelYes.translate().join(' / ')}
        </Button>
      </Box>
    </Box>
  );
}

export const SwitchToFullscreenTranslations = translations.scope(
  'Switch to Fullscreen Screen',
  {
    question: translations.text('Question Text', {
      en: 'Do you want to do the following section in full screen mode? (Recommended)',
      de: 'Möchten Se den folgenden Abschnitt im Vollbildmodus durchführen? (Empfohlen)',
    }),
    buttonLabelYes: translations.text('Yes Button Label', {
      en: 'Yes',
      de: 'Ja',
    }),
    buttonLabelNo: translations.text('No Button Label', {
      en: 'No',
      de: 'Nein',
    }),
  },
);
