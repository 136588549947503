import {
  SequencePhaseBuilder,
  SequenceTaskBuilder,
} from '../../../subject/testRunner/media/sequence-tasks';
import { TestBaseContext } from '../../../subject/testRunner/state/baseTestContext/context/ContextTypes';
import {
  TestResourceAccessor,
  TestResourceLoaderInstance,
} from '../../../subject/testRunner/media/test-resource-loader';
import { Amp } from '../types';
import {
  DefineOptionsParametersFactory,
  OptionArranger,
} from '../../../subject/testRunner/media/options-test';
import { createAmpStructure } from './structure/amp-structure';
import { createAmpTree } from './tree/amp-tree';
import { AmpMediaPool, LoadAmpMedia } from './loading/amp-media-loader';
import { sequenceTree } from '../../../subject/testRunner/graph/tree-sequence/tree-sequence';

export const AmpLoaderSequence = SequencePhaseBuilder.Builder<{
  testContextFactory: () => Promise<TestBaseContext>;
  loader: TestResourceLoaderInstance<Amp>;
}>()
  .start(
    'load',
    SequenceTaskBuilder.Builder<{
      testContextFactory: () => Promise<TestBaseContext>;
      loader: TestResourceLoaderInstance<Amp>;
    }>()
      .start('retrieve', async ({ testContextFactory, loader }) => {
        const testContext = await testContextFactory();
        return {
          testContext,
          accessor: await loader.load(testContext),
        };
      })
      .chainTask('load', ({ accessor }) => ({
        accessor,
        pool: LoadAmpMedia(accessor),
      }))
      .chainTask('determine-parameters', ({ accessor, pool }) => ({
        pool,
        accessor,
        parameters:
          DefineOptionsParametersFactory<Amp>(false).createManager(accessor),
      })),
  )
  .chain(
    'arrange',
    SequenceTaskBuilder.Builder<{
      pool: AmpMediaPool;
      accessor: TestResourceAccessor<Amp>;
      parameters: Record<'optionsArranger', OptionArranger>;
    }>()
      .start('arrange', ({ accessor, pool, parameters }) =>
        createAmpStructure(accessor, pool),
      )
      .chainTask('tree', (structure) => ({
        structure,
        tree: createAmpTree(structure),
      }))
      .chainTask('tree-sequence', (data) => ({
        ...data,
        sequence: sequenceTree(data.tree),
      })),
  )
  .createPhases();
