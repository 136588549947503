import React, { FunctionComponent } from 'react';
import { ErrorMessage, useField } from 'formik';
import {
  Box,
  Stack,
  Switch as MuiSwitch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { UiInput } from './types';
import { InfoOutlined } from '@mui/icons-material';

type SwitchProps = UiInput<boolean> & {
  left: string;
  right: string;
  tooltip?: string | React.ReactNode;
};

const StyledSwitch: FunctionComponent<SwitchProps> = (props) => {
  const { name, label, left, right, onChange, tooltip } = props;
  const [, meta, helpers] = useField<typeof props.value>(name);

  const handleChangeSwitch = (
    ev: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (typeof onChange === 'function') {
      onChange(Boolean(checked));
    }
    helpers.setValue(Boolean(checked));
  };

  return (
    <Box width="100%" position="relative">
      <TextField
        fullWidth
        InputProps={{
          inputComponent: () => (
            <Stack
              px={'14px'}
              py={1.09}
              direction="row"
              spacing={1}
              alignItems="center"
            >
              <Typography variant="inherit">{left}</Typography>
              <MuiSwitch
                checked={Boolean(meta.value)}
                onChange={handleChangeSwitch}
                color="primary"
              />
              <Typography variant="inherit">{right}</Typography>
            </Stack>
          ),
        }}
      />
      <Typography
        variant="caption"
        color="textSecondary"
        sx={{
          position: 'absolute',
          bgcolor: 'white',
          top: -9.5,
          px: '5px',
          left: 9.5,
        }}
      >
        {label}
      </Typography>
      {tooltip && (
        <Tooltip title={tooltip} placement="left-start">
          <InfoOutlined
            sx={{
              color: 'warning.dark',
              bgcolor: 'white',
              position: 'absolute',
              top: -10,
              right: -10,
            }}
          />
        </Tooltip>
      )}
      {meta.error && (
        <Typography variant="caption" color="error">
          <ErrorMessage name={props.name} />
        </Typography>
      )}
    </Box>
  );
};

export default StyledSwitch;
