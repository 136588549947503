import { ImageMultipleChoiceQuestion } from '../../../../../../API';
import { Box, Typography } from '@mui/material';
import React from 'react';
import MediaItemList from '../../../../../media/MediaItemList';

export const ImageMultipleChoiceQuestionView = ({
  imageMCQuestion,
}: {
  imageMCQuestion: ImageMultipleChoiceQuestion;
}) => {
  return (
    <Box sx={{ pl: 1 }}>
      <Typography variant="caption" color="textSecondary">
        Options {imageMCQuestion.randomized && '(Randomized)'}
      </Typography>
      <Box sx={{ pl: 1 }}>
        <MediaItemList items={imageMCQuestion.options} />
      </Box>
    </Box>
  );
};
