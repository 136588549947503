import React, { FC } from "react";
import { Podt } from "../../types";
import { Avatar, Card, CardContent, Chip, Grid, Stack } from "@mui/material";
import { TextDisplay } from "../../../../../components/TextDisplay";
import { BorderBox } from "../../../../../components/BorderBox";

export const PodtBlockSettingsView: FC<{ test: Podt }> = ({ test }) => {
  return (
    <Stack spacing={3}>
      <Stack spacing={3} direction="row">
        {test.targets.map((targetCategory, catIndex) => (
          <TextDisplay
            key={`target-category-${catIndex + 1}`}
            label={`Target Category ${catIndex + 1}`}
            value={targetCategory.label}
          />
        ))}
      </Stack>
      <Grid container>
        {test.blocks.map((testBlock, blockIndex) => (
          <Grid item xs={12} lg={6} xl={4}>
          {/*<Grid item xs={12} md={6} xl={4}>*/}
            <Card
              variant="outlined"
              key={`block-card-${blockIndex}`}
              sx={{ m: 1, position: "relative", overflow: "visible" }}
            >
              <Chip
                size="small"
                label={testBlock.type === "PRACTICE" ? "Practice" : "No Practice"}
                style={{ position: "absolute", top: 8, right: 8 }}
              />
              <Avatar
                sx={{
                  height: 24,
                  width: 24,
                  bgcolor: "darkGrey",
                  position: "absolute",
                  top: -8,
                  left: -8
                }}
              >
                {blockIndex + 1}
              </Avatar>
              <CardContent>
                <Stack spacing={1}>
                  <TextDisplay
                    label={"Name"}
                    value={testBlock.name}
                  />
                  <TextDisplay
                    label={"# Trials"}
                    value={`${testBlock.amountTrials} Trials`}
                  />
                  {testBlock.preparationScreen ?
                    <React.Fragment>
                      <TextDisplay
                        label="Preparation Interval"
                        value={testBlock.preparationScreen.interval}
                        suffix={" ms"}
                      />
                      <BorderBox label="Preparation Screen">
                        <div dangerouslySetInnerHTML={{ __html: testBlock.preparationScreen?.screen.desktop }} />
                      </BorderBox>
                    </React.Fragment>
                    : (
                      <TextDisplay
                        label="Preparation Screen"
                        value="Preparation Screen not enabled"
                        valueProps={{ sx: { fontStyle: "italic" } }}
                      />
                    )
                  }
                  <BorderBox label="Instructions">
                    <div dangerouslySetInnerHTML={{ __html: testBlock.instructions.desktop }} />
                  </BorderBox>
                  {testBlock.tips?.desktop &&
                    <BorderBox label="Tips">
                      <div dangerouslySetInnerHTML={{ __html: testBlock.tips.desktop }} />
                    </BorderBox>}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
