import { BasicDimension } from '../../media/drawable-dimensions';
import React, { CSSProperties, FC, MutableRefObject } from 'react';
import { MeasurableContainerHolder } from './measurable-holder';
import {
  createMeasurableWrapperComponent,
  MeasureRefComponent,
} from './measurable-component';
import { awaitFirst } from '../../utils/rxjs-utils';
import {
  ContainerProvider,
  StyleCB,
} from '../composer/views/render-container-provider';
import { instrumentObject } from '../../utils/instrument-object';
import { isReallyDefined } from '../../../utils';
import { MeasurePhases } from '../measure-phases';

export interface MeasureContainerProvider extends ContainerProvider {
  measure(): Promise<BasicDimension>;

  readonly component: FC;
  element: () => JSX.Element;
}

export class DefaultMeasureContainerProvider
  implements MeasureContainerProvider
{
  protected measureComponentHolder: MeasurableContainerHolder;

  constructor(
    public code: string,
    phase: MeasurePhases,
    style: CSSProperties,
  ) {
    this.measureComponentHolder = new MeasurableContainerHolder(
      code,
      (content, scb) => {
        const styleWrap = scb ?? ((_: string, s: CSSProperties) => s);
        return createMeasurableWrapperComponent(
          instrumentObject(({ containerRef }) => {
            return (
              <div
                style={styleWrap(phase, style)}
                ref={containerRef as MutableRefObject<HTMLDivElement | null>}
              >
                {content}
              </div>
            );
          }, `WrapComponent: ${code}`) as FC<
            MeasureRefComponent<HTMLDivElement>
          >,
        );
      },
    );
  }

  get component() {
    return this.measureComponentHolder.measurableComponent.element;
  }

  element() {
    const Comp = this.component;
    return <Comp />;
  }

  createContainer(content: React.ReactNode, styleCB?: StyleCB): JSX.Element {
    return this.measureComponentHolder.create(content, styleCB);
  }

  measure(): Promise<BasicDimension> {
    return this.measureComponentHolder.componentSignal.then(() =>
      awaitFirst(
        instrumentObject(
          this.measureComponentHolder.measurableComponent.sizeSubscriber,
          `size observer ${this.code}`,
        ),
        isReallyDefined,
      ),
    );
  }
}
