import { TestInvocationParams } from './test-invocation-params';

export type RunnerAppConfigurationSettings = {
  testInvocationParams: TestInvocationParams;
  languages: string[];
};

const defaultLanguages = ['en', 'de'];

export function createAppConfigSettingsFromInvocationParams(
  params: TestInvocationParams,
): RunnerAppConfigurationSettings {
  const languages =
    params.pathParams.testId === '9e3ee46b-de71-44f6-8d74-3fd4f74074ea'
      ? ['ar']
      : params.configurationParams.getAll('lang');
  return {
    testInvocationParams: params,
    languages: languages.length > 0 ? languages : defaultLanguages,
  };
}
