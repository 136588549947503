import React, { FunctionComponent } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import StyledIconButton from '../input/StyledIconButton';
import { Send } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    padding: 14,
    minHeight: 50,
    position: 'relative',
    marginTop: theme.spacing(0.5),
    '& >:nth-child(2)': {
      marginTop: theme.spacing(0.25),
    },
    "& >div[class*='section']:nth-child(2)": {
      marginTop: theme.spacing(1),
    },
    "& >div[class*='section']:last-child": {
      marginBottom: 0,
    },
  },
  copyButton: {
    zIndex: 2,
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
  },
  code: {
    zIndex: 1,
    display: 'flex',
    overflow: 'auto',
    height: 100,
    overflowWrap: 'anywhere',
    wordWrap: 'break-word',
    padding: theme.spacing(1),
    fontSize: '0.75em',
    width: 'calc(100% - 48px)',
    backgroundColor: 'rgba(0,0,0,0.05)',
  },
}));

type CodeSnippetProps = {
  label?: string;
  codeSnippet: string;
  shareLink?: string;
};

const CodeSnippet: FunctionComponent<CodeSnippetProps> = ({
  codeSnippet,
  label,
  shareLink,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <StyledIconButton
        label="Click to copy the code snippet"
        onClick={() => navigator.clipboard.writeText(codeSnippet)}
        className={classes.copyButton}
      >
        <FileCopyIcon fontSize="small" />
      </StyledIconButton>
      {shareLink && (
        <StyledIconButton
          label="Click to copy link to share the code snippet"
          onClick={() => navigator.clipboard.writeText(shareLink)}
          className={classes.copyButton}
          sx={{ marginTop: '1.3em' }}
        >
          <Tooltip
            title={
              <a href={shareLink} target={'_blank'} rel="noreferrer">
                {shareLink}
              </a>
            }
          >
            <Send fontSize="small" />
          </Tooltip>
        </StyledIconButton>
      )}
      <code className={classes.code}>{codeSnippet}</code>
    </div>
  );
};

export default CodeSnippet;
