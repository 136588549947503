import { TimedValue } from '../../../events/TimedEvent';
import { TimeoutEvent } from '../../../events/TimeoutEvent';
import {
  ControlOccurrenceProcessor,
  createActionFactory,
  TransitionAction,
} from '../graph-state/graph-action';
import { DefaultControlTransition } from './control-transition';
import { StateHolder } from '../graph-state/state-holder';

export class ControlOccurrence<TriggerType> {
  constructor(
    public stereotype: ControlStereotype<TriggerType, any>,
    public trigger: TimedValue<TriggerType>,
    public readonly sourceType = 'observed' as 'observed' | 'generated',
  ) {}

  toResultData() {
    let pressedButton = 'custom-control';

    if (this.trigger.value instanceof KeyboardEvent) {
      pressedButton = `keyboard:${this.trigger.value.code}`;
    } else if (this.trigger.value instanceof MouseEvent) {
      pressedButton = 'click';
    } else if (window.TouchEvent) {
      if (this.trigger.value instanceof window.TouchEvent) {
        pressedButton = 'touch';
      }
    }

    return {
      pressedButton,
      triggeredControl: this.stereotype.name,
    };
  }
}

export class ControlRequest<RequestParameter> {
  constructor(
    public stereotype: ControlStereotype<any, RequestParameter>,
    public trigger: RequestParameter,
  ) {}
}

export type StereotypeTrigger<C extends ControlStereotype<any, any>> =
  C extends ControlStereotype<infer T, any> ? T : never;

/**
 * A stereotype is a named control type that can be triggered by a specific event.
 * @param TriggerType The type of the event that triggers the control.
 * @param RequestParameter The type of the parameter that can be used to request the control i.e.timeout before event.
 */
export class ControlStereotype<TriggerType, RequestParameter> {
  constructor(public name: string) {}

  controlOccurred(
    trigger: TimedValue<TriggerType>,
  ): ControlOccurrence<TriggerType> {
    return new ControlOccurrence<TriggerType>(this, trigger);
  }

  requestControl(
    parameters: RequestParameter,
  ): ControlRequest<RequestParameter> {
    return new ControlRequest<RequestParameter>(this, parameters);
  }

  defineTransition(
    parameters: RequestParameter,
    factory: (cO: ControlOccurrenceProcessor<TriggerType>) => TransitionAction,
    predicate?: (stateHolder: StateHolder) => boolean,
  ): DefaultControlTransition<RequestParameter, TriggerType> {
    return new DefaultControlTransition(
      this.requestControl(parameters),
      createActionFactory(factory),
      predicate,
    );
  }
}

export const OptionsStereotypes = {
  Side: {
    Left: new ControlStereotype<KeyboardEvent | MouseEvent, void>('left'),
    Right: new ControlStereotype<KeyboardEvent | MouseEvent, void>('right'),
  },
  Confirm: {
    Control: new ControlStereotype<KeyboardEvent | MouseEvent, void>(
      'confirm-control',
    ),
    UI: new ControlStereotype<MouseEvent, void>('confirm-ui'),
  },
  TimeoutEvent: new ControlStereotype<TimeoutEvent, number>('timeout'),
};

// OptionsStereotypes.Side.Left.defineTransition(undefined, (e) => DefaultTransitionAction.next(IATModifiers.default))
