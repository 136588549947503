import React, { FC } from "react";
import { ScaleQuestion } from "../../../../../../API";
import { Stack } from "@mui/material";
import { TextDisplay } from "../../../../../../components/TextDisplay";

interface ScaleQuestionViewProps {
  scaleQuestion: ScaleQuestion;
}

export const ScaleQuestionView: FC<ScaleQuestionViewProps> = (props) => {
  return (
    <Stack spacing={1} direction="row">
      <TextDisplay
        label="Min"
        value={`${props.scaleQuestion.min?.name} (${props.scaleQuestion.min?.value})`}
      />
      <TextDisplay
        label="Max"
        value={`${props.scaleQuestion.max?.name} (${props.scaleQuestion.max?.value})`}
      />
      <TextDisplay
        label="Inverted"
        value={props.scaleQuestion.invert ? "Yes" : "No"}
      />
    </Stack>
  );
};
