import { DisplayData, Optional, RenderDataType } from './data-wrapper';
import { SelectorDefinition } from './selector-definition';
import { Selector } from './selector';
import { GroupSelector } from './group-selector';

export interface ContentSelectorDefinition<I, R>
  extends SelectorDefinition<I, R, Selector<R>> {
  selectRenderData: (data: I) => RenderDataType<R>;
}

export function defineContentSelector<I, R>(
  def: Omit<ContentSelectorDefinition<I, R>, 'toSelector'>,
): ContentSelectorDefinition<I, R> {
  return {
    ...def,
    toSelector(
      name: string,
      parentSelector?: GroupSelector<I, any, any>,
    ): Selector<R> {
      return {
        selectorInstance: {
          name,
          path: parentSelector
            ? [
                ...parentSelector.selectorInstance.path,
                parentSelector.selectorInstance.name,
              ]
            : [],
          pathName: (parentSelector
            ? [
                ...parentSelector.selectorInstance.path,
                parentSelector.selectorInstance.name,
                name,
              ]
            : [name]
          ).join('.'),
          children: [],
          selectRenderData: (screenData: any) => {
            if (!parentSelector) {
              throw new Error('ContentSelectors must have a parent selector');
            }
            const parentData =
              parentSelector.selectorInstance.selectGroupData(screenData);
            if (Optional.isNone(parentData)) {
              return DisplayData.none<R>();
            }
            return def.selectRenderData(parentData[1]);
          },
        },
      };
    },
  };
}
