import React, { FC } from 'react';
import { MediaItemSnapshotInput } from '../../../API';
import { MediaItem } from '../types';
import { PickerTable } from './PickerTable';
import { mediaItemToMediaItemSnapshotInput } from './pickerUtils';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useGetMediaItemsQuery } from '../redux/mediaApi';
import { InfoIcon } from '../../../components/display/Icons';

interface MediaItemsSinglePickTableProps {
  title?: string;
  onConfirm: (mediaItem: MediaItemSnapshotInput) => void;
  onClose: () => void;
  open: boolean;
  value?: MediaItemSnapshotInput;
}

export const MediaItemsSinglePickTable: FC<MediaItemsSinglePickTableProps> = (
  props,
) => {
  const { data, isLoading, isFetching } = useGetMediaItemsQuery();

  const mediaPool: MediaItem[] = data ?? [];

  const [selection, setSelection] = React.useState<MediaItemSnapshotInput[]>(
    [],
  );

  const handleSelectionChange = (mediaItems: MediaItem[]) => {
    setSelection(mediaItems.map(mediaItemToMediaItemSnapshotInput));
  };

  const handleConfirm = () => {
    props.onConfirm(selection[0]);
    props.onClose();
  };
  return (
    <Dialog open={props.open} maxWidth="xl" onClose={props.onClose}>
      <DialogTitle style={{ position: 'relative' }}>
        <Typography variant="inherit">{props.title}</Typography>
        <div style={{ position: 'absolute', top: 16, right: 24 }}>
          <Button style={{ marginRight: 16 }} onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleConfirm}
          >
            Save
          </Button>
        </div>
      </DialogTitle>
      <DialogContent style={{ overflow: 'visible' }}>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            marginBottom: 8,
          }}
        >
          <InfoIcon
            iconProps={{ fontSize: 'inherit', style: { marginRight: 4 } }}
          />
          If Stimuli appear too small, this may be due to the resolution of one
          or more images.
        </Typography>
        <PickerTable
          singleSelect
          withoutUpload
          mediaItems={mediaPool}
          selectedMediaItems={mediaPool.filter((mI) =>
            selection.some((s) => s.id === mI.id),
          )}
          onSelectionChange={handleSelectionChange}
          isLoading={isFetching || isLoading}
          withoutDisabled={false}
        />
      </DialogContent>
    </Dialog>
  );
};
