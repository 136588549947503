import { TestType } from '../../subject/types';
import { BaseTest } from '../types';
import { useGetSurveyProvidersQuery } from '../../survey-providers/surveyProviderApi';
import React, { useState } from 'react';
import { SurveyProvider } from '../../../API';
import { LinearProgress, Stack, Typography } from '@mui/material';
import { SurveyProviderSelect } from './SurveyProviderSelect';
import { SurveyProviderInstructionsView } from './SurveyProviderInstructionsView';
import { Timeline, timelineItemClasses } from '@mui/lab';
import { SetupStepTimelineItem } from './SetupStepTimelineItem';
import { DsgvoTable } from '../../../components/SetupInstructions/DsgvoTable';

export function SurveyProviderInstructionsContainer({
  testType,
  test,
}: {
  testType: TestType;
  test: BaseTest;
}) {
  const { data: surveyProviders, isLoading } = useGetSurveyProvidersQuery();

  const [selectedProvider, setSelectedProvider] = useState<SurveyProvider>();

  if (!surveyProviders || isLoading) {
    return <LinearProgress />;
  }

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      <SetupStepTimelineItem
        stepIndex={1}
        stepContent={
          <Stack spacing={1}>
            <Typography variant="h5">
              {selectedProvider
                ? 'Survey provider'
                : ' Please choose the survey tool you want to integrate this MIND.set test into'}
            </Typography>
            <SurveyProviderSelect
              providers={surveyProviders}
              selectedProvider={selectedProvider}
              setSelectedProvider={setSelectedProvider}
            />
          </Stack>
        }
      />
      {selectedProvider && (
        <SurveyProviderInstructionsView
          key={selectedProvider.id}
          surveyProvider={selectedProvider}
          testType={testType}
          test={test}
        />
      )}
      {selectedProvider && (
        <SetupStepTimelineItem
          stepIndex={
            selectedProvider.setupDocumentation.steps.length +
            (selectedProvider.snippetConfiguration.surveyIdReplacer ? 1 : 2)
          }
          stepContent={
            <Stack spacing={1}>
              <Typography variant="h4">
                Information about data privacy:
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Due to privacy compliance please make sure to ask respondents
                for their consent that the following data needs to be processed
                by MIND.set:
              </Typography>
              <DsgvoTable />
            </Stack>
          }
        />
      )}
    </Timeline>
  );
}
