import {
  CreateTestResourceLoader,
  GQLTestQueryLoader,
} from '../../../../subject/testRunner/media/test-resource-loader';
import { IatQueries } from './iat.queries';
import { MediaItemSnapshot } from '../../../../../API';

import { IatMediaItemSelector } from './iat-media-item-selector';

export const IatResourceLoader = CreateTestResourceLoader(
  GQLTestQueryLoader(IatQueries.Get),
  (test, loadingManager) => {
    const collect = IatMediaItemSelector.collect(test) as MediaItemSnapshot[];
    loadingManager.attachItems(...collect);
    return loadingManager.loadCache();
  },
);
