import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { Drafts, Send } from '@mui/icons-material';
import { GQLQuery, NewGQL } from '../../GQL';
import {
  BroadcastMail,
  BroadcastMailStatus,
  CreateBroadcastMailMutationVariables,
  FunctionBodyResponse,
  SendBroadcastMailMutationVariables,
} from '../../API';
import {
  createBroadcastMail,
  sendBroadcastMail,
} from '../../graphql/mutations';
import { enqueueSnackbar } from 'notistack';

export function BroadcastMailDialog({
  initialValue,
  saveDraft,
  sendMail,
  cancel,
}: {
  initialValue: Omit<
    BroadcastMail,
    'id' | 'status' | '__typename' | 'createdAt' | 'updatedAt'
  > & { id?: string };
  saveDraft: (
    mail: Omit<
      BroadcastMail,
      'id' | 'status' | '__typename' | 'createdAt' | 'updatedAt'
    > & { id?: string },
  ) => Promise<void>;
  sendMail: (
    mail: Omit<
      BroadcastMail,
      'id' | 'status' | '__typename' | 'createdAt' | 'updatedAt'
    > & { id?: string },
  ) => Promise<void>;
  cancel: () => void;
}) {
  const [content, setContent] = React.useState({
    subject: initialValue.subject,
    message: initialValue.content,
    sender: initialValue.sender as undefined | string,
  });
  return (
    <>
      <Dialog fullWidth={true} open={true} onClose={cancel}>
        <DialogTitle>Send Broadcast Mail</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              label="Subject"
              required
              placeholder="Subject"
              value={content.subject}
              onChange={(e) =>
                setContent({ ...content, subject: e.target.value })
              }
            />
            <TextField
              label="Sender"
              placeholder="Optional name of sender"
              value={content.sender}
              onChange={(e) =>
                setContent({ ...content, sender: e.target.value })
              }
            />
            <TextField
              label="Message"
              placeholder="Message"
              required
              multiline
              rows={15}
              value={content.message}
              onChange={(e) =>
                setContent({ ...content, message: e.target.value })
              }
            />
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <Button onClick={cancel} color="warning">
                Cancel
              </Button>
              <Button
                variant="contained"
                startIcon={<Drafts />}
                disabled={
                  content.message.trim() === '' && content.subject.trim() === ''
                }
                onClick={() => {
                  saveDraft({
                    ...initialValue,
                    subject: content.subject,
                    content: content.message,
                    sender: content.sender,
                  });
                }}
              >
                Save as draft
              </Button>
              <Button
                disabled={
                  content.message.trim() === '' || content.subject.trim() === ''
                }
                onClick={() => {
                  sendMail({
                    content: content.message,
                    subject: content.subject,
                    sender: content.sender,
                  });
                }}
                startIcon={<Send />}
                variant="contained"
                color="primary"
              >
                Send
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

export function CreateBroadcastMailDialogButton({
  refresh,
}: {
  refresh: () => void;
}) {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Button
        startIcon={<Send fontSize="small" />}
        onClick={() => {
          setOpen(true);
        }}
      >
        Send Message
      </Button>
      {open && (
        <BroadcastMailDialog
          initialValue={{ content: '', sender: '', subject: '' }}
          saveDraft={async (content) => {
            NewGQL.DEFAULT_CLIENT.execute(
              GQLQuery.Mutate<
                CreateBroadcastMailMutationVariables,
                BroadcastMail
              >(createBroadcastMail).create({
                input: {
                  content: content.content,
                  subject: content.subject,
                  sender: content.sender,
                  status: BroadcastMailStatus.DRAFT,
                },
              }),
            )
              .then((res) => {
                console.log('Sent broadcast mail', res);
                setOpen(false);
              })
              .catch((err) => {
                console.error('Error sending broadcast mail', err);
                enqueueSnackbar('Failed to safe draft', {
                  variant: 'error',
                });
              });
          }}
          sendMail={async (content) => {
            NewGQL.DEFAULT_CLIENT.execute(
              GQLQuery.Mutate<
                SendBroadcastMailMutationVariables,
                FunctionBodyResponse
              >(sendBroadcastMail).create({
                content: content.content,
                subject: content.subject,
                sender: content.sender,
              }),
            )
              .then((res) => {
                console.log('Sent broadcast mail', res);
                refresh();
                setOpen(false);
              })
              .catch((err) => {
                console.error('Error sending broadcast mail', err);
                enqueueSnackbar('Failed to send mail', {
                  variant: 'error',
                });
              });
          }}
          cancel={() => setOpen(false)}
        />
      )}
    </>
  );
}
