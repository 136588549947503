import { createRunnerConfiguration } from '../../../subject/testRunner/runner-configuration';
import { TestType } from '../../../subject/types';
import { IATSelectorTree } from '../views/iat-selectors';
import { IatStructure } from '../loader/structure/iat-structure';
import { createIatViewMap } from '../views/iat-views';
import { IatLoaderSequence } from '../loader/iat-loader-sequence';
import { IatResourceLoader } from '../loader/loading/iat-loader';
import { DefaultPhaseRunner } from '../../../subject/testRunner/media/sequence-tasks';
import { IatResultPreviewGroups } from './iat-result-preview-table';
import { CreateOptionTestsControlHandlerFactory } from '../../../subject/testRunner/controls/options-control-handler-factory';

export const IatRunnerConfig = createRunnerConfiguration(TestType.IAT, {
  selectorTree: IATSelectorTree,
  resultPreviewTable: IatResultPreviewGroups,
  controlFactory: (structure: IatStructure) =>
    CreateOptionTestsControlHandlerFactory(
      structure.accessor.testContext,
      structure.accessor.modelData.keyboard,
    ),
  createViews: createIatViewMap,
  load: (testContext, gql, mediaManager) => {
    return IatLoaderSequence.performSequence(
      {
        load: {
          testContextFactory: async () => testContext,
          loader: IatResourceLoader.init(gql, mediaManager),
        },
        arrange: ({ accessor, parameters, pool }) => ({
          pool,
          accessor,
          parameters: parameters.determine(accessor.testContext),
        }),
      },
      new DefaultPhaseRunner(),
    );
  },
});
