import {
  DisplayData,
  Optional,
  OptionalValue,
  RenderDataType,
} from './data-wrapper';
import { ObjectTransformer } from '../../media/drawable/drawable-collector';
import { SelectorDefinition } from './selector-definition';
import { SelectorInstance } from './selector-instance';
import { Selector } from './selector';

export type GroupSelector<
  O,
  S,
  Sub extends Record<string, Selector<any>>,
> = Selector<S> & {
  selectorInstance: GroupSelectorInstance<O, S>;
} & Sub;
// this is not counterbalancing, but it is a way to define a selector tree.
// because there are sub-selectors this is call GroupSelector, to group all the sub-selectors
export interface GroupSelectorDefinition<
  I,
  O,
  R,
  S extends Record<string, SelectorDefinition<any, any, any>>,
> extends SelectorDefinition<
    I,
    R,
    GroupSelector<O, R, { [s in keyof S]: ReturnType<S[s]['toSelector']> }>
  > {
  selectGroupData(input: I): OptionalValue<O>;

  selectRenderData?: (data: O) => RenderDataType<R>;
  children: S;
}

export function defineGroupSelector<
  I,
  O,
  R,
  S extends Record<string, SelectorDefinition<any, any, any>>,
>(
  def: Omit<
    GroupSelectorDefinition<I, O, R extends unknown ? undefined : R, S>,
    'toSelector'
  >,
): GroupSelectorDefinition<I, O, R extends unknown ? undefined : R, S> {
  return {
    ...def,
    toSelector: (name: string, parentSelector?: GroupSelector<I, any, any>) => {
      // console.log("to selector", name, parentSelector);
      const selectorInstance = {
        selectorInstance: {
          name,
          path: parentSelector
            ? [
                ...parentSelector.selectorInstance.path,
                parentSelector.selectorInstance.name,
              ]
            : [],
          pathName: (parentSelector
            ? [
                ...parentSelector.selectorInstance.path,
                parentSelector.selectorInstance.name,
                name,
              ]
            : [name]
          ).join('.'),
          children: [],
          selectRenderData: (data: any) => {
            const parentData =
              (parentSelector?.selectorInstance?.selectGroupData(data) ??
                Optional.value(data)) as OptionalValue<I>;
            if (Optional.isNone(parentData)) {
              return Optional.none();
            }
            const groupData = Optional.isValue(parentData)
              ? def.selectGroupData(parentData[1])
              : (['not-set'] as const);
            // console.log(name, data, parentData, groupData);
            if (groupData[0] === 'not-set') {
              return ['hide'];
            }
            if (def.selectRenderData) {
              return def.selectRenderData(groupData[1]);
            }
            return DisplayData.displayNone();
          },
          selectGroupData: (data: any) => {
            const parentData =
              parentSelector?.selectorInstance?.selectGroupData(data) ??
              Optional.value(data);
            if (Optional.isNone(parentData)) {
              return Optional.none();
            }
            return def.selectGroupData(parentData[1]);
          },
        },
      } as unknown as GroupSelector<
        O,
        R extends unknown ? undefined : R,
        { [s in keyof S]: ReturnType<S[s]['toSelector']> }
      >;
      ObjectTransformer.typedEntries(def.children)
        .map(([k, v]) => {
          const subSelector = v.toSelector(k as string, selectorInstance);
          selectorInstance.selectorInstance.children.push(subSelector);
          return [k, subSelector] as const;
        })
        .forEach(([k, v]) => {
          selectorInstance[k] = v;
        });
      return selectorInstance;
    },
  };
}

export interface GroupSelectorInstance<O, S> extends SelectorInstance<S> {
  selectGroupData(data: any): OptionalValue<O>;
}
