import { BlockType, CategoryVariant, IatBlockInput } from '../../../../API';

export const createDefault7Blocks = (): IatBlockInput[] => {
  return [
    {
      index: 0,
      name: 'Categorize targets',
      type: BlockType.PRACTICE,
      amountTrials: 20,
      instructions: {
        desktop: '',
      },
      targetCategoryVariant: CategoryVariant.DEFAULT,
      categoryLabelsInInstructions: false,
    },
    {
      index: 1,
      name: 'Categorize attributes',
      type: BlockType.PRACTICE,
      amountTrials: 20,
      instructions: {
        desktop: '',
      },
      attributeCategoryVariant: CategoryVariant.DEFAULT,
      categoryLabelsInInstructions: false,
    },
    {
      index: 2,
      name: 'Combined block (Practice)',
      type: BlockType.PRACTICE,
      amountTrials: 20,
      instructions: {
        desktop: '',
      },
      targetCategoryVariant: CategoryVariant.DEFAULT,
      attributeCategoryVariant: CategoryVariant.DEFAULT,
      categoryLabelsInInstructions: false,
    },
    {
      index: 3,
      name: 'Combined block (Test)',
      type: BlockType.NON_PRACTICE,
      amountTrials: 40,
      instructions: {
        desktop: '',
      },
      targetCategoryVariant: CategoryVariant.DEFAULT,
      attributeCategoryVariant: CategoryVariant.DEFAULT,
      categoryLabelsInInstructions: false,
    },
    {
      index: 4,
      name: 'Categorize targets (reversed)',
      type: BlockType.PRACTICE,
      amountTrials: 20,
      instructions: {
        desktop: '',
      },
      targetCategoryVariant: CategoryVariant.REVERSED,
      categoryLabelsInInstructions: false,
    },
    {
      index: 5,
      name: 'Reversed combined block (Practice)',
      type: BlockType.PRACTICE,
      amountTrials: 20,
      instructions: {
        desktop: '',
      },
      attributeCategoryVariant: CategoryVariant.DEFAULT,
      targetCategoryVariant: CategoryVariant.REVERSED,
      categoryLabelsInInstructions: false,
    },
    {
      index: 6,
      name: 'Reversed combined block (Test)',
      type: BlockType.NON_PRACTICE,
      amountTrials: 40,
      instructions: {
        desktop: '',
      },
      attributeCategoryVariant: CategoryVariant.DEFAULT,
      targetCategoryVariant: CategoryVariant.REVERSED,
      categoryLabelsInInstructions: false,
    },
  ];
};
