import React, { FunctionComponent, useState } from 'react';
import { useField } from 'formik';
import {
  Box,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  Tooltip,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CategoryVariant } from '../../../../API';
import { InfoOutlined } from '@mui/icons-material';

type CategoriesToDisplay = 'targets' | 'attributes' | 'targets_attributes';

const IatBlockCategorySelector: FunctionComponent<{
  blockName: string;
  disabled?: boolean;
  toolTip?: string;
}> = ({ blockName, disabled, toolTip }) => {
  const classes = useStyles();

  const [, attributesMeta, attributesHelpers] =
    useField<CategoryVariant | null>(`${blockName}.attributeCategoryVariant`);
  const [, targetsMeta, targetsHelpers] = useField<CategoryVariant | null>(
    `${blockName}.targetCategoryVariant`,
  );

  const getValue = (): CategoriesToDisplay => {
    if (targetsMeta.value && attributesMeta.value) {
      return 'targets_attributes';
    }
    if (targetsMeta.value && !attributesMeta.value) {
      return 'targets';
    }
    if (!targetsMeta.value && attributesMeta.value) {
      return 'attributes';
    }
    return 'targets_attributes';
  };

  const [innerValue, setInnerValue] = useState<CategoriesToDisplay>(() =>
    getValue(),
  );

  const onChange = (
    ev: React.ChangeEvent<{ name?: string; value: CategoriesToDisplay }>,
  ) => {
    const { value } = ev.target;
    if (value === 'targets') {
      targetsHelpers.setValue(CategoryVariant.DEFAULT);
      attributesHelpers.setValue(null);
      setInnerValue('targets');
    }
    if (value === 'attributes') {
      attributesHelpers.setValue(CategoryVariant.DEFAULT);
      targetsHelpers.setValue(null);
      setInnerValue('attributes');
    }
    if (value === 'targets_attributes') {
      attributesHelpers.setValue(CategoryVariant.DEFAULT);
      targetsHelpers.setValue(CategoryVariant.DEFAULT);
      setInnerValue('targets_attributes');
    }
  };

  return (
    <Box position="relative">
      <FormControl fullWidth className={classes.formControl}>
        <InputLabel htmlFor="select-categories">
          Categories Displayed
        </InputLabel>
        <MuiSelect
          native
          fullWidth
          disabled={disabled}
          name={blockName}
          inputProps={{
            name: blockName,
            id: `select-${blockName}`,
          }}
          value={innerValue}
          onChange={onChange as any}
        >
          <option value="targets">TARGET_CATEGORIES</option>
          <option value="attributes">ATTRIBUTE_CATEGORIES</option>
          <option value="targets_attributes">
            TARGET_ATTRIBUTE_CATEGORIES
          </option>
        </MuiSelect>
      </FormControl>
      {toolTip && (
        <Tooltip title={toolTip} placement="left-start">
          <InfoOutlined
            sx={{
              color: 'warning.dark',
              bgcolor: 'white',
              position: 'absolute',
              top: -10,
              right: -10,
            }}
          />
        </Tooltip>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        background: 'white',
        marginLeft: theme.spacing(-0.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(0.5),
      },
    },
  }),
);

export default IatBlockCategorySelector;
