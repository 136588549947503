import { TestResourceAccessor } from '../../../../subject/testRunner/media/test-resource-loader';
import { Wsw } from '../../types';
import { TextStyleDescriptor } from '../../../../subject/testRunner/media/text-drawer';
import {
  ConditionalQuestionSet,
  FontStyle,
  MediaItemSnapshot,
  TestQuestion,
  WSWAttribute,
  WSWAttributeType,
  WSWGroup,
  WswStimulusAttributeVariant,
} from '../../../../../API';
import { WswQuestionSetType } from '../types';
import {
  AnnotatedDataPipe,
  AnnotatedValue,
} from '../../../../subject/testRunner/media/drawable/drawable-collector';
import {
  DrawableInstanceFactory,
  DrawableMediaInstance,
  TextMediaInstance,
  transformMediaItemData,
} from '../../../../subject/testRunner/media/MediaData';
import { ColorMediaData, ColorMediaInstance } from '../model/color-media-data';
import { omit, pick } from 'lodash';

export function LoadWswMedia(accessor: TestResourceAccessor<Wsw>) {
  const factories = {
    image: accessor.createDrawableFactory('fullSize'),
    text: accessor.createRenderableFactory(
      accessor.testContext.deviceInfo.mode === 'mobile' &&
        !!accessor.modelData.style.mobileStimulusTextStyle
        ? TextStyleDescriptor.ofFontStyle(
            accessor.modelData.style.mobileStimulusTextStyle as FontStyle,
          )
        : TextStyleDescriptor.ofFontStyle(
            accessor.modelData.style.stimulusTextStyle,
          ),
    ),
  };

  const pools = {
    fakeQuestionsPool: loadQuestionSetPool(
      factories.image,
      accessor.modelData.groupConstruction
        ?.fakeQuestions as ConditionalQuestionSet,
      'group',
      null,
    ),
    distractionQuestionsPool: loadQuestionSetPool(
      factories.image,
      accessor.modelData.distractionPhase
        ?.questionsSet as ConditionalQuestionSet,
      'distraction',
      null,
    ),
    learningQuestionsPool: loadQuestionSetPool(
      factories.image,
      accessor.modelData.learningPhase?.questionsSet as ConditionalQuestionSet,
      'learning',
      null,
    ),
    testQuestionsPool: loadQuestionSetPool(
      factories.image,
      accessor.modelData.testPhase?.questionsSet as ConditionalQuestionSet,
      'test',
      null,
    ),
    testQuestionsOptionsPool: loadQuestionAnswerPool(
      factories.image,
      accessor.modelData.testPhase?.questionsSet as ConditionalQuestionSet,
      'test',
      null,
    ),
  };

  function createAttributesPool(
    type: 'main' | 'additional' | 'criteria',
    attribute: WSWAttribute,
    groups: WSWGroup[],
  ): AnnotatedValue<
    {
      type: 'main' | 'additional' | 'criteria';
      category: string;
      index: number;
    },
    TextMediaInstance | DrawableMediaInstance | ColorMediaInstance
  >[] {
    return attribute.type === WSWAttributeType.GROUPS
      ? groups.map((group, groupIndex) => {
          return new AnnotatedValue(
            {
              type,
              category: group.name!,
              index: 0,
            },
            new ColorMediaInstance(
              new ColorMediaData(
                'group-' + group.name + '-color',
                group.color!,
                groupIndex,
                group.name,
              ),
            ),
          );
        })
      : attribute.categories!.flatMap((cat) => {
          return cat.mediaSnaps!.map((mS, mSI) => {
            return new AnnotatedValue(
              { type, category: cat.name!, index: mSI },
              attribute.stimuliVariant === WswStimulusAttributeVariant.Text
                ? factories.text.createInstance(
                    transformMediaItemData(mS as MediaItemSnapshot),
                  )
                : factories.image.createInstance(
                    transformMediaItemData(mS as MediaItemSnapshot),
                  ),
            );
          });
        });
  }

  const mainAttributes = createAttributesPool(
    'main',
    accessor.modelData.mainAttribute as WSWAttribute,
    accessor.modelData.groupConstruction?.groups as WSWGroup[],
  );

  const additionalAttributes = accessor.modelData.additionalAttribute
    ? createAttributesPool(
        'additional',
        accessor.modelData.additionalAttribute as WSWAttribute,
        accessor.modelData.groupConstruction?.groups as WSWGroup[],
      )
    : [];

  const criteriaAttributes = createAttributesPool(
    'criteria',
    accessor.modelData.criteriaAttribute as WSWAttribute,
    accessor.modelData.groupConstruction?.groups as WSWGroup[],
  );
  return {
    questions: AnnotatedDataPipe.of(
      Object.values(omit(pools, 'testQuestionsOptionsPool')).flat(),
    ),
    answers: AnnotatedDataPipe.of(
      Object.values(pick(pools, 'testQuestionsOptionsPool')).flat(),
    ),
    attributes: AnnotatedDataPipe.of([
      ...mainAttributes,
      ...additionalAttributes,
      ...criteriaAttributes,
    ]),
  };
}
function loadQuestionsSetItemsPool<AV extends {}>(
  imageFactory: DrawableInstanceFactory,
  questionSet: ConditionalQuestionSet | null | undefined,
  accessor: (
    question: TestQuestion,
    questionSetType: WswQuestionSetType,
    questionOrder: 'before' | 'after',
    questionSetIdentifier: string,
    index: number,
  ) =>
    | [MediaItemSnapshot | null | undefined, AV][]
    | (readonly [MediaItemSnapshot | null | undefined, AV])[],
  type: WswQuestionSetType,
  setId: string | null,
) {
  if (!questionSet) {
    return [];
  }
  return [
    ...(questionSet.questionsBefore?.flatMap((q, i) => {
      const values = accessor(q, type, 'before', setId ?? '', i);
      return values.map(([mediaItemSnapshot, annotatedValue]) => {
        return new AnnotatedValue(
          annotatedValue,
          mediaItemSnapshot
            ? imageFactory.createInstance(
                transformMediaItemData(mediaItemSnapshot),
              )
            : undefined,
        );
      });
    }) ?? []),
    ...(questionSet.questionsAfter?.flatMap((q, i) => {
      const values = accessor(q, type, 'after', setId ?? '', i);
      return values.map(([mediaItemSnapshot, annotatedValue]) => {
        return new AnnotatedValue(
          annotatedValue,
          mediaItemSnapshot
            ? imageFactory.createInstance(
                transformMediaItemData(mediaItemSnapshot),
              )
            : undefined,
        );
      });
    }) ?? []),
  ];
}

const loadQuestionSetPool = (
  imageFactory: DrawableInstanceFactory,
  questionSet: ConditionalQuestionSet | null | undefined,
  type: WswQuestionSetType,
  setId: string | null,
) =>
  loadQuestionsSetItemsPool(
    imageFactory,
    questionSet,
    (
      question,
      questionSetType,
      questionOrder,
      questionSetIdentifier,
      index,
    ) => [
      [
        question.mediaItemSnapshot,
        {
          questionSetType,
          questionOrder,
          questionSetIdentifier,
          index,
        },
      ],
    ],
    type,
    setId,
  );

const loadQuestionAnswerPool = (
  imageFactory: DrawableInstanceFactory,
  questionSet: ConditionalQuestionSet | null | undefined,
  type: WswQuestionSetType,
  setId: string | null,
) =>
  loadQuestionsSetItemsPool(
    imageFactory,
    questionSet,
    (question, questionSetType, questionOrder, questionSetIdentifier, index) =>
      question.imageMultipleChoice
        ? question.imageMultipleChoice.options.map(
            (option, optionIndex) =>
              [
                option,
                {
                  questionSetType,
                  questionOrder,
                  questionSetIdentifier,
                  index,
                  optionIndex,
                },
              ] as const,
          )
        : [],
    type,
    setId,
  );

export type WswMediaPool = ReturnType<typeof LoadWswMedia>;
