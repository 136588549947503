import React, { FC } from 'react';
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  Theme,
} from '@mui/material';
import { SxProps } from '@mui/system';

interface FormikNullableCheckboxProps {
  label: string;
  onChange: (v: boolean) => void;
  value: boolean;
  sx?: SxProps<Theme>;
  size?: CheckboxProps['size'];
  edge?: CheckboxProps['edge'];
}

export const FormikNullableCheckbox: FC<FormikNullableCheckboxProps> = (
  props,
) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.checked);
  };

  return (
    <FormControlLabel
      checked={props.value}
      sx={props.sx}
      control={
        <Checkbox
          edge={props.edge}
          size={props.size}
          checked={props.value}
          onChange={handleChange}
        />
      }
      label={props.label}
    />
  );
};
