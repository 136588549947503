import {
  MediaData,
  MediaInstance,
} from '../../../../subject/testRunner/media/MediaData';
import { MediaItemSnapshotInput } from '../../../../../API';

export class ColorMediaData extends MediaData {
  private _textValue: string | null;

  constructor(
    identifier: string,
    public color: string,
    public readonly groupPosition: number,
    textValue?: string | null,
  ) {
    super(identifier);
    this._textValue = textValue ?? null;
  }

  get textValue() {
    return this._textValue;
  }

  toMediaItemSnapshot(): MediaItemSnapshotInput {
    throw new Error(
      'color media data cannot be converted to MediaItemSnapshot',
    );
  }
}

export class ColorMediaInstance implements MediaInstance {
  constructor(public data: ColorMediaData) {}
}
