import React, { FC } from "react";
import { WSWAttributeType, WSWTest } from "../../../../../API";
import { WswAttributesView } from "./WswAttributesView";
import { Grid, Typography } from "@mui/material";
import { WswAttributeCombinationsView } from "./WswAttributeCombinationsView";

interface WswAttributesStepViewProps {
  test: WSWTest;
}

export const WswAttributesStepView: FC<WswAttributesStepViewProps> = ({ test }) => {
  const inconsistentAttributeConfig = ! test.groupConstruction?.groups &&
    (test.additionalAttribute?.type === WSWAttributeType.GROUPS || test.mainAttribute?.type === WSWAttributeType.GROUPS || test.criteriaAttribute?.type === WSWAttributeType.GROUPS);
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sx={{ borderBottom: "solid lightgrey 0.5px", borderRight: "solid lightgrey 0.5px" }}>
        <WswAttributesView attribute={test.mainAttribute} label={"Main Attribute"} />
      </Grid>
      <Grid item xs={6} sx={{ borderBottom: "solid lightgrey 0.5px" }}>
        <WswAttributesView attribute={test.criteriaAttribute} label={"Criteria Attribute"} />
      </Grid>
      {test.additionalAttribute && <Grid item xs={12} sx={{ borderBottom: "solid lightgrey 0.5px" }}>
        <WswAttributesView attribute={test.additionalAttribute} label={"Additional Attribute"} />
      </Grid>}
      {test.attributeCombinations &&
        test.mainAttribute &&
        test.criteriaAttribute &&
        ! inconsistentAttributeConfig &&
        <Grid item xs={12}>
          <WswAttributeCombinationsView
            combinations={test.attributeCombinations}
            mainAttribute={test.mainAttribute}
            criteriaAttribute={test.criteriaAttribute}
            additionalAttribute={test.additionalAttribute}
            groups={test.groupConstruction?.groups}
          />
        </Grid>}
      {inconsistentAttributeConfig &&
          <Typography color="error">An attribute is configured to use groups, but groups are not defined.</Typography>}
    </Grid>
  );
};
