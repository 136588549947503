import React, { FC, MouseEvent } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { TimeMeasuredValue } from '../../events/TimedEvent';
import { TimerBar } from '../../../components/TimerBar';

export interface RunnerButtonProps {
  onAction?: (e: TimeMeasuredValue<MouseEvent>) => number | undefined;
  buttonProps?: ButtonProps & { label: string };
}

export const RunnerButton: FC<RunnerButtonProps> = ({
  onAction,
  buttonProps,
}) => {
  const [timer, setTimer] = React.useState<number>();
  return timer !== undefined ? (
    <TimerBar
      seconds={timer}
      onFinish={() => {
        setTimer(0);
        setTimeout(() => setTimer(undefined), 1000);
      }}
      sx={{ width: 100, height: 30 }}
    />
  ) : (
    <Button
      sx={{ minHeight: '36.5px !important' }}
      variant="contained"
      color="primary"
      {...buttonProps}
      onClick={(e) => {
        if (onAction) {
          const delay = onAction(TimeMeasuredValue.now(e));
          setTimer(delay);
        } else if (buttonProps?.onClick) {
          buttonProps.onClick(e);
        }
      }}
    >
      {buttonProps?.label}
    </Button>
  );
};
