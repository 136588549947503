import { DefaultTransitionAction } from '../../../../subject/testRunner/graph-state/graph-action';
import { PodtaBoxPosition } from '../../../../../API';
import { Podt } from '../../types';
import { PodtStimulus } from '../../creator/podt-picker';
import { PodtBlockPhase } from '../structure/podt-block-structure';
import { PodtOrPodtaStructure } from '../structure/podt-structure';
import { Podta } from '../../../PODTA/types';
import { TestType } from '../../../../subject/types';
import { TextMediaStyle } from '../../../../subject/testRunner/media/text-drawer';
import { StimulusContent } from '../../../../subject/testRunner/screens/basic-views/stimulus-content';
import {
  ControlRequest,
  OptionsStereotypes,
} from '../../../../subject/testRunner/controls/control-stereotypes';
import { PODTModifiers } from './podt-modifiers';
import { DefaultControlTransition } from '../../../../subject/testRunner/controls/control-transition';
import { PODTBlockPointsSlice, PODTReactionSlice } from './podt-tree-state';
import { createTrialResultFactory, ResultFactory } from './podt-result-factory';
import {
  PodtaReactionSource,
  PodtReactionSource,
  ReactionTypeSource,
} from './podt-reaction-type-source';
import { PodtResultContent } from '../view-data/podt-result-content';
import { LazyStimulusContent } from '../view-data/lazy-stimulus-content';
import { PodtOptionsData } from '../view-data/podt-options-data';
import { MapScreenTreeNode } from '../../../../subject/testRunner/graph/nodes/map-screen-tree-node';
import { MapPhaseTreeNode } from '../../../../subject/testRunner/graph/nodes/map-phase-tree-node';

export function createBlockTrialsNode(
  blockNode: MapPhaseTreeNode,
  previousNode: MapScreenTreeNode,
  block: PodtBlockPhase,
  tipsContent: { textStyle: TextMediaStyle; content: string } | null,
  structure: PodtOrPodtaStructure<Podt | Podta>,
) {
  const trialsNode = new MapPhaseTreeNode('trials', blockNode)
    .connectParent()
    .transitionFrom(PODTModifiers.default, previousNode);
  const trialNodes = block.trialStimuli.map((trial, trialIndex) => {
    const trialNode = new MapPhaseTreeNode(
      'trial-' + trialIndex,
      trialsNode,
    ).connectParent(trialIndex === 0 ? PODTModifiers.default : undefined);
    let fixationNode = createTrialFixationNode(block, trialNode, tipsContent);
    const emptyPhase = createTrialEmptyPhase(
      trialNode,
      fixationNode,
      trial,
      tipsContent,
      block,
    );
    const createResult = createTrialResultFactory(
      trial,
      block,
      structure,
      trialIndex,
    );
    const stimulusNode = createTrialStimulusNode(
      trialNode,
      tipsContent,
      block,
      trial,
      emptyPhase,
      createResult,
      structure.accessor.testContext.testIdentity.testType === TestType.PODTA
        ? PodtaReactionSource
        : PodtReactionSource,
      structure,
    );
    new MapScreenTreeNode(
      'result',
      trialNode,
      new PodtOptionsData(tipsContent, block.options, new PodtResultContent()),
    )
      .connectParent()
      .controlRequest(
        OptionsStereotypes.TimeoutEvent.defineTransition(
          structure.accessor.modelData.interTrialInterval,
          () => DefaultTransitionAction.next(PODTModifiers.default),
        ),
      )
      .transitionFrom(PODTModifiers.default, stimulusNode);
    return trialNode;
  });
  trialNodes.reduce((acc, c) => c.transitionFrom(PODTModifiers.default, acc));
}

const PODTABoxPositionMap = {
  [PodtaBoxPosition.LEFT]: 'left',
  [PodtaBoxPosition.RIGHT]: 'right',
} as const;

export function createTrialStimulusNode(
  trialNode: MapPhaseTreeNode,
  tipsContent: { textStyle: TextMediaStyle; content: string } | null,
  block: PodtBlockPhase,
  trial: PodtStimulus,
  emptyPhase: MapPhaseTreeNode,
  createResult: ResultFactory,
  reactionTypeSource: ReactionTypeSource,
  structure: PodtOrPodtaStructure<Podt | Podta>,
) {
  const trialFeedback = structure.config.trialFeedback;
  const controlTransitionFactory = (
    controlRequest: ControlRequest<any>,
    type: 'left' | 'right' | undefined,
  ) => {
    return new DefaultControlTransition(controlRequest, {
      instanceAction(control, stateHolder) {
        const reactionType = reactionTypeSource(
          block,
          type,
          trial.target.metaData.variant,
          trial.scene.box.side
            ? PODTABoxPositionMap[trial.scene.box.side]
            : undefined,
        ); // type === undefined ? podtResponseMatrix['timeout'] : podtResponseMatrix[trial.target.metaData.variant][block.options.getOptionVariant("response", type)!];
        const points = trialFeedback
          ? stateHolder.get(PODTBlockPointsSlice).data.points +
            trialFeedback[reactionType].pointDifference
          : 0;
        return DefaultTransitionAction.result(
          PODTModifiers.default,
          createResult(
            control,
            stateHolder,
            type,
            points,
            trialFeedback ? trialFeedback[reactionType].pointDifference : 0,
            reactionType,
          ),
          PODTBlockPointsSlice.createSnapshot({
            points: points,
          }),
          PODTReactionSlice.createSnapshot({
            type: reactionType,
          }),
        );
      },
    });
  };
  const draw = trial.scene.drawInstance(trial.target);

  return new MapScreenTreeNode(
    'stimulus',
    trialNode,
    new PodtOptionsData(
      tipsContent,
      block.options,
      new LazyStimulusContent(draw),
    ),
  )
    .connectParent()
    .transitionFrom(PODTModifiers.default, emptyPhase)
    .controlRequest(
      controlTransitionFactory(
        OptionsStereotypes.Side.Left.requestControl(),
        'left',
      ),
    )
    .controlRequest(
      controlTransitionFactory(
        OptionsStereotypes.Side.Right.requestControl(),
        'right',
      ),
    )
    .controlRequest(
      controlTransitionFactory(
        OptionsStereotypes.TimeoutEvent.requestControl(
          structure.accessor.modelData.response.timeout,
        ),
        undefined,
      ),
    );
}

export function createTrialEmptyPhase(
  trialNode: MapPhaseTreeNode,
  fixationNode: undefined | MapScreenTreeNode,
  trial: PodtStimulus,
  tipsContent: { textStyle: TextMediaStyle; content: string } | null,
  block: PodtBlockPhase,
) {
  const emptyPhase = new MapPhaseTreeNode('empty', trialNode).connectParent(
    !fixationNode ? PODTModifiers.default : undefined,
  );
  fixationNode?.transition(PODTModifiers.default, emptyPhase);
  const emptySceneNodes = trial.emptyScenes.map((eS, eSI) => {
    const sceneInstance = eS.scene.value.instance;
    return new MapScreenTreeNode(
      'empty-' + eSI,
      emptyPhase,
      new PodtOptionsData(
        tipsContent,
        block.options,
        new LazyStimulusContent({
          size: sceneInstance.drawable.dimension,
          create: () => {
            return sceneInstance;
          },
        }),
      ),
    )
      .controlRequest(
        OptionsStereotypes.TimeoutEvent.defineTransition(eS.interval, (e) =>
          DefaultTransitionAction.next(PODTModifiers.default),
        ),
      )
      .connectParent(eSI === 0 ? PODTModifiers.default : undefined);
  });
  emptySceneNodes.reduce((acc, c, idx) =>
    c.transitionFrom(PODTModifiers.default, acc),
  );
  return emptyPhase;
}

export function createTrialFixationNode(
  block: PodtBlockPhase,
  trialNode: MapPhaseTreeNode,
  tipsContent: { textStyle: TextMediaStyle; content: string } | null,
) {
  let fixationNode: MapScreenTreeNode | undefined = undefined;
  if (block.intermediate.fixation) {
    fixationNode = new MapScreenTreeNode(
      'fixation',
      trialNode,
      new PodtOptionsData(
        tipsContent,
        block.options,
        new StimulusContent(block.intermediate.fixation.presentable),
      ),
    )
      .controlRequest(
        OptionsStereotypes.TimeoutEvent.defineTransition(
          block.intermediate.fixation.interval,
          (e) => DefaultTransitionAction.next(PODTModifiers.default),
        ),
      )
      .connectParent(PODTModifiers.default);
  }
  return fixationNode;
}
