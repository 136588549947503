import React, { FC } from 'react';
import { Box, Collapse, Tooltip } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import { useField } from 'formik';
import * as Yup from 'yup';
import { InfoOutlined } from '@mui/icons-material';
import { EditorVariables } from '../../../tests/text-replacer/textReplacer';
import { StyleableTextTemplateInput } from '../../../../API';
import { FormikNullableCheckbox } from '../../../../components/input/FormikNullableCheckbox';
import { Wysiwyg } from '../../../../components/input/wysiwyg/Wysiwyg';

interface OptionalInstructionsScreenProps {
  fieldName: string;
  checkboxLabel: string;
  dialogLabel: string;
  toolTip?: string;
  variables?: EditorVariables<string>;
  checkboxToolTip?: string;
}

export const OptionalInstructionsSchema = Yup.object()
  .nullable()
  .notRequired()
  .default(undefined)
  .shape({
    desktop: Yup.string().test(
      'empty',
      'Cannot be empty if shownsss',
      function (value) {
        const parent = this.parent as undefined | { desktop: string };

        if (parent === undefined) {
          return true;
        }
        return !(value === '' || value === undefined || value === null);
      },
    ),
  });

export const OptionalInstructionsScreen: FC<
  OptionalInstructionsScreenProps
> = ({
  fieldName,
  checkboxLabel,
  dialogLabel,
  variables,
  checkboxToolTip,
  toolTip,
}) => {
  const [, meta, helpers] = useField<
    StyleableTextTemplateInput | undefined | null
  >(fieldName);
  const hasValue = Boolean(meta.value);

  const styleableTextTemplate: StyleableTextTemplateInput = {
    desktop: `Replace this text as content`,
  };

  return (
    <Box>
      <React.Fragment>
        <FormikNullableCheckbox
          label={checkboxLabel}
          onChange={(checked) =>
            helpers.setValue(checked ? styleableTextTemplate : null)
          }
          value={hasValue}
          sx={{ mb: hasValue ? 1 : undefined }}
        />
        {checkboxToolTip && (
          <Tooltip placement={'right'} title={checkboxToolTip}>
            <InfoOutlined
              sx={{
                color: 'warning.dark',
                bgcolor: 'white',
                position: 'relative',
                ml: '-10px',
                mb: '-5px',
              }}
            />
          </Tooltip>
        )}
      </React.Fragment>
      <TransitionGroup>
        {meta.value !== null && meta.value !== undefined && (
          <Collapse>
            <Wysiwyg
              name={fieldName}
              label={dialogLabel}
              toolTip={toolTip}
              variables={variables}
            />
          </Collapse>
        )}
      </TransitionGroup>
    </Box>
  );
};
