import React, { FC, useMemo } from 'react';
import useCreateMediaItem, { ImageState } from './createMediaItem.hook';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField,
} from '@mui/material';
import { TagAutoComplete } from '../TagAutoComplete';
import { MediaItem } from '../types';
import { NativeImageUploadButton } from './NativeImageUploadButton';
import { AllInclusive } from '@mui/icons-material';
import { Alert } from '@mui/lab';

interface NewImageUploaderProps {
  onMediaItemsUploaded?: (mediaItems: MediaItem[]) => void;
}

export const NewImageUploader: FC<NewImageUploaderProps> = ({
  onMediaItemsUploaded,
}) => {
  const [error, setError] = React.useState<string[] | null>(null);
  const handleCloseAlert = () => setError(null);
  const {
    loading,
    currentFiles,
    updateCurrentFiles,
    uploadCurrentFiles,
    resetDropZone,
    selectedImagesIds,
    setSelectedImagesIds,
    applyTagToAll,
    applyTagsToAll,
    removeTagFromImage,
    source,
    setSource,
    applySourceAll,
  } = useCreateMediaItem();

  const handleUploadFiles = () => {
    uploadCurrentFiles()
      .then((result) => {
        if (typeof onMediaItemsUploaded === 'function') {
          onMediaItemsUploaded(result);
        }
      })
      .catch((error) => {
        if (error && error.stack) {
          const stack = JSON.parse(error.stack);
          const errors = stack.errors.map((e: any) => e.message);
          setError(errors);
        }
      });
  };

  const imagePreviewTableColumns: GridColDef[] = useMemo(
    () => [
      {
        field: 'urlFileObject',
        width: 200,
        headerName: 'Image',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <img
            height={57}
            src={(params.row as ImageState).localUrl}
            alt="preview"
          />
        ),
      },
      {
        field: 'id',
        width: 360,
        headerName: 'Tags',
        filterable: false,
        sortable: false,
        type: 'string',
        disableColumnMenu: true,
        renderCell: (params) => {
          const imageState = params.row as ImageState;
          return imageState.tags.map((tag) => (
            <Chip
              key={`tag-${tag.id}`}
              style={{ marginRight: 2 }}
              label={tag.name}
              onDelete={() =>
                removeTagFromImage(imageState.image.name, tag.id as string)
              }
            />
          ));
        },
      },
      {
        field: 'source',
        headerName: 'Source',
        width: 170,
        renderCell: (params) => params.row.state,
      },
    ],
    [removeTagFromImage],
  );

  return (
    <React.Fragment>
      <Snackbar
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="error"
          sx={{ width: '100%', whiteSpace: 'pre-line', wordWrap: 'break-word' }}
        >
          {error}
        </Alert>
      </Snackbar>

      <NativeImageUploadButton
        label="Upload Images"
        accept={'.png,.jpg,.jpeg'}
        onChange={updateCurrentFiles}
      />

      <Dialog
        open={currentFiles.length > 0}
        onClose={resetDropZone}
        maxWidth="lg"
      >
        <DialogTitle>Adding images</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              paddingTop: 8,
            }}
          >
            <TagAutoComplete
              applyOne={applyTagToAll}
              applyAll={applyTagsToAll}
            />
            <TextField
              label="Source"
              value={source}
              onChange={(e) => setSource(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton edge="end" onClick={applySourceAll}>
                    <AllInclusive />
                  </IconButton>
                ),
              }}
            />
          </div>
          <DataGridPro
            selectionModel={selectedImagesIds}
            onSelectionModelChange={setSelectedImagesIds}
            style={{ height: 500, width: '70vw', marginTop: 16 }}
            columns={imagePreviewTableColumns}
            rows={currentFiles}
          />
        </DialogContent>
        <DialogActions>
          {loading && <CircularProgress />}
          <Button disabled={loading} onClick={resetDropZone}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={handleUploadFiles}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
