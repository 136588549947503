import { RunnerConfiguration } from '../../../features/subject/testRunner/runner-configuration';
import { TestTree } from '../../../features/subject/testRunner/graph/test-tree';
import { TreeSequence } from '../../../features/subject/testRunner/graph/tree-sequence/tree-sequence';
import { TestResourceAccessor } from '../../../features/subject/testRunner/media/test-resource-loader';
import { NewGQL } from '../../../GQL';
import { DrawableDimension } from '../../../features/subject/testRunner/media/drawable-dimensions';
import { createResultPersister } from '../../../features/subject/testRunner/result-screens';
import { Subject } from 'rxjs';
import { TestFlowSnapshot } from '../../../features/subject/testRunner/graph-state/test-director';

export function scaleAndInitializeRunner(
  testLoader: RunnerConfiguration<any>,
  res: {
    tree: TestTree;
    sequence: TreeSequence;
    structure: { accessor: TestResourceAccessor<any> };
  },
) {
  const views = testLoader.createViews(res.structure);
  const selectorMap = testLoader.selectorTree.processNodes(
    Array.from(res.sequence.nodeMap.values()).map((v) => v.node),
  );
  const scaler = views.createScreenScaler(selectorMap);
  const testSize = new DrawableDimension(window.innerWidth, window.innerHeight);
  const resultQueue = createResultPersister(
    res.structure.accessor.testContext,
    res.structure.accessor.modelData.status,
    'fakeId',
    NewGQL.NO_AUTH_CLIENT,
  );

  return scaler.scaleTestScreen(testSize).then((res1) => {
    const flowSubject = new Subject<TestFlowSnapshot>();
    return {
      resultManager: resultQueue,
      subject: flowSubject,
      runnerConfig: testLoader,
      treeSequence: res.sequence,
      structure: res.structure,
      screenController: res1.createScreenController(flowSubject),
    };
  });
}
