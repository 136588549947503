import React from 'react';
import { TestType } from '../../../subject/types';
import { RootState, useAppDispatch } from '../../../../app/redux/store';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BaseTest } from '../../types';
import { TestDetailsBar } from './TestDetailsBar';
import { TestStep } from '../testEditor/types';
import { TestDetailsStepAccordion } from './TestDetailsStepAccordion';
import { useTestAdminNav } from '../utils';
import { PublishTestDialog } from '../dialogs/publishTest/PublishTestDialog';
import { TestThunks } from '../types';
import { LinearProgress } from '@mui/material';
import { useIsTestComplete } from '../testComplete.hook';
import TestSetupInstructions from '../../setup-instructions/TestSetupInstructions';
import { standAloneProvider } from '../../../subject/testRunner/state/TestProviderMap';
import { TestPermissionsAccordion } from '../testPermissions/TestPermissionsAccordion';
import { TestDetailsMeta } from './TestDetailsMeta';
import { useConfirm } from 'material-ui-confirm';
import { TestStatus } from '../../../../API';

interface TestDetailsProps<T, C, U> {
  type: TestType;
  thunks: TestThunks<T, C, U>;
  steps: TestStep[];
}

export function TestDetails<T extends BaseTest, C, U>({
  type,
  thunks,
  steps,
}: TestDetailsProps<T, C, U>) {
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const { testId } = useParams<'testId'>();

  const { navigateToEditor, navigateToList, navigateToSetup } =
    useTestAdminNav(type);

  const { test, loading } = useSelector((state: RootState) => ({
    loading: state[type].isLoading as boolean,
    // Todo neu_manu (bad types)
    test: state[type].tests[testId as string] as unknown as T | undefined,
  }));

  const [showPublishDialog, setShowPublishDialog] = React.useState(false);

  const handleDeleteTest = () => {
    confirm({
      title: 'Delete Test',
      description: `Are you sure you want to delete this test?\n\n${test?.name}\n\n`,
      confirmationButtonProps: { color: 'error' },
      confirmationKeyword: 'permanently delete',
      confirmationKeywordTextFieldProps: {
        placeholder: 'type "permanently delete"',
      },
    })
      .then(() => dispatch(thunks.deleteTest(testId as string)))
      .then(() => navigateToList())
      .catch(() => {});
  };

  const handleCloseTest = () => {
    confirm({
      title: 'Close Test',
      description: `Are you sure you want to close this test?\n\n${test?.name}\n\n`,
      confirmationKeyword: 'close test',
      confirmationKeywordTextFieldProps: {
        placeholder: 'type "close test"',
      },
    })
      .then(() =>
        dispatch(
          thunks.updateTestStatus({
            testId: testId as string,
            status: TestStatus.CLOSED,
          }),
        ),
      )
      .then(() => navigateToList())
      .catch(() => {});
  };

  const handleGoLive = () => {
    confirm({
      title: 'Go Live',
      description: `Are you sure you want to go live with this test?\n\n${test?.name}\n\n`,
      confirmationKeyword: 'go live',
      confirmationKeywordTextFieldProps: {
        placeholder: 'type "go live"',
      },
    }).then(() => {
      dispatch(
        thunks.updateTestStatus({
          testId: testId as string,
          status: TestStatus.LIVE,
        }),
      );
    });
  };

  const testIsComplete = useIsTestComplete(steps, test);

  const previewUrl = testIsComplete
    ? standAloneProvider.createTestUrl(
        {
          testType: type,
          testId: testId as string,
        },
        'preview',
        true,
        test?.language
          ? {
              lang: test.language,
            }
          : {},
      )
    : undefined;

  const standaloneUrl =
    testIsComplete &&
    [TestStatus.PUBLISHED, TestStatus.LIVE].includes(
      test?.status ?? TestStatus.DRAFT,
    )
      ? standAloneProvider.createTestUrl(
          {
            testType: type,
            testId: testId as string,
          },
          'execution',
          false,
        )
      : undefined;
  React.useEffect(() => {
    if (!loading && !test) {
      dispatch(thunks.loadTest(testId as string));
    }
  }, [dispatch, loading, test, testId, thunks]);

  if (!test) {
    return <LinearProgress />;
  }

  return (
    <div>
      <TestSetupInstructions test={test} testType={type} steps={steps} />
      <PublishTestDialog
        type={type}
        steps={steps}
        id={showPublishDialog ? (testId as string) : undefined}
        onClose={() => setShowPublishDialog(false)}
        updateTestStatus={thunks.updateTestStatus}
      />
      <TestDetailsBar
        testType={type}
        test={test}
        loading={loading}
        onClickClose={handleCloseTest}
        onClickLive={handleGoLive}
        onClickDelete={handleDeleteTest}
        onClickPublish={() => setShowPublishDialog(true)}
        onClickDuplicate={() => navigateToEditor('duplicate', testId)}
        onClickEdit={() => navigateToEditor('edit', testId)}
        onClickSetup={() => navigateToSetup()}
        previewUrl={previewUrl}
        standaloneUrl={standaloneUrl}
      />
      <TestDetailsMeta
        id={test.id}
        createdAt={test.createdAt}
        updatedAt={test.updatedAt}
        description={test.description}
      />
      <TestPermissionsAccordion
        testId={test.id}
        testType={type}
        ownerId={test.owner}
        testViewersIds={test.viewers}
        testEditorsIds={test.editors}
        resultsViewersIds={test.resultViewers}
        onReloadTest={() => dispatch(thunks.loadTest(test.id))}
      />
      {steps.map((step, stepIndex) => (
        <TestDetailsStepAccordion
          key={`step-accordion-${stepIndex}`}
          test={test}
          stepIndex={stepIndex}
          step={step}
        />
      ))}
    </div>
  );
}
