import { createTestCrudMap, createTestResultCrudMap } from '../../../../../GQL';
import { Podt, PodtResult } from '../../types';
import {
  CreatePodtResultMutationVariables,
  CreatePodtTestMutationVariables,
  DeletePodtResultMutationVariables,
  DeletePodtTestMutation,
  GetPodtResultQueryVariables,
  GetPodtTestQueryVariables,
  ListPodtResultsQueryVariables,
  ListPodtTestsQueryVariables,
  PodtResultsByTestIdQueryVariables,
  UpdatePodtResultMutationVariables,
  UpdatePodtTestMutationVariables,
} from '../../../../../API';
import {
  createPodtResult,
  createPodtTest,
  deletePodtResult,
  deletePodtTest,
  updatePodtResult,
  updatePodtTest,
} from '../../../../../graphql/mutations';
import {
  getPodtResult,
  listPodtResults,
  listPodtTests,
  podtResultsByTestId,
} from '../../../../../graphql/queries';
import { getPublicPodt } from '../../../../../graphql/customQueries';

export const PodtQueries = createTestCrudMap<Podt>()((query) => ({
  Get: query.Get<GetPodtTestQueryVariables>(getPublicPodt),
  List: query.List<ListPodtTestsQueryVariables>(listPodtTests),
  Create: query.Mutate<CreatePodtTestMutationVariables>(createPodtTest),
  Update: query.Mutate<UpdatePodtTestMutationVariables>(updatePodtTest),
  Delete: query.Mutate<DeletePodtTestMutation>(deletePodtTest),
}));

export const PodtResultQueries = createTestResultCrudMap<PodtResult>()(
  (query) => ({
    ListByTest:
      query.List<PodtResultsByTestIdQueryVariables>(podtResultsByTestId),
    List: query.List<ListPodtResultsQueryVariables>(listPodtResults),
    Get: query.Get<GetPodtResultQueryVariables>(getPodtResult),
    Update: query.Mutate<UpdatePodtResultMutationVariables>(updatePodtResult),
    Delete: query.Mutate<DeletePodtResultMutationVariables>(deletePodtResult),
    Create: query.Mutate<CreatePodtResultMutationVariables>(createPodtResult),
  }),
);
