import { groupBy } from 'lodash';

export type BaseMetaDataMap = Record<string, string | number>;

export class AnnotatedValue<M extends BaseMetaDataMap, V> {
  constructor(
    public readonly metaData: M,
    public readonly value: V,
  ) {}

  matchesFilter(filter: AnnotationFilterType<M>) {
    return Object.entries(filter)
      .filter(([, v]) => v !== undefined)
      .map(
        ([k, v]) =>
          (i: M) =>
            (Array.isArray(v) ? v : [v as string | number]).includes(
              i[k as keyof M],
            ),
      )
      .reduce((acc, c) => acc && c(this.metaData), true);
  }
}

type AnnotationFilterType<M extends BaseMetaDataMap> = {
  [m in keyof M & string]?: M[m] | M[m][];
};

type AnnotationFilterSelectType<
  M extends BaseMetaDataMap,
  F extends AnnotationFilterType<M>,
> = {
  [m in keyof M & string]: F[m] extends undefined
    ? M[m] & (string | number)
    : F[m] extends (infer A)[]
    ? A extends string | number
      ? A
      : never
    : F[m] extends infer B
    ? B extends string | number
      ? B
      : never
    : never;
};

export class AnnotatedDataPipe<M extends BaseMetaDataMap, V> {
  protected constructor(public values: AnnotatedValue<M, V>[]) {}

  collect(): AnnotatedValue<M, V>[] {
    return [...this.values];
  }

  collectGrouped(groupKeys: (keyof M)[]): AnnotatedValue<M, V>[][] {
    return groupKeys.reduce(
      (acc, c) =>
        acc.flatMap((a) => Object.values(groupBy(a, (a) => a.metaData[c]))),
      [this.values] as AnnotatedValue<M, V>[][],
    );
  }

  select<F extends AnnotationFilterType<M>>(
    filter: F,
  ): AnnotatedDataPipe<AnnotationFilterSelectType<M, F>, V> {
    // const filterMatcher = Object.entries(filter).filter(([,v]) => v !== undefined).map(([k,v]) => (i: M) => (Array.isArray(v) ? v : [v as string | number]).includes(i[k as keyof M])).reduce((acc,c) => (a: M) => acc(a) && c(a))
    return new AnnotatedDataPipe<AnnotationFilterSelectType<M, F>, V>(
      this.values.filter((v) => v.matchesFilter(filter)) as AnnotatedValue<
        AnnotationFilterSelectType<M, F>,
        V
      >[],
    );
  }

  static of<M extends BaseMetaDataMap, V>(values: AnnotatedValue<M, V>[]) {
    return new AnnotatedDataPipe(values);
  }
}

export class ObjectTransformer<T extends Record<string | number, any>> {
  protected constructor(protected readonly value: T) {}

  static typedEntries<T extends object>(
    obj: T,
  ): { [k in keyof T]: [k, T[k]] }[keyof T][] {
    return Object.entries(obj) as { [k in keyof T]: [k, T[k]] }[keyof T][];
  }

  static fromTypedEntries<K extends string | number | symbol, V>(
    entries: ([K, V] | readonly [K, V])[],
  ): Record<K, V> {
    return Object.fromEntries(entries) as Record<K, V>;
  }

  static typedKeys<T extends object>(obj: T): (keyof T)[] {
    return Object.keys(obj) as (keyof T)[];
  }

  mapValues<M>(mapper: (value: T[keyof T], key: keyof T, obj: T) => M) {
    return new ObjectTransformer<Record<keyof T, M>>(
      Object.fromEntries(
        ObjectTransformer.typedEntries(this.value).map(([k, v]) => [
          k,
          mapper(v, k as keyof T, this.value),
        ]),
      ) as Record<keyof T, M>,
    );
  }

  get result() {
    return this.value;
  }

  static of<T extends object>(value: T) {
    return new ObjectTransformer(value);
  }
}

function groupByMapped<T extends object, K1 extends string, M>(
  obj: T[],
  keySelector: (m: T) => K1,
  mapper: (m: T[], key: K1) => M,
) {
  return ObjectTransformer.of(
    groupBy(obj, (v) => keySelector(v)) as Record<K1, T[]>,
  ).mapValues((values, key) => mapper(values, key)).result;
}

export class MetaDataMediaSelector<T, M extends BaseMetaDataMap> {
  protected rootSelector: MetaDataMediaSelector<any, any>;
  protected constructor(
    protected values: AnnotatedValue<M, T>[],
    rootSelector?: MetaDataMediaSelector<any, any>,
  ) {
    this.rootSelector = rootSelector ?? this;
  }

  selectRaw<K extends keyof T>(
    ...keys: K[]
  ): MetaDataMediaSelector<Exclude<T[K], null | undefined>, M> {
    return new MetaDataMediaSelector<Exclude<T[K], null | undefined>, M>(
      this.values.flatMap((v) =>
        keys.map(
          (k) =>
            new AnnotatedValue<M, Exclude<T[K], null | undefined>>(
              v.metaData,
              v.value[k] as Exclude<T[K], null | undefined>,
            ),
        ),
      ),
      this.rootSelector,
    );
  }

  splitMetaKey<L extends keyof M, O extends Record<M[L], any>>(
    extractKey: L,
    splitMap: {
      [a in keyof O]: (
        subB: MetaDataMediaSelector<T, Omit<M, L> & { [b in L]: a }>,
      ) => O[a];
    },
  ): { [a in keyof O]: O[a] } {
    return ObjectTransformer.of(splitMap).mapValues((v, k) =>
      v(
        new MetaDataMediaSelector<T, Omit<M, L> & { [b in L]: keyof O }>(
          this.values.filter((v) => v.metaData[extractKey] === k),
          this.rootSelector,
        ),
      ),
    ).result as { [a in keyof O]: O[a] };
  }

  select<
    K extends string,
    N extends BaseMetaDataMap & Partial<Record<keyof T, string | number>>,
  >(
    keyName: K,
    dataMap: N,
  ): MetaDataMediaSelector<
    Exclude<T[keyof N & keyof T], null | undefined>,
    M & { [n in K]: N[keyof N & keyof T] }
  > {
    return new MetaDataMediaSelector(
      this.values.flatMap((v) =>
        ObjectTransformer.typedEntries(dataMap).map(([k, mv]) => {
          const metaVal = mv;
          const val = v.value[k as keyof N & keyof T];
          return new AnnotatedValue<
            M & { [n in K]: N[keyof T] },
            Exclude<T[keyof N & keyof T], null | undefined>
          >(
            {
              ...v.metaData,
              [keyName]: metaVal,
            } as M & { [n in K]: N[keyof N & keyof T] },
            val as Exclude<T[keyof N & keyof T], null | undefined>,
          );
        }),
      ),
      this.rootSelector,
    );
  }

  selectMap<K extends string, R, V>(
    keyName: K,
    mapper: (value: T) => [R, V][],
  ): MetaDataMediaSelector<R, M & { [n in K]: V }> {
    return new MetaDataMediaSelector(
      this.values.flatMap((v) => {
        const mapResult = mapper(v.value);
        return mapResult.map(
          ([result, meta]) =>
            new AnnotatedValue<M & { [n in K]: V }, R>(
              {
                ...v.metaData,
                [keyName]: meta,
              },
              result,
            ),
        );
      }),
      this.rootSelector,
    );
  }

  mapValues<R>(mapper: (value: T) => R): MetaDataMediaSelector<R, M> {
    return new MetaDataMediaSelector(
      this.values.flatMap((v) => {
        const mapResult = mapper(v.value);
        return new AnnotatedValue<M, R>(
          {
            ...v.metaData,
          },
          mapResult,
        );
      }),
      this.rootSelector,
    );
  }

  collect(): AnnotatedValue<M, T>[] {
    return [...this.values];
  }

  collectFirst(): AnnotatedValue<M, T> {
    return this.values[0];
  }

  collectMappedFirst<K extends keyof M>(
    key: K,
  ): { [n in M[K] & string]: AnnotatedValue<Omit<M, K> & { [k in K]: n }, T> } {
    return groupByMapped(
      this.values,
      (v) => v.metaData[key] as M[K] & string,
      (v) => v[0] as AnnotatedValue<Omit<M, K> & { [k in K]: M[K] }, T>,
    ) as {
      [n in M[K] & string]: AnnotatedValue<Omit<M, K> & { [k in K]: n }, T>;
    };
  }

  nestSelectors<R, Root>(
    builder: (
      s: () => MetaDataMediaSelector<T, M>,
      root: MetaDataMediaSelector<Root, any>,
    ) => R,
  ): R {
    return builder(
      () => new MetaDataMediaSelector<T, M>([...this.values]),
      this.rootSelector,
    );
  }

  static of<T>(...value: T[]) {
    return new MetaDataMediaSelector(
      value.map((v) => new AnnotatedValue<{}, T>({}, v)),
    );
  }
}
