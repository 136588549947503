import { Badge, Box, Tooltip, Typography } from '@mui/material';
import { renderMediaItem } from './pickerUtils';
import React from 'react';
import { theme } from '../../../theme';
import { MediaItem } from '../types';
import { MediaItemSnapshotInput, MediaItemSnapshotScope } from '../../../API';
import PublicIcon from '@mui/icons-material/Public';

export const mediaItemStyles = {
  display: 'inline-flex',
  position: 'relative',
  padding: 55,
  // height: 55,
  background: 'transparent',
  borderRadius: '50%',
  border: '1px solid rgba(0,0,0,0.12)',
  margin: 0,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: '4px 0',
  },
  '& img': {
    height: 112,
  },
  '& p': {
    fontWeight: 'bold',
    display: 'inline-block',
    position: 'absolute',
    width: '95%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    margin: 0,
    padding: 0,
    textAlign: 'center',
    lineHeight: '190%',
  },
};

type MediaItemPickerPreviewProps = {
  mediaItem: MediaItem | MediaItemSnapshotInput;
  label?: string;
  color?: string | null;
  bgColor?: string | null;
};

export default function MediaItemPickerPreview({
  mediaItem,
  label,
  color,
  bgColor,
}: MediaItemPickerPreviewProps): JSX.Element {
  const mediaItemStyle = {
    color: color || 'black',
    background: bgColor || 'white',
    paddingLeft: 4,
  };
  const renderedMediaItemElement = renderMediaItem(
    mediaItem,
    false,
    undefined,
    false,
  );

  return (
    <Box>
      {label && <Typography color="textSecondary">{label}</Typography>}
      <Box style={mediaItemStyle}>
        {(mediaItem as MediaItemSnapshotInput).scope !==
        MediaItemSnapshotScope.PRIVATE ? (
          <Badge
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            badgeContent={
              <Tooltip title="This image is part of the public MIND.set stimuli pool">
                <PublicIcon color="info" fontSize="small" />
              </Tooltip>
            }
          >
            {renderedMediaItemElement}
          </Badge>
        ) : (
          renderedMediaItemElement
        )}
      </Box>
    </Box>
  );
}
