import React from 'react';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { QuestionAnswerListener } from './question-answer-listener';
import { WswStereotypes } from '../../wsw-controls';
import { BorderColor } from '@mui/icons-material';
import { WswTestQuestionInstance } from './wsw-test-question-instance';
import { WswQuestionType } from '../../../../../../API';
import { TestBaseContext } from '../../../../../subject/testRunner/state/baseTestContext/context/ContextTypes';
import { MediaInstance } from '../../../../../subject/testRunner/media/MediaData';
import { QuestionLocator } from './question-locator';
import { styled } from '@mui/styles';

const AnswerTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    padding: '3px',
  },
});

function WSWMobileTestTextQuestionComponent({
  questionInstance,
}: {
  questionInstance: WswTestTextQuestionInstance;
}) {
  const [text, setText] = React.useState('');
  const [focus, setFocus] = React.useState(false);
  const [topLeft, setTopLeft] = React.useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (containerRef.current !== null) {
      const boundingClientRect = containerRef.current
        ?.querySelector('div')
        ?.getBoundingClientRect();
      if (boundingClientRect) {
        setTopLeft({
          top: -1 * (boundingClientRect.top + boundingClientRect.height * 0.5),
          left: -1 * boundingClientRect.left,
        });
      }
    }
    // CAUTION: Since the runner circumvent and rearranges some React Render flows, this might actually a necessary dep, but i can't really remember... This should be verified on multiple devices before removal
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]);

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'relative',
        width: '100%',
      }}
    >
      <Box
        sx={
          focus
            ? {
                ...topLeft,
                position: 'absolute',
                bgcolor: 'white',
                width: window.innerWidth,
                height: window.innerHeight,
              }
            : { width: '100%' }
        }
      >
        <Box
          width="100%"
          position="relative"
          padding={1}
          sx={{ pt: focus ? '5px' : undefined }}
        >
          {focus && (
            <Typography variant="body2" sx={{ marginBottom: 0 }}>
              {questionInstance.questionText}
            </Typography>
          )}
          {focus && (
            <AnswerTextField
              fullWidth
              multiline
              autoFocus
              value={text}
              sx={{
                padding: 0,
                margin: 0,
              }}
              margin="none"
              size="small"
              onChange={(evt) => setText(evt.target.value)}
              /*sx={{ minWidth: 200 }} */
              inputProps={{
                style: {
                  padding: 0,
                  margin: 0,
                },
              }}
              InputProps={{
                inputProps: {
                  style: {
                    padding: 0,
                    margin: 0,
                  },
                },
                margin: 'none',
                size: 'small',
                endAdornment: focus ? (
                  <Button
                    disabled={text.trim() === ''}
                    onClick={QuestionAnswerListener((e) => {
                      setText('');
                      setFocus(false);
                      return WswStereotypes.Answer.Answer.controlOccurred(
                        e.map((c) => ({
                          event: (c as any).nativeEvent as MouseEvent,
                          answer: questionInstance.toAnswer(text, false),
                        })),
                      );
                    })}
                    variant="contained"
                    color="primary"
                  >
                    ✔
                  </Button>
                ) : undefined,
              }}
            />
          )}
        </Box>
      </Box>
      <Box width="100%" display="flex" justifyContent="center">
        <IconButton
          onClick={() => {
            setFocus(true);
            setTimeout(() => {
              document.body.scrollTo({ left: 0, top: 0 });
            }, 500);
          }}
        >
          <BorderColor />
        </IconButton>
      </Box>
    </Box>
  );
}

function WSWDesktopTestTextQuestionComponent({
  questionInstance,
}: {
  questionInstance: WswTestTextQuestionInstance;
}) {
  const [text, setText] = React.useState('');

  return (
    <Box
      sx={{
        justifyContent: 'center',
        display: 'flex',
        position: 'relative',
        width: '100%',
      }}
    >
      <TextField
        value={text}
        onChange={(evt) => setText(evt.target.value)}
        sx={{ minWidth: 200 }}
      />
      <Button
        disabled={text.trim() === ''}
        onClick={QuestionAnswerListener((e) => {
          setText('');
          return WswStereotypes.Answer.Answer.controlOccurred(
            e.map((c) => ({
              event: (c as any).nativeEvent as MouseEvent,
              answer: questionInstance.toAnswer(text, false),
            })),
          );
        })}
        variant="contained"
        color="primary"
        sx={{ position: 'absolute', right: 0, top: 0 }}
      >
        ✔
      </Button>
    </Box>
  );
}

export class WswTestTextQuestionInstance extends WswTestQuestionInstance {
  readonly type: WswQuestionType.FreeText = WswQuestionType.FreeText;

  constructor(
    public readonly testContext: TestBaseContext,
    questionText: string,
    stimulus: MediaInstance | null,
    questionLocator: QuestionLocator,
    timeout: number,
  ) {
    super(
      WswQuestionType.FreeText,
      questionText,
      stimulus,
      questionLocator,
      timeout,
    );
  }

  createComponent(): React.FC {
    if (this.testContext.deviceInfo.mode === 'mobile') {
      return () => (
        <WSWMobileTestTextQuestionComponent questionInstance={this} />
      );
    } else {
      return () => (
        <WSWDesktopTestTextQuestionComponent questionInstance={this} />
      );
    }
  }
}
