import {
  WswConstructedGroup,
  WswDistractionPhase,
  WswStructure,
} from '../structure/wsw-structure';
import {
  ControlOccurrenceProcessor,
  DefaultTransitionAction,
} from '../../../../subject/testRunner/graph-state/graph-action';
import { QuestionAnswerEvent, WswStereotypes } from '../wsw-controls';
import {
  createGroupIndicationResult,
  createWSWResultQuery,
} from './wsw-tree-utils';
import { WswContentType, WswTestPhase } from '../../../../../API';
import { WSWModifiers } from './wsw-modifiers';
import { createQuestionSetSubTree } from './wsw-tree-question-set';
import { WswInstructionsData } from '../view-data/wsw-instructions-data';
import { MapScreenTreeNode } from '../../../../subject/testRunner/graph/nodes/map-screen-tree-node';
import { MapPhaseTreeNode } from '../../../../subject/testRunner/graph/nodes/map-phase-tree-node';

export function createDistractionPhaseTree(
  root: MapPhaseTreeNode,
  structure: WswStructure,
  distractionPhase: WswDistractionPhase,
  groupIndication?: WswConstructedGroup,
) {
  const distractionPhaseNode = new MapPhaseTreeNode(
    'distraction-phase',
    root,
  ).connectParent();

  const createResultQuery = (
    control: ControlOccurrenceProcessor<QuestionAnswerEvent>,
    trial: number,
  ) => {
    return createWSWResultQuery(structure, control, {
      groupIndication: groupIndication
        ? createGroupIndicationResult(
            groupIndication,
            structure.groupConstructionPhase?.position !== undefined,
          )
        : null,
      phase: WswTestPhase.DistractionPhase,
      position: structure.groupConstructionPhase ? 2 : 1,
      trialIndex: trial,
      contentType: WswContentType.Stimulus,
    });
  };
  let questionsPhaseNode: MapPhaseTreeNode | undefined;
  if (distractionPhase.questionSet.questionsBefore.length > 0) {
    questionsPhaseNode = createQuestionSetSubTree(
      distractionPhase.questionSet,
      distractionPhaseNode,
      {
        showPlainStimulusBeforeQuestion: true,
        groupIndication,
        resultQuery: (control, order, questionIndex) =>
          createResultQuery(control, questionIndex),
      },
      {
        parentConnector: distractionPhase.startInstructions
          ? undefined
          : WSWModifiers.default,
      },
    ).questionSetPhaseNode;
  }

  let startInstructionsNode: MapScreenTreeNode | undefined;
  if (distractionPhase.startInstructions) {
    startInstructionsNode = new MapScreenTreeNode(
      'start-instructions',
      distractionPhaseNode,
      new WswInstructionsData(
        distractionPhase.startInstructions,
        groupIndication,
      ),
    )
      .connectParent(WSWModifiers.default)
      .controlRequest(
        WswStereotypes.Confirm.defineTransition(undefined, () =>
          DefaultTransitionAction.next(WSWModifiers.default),
        ),
      );
    if (questionsPhaseNode) {
      startInstructionsNode.transition(
        WSWModifiers.default,
        questionsPhaseNode,
      );
    }
  }
  if (distractionPhase.endInstructions) {
    const endInstructionNode = new MapScreenTreeNode(
      'end-instructions',
      distractionPhaseNode,
      new WswInstructionsData(
        distractionPhase.endInstructions,
        groupIndication,
      ),
    )
      .connectParent(
        !distractionPhase.startInstructions &&
          distractionPhase.questionSet.questionsBefore.length === 0
          ? WSWModifiers.default
          : undefined,
      )
      .controlRequest(
        WswStereotypes.Confirm.defineTransition(undefined, () =>
          DefaultTransitionAction.next(WSWModifiers.default),
        ),
      );
    if (questionsPhaseNode) {
      endInstructionNode.transitionFrom(
        WSWModifiers.default,
        questionsPhaseNode,
      );
    } else if (startInstructionsNode) {
      endInstructionNode.transitionFrom(
        WSWModifiers.default,
        startInstructionsNode,
      );
    }
  }
  return distractionPhaseNode;
}
