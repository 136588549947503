import { Box, Typography } from '@mui/material';
import { formatDate } from '../../../../dateUtils';
import React, { FC } from 'react';

type TestDetailsMetaProps = {
  id: string;
  description?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export const TestDetailsMeta: FC<TestDetailsMetaProps> = ({
  id,
  createdAt,
  updatedAt,
  description,
}) => (
  <Box sx={{ display: 'flex', m: 1, justifyContent: 'space-between' }}>
    <div>
      <Typography color="textSecondary" variant="subtitle2">
        Created on: {formatDate(createdAt)}
      </Typography>
      <Typography color="textSecondary" variant="subtitle2">
        Updated on: {formatDate(updatedAt)}
      </Typography>
      <Typography sx={{ flexGrow: 1, my: 1 }}>{description}</Typography>
    </div>
    <Typography color="textSecondary" variant="subtitle2">
      Test-ID: {id}
    </Typography>
  </Box>
);
