import {
  FontFace,
  FontStyleInput,
  MediaItemSnapshotInput,
  MediaItemSnapshotScope,
  TextAlignment,
} from '../../API';
import { v4 as uuid } from 'uuid';

export const createEmptyMediaItemSnapshot = (): MediaItemSnapshotInput => ({
  id: uuid(),
  tags: [],
  scope: MediaItemSnapshotScope.PRIVATE,
});

export const createDefaultFontStyle = (
  alignment: TextAlignment,
): FontStyleInput => ({
  fontFace: FontFace.ARIAL,
  fontSize: 14,
  color: 'black',
  alignment,
});
