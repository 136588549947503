import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { FunctionComponent } from 'react';
import MuiDialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import StyledIconButton from '../input/StyledIconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(1)}`,
    width: '100%',
    position: 'relative',
    height: '100%',
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '90vw',
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.5),
    },
  },
  title: {
    // padding: `0 ${theme.spacing(1)}`
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    color: theme.palette.grey[600],
  },
  saveButton: {
    display: 'block',
    width: 300,
    margin: `${theme.spacing(1)} auto`,
  },
  content: {
    width: '100%',
    height: 'auto',
    padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
  },
}));

type StyledDialogProps = MuiDialogProps & {
  label?: string;
  open: boolean;
  onClose: () => void;
  onSave?: () => void;
};

const StyledDialog: FunctionComponent<StyledDialogProps> = ({
  label,
  children,
  open,
  onClose,
  onSave,
  ...props
}) => {
  const classes = useStyles();

  const header = (
    <MuiDialogTitle className={classes.root}>
      {label && (
        <Typography variant="h6" className={classes.title}>
          {label}
        </Typography>
      )}
      <StyledIconButton
        label="close"
        onClick={onClose}
        className={classes.closeButton}
      >
        <CloseIcon />
      </StyledIconButton>
    </MuiDialogTitle>
  );

  const saveButton = onSave && (
    <Button
      className={classes.saveButton}
      variant="contained"
      color="secondary"
      onClick={onSave}
      title={`Save ${label}`}
    >
      Save
    </Button>
  );

  return (
    <MuiDialog
      open={open}
      onBackdropClick={onClose}
      className={classes.dialog}
      {...props}
    >
      <DialogTitle>{header}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      {onSave && (
        <DialogActions disableSpacing={true}>{saveButton}</DialogActions>
      )}
    </MuiDialog>
  );
};

export default StyledDialog;
