import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { SyncAlt } from '@mui/icons-material';
import StyledIconButton from '../StyledIconButton';

interface SmartPhoneButtonPickerProps {
  leftLabel: string;
  rightLabel: string;
  onFlipLabels?: (l: string, r: string) => void;
  active?: 'left' | 'right';
}

export const SmartPhoneButtonPicker: FC<SmartPhoneButtonPickerProps> = ({
  leftLabel,
  rightLabel,
  onFlipLabels,
  active,
}) => {
  const leftActive = active === 'left';
  const rightActive = active === 'right';

  return (
    <Box
      sx={{
        border: 1,
        borderRadius: 2,
        display: 'flex',
        width: '100%',
        height: 190,
        borderColor: 'text.secondary',
        pt: 1,
        pb: 1,
        pr: 1,
        pl: 0.5,
        alignItems: 'center',
      }}
    >
      <Box sx={{ border: 1, height: '40%', mr: 0.5 }} />
      <Box
        sx={{
          border: 1,
          display: 'flex',
          flexGrow: 1,
          borderColor: 'text.secondary',
          height: '100%',
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '20%',
            bgcolor: 'primary.main',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...(leftActive && {
              border: 3,
              borderColor: 'orange',
            }),
          }}
        >
          <Typography sx={{ color: 'white' }}>{leftLabel}</Typography>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {onFlipLabels && (
            <StyledIconButton
              label="click to switch response categories"
              onClick={() => onFlipLabels(leftLabel, rightLabel)}
            >
              <SyncAlt />
            </StyledIconButton>
          )}
        </Box>
        <Box
          sx={{
            height: '100%',
            width: '20%',
            bgcolor: 'primary.main',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...(rightActive && {
              border: 3,
              borderColor: 'orange',
            }),
          }}
        >
          <Typography sx={{ color: 'white' }}>{rightLabel}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
