import React from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useField } from 'formik';
import { Add, Build } from '@mui/icons-material';
import * as Yup from 'yup';
import {
  AmpBlockInput,
  AmpLabeledStimuliPoolInput,
  BlockType,
  CategoryVariant,
} from '../../../../../API';
import StyledTextInput from '../../../../../components/input/StyledTextInput';
import MediaItemPicker from '../../../../media/picker/MediaItemPicker';
import BlockSettings from '../../../testAdmin/testEditor/BlockSettings';
import {
  EditorSectionTitle,
  EditorStepTitle,
} from '../../../../../components/grid';
import TextStyleDialog from '../../../../../components/TextStyleDialog';
import { TestType } from '../../../../subject/types';
import { AmpVariables } from '../../../text-replacer/textReplacer';
import { instructionsSchema } from '../../../testAdmin/testEditor/instructionsSchema';

const ampCategoriesLabelsSchema = Yup.object().shape({
  categories: Yup.array()
    .min(1, 'min 1')
    .of(
      Yup.object()
        .required()
        .shape({
          label: Yup.string().required('required'),
        }),
    ),
});

export const BlockSettingsSchema = Yup.object().shape({
  attributeCategories: Yup.object().shape({
    categoryA: Yup.object().test(
      'hasStimulus',
      'category must be chosen.',
      (value: any) => Boolean(value.image || value.text),
    ),
    categoryB: Yup.object().test(
      'hasStimulus',
      'category must be chosen.',
      (value: any) => Boolean(value.image || value.text),
    ),
  }),
  primes: ampCategoriesLabelsSchema,
  blocks: Yup.array()
    .min(1)
    .of(
      Yup.object().shape({
        name: Yup.string().required('block name required'),
        instructions: instructionsSchema,
        type: Yup.string().required('required'),
        amountTrials: Yup.number().required('amount trials required'),
      }),
    ),
});

const AmpBlockSettings = () => {
  const [, { value: blocks }, blockHelpers] =
    useField<AmpBlockInput[]>('blocks');
  const [, { value: primeCategories }, primesHelpers] =
    useField<AmpLabeledStimuliPoolInput[]>('primes.categories');

  const attributeCategories = (
    <Stack spacing={4}>
      <Stack direction="row" spacing={3}>
        <MediaItemPicker
          name="attributeCategories.categoryA"
          label="Attribute Category A"
          colorFieldName="attributeCategories.fontStyle.color"
          bgColorFieldName="style.backgroundColour"
        />
        <MediaItemPicker
          name="attributeCategories.categoryB"
          label="Attribute Category B "
          colorFieldName="attributeCategories.fontStyle.color"
          bgColorFieldName="style.backgroundColour"
        />
        <TextStyleDialog
          label={'Category Style'}
          name={'attributeCategories.fontStyle'}
        >
          <Build />
        </TextStyleDialog>
      </Stack>
    </Stack>
  );

  const primeCategoriesEl = (
    <Stack direction="row" spacing={3} sx={{ mt: 3, mb: 3 }}>
      {primeCategories.map((_, categoryIndex) => {
        const primeCategoryPath = `primes.categories[${categoryIndex}]`;
        return (
          <Box width={279} key={`primeCategories-${categoryIndex}`}>
            <Typography color="textSecondary" sx={{ mb: 2 }}>{`Prime Category ${
              categoryIndex + 1
            }`}</Typography>
            <StyledTextInput
              name={`${primeCategoryPath}.label`}
              label="Label"
              InputProps={{
                endAdornment:
                  categoryIndex !== 0 ? (
                    <IconButton
                      onClick={() =>
                        primesHelpers.setValue(
                          primeCategories.filter((_, i) => i !== categoryIndex),
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : undefined,
              }}
            />
          </Box>
        );
      })}
      <Box display="flex" alignItems="flex-end" pb={1.5}>
        <Button
          sx={{ width: 279 }}
          onClick={() => {
            const updatedPrimes: AmpLabeledStimuliPoolInput[] = [
              ...primeCategories,
              {
                label: '',
                testStimuliPool: [],
                practiceStimuliPool: [],
              },
            ];
            primesHelpers.setValue(updatedPrimes);
          }}
          startIcon={<Add />}
          title="Add"
          variant="contained"
          color="primary"
        >
          Add Prime Category
        </Button>
      </Box>
    </Stack>
  );

  return (
    <Box>
      <EditorStepTitle prefix={2} title="Block Settings" />
      {attributeCategories}
      {primeCategoriesEl}
      <EditorSectionTitle subtitle="Blocks" />
      <Box width={'50%'}>
        <Stack spacing={1}>
          {blocks.map((block, index) => (
            <BlockSettings
              key={`block${index}`}
              name={block.name}
              testType={TestType.AMP}
              accumulationText={'Trials per Prime Category'}
              amountTrialsDivisibleBy={Object.values(primeCategories).length}
              index={index}
              removeBlock={() =>
                blockHelpers.setValue(blocks.filter((_, i) => i !== index))
              }
              variables={AmpVariables}
            />
          ))}
          <Button
            key="add-button"
            onClick={() => {
              const updatedBlocks: AmpBlockInput[] = [
                ...blocks,
                {
                  index: blocks.length,
                  name: '',
                  amountTrials: 10,
                  attributeVariant: CategoryVariant.DEFAULT,
                  instructions: {
                    desktop: '',
                  },
                  type:
                    blocks.length % 2 === 1
                      ? BlockType.NON_PRACTICE
                      : BlockType.PRACTICE,
                },
              ];
              blockHelpers.setValue(updatedBlocks);
            }}
            startIcon={<Add />}
            title="Add"
            variant="contained"
            color="primary"
          >
            Add Block
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default AmpBlockSettings;
