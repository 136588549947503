import {
  AdminChangeUserGroupsMutationVariables,
  FunctionBodyResponse,
  UserListElement,
} from '../../API';
import React, { FC, useState } from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { roles, UserRole } from '../../auth/user-role';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { Edit } from '@mui/icons-material';
import { GQLQuery, NewGQL } from '../../GQL';
import { adminChangeUserGroups } from '../../graphql/mutations';

async function updateUserGroupsMutation(userId: string, groups: UserRole[]) {
  return NewGQL.DEFAULT_CLIENT.execute(
    GQLQuery.Mutate<
      AdminChangeUserGroupsMutationVariables,
      FunctionBodyResponse
    >(adminChangeUserGroups).create({
      userId,
      groups,
    }),
  );
}

function useUpdateUserGroups() {
  const [loading, setLoading] = useState(false);
  const updateUserGroups = async (userId: string, groups: UserRole[]) => {
    setLoading(true);
    await updateUserGroupsMutation(userId, groups);
    setLoading(false);
  };
  return { updateUserGroups, loading };
}

type EditUserFormProps = {
  user: UserListElement;
  onClose: () => void;
  onSuccess: () => void;
};

const EditUserForm: FC<EditUserFormProps> = ({ user, onClose, onSuccess }) => {
  const [userToEdit, setUserToEdit] = useState<UserListElement>(user);
  const { updateUserGroups, loading } = useUpdateUserGroups();

  const handleCloseDialog = () => {
    setUserToEdit(user);
    onClose();
  };

  const handleUpdateUserGroups = async () => {
    await updateUserGroups(userToEdit.sub, userToEdit.groups as UserRole[]);
    onSuccess();
    onClose();
  };

  return (
    <>
      <DialogContent sx={{ overflow: 'visible' }}>
        <Stack spacing={2}>
          <Typography variant="h6" color="primary">
            {userToEdit.email}
          </Typography>
          <Autocomplete
            disableClearable
            value={userToEdit.groups[0]}
            onChange={(_, newValue) => {
              if (newValue) {
                setUserToEdit({
                  ...user,
                  groups: [newValue],
                });
              }
            }}
            renderInput={(params) => <TextField {...params} label="Role" />}
            options={roles}
          />
        </Stack>
      </DialogContent>
      {loading ? (
        <LinearProgress />
      ) : (
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            color="inherit"
          >
            Cancel
          </Button>
          <Button onClick={handleUpdateUserGroups} variant="contained">
            Save
          </Button>
        </DialogActions>
      )}
    </>
  );
};

type EditUserDialogProps = {
  user: UserListElement;
  onSuccess: () => void;
};

export const EditUserDialog: FC<EditUserDialogProps> = ({
  user,
  onSuccess,
}) => {
  const [open, setOpen] = useState(false);
  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  return (
    <>
      <GridActionsCellItem
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
        showInMenu={false}
        placeholder=""
        icon={<Edit />}
        label="Edit"
        onClick={openDialog}
      />
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Edit user</DialogTitle>
        <EditUserForm user={user} onClose={closeDialog} onSuccess={onSuccess} />
      </Dialog>
    </>
  );
};
