import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { API, Storage } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import {
  ListDocumentationFilesQuery,
  DocumentationFile,
  CreateDocumentationFileMutation,
  CreateDocumentationFileInput,
  UpdateDocumentationFileMutation,
  UpdateDocumentationFileInput,
  DeleteDocumentationFileMutation,
} from '../../API';
import { listDocumentationFiles } from '../../graphql/queries';
import {
  createDocumentationFile,
  deleteDocumentationFile,
  updateDocumentationFile,
} from '../../graphql/mutations';
import { v4 } from 'uuid';

export const filesApi = createApi({
  reducerPath: 'files',
  baseQuery: graphqlRequestBaseQuery({
    url: '/graphql',
  }),
  tagTypes: ['File'],
  endpoints: (builder) => ({
    listFiles: builder.query<DocumentationFile[], void>({
      queryFn: async () => {
        const result = await API.graphql<
          GraphQLQuery<ListDocumentationFilesQuery>
        >({
          query: listDocumentationFiles,
        });
        return {
          data: (result.data?.listDocumentationFiles?.items ??
            []) as DocumentationFile[],
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'File' as const,
                id,
              })),
              { type: 'File', id: 'LIST' },
            ]
          : [{ type: 'File', id: 'LIST' }],
    }),
    updateFile: builder.mutation<
      UpdateDocumentationFileMutation['updateDocumentationFile'],
      { dto: UpdateDocumentationFileInput; file?: File }
    >({
      queryFn: async (params) => {
        const input: UpdateDocumentationFileInput = { ...params.dto };
        if (params.file) {
          const s3Result = await Storage.put(
            `files/${v4()}.${params.file.type}`,
            params.file,
            {
              contentType: params.file.type,
            },
          );
          input.s3Key = s3Result.key;
        }
        const apiResult = await API.graphql<
          GraphQLQuery<UpdateDocumentationFileMutation>
        >({
          query: updateDocumentationFile,
          variables: { input: params.dto },
        });
        return {
          data: apiResult.data?.updateDocumentationFile,
        };
      },
      invalidatesTags: (result) =>
        result
          ? [
              {
                type: 'File' as const,
                id: result.id,
              },
              { type: 'File', id: 'LIST' },
            ]
          : [{ type: 'File', id: 'LIST' }],
    }),
    deleteFile: builder.mutation<
      DeleteDocumentationFileMutation['deleteDocumentationFile'],
      { id: string }
    >({
      queryFn: async (file: DocumentationFile) => {
        await Storage.remove(file.s3Key);

        const result = await API.graphql<
          GraphQLQuery<DeleteDocumentationFileMutation>
        >({
          query: deleteDocumentationFile,
          variables: {
            input: { id: file.id },
          },
        });
        return { data: result.data?.deleteDocumentationFile };
      },
      invalidatesTags: (result) =>
        result
          ? [
              {
                type: 'File' as const,
                id: result.id,
              },
              { type: 'File', id: 'LIST' },
            ]
          : [{ type: 'File', id: 'LIST' }],
    }),
    uploadFile: builder.mutation<
      CreateDocumentationFileMutation['createDocumentationFile'],
      { description: string; file: File }
    >({
      queryFn: async (dto) => {
        const s3Result = await Storage.put(`files/${v4()}`, dto.file, {
          contentType: dto.file.type,
        });

        const input: CreateDocumentationFileInput = {
          description: dto.description,
          name: dto.file.name,
          s3Key: s3Result.key,
        };

        const apiResult = await API.graphql<
          GraphQLQuery<CreateDocumentationFileMutation>
        >({
          query: createDocumentationFile,
          variables: { input },
        });
        return {
          data: apiResult.data?.createDocumentationFile,
        };
      },
      invalidatesTags: (result) =>
        result
          ? [
              {
                type: 'File' as const,
                id: result.id,
              },
              { type: 'File', id: 'LIST' },
            ]
          : [{ type: 'File', id: 'LIST' }],
    }),
  }),
});

export const {
  useListFilesQuery,
  useUploadFileMutation,
  useUpdateFileMutation,
  useDeleteFileMutation,
} = filesApi;
