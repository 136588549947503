import { WswStructure } from '../../loader/structure/wsw-structure';
import { WswSingleStimulus } from '../../loader/view-data/wsw-question-stimulus';
import { WswStimulusAttributeVariant } from '../../../../../API';
import {
  GroupIndicationLabel,
  WswCanvasContentView,
} from '../../loader/wsw-canvas-scaler';
import { TextMediaInstance } from '../../../../subject/testRunner/media/MediaData';
import React from 'react';
import { HTMLContentView } from '../../../../subject/testRunner/screens/composer/views/html-content-view';
import { Selector } from '../../../../subject/testRunner/screens/selectors/selector';

export function createWSWStimulusContentView(
  structure: WswStructure,
  selector: Selector<WswSingleStimulus>,
  stimuliVariant: WswStimulusAttributeVariant,
) {
  if (stimuliVariant === WswStimulusAttributeVariant.Text) {
    return HTMLContentView.growing(selector, ({ value }) => {
      return (
        <div style={{ position: 'relative' }}>
          {value.color && (
            <GroupIndicationLabel groupIndication={value.color} />
          )}
          <div
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
              padding: '8px',
              justifyContent: 'center',
              alignItems: 'center',
              border: value.color
                ? '12px solid ' + value.color.data.color
                : 'none',
              ...(value.instance as TextMediaInstance).textStyle.createStyle(),
            }}
          >
            {(value.instance as TextMediaInstance).data.text}
          </div>
        </div>
      );
    });
  }
  return WswCanvasContentView.growing(
    selector,
    structure.groupConstructionPhase !== null,
  );
}
