import { WswResult } from '../../../../API';
import { ResultTableColumn } from '../../IAT/runner/result-preview-table';

function attributeAccessor(
  result: WswResult,
  type: Exclude<
    keyof Exclude<WswResult['attributeCombination'], undefined | null>,
    'isLearningStimulus' | '__typename'
  >,
) {
  if (!result.attributeCombination || !result.attributeCombination[type]) {
    return '-';
  }
  const data = result.attributeCombination![type]!;
  return `${data.stimulusType}[${data.stimulusPoolCategory}][${data.stimulusPoolIndex}]`;
}

export const WswResultPreviewGroups: ResultTableColumn<WswResult>[] = [
  {
    name: 'group ind',
    accessor: (r) =>
      r.groupIndication
        ? r.groupIndication.groupIndicationCategory +
          '-' +
          r.groupIndication.groupIndicatorShow
        : '-',
  },
  {
    name: 'phase',
    accessor: (r) => r.phase + '',
  },
  {
    name: 'phase position',
    accessor: (r) => r.position + '',
  },
  {
    name: 'trial',
    accessor: (r) => r.trialIndex + '',
  },
  {
    name: 'type',
    accessor: (r) => r.questionType!,
  },
  {
    name: 'pool',
    accessor: (r) => r.questionPoolName!,
  },
  {
    name: 'question pos',
    accessor: (r) => r.questionPosition! + '',
  },
  {
    name: 'answer',
    accessor: (r) => r.questionAnswer!,
  },
  {
    name: 'answerOptionIdx',
    accessor: (r) =>
      r.answerOptionIndex !== null && r.answerOptionIndex !== undefined
        ? String(r.answerOptionIndex)
        : '-',
  },
  {
    name: 'noAnswer',
    accessor: (r) => (r.noAnswer ? 'yes' : 'no'),
  },
  {
    name: 'content type',
    accessor: (r) => r.contentType!,
  },
  {
    name: 'stimulus type',
    accessor: (r) => (r.resultStimulus ? r.resultStimulus.stimulusType! : '-'),
  },
  {
    name: 'is learning',
    accessor: (r) =>
      r.attributeCombination
        ? String(r.attributeCombination!.isLearningStimulus)
        : '-',
  },
  {
    name: 'main',
    accessor: (r) => attributeAccessor(r, 'mainStimulus'),
  },
  {
    name: 'additional',
    accessor: (r) => attributeAccessor(r, 'additionalStimulus'),
  },
  {
    name: 'criteria',
    accessor: (r) => attributeAccessor(r, 'criteriaStimulus'),
  },
  {
    name: 'trigger start',
    accessor: (r) => r.timing!.startTriggerDelay + 'ms',
  },
  {
    name: 'race start',
    accessor: (r) => (r.timing!.controlRaceDelay ?? '-') + 'ms',
  },
  {
    name: 'render start',
    accessor: (r) => (r.timing!.renderStartDelay ?? '-') + 'ms',
  },
  {
    name: 'render end',
    accessor: (r) => (r.timing!.renderEndDelay ?? '-') + 'ms',
  },
];
