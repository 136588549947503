import React, { FC } from 'react';
import { RegisterUserMutationVariables } from '../../API';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';

export const RegistrationSubmission: FC<{
  name: string;
  currentData: Partial<RegisterUserMutationVariables>;
  back?: (dataUpdate?: Partial<RegisterUserMutationVariables>) => void;
  next: (
    updateData: Partial<RegisterUserMutationVariables>,
    done: boolean,
  ) => void;
}> = ({ next, currentData, back }) => {
  const [confirmed, setConfirmed] = React.useState(
    currentData.confirmedUsagePolicy ?? false,
  );
  return (
    <Stack spacing={2}>
      <Typography variant="subtitle1">Complete registration</Typography>
      <Typography variant="body2">
        The following terms of use must be read and accepted before the
        application can be sent.
      </Typography>
      <Box
        sx={{
          margin: '1em',
          border: '1px solid black',
          padding: '1em',
          maxHeight: '40vh',
          overflow: 'auto',
          backdropFilter: 'brightness(1.5)',
        }}
      >
        <Typography variant="overline">Usage Policy</Typography>
        <Typography>
          Quisquis enim sentit, quem ad modum sit affectus, eum necesse est aut
          in voluptate esse aut in dolore. Omnis autem privatione doloris putat
          Epicurus terminari summam voluptatem, ut postea variari voluptas
          distinguique possit, augeri amplificarique non possit. At etiam
          Athenis, ut e patre audiebam facete et urbane Stoicos irridente,
          statua est in Ceramico Chrysippi sedentis porrecta manu, quae manus
          significet illum in hae esse rogatiuncula delectatum: 'Numquidnam
          manus tua sic affecta, quem ad modum affecta nunc est, desiderat?' -
          Nihil sane. - 'At, si voluptas esset bonum, desideraret.' - Ita credo.
          - 'Non est igitur voluptas bonum.' Hoc ne statuam quidem dicturam
          pater aiebat, si loqui posset. Conclusum est enim contra Cyrenaicos
          satis acute, nihil ad Epicurum. Nam si ea sola voluptas esset, quae
          quasi titillaret sensus, ut ita dicam, et ad eos cum suavitate
          afflueret et illaberetur, nec manus esse contenta posset nec ulla pars
          vacuitate doloris sine iucundo motu voluptatis. Sin autem summa
          voluptas est, ut Epicuro placet, nihil dolere, primum tibi recte,
          Chrysippe, concessum est nihil desiderare manum, cum ita esset
          affecta, secundum non recte, si voluptas esset bonum, fuisse
          desideraturam. Idcirco enim non desideraret, quia, quod dolore caret,
          id in voluptate est. Extremum autem esse bonorum voluptatem ex hoc
          facillime perspici potest: Constituamus aliquem magnis, multis,
          perpetuis fruentem et animo et corpore voluptatibus nullo dolore nec
          impediente nec inpendente, quem tandem hoc statu praestabiliorem aut
          magis expetendum possimus dicere? Inesse enim necesse est in eo, qui
          ita sit affectus, et firmitatem animi nec mortem nec dolorem timentis,
          quod mors sensu careat, dolor in longinquitate levis, in gravitate
          brevis soleat esse, ut eius magnitudinem celeritas, diuturnitatem
          allevatio consoletur. Ad ea cum accedit, ut neque divinum numen
          horreat nec praeteritas voluptates effluere patiatur earumque assidua
          recordatione laetetur, quid est, quod huc possit, quod melius sit,
          accedere? Statue contra aliquem confectum tantis animi corporisque
          doloribus, quanti in hominem maximi cadere possunt, nulla spe
          proposita fore levius aliquando, nulla praeterea neque praesenti nec
          expectata voluptate, quid eo miserius dici aut fingi potest? Quodsi
          vita doloribus referta maxime fugienda est, summum profecto malum est
          vivere cum dolore, cui sententiae consentaneum est ultimum esse
          bonorum eum voluptate vivere. Nec enim habet nostra mens quicquam, ubi
          consistat tamquam in extremo, omnesque et metus et aegritudines ad
          dolorem referuntur, nec praeterea est res ulla, quae sua natura aut
          sollicitare possit aut angere. Praeterea et appetendi et refugiendi et
          omnino rerum gerendarum initia proficiscuntur aut a voluptate aut a
          dolore. Quod cum ita sit, perspicuum est omnis rectas res atque
          laudabilis eo referri, ut cum voluptate vivatur. Quoniam autem id est
          vel summum bonorum vel ultimum vel extremum - quod Graeci telos
          nominant - quod ipsum nullam ad aliam rem, ad id autem res referuntur
          omnes, fatendum est summum esse bonum iucunde vivere. Id qui in una
          virtute ponunt et splendore nominis capti quid natura postulet non
          intellegunt, errore maximo, si Epicurum audire voluerint,
          liberabuntur: istae enim vestrae eximiae pulchraeque virtutes nisi
          voluptatem efficerent, quis eas aut laudabilis aut expetendas
          arbitraretur? Ut enim medicorum scientiam non ipsius artis, sed bonae
          valetudinis causa probamus, et gubernatoris ars, quia bene navigandi
          rationem habet, utilitate, non arte laudatur, sic sapientia, quae ars
          vivendi putanda est, non expeteretur, si nihil efficeret; nunc
          expetitur, quod est tamquam artifex conquirendae et comparandae
          voluptatis - Quam autem ego dicam voluptatem, iam videtis, ne invidia
          verbi labefactetur oratio mea -. Nam cum ignoratione rerum bonarum et
          malarum maxime hominum vita vexetur, ob eumque errorem et voluptatibus
          maximis saepe priventur et durissimis animi doloribus torqueantur,
          sapientia est adhibenda, quae et terroribus cupiditatibusque detractis
          et omnium falsarum opinionum temeritate derepta certissimam se nobis
          ducem praebeat ad voluptatem. Sapientia enim est una, quae maestitiam
          pellat ex animis, quae nos exhorrescere metu non sinat. Qua
          praeceptrice in tranquillitate vivi potest omnium cupiditatum ardore
          restincto. Cupiditates enim sunt insatiabiles, quae non modo singulos
          homines, sed universas familias evertunt, totam etiam labefactant
          saepe rem publicam. Ex cupiditatibus odia, discidia, discordiae,
          seditiones, bella nascuntur, nec eae se foris solum iactant nec tantum
          in alios caeco impetu incurrunt, sed intus etiam in animis inclusae
          inter se dissident atque discordant, ex quo vitam amarissimam necesse
          est effici, ut sapiens solum amputata circumcisaque inanitate omni et
          errore naturae finibus contentus sine aegritudine possit et sine metu
          vivere. Quae est enim aut utilior aut ad bene vivendum aptior partitio
          quam illa, qua est usus Epicurus? Qui unum genus posuit earum
          cupiditatum, quae essent et naturales et necessariae, alterum, quae
          naturales essent nec tamen necessariae, tertium, quae nec naturales
          nec necessariae. Quarum ea ratio est, ut necessariae nec opera multa
          nec impensa expleantur; ne naturales quidem multa desiderant,
          propterea quod ipsa natura divitias, quibus contenta sit, et parabilis
          et terminatas habet; inanium autem cupiditatum nec modus ullus nec
          finis inveniri potest. Quodsi vitam omnem perturbari videmus errore et
          inscientia, sapientiamque esse solam, quae nos a libidinum impetu et a
          formidinum terrore vindicet et ipsius fortunae modice ferre doceat
          iniurias et omnis monstret vias, quae ad quietem et ad tranquillitatem
          ferant, quid est cur dubitemus dicere et sapientiam propter voluptates
          expetendam et insipientiam propter molestias esse fugiendam? Eademque
          ratione ne temperantiam quidem propter se expetendam esse dicemus, sed
          quia pacem animis afferat et eos quasi concordia quadam placet ac
          leniat. Temperantia est enim, quae in rebus aut expetendis aut
          fugiendis ut rationem sequamur monet. Nec enim satis est iudicare quid
          faciendum non faciendumve sit, sed stare etiam oportet in eo, quod sit
          iudicatum. Plerique autem, quod tenere atque servare id, quod ipsi
          statuerunt, non possunt, victi et debilitati obiecta specie voluptatis
          tradunt se libidinibus constringendos nec quid eventurum sit provident
          ob eamque causam propter voluptatem et parvam et non necessariam et
          quae vel aliter pararetur et qua etiam carere possent sine dolore tum
          in morbos gravis, tum in damna, tum in dedecora incurrunt, saepe etiam
          legum iudiciorumque poenis obligantur. Qui autem ita frui volunt
          voluptatibus, ut nulli propter eas consequantur dolores, et qui suum
          iudicium retinent, ne voluptate victi faciant id, quod sentiant non
          esse faciendum, ii voluptatem maximam adipiscuntur praetermittenda
          voluptate. Idem etiam dolorem saepe perpetiuntur, ne, si id non
          faciant, incidant in maiorem. Ex quo intellegitur nec intemperantiam
          propter se esse fugiendam temperantiamque expetendam, non quia
          voluptates fugiat, sed quia maiores consequatur. Eadem fortitudinis
          ratio reperietur. Nam neque laborum perfunctio neque perpessio dolorum
          per se ipsa allicit nec patientia nec assiduitas nec vigiliae nec ea
          ipsa, quae laudatur, industria, ne fortitudo quidem, sed ista
          sequimur, ut sine cura metuque vivamus animumque et corpus, quantum
          efficere possimus, molestia liberemus. Ut enim mortis metu omnis
          quietae vitae status perturbatur, et ut succumbere doloribus eosque
          humili animo inbecilloque ferre miserum est, ob eamque debilitatem
          animi multi parentes, multi amicos, non nulli patriam, plerique autem
          se ipsos penitus perdiderunt, sic robustus animus et excelsus omni est
          liber cura et angore, cum et mortem contemnit, qua qui affecti sunt in
          eadem causa sunt, qua ante quam nati, et ad dolores ita paratus est,
          ut meminerit maximos morte finiri, parvos multa habere intervalla
          requietis, mediocrium nos esse dominos, ut, si tolerabiles sint,
          feramus, si minus, animo aequo e vita, cum ea non placeat, tamquam e
          theatro exeamus. Quibus rebus intellegitur nec timiditatem ignaviamque
          vituperari nec fortitudinem patientiamque laudari suo nomine, sed
          illas reici, quia dolorem pariant, has optari, quia voluptatem.
          Iustitia restat, ut de omni virtute sit dictum. Sed similia fere dici
          possunt. Ut enim sapientiam, temperantiam, fortitudinem copulatas esse
          docui cum voluptate, ut ab ea nullo modo nec divelli nec distrahi
          possint, sic de iustitia iudicandum est, quae non modo numquam nocet
          cuiquam, sed contra semper afficit cum vi sua atque natura, quod
          tranquillat animos, tum spe nihil earum rerum defuturum, quas natura
          non depravata desiderat. Quem ad modum temeritas et libido et ignavia
          semper animum excruciant et semper sollicitant turbulentaeque sunt,
          sic cuius in mente consedit, hoc ipso, quod adest, turbulenta est; si
          vero molita quippiam est, quamvis occulte fecerit, numquam tamen id
          confidet fore semper occultum. Plerumque improborum facta primo
          suspicio insequitur, dein sermo atque fama, tum accusator, tum iudex;
          Multi etiam, ut te consule, ipsi se indicaverunt. Quodsi qui satis
          sibi contra hominum conscientiam saepti esse et muniti videntur,
          deorum tamen horrent easque ipsas sollicitudines, quibus eorum animi
          noctesque diesque exeduntur, a diis inmortalibus supplicii causa
          importari putant. Quae autem tanta ex improbis factis ad minuendas
          vitae molestias accessio potest fieri, quanta ad augendas, cum
          conscientia factorum, tum poena legum odioque civium? Et tamen in
          quibusdam neque pecuniae modus est neque honoris neque imperii nec
          libidinum nec epularum nec reliquarum cupiditatum, quas nulla praeda
          umquam improbe parta minuit, potius inflammat, ut coercendi magis quam
          dedocendi esse videantur. Invitat igitur vera ratio bene sanos ad
          iustitiam, aequitatem, fidem, neque homini infanti aut inpotenti
          iniuste facta conducunt, qui nec facile efficere possit, quod conetur,
          nec optinere, si effecerit, et opes vel fortunae vel ingenii
          liberalitati magis conveniunt, qua qui utuntur, benivolentiam sibi
          conciliant et, quod aptissimum est ad quiete vivendum, caritatem,
          praesertim cum omnino nulla sit causa peccandi. Quae enim cupiditates
          a natura proficiscuntur, facile explentur sine ulla iniuria, quae
          autem inanes sunt, iis parendum non est. Nihil.
        </Typography>
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={confirmed}
            onChange={() => setConfirmed((c) => !c)}
          />
        }
        label={
          <Typography variant="inherit">
            I have read the terms of use and accept them
          </Typography>
        }
      />
      <Stack
        direction="row"
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          marginTop: '1.5rem',
        }}
      >
        <Button
          onClick={() => {
            back?.({ confirmedUsagePolicy: confirmed });
          }}
        >
          Back
        </Button>
        <Button
          disabled={!confirmed}
          onClick={() => {
            next({ confirmedUsagePolicy: confirmed }, true);
          }}
        >
          Submit
        </Button>
      </Stack>
    </Stack>
  );
};
