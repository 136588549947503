import { WswTestQuestionInstance } from '../model/questions/wsw-test-question-instance';
import { WswConstructedGroup } from '../structure/wsw-structure';
import { ControlTransition } from '../../../../subject/testRunner/controls/control-transition';
import {
  DrawableMediaInstance,
  TextMediaInstance,
} from '../../../../subject/testRunner/media/MediaData';
import { ColorMediaInstance } from '../model/color-media-data';
import { WswScreenData } from './wsw-screen-data';

export class WswQuestionStimulus {}

export class WswEmptyStimulus extends WswQuestionStimulus {}

export type WswStimulusScope =
  | 'presentation'
  | 'distraction'
  | 'group-construction';

export class WswSingleStimulus extends WswQuestionStimulus {
  constructor(
    public readonly instance: DrawableMediaInstance | TextMediaInstance,
    public readonly color?: ColorMediaInstance,
    public readonly scope: WswStimulusScope = 'presentation',
  ) {
    super();
  }
}

export class WswDoubleStimulus extends WswQuestionStimulus {
  constructor(
    public readonly primaryStimulus: WswSingleStimulus,
    public readonly secondaryStimulus: WswSingleStimulus,
  ) {
    super();
  }
}

export class WswQuestionData extends WswScreenData {
  constructor(
    public readonly questionStimulus:
      | WswSingleStimulus
      | WswDoubleStimulus
      | WswEmptyStimulus,
    public readonly question: WswTestQuestionInstance | null,
    probandGroup = null as WswConstructedGroup | null,
    controlTransitions: ControlTransition<any, any>[] = [],
  ) {
    super(probandGroup, controlTransitions);
  }
}
