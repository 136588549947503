export default class GqlError extends Error {
  public error: any;

  public query: string;

  public variables: object;

  constructor(message: string, query: string, variables: object, error?: any) {
    super(message);
    this.stack = JSON.stringify(error);
    this.error = error;
    this.query = query;
    this.variables = variables;
  }
}
