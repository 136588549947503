import { GridFilterPanel, useGridApiContext } from '@mui/x-data-grid-pro';
import { Box, Button } from '@mui/material';
import React, { FC } from 'react';
import SaveIcon from '@mui/icons-material/Save';

export const CustomFilterPanel: FC = () => {
  const apiRef = useGridApiContext();

  return (
    <Box>
      <GridFilterPanel />
      <Button
        onClick={() => apiRef.current.hideFilterPanel()}
        sx={{
          position: 'absolute',
          bottom: 10,
          right: 10,
        }}
      >
        <SaveIcon />
      </Button>
    </Box>
  );
};
