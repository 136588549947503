import * as React from 'react';
import { FC } from 'react';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';

const dsgvoRows: GridRowsProp = [
  {
    id: 1,
    col1: 'ua',
    col2: 'Gerätehersteller inkl. Gerätetyp',
    col3: 'Gewährleistung einer responsiven, reibungslosen Darstellung der Experimente sowie Auswertungszwecke',
  },
  {
    id: 2,
    col1: 'BrowserInfo',
    col2: 'Browsertyp inkl. Versionsnummer',
    col3: 'Gewährleistung einer responsiven, reibungslosen Darstellung der Experimente sowie Auswertungszwecke',
  },
  {
    id: 3,
    col1: 'DeviceInfo',
    col2: 'Gerätehersteller inkl. Gerätetyp',
    col3: 'Gewährleistung einer responsiven, reibungslosen Darstellung der Experimente sowie Auswertungszwecke',
  },
  {
    id: 4,
    col1: 'EngineInfo',
    col2: 'Browserengine inkl. Versionsnummer',
    col3: 'Gewährleistung einer responsiven, reibungslosen Darstellung der Experimente sowie Auswertungszwecke',
  },
  {
    id: 5,
    col1: 'OsInfo',
    col2: 'Betriebssystemtyp inkl. Versionsnummer',
    col3: 'Gewährleistung einer responsiven, reibungslosen Darstellung der Experimente sowie Auswertungszwecke',
  },
  {
    id: 6,
    col1: 'CpuInfo',
    col2: 'Prozessorhersteller inkl. Modellbezeichnung',
    col3: 'Einschätzung der Performance (Auswertung der Timings) und zur Fehleranalyse',
  },
  {
    id: 7,
    col1: 'timing',
    col2: 'Datum und Uhrzeit des Zugriffs',
    col3: 'Administrative und Auswertungszwecke',
  },
  {
    id: 8,
    col1: 'key',
    col2: 'Gedrückte Tasten auf Computertastatur',
    col3: 'Registrierung der Reaktion der Probanden',
  },
];

const dsgvoColumns: GridColDef[] = [
  { field: 'id', hide: true },
  { field: 'col1', headerName: 'Datentyp', flex: 1 },
  { field: 'col2', headerName: 'Bedeutung', flex: 1 },
  { field: 'col3', headerName: 'Zweck der Verarbeitung', flex: 2 },
];

export const DsgvoTable: FC<{
  columns?: GridColDef[];
  rows?: GridRowsProp;
}> = ({ columns = [], rows = [] }) => (
  <Box height={530} mt={1}>
    <DataGridPro
      columns={[...dsgvoColumns, ...columns]}
      rows={[...dsgvoRows, ...rows]}
    />
  </Box>
);
