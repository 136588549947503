import { Subject } from 'rxjs';
import { TestFlowSnapshot } from '../../graph-state/test-director';
import React from 'react';
import { TreeSequence } from '../../graph/tree-sequence/tree-sequence';
import { NodePathEntry } from '../../graph/tree-sequence/node-path-entry';
import { ScreenTreeNode } from '../../graph/nodes/screen-tree-node';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { AccountTree, ListAlt } from '@mui/icons-material';
import { NestedTreeSequenceNavigator } from './tree/nested-tree-navigator';

function TreeSequenceItem({
  screenNode,
  active,
  activate,
}: {
  screenNode: ScreenTreeNode;
  active: boolean;
  activate: (node: ScreenTreeNode) => void;
}) {
  return (
    <div>
      <span
        style={{ fontWeight: active ? 'bold' : undefined }}
        onClick={() => {
          activate(screenNode);
        }}
      >
        {screenNode.pathName}
      </span>
      <br />
    </div>
  );
}

export function TreeSequenceNavigatorContainer({
  treeSequence,
  stateChangeSubject,
  subject,
}: {
  treeSequence: TreeSequence;
  stateChangeSubject: Subject<NodePathEntry>;
  subject: Subject<TestFlowSnapshot>;
}) {
  const [view, setView] = React.useState('sequence' as 'nested' | 'sequence');
  const [currentNode, setCurrentNode] = React.useState<TestFlowSnapshot>();
  React.useEffect(() => {
    const subs = subject.subscribe({
      next: (value) => setCurrentNode(value),
    });
    return () => subs.unsubscribe();
  }, [subject]);
  const activate = React.useCallback(
    (node: ScreenTreeNode) => {
      stateChangeSubject.next(treeSequence.nodeMap.get(node)!);
    },
    [stateChangeSubject, treeSequence.nodeMap],
  );

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <ToggleButtonGroup value={view}>
          <ToggleButton
            size="small"
            value="sequence"
            onClick={() => setView('sequence')}
          >
            <ListAlt fontSize="small" />
          </ToggleButton>
          <ToggleButton
            size="small"
            value="nested"
            onClick={() => setView('nested')}
          >
            <AccountTree fontSize="small" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {view === 'sequence' && (
        <TreeSequenceNavigator
          currentNode={currentNode}
          activate={activate}
          treeSequence={treeSequence}
        />
      )}
      {view === 'nested' && (
        <NestedTreeSequenceNavigator
          treeSequence={treeSequence}
          currentNode={currentNode}
          activate={activate}
        />
      )}
    </>
  );
}

export function TreeSequenceNavigator({
  treeSequence,
  activate,
  currentNode,
}: {
  treeSequence: TreeSequence;
  activate: (node: ScreenTreeNode) => void;
  currentNode: TestFlowSnapshot | undefined;
}) {
  const sortedTreeSequence = React.useMemo(() => {
    return Array.from(treeSequence.nodeMap.keys());
  }, [treeSequence]);

  return (
    <div>
      <TreeSequenceNavigatorHint />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'auto',
        }}
      >
        {sortedTreeSequence.map((k) => (
          <TreeSequenceItem
            screenNode={k}
            active={k === currentNode?.screenNode?.node}
            activate={activate}
          />
        ))}
      </div>
    </div>
  );
}

function TreeSequenceNavigatorHint() {
  return (
    <div
      style={{
        fontStyle: 'italic',
        borderColor: 'lightgrey',
        borderBottomStyle: 'solid',
        backgroundColor: '#f5f5f5',
      }}
    >
      Items in this list represent the internal state of the app and do not show
      the order in which the test windows are run.
    </div>
  );
}
