import { WSWSelectorTree } from '../wsw-selectors';
import { StimulusCanvasStrategy } from '../../../../subject/testRunner/screens/composer/scaler/canvas-strategy';
import { WswStructure } from '../../loader/structure/wsw-structure';
import {
  WSWAttributeType,
  WswStimulusAttributeVariant,
} from '../../../../../API';
import { ViewBoxes } from '../../../../subject/testRunner/screens/layouting-view';
import React from 'react';
import { createWSWStimulusContentView } from './wsw-stimulus-content-view';
import { ViewDefinitionCollection } from '../../../../subject/testRunner/screens/composer/view-definition-collection';
import { CanvasContentView } from '../../../../subject/testRunner/screens/composer/views/canvas-content-view';
import { LayoutingView } from '../../../../subject/testRunner/screens/composer/views/layouting-view';

export function createWswQuestionStimulusView(structure: WswStructure) {
  return LayoutingView.growing({
    selector: WSWSelectorTree.rootSelector.phaseQuestions.stimulus,
    layout: {
      subComponents: ViewDefinitionCollection.Create({
        distraction: createWSWScopeView('distraction'),
        groupConstruction: createWSWScopeView('group-construction'),
        single: createWSWSingleView(structure),
        double: createWSWDoubleView(structure),
      }),
      boxes: ViewBoxes.Define((builder) => ({
        content: builder.growing(
          'distraction',
          'groupConstruction',
          'single',
          'double',
        ),
      })),
    },
    contentComponent: (containerProvider, boxProvider) => {
      return () => {
        return containerProvider.createContainer(
          <>{boxProvider.createBox('content')}</>,
        );
      };
    },
  });
}

function createWSWScopeView(scope: 'group-construction' | 'distraction') {
  return CanvasContentView.growing(
    WSWSelectorTree.rootSelector.phaseQuestions.stimulus[scope],
    new StimulusCanvasStrategy(),
  );
}

function createWSWSingleView(structure: WswStructure) {
  return LayoutingView.growing({
    selector: WSWSelectorTree.rootSelector.phaseQuestions.stimulus.presentation,
    layout: {
      subComponents: ViewDefinitionCollection.Create({
        content: createWSWStimulusContentView(
          structure,
          WSWSelectorTree.rootSelector.phaseQuestions.stimulus.presentation,
          structure.accessor.modelData.mainAttribute.stimuliVariant!,
        ),
      }),
      boxes: ViewBoxes.Define((builder) => ({
        content: builder.growing('content'),
      })),
    },
    contentComponent: (containerProvider, boxProvider) => {
      return () => {
        return containerProvider.createContainer(
          <div style={{ width: '50%', height: '100%' }}>
            {boxProvider.createBox('content')}
          </div>,
          (_, style) => ({
            ...style,
            display: 'flex',
            justifyContent: 'center',
          }),
        );
      };
    },
  });
}

function createWSWDoubleView(structure: WswStructure) {
  const testData = structure.accessor.modelData;
  const criteriaAttribute = testData.criteriaAttribute;
  const additionalAttribute = testData.additionalAttribute;
  const secondaryStimulusType =
    criteriaAttribute.type === WSWAttributeType.STIMULI
      ? criteriaAttribute.stimuliVariant!
      : additionalAttribute?.type === WSWAttributeType.STIMULI
      ? additionalAttribute.stimuliVariant
      : WswStimulusAttributeVariant.Image;
  return LayoutingView.growing({
    selector: WSWSelectorTree.rootSelector.phaseQuestions.stimulus.double,
    layout: {
      subComponents: ViewDefinitionCollection.Create({
        primary: createWSWStimulusContentView(
          structure,
          WSWSelectorTree.rootSelector.phaseQuestions.stimulus.double.primary,
          testData.mainAttribute.stimuliVariant!,
        ),
        secondary: createWSWStimulusContentView(
          structure,
          WSWSelectorTree.rootSelector.phaseQuestions.stimulus.double.secondary,
          secondaryStimulusType!,
        ),
      }),
      boxes: ViewBoxes.Define((builder) => ({
        primary: builder.growing('primary'),
        secondary: builder.growing('secondary'),
      })),
    },
    contentComponent: (containerProvider, boxProvider) => {
      return () => {
        return containerProvider.createContainer(
          <>
            <div style={{ width: '50%', height: '100%' }}>
              {boxProvider.createBox('primary')}{' '}
            </div>
            <div style={{ width: '50%', height: '100%' }}>
              {boxProvider.createBox('secondary')}
            </div>
          </>,
          (_, style) => ({
            ...style,
            display: 'flex',
            justifyContent: 'center',
          }),
        );
      };
    },
  });
}
