import { IatStructure } from '../structure/iat-structure';
import { ControlOccurrenceProcessor } from '../../../../subject/testRunner/graph-state/graph-action';
import { IatResultQueries } from '../loading/iat.queries';
import { v4 as uuid } from 'uuid';
import { omit } from 'lodash';
import { IatCategoryType } from '../../../../../API';
import { IatTrialPhaseDescriptor } from './iat-trial-tree';

export function buildCreateResultQuery(
  iatStructure: IatStructure,
  trialDescriptor: IatTrialPhaseDescriptor,
) {
  const { blockIndex, block, trialIndex, trial } = trialDescriptor;
  return (
    control: ControlOccurrenceProcessor<any>,
    side: 'left' | 'right',
    action: 'correct' | 'incorrect',
  ) => {
    return IatResultQueries.Create.create({
      input: {
        clientId: uuid(),
        device: omit(iatStructure.accessor.testContext.deviceInfo, 'mode'),
        testId: iatStructure.accessor.modelData.id,
        status: iatStructure.accessor.modelData.status,
        survey: iatStructure.accessor.testContext.logic.resultEntry,
        timing: control.reactionTimeProcessor.toResultTiming(),
        blockIndex,
        blockName: block.blockDescriptor.blockName,
        blockType: block.blockDescriptor.blockType,
        ...control.occurrence.toResultData(),
        trialIndex: trialIndex,
        actionResultName: action,
        actionTarget:
          block.blockDescriptor.options.target?.[
            side
          ]?.mediaInstance?.data?.toMediaItemSnapshot() ?? null,
        actionAttribute:
          block.blockDescriptor.options.attribute?.[
            side
          ]?.mediaInstance?.data?.toMediaItemSnapshot() ?? null,
        counterBalancingGroup: iatStructure.options.arranger.cbGroup,
        stimulus: {
          categoryLabel:
            iatStructure.options.categories[trial.metaData.category][
              trial.metaData.variant
            ].mediaInstance.data.toMediaItemSnapshot(),
          stimulus: trial.value.data.toMediaItemSnapshot(),
          categoryType:
            trial.metaData.category === 'attribute'
              ? IatCategoryType.ATTRIBUTE
              : IatCategoryType.TARGET,
        },
        attributeLeft:
          block.blockDescriptor.options.attribute?.left?.mediaInstance?.data?.toMediaItemSnapshot(),
        attributeRight:
          block.blockDescriptor.options.attribute?.right?.mediaInstance?.data?.toMediaItemSnapshot(),
        targetLeft:
          block.blockDescriptor.options.target?.left?.mediaInstance?.data?.toMediaItemSnapshot(),
        targetRight:
          block.blockDescriptor.options.target?.right?.mediaInstance?.data?.toMediaItemSnapshot(),
      },
    });
  };
}
