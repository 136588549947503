import { TranslationSource } from './translation-store';
import {
  TranslationKeyValueTypeMap,
  translationValueTypeHandlerMap,
} from './translation-value';
import { StaticTranslationKey, TranslationModifier } from './translation-key';

export const defaultLanguage = 'en';

export interface AppTranslator {
  readonly languages: string[];
  readonly translationSource: TranslationSource;
  readonly translate: <V extends keyof TranslationKeyValueTypeMap>(
    key: StaticTranslationKey<V>,
    languageModifier?: TranslationModifier,
  ) => Record<string, TranslationKeyValueTypeMap[V]>;
}

export class DefaultAppTranslator implements AppTranslator {
  constructor(
    public readonly languages: string[],
    public readonly translationSource: TranslationSource = () => undefined,
    public readonly defaultModifier:
      | TranslationModifier
      | undefined = undefined,
    public readonly missingTranslationHandler: (
      key: string,
    ) => string[] | string | undefined = () => undefined,
  ) {}

  protected languageCode(
    lang: string,
    modifier: TranslationModifier | undefined = this.defaultModifier,
  ) {
    return modifier ? `${lang}:${modifier}` : lang;
  }
  protected languageCodes(
    modifier: TranslationModifier | undefined = this.defaultModifier,
  ) {
    return this.languages.map(
      (lang) => [lang, modifier ? `${lang}:${modifier}` : lang] as const,
    );
  }
  translate<V extends keyof TranslationKeyValueTypeMap>(
    key: StaticTranslationKey<V>,
    languageModifier?: TranslationModifier,
  ) {
    const translations = Object.fromEntries(
      this.languageCodes(languageModifier)
        .map(([lang, code]) => [
          lang,
          key.translate(code, this.translationSource),
        ])
        .filter(([, value]) => value !== undefined),
    ) as Record<string, TranslationKeyValueTypeMap[V]>;
    if (Object.keys(translations).length === 0) {
      return {
        [defaultLanguage]:
          key.translate(
            this.languageCode(defaultLanguage),
            this.translationSource,
          ) ??
          translationValueTypeHandlerMap[key.type](
            this.missingTranslationHandler(key.key),
          ),
      } as Record<string, TranslationKeyValueTypeMap[V]>;
    }
    return translations;
  }
}
