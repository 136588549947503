import React, { FC } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { useField } from 'formik';
import { ScaleQuestionInput, TestQuestionInput } from '../../../../../API';
import { ConditionalQuestion } from './ConditionalQuestion';
import * as Yup from 'yup';
import { FormikCheckboxField } from '../../../../../components/input/FormikCheckboxField';
import { QuestionListEditor } from './QuestionListEditor';

const questionListElementSchema = Yup.object()
  .nullable()
  .shape({
    stimulusTimeout: Yup.mixed()
      .test('is correct', 'is correct', function (val) {
        const question = this.parent;

        if (question.mediaItemSnapshot && typeof val === 'number' && val < 0) {
          return this.createError({
            path: this.path,
            message: 'At least 0',
          });
        }
        return true;
      })
      .required('required'),
    questionText: Yup.string().required('required'),
    multiple: Yup.object()
      .notRequired()
      .nullable()
      .shape({
        options: Yup.array()
          .min(1, 'must have at least one option')
          .of(Yup.string().required('Required')),
      }),
    imageMultipleChoice: Yup.object()
      .notRequired()
      .nullable()
      .shape({
        options: Yup.array()
          .min(1, 'must have at least one option')
          .of(Yup.object().required()),
      }),
    scale: Yup.object()
      .notRequired()
      .nullable()
      .shape({
        min: Yup.object()
          .required()
          .shape({
            name: Yup.string().required('Required'),
            value: Yup.number().required().min(1, 'min 1'),
          }),
        max: Yup.object()
          .required()
          .shape({
            name: Yup.string().required('Required'),
            value: Yup.number().required(),
          }),
      })
      .test('max', 'max must be greater then min', function (value) {
        const scale = value as ScaleQuestionInput;
        if (scale) {
          if (scale.max.value <= scale.min.value) {
            return this.createError({
              path: `${this.path}.max.value`,
              message: 'Must be more than min.',
            });
          }
          if (scale.max.value > scale.min.value + 10) {
            return this.createError({
              path: `${this.path}.max.value`,
              message: 'To high.',
            });
          }
        }
        return true;
      }),
  });

export const ConditionalQuestionSetSchema = Yup.object()
  .notRequired()
  .nullable()
  .default(null)
  .shape({
    questionsBefore: Yup.mixed().when(
      'conditionalQuestion',
      ([conditionalQuestion]) => {
        if (conditionalQuestion === null) {
          return Yup.array()
            .min(1, 'Add at least one Question')
            .of(questionListElementSchema);
        } else {
          return Yup.array().of(questionListElementSchema);
        }
      },
    ),
    conditionalQuestion: Yup.object()
      .notRequired()
      .nullable()
      .default(null)
      .shape({
        questionText: Yup.string().required('required'),
        confirmText: Yup.string().required('required'),
        declineText: Yup.string().required('required'),
      }),
    questionsAfter: Yup.mixed().when(
      'conditionalQuestion',
      ([conditionalQuestion]) => {
        if (conditionalQuestion !== null) {
          return Yup.array()
            .min(1, 'Add at least one "Post Question"')
            .of(questionListElementSchema);
        } else {
          return Yup.array().of(questionListElementSchema);
        }
      },
    ),
  });

interface ConditionalQuestionSetProps {
  fieldName: string;
  label: string;
  withImageInQuestions: boolean;
  withImageMCQuestions?: boolean;
  withConditionalQuestion: boolean;
  withQuestionTimeout: boolean;
}

export const ConditionalQuestionSet: FC<ConditionalQuestionSetProps> = ({
  fieldName,
  label,
  withConditionalQuestion,
  withImageInQuestions,
  withQuestionTimeout,
  withImageMCQuestions = false,
}) => {
  const [, { error }] = useField(fieldName);
  const [, { value: conditionalQuestion }] = useField<
    TestQuestionInput[] | null | undefined
  >(`${fieldName}.conditionalQuestion`);
  return (
    <Stack sx={{ pl: 1 }} spacing={2}>
      <Typography
        variant="h6"
        color="textSecondary"
        sx={{ textDecoration: 'underline' }}
      >
        {label}&nbsp;
        <FormikCheckboxField
          name={`${fieldName}.randomizeSequence`}
          label={'Randomize Questions Sequence'}
          size="small"
          sx={{ ml: 2 }}
        />
      </Typography>
      {typeof error === 'string' && (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      )}
      <QuestionListEditor
        fieldName={fieldName}
        withQuestionTimeout={withQuestionTimeout}
        questionsField={'questionsBefore'}
        withImageInQuestions={withImageInQuestions && !withImageMCQuestions}
        withImageMCQuestions={withImageMCQuestions}
        title={
          <Typography sx={{ pl: 1, mb: 0.5 }} color="textSecondary">
            {conditionalQuestion ? 'Pre conditinal questions' : 'Questions'}
          </Typography>
        }
        addQuestionText={
          conditionalQuestion ? 'Add pre conditinal question' : 'Add question'
        }
      />
      <Divider />
      {withConditionalQuestion && (
        <>
          <ConditionalQuestion fieldName={`${fieldName}.conditionalQuestion`} />
          <Divider />
          {!!conditionalQuestion && (
            <QuestionListEditor
              fieldName={fieldName}
              withQuestionTimeout={withQuestionTimeout}
              questionsField={'questionsAfter'}
              withImageInQuestions={
                withImageInQuestions && !withImageMCQuestions
              }
              withImageMCQuestions={withImageMCQuestions}
              title={
                <Typography sx={{ pl: 1 }} color="textSecondary">
                  Post conditinal questions
                </Typography>
              }
              addQuestionText="Add post conditinal question"
            />
          )}
        </>
      )}
    </Stack>
  );
};
