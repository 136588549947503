import { useRunnerExecutionContext } from '../../../../../runner-execution-context';
import React from 'react';
import { TranslationKeyValueTypeMap } from './translation-value';
import { StaticTranslationKey } from './translation-key';
import { TranslationScopeMap } from './translation-scope';
import { omit } from 'lodash';

export type StaticTranslator = {
  translate: <V extends keyof TranslationKeyValueTypeMap>(
    key: StaticTranslationKey<V>,
  ) => TranslationKeyValueTypeMap[V][];
  translateWithLangInfo: <V extends keyof TranslationKeyValueTypeMap>(
    key: StaticTranslationKey<V>,
  ) => [translation: TranslationKeyValueTypeMap[V], lang: string][];
  mapTranslation: <V extends keyof TranslationKeyValueTypeMap, O>(
    key: StaticTranslationKey<V>,
    mapper: (translation: TranslationKeyValueTypeMap[V], lang: string) => O,
  ) => O[];
  translatePrimary: <V extends keyof TranslationKeyValueTypeMap>(
    key: StaticTranslationKey<V>,
  ) => TranslationKeyValueTypeMap[V];
};
export function useStaticTranslator(): StaticTranslator {
  const {
    appConfiguration: { translator, settings },
  } = useRunnerExecutionContext();
  return React.useMemo(
    () => ({
      translate: <V extends keyof TranslationKeyValueTypeMap>(
        key: StaticTranslationKey<V>,
      ): TranslationKeyValueTypeMap[V][] => {
        return Object.values(translator.translate(key));
      },
      translateWithLangInfo: <V extends keyof TranslationKeyValueTypeMap>(
        key: StaticTranslationKey<V>,
      ): [translation: TranslationKeyValueTypeMap[V], lang: string][] => {
        return Object.entries(translator.translate(key)).map(
          ([lang, value]) => [value, lang],
        );
      },
      mapTranslation: <V extends keyof TranslationKeyValueTypeMap, O>(
        key: StaticTranslationKey<V>,
        mapper: (translation: TranslationKeyValueTypeMap[V], lang: string) => O,
      ) => {
        return Object.entries(translator.translate(key)).map(([lang, value]) =>
          mapper(value, lang),
        );
      },
      translatePrimary: <V extends keyof TranslationKeyValueTypeMap>(
        key: StaticTranslationKey<V>,
      ): TranslationKeyValueTypeMap[V] => {
        const translationMap = translator.translate(key);
        return (
          translationMap[settings.languages[0]] ??
          translationMap[Object.keys(translationMap)[0]]
        );
      },
    }),
    [settings.languages, translator],
  );
}

export type ScopedKeyTranslator<V extends keyof TranslationKeyValueTypeMap> = {
  translate: () => TranslationKeyValueTypeMap[V][];
  translateWithLangInfo: () => [
    translation: TranslationKeyValueTypeMap[V],
    lang: string,
  ][];
  mapTranslation: <O>(
    mapper: (translation: TranslationKeyValueTypeMap[V], lang: string) => O,
  ) => O[];
  translatePrimary: () => TranslationKeyValueTypeMap[V];
};

export type ScopeTranslator<M extends TranslationScopeMap<any>> = {
  [K in keyof M]: M[K] extends TranslationScopeMap<infer SM>
    ? ScopeTranslator<TranslationScopeMap<SM>>
    : M[K] extends StaticTranslationKey<infer V>
      ? ScopedKeyTranslator<V>
      : never;
};

export function useRunnerAppTranslator<M extends TranslationScopeMap<any>>(
  scope: M,
) {
  const translator = useStaticTranslator();
  return React.useMemo(() => {
    function scopedKeyTranslator<V extends keyof TranslationKeyValueTypeMap>(
      key: StaticTranslationKey<V>,
    ): ScopedKeyTranslator<V> {
      return {
        translate: () => translator.translate(key),
        translateWithLangInfo: () => translator.translateWithLangInfo(key),
        mapTranslation: <O>(
          mapper: (val: TranslationKeyValueTypeMap[V], lang: string) => O,
        ) => translator.mapTranslation(key, mapper),
        translatePrimary: () => translator.translatePrimary(key),
      };
    }
    function scopedTranslator<M extends TranslationScopeMap<any>>(
      scope: M,
    ): ScopeTranslator<M> {
      return Object.fromEntries(
        Object.entries(omit(scope, 'scope')).map(([key, value]) => {
          return [
            key,
            value instanceof StaticTranslationKey
              ? scopedKeyTranslator(value)
              : scopedTranslator(value),
          ];
        }),
      ) as ScopeTranslator<M>;
    }
    return scopedTranslator(scope) as ScopeTranslator<M>;
  }, [scope, translator]);
}
