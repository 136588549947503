import {
  CombinationStimulusItemInput,
  ConditionalQuestionSet,
  PickingStrategy,
  StimulusType,
  WSWAttribute,
  WswAttributeCombinationResultInput,
  WSWAttributeType,
  WSWGroup,
} from '../../../../../API';
import { Wsw } from '../../types';
import { MappingPickingSet } from '../../../pickingSet/CombinedPickingSet/MappingPickingSet';
import { createPickingSet } from '../../../pickingSet/picking-strategy';
import { MindSetRandomUtils } from '../../../pickingSet/picking-utils';
import { weightedSet } from '../../../pickingSet/weightedSet/WeightedSet';
import { NonRandomPickingSet } from '../../../pickingSet/nonRandomPickingSet/NonRandomPickingSet';
import {
  WswQuestionSetFactory,
  WswQuestionSetInstance,
} from '../model/questions/question-set-factory';
import { TestResourceAccessor } from '../../../../subject/testRunner/media/test-resource-loader';
import {
  DrawableMediaInstance,
  ImageMediaData,
  TextMediaData,
  TextMediaInstance,
} from '../../../../subject/testRunner/media/MediaData';
import { pickWSWUniverseNew } from '../../creator/wsw-picker';
import { ColorMediaInstance } from '../model/color-media-data';
import { WswMediaPool } from '../loading/load-wsw-media';
import {
  InstructionScreenContent,
  TestInstructionsPhase,
} from '../../../../subject/testRunner/utils/structure-utils';
import { chooseTemplate, toAttributeResultType } from './utils';

export interface WswConstructedGroup {
  group: WSWGroup;
  index: number;
  color: string;
}

export type WswGroupCondition =
  | { type: 'never' }
  | { type: 'before-learning' | 'after-learning'; group: WswConstructedGroup };

export interface WswGlobalConfig {
  groups: null | {
    groups: WswConstructedGroup[];
    groupCondition: WswGroupCondition;
  };
}

export function createWSWGlobalConfig(
  wsw: Pick<Wsw, 'groupConstruction'>,
): WswGlobalConfig {
  if (wsw.groupConstruction) {
    const colorPicker = new MappingPickingSet(
      wsw.groupConstruction.randomizeGroupColors
        ? createPickingSet(
            'group-colors',
            PickingStrategy.PERMUTATION,
            wsw.groupConstruction.groups,
          )
        : new NonRandomPickingSet(wsw.groupConstruction.groups),
      (group) => group.color,
    );
    const groups: WswConstructedGroup[] = wsw.groupConstruction.groups.map(
      (group, index) => ({
        group: group as WSWGroup,
        index,
        color: colorPicker.pick(),
      }),
    );
    const groupConditionKey = MindSetRandomUtils.choseFromDistribution({
      never: wsw.groupConstruction.conditions.condition1,
      'before-learning': wsw.groupConstruction.conditions.condition2,
      'after-learning': wsw.groupConstruction.conditions.condition3,
    });

    return {
      groups: {
        groups,
        groupCondition:
          groupConditionKey === 'never'
            ? { type: groupConditionKey }
            : {
                type: groupConditionKey,
                group: MindSetRandomUtils.chooseArrayItem(
                  weightedSet(groups.map((g) => [g, g.group.probability!])),
                ),
              },
      },
    };
  }
  return {
    groups: null,
  };
}

export interface WswBasePhase {
  startInstructions: InstructionScreenContent | null;
  endInstructions: InstructionScreenContent | null;
}

export interface WswAttributeCombination {
  primary: TextMediaInstance | DrawableMediaInstance;
  secondary: TextMediaInstance | DrawableMediaInstance | null;
  color: ColorMediaInstance | null;
  resultEntry: WswAttributeCombinationResultInput;
}

export interface WswAttributeCombinationTrial {
  combination: WswAttributeCombination;
  questionSet: WswQuestionSetInstance;
}

export interface AttributeCombinationType {
  secondary: boolean;
  color: boolean;
}

export interface WswDistractionPhase extends WswBasePhase {
  questionSet: WswQuestionSetInstance;
}

export interface WswGroupConstructionPhase extends WswBasePhase {
  position: 'before-learning' | 'after-learning';
  fakeQuestionSet: WswQuestionSetInstance;
  resultingGroup: WswConstructedGroup;
  manipulationCheckQuestionSet: WswQuestionSetInstance;
}
export interface WswLearningPhase extends WswBasePhase {
  combinationType: AttributeCombinationType;
  trials: WswAttributeCombinationTrial[];
}

export type WswTestPresentationPhase = WswLearningPhase;

export interface WswStructure {
  accessor: TestResourceAccessor<Wsw>;
  config: WswGlobalConfig;
  pool: WswMediaPool;
  welcomePhase: TestInstructionsPhase;
  distractionPhase: WswDistractionPhase | null;
  groupConstructionPhase: WswGroupConstructionPhase | null;
  learningPhase: WswLearningPhase;
  testPhase: WswTestPresentationPhase;
  endPhase: TestInstructionsPhase;
}

export function createWswStructure(
  accessor: TestResourceAccessor<Wsw>,
  pool: WswMediaPool,
): WswStructure {
  const wsw = accessor.modelData;
  const wswConfig = createWSWGlobalConfig(wsw);
  if (wswConfig.groups) {
    pool.attributes.values.forEach((attr) => {
      if (attr.value instanceof ColorMediaInstance) {
        attr.value.data.color =
          wswConfig.groups!.groups[attr.value.data.groupPosition].color;
      }
    });
  }
  const universe = pickWSWUniverseNew(
    {
      main: {
        pickingStrategy: wsw.mainAttribute.pickingStrategy,
      },
      additional: wsw.additionalAttribute
        ? {
            pickingStrategy: wsw.additionalAttribute.pickingStrategy,
          }
        : null,
      criteria: {
        pickingStrategy: wsw.criteriaAttribute.pickingStrategy,
      },
      combinations: wsw.attributeCombinations.map((ac) => ({
        mainAttribute: ac.mainCategory,
        additionalAttribute: ac.additionalCategory ?? null,
        criteriaAttribute: ac.criteriaCategory ?? null,
        amount: ac.amount,
      })),
    },
    pool.attributes,
  );

  const testRoleMap = {
    primary: 'mainAttribute',
    secondary:
      wsw.additionalAttribute?.type === WSWAttributeType.STIMULI
        ? 'additionalAttribute'
        : null,
    color:
      wsw.additionalAttribute?.type === WSWAttributeType.GROUPS
        ? 'additionalAttribute'
        : null,
  } as const;
  const learningRoleMap = {
    primary: 'mainAttribute',
    secondary:
      testRoleMap['secondary'] === null &&
      wsw.criteriaAttribute.type === WSWAttributeType.STIMULI
        ? 'criteriaAttribute'
        : testRoleMap['secondary'],
    color:
      testRoleMap['color'] === null &&
      wsw.criteriaAttribute.type === WSWAttributeType.GROUPS
        ? 'criteriaAttribute'
        : testRoleMap['color'],
  } as const;
  const toCombinationResultItem = (
    attribute: WSWAttribute,
    item: WswMediaPool['attributes']['values'][number],
  ): CombinationStimulusItemInput => {
    return {
      stimulusType: toAttributeResultType(attribute.type!),
      stimulusPoolCategory: item.metaData.category,
      stimulusPoolIndex: item.metaData.index,
      stimulusPoolPosition: item.metaData.index,
      type:
        item.value instanceof ColorMediaInstance
          ? null
          : item.value.data instanceof TextMediaData
          ? StimulusType.Text
          : StimulusType.Image,
      stimulusId:
        item.value.data instanceof ImageMediaData
          ? item.value.data.identifier
          : null,
      stimulusTextValue:
        item.value.data instanceof TextMediaData ? item.value.data.text : null,
      stimulusTags:
        item.value instanceof ColorMediaInstance
          ? []
          : item.value.data
              ?.toMediaItemSnapshot()
              ?.tags.map((tag) => tag.name) ?? [],
      stimulusOriginalFilename:
        item.value.data instanceof ImageMediaData
          ? item.value.data.originalFilename
          : null,
    };
  };
  const buildCombinationUniverse = (
    uni: typeof universe,
    roleMap: typeof learningRoleMap | typeof testRoleMap,
  ) =>
    uni.map((uc) => ({
      primary: uc[roleMap.primary].value,
      secondary: roleMap.secondary ? uc[roleMap.secondary]!.value : null,
      color: roleMap.color ? uc[roleMap.color]!.value : null,
      resultEntry: {
        isLearningStimulus: uc.criteriaAttribute !== null,
        mainStimulus: toCombinationResultItem(
          wsw.mainAttribute as WSWAttribute,
          uc.mainAttribute,
        ),
        additionalStimulus: uc.additionalAttribute
          ? toCombinationResultItem(
              wsw.additionalAttribute as WSWAttribute,
              uc.additionalAttribute,
            )
          : null,
        criteriaStimulus: uc.criteriaAttribute
          ? toCombinationResultItem(
              wsw.criteriaAttribute as WSWAttribute,
              uc.criteriaAttribute,
            )
          : null,
      },
    })) as {
      primary: TextMediaInstance | DrawableMediaInstance;
      secondary: TextMediaInstance | DrawableMediaInstance | null;
      color: ColorMediaInstance | null;
      resultEntry: WswAttributeCombinationResultInput;
    }[];

  const learningPhase: WswLearningPhase = {
    startInstructions: wsw.learningPhase.instructionsScreen
      ? {
          content: chooseTemplate(
            accessor.testContext,
            wsw.learningPhase.instructionsScreen,
            wswConfig.groups?.groups ?? null,
          ),
          nextButton: wsw.nextLabel,
        }
      : null,
    endInstructions: wsw.learningPhase.feedbackScreen
      ? {
          content: chooseTemplate(
            accessor.testContext,
            wsw.learningPhase.feedbackScreen,
            wswConfig.groups?.groups ?? null,
          ),
          nextButton: wsw.nextLabel,
        }
      : null,
    trials: MindSetRandomUtils.shuffleArray(
      buildCombinationUniverse(
        universe.filter((uc) => uc.criteriaAttribute !== null),
        learningRoleMap,
      ),
    ).map((combination) => ({
      combination,
      questionSet: new WswQuestionSetFactory(
        accessor.testContext,
        pool.questions,
        pool.answers,
        'learning',
        null,
        wsw.learningPhase.questionsSet as ConditionalQuestionSet,
      ).buildInstance(wsw.learningPhase.questionOffsetTimeout ?? 0),
    })),
    combinationType: {
      secondary:
        wsw.additionalAttribute?.type === WSWAttributeType.STIMULI ||
        wsw.criteriaAttribute?.type === WSWAttributeType.STIMULI,
      color:
        wsw.additionalAttribute?.type === WSWAttributeType.GROUPS ||
        wsw.criteriaAttribute?.type === WSWAttributeType.GROUPS,
    },
  };
  const testPhase: WswLearningPhase = {
    startInstructions: wsw.testPhase.instructionsScreen
      ? {
          content: chooseTemplate(
            accessor.testContext,
            wsw.testPhase.instructionsScreen,
            wswConfig.groups?.groups ?? null,
          ),
          nextButton: wsw.nextLabel,
        }
      : null,
    endInstructions: wsw.testPhase.feedbackScreen
      ? {
          content: chooseTemplate(
            accessor.testContext,
            wsw.testPhase.feedbackScreen,
            wswConfig.groups?.groups ?? null,
          ),
          nextButton: wsw.nextLabel,
        }
      : null,
    trials: MindSetRandomUtils.shuffleArray(
      buildCombinationUniverse(universe, testRoleMap),
    ).map((combination) => ({
      combination,
      questionSet: new WswQuestionSetFactory(
        accessor.testContext,
        pool.questions,
        pool.answers,
        'test',
        null,
        wsw.testPhase.questionsSet as ConditionalQuestionSet,
      ).buildInstance(wsw.testPhase.questionOffsetTimeout ?? 0),
    })),
    combinationType: {
      secondary: wsw.additionalAttribute?.type === WSWAttributeType.STIMULI,
      color: wsw.additionalAttribute?.type === WSWAttributeType.GROUPS,
    },
  };
  return {
    accessor,
    pool,
    config: wswConfig,
    welcomePhase: {
      content: {
        content: chooseTemplate(
          accessor.testContext,
          wsw.instructions,
          wswConfig.groups?.groups ?? null,
        ),
        nextButton: wsw.startLabel,
      },
    },
    distractionPhase: wsw.distractionPhase
      ? {
          startInstructions: wsw.distractionPhase.instructionsScreen
            ? {
                content: chooseTemplate(
                  accessor.testContext,
                  wsw.distractionPhase.instructionsScreen,
                  wswConfig.groups?.groups ?? null,
                ),
                nextButton: wsw.nextLabel,
              }
            : null,
          endInstructions: wsw.distractionPhase.feedbackScreen
            ? {
                content: chooseTemplate(
                  accessor.testContext,
                  wsw.distractionPhase.feedbackScreen,
                  wswConfig.groups?.groups ?? null,
                ),
                nextButton: wsw.nextLabel,
              }
            : null,
          questionSet: new WswQuestionSetFactory(
            accessor.testContext,
            pool.questions,
            pool.answers,
            'distraction',
            null,
            wsw.distractionPhase!.questionsSet as ConditionalQuestionSet,
          ).buildInstance(),
        }
      : null,
    groupConstructionPhase:
      wsw.groupConstruction &&
      (wswConfig.groups!.groupCondition.type === 'after-learning' ||
        wswConfig.groups!.groupCondition.type === 'before-learning')
        ? {
            startInstructions: wsw.groupConstruction.instructionsScreen
              ? {
                  content: chooseTemplate(
                    accessor.testContext,
                    wsw.groupConstruction.instructionsScreen,
                    wswConfig.groups?.groups ?? null,
                  ),
                  nextButton: wsw.nextLabel,
                }
              : null,
            fakeQuestionSet: new WswQuestionSetFactory(
              accessor.testContext,
              pool.questions,
              pool.answers,
              'group',
              null,
              wsw.groupConstruction!.fakeQuestions as ConditionalQuestionSet,
            ).buildInstance(),
            position: wswConfig.groups!.groupCondition!.type,
            resultingGroup: wswConfig.groups!.groupCondition!.group!,
            manipulationCheckQuestionSet: new WswQuestionSetFactory(
              accessor.testContext,
              pool.questions,
              pool.answers,
              'group',
              wswConfig.groups!.groupCondition!.group!.group!.name!,
              wsw.groupConstruction!.groups!.find(
                (g) =>
                  wswConfig!.groups?.groupCondition!.type !== 'never' &&
                  g === wswConfig.groups!.groupCondition!.group!.group!,
              )!.questionSet as ConditionalQuestionSet,
            ).buildInstance(),
            endInstructions: wsw.groupConstruction.feedbackScreen
              ? {
                  content: chooseTemplate(
                    accessor.testContext,
                    wsw.groupConstruction.feedbackScreen,
                    wswConfig.groups?.groups ?? null,
                  ),
                  nextButton: wsw.nextLabel,
                }
              : null,
          }
        : null,
    learningPhase,
    testPhase,
    endPhase: {
      content: {
        content: wsw.endScreen
          ? chooseTemplate(
              accessor.testContext,
              wsw.endScreen,
              wswConfig.groups?.groups ?? null,
            )
          : '',
        nextButton: wsw.finishLabel,
      },
    },
  };
}
