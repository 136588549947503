import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import {
  ConfirmRegistrationMutation,
  ConfirmRegistrationMutationVariables,
  DenyRegistrationMutation,
  DenyRegistrationMutationVariables,
  FunctionBodyResponse,
  ListUserRegistrationsQueryVariables,
  UserRegistration,
} from '../../API';
import { GQLQuery, NewGQL } from '../../GQL';
import { listUserRegistrations } from '../../graphql/queries';
import { confirmRegistration, denyRegistration } from '../../graphql/mutations';

export const userRegistrationApi = createApi({
  reducerPath: 'userRegistrationApi',
  baseQuery: graphqlRequestBaseQuery({
    url: '/graphql',
  }),
  tagTypes: ['UserRegistration'],
  endpoints: (builder) => ({
    getUserRegistrations: builder.query<UserRegistration[], void>({
      queryFn: async () => ({
        data: await NewGQL.DEFAULT_CLIENT.execute(
          GQLQuery.List<ListUserRegistrationsQueryVariables, UserRegistration>(
            listUserRegistrations,
          ).create({}),
        ),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'UserRegistration' as const,
                id,
              })),
              { type: 'UserRegistration', id: 'LIST' },
            ]
          : [{ type: 'UserRegistration', id: 'LIST' }],
    }),
    confirmUserRegistration: builder.mutation<
      ConfirmRegistrationMutation['confirmRegistration'],
      ConfirmRegistrationMutationVariables
    >({
      queryFn: async (data) => ({
        data: await NewGQL.DEFAULT_CLIENT.execute(
          GQLQuery.Mutate<
            ConfirmRegistrationMutationVariables,
            FunctionBodyResponse
          >(confirmRegistration).create(data),
        ),
      }),
      invalidatesTags: (result) =>
        result && result.body
          ? [
              {
                type: 'UserRegistration' as const,
                id: result.body,
              },
              { type: 'UserRegistration', id: 'LIST' },
            ]
          : [{ type: 'UserRegistration', id: 'LIST' }],
    }),
    denyUserRegistration: builder.mutation<
      DenyRegistrationMutation['denyRegistration'],
      DenyRegistrationMutationVariables
    >({
      queryFn: async (data) => ({
        data: await NewGQL.DEFAULT_CLIENT.execute(
          GQLQuery.Mutate<
            DenyRegistrationMutationVariables,
            FunctionBodyResponse
          >(denyRegistration).create({
            registrationId: data.registrationId,
          }),
        ),
      }),
      invalidatesTags: (result) =>
        result && result.body
          ? [
              {
                type: 'UserRegistration' as const,
                id: result.body,
              },
              { type: 'UserRegistration', id: 'LIST' },
            ]
          : [{ type: 'UserRegistration', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetUserRegistrationsQuery,
  useConfirmUserRegistrationMutation,
  useDenyUserRegistrationMutation,
} = userRegistrationApi;
