import { FC } from 'react';
import { Chip, ChipProps } from '@mui/material';

const userStatusLabelMap: Record<string, string> = {
  UNCONFIRMED: 'Unconfirmed',
  CONFIRMED: 'Confirmed',
  EXTERNAL_PROVIDER: 'External Provider',
  ARCHIVED: 'Archived',
  UNKNOWN: 'Unknown',
  RESET_REQUIRED: 'Reset Required',
  FORCE_CHANGE_PASSWORD: 'Force Change Password',
};

export const UserStatusChip: FC<{
  status: string;
  size?: ChipProps['size'];
}> = ({ status, size }) => {
  const label = userStatusLabelMap[status] ?? '';
  return <Chip label={label} size={size} />;
};
