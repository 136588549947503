import { ViewInstanceController } from './view-instance-controller';
import { Observable, Subscription } from 'rxjs';
import { TestFlowSnapshot } from '../../../graph-state/test-director';

export class TestScreenInstanceController {
  readonly mainViewController: ViewInstanceController;
  private flowSubscription: Subscription;

  constructor(
    mainViewController: ViewInstanceController,
    flowStream: Observable<TestFlowSnapshot>,
  ) {
    this.mainViewController = mainViewController;
    this.flowSubscription = flowStream.subscribe({
      next: (value) => {
        value.timeProcessor.processTime('renderStart');
      },
    });
  }

  close() {
    this.flowSubscription.unsubscribe();
  }
}
