import { random, shuffle } from 'lodash';
import { weightedSet } from './weightedSet/WeightedSet';

export interface ContextParameterRandom {
  modularInt(max: number): number;
}

export const DefaultParameterRandom: ContextParameterRandom = {
  modularInt(max: number): number {
    return MindSetRandomUtils.randomInt(0, max);
  },
};

export function createIndexBasedContextRandom(
  index: number,
): ContextParameterRandom {
  return {
    modularInt(max: number): number {
      return index % (max + 1);
    },
  };
}

export function createStaticContextRandom(
  factory: () => number = () => Date.now(),
) {
  return createIndexBasedContextRandom(factory());
}

export const MindSetRandomUtils = {
  /**
   * Creates a random integer
   * @param min
   * @param max
   * @param stepSize returned integer must be divisible by stepSize
   *                 i.e. min: 0, max: 10, stepSize: 5 can create values like 0,5,10
   */
  randomInt: (min: number, max: number, stepSize = 1) => {
    return random(0, (max - min) / stepSize, false) * stepSize + min;
  },

  /**
   * Randomizes the sequence of an array
   * @param values
   */
  shuffleArray: <T>(values: T[]): T[] => {
    return shuffle(values);
  },
  /**
   * Randomizes the sequence of an array if doShuffle === true
   * @param values
   * @param doShuffle
   */
  shuffleArrayMaybe: <T>(values: T[], doShuffle: boolean): T[] => {
    return doShuffle ? shuffle(values) : values;
  },

  chooseArrayItem<T>(
    universe: T[],
    contextRandom: ContextParameterRandom = DefaultParameterRandom,
  ): T {
    return universe[contextRandom.modularInt(universe.length - 1)];
  },

  /**
   * Chooses an item from a set of items with a
   * certain random distribution
   * @param distribution
   *      keys are the items to pick from,
   *      values are their distribution
   * @param contextRandom
   */
  choseFromDistribution<T extends Record<string, number>>(
    distribution: T,
    contextRandom: ContextParameterRandom = DefaultParameterRandom,
  ): keyof T {
    const arrayToPickFrom = weightedSet(Object.entries(distribution));
    const randomIndex = contextRandom.modularInt(arrayToPickFrom.length - 1);
    return arrayToPickFrom[randomIndex];
  },
};
