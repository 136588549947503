import {
  createAsyncAppThunk,
  TestResultsThunkOutput,
} from '../../../../app/redux/thunk';
import GQL from '../../../../GQL';
import {
  getPodtTest,
  listPodtTests,
  podtResultsByTestId,
} from '../../../../graphql/queries';
import {
  createPodtTest,
  deletePodtResult,
  deleteTest,
  updatePodtTest,
} from '../../../../graphql/mutations';
import { Podt, PodtResult } from '../types';
import {
  CreatePodtTestInput,
  CreatePodtTestMutationVariables,
  DeletePodtResultMutationVariables,
  DeleteTestMutationVariables,
  FunctionBodyResponse,
  GetPodtTestQueryVariables,
  PodtResultsByTestIdQueryVariables,
  TestStatus,
  UpdatePodtTestInput,
  UpdatePodtTestMutationVariables,
} from '../../../../API';
import { removeKeysDeep } from '../../testAdmin/utils';

export const fetchPodtTest = createAsyncAppThunk<Podt, string>(
  'podt/get',
  (id) => GQL.get<GetPodtTestQueryVariables, Podt>(getPodtTest, { id }),
);

export const fetchPodtTests = createAsyncAppThunk('podt/list', () =>
  GQL.list<Podt>(listPodtTests),
);

export const fetchPodtResults = createAsyncAppThunk<
  TestResultsThunkOutput<PodtResult>,
  { testId: string }
>('podt/results', async ({ testId }) => {
  const results = await GQL.list<PodtResult, PodtResultsByTestIdQueryVariables>(
    podtResultsByTestId,
    { testId },
  );
  return { testId, results };
});

export const updatePodtAsync = createAsyncAppThunk<Podt, UpdatePodtTestInput>(
  'podt/update',
  (input) =>
    GQL.mutate<Podt, UpdatePodtTestMutationVariables>(updatePodtTest, {
      input: { ...(removeKeysDeep(input, 'tableData') as UpdatePodtTestInput) },
    }),
);

export const updatePodtStatusAsync = createAsyncAppThunk<
  Podt,
  { testId: string; status: TestStatus }
>('podt/update/status', ({ status, testId }) =>
  GQL.mutate<Podt, UpdatePodtTestMutationVariables>(updatePodtTest, {
    input: { id: testId, status },
  }),
);

export const createPodtAsync = createAsyncAppThunk<Podt, CreatePodtTestInput>(
  'podt/create',
  (arg) => {
    return GQL.mutate<Podt, CreatePodtTestMutationVariables>(createPodtTest, {
      input: arg,
    });
  },
);

export const deletePodtResultsAsync = createAsyncAppThunk<
  { testId: string; resultsIds: string[] },
  { testId: string; resultsIds: string[] }
>('podt/delete/results', async (arg) => {
  await Promise.all(
    arg.resultsIds.map((id) =>
      GQL.mutate<PodtResult, DeletePodtResultMutationVariables>(
        deletePodtResult,
        { input: { id } },
      ),
    ),
  );
  return arg;
});

export const deletePodtAsync = createAsyncAppThunk<string, string>(
  'podt/delete',
  async (testId) => {
    await GQL.mutate<FunctionBodyResponse, DeleteTestMutationVariables>(
      deleteTest,
      {
        testId,
        testType: 'PODT',
      },
    );
    return testId;
  },
);
