import { ViewBoxes } from '../../../subject/testRunner/screens/layouting-view';
import { IATSelectorTree } from './iat-selectors';
import { IatStructure } from '../loader/structure/iat-structure';
import React from 'react';
import { TestInstructionsView } from '../../../subject/testRunner/screens/basic-views/test-instructions-view';
import { OptionsStereotypes } from '../../../subject/testRunner/controls/control-stereotypes';
import { createOptionTipsContainerViewWrapper } from '../../../subject/testRunner/screens/basic-views/option-test-views';
import { createOptionsContentView } from './iat-options-content-view';
import { TestScreenComposer } from '../../../subject/testRunner/screens/composer/test-screen-composer';
import { ViewDefinitionCollection } from '../../../subject/testRunner/screens/composer/view-definition-collection';
import { LayoutingView } from '../../../subject/testRunner/screens/composer/views/layouting-view';

export function createIatViewMap(structure: IatStructure) {
  return TestScreenComposer.Create(
    LayoutingView.growing({
      selector: IATSelectorTree.rootSelector,
      layout: {
        subComponents: ViewDefinitionCollection.Create({
          instructions: TestInstructionsView({
            selector: IATSelectorTree.rootSelector.instructions,
            control: OptionsStereotypes.Confirm.UI,
          }),
          blockInstructions: TestInstructionsView({
            delay:
              structure.accessor.testContext.deviceInfo.mode === 'mobile'
                ? 3
                : 0,
            selector: IATSelectorTree.rootSelector.blockInstructions,
            control:
              structure.accessor.testContext.deviceInfo.mode === 'mobile'
                ? (OptionsStereotypes.Confirm.Control as any)
                : undefined,
          }),
          options: createOptionTipsContainerViewWrapper(
            structure.accessor.testContext,
            structure.options,
            IATSelectorTree.rootSelector.options,
            () => createOptionsContentView(structure),
          ),
        }),
        boxes: ViewBoxes.Define((builder) => ({
          content: builder.growing(
            'instructions',
            'options',
            'blockInstructions',
          ),
        })),
      } as const,
      contentComponent: (containerProvider, boxProvider) => {
        return () => {
          return containerProvider.createContainer(
            <div
              style={{
                width:
                  structure.accessor.testContext.deviceInfo.mode === 'mobile'
                    ? '100%'
                    : structure.accessor.modelData.style.maxWidth + '%',
                height: '100%',
                backgroundColor:
                  structure.accessor.modelData.style.backgroundColour ??
                  'white',
              }}
            >
              {boxProvider.createBox('content')}
            </div>,
            (_, style) => ({
              ...style,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
                structure.accessor.modelData.style.backgroundColour ?? 'white',
            }),
          );
        };
      },
    }),
  );
}
