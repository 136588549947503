import { useField } from 'formik';
import {
  MediaItemSnapshotInput,
  WswStimulusAttributeVariant,
} from '../../../API';
import MediaItemList from '../MediaItemList';
import React from 'react';
import {
  Button,
  IconButton,
  ListItemAvatar,
  Stack,
  Typography,
} from '@mui/material';
import { MediaItemSelectionListDialog } from './MidiaItemsPicker/MediaItemsPicker';
import { Add, Edit } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { TextPickerDialog } from './TextPickerDialog';

function TextMediaItemsView({
  mediaItems,
}: {
  mediaItems: MediaItemSnapshotInput[];
}) {
  const [showAll, setShowAll] = React.useState(false);
  return (
    <List dense>
      {(showAll ? mediaItems : mediaItems.slice(0, 2)).map((text, index) => {
        return (
          <ListItem sx={{ alignItems: 'flex-start' }}>
            <ListItemAvatar sx={{ mt: '4px' }}>{index + 1}.</ListItemAvatar>
            <ListItemText
              primary={
                showAll ? text.text! : text.text!.substring(0, 150) + '...'
              }
              title={text.text!}
            />
          </ListItem>
        );
      })}
      <ListItem>
        <ListItemAvatar sx={{ mt: '4px' }}></ListItemAvatar>
        <ListItemIcon>
          <Button onClick={() => setShowAll(!showAll)}>
            {showAll ? 'Show less' : '... Show more'}
          </Button>
        </ListItemIcon>
      </ListItem>
    </List>
  );
}

export function WSWAttributeMediaItemPicker({
  name,
  pickerScope,
}: {
  pickerScope: WswStimulusAttributeVariant;
  name: string;
}) {
  const [, { value: mediaItems }, { setValue: setMediaItems }] = useField<
    MediaItemSnapshotInput[] | undefined
  >(name);
  const [showPicker, setShowPicker] = React.useState(false);

  if (pickerScope === WswStimulusAttributeVariant.Image) {
    return (
      <>
        <Stack direction="row" sx={{ width: '100%', alignItems: 'center' }}>
          <Typography>Image Stimuli ({mediaItems?.length})</Typography>
          {!!mediaItems?.length && (
            <IconButton onClick={() => setShowPicker(true)}>
              <Edit />
            </IconButton>
          )}
        </Stack>
        {mediaItems?.length ? (
          <MediaItemList items={mediaItems} datatype={'image'} />
        ) : (
          <Button
            sx={{ margin: '0.5em' }}
            onClick={() => setShowPicker(true)}
            startIcon={<Add fontSize="small" />}
          >
            Choose images
          </Button>
        )}
        {showPicker && (
          <MediaItemSelectionListDialog
            value={mediaItems}
            open={showPicker}
            onClose={() => setShowPicker(false)}
            title={'Images'}
            onConfirm={(mediaItems) => setMediaItems(mediaItems)}
          />
        )}
      </>
    );
  }
  return (
    <>
      <Stack direction="row" sx={{ width: '100%', alignItems: 'center' }}>
        <Typography>Text Stimuli ({mediaItems?.length})</Typography>
        {!!mediaItems?.length && (
          <IconButton onClick={() => setShowPicker(true)}>
            <Edit />
          </IconButton>
        )}
      </Stack>
      {mediaItems?.length ? (
        <TextMediaItemsView mediaItems={mediaItems} />
      ) : (
        <Button
          sx={{ margin: '0.5em' }}
          onClick={() => setShowPicker(true)}
          startIcon={<Add fontSize="small" />}
        >
          Add Texts
        </Button>
      )}
      {showPicker && (
        <TextPickerDialog
          fieldName={name}
          dialogOpen={showPicker}
          onClose={() => setShowPicker(false)}
        />
      )}
    </>
  );
}
