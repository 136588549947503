import { PickingSet } from './PickingSet';

/**
 * PickingSet intended to create a PickingSet with a plain generator function
 */
export class NewGeneratingPickingSet<T> implements PickingSet<T> {
  constructor(
    private producer: () => T,
    public readonly name?: string,
  ) {}

  pick(): T {
    return this.producer();
  }

  validate(): void {}
}
