import { createSlice, isAnyOf, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { Amp, AmpResult } from "../types";
import {
  createAmpAsync,
  deleteAmpAsync,
  deleteAmpResultsAsync,
  fetchAmpResults,
  fetchAmpTest,
  fetchAmpTests,
  updateAmpAsync,
  updateAmpStatusAsync
} from "./amp.thunk";
import { TestType } from "../../../subject/types";
import { createInitialTestSliceState } from "../../utils";

export const ampSlice = createSlice({
  name: TestType.AMP,
  initialState: createInitialTestSliceState<Amp, AmpResult>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAmpTest.fulfilled, (state, action) => {
        state.tests[action.payload.id] = action.payload;
      })
      .addCase(fetchAmpTests.fulfilled, (state, action) => {
        state.tests = action.payload
          .sort(
            (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          )
          .reduce((result: { [id: string]: Amp }, currentValue: Amp | null) => {
          if (currentValue) {
            result[currentValue.id] = currentValue;
          }
          return result;
        }, {});
      })
      .addCase(fetchAmpResults.fulfilled, (state, action) => {
        state.results[action.payload.testId] = action.payload;
      })
      .addCase(deleteAmpAsync.fulfilled, (state, action) => {
        const tests = { ...state.tests };
        delete tests[action.payload];
        state.tests = tests;
      })
      .addCase(deleteAmpResultsAsync.fulfilled, (state, action) => {
        if (state.results[action.payload.testId]) {
          state.results[action.payload.testId].results = state.results[action.payload.testId].results.filter(
            (r) => !action.payload.resultsIds.includes(r.id)
          );
         /* state.results[action.payload.testId].tableView.results = state.results[
            action.payload.testId
          ].tableView.results.filter((r) => !action.payload.resultsIds.includes(r.id));*/
        }
      })
      .addMatcher(
        isAnyOf(createAmpAsync.fulfilled, updateAmpAsync.fulfilled, updateAmpStatusAsync.fulfilled),
        (state, action) => {
          state.tests[action.payload.id] = action.payload;
        }
      )
      .addMatcher(isPending(), (state,action) => {
        if(action.type.toUpperCase().includes(TestType.AMP)) {
          state.isLoading = true;
        }
      })
      .addMatcher(isFulfilled(), (state) => {
        state.isLoading = false;
      })
      .addMatcher(isRejected(), (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  }
});

export default ampSlice.reducer;
