import StyledSelect from './StyledSelect';
import React from 'react';
import { translationInfoMap } from '../../features/subject/testRunner/state/baseTestContext/app-configuration/translations/translation-map';

export function TestLanguagePicker() {
  return (
    <StyledSelect name="language" label={'Language'}>
      <option key={''} value={''}>
        -
      </option>
      {Object.entries(translationInfoMap).map(([key, value]) => (
        <option key={key} value={key}>
          {value.languageName}
        </option>
      ))}
    </StyledSelect>
  );
}
