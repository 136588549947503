import { IatResult } from '../types';
import { ResultTableColumn } from './result-preview-table';

export const IatResultPreviewGroups: ResultTableColumn<IatResult>[] = [
  {
    name: 'test id',
    accessor: (r) => r.testId,
  },
  {
    name: 'proband id',
    accessor: (r) => r.survey.probandId,
  },
  {
    name: 'provider',
    accessor: (r) => r.survey.testProviderName,
  },
  {
    name: 'blockIndex',
    accessor: (r) => r.blockIndex + '',
  },
  {
    name: 'blockName',
    accessor: (r) => r.blockName + '',
  },
  {
    name: 'trialIndex',
    accessor: (r) => r.trialIndex + '',
  },
  {
    name: 'label',
    accessor: (r) => r.stimulus.categoryLabel.text + '',
  },
  {
    name: 'type',
    accessor: (r) => r.stimulus.categoryType + '',
  },
  {
    name: 'trigger start',
    accessor: (r) => r.timing.startTriggerDelay + 'ms',
  },
  {
    name: 'action',
    accessor: (r) => r.actionResultName,
  },
  {
    name: 'race start',
    accessor: (r) => (r.timing.controlRaceDelay ?? '-') + 'ms',
  },
  {
    name: 'render start',
    accessor: (r) => (r.timing.renderStartDelay ?? '-') + 'ms',
  },
  {
    name: 'render end',
    accessor: (r) => (r.timing.renderEndDelay ?? '-') + 'ms',
  },
];
