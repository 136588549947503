import { MediaItemSnapshot } from '../../../../../API';
import {
  CreateTestResourceLoader,
  GQLTestQueryLoader,
} from '../../../../subject/testRunner/media/test-resource-loader';
import { PodtQueries } from './podt.queries';
import {
  PodtMediaItemResponseSelector,
  PodtMediaItemScenesSelector,
  PodtMediaItemTargetSelector,
} from './podt-media-item-selectors';
import { LoadPodtMedia } from './load-podt-media';

export type PodtOptionsType = 'response';

export const PodtResourceLoader = CreateTestResourceLoader(
  GQLTestQueryLoader(PodtQueries.Get),
  (test, loadingManager) => {
    const collect = [
      ...(PodtMediaItemScenesSelector.collect(test) as MediaItemSnapshot[]),
      ...(PodtMediaItemTargetSelector.collect(test) as MediaItemSnapshot[]),
      ...(PodtMediaItemResponseSelector.collect(test) as MediaItemSnapshot[]),
      ...((test.fixation?.mediaItem
        ? [test.fixation.mediaItem]
        : []) as MediaItemSnapshot[]),
    ];
    loadingManager.attachItems(...collect);
    return loadingManager.loadCache();
  },
);

export type PodtMediaPool = ReturnType<typeof LoadPodtMedia>;
