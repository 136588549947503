import React, { FC, useMemo } from 'react';
import {
  DataGridPro,
  GridEnrichedColDef,
  GridRowParams,
  GridSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { NewImageUploader } from '../uploader/NewImageUploader';
import {
  Box,
  Chip,
  IconButton,
  Paper,
  Snackbar,
  TextField,
} from '@mui/material';
import { MediaItem } from '../types';
import { Tag } from '../../../API';
import { TagAutoComplete } from '../TagAutoComplete';
import { formatDate } from '../../../dateUtils';
import MediaItemComponent from '../MediaItemComponent';
import {
  useGetMediaItemsQuery,
  useLinkMediaItemsTagsMutation,
  useSetSourceMutation,
  useUnlinkMediaItemTagMutation,
} from '../redux/mediaApi';
import { AllInclusive } from '@mui/icons-material';
import { tagEqualOperators } from './MediaPoolFilters';
import { CustomFilterPanel } from '../../tests/testAdmin/testList/CustomFilterPanel';
import { Alert } from '@mui/lab';
import { DeleteMediaItemDialog } from '../DeleteMediaItemsDialog';
import { useIsAdmin } from '../../../auth/auth.hook';
import { RequireAuth } from '../../../auth/RequiredAuth';

const MediaPool: FC = () => {
  const [selectedMediaItemIds, setSelectedMediaItemIds] =
    React.useState<GridSelectionModel>([]);
  const [newSourceValue, setNewSourceValue] = React.useState('');

  const { data, isLoading, isFetching, error } = useGetMediaItemsQuery();

  const [linkTags] = useLinkMediaItemsTagsMutation();
  const [unlinkTag] = useUnlinkMediaItemTagMutation();
  const [setSource] = useSetSourceMutation();

  const isAdmin = useIsAdmin();

  const handleUpdateSource = () =>
    setSource({
      source: newSourceValue,
      mediaItemIds: selectedMediaItemIds as string[],
    });

  const mediaPoolColums: GridEnrichedColDef[] = useMemo(
    () => [
      {
        field: 'Value',
        headerName: 'Value',
        sortable: false,
        filterable: false,
        width: 300,
        renderCell: (params) => (
          <MediaItemComponent mediaItem={params.row as MediaItem} />
        ),
      },
      {
        field: 'tags',
        headerName: 'Tags',
        width: 350,
        filterOperators: tagEqualOperators,
        valueGetter: (params) =>
          (params.row as any)?.tags?.items
            ?.map((t: { tag: Tag }) => t.tag.name)
            .join(', '),
        renderCell: (params) =>
          (params.row as MediaItem)?.tags?.items?.map((tag) => {
            return (
              <Chip
                style={{ marginRight: 2 }}
                size="small"
                label={tag?.tag.name}
                onDelete={
                  isAdmin
                    ? () => {
                        unlinkTag({
                          id: tag?.id as string,
                        });
                      }
                    : undefined
                }
              />
            );
          }),
      },
      {
        field: 'source',
        headerName: 'Source',
        width: 200,
      },
      {
        field: 'originalFileName',
        headerName: 'Original file name',
        width: 200,
      },
      {
        field: 'updatedAt',
        headerName: 'Updated',
        type: 'date',
        width: 150,
        valueFormatter: (params) => formatDate(params.value as string),
      },
      {
        hide: true,
        field: 'createdAt',
        headerName: 'Created',
        type: 'date',
        width: 150,
        valueFormatter: (params) => formatDate(params.value as string),
      },
      {
        field: 'id',
        headerName: 'ID',
        width: 320,
        hide: true,
      },
      ...(isAdmin
        ? [
            {
              field: 'actions',
              type: 'actions',
              getActions: (params: GridRowParams<any>) => {
                return [
                  <DeleteMediaItemDialog selectedMediaItem={params.row} />,
                ];
              },
            },
          ]
        : []),
    ],
    [isAdmin, unlinkTag],
  );

  return (
    <RequireAuth>
      <Paper sx={{ height: '100%' }}>
        <Snackbar open={Boolean(error)} autoHideDuration={6000}>
          <Alert
            severity="error"
            sx={{
              width: '100%',
              whiteSpace: 'pre-line',
              wordWrap: 'break-word',
            }}
          >
            {(error as any)?.message}
          </Alert>
        </Snackbar>
        {selectedMediaItemIds.length > 0 && isAdmin && (
          <Box sx={{ display: 'flex', p: 2 }}>
            <Box sx={{ mr: 6 }}>
              <TagAutoComplete
                applyOne={(tag) => {
                  linkTags({
                    tags: [tag],
                    mediaItemIds: selectedMediaItemIds as string[],
                    existingMediaItemTags: Object.fromEntries(
                      data
                        ?.filter((m) => selectedMediaItemIds.includes(m.id))
                        ?.map(
                          (m) =>
                            [
                              m.id,
                              m.tags?.items?.map((t) => t?.tagId ?? '') ?? [],
                            ] as const,
                        ) ?? [],
                    ),
                  });
                }}
                applyAll={(tags) => {
                  linkTags({
                    tags,
                    mediaItemIds: selectedMediaItemIds as string[],
                    existingMediaItemTags: Object.fromEntries(
                      data
                        ?.filter((m) => selectedMediaItemIds.includes(m.id))
                        ?.map(
                          (m) =>
                            [
                              m.id,
                              m.tags?.items?.map((t) => t?.tagId ?? '') ?? [],
                            ] as const,
                        ) ?? [],
                    ),
                  });
                }}
              />
            </Box>
            <TextField
              label="Source"
              sx={{ mr: 6, width: 208 }}
              value={newSourceValue}
              onChange={(e) => setNewSourceValue(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton edge="end" onClick={handleUpdateSource}>
                    <AllInclusive />
                  </IconButton>
                ),
              }}
            />
          </Box>
        )}
        <DataGridPro
          style={{ height: '94vh' }}
          className={'noSelect'}
          disableSelectionOnClick={true}
          checkboxSelection={isAdmin}
          loading={isFetching || isLoading}
          selectionModel={selectedMediaItemIds}
          onSelectionModelChange={setSelectedMediaItemIds}
          components={{
            Toolbar: () => (
              <GridToolbarContainer>
                {selectedMediaItemIds.length === 0 && (
                  <div style={{ paddingLeft: 12 }}>
                    {isAdmin && <NewImageUploader />}
                    <GridToolbarFilterButton
                      onPointerEnterCapture={() => {}}
                      onPointerLeaveCapture={() => {}}
                      placeholder=""
                    />
                    <GridToolbarColumnsButton
                      onPointerEnterCapture={() => {}}
                      onPointerLeaveCapture={() => {}}
                      placeholder=""
                    />
                  </div>
                )}
              </GridToolbarContainer>
            ),
            FilterPanel: () => <CustomFilterPanel />,
          }}
          rowHeight={100}
          columns={mediaPoolColums}
          rows={data ?? []}
        />
      </Paper>
    </RequireAuth>
  );
};
export default MediaPool;
