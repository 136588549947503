import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { SxProps } from '@mui/system';

export interface InfoMessageProps {
  variant?: 'hint' | 'warn' | 'error' | 'success';
  message: string | ReactNode;
  sx?: SxProps;
}

export default function InfoMessage({ message, sx }: InfoMessageProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', ...sx }}>
      <ErrorOutlineOutlined
        fontSize="small"
        sx={{ color: 'warning.dark', display: 'inline-flex', mr: 1 }}
      />
      <Typography
        variant="caption"
        sx={{ wordWrap: 'break-word', whiteSpace: 'break-spaces' }}
      >
        {message}
      </Typography>
    </Box>
  );
}
