import { TreeSequence } from '../tree-sequence/tree-sequence';
import { ScreenTreeNode } from '../nodes/screen-tree-node';
import { PhaseTreeNode } from '../nodes/phase-tree-node';
import { isReallyDefined } from '../../../utils';
import { uniqBy } from 'lodash';
import { TreeNode } from '../nodes/tree-node';
import { SortedTreeNode } from './sorted-tree-node';

export class SortedTree {
  rootNode: SortedTreeNode;

  constructor(rootNode: SortedTreeNode) {
    this.rootNode = rootNode;
  }

  protected insertNode(screenNode: ScreenTreeNode) {
    const path: PhaseTreeNode[] = [];
    let currentParent = screenNode.parent as PhaseTreeNode;
    while (currentParent.parent && currentParent !== this.rootNode.treeNode) {
      path.unshift(currentParent);
      currentParent = currentParent.parent;
    }
    this.rootNode.insert(path, screenNode);
  }

  static create(treeSequence: TreeSequence): SortedTree {
    const modifiers = treeSequence.tree.modifiers;
    const visitedNodePaths = new Set<string>();
    function nextScreenNodes(nodes: TreeNode[]) {
      const result = uniqBy(
        Object.values(modifiers)
          .flatMap((mod) =>
            nodes
              .map((n) => n.next(mod))
              .filter(isReallyDefined)
              .map((n) => n[1].screen(mod)),
          )
          .filter(isReallyDefined)
          .map((t) => t[1]),
        (t) => t.pathName,
      ).filter((t) => !visitedNodePaths.has(t.pathName));
      result.forEach((n) => visitedNodePaths.add(n.pathName));
      return result;
    }
    const root = treeSequence.tree.root();
    const startScreens = Object.values(modifiers)
      .map((mod) => root.screen(mod))
      .filter(isReallyDefined)
      .map((n) => n[1]);

    const sortedScreens: ScreenTreeNode[] = [];
    startScreens.forEach((s) => visitedNodePaths.add(s.pathName));
    sortedScreens.push(...startScreens);
    let currentNodes = nextScreenNodes(startScreens);
    while (currentNodes.length > 0) {
      sortedScreens.push(...currentNodes);
      currentNodes = nextScreenNodes(currentNodes);
    }
    const sortedTree = new SortedTree(
      new SortedTreeNode(root.displayName, root),
    );
    sortedScreens.forEach((screen) => sortedTree.insertNode(screen));
    return sortedTree;
  }
}
