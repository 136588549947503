import React from 'react';
import { EditorVariables } from './textReplacer';
import { Stack, Typography } from '@mui/material';
import InfoMessage from '../../../components/InfoMessage';

interface VariablesDisplayProps<T extends string> {
  variables: EditorVariables<T>;
}

export function VariablesDisplay<T extends string>({
  variables,
}: VariablesDisplayProps<T>) {
  const vars = Object.values(variables.definitions) as Record<
    string,
    {
      name: string;
      description: string;
    }
  >[];

  return (
    <Stack sx={{ mb: 2 }}>
      <InfoMessage
        message={'These variables can be used an will be replaced'}
      />
      {vars.map((variable, i) => (
        <Typography variant="body2" key={`variable-${i}`}>
          {`#${variable.name}# : ${variable.description}`}
        </Typography>
      ))}
    </Stack>
  );
}
