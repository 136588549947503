import {
  BasicDimension,
  DrawableDimension,
  DrawableMetrics,
} from '../../../media/drawable-dimensions';
import {
  DrawableMediaInstance,
  ImageMediaData,
  MediaDrawable,
  TextMediaData,
} from '../../../media/MediaData';
import { TestOptionCategory } from '../../../media/options-test';
import { DimensionReducer } from '../../dom-measure/dimension-reducer';
import MediaOffscreenCanvas from '../../../../MediaOffscreenCanvas';
import { displayRatio } from '../../basic-components/media-instance-components';

export interface CanvasStrategy<R> {
  minSize: (data: R[]) => BasicDimension;
  scaleSize: (maxSize: BasicDimension, data: R[]) => BasicDimension;
  scaler: (
    scaleSize: BasicDimension,
    data: R[],
  ) => {
    scale: (data: R) => MediaDrawable;
  };
}

export class OptionsCanvasStrategy<O extends string>
  implements CanvasStrategy<'first' | 'second'>
{
  constructor(
    private optionCategory: TestOptionCategory<O>,
    private variant: 'first' | 'second',
  ) {}

  minSize(data: ('first' | 'second')[]): BasicDimension {
    return DimensionReducer.max().process(
      this.optionCategory.first.mediaInstance.drawable.dimension,
      this.optionCategory.second.mediaInstance.drawable.dimension,
    ).currentDimension;
  }

  scaleSize(
    maxSize: BasicDimension,
    data: ('first' | 'second')[],
  ): BasicDimension {
    return this.optionCategory[this.variant].mediaInstance.drawable.dimension;
  }

  scaler(
    scaleSize: BasicDimension,
    data: ('first' | 'second')[],
  ): { scale: (data: 'first' | 'second') => MediaDrawable } {
    return {
      scale: (d) => {
        return this.optionCategory[d].mediaInstance.drawable;
      },
    };
  }
}

export class StimulusCanvasStrategy
  implements CanvasStrategy<DrawableMediaInstance | undefined>
{
  minSize(data: (DrawableMediaInstance | undefined)[]): BasicDimension {
    return DrawableDimension.NullSize;
  }

  scaleSize(
    maxSize: BasicDimension,
    data: (DrawableMediaInstance | undefined)[],
  ): BasicDimension {
    const maxCanvasSize = DimensionReducer.max().process(
      ...data.map(
        (di) => di?.drawable?.dimension ?? DrawableDimension.NullSize,
      ),
    ).currentDimension;
    return DimensionReducer.min().process(maxSize).process(maxCanvasSize)
      .currentDimension;
  }

  scaler(
    scaleSize: BasicDimension,
    data: (DrawableMediaInstance | undefined)[],
  ): { scale: (data: DrawableMediaInstance | undefined) => MediaDrawable } {
    // const maxCanvasSize = DimensionReducer.max().process(...data.map(di => di.drawable.dimension)).currentDimension;
    const textDrawables = data
      .filter((d) => d?.data instanceof TextMediaData)
      .map((d) => d!.drawable);
    const textMax = DimensionReducer.max().process(
      ...textDrawables.map((td) => td.dimension),
    ).currentDimension;
    const textScaleFactor =
      textMax.width > scaleSize.width || textMax.height > scaleSize.height
        ? Math.min(
            scaleSize.width / textMax.width,
            scaleSize.height / textMax.height,
          )
        : 1;

    const imageDrawables = data
      .filter((d) => d?.data instanceof ImageMediaData)
      .map((d) => d!.drawable);
    const imageMax = DimensionReducer.max().process(
      ...imageDrawables.map((td) => td.dimension),
    ).currentDimension;
    const imageScaleFactor =
      imageMax.width > scaleSize.width || imageMax.height > scaleSize.height
        ? Math.min(
            scaleSize.width / imageMax.width,
            scaleSize.height / imageMax.height,
          )
        : 1;
    return {
      scale: (sData) => {
        if (sData?.data instanceof TextMediaData) {
          const mediaCanvas = new MediaOffscreenCanvas(
            DrawableDimension.multiply(scaleSize, displayRatio),
          );
          mediaCanvas.drawImgSourceCenter(
            sData.drawable.imageSource,
            new DrawableDimension(
              sData.drawable.dimension.width * textScaleFactor,
              sData.drawable.dimension.height * textScaleFactor,
            ),
            displayRatio,
          );
          return {
            dimension: new DrawableMetrics(
              mediaCanvas.width,
              mediaCanvas.height,
            ),
            imageSource: mediaCanvas.canvas,
          };
        }
        if (sData?.data instanceof ImageMediaData) {
          const mediaCanvas = new MediaOffscreenCanvas(
            DrawableDimension.multiply(scaleSize, displayRatio),
          );
          mediaCanvas.drawImgSourceCenter(
            sData.drawable.imageSource,
            new DrawableDimension(
              sData.drawable.dimension.width * imageScaleFactor,
              sData.drawable.dimension.height * imageScaleFactor,
            ),
            displayRatio,
          );
          return {
            dimension: new DrawableMetrics(
              mediaCanvas.width,
              mediaCanvas.height,
            ),
            imageSource: mediaCanvas.canvas,
          };
        }
        if (sData === undefined) {
          const offscreenCanvas = new MediaOffscreenCanvas(scaleSize);
          offscreenCanvas.clearTransparent();

          return {
            dimension: new DrawableMetrics(scaleSize.width, scaleSize.height),
            imageSource: offscreenCanvas.canvas,
          };
        }
        throw new Error('unknown drawable type');
      },
    };
  }
}
