import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from './store';

export function createAsyncAppThunk<Returned, ThunkArg = void>(
  prefix: string,
  creator: AsyncThunkPayloadCreator<Returned, ThunkArg, { state: RootState }>,
) {
  return createAsyncThunk<Returned, ThunkArg, { state: RootState }>(
    prefix,
    creator,
  );
}

export type TestResultsThunkOutput<R> = {
  testId: string;
  results: R[];
};
