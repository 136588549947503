import React, { FC } from 'react';
import {
  createMeasurableWrapperComponent,
  MeasureRefComponent,
} from '../../dom-measure/measurable-component';
import { SelectorNodeMap } from '../../selectors/selector-tree';
import { ViewContainerFactory } from '../../view-content-container';
import { ViewScaler } from '../scaler/view-scaler';
import { View } from './view';
import { AbstractView } from './abstract-view';
import { ViewDefinition } from '../view-definition-collection';
import {
  DrawableDimension,
  SizedArea,
} from '../../../media/drawable-dimensions';
import { createIndependentDOMMeasurer } from '../../dom-measure/measure-render';
import { DimensionReducer } from '../../dom-measure/dimension-reducer';
import { awaitFirst } from '../../../utils/rxjs-utils';
import { ViewInstance } from '../screen-instance/view-instance';
import { Optional, OptionalValue } from '../../selectors/data-wrapper';
import { AbstractViewScaler } from '../scaler/abstract-view-scaler';
import { MeasurableValue } from '../screen-instance/measurable-value';
import { Selector } from '../../selectors/selector';
import { isReallyDefined } from '../../../../utils';

export type HTMLContentComponent<R> = FC<
  MeasureRefComponent<any> & { value: R }
>;

export class HTMLContentViewScaler extends AbstractViewScaler {
  protected contentComponent: HTMLContentComponent<any>;

  constructor(
    viewScalerMap: Map<View, ViewScaler>,
    selectorNodeMap: SelectorNodeMap,
    view: HTMLContentView<any>,
  ) {
    super(viewScalerMap, view, selectorNodeMap);
    this.contentComponent = view.contentComponent;
  }

  async measureInner(): Promise<ViewScaler> {
    this.sizeState.innerSize = this.view.grows
      ? SizedArea.dimPx(this.sizeState.size)
      : SizedArea.of(undefined, undefined);
    return this;
  }

  async measureMin(): Promise<ViewScaler> {
    if (this.view.grows) {
      this.sizeState.minSize = DrawableDimension.NullSize;
      return this;
    }
    const domMeasurer = await createIndependentDOMMeasurer(
      this.sizeState.maxSize,
    );
    const displayDataSet = this.selectorNodeMap
      .getNodeMap(this.view.selector)
      .getDisplayDataSet();

    this.sizeState.minSize = DimensionReducer.max().process(
      ...(await Promise.all(
        displayDataSet
          .map((dd) => dd[1][1])
          .map(async (dd) => {
            const measurableComponent = createMeasurableWrapperComponent(
              (props) =>
                React.createElement(this.contentComponent, {
                  ...props,
                  value: dd,
                }),
            );
            const size = awaitFirst(
              measurableComponent.sizeSubscriber,
              isReallyDefined,
            );
            domMeasurer.renderElement(
              measurableComponent.element,
              this.view.grows
                ? undefined
                : {
                    width: 'max',
                    height: 'max',
                  },
            );
            return size;
          }),
      )),
    ).currentDimension;
    domMeasurer.close();
    return this;
  }

  async scale(): Promise<ViewScaler> {
    this.viewInstance = new ViewInstance(
      this,
      this.selectorNodeMap.getNodeMap(this.view.selector),
      (_, dataStream) => {
        return () => {
          const pseudoRef = React.useRef<HTMLElement | null>(null);
          const [data, setData] = React.useState<
            OptionalValue<MeasurableValue<any>>
          >(Optional.none());
          React.useEffect(() => {
            const subscription = dataStream.subscribe({
              next: (value) => {
                setData(Optional.value(value));
              },
            });
            return () => subscription.unsubscribe();
          }, []);
          React.useEffect(() => {
            if (Optional.isValue(data)) {
              data[1].measureProcessTime();
            }
          }, [data]);
          const ContentComponent = this.contentComponent;
          return (
            <div style={{ ...this.sizeState.innerSize.toSizeModeStyle() }}>
              {Optional.isValue(data) && (
                <ContentComponent
                  containerRef={pseudoRef}
                  value={data[1].value}
                />
              )}
            </div>
          );
        };
      },
    );
    return this;
  }
}

export class HTMLContentView<R> extends AbstractView {
  constructor(
    grows: boolean,
    selector: Selector<R>,
    public contentComponent: HTMLContentComponent<R>,
    containerFactory?: ViewContainerFactory,
  ) {
    super(grows, selector, containerFactory);
  }

  createViewScaler(
    viewScalerMap: Map<View, ViewScaler>,
    selectorNodeMap: SelectorNodeMap,
  ): ViewScaler {
    return new HTMLContentViewScaler(viewScalerMap, selectorNodeMap, this);
  }

  static growing<R>(
    selector: Selector<R>,
    contentComponent: HTMLContentComponent<R>,
    containerFactory?: ViewContainerFactory,
  ) {
    return ViewDefinition.Leaf(
      new HTMLContentView(true, selector, contentComponent, containerFactory),
    );
  }

  static fixed<R>(
    selector: Selector<R>,
    contentComponent: HTMLContentComponent<R>,
    containerFactory?: ViewContainerFactory,
  ) {
    return ViewDefinition.Leaf(
      new HTMLContentView(false, selector, contentComponent, containerFactory),
    );
  }
}
