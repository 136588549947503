import {
  BasicDimension,
  DrawableDimension,
} from '../../media/drawable-dimensions';

export class DimensionReducer {
  currentDimension: BasicDimension;
  strategy: 'min' | 'max';

  private constructor(
    currentDimension: BasicDimension,
    strategy: 'min' | 'max',
  ) {
    this.currentDimension = currentDimension;
    this.strategy = strategy;
  }

  process(...dimensions: BasicDimension[]): DimensionReducer {
    this.currentDimension = dimensions.reduce(
      (acc, c) => DrawableDimension.extremes(this.strategy, acc, c),
      this.currentDimension,
    );
    return this;
  }

  static min() {
    return new DimensionReducer(
      new DrawableDimension(Number.MAX_VALUE, Number.MAX_VALUE),
      'min',
    );
  }

  static max() {
    return new DimensionReducer(new DrawableDimension(0, 0), 'max');
  }
}
