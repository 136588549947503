import React, { FC } from 'react';
import { Button, ButtonProps, Tooltip } from '@mui/material';
import { BurstMode as UploadIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const Input = styled('input')({
  display: 'none',
});

interface NativeImageUploadButtonProps {
  onChange: (files: File[]) => void;
  maxFileSize?: number;
  maxFileAmount?: number;
  buttonProps?: ButtonProps;
  accept?: string;
  label: string;
}

export const NativeImageUploadButton: FC<NativeImageUploadButtonProps> = ({
  label,
  onChange,
  buttonProps,
  maxFileSize = 10000000,
  maxFileAmount = 200,
  accept,
}) => {
  const onFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const errors: string[] = [];
    if (event.target.files) {
      if (event.target.files.length > maxFileAmount) {
        alert('To many files. Maxmium 200 files at a time.');
        return;
      }
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files.item(i);
        if (file) {
          if (file.size > maxFileSize) {
            errors.push(`File "${file.name}" is too big.`);
          }
        }
      }
      if (errors.length > 0) {
        const formatedFileErrors = errors.join('\r');
        alert(`Maximum filesize is 1 Mb.\r\r${formatedFileErrors}`);
      } else {
        onChange(Array.from(event.target.files));
      }
    }
  };

  return (
    <label htmlFor="contained-button-file">
      <Input
        accept={accept}
        id="contained-button-file"
        multiple
        type="file"
        onChange={onFilesChange}
      />
      <Tooltip title={'Only Admins can upload images'}>
        <Button
          size="small"
          component="span"
          startIcon={<UploadIcon />}
          {...buttonProps}
        >
          {label}
        </Button>
      </Tooltip>
    </label>
  );
};
