import React, { FC } from 'react';
import { TestBaseContext } from './testRunner/state/baseTestContext/context/ContextTypes';
import {
  createFromUrl,
  useSurveyProviderLookupMap,
} from './testRunner/state/TestProviderMap';
import { ParticipantStageContainer } from './testRunner/stage/proband/ParticipantStageContainer';
import { TestType } from './types';
import useIframeListener from './iframe.hook';
import { Box, Button, Typography } from '@mui/material';
import Log from '../logger';
import {
  RunnerExecutionContextProvider,
  useRunnerExecutionContext,
} from './runner-execution-context';
import { useRunnerAppTranslator } from './testRunner/state/baseTestContext/app-configuration/translations/translator.hook';
import { Translations } from './testRunner/state/baseTestContext/app-configuration/translations/app-translations';
import { translationInfoMap } from './testRunner/state/baseTestContext/app-configuration/translations/translation-map';

function SurveyToolWrapper({
  testType,
  testContext,
  iframeHandle,
}: {
  testContext: TestBaseContext;
  testType: TestType;
  iframeHandle: () => void;
}) {
  return (
    <ParticipantStageContainer
      testContext={testContext}
      testType={testType}
      onFinish={() => {
        iframeHandle!();
      }}
    />
  );
}

function StandaloneWrapper({
  testType,
  testContext,
}: {
  testContext: TestBaseContext;
  testType: TestType;
}) {
  return (
    <ParticipantStageContainer
      testContext={testContext}
      testType={testType}
      onFinish={() => {}}
    />
  );
}

const SubjectApp: FC = () => {
  const { testInvocationParams, appConfiguration } =
    useRunnerExecutionContext();
  React.useEffect(() => {
    if (
      translationInfoMap[appConfiguration.translator.languages[0]]
        ?.direction === 'rtl'
    ) {
      document.documentElement.dir = 'rtl';
    }
  }, [appConfiguration.translator.languages]);
  const screenTranslator = useRunnerAppTranslator(
    Translations.App.Errors.TestInitError,
  );
  const iframeHandle = useIframeListener();
  const [testContext, setTestContext] = React.useState<
    TestBaseContext | 'strict-error' | 'error'
  >();
  const surveyProviderLookupMap = useSurveyProviderLookupMap();
  // const urlParams = useParams();

  React.useEffect(() => {
    try {
      if (surveyProviderLookupMap) {
        setTestContext(
          createFromUrl(
            testInvocationParams,
            surveyProviderLookupMap,
            undefined,
            appConfiguration,
          ),
        );
      }
    } catch (e) {
      setTestContext('strict-error');
      Log.error(`Error creating testcontext`, e as Error);
    }
  }, [appConfiguration, surveyProviderLookupMap, testInvocationParams]);
  if (typeof testContext !== 'string' && testContext !== undefined) {
    if (
      testContext.providerContext.providerType === 'survey-tool' /*&&
      iframeHandle*/
    ) {
      return (
        <SurveyToolWrapper
          iframeHandle={iframeHandle!}
          testContext={testContext}
          testType={testContext.testIdentity.testType}
        />
      );
    }
    // @ts-ignore
    if (testContext.providerContext.providerType !== 'survey-tool') {
      return (
        <StandaloneWrapper
          testContext={testContext}
          testType={testContext.testIdentity.testType}
        />
      );
    }
  }
  if (testContext === 'strict-error') {
    return (
      <Box
        display="flex"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {screenTranslator.InvalidProbandID.message
            .mapTranslation((msg) =>
              msg.map((m) => <Typography>{m}</Typography>),
            )
            .flat()}
          <Button
            disabled={!surveyProviderLookupMap}
            onClick={() => {
              try {
                if (surveyProviderLookupMap) {
                  setTestContext(
                    createFromUrl(
                      testInvocationParams,
                      surveyProviderLookupMap,
                      false,
                      appConfiguration,
                    ),
                  );
                }
              } catch (e) {
                setTestContext('error');
                Log.error(
                  `Error creating testcontext from url non-strict`,
                  e as Error,
                );
              }
            }}
          >
            {screenTranslator.InvalidProbandID.button.translate().join(' / ')}
          </Button>
        </Box>
      </Box>
    );
  }
  if (testContext === 'error') {
    return (
      <Box
        display="flex"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        {screenTranslator.InvalidTestURL.mapTranslation((msg) => (
          <Typography>{msg}</Typography>
        ))}
      </Box>
    );
  }
  return <></>;
};

function SubjectAppRuntimeWrapper() {
  return (
    <RunnerExecutionContextProvider>
      <SubjectApp />
    </RunnerExecutionContextProvider>
  );
}
export default SubjectAppRuntimeWrapper;
