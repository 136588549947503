import { Params } from 'react-router-dom';
import { SurveyProviderParamsKeys } from './surveyProviderParams';

/**
 * Wrapper for passing UrlParams or SearchParams.
 * Intended for passing fake params
 */
export interface TestInvocationParams {
  configurationParams: TestRunnerConfigurationParams;
  pathParams: TestPathParams;
}

export type TestPathParamNames = 'testId' | 'testType';
export type TestRunnerConfigurationParamKeys =
  | SurveyProviderParamsKeys
  | 'lang';

export type TestPathParams = Readonly<Record<string, string | undefined>> &
  Readonly<Record<TestPathParamNames, string | undefined>>;

export type TestRunnerConfigurationParams = {
  get(name: string | TestRunnerConfigurationParamKeys): string | null;

  getAll(name: string): string[];

  has(name: TestRunnerConfigurationParamKeys): boolean;
};

export function testInvocationParamsFromBrowserURI(
  urlParams: Readonly<Params>,
  urlSearchParams?: URLSearchParams,
): TestInvocationParams {
  const queryParams =
    urlSearchParams ?? new URLSearchParams(window.location.search);
  return {
    pathParams: urlParams as TestPathParams,
    configurationParams: queryParams,
  };
}

export function createTestInvocationParams({
  configurationParams,
  ...path
}: Record<TestPathParamNames, string> & {
  configurationParams: Record<
    string | TestRunnerConfigurationParamKeys,
    string[] | string | undefined
  >;
}): TestInvocationParams {
  function firstConfigParam(name: string | TestRunnerConfigurationParamKeys) {
    const value = configurationParams[name];
    return Array.isArray(value) ? value[0] : value ?? null;
  }
  function allConfigParam(name: string | TestRunnerConfigurationParamKeys) {
    const value = configurationParams[name];
    if (Array.isArray(value)) {
      return value;
    }
    return value !== undefined ? [value] : [];
  }
  return {
    pathParams: path as TestPathParams,
    configurationParams: {
      get: (name: string | TestRunnerConfigurationParamKeys) =>
        firstConfigParam(name),
      getAll: (name: string | TestRunnerConfigurationParamKeys) =>
        allConfigParam(name),
      has: (name: string | TestRunnerConfigurationParamKeys) =>
        configurationParams[name] !== undefined,
    },
  };
}
