import {
  BlockType,
  FontFace,
  MediaItemSnapshotScope,
  OptionsPosition,
  PodtPreparationScreenInput,
  PodtTargetCategoryInput,
  TestStatus,
  TextAlignment,
} from '../../../../API';
import { PodtCreatorState } from './types';
import {
  createDefaultFontStyle,
  createDefaultStyle,
  createEmptyDefaultFixation,
  createEmptyKeyboardSettings,
  createEmptyMediaItemSnapshot,
  createWeightedStyledTextInput,
} from '../../testAdmin/testEditor/initialEditorStates';
import { Podt } from '../types';

const createEmptyLabeledStimuliPool = (): PodtTargetCategoryInput => ({
  label: '',
  practiceStimuliPool: {
    dangerousStimuli: [],
    nonDangerousStimuli: [],
  },
  testStimuliPool: {
    dangerousStimuli: [],
    nonDangerousStimuli: [],
  },
});

export const getDefaultPreparationScreen = (): PodtPreparationScreenInput => ({
  screen: {
    desktop: '',
  },
  interval: 500,
});

export const podtTemplates: Podt[] = [
  {
    __typename: 'PodtTest',
    id: '123',
    name: 'Shooter task with Arab and White',
    description: 'Shooter task with Arab and White targets',
    instructions: {
      desktop:
        '<p style="text-align:center;"></p>\n<p></p>\n<p></p>\n<p></p>\n<p style="text-align:center;"><span style="background-color: rgb(255,255,255);font-size: 18px;font-family: Mulish;">Nun wollen wir Sie bitten, an einem kognitiven Test  teilzunehmen. </span></p>\n<p style="text-align:center;"><span style="background-color: rgb(255,255,255);font-size: 18px;font-family: Mulish;">Auf der nächsten Seite wird Ihnen erläutert, worum es bei dem Test im Detail geht.</span><br><span style="font-size: 18px;font-family: Mulish;">Zum Starten drücken Sie bitte auf <strong>START</strong>.</span></p>\n',
      mobile:
        '<p style="text-align:center;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 14px;font-family: Mulish;">Nun wollen wir Sie bitten, an einem kognitiven Test  teilzunehmen.</span></p>\n<p style="text-align:center;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 14px;font-family: Mulish;">Auf der nächsten Seite wird Ihnen erläutert, worum es bei dem Test im Detail geht.</span></p>\n<p style="text-align:center;"></p>\n',
      fontStyle: null,
      __typename: 'StyleableTextTemplate',
    },
    keyboard: {
      left: 'KeyA',
      right: 'KeyL',
      confirm: 'Space',
      __typename: 'KeyboardSettings',
    },
    counterbalancing: true,
    probabilityWithoutReplacement: true,
    interTrialInterval: 2000,
    style: {
      __typename: 'TestStyle',
      maxWidth: 80,
      backgroundColour: 'white',
      stimulusTextStyle: {
        fontSize: 40,
        color: 'white',
        alignment: TextAlignment.CENTER,
        fontFace: FontFace.ARIAL,
        __typename: 'FontStyle',
      },
      mobileStimulusTextStyle: null,
      optionsPosition: OptionsPosition.MIDDLE,
      optionsPositionMobile: OptionsPosition.MIDDLE,
    },
    status: TestStatus.DRAFT,
    endScreen: {
      desktop:
        '<p style="text-align:center;"></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;"> Damit ist der kognitive Test beendet.</span></p>\n<p style="text-align:center;"><span style="color: black;background-color: white;font-size: 18px;font-family: Mulish;">Es folgen nun noch einige weitere Fragen zu verschiedenen Themen.</span>&nbsp;</p>\n<p style="text-align:center;"><span style="color: black;background-color: white;font-size: 18px;font-family: Mulish;">Drücken Sie dafür bitte auf <strong>"Weiter"</strong>.</span>&nbsp;</p>\n',
      mobile:
        '<p style="text-align:center;"></p>\n<p style="text-align:center;"><span style="font-size: 14px;font-family: Mulish;"> Damit ist der kognitive Test beendet.</span></p>\n<p style="text-align:center;"><span style="color: black;background-color: white;font-size: 14px;font-family: Mulish;">Es folgen nun noch einige weitere Fragen zu verschiedenen Themen.</span><span style="font-size: 14px;"> </span></p>\n<p style="text-align:center;"><span style="color: black;background-color: white;font-size: 14px;font-family: Mulish;">Drücken Sie dafür bitte auf <strong>"Weiter"</strong>.</span><span style="font-size: 14px;"> </span></p>\n',
      fontStyle: null,
      __typename: 'StyleableTextTemplate',
    },
    response: {
      __typename: 'PodtResponse',
      timeout: 850,
      fontStyle: {
        fontSize: 20,
        color: 'black',
        alignment: TextAlignment.CENTER,
        fontFace: FontFace.ARIAL,
        __typename: 'FontStyle',
      },
      active: {
        id: 'aa279b57-0398-4049-bfbd-989fe599b136',
        tags: [],
        text: 'WAFFE',
        image: null,
        originalFileName: null,
        __typename: 'MediaItemSnapshot',
        scope: MediaItemSnapshotScope.PUBLIC,
      },
      passive: {
        id: '6278fd1c-4d98-4770-8c4c-feb362f52001',
        tags: [],
        text: 'KEINE WAFFE',
        image: null,
        originalFileName: null,
        __typename: 'MediaItemSnapshot',
        scope: MediaItemSnapshotScope.PUBLIC,
      },
    },
    scenes: {
      maxAmountScenesPerTrial: 4,
      __typename: 'PodtScenes',
      scenes: [
        {
          __typename: 'PodtScene',
          mediaItem: {
            id: 'abc148c2-60ab-4a22-a428-8a686bca9af6',
            __typename: 'MediaItemSnapshot',
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
                __typename: 'TagSnapShot',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
                __typename: 'TagSnapShot',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              __typename: 'Image',
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/f1b3239e-ed04-45c1-8791-ef3f79d3bce7.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/f1b3239e-ed04-45c1-8791-ef3f79d3bce7.jpg',
                __typename: 'ImageInstance',
              },
            },
            originalFileName: 'dpd_sc_squ_28.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
        {
          __typename: 'PodtScene',
          mediaItem: {
            id: '4d6b452a-550b-4916-be39-e705f38665b2',
            __typename: 'MediaItemSnapshot',
            scope: MediaItemSnapshotScope.PUBLIC,
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
                __typename: 'TagSnapShot',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
                __typename: 'TagSnapShot',
              },
            ],
            text: null,
            image: {
              __typename: 'Image',
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/19e15666-2615-47a7-aa96-f81129ddc8be.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/19e15666-2615-47a7-aa96-f81129ddc8be.jpg',
                __typename: 'ImageInstance',
              },
            },
            originalFileName: 'dpd_sc_squ_17.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
        {
          mediaItem: {
            id: '54fb5977-8e9a-40b6-acaa-0fb2b547d2a1',
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/5133ce0f-05da-484c-ae3e-a1f2dff571f1.jpg',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/5133ce0f-05da-484c-ae3e-a1f2dff571f1.jpg',
              },
            },
            originalFileName: 'dpd_sc_squ_26.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
        {
          mediaItem: {
            id: '6fcccadc-4624-4c58-945a-5ef79d402000',
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/7e9414a9-a5b8-4161-abb1-4cffa9a57383.jpg',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/7e9414a9-a5b8-4161-abb1-4cffa9a57383.jpg',
              },
            },
            originalFileName: 'dpd_sc_squ_07.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
        {
          mediaItem: {
            id: 'ece1e844-34e4-462c-a634-21b3f22a41f5',
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/730a7fe6-47b1-4a62-9b23-6f59cb9d414f.jpg',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/730a7fe6-47b1-4a62-9b23-6f59cb9d414f.jpg',
              },
            },
            originalFileName: 'dpd_sc_squ_23.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
        {
          mediaItem: {
            id: '66a4b673-de18-4ed0-9026-2429b8331c64',
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/57684f6b-4174-457a-bd42-871e39ad292f.jpg',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/57684f6b-4174-457a-bd42-871e39ad292f.jpg',
              },
            },
            originalFileName: 'dpd_sc_squ_15.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
        {
          mediaItem: {
            id: '723d4610-f802-4466-92b7-35470c541c5e',
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/514a1c95-32d7-447e-954c-c2a6a0e1c32e.jpg',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/514a1c95-32d7-447e-954c-c2a6a0e1c32e.jpg',
              },
            },
            originalFileName: 'dpd_sc_squ_14.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
        {
          mediaItem: {
            id: '2c77f48c-dd55-435a-a724-455dac3ae15e',
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/61858abf-2433-4435-b2bd-751c3825c915.jpg',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/61858abf-2433-4435-b2bd-751c3825c915.jpg',
              },
            },
            originalFileName: 'dpd_sc_squ_19.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
        {
          mediaItem: {
            id: '5c7b875d-c743-4db0-beb9-cf8224c704ba',
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/94b2e25c-11c6-4b2c-b716-53dbbc0ac6da.jpg',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/94b2e25c-11c6-4b2c-b716-53dbbc0ac6da.jpg',
              },
            },
            originalFileName: 'dpd_sc_squ_04.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
        {
          mediaItem: {
            id: 'b1504b64-45e8-4f78-8f6d-9028423292cd',
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/7c7ed884-3352-42ca-806e-88de14f0d4a3.jpg',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/7c7ed884-3352-42ca-806e-88de14f0d4a3.jpg',
              },
            },
            originalFileName: 'dpd_sc_squ_44.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
        {
          mediaItem: {
            id: '7f3fc993-df25-42b6-8e84-b96413733847',
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/48f2a091-06e0-4d83-aae6-b21e34fdf68b.jpg',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/48f2a091-06e0-4d83-aae6-b21e34fdf68b.jpg',
              },
            },
            originalFileName: 'dpd_sc_squ_10.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
        {
          mediaItem: {
            id: 'fd5cd1b6-0307-4ee7-972e-ccc720c22100',
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/f28c5fdb-9e29-4a8d-ac7b-ee3835c4db3e.jpg',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/f28c5fdb-9e29-4a8d-ac7b-ee3835c4db3e.jpg',
              },
            },
            originalFileName: 'dpd_sc_squ_05.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
        {
          mediaItem: {
            id: '5dc1eb6e-a258-4b4f-b7f6-d2aa63b70114',
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/cacfdcd4-2e10-4e88-9f21-b39b993de286.jpg',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/cacfdcd4-2e10-4e88-9f21-b39b993de286.jpg',
              },
            },
            originalFileName: 'dpd_sc_squ_27.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
        {
          mediaItem: {
            id: 'd25e66d8-0c3a-4025-9381-0b1009081839',
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/8df3cd02-7bb9-49a4-ba67-1cd557fd4895.jpg',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/8df3cd02-7bb9-49a4-ba67-1cd557fd4895.jpg',
              },
            },
            originalFileName: 'dpd_sc_squ_29.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
        {
          mediaItem: {
            id: 'b550091d-1aa3-47da-a017-dabd212de445',
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/8c84c6a0-f463-4e19-a1cd-d418fb606a7f.jpg',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/8c84c6a0-f463-4e19-a1cd-d418fb606a7f.jpg',
              },
            },
            originalFileName: 'dpd_sc_squ_22.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
        {
          mediaItem: {
            id: '8817659c-d8f8-4358-beea-fb067120d64a',
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/e4808829-0569-4d44-8ead-223668c68069.jpg',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/e4808829-0569-4d44-8ead-223668c68069.jpg',
              },
            },
            originalFileName: 'dpd_sc_squ_42.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
        {
          mediaItem: {
            id: '62a96ff5-0682-44cf-a03b-25f17e6614c9',
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/98c86bc1-b0e0-4ea5-a1a0-c10f13a37978.jpg',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/98c86bc1-b0e0-4ea5-a1a0-c10f13a37978.jpg',
              },
            },
            originalFileName: 'dpd_sc_squ_09.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
        {
          mediaItem: {
            id: 'ab0b28e2-b8a3-4f14-b253-3a26d28125b5',
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/dc41ec64-d548-4aed-9bf2-91c8f1ff235b.jpg',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/dc41ec64-d548-4aed-9bf2-91c8f1ff235b.jpg',
              },
            },
            originalFileName: 'dpd_sc_squ_25.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
        {
          mediaItem: {
            id: '54186a77-c5cd-4166-8070-e0d21aa3fb0b',
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/b234476c-bd40-4b53-bc38-ffd2c49033eb.jpg',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/b234476c-bd40-4b53-bc38-ffd2c49033eb.jpg',
              },
            },
            originalFileName: 'dpd_sc_squ_37.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
        {
          mediaItem: {
            id: 'a0b9607b-7ec8-4d7b-a96e-6b0f4519580d',
            tags: [
              {
                id: '63271418-b491-4f7f-b4f6-2de579d44e55',
                name: 'square',
              },
              {
                id: 'c36509be-6eb3-4831-b90e-7bf17ff8f921',
                name: 'scene',
              },
            ],
            text: null,
            scope: MediaItemSnapshotScope.PUBLIC,
            image: {
              fullSize: {
                width: 1920,
                height: 1280,
                key: 'fullSize/9b764035-1426-4fad-82a0-abadf4cff2de.jpg',
              },
              thumbNail: {
                width: 80,
                height: 53,
                key: 'thumbNail/9b764035-1426-4fad-82a0-abadf4cff2de.jpg',
              },
            },
            originalFileName: 'dpd_sc_squ_11.jpg',
          },
          boxes: [
            {
              top: 0,
              left: 330,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
            {
              top: 88,
              left: 328,
              width: 1215,
              height: 1192,
              __typename: 'PodtBox',
            },
          ],
        },
      ],
      intervalConfig: {
        min: 500,
        max: 1000,
        stepSize: 100,
      },
    },
    targets: [
      {
        label: 'White',
        practiceStimuliPool: {
          dangerousStimuli: [
            {
              id: 'd243832d-e34a-4a38-81a9-c07e41345738',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                  name: 'practise',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 251,
                  height: 800,
                  key: 'fullSize/d8cd768e-90cc-4238-8803-50befb9e20c6.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/d8cd768e-90cc-4238-8803-50befb9e20c6.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_08.png',
            },
            {
              id: 'fe37bdd1-1a75-4904-a551-2827a553d460',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                  name: 'practise',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 251,
                  height: 800,
                  key: 'fullSize/264f464e-8da4-4ba3-bfc3-862203db7753.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/264f464e-8da4-4ba3-bfc3-862203db7753.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_02.png',
            },
          ],
          nonDangerousStimuli: [
            {
              id: 'f6bedad1-8d5a-4be8-8c2f-8ebb04666f32',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
                {
                  id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                  name: 'practise',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 251,
                  height: 800,
                  key: 'fullSize/ff144fc9-fa10-425b-97df-5e8ce6778866.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/ff144fc9-fa10-425b-97df-5e8ce6778866.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_08.png',
            },
            {
              id: 'd0de2b56-f0cd-42ec-9bdd-5fb82dbba350',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
                {
                  id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                  name: 'practise',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 251,
                  height: 800,
                  key: 'fullSize/8da8c4f5-5b52-4534-abae-da73caa6ad66.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/8da8c4f5-5b52-4534-abae-da73caa6ad66.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_02.png',
            },
          ],
        },
        testStimuliPool: {
          dangerousStimuli: [
            {
              id: 'ce59ccf2-0e8e-48bf-a83c-248e981aaaa1',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 261,
                  height: 800,
                  key: 'fullSize/742f367f-6900-4207-872e-fd73b95312ca.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/742f367f-6900-4207-872e-fd73b95312ca.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_04.png',
            },
            {
              id: '64e38ad3-b0c6-4bf4-8a71-eab30ab276d1',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 255,
                  height: 800,
                  key: 'fullSize/974248a9-a62b-4103-902c-a396d92a81b4.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/974248a9-a62b-4103-902c-a396d92a81b4.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_03.png',
            },
            {
              id: '0d48c140-289d-401d-afa1-b08e83d82714',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 254,
                  height: 800,
                  key: 'fullSize/64a4f5c5-15c6-4fdf-a998-e33317652e17.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/64a4f5c5-15c6-4fdf-a998-e33317652e17.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_25.png',
            },
            {
              id: '83e83c3a-0a21-429e-a490-b9b8fe5bd234',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 243,
                  height: 800,
                  key: 'fullSize/1e924020-b7a0-42bd-80e2-b1a7e7ad191d.png',
                },
                thumbNail: {
                  width: 24,
                  height: 80,
                  key: 'thumbNail/1e924020-b7a0-42bd-80e2-b1a7e7ad191d.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_09.png',
            },
            {
              id: '6f9a34f7-e953-420e-aa58-952cb494c834',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 250,
                  height: 800,
                  key: 'fullSize/15d73189-d2b3-408c-ace1-362268ac60ad.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/15d73189-d2b3-408c-ace1-362268ac60ad.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_06.png',
            },
            {
              id: 'c635c42a-b173-4138-9bb6-db9912832d68',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 265,
                  height: 800,
                  key: 'fullSize/6405f017-8113-4b01-8da1-4ba46eae2e77.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/6405f017-8113-4b01-8da1-4ba46eae2e77.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_17.png',
            },
            {
              id: '902a5b8c-6fab-4123-80f8-317e55ba1d00',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 247,
                  height: 800,
                  key: 'fullSize/9f30b3d6-4cc2-409b-9306-ebe8467cc199.png',
                },
                thumbNail: {
                  width: 24,
                  height: 80,
                  key: 'thumbNail/9f30b3d6-4cc2-409b-9306-ebe8467cc199.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_07.png',
            },
            {
              id: 'dc8bd7bd-0374-4bb2-9902-e7779941409b',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 301,
                  height: 800,
                  key: 'fullSize/352deb1e-636a-40fe-8938-c5b195bb2219.png',
                },
                thumbNail: {
                  width: 30,
                  height: 80,
                  key: 'thumbNail/352deb1e-636a-40fe-8938-c5b195bb2219.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_22.png',
            },
            {
              id: 'dd2ee5c9-f992-41c5-99d7-970dbe9f7d73',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 264,
                  height: 800,
                  key: 'fullSize/4730bf19-11ff-4db3-bd5a-9828310c35ad.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/4730bf19-11ff-4db3-bd5a-9828310c35ad.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_15.png',
            },
            {
              id: '15f6b71b-e7fd-4ad4-be1d-2e45b5c4832a',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 241,
                  height: 800,
                  key: 'fullSize/718a73b6-81f0-4637-8784-1d6fc22a867a.png',
                },
                thumbNail: {
                  width: 24,
                  height: 80,
                  key: 'thumbNail/718a73b6-81f0-4637-8784-1d6fc22a867a.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_13.png',
            },
            {
              id: '8f9a28ad-38ac-4bfc-8d91-9ea39b242006',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 242,
                  height: 800,
                  key: 'fullSize/56e784d6-4776-4bac-be90-411d2103b512.png',
                },
                thumbNail: {
                  width: 24,
                  height: 80,
                  key: 'thumbNail/56e784d6-4776-4bac-be90-411d2103b512.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_01.png',
            },
            {
              id: '173c9ed2-1fa9-405b-a032-dbd3229bb27a',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 248,
                  height: 800,
                  key: 'fullSize/703b67d7-9a41-402c-af6a-2755e7640936.png',
                },
                thumbNail: {
                  width: 24,
                  height: 80,
                  key: 'thumbNail/703b67d7-9a41-402c-af6a-2755e7640936.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_10.png',
            },
            {
              id: '286dfbe3-b7d5-4a6f-adfd-8a7b1b71396a',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 251,
                  height: 800,
                  key: 'fullSize/47ba5463-59be-40d8-aded-b5531bc3fdde.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/47ba5463-59be-40d8-aded-b5531bc3fdde.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_20.png',
            },
            {
              id: '83883812-0eed-4f75-9d71-518a828fcfba',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 239,
                  height: 800,
                  key: 'fullSize/cbc52069-962a-4113-a15a-381fa14a252d.png',
                },
                thumbNail: {
                  width: 23,
                  height: 80,
                  key: 'thumbNail/cbc52069-962a-4113-a15a-381fa14a252d.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_24.png',
            },
            {
              id: '3f78fd03-a983-490e-b764-88873c7a7730',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 242,
                  height: 800,
                  key: 'fullSize/22428ec0-d352-4cc8-ba44-0fab4b6469c5.png',
                },
                thumbNail: {
                  width: 24,
                  height: 80,
                  key: 'thumbNail/22428ec0-d352-4cc8-ba44-0fab4b6469c5.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_14.png',
            },
            {
              id: '059f149d-b9a7-4d4c-a96c-d150148de199',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 269,
                  height: 800,
                  key: 'fullSize/f1af16e3-bc8a-4a81-8414-b2aa92596ff2.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/f1af16e3-bc8a-4a81-8414-b2aa92596ff2.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_19.png',
            },
            {
              id: '18f919b3-7c74-4251-8f8d-b9b64be6117d',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 281,
                  height: 800,
                  key: 'fullSize/448904bc-888c-4890-8eaf-c0bc94da247d.png',
                },
                thumbNail: {
                  width: 28,
                  height: 80,
                  key: 'thumbNail/448904bc-888c-4890-8eaf-c0bc94da247d.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_18.png',
            },
            {
              id: '99ae7559-10ad-4ad2-878a-c0d7723b102c',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 246,
                  height: 800,
                  key: 'fullSize/41a43852-7876-4c0f-9a52-354c24acd257.png',
                },
                thumbNail: {
                  width: 24,
                  height: 80,
                  key: 'thumbNail/41a43852-7876-4c0f-9a52-354c24acd257.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_11.png',
            },
            {
              id: '1d0b5d66-71fc-4233-b98a-dce4e46ec876',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 294,
                  height: 800,
                  key: 'fullSize/f1f00e51-2502-478e-bbb1-e04dcdb451ed.png',
                },
                thumbNail: {
                  width: 29,
                  height: 80,
                  key: 'thumbNail/f1f00e51-2502-478e-bbb1-e04dcdb451ed.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_21.png',
            },
            {
              id: '4cdef68a-8a35-492b-873f-3ecaaae0b7a5',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 290,
                  height: 800,
                  key: 'fullSize/a32fd018-587e-4f25-9506-3fdc5c0dc4fe.png',
                },
                thumbNail: {
                  width: 29,
                  height: 80,
                  key: 'thumbNail/a32fd018-587e-4f25-9506-3fdc5c0dc4fe.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_02_05.png',
            },
          ],
          nonDangerousStimuli: [
            {
              id: '71d4c140-aacf-4c12-b8bc-e5bb010fe71d',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 255,
                  height: 800,
                  key: 'fullSize/b40ab6dc-19c1-4c15-bc52-c9204706f558.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/b40ab6dc-19c1-4c15-bc52-c9204706f558.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_03.png',
            },
            {
              id: '6ba29cf6-8ef5-41e6-a69c-746a0e109233',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 261,
                  height: 800,
                  key: 'fullSize/3dc5a526-1a77-4103-83a7-14e0ea729072.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/3dc5a526-1a77-4103-83a7-14e0ea729072.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_04.png',
            },
            {
              id: '333df582-bfe2-4140-8d47-4e097fbdf486',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 241,
                  height: 800,
                  key: 'fullSize/0a42bcdf-5a19-482c-8e79-a310ac5348d4.png',
                },
                thumbNail: {
                  width: 24,
                  height: 80,
                  key: 'thumbNail/0a42bcdf-5a19-482c-8e79-a310ac5348d4.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_13.png',
            },
            {
              id: '2529ce2f-e46b-409d-b136-9c114a95e137',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 264,
                  height: 800,
                  key: 'fullSize/21640b32-7e3d-4f91-884b-bcabba7322f7.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/21640b32-7e3d-4f91-884b-bcabba7322f7.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_15.png',
            },
            {
              id: 'e725e27b-7d38-4b83-b1bd-82a4911a354e',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 247,
                  height: 800,
                  key: 'fullSize/c5a10834-390e-40c9-b424-e556870edbc1.png',
                },
                thumbNail: {
                  width: 24,
                  height: 80,
                  key: 'thumbNail/c5a10834-390e-40c9-b424-e556870edbc1.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_07.png',
            },
            {
              id: '135b0404-a792-4f28-a8c7-1986750ee818',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 265,
                  height: 800,
                  key: 'fullSize/2e3f1b5e-2b33-4bc3-9f43-0cd6aabf39ba.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/2e3f1b5e-2b33-4bc3-9f43-0cd6aabf39ba.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_17.png',
            },
            {
              id: '57d0d6f7-7267-4577-b445-6a5911bd82c6',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 301,
                  height: 800,
                  key: 'fullSize/43bd9674-422e-4135-be73-87a8e18d73d9.png',
                },
                thumbNail: {
                  width: 30,
                  height: 80,
                  key: 'thumbNail/43bd9674-422e-4135-be73-87a8e18d73d9.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_22.png',
            },
            {
              id: '46673420-63f8-46dc-86d4-e2bb6ff0597c',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 254,
                  height: 800,
                  key: 'fullSize/c89360ff-7fab-409d-94d5-9371405f17c5.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/c89360ff-7fab-409d-94d5-9371405f17c5.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_25.png',
            },
            {
              id: '0c41ecff-5294-4706-a980-6074e2e212be',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 250,
                  height: 800,
                  key: 'fullSize/cdce2829-e349-4f6e-9390-9fe3ea0d4aa0.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/cdce2829-e349-4f6e-9390-9fe3ea0d4aa0.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_06.png',
            },
            {
              id: '2eec560e-88da-4e31-8a24-3dcd7ddea7cb',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 243,
                  height: 800,
                  key: 'fullSize/e2013f13-2c91-4735-b6e7-265fa62faf50.png',
                },
                thumbNail: {
                  width: 24,
                  height: 80,
                  key: 'thumbNail/e2013f13-2c91-4735-b6e7-265fa62faf50.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_09.png',
            },
            {
              id: 'e749998e-fefa-41f2-8511-2fa91e098592',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 248,
                  height: 800,
                  key: 'fullSize/0febc083-039c-4a8f-aff9-8eba29ed4032.png',
                },
                thumbNail: {
                  width: 24,
                  height: 80,
                  key: 'thumbNail/0febc083-039c-4a8f-aff9-8eba29ed4032.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_10.png',
            },
            {
              id: '04b544e2-235f-4507-b054-6be6569d64ee',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 242,
                  height: 800,
                  key: 'fullSize/a5a8c61f-8a67-48eb-b251-dab0187a2eff.png',
                },
                thumbNail: {
                  width: 24,
                  height: 80,
                  key: 'thumbNail/a5a8c61f-8a67-48eb-b251-dab0187a2eff.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_14.png',
            },
            {
              id: '4a0bbd69-8d94-41bd-8ae8-226cfcb1557b',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 294,
                  height: 800,
                  key: 'fullSize/48944162-ed0e-4c1b-8ded-4763a283f8de.png',
                },
                thumbNail: {
                  width: 29,
                  height: 80,
                  key: 'thumbNail/48944162-ed0e-4c1b-8ded-4763a283f8de.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_21.png',
            },
            {
              id: 'f3b396dc-8ffb-4bf2-a739-d036a8a725b0',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 251,
                  height: 800,
                  key: 'fullSize/cfeb6398-444d-4f52-bb5e-d20089f471f9.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/cfeb6398-444d-4f52-bb5e-d20089f471f9.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_20.png',
            },
            {
              id: '455c12f7-2c1a-418c-ad30-bdd124b3c7d9',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 242,
                  height: 800,
                  key: 'fullSize/2159a6d1-e912-4a4f-9021-725f156171a1.png',
                },
                thumbNail: {
                  width: 24,
                  height: 80,
                  key: 'thumbNail/2159a6d1-e912-4a4f-9021-725f156171a1.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_01.png',
            },
            {
              id: '5ea92b74-f968-426e-9f57-99ce67b78bbc',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 281,
                  height: 800,
                  key: 'fullSize/801dabcf-88dc-43a8-b2d0-acd736f467c2.png',
                },
                thumbNail: {
                  width: 28,
                  height: 80,
                  key: 'thumbNail/801dabcf-88dc-43a8-b2d0-acd736f467c2.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_18.png',
            },
            {
              id: '8948d6bc-7b2a-484b-b0d1-757ad561e0e4',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 290,
                  height: 800,
                  key: 'fullSize/da2b5dae-9937-4b78-a867-63f9799e1622.png',
                },
                thumbNail: {
                  width: 29,
                  height: 80,
                  key: 'thumbNail/da2b5dae-9937-4b78-a867-63f9799e1622.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_05.png',
            },
            {
              id: 'c5327d9e-2050-4a28-9947-71c1daaa7c3f',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 246,
                  height: 800,
                  key: 'fullSize/7893e21f-5607-44c0-bd67-0587aa193bb6.png',
                },
                thumbNail: {
                  width: 24,
                  height: 80,
                  key: 'thumbNail/7893e21f-5607-44c0-bd67-0587aa193bb6.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_11.png',
            },
            {
              id: '3d62a7b8-897d-4e66-9c10-e7945fbe88f5',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 239,
                  height: 800,
                  key: 'fullSize/85c6b93c-393e-4a86-8b7c-445854788907.png',
                },
                thumbNail: {
                  width: 23,
                  height: 80,
                  key: 'thumbNail/85c6b93c-393e-4a86-8b7c-445854788907.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_24.png',
            },
            {
              id: '48ce5c6d-29c0-451e-8c9e-4b6acb48c93f',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 269,
                  height: 800,
                  key: 'fullSize/cede9e29-d83d-468b-9c33-22103cf45293.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/cede9e29-d83d-468b-9c33-22103cf45293.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_02_19.png',
            },
          ],
        },
      },
      {
        label: 'Arab',
        practiceStimuliPool: {
          dangerousStimuli: [
            {
              id: 'a69ad042-50bd-4dc4-8a74-f7db005dbb54',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                  name: 'practise',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 272,
                  height: 800,
                  key: 'fullSize/3619c0bd-3f63-4d5b-8409-7d4dba61636b.png',
                },
                thumbNail: {
                  width: 27,
                  height: 80,
                  key: 'thumbNail/3619c0bd-3f63-4d5b-8409-7d4dba61636b.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_11.png',
            },
            {
              id: 'c62ecda6-108d-455c-b2b4-dec754c4ac37',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                  name: 'practise',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 252,
                  height: 800,
                  key: 'fullSize/ad4f9c46-ffa1-4eed-b7d8-096e27ec2663.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/ad4f9c46-ffa1-4eed-b7d8-096e27ec2663.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_13.png',
            },
          ],
          nonDangerousStimuli: [
            {
              id: '821684f1-50ac-45fc-a4c0-981ccb67fe93',
              tags: [
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
                {
                  id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                  name: 'practise',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 272,
                  height: 800,
                  key: 'fullSize/aea1c83f-a605-43f6-b044-e3707f0954c5.png',
                },
                thumbNail: {
                  width: 27,
                  height: 80,
                  key: 'thumbNail/aea1c83f-a605-43f6-b044-e3707f0954c5.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_11.png',
            },
            {
              id: '4cfd5361-77d4-4e8a-a130-5e050747e572',
              tags: [
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
                {
                  id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                  name: 'practise',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 252,
                  height: 800,
                  key: 'fullSize/c55b979b-bd98-49a1-9bac-b0b330195575.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/c55b979b-bd98-49a1-9bac-b0b330195575.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_13.png',
            },
          ],
        },
        testStimuliPool: {
          dangerousStimuli: [
            {
              id: 'ef5cbeed-fffe-40b3-a728-b7c78b7688dc',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 261,
                  height: 800,
                  key: 'fullSize/cf6852d5-0664-4087-845a-e3c65ecf5974.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/cf6852d5-0664-4087-845a-e3c65ecf5974.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_03.png',
            },
            {
              id: 'a23860a7-7b57-4b73-8488-19f49402d807',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 266,
                  height: 800,
                  key: 'fullSize/e8c2b9c4-2e6e-4dba-9eca-3440fdb63c8b.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/e8c2b9c4-2e6e-4dba-9eca-3440fdb63c8b.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_05.png',
            },
            {
              id: '182e7279-eb09-4f43-a3fe-986e7970e0a6',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 259,
                  height: 800,
                  key: 'fullSize/542a73bb-de28-4df9-a9ca-c2e930d9047c.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/542a73bb-de28-4df9-a9ca-c2e930d9047c.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_07.png',
            },
            {
              id: '78d01b3e-3d81-47ba-b14a-67499b70393d',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 310,
                  height: 800,
                  key: 'fullSize/150fe3b3-0d88-441f-b913-9d42a8f8f500.png',
                },
                thumbNail: {
                  width: 31,
                  height: 80,
                  key: 'thumbNail/150fe3b3-0d88-441f-b913-9d42a8f8f500.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_15.png',
            },
            {
              id: '37e0f41f-fa6d-4a0c-b3b2-896bf4fc267e',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 262,
                  height: 800,
                  key: 'fullSize/9df29f06-8661-48fb-80e6-25c89dfec11a.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/9df29f06-8661-48fb-80e6-25c89dfec11a.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_22.png',
            },
            {
              id: '17bda75b-5ec0-45b4-9c22-777ed8c764a9',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 268,
                  height: 800,
                  key: 'fullSize/050f96c0-2a6a-41b2-a62f-f7a0d3c0f590.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/050f96c0-2a6a-41b2-a62f-f7a0d3c0f590.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_01.png',
            },
            {
              id: 'c6fbc7a3-2d9a-42b5-b5f6-171510c6db88',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 261,
                  height: 800,
                  key: 'fullSize/37e391d1-0df7-4f3c-bd12-7a2036ea5db5.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/37e391d1-0df7-4f3c-bd12-7a2036ea5db5.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_02.png',
            },
            {
              id: 'a4f31c55-75de-46b5-b340-5be0111de1d8',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 301,
                  height: 800,
                  key: 'fullSize/4afcb9ba-387c-49d7-abc2-e830a346b3dd.png',
                },
                thumbNail: {
                  width: 30,
                  height: 80,
                  key: 'thumbNail/4afcb9ba-387c-49d7-abc2-e830a346b3dd.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_06.png',
            },
            {
              id: '5f237cb9-2724-46df-96ab-5179b94319f5',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 260,
                  height: 800,
                  key: 'fullSize/80548c7e-6026-4d73-a7e5-55d8a5361ec5.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/80548c7e-6026-4d73-a7e5-55d8a5361ec5.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_14.png',
            },
            {
              id: 'e2816e10-79b5-4bfc-a215-0bf9738f02fd',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 252,
                  height: 800,
                  key: 'fullSize/f73597a5-e5dc-4f5a-89c1-c8012063bd83.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/f73597a5-e5dc-4f5a-89c1-c8012063bd83.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_04.png',
            },
            {
              id: '405fd5f3-c11f-4092-bb8c-3ba7384fb6b1',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 267,
                  height: 800,
                  key: 'fullSize/51090ba3-c656-4d78-bd82-f4b8665c3728.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/51090ba3-c656-4d78-bd82-f4b8665c3728.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_21.png',
            },
            {
              id: '60a9e669-4c35-4ad2-8c7c-5e0878ca9bcc',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 264,
                  height: 800,
                  key: 'fullSize/d6977b92-11df-4cde-a95b-9b6616e21d94.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/d6977b92-11df-4cde-a95b-9b6616e21d94.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_10.png',
            },
            {
              id: '68b9cda3-40a4-4c18-9383-a36df486fa97',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 274,
                  height: 800,
                  key: 'fullSize/f155a611-d7fc-4fab-98aa-86edcfee06c3.png',
                },
                thumbNail: {
                  width: 27,
                  height: 80,
                  key: 'thumbNail/f155a611-d7fc-4fab-98aa-86edcfee06c3.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_17.png',
            },
            {
              id: 'a75f8627-33f1-4afa-9b71-41268c76ce96',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 295,
                  height: 800,
                  key: 'fullSize/1b857c3b-18fc-40cd-8f0d-a449ef79c02d.png',
                },
                thumbNail: {
                  width: 29,
                  height: 80,
                  key: 'thumbNail/1b857c3b-18fc-40cd-8f0d-a449ef79c02d.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_08.png',
            },
            {
              id: 'aa9c09d3-22aa-460f-8fc1-316c039909c1',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 238,
                  height: 800,
                  key: 'fullSize/a5c5789d-7139-47bb-a7f8-5f95ccddbcf8.png',
                },
                thumbNail: {
                  width: 23,
                  height: 80,
                  key: 'thumbNail/a5c5789d-7139-47bb-a7f8-5f95ccddbcf8.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_16.png',
            },
            {
              id: 'dc9d9f7b-a7b4-49ab-8d58-1a725219ac45',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 258,
                  height: 800,
                  key: 'fullSize/bbcff0c7-964e-432e-a22a-a58b796e34a1.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/bbcff0c7-964e-432e-a22a-a58b796e34a1.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_19.png',
            },
            {
              id: 'df52972d-4dd5-4eed-b4fe-438430712a04',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 249,
                  height: 800,
                  key: 'fullSize/fa802276-ca14-4922-b243-4c03b990ff9b.png',
                },
                thumbNail: {
                  width: 24,
                  height: 80,
                  key: 'thumbNail/fa802276-ca14-4922-b243-4c03b990ff9b.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_20.png',
            },
            {
              id: 'eeb6b89f-06bb-4c04-83ea-7fd3322c3b25',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 305,
                  height: 800,
                  key: 'fullSize/b3da69d1-8621-4cd7-a755-b0f86289daeb.png',
                },
                thumbNail: {
                  width: 30,
                  height: 80,
                  key: 'thumbNail/b3da69d1-8621-4cd7-a755-b0f86289daeb.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_09.png',
            },
            {
              id: '70cf2642-e1b8-4c6b-81a3-2147a8f7c373',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 276,
                  height: 800,
                  key: 'fullSize/b8129bb4-56e7-4bcd-86a4-3c022edc3ed6.png',
                },
                thumbNail: {
                  width: 27,
                  height: 80,
                  key: 'thumbNail/b8129bb4-56e7-4bcd-86a4-3c022edc3ed6.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_18.png',
            },
            {
              id: '5a4a46bf-b01d-471c-943a-721431db7d78',
              tags: [
                {
                  id: '0863d5c0-9404-450a-bd1e-564e5d2e6e1a',
                  name: 'Armed',
                },
                {
                  id: '23aac774-872a-47ee-9fa4-c4bf1dec35fc',
                  name: 'Gun',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 246,
                  height: 800,
                  key: 'fullSize/34b10f06-5574-42cf-ae74-4ab7df76d4d0.png',
                },
                thumbNail: {
                  width: 24,
                  height: 80,
                  key: 'thumbNail/34b10f06-5574-42cf-ae74-4ab7df76d4d0.png',
                },
              },
              originalFileName: 'dpd_fb_arm_gu_ma_01_12.png',
            },
          ],
          nonDangerousStimuli: [
            {
              id: '2f956f29-c690-4137-a73f-859dfee52696',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 267,
                  height: 800,
                  key: 'fullSize/61220baa-3c24-48d1-9c67-6f2fb84638c3.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/61220baa-3c24-48d1-9c67-6f2fb84638c3.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_21_corr.png',
            },
            {
              id: '6648319e-b960-4c49-8718-54fd29ec96e3',
              tags: [
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 310,
                  height: 800,
                  key: 'fullSize/a7c67ca8-18f6-4267-8ea4-9fbe04a673b2.png',
                },
                thumbNail: {
                  width: 31,
                  height: 80,
                  key: 'thumbNail/a7c67ca8-18f6-4267-8ea4-9fbe04a673b2.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_15.png',
            },
            {
              id: '30b0b1be-1ab5-4f3f-94a9-a7bc9ba2885a',
              tags: [
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 261,
                  height: 800,
                  key: 'fullSize/b623303c-1788-4b68-ab92-feab0cd78b46.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/b623303c-1788-4b68-ab92-feab0cd78b46.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_02.png',
            },
            {
              id: '9585d17e-ec8b-4ee8-ad68-d6431c69eac7',
              tags: [
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 259,
                  height: 800,
                  key: 'fullSize/41550fa8-c8f1-4197-b68d-9e1d1ec10514.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/41550fa8-c8f1-4197-b68d-9e1d1ec10514.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_07.png',
            },
            {
              id: '8ed0f96f-04e8-4147-8f2c-a5426e52b75e',
              tags: [
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 301,
                  height: 800,
                  key: 'fullSize/d2b2277e-bd47-4e65-b841-65416997f2d2.png',
                },
                thumbNail: {
                  width: 30,
                  height: 80,
                  key: 'thumbNail/d2b2277e-bd47-4e65-b841-65416997f2d2.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_06.png',
            },
            {
              id: '95b90ad6-112f-4da9-b0dd-f8c1ca0b3e86',
              tags: [
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 266,
                  height: 800,
                  key: 'fullSize/75deabca-343a-467d-99a3-2a081d8a314b.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/75deabca-343a-467d-99a3-2a081d8a314b.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_05.png',
            },
            {
              id: 'd59dfc13-e75c-4b10-ba33-60621bdc76c7',
              tags: [
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 252,
                  height: 800,
                  key: 'fullSize/572916e6-bd69-44e2-9269-caa30094ac1d.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/572916e6-bd69-44e2-9269-caa30094ac1d.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_04.png',
            },
            {
              id: '50691dfd-09bf-4d6c-bc24-038828a111cd',
              tags: [
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 261,
                  height: 800,
                  key: 'fullSize/b2ddcb3d-7bc1-4c81-97e5-80dd3947f251.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/b2ddcb3d-7bc1-4c81-97e5-80dd3947f251.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_03.png',
            },
            {
              id: '97310998-df54-466f-928b-c28deb42f9d8',
              tags: [
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 260,
                  height: 800,
                  key: 'fullSize/11335d6c-bb81-4765-8c1e-83eaf1d4be4c.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/11335d6c-bb81-4765-8c1e-83eaf1d4be4c.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_14.png',
            },
            {
              id: '06eb4f8d-ee56-4b30-919f-b27a8427743d',
              tags: [
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 262,
                  height: 800,
                  key: 'fullSize/cec359c8-a58b-4517-ab5e-e50a51d96452.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/cec359c8-a58b-4517-ab5e-e50a51d96452.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_22.png',
            },
            {
              id: 'e4924958-8656-4b79-856a-9d41ff72494a',
              tags: [
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 268,
                  height: 800,
                  key: 'fullSize/5f301a92-212f-462d-9804-ce5d82adf999.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/5f301a92-212f-462d-9804-ce5d82adf999.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_01.png',
            },
            {
              id: '56ef0020-4ec7-471c-b5de-1f9edb00448b',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 246,
                  height: 800,
                  key: 'fullSize/fe9cf92c-99ef-4b4d-838a-cbeabbfb2af1.png',
                },
                thumbNail: {
                  width: 24,
                  height: 80,
                  key: 'thumbNail/fe9cf92c-99ef-4b4d-838a-cbeabbfb2af1.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_12.png',
            },
            {
              id: '5a4c6571-b8e3-44d7-bc37-60fc1262c225',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 276,
                  height: 800,
                  key: 'fullSize/ebe2915f-1591-4b7d-89fd-aa6e16a219a0.png',
                },
                thumbNail: {
                  width: 27,
                  height: 80,
                  key: 'thumbNail/ebe2915f-1591-4b7d-89fd-aa6e16a219a0.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_18.png',
            },
            {
              id: 'c492f98b-3613-4513-9f0d-bea55e4e248c',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 249,
                  height: 800,
                  key: 'fullSize/9ac503bf-5015-44f7-963b-62c5455e1af7.png',
                },
                thumbNail: {
                  width: 24,
                  height: 80,
                  key: 'thumbNail/9ac503bf-5015-44f7-963b-62c5455e1af7.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_20.png',
            },
            {
              id: '3a6b5aba-cb9b-4af7-a526-aae2b6cc994b',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 264,
                  height: 800,
                  key: 'fullSize/d73fb5f8-f558-476d-a2d2-6ef9161cc455.png',
                },
                thumbNail: {
                  width: 26,
                  height: 80,
                  key: 'thumbNail/d73fb5f8-f558-476d-a2d2-6ef9161cc455.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_10.png',
            },
            {
              id: 'ec259201-f0b2-41b7-ad77-ba11fb48718f',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 305,
                  height: 800,
                  key: 'fullSize/d0d1410f-7da5-4340-b1bb-a94c1836f73c.png',
                },
                thumbNail: {
                  width: 30,
                  height: 80,
                  key: 'thumbNail/d0d1410f-7da5-4340-b1bb-a94c1836f73c.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_09.png',
            },
            {
              id: 'd5ba2dcf-2c95-4888-9e0d-00e51d75c447',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 274,
                  height: 800,
                  key: 'fullSize/d3cba741-d1d0-4ea0-b84c-2b733fedcd00.png',
                },
                thumbNail: {
                  width: 27,
                  height: 80,
                  key: 'thumbNail/d3cba741-d1d0-4ea0-b84c-2b733fedcd00.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_17.png',
            },
            {
              id: 'ec4be2cf-9541-4184-8124-9e956229aeb5',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 258,
                  height: 800,
                  key: 'fullSize/b26a7d35-a618-4239-a312-6dba8e437e0d.png',
                },
                thumbNail: {
                  width: 25,
                  height: 80,
                  key: 'thumbNail/b26a7d35-a618-4239-a312-6dba8e437e0d.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_19.png',
            },
            {
              id: '1b6e963d-dd66-431d-8ff8-632955cec4c7',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 238,
                  height: 800,
                  key: 'fullSize/bbb3be01-4000-456d-8843-2f896b8a36c0.png',
                },
                thumbNail: {
                  width: 23,
                  height: 80,
                  key: 'thumbNail/bbb3be01-4000-456d-8843-2f896b8a36c0.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_16.png',
            },
            {
              id: '8607b0f4-246f-44f7-a5a9-4a3648aa67a6',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: '6d06b889-36f7-4542-b297-fbdf04f4b68e',
                  name: 'phone',
                },
                {
                  id: '8828f354-3e79-46e5-a09e-b6132fd9ca28',
                  name: 'unarmed',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 295,
                  height: 800,
                  key: 'fullSize/ac094234-e03a-4e09-9134-b2405a01dd78.png',
                },
                thumbNail: {
                  width: 29,
                  height: 80,
                  key: 'thumbNail/ac094234-e03a-4e09-9134-b2405a01dd78.png',
                },
              },
              originalFileName: 'dpd_fb_una_ph_ma_01_08.png',
            },
          ],
        },
      },
    ],
    fixation: {
      interval: 1500,
      mediaItem: {
        id: 'baa416f9-d9f8-42fb-9ac5-0fe500297785',
        tags: [],
        text: '+',
        image: null,
        originalFileName: null,
        scope: MediaItemSnapshotScope.PUBLIC,
      },
    },
    trialFeedback: {
      correctRejectionText: {
        value: 'Richtige Entscheidung!',
        fontStyle: {
          fontSize: 30,
          color: 'green',
          alignment: TextAlignment.CENTER,
          fontFace: FontFace.ARIAL,
        },
        weight: 5,
      },
      hitText: {
        value: 'Richtige Entscheidung!',
        fontStyle: {
          fontSize: 30,
          color: 'green',
          alignment: TextAlignment.CENTER,
          fontFace: FontFace.ARIAL,
        },
        weight: 10,
      },
      missText: {
        value: 'Die Person war bewaffnet!',
        fontStyle: {
          fontSize: 30,
          color: 'red',
          alignment: TextAlignment.CENTER,
          fontFace: FontFace.ARIAL,
        },
        weight: -40,
      },
      falseAlarmText: {
        value: 'Die Person war unbewaffnet!',
        fontStyle: {
          fontSize: 30,
          color: 'red',
          alignment: TextAlignment.CENTER,
          fontFace: FontFace.ARIAL,
        },
        weight: -20,
      },
      noResponseText: {
        value: 'Sie waren zu langsam!',
        fontStyle: {
          fontSize: 30,
          color: 'orange',
          alignment: TextAlignment.CENTER,
          fontFace: FontFace.ARIAL,
        },
        weight: -10,
      },
      pointsFontsize: 40,
    },
    blocks: [
      {
        __typename: 'PodtBlock',
        index: 0,
        name: 'Practice',
        type: 'NON_PRACTICE',
        amountTrials: 4,
        instructions: {
          desktop:
            '<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">In der folgenden Aufgabe müssen Sie erkennen ob Personen Waffen oder harmlose Gegenstände in der Hand halten.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wenn eine Person eine <strong>Waffe</strong> in der Hand hält, drücken Sie <strong>#WEAPON_BUTTON#</strong> ("WAFFE").</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wenn eine Person ein <strong>Handy</strong> in der Hand hält, drücken Sie <strong>#OBJECT_BUTTON#</strong> ("KEINE WAFFE").</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Sie haben weniger als eine Sekunde Zeit um zu entscheiden. Je nach Leistung erhalten Sie pro Durchgang Punkte.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Die erste Runde dient zur Übung und hat vier Durchgänge.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wenn Sie bereit sind mit dem Übungsdurchgang zu beginnen, dann drücken sie nun die </span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;"><strong>Leertaste</strong>.</span></p>\n',
          mobile:
            '<p style="text-align:center;"><span style="color: rgb(0,0,0);font-size: 18px;font-family: Mulish;">In der folgenden Aufgabe müssen Sie erkennen ob Personen Waffen oder harmlose Gegenstände in der Hand halten.</span></p>\n<p style="text-align:center;"><span style="color: rgb(0,0,0);font-size: 18px;font-family: Mulish;">Wenn eine Person eine <strong>Waffe </strong>in der Hand hält, drücken Sie "WAFFE".</span></p>\n<p style="text-align:center;"><span style="color: rgb(0,0,0);font-size: 18px;font-family: Mulish;">Wenn eine Person ein <strong>Handy</strong> in der Hand hält, drücken Sie "KEINE WAFFE".</span></p>\n<p style="text-align:center;"><span style="color: rgb(0,0,0);font-size: 18px;font-family: Mulish;">Sie haben weniger als eine Sekunde Zeit um zu entscheiden. Je nach Leistung erhalten Sie pro Durchgang Punkte.</span></p>\n<p style="text-align:center;"><span style="color: rgb(0,0,0);font-size: 18px;font-family: Mulish;">Die erste Runde dient zur Übung und hat vier Durchgänge.</span></p>\n<p style="text-align:center;"><span style="color: rgb(0,0,0);font-size: 18px;font-family: Mulish;">Wenn Sie bereit sind, dann fangen Sie jetzt an.</span></p>\n',
          fontStyle: null,
        },
        preparationScreen: {
          interval: 2000,
          screen: {
            desktop:
              '<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;"><span style="font-size: 24px;font-family: Mulish;"><strong>Bereit?</strong></span></p>\n<p style="text-align:center;"><span style="font-size: 24px;font-family: Mulish;"><strong>Dann geht es jetzt los...!</strong></span></p>\n',
            mobile:
              '<p style="text-align:center;"><span style="font-size: 24px;font-family: Mulish;"><strong>Bereit?</strong></span></p>\n<p style="text-align:center;"><span style="font-size: 24px;font-family: Mulish;"><strong>Dann geht es jetzt los...!</strong></span></p>\n',
            fontStyle: null,
          },
        },
        tips: {
          desktop: '',
          mobile: null,
          fontStyle: null,
        },
      },
      {
        index: 1,
        name: 'Test',
        type: 'NON_PRACTICE',
        amountTrials: 80,
        instructions: {
          desktop:
            '<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;">&nbsp;</p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Der Übungsdurchgang ist nun beendet.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Zur Erinnerung:</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Entscheiden Sie so schnell und akkurat wie möglich. </span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wenn die Person eine <strong>Waffe </strong>in der Hand hält, drücken Sie <strong>#WEAPON_BUTTON# </strong>("WAFFE"). </span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wenn die Person ein <strong>Handy</strong> in der Hand hält, drücken Sie <strong>#OBJECT_BUTTON# </strong></span><span style="color: rgba(0,0,0,0.87);background-color: rgb(255,255,255);font-size: 18px;font-family: Mulish;">("KEINE WAFFE")</span><span style="font-size: 18px;font-family: Mulish;">.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wichtig: Benutzen Sie bitte die Finger beider Hände für die Aufgabe.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Wenn Sie bereit sind, dann drücken Sie nun die <strong>Leertaste</strong>.</span></p>\n',
          mobile:
            '<p style="text-align:center;"><span style="color: rgb(0,0,0);font-size: 18px;font-family: Mulish;">Der Übungsdurchgang ist nun beendet.</span></p>\n<p style="text-align:center;"><span style="color: rgb(0,0,0);font-size: 18px;font-family: Mulish;">Zur Erinnerung:</span></p>\n<p style="text-align:center;"><span style="color: rgb(0,0,0);font-size: 18px;font-family: Mulish;">Entscheiden Sie so schnell und akkurat wie möglich. </span></p>\n<p style="text-align:center;"><span style="color: rgb(0,0,0);font-size: 18px;font-family: Mulish;">Wenn die Person eine <strong>Waffe </strong>in der Hand hält, drücken Sie "WAFFE". </span></p>\n<p style="text-align:center;"><span style="color: rgb(0,0,0);font-size: 18px;font-family: Mulish;">Wenn die Person ein <strong>Handy</strong> in der Hand hält, drücken Sie "KEINE WAFFE".</span></p>\n<p style="text-align:center;"><span style="color: rgb(0,0,0);font-size: 18px;font-family: Mulish;">Wichtig: Benutzen Sie bitte die Finger beider Hände für die Aufgabe.</span></p>\n<p style="text-align:center;"><span style="color: rgb(0,0,0);font-size: 18px;font-family: Mulish;">Wenn Sie bereit sind, dann fangen Sie jetzt an.</span></p>\n',
          fontStyle: null,
        },
        preparationScreen: {
          interval: 500,
          screen: {
            desktop:
              '<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;"><strong>Bereit?</strong></span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;"><strong>Dann geht es jetzt los!</strong></span></p>\n',
            mobile:
              '<p style="text-align:center;"><span style="font-family: Mulish;"><strong>Bereit?</strong></span></p>\n<p style="text-align:center;"><span style="font-family: Mulish;"><strong>Dann geht es jetzt los!</strong></span></p>\n',
            fontStyle: null,
          },
        },
        tips: {
          desktop: '',
          mobile: null,
          fontStyle: {
            fontSize: 14,
            color: 'black',
            alignment: TextAlignment.CENTER,
            fontFace: FontFace.ARIAL,
          },
        },
      },
    ],
    showCategoriesAfterInstructions: null,
    startLabel: 'Start',
    nextLabel: 'Next',
    finishLabel: 'Beenden',
    createdAt: '2022-12-23T14:50:30.076Z',
    updatedAt: '2023-01-25T13:19:26.695Z',
  } as unknown as Podt,
];

export const InitialPodtCreatorState: PodtCreatorState = {
  startLabel: 'Start',
  nextLabel: 'Next',
  finishLabel: 'Finish',
  name: '',
  description: '',
  scenes: {
    intervalConfig: {
      min: 500,
      max: 1000,
      stepSize: 100,
    },
    maxAmountScenesPerTrial: 4,
    scenes: [],
  },
  targets: [createEmptyLabeledStimuliPool()],
  counterbalancing: true,
  probabilityWithoutReplacement: true,
  fixation: createEmptyDefaultFixation(),
  response: {
    fontStyle: createDefaultFontStyle(TextAlignment.CENTER),
    timeout: 850,
    active: createEmptyMediaItemSnapshot(),
    passive: createEmptyMediaItemSnapshot(),
  },
  interTrialInterval: 3000,
  keyboard: createEmptyKeyboardSettings(),
  instructions: {
    desktop: '',
  },
  trialFeedback: {
    correctRejectionText: createWeightedStyledTextInput('correct rejection', 5),
    hitText: createWeightedStyledTextInput('correct hit', 10),
    missText: createWeightedStyledTextInput('missed target', -40),
    falseAlarmText: createWeightedStyledTextInput('false alaram', -20),
    noResponseText: createWeightedStyledTextInput('no response', -10),
  },
  blocks: [
    {
      index: 0,
      name: '',
      instructions: {
        desktop: '',
      },
      amountTrials: 20,
      preparationScreen: getDefaultPreparationScreen(),
      type: BlockType.NON_PRACTICE,
    },
  ],
  style: createDefaultStyle(),
  status: TestStatus.DRAFT,
};
