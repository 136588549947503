import React from 'react';
import { Storage } from 'aws-amplify';
import _ from 'lodash';
import { ImageInput, MediaItemSnapshotInput } from '../../API';
import {
  BasicDimension,
  Coordinate,
  DrawableDimension,
} from './testRunner/media/drawable-dimensions';
import { CanvasMemoryError } from './testRunner/canvas-memory-error';

export interface TextStyle {
  font?: string;
  fontSize?: number;
  textColor?: string;
}

export interface CanvasConfig {
  width: number;
  height: number;
}

export default class MediaOffscreenCanvas {
  readonly canvas: HTMLCanvasElement;

  private static redrawOperations = {
    fit: MediaOffscreenCanvas.prototype.drawImageToOffScreenCanvasWithFit,
    fill: MediaOffscreenCanvas.prototype.drawImageToOffScreenCanvasWithFill,
    center: MediaOffscreenCanvas.prototype.drawImageToOffScreenCanvasCenter,
    random: MediaOffscreenCanvas.prototype.drawImageToRandomPosition,
  };

  constructor(canvasElement?: HTMLCanvasElement | CanvasConfig) {
    if (canvasElement instanceof HTMLCanvasElement) {
      this.canvas = canvasElement;
    } else {
      this.canvas = document.createElement('canvas');
      this.canvas.height = canvasElement?.height || 350;
      this.canvas.width = canvasElement?.width || 350;
    }
  }

  get width() {
    return this.canvas.width;
  }

  get height() {
    return this.canvas.height;
  }

  static ofMediaItemImage(item: MediaItemSnapshotInput, thumb: boolean) {
    const image = item.image as ImageInput;
    const imageInstance = thumb ? image.thumbNail : image.fullSize;
    // console.log(imageInstance.width, imageInstance.height);
    const canvas = new MediaOffscreenCanvas({
      width: imageInstance.width,
      height: imageInstance.height,
    });
    const img = new Image();
    const promise = new Promise<MediaOffscreenCanvas>((resolve, reject) => {
      img.onload = () => {
        (canvas.canvas.getContext('2d') as CanvasRenderingContext2D).drawImage(
          img,
          0,
          0,
        );
        resolve(canvas);
      };
    });
    return Storage.get(imageInstance.key).then((url) => {
      img.src = url as string;
      img.crossOrigin = 'anonymous';
      // console.log(url);
      return promise;
    });
  }

  static ofMediaItemText(item: MediaItemSnapshotInput, textStyle: TextStyle) {
    const canvasElement = document.createElement('canvas');
    const text = item.text as string;
    const ctx = canvasElement.getContext('2d') as CanvasRenderingContext2D;

    const fontSize = textStyle.fontSize || 30;
    const setContextStyle = (
      canvasRenderingContext: CanvasRenderingContext2D,
    ) => {
      canvasRenderingContext.font = `${fontSize}px ${
        textStyle.font || 'Arial'
      }`;
      canvasRenderingContext.fillStyle = textStyle.textColor || 'black';
      canvasRenderingContext.textBaseline = 'middle';
      canvasRenderingContext.textAlign = 'center';
    };

    setContextStyle(ctx);
    const measure = ctx.measureText(text);
    canvasElement.width = measure.width + 2;
    canvasElement.height = fontSize + 2;

    setContextStyle(ctx);
    ctx.fillText(text, measure.width / 2, fontSize / 2);

    return new MediaOffscreenCanvas(canvasElement);
  }

  static ofCachedCanvas(
    cachedCanvas: MediaOffscreenCanvas,
    operation: keyof typeof MediaOffscreenCanvas.redrawOperations,
    width = 350,
    height = 350,
  ) {
    const canvas = new MediaOffscreenCanvas({ width, height });
    MediaOffscreenCanvas.redrawOperations[operation].call(
      canvas,
      cachedCanvas.canvas,
    );
    return canvas;
  }

  private drawImageToOffScreenCanvasCenter(
    image: HTMLImageElement | HTMLCanvasElement,
  ) {
    const offScreenCanvasContext = this.canvas.getContext(
      '2d',
    ) as CanvasRenderingContext2D;
    offScreenCanvasContext.clearRect(
      0,
      0,
      this.canvas.width,
      this.canvas.height,
    );
    const { width } = this.canvas;
    const { height } = this.canvas;
    const left = (width - image.width) / 2;
    const top = (height - image.height) / 2;
    offScreenCanvasContext.drawImage(image, Math.round(left), Math.round(top));
  }

  private drawImageToOffScreenCanvasWithFit(
    image: HTMLImageElement | HTMLCanvasElement,
  ) {
    const offScreenCanvasContext = this.canvas.getContext('2d');
    if (offScreenCanvasContext) {
      const { canvas } = offScreenCanvasContext;
      const hRatio = canvas.width / image.width;
      const vRatio = canvas.height / image.height;
      const ratio = Math.min(hRatio, vRatio);
      const centerShiftX = (canvas.width - image.width * ratio) / 2;
      const centerShiftY = (canvas.height - image.height * ratio) / 2;
      offScreenCanvasContext.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        centerShiftX,
        centerShiftY,
        image.width * ratio,
        image.height * ratio,
      );
    }
  }

  private drawImageToOffScreenCanvasWithFill(
    image: HTMLImageElement | HTMLCanvasElement,
  ) {
    const offScreenCanvasContext = this.canvas.getContext('2d');
    if (offScreenCanvasContext) {
      const { canvas } = offScreenCanvasContext;
      offScreenCanvasContext.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        0,
        0,
        canvas.width,
        canvas.height,
      );
    }
  }

  public clearTransparent() {
    const offScreenCanvasContext = this.canvas.getContext('2d');
    offScreenCanvasContext!.fillStyle = 'transparent';
    offScreenCanvasContext!.fillRect(0, 0, this.width, this.height);
  }

  public drawImageToRandomPosition(
    image: HTMLImageElement | HTMLCanvasElement,
  ) {
    const offScreenCanvasContext = this.canvas.getContext('2d');
    if (offScreenCanvasContext) {
      offScreenCanvasContext.drawImage(
        image,
        _.random(this.canvas.width),
        _.random(this.canvas.height),
      );
    }
  }

  public drawImageSource(
    imgSource: CanvasImageSource,
    position: Coordinate,
    size: BasicDimension,
  ) {
    /*    const intSize = DrawableDimension.toInt(dimension);
       // const intPosition = {x: Math.round(position.x), y: Math.round(position.y)};
        const offScreenCanvasContext = this.canvas.getContext("2d")!;
        offScreenCanvasContext.drawImage(image, 0, 0,image.width as number, image.height as number,0,0, dimension.width, dimension.height);
        //offScreenCanvasContext.drawImage(image, 0, 0, image.width as number, image.height as number, intPosition.x, intPosition.y, intSize.width, intSize.height);*/

    /*    const position = DrawableDimension.toInt({
          width: (this.canvas.width - size.width) / 2,
          height: (this.canvas.height - size.height) / 2
        });*/

    const intSize = DrawableDimension.toInt(size);
    const offScreenCanvasContext = this.canvas.getContext('2d')!;
    offScreenCanvasContext.moveTo(0, 0);
    offScreenCanvasContext.drawImage(
      imgSource,
      position.x,
      position.y,
      intSize.width,
      intSize.height,
    );
  }

  public drawImageToPosition(
    image: CanvasImageSource,
    top: number,
    left: number,
  ) {
    const offScreenCanvasContext = this.canvas.getContext('2d');
    if (offScreenCanvasContext) {
      offScreenCanvasContext.drawImage(image, left, top);
    }
  }

  public drawImgSourceCenter(
    imgSource: CanvasImageSource,
    size: BasicDimension,
    ratio = 1,
  ) {
    const position = DrawableDimension.toInt({
      width: (this.canvas.width / ratio - size.width) / 2,
      height: (this.canvas.height / ratio - size.height) / 2,
    });
    const intSize = DrawableDimension.toInt(size);
    const offScreenCanvasContext = this.canvas.getContext('2d');
    if (offScreenCanvasContext === null) {
      throw new CanvasMemoryError();
    }
    offScreenCanvasContext.scale(ratio, ratio);
    offScreenCanvasContext.drawImage(
      imgSource,
      0,
      0,
      imgSource.width as number,
      imgSource.height as number,
      position.width,
      position.height,
      intSize.width,
      intSize.height,
    );
  }

  public drawToCanvasWithFit(canvasRef: React.RefObject<HTMLCanvasElement>) {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const canvasContext = canvasRef.current.getContext('2d');
      if (canvasContext) {
        const hRatio = canvas.width / this.canvas.width;
        const vRatio = canvas.height / this.canvas.height;
        const ratio = Math.min(hRatio, vRatio);
        const centerShiftX = (canvas.width - this.canvas.width * ratio) / 2;
        const centerShiftY = (canvas.height - this.canvas.height * ratio) / 2;

        canvasContext.drawImage(
          this.canvas,
          0,
          0,
          this.canvas.width,
          this.canvas.height,
          centerShiftX,
          centerShiftY,
          this.canvas.width * ratio,
          this.canvas.height * ratio,
        );
      }
    }
  }
}
