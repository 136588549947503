import React, { FC } from "react";
import { WSWTest } from "../../../../../API";
import { Box, Stack } from "@mui/material";
import { TextDisplay } from "../../../../../components/TextDisplay";
import { BorderBox } from "../../../../../components/BorderBox";
import { ConditionalQuestionSetView } from "../../components/conditionalQuestionSet/view/ConditionalQuestionSetView";
import { WswGroupsView } from "../../components/groupConstruction/view/WswGroupsView";

export const WswGroupSettingsView: FC<{ test: WSWTest }> = ({ test }) => {
  return (
    <Box>
      {!test.groupConstruction
        ? <BorderBox label="Groupconstruction" none={true}>
          No Groupconstruction enabeld
        </BorderBox>
        : (
          <Stack spacing={2}>
            <Stack spacing={2} direction="row">
              <TextDisplay
                label="Condition 1"
                value={test.groupConstruction.conditions?.condition1}
              />
              <TextDisplay
                label="Condition 2"
                value={test.groupConstruction.conditions?.condition2}
              />
              <TextDisplay
                label="Condition 3"
                value={test.groupConstruction.conditions?.condition3}
              />
            </Stack>

            <BorderBox
              boxProps={{ width: "50%" }}
              label="Welcome screen"
              none={!test.groupConstruction.instructionsScreen}
            >
              {test.groupConstruction.instructionsScreen?.desktop
                ? <div
                  dangerouslySetInnerHTML={{
                    __html: test.groupConstruction.instructionsScreen.desktop
                  }}
                />
                : "No Instructions screen enabeld"}
            </BorderBox>
            <BorderBox
              boxProps={{ width: "50%" }}
              label="Feedback screen"
              none={!test.groupConstruction.feedbackScreen}
            >
              {test.groupConstruction.feedbackScreen?.desktop
                ? <div
                  dangerouslySetInnerHTML={{
                    __html: test.groupConstruction.feedbackScreen.desktop
                  }}
                />
              : "No Feedback screen enabeld"}
            </BorderBox>
            <ConditionalQuestionSetView
              label={"Fake Questions"}
              conditionalQuestionSet={test.groupConstruction.fakeQuestions}
            />
            {test.groupConstruction.groups &&
              <WswGroupsView groups={test.groupConstruction.groups} />
            }
          </Stack>
        )
      }
    </Box>
  );
};
