import { Box } from '@mui/material';
import React from 'react';
import { FullScreenHandle } from 'react-full-screen';
import { TestBaseContext } from '../../state/baseTestContext/context/ContextTypes';
import { determineFullscreenCapability } from '../../utils/fscreen-checker';
import { TestType } from '../../../types';
import { MobileFullscreenSetup } from '../../state-display-screens/loading-screens/mobile-fullscreen-setup';
import { MobileOrientationSetup } from '../../state-display-screens/loading-screens/mobile-orientation-setup';

export function ParticipantStageSetup({
  onFinish,
  handle,
  testContext,
}: {
  onFinish: (fullscreen: boolean) => void;
  handle: FullScreenHandle;
  testContext: TestBaseContext;
}) {
  React.useEffect(() => {
    if (testContext.deviceInfo.mode === 'desktop') {
      onFinish(false);
    }
  }, [onFinish, testContext]);
  const fullscreenPossible = React.useMemo(
    () => {
      if (
        testContext.testIdentity.testType === TestType.WSW &&
        (testContext.deviceInfo.device?.model
          ?.toLocaleLowerCase()
          ?.includes('ipad') ??
          false)
      ) {
        return false;
      }
      return determineFullscreenCapability(document.documentElement);
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [],
  );
  if (testContext.deviceInfo.mode === 'desktop') {
    return <></>;
  }
  return (
    <MobileStageSetup
      tryFullscreen={fullscreenPossible}
      onFinish={onFinish}
      handle={handle}
    />
  );
}

export function MobileStageSetup({
  onFinish,
  handle,
  tryFullscreen,
}: {
  tryFullscreen: boolean;
  onFinish: (fullscreen: boolean) => void;
  handle: FullScreenHandle;
}) {
  const [setupState, setSetupState] = React.useState<
    'fullscreen-setup' | 'landscape-setup'
  >(tryFullscreen ? 'fullscreen-setup' : 'landscape-setup');
  const [fullscreen, setFullscreen] = React.useState(false);
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {setupState === 'fullscreen-setup' && (
        // eslint-disable-next-line react/jsx-no-undef
        <MobileFullscreenSetup
          handle={handle}
          onFinish={(fullscreen: boolean, locked: boolean) => {
            if (locked) {
              onFinish(true);
            } else {
              setSetupState('landscape-setup');
              setFullscreen(fullscreen);
            }
          }}
        />
      )}
      {setupState === 'landscape-setup' && (
        <MobileOrientationSetup onFinish={() => onFinish(fullscreen)} />
      )}
    </Box>
  );
}
