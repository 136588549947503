import React, { FC } from 'react';
import { Typography } from '@mui/material';

export const PickingStrategyToolTip: FC = () => (
  <ul style={{ paddingLeft: 16, paddingRight: 16 }}>
    <li>
      <Typography color="inherit" variant="body1">
        Definitions
      </Typography>
      <Typography color="inherit" variant="body2">
        * n - total number of attribute stimuli (size of the universe)
      </Typography>
      <Typography color="inherit" variant="body2">
        * x - number of stimuli presented in learning phase (selected values)
      </Typography>
    </li>
    <br />
    <li>
      <Typography color="inherit" variant="body1">
        Permutation
      </Typography>
      <Typography color="inherit" variant="body2">
        * n = x
      </Typography>
      <Typography color="inherit" variant="body2" sx={{ maxWidth: 'sm' }}>
        Urn draw without replacement; All attributes “x” from the universe “n”
        (urn) will be presented. Each attribute will be presented exactly once.
        The number of trials in the test phase equals the number of attributes.
      </Typography>
    </li>
    <br />
    <li>
      <Typography color="inherit" variant="body1">
        Without replacement
      </Typography>
      <Typography color="inherit" variant="body2">
        {'* n <= x'}
      </Typography>
      <Typography color="inherit" variant="body2" sx={{ maxWidth: 'sm' }}>
        Urn draw without replacement; A number of attributes “x” from the
        universe “n” (urn) will be randomly chosen to be presented. Each
        attribute will be presented at most once.
      </Typography>
    </li>
    <br />
    <li>
      <Typography color="inherit" variant="body1">
        Refilling without replacement
      </Typography>
      <Typography color="inherit" variant="body2">
        {'* n >= x'}
      </Typography>
      <Typography color="inherit" variant="body2" sx={{ maxWidth: 'sm' }}>
        Urn draw with replacement: A number of attributes “x” from the universe
        “n” (urn) will be randomly chosen to be presented. The urn will be
        refilled once it is empty. Attributes could be presented more than once.
      </Typography>
    </li>
    <br />
    <li>
      <Typography color="inherit" variant="body1">
        With Replacement
      </Typography>
      <Typography color="inherit" variant="body2" sx={{ maxWidth: 'sm' }}>
        Urn draw with replacement. A number of attributes “x” from the universe
        “n” (urn) will be presented. The urn will be refilled after each draw.
        Attributes could be presented more or less than once.
      </Typography>
    </li>
    <br />
    <p>
      <Typography color="red" variant="body1">
        NOTE
      </Typography>
      <Typography color="inherit" variant="body2">
        If you have fewer additional attributes than main attributes,
        <br />
        you can only choose picking strategy “with replacement” or “refilling
        without replacement”
      </Typography>
    </p>
  </ul>
);
