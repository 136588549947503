import { QuestionLocator } from './question-locator';
import { WswQuestionType } from '../../../../../../API';

export class QuestionSetLocator {
  constructor(
    public readonly questionPoolName: string,
    public readonly questionsBeforeAmount: number,
  ) {}

  locateConditional(snapshot: string): QuestionLocator {
    return {
      questionPoolName: this.questionPoolName,
      questionPosition: this.questionsBeforeAmount,
      questionSnapshot: snapshot,
      questionType: WswQuestionType.YesNo,
    };
  }

  locate(
    order: 'before' | 'after',
    position: number,
    type: WswQuestionType,
    snapshot: string,
  ): QuestionLocator {
    return {
      questionPoolName: this.questionPoolName,
      questionPosition:
        position + (order === 'after' ? this.questionsBeforeAmount + 1 : 0),
      questionSnapshot: snapshot,
      questionType: type,
    };
  }
}
