import {
  Optional,
  OptionalValue,
} from '../../../subject/testRunner/screens/selectors/data-wrapper';
import React, { MutableRefObject } from 'react';

export function IATFailComponent({
  value,
  containerRef,
}: {
  value: OptionalValue<boolean>;
  containerRef: MutableRefObject<any | null>;
}) {
  return (
    <div
      style={{
        color: 'red',
        fontSize: '150px',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
      ref={containerRef}
    >
      {Optional.isValue(value) && value[1] ? 'X' : ''}
    </div>
  );
}
