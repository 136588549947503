import React, { FunctionComponent, useRef, useState } from 'react';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useField } from 'formik';
import { UiInput } from '../types';
import htmlToDraft from 'html-to-draftjs';
import makeStyles from '@mui/styles/makeStyles';
import draftToHtml from 'draftjs-to-html';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& .public-DraftStyleDefault-ltr': { textAlign: 'inherit' },
  },
  toolbar: {
    height: 0,
    padding: 0,
    marginBottom: 0,
    transition: theme.transitions.create('height'),
    '&[aria-hidden=false]': {
      padding: '6px 5px 0',
      marginBottom: 5,
      height: 40,
    },
  },
  editor: {
    borderRadius: '4px',
    padding: theme.spacing(2),
    '& .DraftEditor-root': {
      marginTop: theme.spacing(-2),
    },
  },
}));

interface WysiwygEditorProps extends UiInput<string> {
  onChangeHtml?: (html: string) => void;
  toolTip?: string;
  focusOnMount?: boolean;
}

const WysiwygEditor: FunctionComponent<WysiwygEditorProps> = ({
  name,
  onChangeHtml,
  value,
  toolTip,
  focusOnMount,
}) => {
  const classes = useStyles();
  const [, meta, helpers] = useField<typeof value>(name);

  const editorRef = useRef(null);

  React.useEffect(() => {
    if (editorRef.current && focusOnMount) {
      // @ts-ignore
      editorRef.current?.focus();
    }
  }, [focusOnMount]);

  const getDefaultState = () => {
    if (meta.value) {
      const contentBlock = htmlToDraft(meta.value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        return EditorState.createWithContent(contentState);
      }
    } else {
      return () => EditorState.createEmpty();
    }
  };

  const [editorState, setEditorState] = useState(getDefaultState());

  const handleChangeEditorState = (state: EditorState) => {
    setEditorState(state);
    const currentContent = state.getCurrentContent();
    const raw = convertToRaw(currentContent);
    const html = draftToHtml(raw);
    helpers.setValue(html === '<p></p>\n' || html === '<p></p>' ? '' : html);
    if (typeof onChangeHtml === 'function') {
      onChangeHtml(html);
    }
  };

  const borderColor = 'lightgrey';

  return (
    <Editor
      toolbar={{
        list: { inDropdown: true },
        history: { inDropdown: true },
        textAlign: { inDropdown: true },
        colorPicker: {
          colors: [
            'rgba(30, 95, 70, 0.87)',
            'rgb(85, 58, 54)',
            'rgb(30, 95, 70)',
            'rgb(0,110,120)',
            'rgb(255,170,40)',
            'rgb(165,50,55)',
            'rgb(225,120,50)',
            'rgb(97,189,109)',
            'rgb(26,188,156)',
            'rgb(84,172,210)',
            'rgb(44,130,201)',
            'rgb(147,101,184)',
            'rgb(71,85,119)',
            'rgb(204,204,204)',
            'rgb(65,168,95)',
            'rgb(0,168,133)',
            'rgb(61,142,185)',
            'rgb(41,105,176)',
            'rgb(85,57,130)',
            'rgb(40,50,78)',
            'rgb(0,0,0)',
            'rgb(247,218,100)',
            'rgb(251,160,38)',
            'rgb(235,107,86)',
            'rgb(226,80,65)',
            'rgb(163,143,132)',
            'rgb(239,239,239)',
            'rgb(255,255,255)',
            'rgb(250,197,28)',
            'rgb(243,121,52)',
            'rgb(209,72,65)',
            'rgb(184,49,47)',
            'rgb(124,112,107)',
            'rgb(209,213,216)',
          ],
        },
        fontFamily: {
          options: [
            'Mulish',
            'Arial',
            'Geogia',
            'Impact',
            'Tahoma',
            'Times New Roman',
            'Verdana',
          ],
        },
      }}
      editorRef={(ref) => (editorRef.current = ref as any)}
      toolbarHidden={false}
      wrapperClassName={classes.wrapper}
      toolbarClassName={classes.toolbar}
      editorClassName={classes.editor}
      editorState={editorState}
      onEditorStateChange={handleChangeEditorState}
      editorStyle={{ border: `1px ${borderColor} solid` }}
    />
  );
};

export default WysiwygEditor;
