import { DrawableMetrics } from './drawable-dimensions';
import { MediaDrawable } from './MediaData';
import { FontStyle, TextAlignment } from '../../../../API';
import { displayRatio } from '../screens/basic-components/media-instance-components';
import { CanvasMemoryError } from '../canvas-memory-error';

export class TextMediaStyle {
  constructor(
    public fontFace: string,
    public fontSize: UnitFontSize,
    public color: string,
    public alignment?: CanvasTextAlign,
  ) {}

  createStyle() {
    return {
      fontSize: `${this.fontSize.size}${this.fontSize.unit}`,
      fontFace: this.fontFace,
      color: this.color,
      textAlign: this.alignment ?? undefined,
    } as const;
  }

  createNativeStyle() {
    return `font-size: ${this.fontSize.size}${this.fontSize.unit}; font-face: ${
      this.fontFace
    }; color: ${this.color}; text-align: ${this.alignment ?? 'left'}`;
  }
}

const AlignmentMap: Record<TextAlignment, CanvasTextAlign> = {
  [TextAlignment.CENTER]: 'center',
  [TextAlignment.LEFT]: 'start',
  [TextAlignment.RIGHT]: 'end',
};

export interface UnitFontSize {
  size: number;
  unit: 'px' | 'em' | 'rem';
}

export function unitFontSize(size: number, unit: UnitFontSize['unit']) {
  return { size, unit };
}

export const UnitFontSizes = {
  of: (size: number, unit: UnitFontSize['unit']) => ({ size, unit }),
  px: (size: number) => UnitFontSizes.of(size, 'px'),
};

export class TextStyleDescriptor {
  constructor(
    public fontFace?: string,
    public fontSize?: UnitFontSize,
    public color?: string,
    public alignment?: CanvasTextAlign,
  ) {}

  static ofFontStyle(fontStyle: Omit<FontStyle, '__typename'>) {
    return new TextStyleDescriptor(
      fontStyle.fontFace,
      fontStyle.fontSize
        ? {
            size: fontStyle.fontSize,
            unit: 'px',
          }
        : undefined,
      fontStyle.color,
      fontStyle.alignment ? AlignmentMap[fontStyle.alignment] : undefined,
    );
  }
}

export function applyTextStyleToContext(
  ctx: CanvasRenderingContext2D,
  textStyle: TextMediaStyle,
) {
  if ('textRendering' in ctx) {
    ctx.textRendering = 'geometricPrecision';
  }
  ctx.font = `${textStyle.fontSize.size}${textStyle.fontSize.unit} Arial`;
  ctx.fillStyle = textStyle.color;
  if (textStyle.alignment) {
    ctx.textAlign = textStyle.alignment;
  }
}

function estimateTextDimensions(text: string, style: TextMediaStyle) {
  const spanElement = document.createElement('span');
  spanElement.innerText = text;
  spanElement.style.cssText = style.createNativeStyle();
  document.body.append(spanElement);
  const boundingBox = spanElement.getBoundingClientRect();
  document.body.removeChild(spanElement);
  return new DrawableMetrics(
    Math.ceil(boundingBox.width),
    Math.ceil(boundingBox.height),
  );
}

export class TextDrawableBuilder {
  text: string;
  style: TextMediaStyle;
  dimension: DrawableMetrics;

  constructor(
    text: string,
    style: TextMediaStyle,
    protected dimensionCollector?: {
      processWeightedDimension: (dim: DrawableMetrics) => void;
      getAllProcessedDimensions: () => DrawableMetrics[];
    },
  ) {
    this.text = text;
    this.style = style;
    this.dimension = TextDrawableBuilder.measureText(text, style);
  }
  static calculateCanvasTextMeasure(
    text: string,
    style: TextMediaStyle,
    baseLine: CanvasTextBaseline,
  ) {
    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D;
    canvasContext.textBaseline = baseLine;
    applyTextStyleToContext(canvasContext, style);
    return canvasContext.measureText(text);
  }
  static measureText(text: string, style: TextMediaStyle): DrawableMetrics {
    const measure = TextDrawableBuilder.calculateCanvasTextMeasure(
      text,
      style,
      'alphabetic',
    );
    const actualSize =
      Math.ceil(measure.actualBoundingBoxAscent) +
      Math.ceil(measure.fontBoundingBoxDescent);

    /*
      Some browsers prevent measuring and return undefined values - use fallback measuring in this case
     */
    if (
      typeof measure.width !== 'number' ||
      typeof measure.actualBoundingBoxDescent !== 'number' ||
      typeof measure.actualBoundingBoxAscent !== 'number'
    ) {
      return estimateTextDimensions(text, style);
    }
    return new DrawableMetrics(
      Math.ceil(measure.width),
      Math.ceil(actualSize),
      Math.ceil(measure.actualBoundingBoxAscent),
    );
  }

  build(): MediaDrawable {
    if (this.dimensionCollector) {
      this.dimensionCollector.processWeightedDimension(this.dimension);
    }
    const createResultLazy = (): MediaDrawable => {
      let realDimensions = this.dimension;
      if (this.dimensionCollector) {
        const allProcessedDimensions =
          this.dimensionCollector.getAllProcessedDimensions();
        const maxTopToBaseline = Math.max(
          ...allProcessedDimensions.map((d) => d.weightedCenter.y),
        );
        const maxBottomToBaseline = Math.max(
          ...allProcessedDimensions.map((d) => d.height - d.weightedCenter.y),
        );
        realDimensions = new DrawableMetrics(
          this.dimension.width,
          maxTopToBaseline + maxBottomToBaseline + 1,
          maxTopToBaseline,
        );
      }
      const canvas = document.createElement('canvas');
      canvas.width = realDimensions.width * displayRatio;
      canvas.height = realDimensions.height * displayRatio;
      const context = canvas.getContext('2d') as CanvasRenderingContext2D;
      // context.scale(window.devicePixelRatio, window.devicePixelRatio);
      if (context === null) {
        throw new CanvasMemoryError();
      }
      context.scale(displayRatio, displayRatio);
      if (context) {
        context.textBaseline = 'alphabetic';

        applyTextStyleToContext(context, this.style);
        context.fillText(
          this.text,
          0,
          realDimensions.weightedCenter.y + 1,
          realDimensions.width,
        );
      }
      return { dimension: realDimensions, imageSource: canvas };
    };
    class ProxyResult implements MediaDrawable {
      private _instance?: MediaDrawable;
      private ensureInstance(accessor: string): MediaDrawable {
        if (!this._instance) {
          console.log('mediaDrawable queried, ' + accessor, this);
          this._instance = createResultLazy();
        }
        return this._instance!;
      }
      get dimension() {
        return this.ensureInstance('get dim').dimension;
      }
      set dimension(value) {
        this.ensureInstance('set dim').dimension = value;
      }
      get imageSource() {
        return this.ensureInstance('get img').imageSource;
      }
      set imageSource(value) {
        this.ensureInstance('set img').imageSource = value;
      }
    }
    return new ProxyResult();

    // return { dimension: this.dimension, imageSource: canvas };
  }
}
