import { FC } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useGetTagsQuery } from './redux/mediaApi';

interface PlainTagAutoCompleteProps {
  onChangeValue: (t: string) => void;
  value: string;
}

export const PlainTagAutoComplete: FC<PlainTagAutoCompleteProps> = ({
  value,
  onChangeValue,
}) => {
  const { data = [], isFetching } = useGetTagsQuery();

  return (
    <Autocomplete
      loading={isFetching}
      id="combo-box-demo"
      options={data.map((t) => t.name)}
      value={value}
      onChange={(_, value) => onChangeValue(value as string)}
      renderInput={(params) => (
        <TextField
          label="Value"
          autoFocus={true}
          name="tags-equal-operator"
          placeholder="Filter value"
          variant="standard"
          {...params}
        />
      )}
    />
  );
};
