import { DefaultTestTree } from '../../../../subject/testRunner/graph/test-tree';
import { OptionsStereotypes } from '../../../../subject/testRunner/controls/control-stereotypes';
import { DefaultTransitionAction } from '../../../../subject/testRunner/graph-state/graph-action';
import { Podt } from '../../types';
import { PodtOrPodtaStructure } from '../structure/podt-structure';
import { Podta } from '../../../PODTA/types';
import { PODTModifiers } from './podt-modifiers';
import { createPodtBlockPhases } from './podt-block-tree';
import { PodtInstructionsData } from '../view-data/podt-instructions-data';
import { MapScreenTreeNode } from '../../../../subject/testRunner/graph/nodes/map-screen-tree-node';
import { MapPhaseTreeNode } from '../../../../subject/testRunner/graph/nodes/map-phase-tree-node';

export function createPodtTree(structure: PodtOrPodtaStructure<Podt | Podta>) {
  const root = new MapPhaseTreeNode('podt', null);

  const start = createPodtStartPhase(root, structure);
  const blocks = createPodtBlockPhases(root, start, structure);
  createPodtEndPhase(root, structure, blocks);
  return new DefaultTestTree(PODTModifiers, root);
}

function createPodtStartPhase(
  root: MapPhaseTreeNode,
  structure: PodtOrPodtaStructure<Podt | Podta>,
) {
  const start = new MapPhaseTreeNode('start', root).connectParent(
    PODTModifiers.default,
  );
  new MapScreenTreeNode(
    'welcome',
    start,
    new PodtInstructionsData({
      content: structure.welcomePhase.content.content,
      nextButton: structure.welcomePhase.content.nextButton,
    }),
  )
    .controlRequest(
      OptionsStereotypes.Confirm.UI.defineTransition(undefined, () =>
        DefaultTransitionAction.next(PODTModifiers.default),
      ),
    )
    .connectParent(PODTModifiers.default);
  return start;
}

function createPodtEndPhase(
  root: MapPhaseTreeNode,
  structure: PodtOrPodtaStructure<Podt | Podta>,
  blocks: MapPhaseTreeNode,
) {
  new MapScreenTreeNode(
    'end-screen',
    root,
    new PodtInstructionsData({
      content: structure.endPhase.content.content,
      nextButton: structure.endPhase.content.nextButton,
      finish: true,
    }),
  )
    .controlRequest(
      OptionsStereotypes.Confirm.UI.defineTransition(undefined, () =>
        DefaultTransitionAction.next(PODTModifiers.default),
      ),
    )
    .connectParent()
    .transitionFrom(PODTModifiers.default, blocks);
}
