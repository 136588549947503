import { useField } from 'formik';
import { ImageInstanceInput } from '../../API';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import S3Image from '../../components/S3Image';
import { LoadingIcon } from '../../components/utils/LoadingIcon';
import { Delete, Upload } from '@mui/icons-material';
import React from 'react';
import { useUploadImageToStorage } from '../media/uploader/uploadPrivateImage.hook';

export function DocumentationStepImageInput({
  stepFieldName,
}: {
  stepFieldName: string;
}) {
  const { uploadImages, uploadState } = useUploadImageToStorage();
  const [, meta, helpers] = useField<ImageInstanceInput | null | undefined>(
    `${stepFieldName}.image`,
  );
  const onFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] ?? null;
    if (file) {
      uploadImages(file).then((res) => {
        helpers.setValue(res.fullSize);
      });
    }
  };

  const hiddenFileInputRef = React.useRef<HTMLInputElement | null>(null);
  return (
    <Stack spacing={1}>
      <Stack direction="row">
        <Button
          startIcon={uploadState === 'loading' ? <LoadingIcon /> : <Upload />}
          disabled={uploadState === 'loading'}
          onClick={() => {
            hiddenFileInputRef.current!.click();
          }}
        >
          {meta.value ? 'Exchange Image' : 'Upload Image'}
        </Button>
        {uploadState === 'error' && (
          <Typography color="error">Error while uploading image</Typography>
        )}
        <input
          ref={hiddenFileInputRef}
          accept={'image/*'}
          multiple={false}
          type="file"
          style={{ display: 'none' }}
          onChange={onFilesChange}
        />
        {meta.value && (
          <IconButton onClick={() => helpers.setValue(null)}>
            <Delete />
          </IconButton>
        )}
      </Stack>
      {meta.value && <S3Image containerScale imgInstance={meta.value} />}
    </Stack>
  );
}
