import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { GQLQuery, NewGQL } from '../../GQL';
import {
  CreateSurveyProviderMutation,
  CreateSurveyProviderMutationVariables,
  DeleteSurveyProviderMutation,
  DeleteSurveyProviderMutationVariables,
  ListSurveyProvidersQueryVariables,
  SurveyProvider,
  UpdateSurveyProviderMutation,
  UpdateSurveyProviderMutationVariables,
} from '../../API';
import { listSurveyProviders } from '../../graphql/queries';
import {
  createSurveyProvider,
  deleteSurveyProvider,
  updateSurveyProvider,
} from '../../graphql/mutations';

import { getGraphQL } from '../utils';

export const surveyProviderApi = createApi({
  reducerPath: 'surveyProviderApi',
  baseQuery: graphqlRequestBaseQuery({
    url: '/graphql',
  }),
  tagTypes: ['SurveyProvider'],
  endpoints: (builder) => ({
    getSurveyProviders: builder.query<SurveyProvider[], void>({
      queryFn: async () => ({
        data: await NewGQL.DEFAULT_CLIENT.execute(
          GQLQuery.List<ListSurveyProvidersQueryVariables, SurveyProvider>(
            listSurveyProviders,
          ).create({}),
        ),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'SurveyProvider' as const,
                id,
              })),
              { type: 'SurveyProvider', id: 'LIST' },
            ]
          : [{ type: 'SurveyProvider', id: 'LIST' }],
    }),
    getSurveyProvidersNoAuth: builder.query<SurveyProvider[], void>({
      queryFn: async () => {
        try {
          return {
            data: await (
              await getGraphQL()
            ).execute(
              GQLQuery.List<ListSurveyProvidersQueryVariables, SurveyProvider>(
                listSurveyProviders,
              ).create({}),
            ),
          };
        } catch (e) {
          console.error('Error loading survey providder', e);
          return {
            data: undefined,
            error: { message: e instanceof Error ? e.message : `${e}` },
          };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'SurveyProvider' as const,
                id,
              })),
              { type: 'SurveyProvider', id: 'LIST' },
            ]
          : [{ type: 'SurveyProvider', id: 'LIST' }],
    }),
    deleteSurveyProvider: builder.mutation<
      SurveyProvider | null | undefined,
      DeleteSurveyProviderMutationVariables
    >({
      queryFn: async (arg) => ({
        data: (
          await NewGQL.DEFAULT_CLIENT.execute(
            GQLQuery.Mutate<
              DeleteSurveyProviderMutationVariables,
              DeleteSurveyProviderMutation
            >(deleteSurveyProvider).create(arg),
          )
        ).deleteSurveyProvider,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              {
                type: 'SurveyProvider' as const,
                id: result.id,
              },
              { type: 'SurveyProvider', id: 'LIST' },
            ]
          : [{ type: 'SurveyProvider', id: 'LIST' }],
    }),
    updateSurveyProvider: builder.mutation<
      UpdateSurveyProviderMutation['updateSurveyProvider'],
      UpdateSurveyProviderMutationVariables
    >({
      queryFn: async (arg) => ({
        data: (
          await NewGQL.DEFAULT_CLIENT.execute(
            GQLQuery.Mutate<
              UpdateSurveyProviderMutationVariables,
              UpdateSurveyProviderMutation
            >(updateSurveyProvider).create(arg),
          )
        ).updateSurveyProvider,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              {
                type: 'SurveyProvider' as const,
                id: result.id,
              },
              { type: 'SurveyProvider', id: 'LIST' },
            ]
          : [{ type: 'SurveyProvider', id: 'LIST' }],
    }),
    createSurveyProvider: builder.mutation<
      CreateSurveyProviderMutation['createSurveyProvider'],
      CreateSurveyProviderMutationVariables
    >({
      queryFn: async (arg) => ({
        data: (
          await NewGQL.DEFAULT_CLIENT.execute(
            GQLQuery.Mutate<
              CreateSurveyProviderMutationVariables,
              CreateSurveyProviderMutation
            >(createSurveyProvider).create(arg),
          )
        ).createSurveyProvider,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              {
                type: 'SurveyProvider' as const,
                id: result.id,
              },
              { type: 'SurveyProvider', id: 'LIST' },
            ]
          : [{ type: 'SurveyProvider', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetSurveyProvidersQuery,
  useGetSurveyProvidersNoAuthQuery,
  useCreateSurveyProviderMutation,
  useUpdateSurveyProviderMutation,
  useDeleteSurveyProviderMutation,
} = surveyProviderApi;
