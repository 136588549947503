import React, { FC } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import { useField } from "formik";
import { AttributeCombinationInput, WSWAttributeInput, WSWAttributeType, WSWGroupInput } from "../../../../../API";
import StyledNumberInput from "../../../../../components/input/StyledNumberInput";
import { WswAttributeIndicationHoverState } from "./WswAttributeCombinationSummaryComponent";
import { InfoOutlined } from "@mui/icons-material";


interface AttributeCombinationInputComponentProps {
  fieldName: string;
  attributeIndicationState?: WswAttributeIndicationHoverState;
}

export const WswAttributeCombinationInputComponent: FC<AttributeCombinationInputComponentProps> = ({
                                                                                                     fieldName,
                                                                                                     attributeIndicationState
                                                                                                   }) => {

  const [, { value: mainAttribute }] = useField<WSWAttributeInput>("mainAttribute");
  const [, { value: criteriaAttribute }] = useField<WSWAttributeInput>("criteriaAttribute");
  const [, { value: additionalAttribute }] = useField<WSWAttributeInput | undefined | null>("additionalAttribute");
  const [, { value: combinations, error }, combinationsHelper] = useField<AttributeCombinationInput[]>("attributeCombinations");
  const [, { value: groups }] = useField<WSWGroupInput[] | undefined | null>("groupConstruction.groups");

  const mapAttributeValues = React.useCallback((attribute: WSWAttributeInput): { name: string; index: number | null }[] => {
    if (attribute.type === WSWAttributeType.STIMULI && attribute.categories) {
      return attribute.categories.map((cat, catIndex) => ({
        name: cat.name,
        index: catIndex
      }));
    } else if (attribute.type === WSWAttributeType.GROUPS && groups) {
      return groups.map((group, groupIndex) => ({
        name: group.name,
        index: groupIndex
      }));
    } else {
      throw new Error("Error mapping Attribute Values");
    }
  }, [groups]);

  const mainAttributeValues = React.useMemo(() => mapAttributeValues(mainAttribute), [mainAttribute, mapAttributeValues]);

  const criteriaAttributeValues = React.useMemo(() => [...mapAttributeValues(criteriaAttribute), {
    name: "Distraktor",
    index: null
  }], [criteriaAttribute, mapAttributeValues]);

  const additionalAttributeValues = React.useMemo(() => additionalAttribute ? mapAttributeValues(additionalAttribute) : [{
    name: "",
    index: null
  }], [additionalAttribute, mapAttributeValues]);

  React.useEffect(() => {
    let currentValues = [...combinations];
    let added = false;
    const addValues: AttributeCombinationInput[] = [];
    criteriaAttributeValues.forEach((cA) => {
      mainAttributeValues
        .forEach((mAV) => additionalAttributeValues
          .forEach((aAV) => {
              const findIndex = currentValues.findIndex((comb) => {
                return comb.mainCategory === mAV.index && comb.additionalCategory === aAV.index && cA.index === comb.criteriaCategory;
              });
              if (findIndex >= 0) {
                addValues.push(currentValues[findIndex]);
                currentValues = currentValues.filter((_, ci) => ci !== findIndex);
              } else {
                addValues.push({
                  mainCategory: mAV.index as number,
                  additionalCategory: aAV.index,
                  criteriaCategory: cA.index,
                  amount: 1,
                  multiplicator: 1
                });
                added = true;
              }

            }
          )
        );
    });

    if (added || currentValues?.length > 0) {
      combinationsHelper.setValue(addValues);
    }
  }, [additionalAttributeValues, combinations, combinationsHelper, criteriaAttributeValues, mainAttributeValues]);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell key="title-cell">
                <Typography
                  variant="h6"
                  color="textSecondary"
                >
                  Attribute Combinations
                  <Tooltip placement={"right"}
                           title={"Frequencies of the Main Attribute paired with one criteria attribute (and potentially one additional attribute) during learning and test phase as defined in the attribute combination matrix; distractors will only be presented in the test phase."}>
                    <InfoOutlined
                      sx={{
                        color: "warning.dark",
                        bgcolor: "white",
                        position: "relative"
                        // left: "-1em"
                      }}
                    />
                  </Tooltip>
                </Typography>
              </TableCell>
              {mainAttributeValues.map((mainAttributeCat, mainAttributeCatIndex) => (
                <TableCell
                  align="center"
                  key={`main-attribute-cat-${mainAttributeCatIndex}`}
                  colSpan={additionalAttributeValues?.length}
                >
                  <b>{mainAttributeCat.name}</b>
                </TableCell>
              ))}
            </TableRow>
            {!!additionalAttribute &&
            <TableRow>
              <TableCell key="empty-cell-2" />
              {mainAttributeValues
                .flatMap((_, i) => additionalAttributeValues
                  .map((additionalAttributeCat, additionalAttributeCatIndex) => (
                    <TableCell
                      key={`additional-attribute-cat-${additionalAttributeCatIndex}-${i}`}
                      align="center"
                    >
                      {additionalAttributeCat.name}
                    </TableCell>
                  )))}
            </TableRow>}
          </TableHead>
          <TableBody>
            {criteriaAttributeValues.map((cA, cAI) => <TableRow>
              <TableCell>{cA.name}</TableCell>
              {mainAttributeValues
                .flatMap((mAV, mAVi) => additionalAttributeValues
                  .map((aAV, aAVi) => {
                      const findIndex = combinations.findIndex((comb) => {
                        return comb.mainCategory === mAV.index && comb.additionalCategory === aAV.index && cA.index === comb.criteriaCategory;
                      });
                      return (
                        <TableCell key={`matrices-element-${mAVi}-${aAVi}-${cAI}`}>
                          {findIndex >= 0
                            ? <StyledNumberInput
                              focused={attributeIndicationState?.criteriaAttribute === cA.index || attributeIndicationState?.mainAttribute === mAV.index || attributeIndicationState?.additionalAttribute === aAV.index}
                              name={`${fieldName}[${findIndex}].amount`}
                              label={""} />
                            : <>-</>
                          }
                        </TableCell>
                      );
                    }
                  )
                )
              }
            </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>
      {error && <Typography sx={{mt: 1}} color="error">{error}</Typography>}
    </div>
  );
};
