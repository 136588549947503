import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionProps as MuiAccordionProps,
  AccordionSummary,
} from '@mui/material';
import React, { FunctionComponent, ReactNode } from 'react';
import { ExpandMore } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';

type StyledAccordionProps = MuiAccordionProps & {
  label: string | ReactNode;
};

const StyledAccordion: FunctionComponent<StyledAccordionProps> = (props) => {
  const { label, children } = props;
  const classes = useStyles();
  return (
    <MuiAccordion {...props} className={classes.accordion}>
      <AccordionSummary expandIcon={<ExpandMore />}>{label}</AccordionSummary>
      <AccordionDetails
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </AccordionDetails>
    </MuiAccordion>
  );
};

const useStyles = makeStyles(() => ({
  accordion: {
    width: '100%',
    '& h4': {
      margin: 0,
    },
  },
}));

export default StyledAccordion;
