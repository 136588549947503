import { TransitionModifier } from '../../graph-state/transition-modifiers';
import { isDefined } from '../../../utils';
import { TestTree } from '../test-tree';
import { TreeTransition } from './tree-transition';
import { NodePathEntry } from './node-path-entry';
import { ScreenTreeNode } from '../nodes/screen-tree-node';
import { PhaseTreeNode } from '../nodes/phase-tree-node';

export interface TreeSequence {
  tree: TestTree;
  start: Map<TransitionModifier, TreeTransition>;
  nodeMap: Map<ScreenTreeNode, NodePathEntry>;
}

export function sequenceTree(testTree: TestTree): TreeSequence {
  const startScreen = testTree.root().screen(testTree.modifiers.default);
  const startMap = new Map<TransitionModifier, TreeTransition>().set(
    testTree.modifiers.default,
    { out: [], in: startScreen[0], screen: startScreen[1] },
  );

  function enumerateScreens(
    node: PhaseTreeNode | ScreenTreeNode,
  ): Set<ScreenTreeNode> {
    if (node.type === 'phase') {
      return new Set(
        node.children.flatMap((ch) => Array.from(enumerateScreens(ch))),
      );
    } else {
      return new Set([node]);
    }
  }

  const screenNodeMap = enumerateScreens(testTree.root());
  const nodeMap = new Map(
    Array.from(screenNodeMap).map((sn): [ScreenTreeNode, NodePathEntry] => {
      const transitions = new Map(
        Object.values(testTree.modifiers)
          .map((mod) => {
            const next = sn.next(mod);
            if (next) {
              const nextScreen = next[1].screen(mod);
              return [
                mod,
                {
                  out: next[0],
                  in: nextScreen[0],
                  screen: nextScreen[1],
                } as const,
              ] as const;
            } else {
              return undefined;
            }
          })
          .filter(isDefined),
      );
      return [
        sn,
        {
          node: sn,
          screenData: sn.screenData,
          //  controls: sn.controlTransitions,
          transitions,
        },
      ];
    }),
  );
  return {
    tree: testTree,
    start: startMap,
    nodeMap,
  };
}
