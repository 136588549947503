import { BlockInterface, TestInterface, TestResult } from './types';
import { SerializedError } from '@reduxjs/toolkit';

export function createInitialTestSliceState<
  Test extends TestInterface<BlockInterface>,
  Result extends TestResult,
>() {
  const tests: { [testId: string]: Test } = {};
  const results: { [testId: string]: { results: Result[] } } = {};
  const error = null as SerializedError | null;
  return {
    isLoading: false,
    activeTestId: '',
    error,
    tests,
    results,
  };
}
