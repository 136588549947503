import React, { FunctionComponent, useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { Dialog, Fade, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import { ImageInstanceInterface } from '../../features/media/types';
import { ImageInstanceInput } from '../../API';
import StyledIconButton from '../input/StyledIconButton';

const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: 200,
    transition: theme.transitions.create('all'),
  },
  closeButton: {
    background: 'white',
    position: 'absolute',
    right: 8,
    top: 8,
    '&:hover': {
      background: 'white',
    },
  },
}));

const S3Image: FunctionComponent<{
  imgInstance: ImageInstanceInterface | ImageInstanceInput;
  expandedImage?: ImageInstanceInterface | ImageInstanceInput;
  withFade?: boolean;
  fullWidth?: boolean;
  containerScale?: boolean;
}> = ({ imgInstance, withFade, expandedImage, fullWidth, containerScale }) => {
  const classes = useStyles();
  const [srcUri, setSrcUri] = useState<string>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    Storage.get(imgInstance.key).then((s) => setSrcUri(s as string));
  }, [imgInstance.key]);
  return (
    <>
      {srcUri && (
        <>
          {withFade ? (
            <Fade timeout={750} in={Boolean(setSrcUri)}>
              {containerScale ? (
                <img
                  src={srcUri}
                  alt={imgInstance.key}
                  style={{ width: '100%' }}
                />
              ) : (
                <img
                  src={srcUri}
                  alt={imgInstance.key}
                  height={imgInstance.height}
                />
              )}
            </Fade>
          ) : (
            <>
              <Tooltip title={expandedImage ? 'click to expand' : ''}>
                {containerScale ? (
                  <img
                    src={srcUri}
                    onClick={expandedImage ? () => setOpen(!open) : undefined}
                    style={{ cursor: 'pointer', width: '100%' }}
                    alt={imgInstance.key}
                  />
                ) : (
                  <img
                    src={srcUri}
                    onClick={expandedImage ? () => setOpen(!open) : undefined}
                    style={{ cursor: 'pointer' }}
                    alt={imgInstance.key}
                    width={fullWidth ? imgInstance.width : undefined}
                    height={fullWidth ? undefined : imgInstance.height}
                  />
                )}
              </Tooltip>
              {expandedImage && (
                <Dialog
                  open={open}
                  onClose={() => setOpen(false)}
                  maxWidth="lg"
                  className={classes.dialog}
                >
                  <StyledIconButton
                    withShadow
                    className={classes.closeButton}
                    label="close"
                    onClick={() => setOpen(false)}
                  >
                    <CloseIcon />
                  </StyledIconButton>
                  <S3Image fullWidth imgInstance={expandedImage} />
                </Dialog>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default S3Image;
