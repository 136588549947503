import { IatScreenData } from '../loader/view-data/iat-screen-data';
import {
  DisplayData,
  Optional,
  OptionalValue,
} from '../../../subject/testRunner/screens/selectors/data-wrapper';
import { ArrangementVariant } from '../../../subject/testRunner/media/options-test';
import { SelectorTree } from '../../../subject/testRunner/screens/selectors/selector-tree';
import { DrawableMediaInstance } from '../../../subject/testRunner/media/MediaData';
import { InstructionsContent } from '../../../subject/testRunner/screens/basic-views/test-instructions-view';
import { defineOptionSideSelectorFactory } from '../../../subject/testRunner/screens/basic-views/option-test-views';

import { StimulusContent } from '../../../subject/testRunner/screens/basic-views/stimulus-content';
import { IatInstructionsData } from '../loader/view-data/iat-instructions-data';
import { TipsContent } from '../../../subject/testRunner/screens/basic-views/tips-content';
import { IatOptionsData } from '../loader/view-data/iat-options-data';
import { IatOptionsType } from '../loader/structure/iat-options-type';
import { defineContentSelector } from '../../../subject/testRunner/screens/selectors/content-selector';
import { defineGroupSelector } from '../../../subject/testRunner/screens/selectors/group-selector';

const optionSelectorFactory = defineOptionSideSelectorFactory<
  IatOptionsType,
  'attribute' | 'target' | 'attribute-target'
>(
  new ArrangementVariant<IatOptionsType>(['attribute']),
  new ArrangementVariant<IatOptionsType>(['target']),
  new ArrangementVariant<IatOptionsType>(['attribute', 'target']),
);

const iatRootSelector = defineGroupSelector({
  selectGroupData(
    input: IatScreenData,
  ): OptionalValue<IatInstructionsData | IatOptionsData> {
    return input instanceof IatInstructionsData ||
      input instanceof IatOptionsData
      ? Optional.value(input)
      : Optional.none();
  },
  children: {
    instructions: defineContentSelector({
      selectRenderData: (input: IatInstructionsData | IatOptionsData) => {
        return input instanceof IatInstructionsData && input.scope === 'test'
          ? DisplayData.displayData<InstructionsContent>(input.content)
          : DisplayData.hide<InstructionsContent>();
      },
    }),
    blockInstructions: defineContentSelector({
      selectRenderData: (input: IatInstructionsData | IatOptionsData) => {
        return input instanceof IatInstructionsData && input.scope === 'block'
          ? DisplayData.displayData<InstructionsContent>(input.content)
          : DisplayData.hide<InstructionsContent>();
      },
    }),
    options: defineGroupSelector({
      selectGroupData(
        input: IatInstructionsData | IatOptionsData,
      ): OptionalValue<IatOptionsData> {
        return input instanceof IatOptionsData
          ? Optional.value(input)
          : Optional.none();
      },
      children: {
        tips: defineContentSelector({
          selectRenderData: (input: IatOptionsData) => {
            return input.tips
              ? DisplayData.displayData<TipsContent>(input.tips)
              : DisplayData.hide<TipsContent>();
          },
        }),
        stimulus: defineContentSelector({
          selectRenderData: (input: IatOptionsData) => {
            if (!(input.content instanceof InstructionsContent)) {
              return DisplayData.displayData<DrawableMediaInstance | undefined>(
                input.content?.stimulus,
              );
            }
            // the undefined is because of the blank and leads to rendering a blank screen
            return DisplayData.hide<DrawableMediaInstance | undefined>();
          },
        }),
        fail: defineContentSelector({
          selectRenderData: (input: IatOptionsData) => {
            if (
              !(input.content instanceof InstructionsContent) &&
              input.content instanceof StimulusContent
            ) {
              return DisplayData.displayData<undefined>(undefined);
            }
            return DisplayData.hide<undefined>();
          },
        }),
        instructions: defineContentSelector({
          selectRenderData: (input: IatOptionsData) => {
            return input.content instanceof InstructionsContent
              ? DisplayData.displayData<InstructionsContent>(input.content)
              : DisplayData.hide<InstructionsContent>();
          },
        }),
        optionsLeft: optionSelectorFactory('left'),
        optionsRight: optionSelectorFactory('right'),
      },
    }),
  },
});
export const IATSelectorTree = new SelectorTree('iat', iatRootSelector);
