import { ViewBoxes } from '../../../../subject/testRunner/screens/layouting-view';
import { TestInstructionsView } from '../../../../subject/testRunner/screens/basic-views/test-instructions-view';
import React from 'react';
import { WSWSelectorTree } from '../wsw-selectors';
import { WswStructure } from '../../loader/structure/wsw-structure';
import { WswStereotypes } from '../../loader/wsw-controls';
import { createWswGroupIndicationView } from './wsw-group-indication-view';
import { createWswQuestionsView } from './wsw-questions-view';
import { TestScreenComposer } from '../../../../subject/testRunner/screens/composer/test-screen-composer';
import { ViewDefinitionCollection } from '../../../../subject/testRunner/screens/composer/view-definition-collection';
import { LayoutingView } from '../../../../subject/testRunner/screens/composer/views/layouting-view';

export function createWswViewMap(structure: WswStructure) {
  return TestScreenComposer.Create(
    LayoutingView.growing({
      selector: WSWSelectorTree.rootSelector,
      layout: {
        subComponents: ViewDefinitionCollection.Create({
          groupIndication: createWswGroupIndicationView(),
          instructions: TestInstructionsView({
            selector: WSWSelectorTree.rootSelector.instructions,
            control: WswStereotypes.Confirm,
          }),
          questions: createWswQuestionsView(structure),
        }),
        boxes: ViewBoxes.Define((builder) => ({
          groupIndication: builder.fixed('groupIndication'),
          content: builder.growing('instructions', 'questions'),
        })),
      } as const,
      contentComponent: (containerProvider, boxProvider) => {
        return () => {
          return containerProvider.createContainer(
            <div
              style={{
                width:
                  structure.accessor.testContext.deviceInfo.mode === 'mobile'
                    ? '100%'
                    : structure.accessor.modelData.style.maxWidth + '%',
                height: '100%',
                backgroundColor:
                  structure.accessor.modelData.style.backgroundColour ??
                  'white',
                position: 'relative',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '0px',
                }}
              >
                {boxProvider.createBox('groupIndication')}
              </div>
              {boxProvider.createBox('content')}
            </div>,
            (_, style) => ({
              ...style,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
                structure.accessor.modelData.style.backgroundColour ?? 'white',
            }),
          );
        };
      },
    }),
  );
}
