import React from 'react';
import { ErrorMessage, useField } from 'formik';
import { QuestionType, TestQuestionInput } from '../../../../../API';
import { createEmptyTestQuestion } from '../../../testAdmin/testEditor/initialEditorStates';
import {
  Box,
  Button,
  Collapse,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import { TestQuestionInputComponent } from './TestQuestionInputComponent';
import { Add } from '@mui/icons-material';

export function QuestionListEditor({
  fieldName,
  questionsField,
  withImageInQuestions,
  withQuestionTimeout,
  title,
  addQuestionText,
  withImageMCQuestions,
}: {
  title?: React.ReactNode;
  addQuestionText: string;
  fieldName: string;
  withQuestionTimeout: boolean;
  questionsField: string;
  withImageInQuestions: boolean;
  withImageMCQuestions: boolean;
}) {
  const [, { error, value: questions }, questionsHelpers] = useField<
    TestQuestionInput[] | null | undefined
  >(`${fieldName}.${questionsField}`);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenTypeMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseTypeMenu = () => {
    setAnchorEl(null);
  };

  const addQuestion = (type: QuestionType) => () => {
    if (questions) {
      questionsHelpers.setValue([...questions, createEmptyTestQuestion(type)]);
    } else {
      questionsHelpers.setValue([createEmptyTestQuestion(type)]);
    }
    handleCloseTypeMenu();
  };

  const removeQuestion = React.useCallback(
    (qi: number) => {
      if (questions) {
        questionsHelpers.setValue(questions.filter((_, i) => i !== qi));
      }
    },
    [questions, questionsHelpers],
  );
  return (
    <Box>
      {title}
      <Box sx={{ pl: 3 }}>
        {typeof error === 'string' && (
          <ErrorMessage
            name={`${fieldName}.${questionsField}`}
            render={(errorMessage) => (
              <Typography color="error">{errorMessage}</Typography>
            )}
          />
        )}
        <TransitionGroup>
          {questions?.map((_, qi) => (
            <Collapse key={`test-question-collapse-${qi}`}>
              <TestQuestionInputComponent
                key={`test-question-${qi}`}
                index={qi}
                fieldName={`${fieldName}.${questionsField}[${qi}]`}
                withImageInQuestions={withImageInQuestions}
                withQuestionTimeout={withQuestionTimeout}
                onRemove={() => removeQuestion(qi)}
              />
            </Collapse>
          ))}
        </TransitionGroup>
        <Button
          variant="contained"
          id="add-question-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleOpenTypeMenu}
          startIcon={<Add />}
          size="small"
        >
          {addQuestionText}
        </Button>
        <Menu
          id="add-question-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseTypeMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={addQuestion(QuestionType.TEXT)}>
            Text-Question
          </MenuItem>
          <MenuItem onClick={addQuestion(QuestionType.MULTIPLE_CHOICE)}>
            Mulitple-Choice-Question
          </MenuItem>
          {withImageMCQuestions && (
            <MenuItem onClick={addQuestion(QuestionType.IMAGE_MULTIPLE_CHOICE)}>
              Image-Choice-Question
            </MenuItem>
          )}
          <MenuItem onClick={addQuestion(QuestionType.SCALE)}>
            Scale-Question
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
}
