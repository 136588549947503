import { useNavigate, useSearchParams } from 'react-router-dom';
import { TestsMetaData, TestType } from '../../subject/types';
import { EditorMode } from './testEditor/types';
import { BaseTest } from '../types';

type DeepOmitHelper<T, K extends string> = {
  [a in Exclude<keyof T, K>]: T[a] extends object
    ? DeepOmit<T[a], K>
    : T[a] extends (infer A)[]
    ? DeepOmitArray<A, K>
    : T[a];
};
type DeepOmitArray<T, K extends string> = DeepOmitHelper<T, K>[];
type DeepOmit<T extends object, K extends string> = DeepOmitHelper<T, K>;

export const removeKeysDeep = <T extends object, K extends string>(
  value: T,
  ...keys: K[]
): DeepOmit<T, K> => {
  const handleProperties = <V extends any>(
    val: V | V[],
  ): DeepOmitHelper<V, K> | DeepOmitHelper<V, K>[] => {
    if (Array.isArray(val)) {
      return val.map((v) => handleProperties(v)) as DeepOmitHelper<V, K>[];
    }
    if (typeof val === 'object' && val !== null) {
      return removeKeysDeep(val as V & object, ...keys);
    }
    return val as DeepOmitHelper<V, K>;
  };

  return Object.fromEntries(
    Object.entries(value)
      .filter(([k]) => !keys.includes(k as K))
      .map(([k, v]) => [k, handleProperties(v)]),
  ) as DeepOmit<T, K>;
};

export function useTestAdminNav(testType: TestType) {
  const navigate = useNavigate();

  const [, setParams] = useSearchParams();

  return {
    navigateToEditor: (
      mode: EditorMode,
      testId?: string,
      template?: BaseTest,
    ) => {
      navigate(
        `/tests/${TestsMetaData[testType].identifier}/${
          testId ?? 'new'
        }/editor/${mode}`,
        { state: template },
      );
    },
    navigateToDetails: (testId: string, replace?: boolean) =>
      navigate(`/tests/${TestsMetaData[testType].identifier}/${testId}`, {
        replace,
      }),
    navigateToList: (replace?: boolean) =>
      navigate(`/tests/${TestsMetaData[testType].identifier}`, { replace }),
    navigateBack: () => navigate(-1),
    navigateToSetup: () => setParams({ setup: 'true' }),
  };
}
