import React, { FC } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import * as Yup from 'yup';
import StyledSwitch from '../../../../../components/input/StyledSwitch';
import MediaItemPicker from '../../../../media/picker/MediaItemPicker';
import StyledSelect from '../../../../../components/input/StyledSelect';
import {
  MediaItemSnapshot,
  OptionsPosition,
  PodtSceneInput,
  PodtTargetCategoryInput,
  TimedMediaItemInput,
} from '../../../../../API';
import StyledNumberSelect from '../../../../../components/input/StyledNumberSelect';
import MediaItemsPicker from '../../../../media/picker/MidiaItemsPicker/MediaItemsPicker';
import StyledNumberInput from '../../../../../components/input/StyledNumberInput';
import FixationPickerWithToggle, {
  FixationWithToggleSchema,
} from '../../../../../components/input/FixationPickerWithToggle';
import {
  EditorSectionTitle,
  EditorStepTitle,
} from '../../../../../components/grid';
import StyledAccordion from '../../../../../components/StyledAccordion';
import TextStyleDialog from '../../../../../components/TextStyleDialog';
import { Build } from '@mui/icons-material';
import ScenesPicker from '../../../../media/picker/ScenesPicker';
import { useField } from 'formik';

const targetPoolSchema = Yup.array().test('target', 'equal size', function (v) {
  const stimuliPool = v as MediaItemSnapshot[];

  // const { targets } = this.options.context as PodtCreatorState;

  const image = Boolean(stimuliPool.find((m) => m.image));
  const text = Boolean(stimuliPool.find((m) => m.text));
  if (image && text) {
    return this.createError({
      message: 'sets can not have different types',
      path: this.path,
    });
  }

  // eslint-disable-next-line no-restricted-syntax
  // for (const target of targets) {
  //   if (
  //     target.practiceStimuliPool.dangerousStimuli.length !== stimuliPool.length ||
  //     target.practiceStimuliPool.nonDangerousStimuli.length !== stimuliPool.length ||
  //     target.testStimuliPool.dangerousStimuli.length !== stimuliPool.length ||
  //     target.testStimuliPool.nonDangerousStimuli.length !== stimuliPool.length
  //   ) {
  //     return this.createError({ message: "sets have to be equal size", path: this.path });
  //   }
  // }

  return true;
});

export const PodtStimuliSettingsSchema = Yup.object().shape({
  fixation: FixationWithToggleSchema,
  response: Yup.object().shape({
    timeout: Yup.number().required('required').min(1),
    active: Yup.object().test(
      'hasStimulus',
      'categoryA stimulus must be chosen.',
      (value: any) => Boolean(value.image || value.text),
    ),
    passive: Yup.object().test(
      'hasStimulus',
      'categoryA stimulus must be chosen.',
      (value: any) => Boolean(value.image || value.text),
    ),
  }),
  scenes: Yup.object().shape({
    maxAmountScenesPerTrial: Yup.number().min(1, 'must be at least one'),
    scenes: Yup.array()
      .min(1, 'not enough')
      .test('scene', 'equal dimensions', function (v) {
        const firstDim = {
          width:
            (v as PodtSceneInput[])[0]?.mediaItem.image?.fullSize.width || 0,
          height:
            (v as PodtSceneInput[])[0]?.mediaItem.image?.fullSize.height || 0,
        };

        for (const scene of v as PodtSceneInput[]) {
          const fullSizeImage = scene.mediaItem.image?.fullSize;

          if (fullSizeImage) {
            const dimensions = {
              width: fullSizeImage.width,
              height: fullSizeImage.height,
            };

            if (
              firstDim.width !== dimensions.width ||
              firstDim.height !== dimensions.height
            ) {
              return this.createError({
                message: 'scenes have to be equal dimensions',
                path: this.path,
              });
            }
          }
        }

        return true;
      }),
    interval: Yup.object().shape({
      min: Yup.number().min(1, 'to low'),
      max: Yup.number().min(1, 'to high'),
      stepSize: Yup.number().min(1, 'to small'),
    }),
  }),
  targets: Yup.array()
    .min(1)
    .of(
      Yup.object().shape({
        practiceStimuliPool: Yup.object().required().shape({
          dangerousStimuli: targetPoolSchema,
          nonDangerousStimuli: targetPoolSchema,
        }),
        testStimuliPool: Yup.object().required().shape({
          dangerousStimuli: targetPoolSchema,
          nonDangerousStimuli: targetPoolSchema,
        }),
      }),
    ),
});

const PodtStimuliSettings: FC<{ podta: boolean }> = ({ podta }) => {
  const [, { value: fixation }] = useField<TimedMediaItemInput | undefined>(
    'fixation',
  );
  const [, { value: targets }] = useField<PodtTargetCategoryInput[]>('targets');

  const responses = (
    <Stack spacing={3} sx={{ width: '50%' }}>
      <Stack direction="row" spacing={2}>
        <StyledSelect name="style.optionsPosition" label="Response Position">
          <option value={OptionsPosition.TOP}>top</option>
          <option value={OptionsPosition.MIDDLE}>middle</option>
          <option value={OptionsPosition.BOTTOM}>bottom</option>
        </StyledSelect>
        <StyledSelect
          name="style.optionsPositionMobile"
          label="Response Position Mobile"
        >
          <option value={OptionsPosition.TOP}>top</option>
          <option value={OptionsPosition.MIDDLE}>middle</option>
          <option value={OptionsPosition.BOTTOM}>bottom</option>
        </StyledSelect>
        <TextStyleDialog
          label={'Response Category Style'}
          name={'response.fontStyle'}
        >
          <Build />
        </TextStyleDialog>
      </Stack>
      <StyledNumberInput
        name="response.timeout"
        label="Response Timeout"
        unit="ms"
      />
      <MediaItemPicker
        name="response.active"
        label={
          podta ? 'Response Category A (Left)' : 'Response Category A (Weapon)'
        }
        colorFieldName={'response.fontStyle.color'}
        bgColorFieldName={'style.backgroundColour'}
      />
      <MediaItemPicker
        name="response.passive"
        label={
          podta ? 'Response Category B (Right)' : 'Response Category B (Object)'
        }
        colorFieldName={'response.fontStyle.color'}
        bgColorFieldName={'style.backgroundColour'}
      />
    </Stack>
  );
  const scenes = (
    <Box width="50%">
      <Grid container spacing={3} alignContent="flex-start">
        <Grid item xs={12}>
          <ScenesPicker />
        </Grid>
        <Grid item xs={12}>
          <StyledNumberSelect
            name="scenes.maxAmountScenesPerTrial"
            label="max # Scenes per Trial"
            defaultValue={4}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledNumberInput
            name="scenes.intervalConfig.min"
            label="min"
            unit="ms"
          />
        </Grid>
        <Grid item xs={12}>
          <StyledNumberInput
            name="scenes.intervalConfig.max"
            label="max"
            unit="ms"
          />
        </Grid>
        <Grid item xs={12}>
          <StyledNumberInput
            name="scenes.intervalConfig.stepSize"
            label="Step-Size"
            unit="ms"
          />
        </Grid>
      </Grid>
    </Box>
  );
  const targetsEl = targets.map((target, targetIndex) => {
    const targetPath = `targets[${targetIndex}]`;
    return (
      <Grid container xs={12} md={9} key={`target-${targetIndex}`}>
        <StyledAccordion label={target.label}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" color="textSecondary">
                Practice Pools
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <MediaItemsPicker
                name={`${targetPath}.practiceStimuliPool.dangerousStimuli`}
                label="Armed Stimuli Set"
              />
            </Grid>
            <Grid item xs={12}>
              <MediaItemsPicker
                name={`${targetPath}.practiceStimuliPool.nonDangerousStimuli`}
                label="Unarmed Stimuli Set"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" color="textSecondary">
                Test Pools
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <MediaItemsPicker
                name={`${targetPath}.testStimuliPool.dangerousStimuli`}
                label="Armed Stimuli Set"
              />
            </Grid>
            <Grid item xs={12}>
              <MediaItemsPicker
                name={`${targetPath}.testStimuliPool.nonDangerousStimuli`}
                label="Unarmed Stimuli Set"
              />
            </Grid>
          </Grid>
        </StyledAccordion>
      </Grid>
    );
  });

  return (
    <Stack spacing={3}>
      <EditorStepTitle prefix={3} title="Stimuli Settings" />
      <Stack width="50%" spacing={3}>
        <StyledNumberInput
          name="interTrialInterval"
          label="Inter Trial Interval"
          unit="ms"
          toolTip={'Time in ms between two trials'}
        />
        <StyledSwitch
          name="probabilityWithoutReplacement"
          label="Probability Without Replacement"
          left="Off"
          right="On"
          tooltip={
            // <React.Fragment>
            //   If "Probability without replacement" = on, then Stimuli are picked from pool (once) and are not
            //   replaced.<br />
            //   If "Probability without replacement" = off, then Stimuli are picked from pool and are being returned to
            //   pool after wards.
            // </React.Fragment>
            <React.Fragment>
              <b>ON</b> {'->'} Stimuli are picked from pool (once) and are not
              replaced.
              <br />
              <b>OFF</b> {'->'} Stimuli are picked from pool and are being
              returned to pool afterwards.
            </React.Fragment>
          }
        />
      </Stack>
      <FixationPickerWithToggle
        fixationOn={Boolean(fixation)}
        stylesEditable={true}
      />
      {responses}
      {scenes}
      <EditorSectionTitle subtitle="Targets" />
      {targetsEl}
    </Stack>
  );
};

export default PodtStimuliSettings;
