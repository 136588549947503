import { SurveyProvider } from '../../../API';
import { MenuItem, Select } from '@mui/material';

export function SurveyProviderSelect({
  providers,
  selectedProvider,
  setSelectedProvider,
}: {
  providers: SurveyProvider[];
  selectedProvider: SurveyProvider | undefined;
  setSelectedProvider: (provider?: SurveyProvider) => void;
}) {
  return (
    <Select
      value={selectedProvider?.id ?? ''}
      onChange={(provider) =>
        setSelectedProvider(
          providers.find((p) => p.id === provider.target.value) ?? undefined,
        )
      }
    >
      {providers.map((provider) => (
        <MenuItem value={provider.id}>{provider.name}</MenuItem>
      ))}
    </Select>
  );
}
