import { TestResourceAccessor } from '../../../../subject/testRunner/media/test-resource-loader';
import { Podta } from '../../types';
import { PodtMediaPool } from '../../../PODT/loader/loading/podt-loader';
import { pickPodtaBlockNew } from '../../../PODT/creator/podt-picker';
import {
  createPodtOrPodtaStructure,
  PodtaStructure,
} from '../../../PODT/loader/structure/podt-structure';

export function createPodtaStructure(
  accessor: TestResourceAccessor<Podta>,
  pool: PodtMediaPool,
): PodtaStructure {
  return createPodtOrPodtaStructure<Podta>(
    accessor,
    pool,
    pickPodtaBlockNew,
    false,
  );
}
