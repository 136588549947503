import React, { FunctionComponent } from 'react';
import { useField } from 'formik';
import { Box, TextField, TextFieldProps, Tooltip } from '@mui/material';
import { UiInput } from './types';
import { InfoOutlined } from '@mui/icons-material';

type StyledTextInputProps = UiInput<string> &
  TextFieldProps & {
    toolTip?: string;
  };

const StyledTextInput: FunctionComponent<StyledTextInputProps> = (props) => {
  const { toolTip, ...rest } = props;
  const [, meta, helpers] = useField<typeof rest.value>(rest.name);

  const onChangeTextField = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (rest.onChange) {
      rest.onChange(ev.target.value);
    } else {
      helpers.setValue(ev.target.value);
    }
  };

  const showView = props.disabled;

  return (
    <Box position="relative">
      <TextField
        fullWidth
        value={meta.value}
        onChange={showView ? undefined : onChangeTextField}
        focused={showView ? false : undefined}
        sx={{
          caretColor: showView ? 'transparent' : undefined,
        }}
        error={meta.touched && meta.error !== undefined}
        helperText={(meta.touched && meta.error) || ''}
        {...rest}
      />
      {toolTip && (
        <Tooltip title={toolTip} placement="left-start">
          <InfoOutlined
            sx={{
              color: 'warning.dark',
              bgcolor: 'white',
              position: 'absolute',
              top: -10,
              right: -10,
            }}
          />
        </Tooltip>
      )}
    </Box>
  );
};

export default StyledTextInput;
