export class TestFinishEvent extends CustomEvent<{}> {
  static EventName = '_TestFinishEvent';

  constructor() {
    super(TestFinishEvent.EventName, {
      bubbles: true,
      composed: false,
      detail: {},
      cancelable: false,
    });
  }

  static dispatchFinishOccurrence(eventTarget: EventTarget) {
    const event = new TestFinishEvent();
    const target = eventTarget;
    if (!target) {
      throw new Error('event ');
    }
    target.dispatchEvent(event);
  }
}
