export interface TransitionModifier {
  readonly code: string | undefined;
}

export class SimpleTransitionModifier implements TransitionModifier {
  constructor(public readonly code: string | undefined) {}
}

export function defineTransitionModifiers<M extends string>(
  modifiers: M[],
): Record<'default' | M, TransitionModifier> {
  return {
    default: new SimpleTransitionModifier(undefined),
    ...(Object.fromEntries(
      modifiers.map((m) => [m, new SimpleTransitionModifier(m)]),
    ) as Record<M, TransitionModifier>),
  };
}
