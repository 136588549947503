import {
  createAsyncAppThunk,
  TestResultsThunkOutput,
} from '../../../../app/redux/thunk';
import { Amp, AmpResult } from '../types';
import GQL from '../../../../GQL';
import {
  getAmpTest,
  listAmpTests,
  ampResultsByTestId,
} from '../../../../graphql/queries';
import {
  CreateAmpTestInput,
  CreateAmpTestMutationVariables,
  DeleteAmpResultMutationVariables,
  GetAmpTestQueryVariables,
  AmpResultsByTestIdQueryVariables,
  TestStatus,
  UpdateAmpTestInput,
  UpdateAmpTestMutationVariables,
  FunctionBodyResponse,
  DeleteTestMutationVariables,
} from '../../../../API';
import {
  createAmpTest,
  deleteAmpResult,
  deleteTest,
  updateAmpTest,
} from '../../../../graphql/mutations';

export const fetchAmpTest = createAsyncAppThunk<Amp, string>('amp/get', (id) =>
  GQL.get<GetAmpTestQueryVariables, Amp>(getAmpTest, { id }),
);

export const fetchAmpTests = createAsyncAppThunk('amp/list', () =>
  GQL.list<Amp>(listAmpTests),
);

export const fetchAmpResults = createAsyncAppThunk<
  TestResultsThunkOutput<AmpResult>,
  { testId: string }
>('amp/results', async ({ testId }) => {
  const results = await GQL.list<AmpResult, AmpResultsByTestIdQueryVariables>(
    ampResultsByTestId,
    { testId },
  );
  return { testId, results };
});

export const updateAmpAsync = createAsyncAppThunk<Amp, UpdateAmpTestInput>(
  'amp/update',
  (input) =>
    GQL.mutate<Amp, UpdateAmpTestMutationVariables>(updateAmpTest, { input }),
);

export const updateAmpStatusAsync = createAsyncAppThunk<
  Amp,
  { testId: string; status: TestStatus }
>('amp/update/status', ({ status, testId }) =>
  GQL.mutate<Amp, UpdateAmpTestMutationVariables>(updateAmpTest, {
    input: { id: testId, status },
  }),
);

export const createAmpAsync = createAsyncAppThunk<Amp, CreateAmpTestInput>(
  'amp/create',
  (arg) => {
    return GQL.mutate<Amp, CreateAmpTestMutationVariables>(createAmpTest, {
      input: arg,
    });
  },
);

export const deleteAmpResultsAsync = createAsyncAppThunk<
  { testId: string; resultsIds: string[] },
  { testId: string; resultsIds: string[] }
>('amp/delete/results', async (arg) => {
  await Promise.all(
    arg.resultsIds.map((id) =>
      GQL.mutate<AmpResult, DeleteAmpResultMutationVariables>(deleteAmpResult, {
        input: { id },
      }),
    ),
  );
  return arg;
});

export const deleteAmpAsync = createAsyncAppThunk<string, string>(
  'amp/delete',
  async (testId) => {
    await GQL.mutate<FunctionBodyResponse, DeleteTestMutationVariables>(
      deleteTest,
      {
        testId,
        testType: 'AMP',
      },
    );
    return testId;
  },
);
