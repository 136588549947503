import React from 'react';
import {
  ImageInstanceInput,
  MediaItemSnapshotInput,
  MediaItemSnapshotScope,
} from '../../../API';
import { uploadImageToStorage } from '../redux/mediaApi';
import { v4 as uuid } from 'uuid';

export function useUploadImageToStorage(): {
  uploadImages: (
    file: File,
  ) => Promise<{ fullSize: ImageInstanceInput; thumbNail: ImageInstanceInput }>;
  uploadState: 'idle' | 'loading' | 'error';
} {
  const [uploadState, setUploadState] = React.useState(
    'idle' as 'idle' | 'loading' | 'error',
  );
  return {
    uploadState,
    uploadImages: async (file: File) => {
      setUploadState('loading');

      try {
        const imageData = await uploadImageToStorage(file);
        setUploadState('idle');
        return imageData;
      } catch (e) {
        console.error('failed to upload images', e);
        setUploadState('error');
        throw e;
      }
    },
  };
}

export function useUploadPrivateImage(): {
  uploadImages: (files: File[]) => Promise<MediaItemSnapshotInput[]>;
  uploadState: 'idle' | 'loading' | 'error';
} {
  const [uploadState, setUploadState] = React.useState(
    'idle' as 'idle' | 'loading' | 'error',
  );
  return {
    uploadState,
    uploadImages: async (files: File[]) => {
      setUploadState('loading');

      try {
        const storageResults = await Promise.all(
          files.map(async (file) => {
            const storageResult = await uploadImageToStorage(file);
            setUploadState('idle');
            return {
              id: uuid(),
              image: {
                fullSize: storageResult.fullSize,
                thumbNail: storageResult.thumbNail,
              },
              scope: MediaItemSnapshotScope.PRIVATE,
              text: null,
              tags: [],
              originalFileName: file.name,
            };
          }),
        );
        return storageResults;
      } catch (e) {
        console.error('failed to upload images', e);
        setUploadState('error');
        throw e;
      }
    },
  };
}
