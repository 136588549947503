export interface SequenceTask<I, O> {
  name: string;
  executor: (input: I) => O | Promise<O>;
}

class SequenceTaskSet<I, O> {
  constructor(protected tasks: SequenceTaskBuilder<I, any, O>[]) {}
  runSet(
    runner: <RI, RO>(task: SequenceTask<RI, RO>, value: RI) => Promise<RO> | RO,
    initial: I,
  ): Promise<O> {
    const tasks = this.tasks.map((t) => t.createTask());
    return tasks.reduce(
      async (acc, c) => {
        const value = await acc;
        return runner(c, value);
      },
      Promise.resolve(initial) as Promise<any>,
    ) as Promise<O>;
  }
}

export interface PhaseRunner {
  runPhase<I, O>(phase: SequencePhase<I, O>, input: I): O | Promise<O>;
  initPhaseData<I, O, V>(
    phase: SequencePhase<I, O>,
    init: (value: V) => I | Promise<I>,
    value: V,
  ): I | Promise<I>;
}
class PhaseTaskTimer {
  phaseTimer: PhaseTimer;
  start: number;
  name: string;

  constructor(phaseTimer: PhaseTimer, start: number, name: string) {
    this.phaseTimer = phaseTimer;
    this.start = start;
    this.name = name;
  }

  finish() {}
}
class PhaseTimer {
  globalStart: number = performance.now();

  startTask(name: string): PhaseTaskTimer {
    return new PhaseTaskTimer(this, performance.now(), name);
  }
}

export class DefaultPhaseRunner implements PhaseRunner {
  phaseTimer = new PhaseTimer();
  constructor(protected taskRunner: TaskRunner = DefaultTaskRunner) {}

  async initPhaseData<I, O, V>(
    phase: SequencePhase<I, O>,
    init: (value: V) => Promise<I> | I,
    value: V,
  ): Promise<I> {
    const taskTimer = this.phaseTimer.startTask(phase.name + ' - init');
    const resultRaw = init(value);
    const result = resultRaw instanceof Promise ? await resultRaw : resultRaw;
    taskTimer.finish();
    return result;
  }

  async runPhase<I, O>(phase: SequencePhase<I, O>, input: I): Promise<O> {
    const taskTimer = this.phaseTimer.startTask(phase.name + ' - execute');
    const result = await phase.taskSet.runSet(
      (task, value) => this.taskRunner.runTask(phase, task, value),
      input,
    );
    taskTimer.finish();
    return result;
  }
}

export interface TaskRunner {
  runTask<I, O>(
    phase: SequencePhase<any, any>,
    task: SequenceTask<I, O>,
    value: I,
  ): O | Promise<O>;
}
export const DefaultTaskRunner: TaskRunner = {
  async runTask<I, O>(
    phase: SequencePhase<any, any>,
    task: SequenceTask<I, O>,
    value: I,
  ): Promise<O> {
    const resultRaw = task.executor(value);
    //console.log("ended task "+phase.name+": "+task.name, result);
    return resultRaw instanceof Promise ? await resultRaw : resultRaw;
  },
};

export interface SequencePhase<I, O> {
  name: string;
  readonly taskSet: SequenceTaskSet<I, O>;
}
export class SequencePhaseBuilder<
  I,
  R,
  O,
  M extends Record<string, I | ((before: any) => any)>,
> {
  constructor(
    protected phases: SequencePhaseBuilder<any, any, any, any>[],
    protected name: string,
    protected taskSet: SequenceTaskSet<R, O>,
  ) {
    this.phases = [...phases, this];
  }
  chain<N extends string, I1, O1>(
    name: N,
    taskBuilder: SequenceTaskBuilder<I1, any, O1>,
  ): SequencePhaseBuilder<I, I1, O1, M & { [n in N]: (o: O) => I1 }> {
    return new SequencePhaseBuilder(
      [...this.phases],
      name,
      taskBuilder.createTaskSet(),
    );
  }
  createPhase(): SequencePhase<R, O> {
    return {
      name: this.name,
      taskSet: this.taskSet,
    };
  }
  createPhases(): {
    performSequence: (map: M, runner: PhaseRunner) => Promise<O>;
  } {
    return {
      performSequence: async (map: M, runner: PhaseRunner) => {
        const phaseInstances = this.phases.map((p) => p.createPhase());
        return phaseInstances.reduce(
          async (acc, c) => {
            const before = await acc;
            const input =
              typeof map[c.name] === 'function'
                ? await runner.initPhaseData(c, map[c.name] as any, before)
                : map[c.name];
            return await runner.runPhase(c, input);
          },
          Promise.resolve(undefined as any),
        );
      },
    };
  }
  static Builder<I1>() {
    return {
      start: <N1 extends string, O1>(
        name: N1,
        taskSet: SequenceTaskBuilder<I1, any, O1>,
      ) => {
        return new SequencePhaseBuilder<I1, I1, O1, { [n in N1]: I1 }>(
          [],
          name,
          taskSet.createTaskSet(),
        );
      },
    };
  }
}

export class SequenceTaskBuilder<I, R, O> {
  constructor(
    protected tasks: SequenceTaskBuilder<any, any, any>[],
    protected transformer: (i: R) => O | Promise<O>,
    protected name: string,
  ) {
    this.tasks = [...tasks, this];
  }

  createTask(): SequenceTask<R, O> {
    return {
      name: this.name,
      executor: async (input: R) => {
        return this.transformer(input);
      },
    };
  }

  createTaskSet() {
    return new SequenceTaskSet<I, O>(this.tasks);
  }
  chainTask<NO>(
    name: string,
    transformer: (i: O) => NO | Promise<NO>,
  ): SequenceTaskBuilder<I, O, NO> {
    return new SequenceTaskBuilder([...this.tasks], transformer, name);
  }

  static Builder<I1>() {
    return {
      start: <O1>(name: string, transformer: (i: I1) => O1 | Promise<O1>) =>
        SequenceTaskBuilder.Start(name, transformer),
    };
  }

  static Start<I, O>(name: string, transformer: (i: I) => O | Promise<O>) {
    return new SequenceTaskBuilder([], transformer, name);
  }
}
