import React, { FC } from 'react';
import { View } from '../views/view';
import { ViewInstanceController } from '../screen-instance/view-instance-controller';
import { BoxHandler } from './box-handler';
import { ObjectTransformer } from '../../../media/drawable/drawable-collector';
import { BoxRenderProvider } from '../../layouting-view';
import { StyleCB } from '../views/render-container-provider';

export class DefaultBoxRenderProvider<T extends string>
  implements BoxRenderProvider<T>
{
  private boxMap: Record<T, (styleCB?: StyleCB) => FC>;

  constructor(
    public name: string,
    viewControllerMap: Map<View, ViewInstanceController>,
    boxes: Record<string, BoxHandler>,
  ) {
    this.boxMap = ObjectTransformer.of(boxes).mapValues(
      (v) => (styleCB?: StyleCB) =>
        v.createRenderComponent(viewControllerMap, styleCB),
    ).result;
  }

  createBox(name: T, styleCB?: StyleCB): JSX.Element {
    const RenderComponent = this.boxMap[name](styleCB);
    return <RenderComponent />;
  }
}
