import React, { FC } from 'react';
import { Wsw } from '../../types';
import { Box, Stack } from '@mui/material';
import { BorderBox } from '../../../../../components/BorderBox';
import { TextDisplay } from '../../../../../components/TextDisplay';
import { translationInfoMap } from '../../../../subject/testRunner/state/baseTestContext/app-configuration/translations/translation-map';

export const WswGameSettingsView: FC<{ test: Wsw }> = ({ test }) => {
  return (
    <Stack spacing={3}>
      <Stack spacing={5} direction="row">
        <TextDisplay
          label="Language"
          value={
            test.language ? translationInfoMap[test.language].languageName : '-'
          }
          suffix=""
        />
        <TextDisplay label="Width" value={test.style.maxWidth} suffix="%" />
        <TextDisplay
          label="Background"
          value={test.style.backgroundColour}
          suffix={
            <Box
              sx={{
                borderRadius: '50%',
                border: 1,
                height: 24,
                width: 24,
                bgcolor: test.style.backgroundColour,
              }}
            />
          }
        />
      </Stack>
      <BorderBox label="Welcome screen">
        <div dangerouslySetInnerHTML={{ __html: test.instructions.desktop }} />
      </BorderBox>
      <BorderBox label="End screen">
        <div
          dangerouslySetInnerHTML={
            test.endScreen?.desktop
              ? { __html: test.endScreen.desktop }
              : undefined
          }
        />
      </BorderBox>
      <Stack spacing={3} direction="row">
        <TextDisplay label="Start Label" value={test.startLabel} />
        <TextDisplay label="next Label" value={test.nextLabel} />
        <TextDisplay label="Finish Label" value={test.finishLabel} />
      </Stack>
    </Stack>
  );
};
