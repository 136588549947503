import React, { FC } from 'react';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useField } from 'formik';
import { MultipleChoiceQuestionInput } from '../../../../../API';
import StyledTextInput from '../../../../../components/input/StyledTextInput';
import { Add, Delete, InfoOutlined } from '@mui/icons-material';
import { TransitionGroup } from 'react-transition-group';
import { FormikCheckboxField } from '../../../../../components/input/FormikCheckboxField';

export const MultipleChoiceQuestionComponent: FC<{ fieldName: string }> = ({
  fieldName,
}) => {
  const [, meta, helpers] = useField<MultipleChoiceQuestionInput | undefined>(
    fieldName,
  );

  const addOption = () => {
    if (meta.value) {
      helpers.setValue({
        ...meta.value,
        options: [...meta.value?.options, ''],
      });
    }
  };

  const removeOption = (optionIndex: number) => {
    if (meta.value) {
      helpers.setValue({
        ...meta.value,
        options: meta.value?.options.filter((_, oi) => optionIndex !== oi),
      });
    }
  };

  return (
    <Box sx={{ ml: 2 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography color="textSecondary">Options</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormikCheckboxField
            name={`${fieldName}.randomized`}
            label={'Randomize options'}
            size="small"
          />
          <Tooltip
            placement={'right'}
            title={
              'Show answer options in randomized order in the horizontal presentation  from left to right.'
            }
          >
            <InfoOutlined
              sx={{
                color: 'warning.dark',
                bgcolor: 'white',
                ml: '-15px',
              }}
            />
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ pl: 2 }}>
          <TransitionGroup>
            {meta.value?.options.map((o, oi) => (
              <Collapse key={`option-collapse-${oi}`}>
                <StyledTextInput
                  size="small"
                  key={`option-${oi}`}
                  label={`Option ${oi + 1}`}
                  name={`${fieldName}.options[${oi}]`}
                  sx={{ my: 1 }}
                  InputProps={{
                    endAdornment: (
                      <IconButton size="small" onClick={() => removeOption(oi)}>
                        <Delete fontSize="small" />
                      </IconButton>
                    ),
                  }}
                />
              </Collapse>
            ))}
          </TransitionGroup>
          {meta.error && (
            <Typography variant="body2" color="error">
              {(meta.error as any).options}
            </Typography>
          )}
        </Box>
        <Button
          sx={{ marginRight: 'auto' }}
          /*variant="contained"*/
          startIcon={<Add />}
          size="small"
          onClick={addOption}
          color="inherit"
        >
          Add Option
        </Button>
      </Box>
    </Box>
  );
};
