import React, { FC } from 'react';
import { useField } from 'formik';
import { BlockType, CategoryVariant, IatBlockInput } from '../../../../../API';
import { Box, Button, Grid } from '@mui/material';
import BlockSettings from '../../../testAdmin/testEditor/BlockSettings';
import { TestType } from '../../../../subject/types';
import IatBlockCategorySelector from '../IatCategorySelector';
import CategorySwitcher from '../components/CategorySwitcher';
import StyledSwitch from '../../../../../components/input/StyledSwitch';
import { Add } from '@mui/icons-material';
import { IatVariables } from '../../../text-replacer/textReplacer';

const getAmountTrialsDivisibleBy = (
  variants: (CategoryVariant | undefined | null)[],
): number => {
  if (variants[0] && variants[1]) {
    return 4;
  }
  if (variants[0] && !variants[1]) {
    return 2;
  }
  if (!variants[0] && variants[1]) {
    return 2;
  }
  return 2;
};

export const IatBlocksInput: FC<{ fieldName: string }> = ({ fieldName }) => {
  const [, { value: blocks }, helpers] = useField<IatBlockInput[]>(fieldName);
  const [, { value: counterbalancing }] = useField<boolean>('counterbalancing');

  const handleAddBlock = () => {
    const updatedBlocks: IatBlockInput[] = [
      ...blocks,
      {
        index: blocks.length,
        name: '',
        amountTrials: 10,
        attributeCategoryVariant: CategoryVariant.DEFAULT,
        instructions: {
          desktop: '',
        },
        type:
          blocks.length % 2 === 1 ? BlockType.NON_PRACTICE : BlockType.PRACTICE,
        categoryLabelsInInstructions: false,
      },
    ];
    helpers.setValue(updatedBlocks);
  };

  return (
    <React.Fragment>
      {blocks.map(
        ({ targetCategoryVariant, attributeCategoryVariant, name }, index) => {
          const amountTrialsDivisibleBy = getAmountTrialsDivisibleBy([
            targetCategoryVariant,
            attributeCategoryVariant,
          ]);
          return (
            <Box width={'50%'} key={`block${index}`}>
              <BlockSettings
                name={name}
                testType={TestType.IAT}
                amountTrialsDivisibleBy={amountTrialsDivisibleBy}
                index={index}
                counterBalancing={counterbalancing}
                removeBlock={() =>
                  helpers.setValue(blocks.filter((c, i) => i !== index))
                }
                variables={IatVariables}
              >
                <Grid item xs={12}>
                  <IatBlockCategorySelector
                    blockName={`blocks[${index}]`}
                    toolTip={
                      'Select which categories should be displayed in this block: Only targets, only attributes or both'
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <CategorySwitcher
                    label="Target"
                    categoryName="targetCategories"
                    variantName={`blocks[${index}].targetCategoryVariant`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CategorySwitcher
                    label="Attribute"
                    categoryName="attributeCategories"
                    variantName={`blocks[${index}].attributeCategoryVariant`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledSwitch
                    name={`blocks[${index}].categoryLabelsInInstructions`}
                    label={'Category Labels in Instructions'}
                    left={'No'}
                    right={'Yes'}
                    tooltip={
                      'if yes, shows categories also during block-instructions'
                    }
                  />
                </Grid>
              </BlockSettings>
            </Box>
          );
        },
      )}
      <Button
        onClick={handleAddBlock}
        startIcon={<Add />}
        title="Add"
        variant="contained"
        color="primary"
        sx={{ width: 250 }}
      >
        Add Block
      </Button>
    </React.Fragment>
  );
};
