import { createRoot, Root } from 'react-dom/client';

export interface ReactDOMRootWrapper {
  render(element: JSX.Element): void;

  unmount(): void;
}
/*
class React17DOMRootWrapper implements ReactDOMRootWrapper {
  private mounted = false;
  constructor(private domRoot: HTMLElement) {}

  render(element: JSX.Element) {
    if (this.mounted) {
      unmountComponentAtNode(this.domRoot);
    }
    ReactDOM.render(element, this.domRoot);
    this.mounted = true;
  }

  unmount() {
    unmountComponentAtNode(this.domRoot);
    this.mounted = false;
  }
}*/

class React18DOMRootWrapper implements ReactDOMRootWrapper {
  private mounted = false;
  private reactRoot: Root;
  constructor(private domRoot: HTMLElement) {
    this.reactRoot = createRoot(this.domRoot);
  }

  render(element: JSX.Element) {
    if (this.mounted) {
      this.reactRoot.unmount();
    }
    this.reactRoot.render(element);
    this.mounted = true;
  }

  unmount() {
    this.reactRoot.unmount();
    this.mounted = false;
  }
}

export const ReactRootWrapper = React18DOMRootWrapper;
