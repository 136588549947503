import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE, GraphQLResult } from '@aws-amplify/api-graphql';
import GqlError from './features/errorHandeling/GqlError';
import Log from './features/logger';

export type GraphQLQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};
type GraphQLMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export enum GQLQueryType {
  REQUEST = 'REQUEST',
  MUTATE = 'MUTATE',
  GET = 'GET',
  LIST = 'LIST',
}

export interface GQLQueryDescriptor<V extends object> {
  query: string;
  variables: V;
  code: string;
}

export interface NewGQLClient {
  request<V extends object, R>(
    queryDescriptor: GQLQueryDescriptor<V>,
  ): Promise<R>;
}

export class NewGQL implements NewGQLClient {
  private readonly authMode: GRAPHQL_AUTH_MODE;

  protected constructor(authMode: GRAPHQL_AUTH_MODE) {
    this.authMode = authMode;
  }

  async request<V extends object, R>(query: GQLQueryDescriptor<V>): Promise<R> {
    try {
      const result = (await API.graphql({
        query: query.query,
        variables: query.variables,
        authMode: this.authMode,
      })) as GraphQLResult<Record<string, R>>;
      if (result.data) {
        return result.data[Object.keys(result.data)[0]];
      }
    } catch (e) {
      const gqlError = new GqlError(
        `${query.code}_ERROR`,
        query.query,
        query.variables,
        e,
      );
      Log.apiError(gqlError);
      throw gqlError;
    }

    const unknownError = new GqlError(
      `UNKNOWN_${query.code}_ERROR`,
      query.query,
      query.variables,
    );
    Log.apiError(unknownError);
    throw unknownError;
  }

  execute<V extends object, R>(
    queryInstance: GQLQueryInstance<V, R>,
  ): Promise<R> {
    return queryInstance.execute(this);
  }

  runMutation<M extends GraphQLMutation<unknown, unknown>>(
    query: M,
    input: M extends GraphQLMutation<infer I, unknown>
      ? I extends object
        ? I
        : never
      : never,
  ): Promise<M extends GraphQLMutation<unknown, infer O> ? O : never> {
    return this.request({
      code: GQLQueryType.MUTATE,
      query,
      variables: input,
    });
  }
  static readonly DEFAULT_CLIENT = new NewGQL(
    GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  );
  static readonly NO_AUTH_CLIENT = new NewGQL(GRAPHQL_AUTH_MODE.AWS_IAM);
}

export interface GQLQueryInstance<V extends object, R>
  extends GQLQueryDescriptor<V> {
  readonly query: string;
  readonly variables: V;
  readonly code: string;
  execute: (gql: NewGQLClient) => Promise<R>;
}

class GQLDefaultQueryInstance<V extends object, R>
  implements GQLQueryInstance<V, R>, GQLQueryDescriptor<V>
{
  constructor(
    private queryObj: GQLQuery<V, R>,
    public variables: V,
    private executor: (
      gql: NewGQLClient,
      instance: GQLQueryInstance<V, R>,
    ) => Promise<R>,
  ) {}

  get query() {
    return this.queryObj.query;
  }

  get code() {
    return this.queryObj.queryType;
  }

  execute(gql: NewGQLClient): Promise<R> {
    return this.executor(gql, this);
  }
}

type BuilderAttributeMap = Record<string, (...args: any[]) => any>;
type BuilderResult<M extends BuilderAttributeMap> = {
  [n in keyof M]: ReturnType<M[n]>;
};
export class DataBuilderTest<
  M extends BuilderAttributeMap,
  R extends keyof M,
  ER extends BuilderResult<M>,
> {
  helperMap: M;
  results: Omit<BuilderResult<M>, R>;
  builder: Omit<BuilderResult<M>, R> extends BuilderResult<M>
    ? { build(): ER }
    : {
        [n in R]: (
          ...args: Parameters<M[n]>
        ) => DataBuilderTest<M, Exclude<R, n>, ER>['builder'];
      };

  constructor(
    helperMap: M,
    results: Omit<BuilderResult<M>, R>,
    private buildCallback: (result: BuilderResult<M>) => ER,
  ) {
    this.helperMap = helperMap;
    this.results = results;
    this.builder =
      Object.keys(results).length < Object.keys(helperMap).length
        ? (Object.fromEntries(
            Object.entries(this.helperMap)
              .filter(([k]) => !Object.keys(results).includes(k))
              .map(([k, v]) => {
                return [
                  k,
                  (...args: any[]) => {
                    const entry = this.helperMap[k](...args);
                    return new DataBuilderTest(
                      this.helperMap,
                      { ...this.results, [k]: entry },
                      this.buildCallback,
                    );
                  },
                ];
              }),
          ) as any)
        : {
            build: (): ER => {
              return this.buildCallback(this.results as BuilderResult<M>);
            },
          };
  }

  static Create<M extends BuilderAttributeMap, ER extends BuilderResult<M>>(
    map: M,
    buildCallback: (result: BuilderResult<M>) => ER,
  ) {
    return new DataBuilderTest<M, keyof M, ER>(map, {} as any, buildCallback);
  }
}

export class GQLQuery<V extends object, R> {
  constructor(
    public query: string,
    public queryType: GQLQueryType = GQLQueryType.REQUEST,
  ) {}

  create(variables: V, recursive?: boolean): GQLQueryInstance<V, R> {
    return new GQLDefaultQueryInstance(
      this,
      variables,
      this.createExecutor(recursive),
    );
  }

  execute(gql: NewGQLClient, variables: V, recursive?: boolean): Promise<R> {
    return gql.request(this.create(variables, recursive));
  }
  protected createExecutor(
    recursive = false,
  ): (gql: NewGQLClient, instance: GQLQueryInstance<V, R>) => Promise<R> {
    if (recursive) {
      throw new Error(
        'GQL Query of type ' +
          this.queryType +
          ' does not support recursive mode',
      );
    }
    return (gql, instance) => {
      return gql.request<V, R>(instance);
    };
  }

  static Get<V extends object, R>(query: string) {
    return new GQLQuery<V, R>(query, GQLQueryType.GET);
  }
  static List<V extends object, R>(query: string) {
    return new GQLListQuery<V, R>(query);
  }
  static Mutate<V extends object, R>(query: string) {
    return new GQLQuery<V, R>(query, GQLQueryType.MUTATE);
  }
  static Request<V extends object, R>(query: string) {
    return new GQLQuery<V, R>(query, GQLQueryType.REQUEST);
  }
}

export type CrudQueryList<R> = {
  Get: GQLQuery<any, R>;
  List: GQLListQuery<any, R>;
  Create: GQLQuery<any, R>;
  Update: GQLQuery<any, R>;
  Delete: GQLQuery<any, R>;
};
interface TypeQueryBuilder<R> {
  Get<V extends object, R1 = R>(query: string): GQLQuery<V, R1>;
  Mutate<V extends object, R1 = R>(query: string): GQLQuery<V, R1>;
  List<V extends object, R1 = R>(query: string): GQLListQuery<V, R1>;
  Request<V extends object, R1 = R>(query: string): GQLQuery<V, R1>;
}
export function createTypeQueryMap<R>() {
  return <M extends Record<string, GQLQuery<any, any>>>(
    fac: (builder: TypeQueryBuilder<R>) => M,
  ) => {
    return fac(GQLQuery);
  };
}
type TestCrudMap<R> = Record<
  'Get' | 'Create' | 'Update' | 'Delete',
  GQLQuery<any, R>
> &
  Record<'List', GQLListQuery<any, R>>;
export function createTestCrudMap<R>() {
  return <M extends TestCrudMap<R>>(
    fac: (builder: TypeQueryBuilder<R>) => M,
  ) => {
    return fac(GQLQuery);
  };
}
type TestResultCrudMap<R> = TestCrudMap<R> & {
  ListByTest: GQLListQuery<any, R>;
};
export function createTestResultCrudMap<R>() {
  return <M extends TestResultCrudMap<R>>(
    fac: (builder: TypeQueryBuilder<R>) => M,
  ) => {
    return fac(GQLQuery);
  };
}
export class GQLListQuery<
  V extends object & { nextToken?: string },
  R,
> extends GQLQuery<V, R[]> {
  constructor(query: string, queryType: GQLQueryType = GQLQueryType.LIST) {
    super(query, queryType);
  }

  protected createExecutor(
    recursive: boolean = false,
  ): (gql: NewGQLClient, instance: GQLQueryInstance<V, R[]>) => Promise<R[]> {
    return (gql, instance) => {
      async function fetchRecursive(
        cInstance: GQLQueryInstance<V, R[]>,
      ): Promise<R[]> {
        const result = await gql.request<
          V,
          { items: R[]; nextToken?: string | null }
        >(cInstance);
        return [
          ...result.items,
          ...(recursive && result.nextToken
            ? await fetchRecursive({
                ...instance,
                variables: {
                  ...instance.variables,
                  nextToken: result.nextToken,
                },
              })
            : []),
        ];
      }
      return fetchRecursive(instance);
    };
  }

  createRecursive(variables: V) {
    return new GQLDefaultQueryInstance<V, R[]>(
      this,
      variables,
      this.createExecutor(true),
    );
  }
}

export interface GQLClient {
  request<V extends object, R>(
    query: string,
    variables: V,
    nextToken?: string,
  ): Promise<R>;

  mutate<R extends { [key: string]: any }, V extends object>(
    query: string,
    variables: V,
  ): Promise<R>;

  get<V extends object, R extends { [key: string]: any }>(
    query: string,
    variables: V,
  ): Promise<R>;

  list<R extends { [key: string]: any }, V extends object = {}>(
    query: string,
    variables?: V,
  ): Promise<R[]>;

  listNew<R extends { [key: string]: any }, V extends object = {}>(
    query: string,
    variables?: V,
  ): Promise<{ data: R[] }>;
}

class GQL implements GQLClient {
  private readonly authMode: GRAPHQL_AUTH_MODE;

  constructor(auth = false) {
    this.authMode = auth
      ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      : GRAPHQL_AUTH_MODE.AWS_IAM;
  }

  async request<V extends object, R>(
    query: string,
    variables: V,
    nextToken?: string,
  ) {
    let result: GraphQLResult<R>;
    try {
      result = (await API.graphql({
        query,
        variables: nextToken ? { ...variables, nextToken } : variables,
        authMode: this.authMode,
      })) as GraphQLResult<R>;
    } catch (e) {
      const error = new GqlError('REQUEST_ERROR', query, {}, e);
      Log.apiError(error);
      throw error;
    }
    if (result.data) {
      return result.data;
    }
    const gqlError = new GqlError('UNKNOWN_REQUEST_ERROR', query, {});
    Log.apiError(gqlError);
    throw gqlError;
  }

  async mutate<R extends { [key: string]: any }, V extends object>(
    query: string,
    variables: V,
  ) {
    let result: GraphQLResult<R>;
    try {
      result = (await API.graphql({
        query,
        variables,
        authMode: this.authMode,
      })) as GraphQLResult<R>;
    } catch (e) {
      const gqlError = new GqlError('MUTATE_ERROR', query, variables, e);
      Log.apiError(gqlError);
      throw gqlError;
    }

    if (result.data) {
      return result.data[Object.keys(result.data)[0]] as R;
    }
    const unknownMutateError = new GqlError(
      'UNKNOWN_MUTATE_ERROR',
      query,
      variables,
    );
    Log.apiError(unknownMutateError);
    throw unknownMutateError;
  }

  async get<V extends object, R extends { [key: string]: any }>(
    query: string,
    variables: V,
  ) {
    let result: GraphQLResult<R>;
    try {
      result = (await API.graphql({
        query,
        authMode: this.authMode,
        variables,
      })) as GraphQLResult<R>;
    } catch (e) {
      const error = new GqlError('GET_ERROR', query, variables, e);
      Log.apiError(error);
      throw error;
    }
    if (result.data && result.data[Object.keys(result.data)[0]]) {
      return result.data[Object.keys(result.data)[0]] as R;
    }
    const unknownGetError = new GqlError('ITEM_NOT_FOUND', query, variables);
    Log.apiError(unknownGetError);
    throw unknownGetError;
  }

  async list<R extends { [key: string]: any }, V extends object = {}>(
    query: string,
    variables?: V,
  ) {
    const results: R[] = [];

    const fetchList = async (nextToken?: string): Promise<R[]> => {
      try {
        const subResults = (await API.graphql({
          query,
          authMode: this.authMode,
          variables: nextToken ? { ...variables, nextToken } : variables,
        })) as GraphQLResult<R>;
        if (subResults.data) {
          const queryData = subResults.data[Object.keys(subResults.data)[0]];
          results.push(...queryData.items);
          if (queryData.nextToken) {
            await fetchList(queryData.nextToken);
          } else {
            return results;
          }
        }
      } catch (e) {
        const error = new GqlError('LIST_ERROR', query, {}, e);
        Log.apiError(error);
        throw error;
      }
      return [];
    };

    await fetchList();
    return results;
  }

  async listNew<R extends { [key: string]: any }, V extends object = {}>(
    query: string,
    variables?: V,
  ) {
    const results: R[] = [];

    const fetchList = async (nextToken?: string): Promise<R[]> => {
      try {
        const subResults = (await API.graphql({
          query,
          authMode: this.authMode,
          variables: nextToken ? { ...variables, nextToken } : variables,
        })) as GraphQLResult<R>;
        if (subResults.data) {
          const queryData = subResults.data[Object.keys(subResults.data)[0]];
          results.push(...queryData.items);
          if (queryData.nextToken) {
            await fetchList(queryData.nextToken);
          } else {
            return results;
          }
        }
      } catch (e) {
        const error = new GqlError('LIST_ERROR', query, {}, e);
        Log.apiError(error);
        throw error;
      }
      return [];
    };

    await fetchList();
    return { data: results };
  }
}

export const noAuthGQL = new GQL(false);

const GqlClient = new GQL(true);

export default GqlClient;
