import React, { MutableRefObject } from 'react';
import { OptionsPosition } from '../../../../../API';
import {
  ArrangementVariant,
  OptionCategoriesArrangement,
  OptionVariant,
  TestOptionCategories,
} from '../../media/options-test';
import { DisplayData, Optional } from '../selectors/data-wrapper';
import { OptionsCanvasStrategy } from '../composer/scaler/canvas-strategy';
import { ViewBoxes } from '../layouting-view';
import { ViewContentContainerFactory } from '../view-content-container';
import { DrawableMediaComponent } from '../basic-components/media-instance-components';
import { ObjectTransformer } from '../../media/drawable/drawable-collector';
import { TestBaseContext } from '../../state/baseTestContext/context/ContextTypes';
import { ControlEventListener } from '../../controls/control-monitor';
import { OptionsStereotypes } from '../../controls/control-stereotypes';
import { makeStyles } from '@mui/styles';
import { TipsContent } from './tips-content';
import {
  ViewDefinition,
  ViewDefinitionCollection,
} from '../composer/view-definition-collection';
import { HTMLContentView } from '../composer/views/html-content-view';
import { CanvasContentView } from '../composer/views/canvas-content-view';
import { LayoutingView } from '../composer/views/layouting-view';
import { defineContentSelector } from '../selectors/content-selector';
import {
  defineGroupSelector,
  GroupSelector,
} from '../selectors/group-selector';
import { Selector } from '../selectors/selector';

export interface OptionsScreenData<OT extends string> {
  options: OptionCategoriesArrangement<OT>;
}

const positionAlignMap = {
  [OptionsPosition.TOP]: 'flex-start',
  [OptionsPosition.MIDDLE]: 'center',
  [OptionsPosition.BOTTOM]: 'flex-end',
};
const horizontalAlignMap = {
  left: {
    out: 'flex-start',
    center: 'center',
    in: 'flex-end',
  },
  right: {
    out: 'flex-end',
    center: 'center',
    in: 'flex-start',
  },
};

export const horizontalContainerAlignMap = {
  left: {
    out: 'start',
    center: 'center',
    in: 'end',
  },
  right: {
    out: 'end',
    center: 'center',
    in: 'start',
  },
} as const;

export function defineOptionsArrangementVariantOptionVariantSelector<
  OT extends string,
>(side: 'left' | 'right', item: OT) {
  return defineGroupSelector({
    selectGroupData(input: OptionCategoriesArrangement<OT>) {
      return Optional.value(input[item]![side].optionVariant);
    },
    children: {
      first: defineContentSelector({
        selectRenderData: (input: 'first' | 'second') => {
          return input === 'first'
            ? DisplayData.displayData<'first'>('first')
            : DisplayData.hide<'first'>();
        },
      }),
      second: defineContentSelector({
        selectRenderData: (input: 'first' | 'second') => {
          return input === 'second'
            ? DisplayData.displayData<'second'>('second')
            : DisplayData.hide<'second'>();
        },
      }),
    },
  });
}

function createOptionArrangementVariantSelector<OT extends string>(
  side: 'left' | 'right',
  arrangementVariant: ArrangementVariant<OT>,
) {
  return defineGroupSelector({
    selectGroupData(input: OptionCategoriesArrangement<OT>) {
      return input.arrangementVariant.name === arrangementVariant.name
        ? Optional.value<OptionCategoriesArrangement<OT>>(input)
        : Optional.none<OptionCategoriesArrangement<OT>>();
    },
    children: Object.fromEntries(
      arrangementVariant.items.map((item) => [
        item,
        defineOptionsArrangementVariantOptionVariantSelector<OT>(side, item),
      ]),
    ),
  });
}

export function defineOptionSideSelectorFactory<
  OT extends string,
  K extends string,
>(...arrangements: ArrangementVariant<OT>[]) {
  return (side: 'left' | 'right') => {
    return defineGroupSelector({
      selectGroupData: (input: OptionsScreenData<OT>) => {
        return Optional.value(input.options);
      },
      children: Object.fromEntries(
        arrangements.map((arr) => [
          arr.name,
          createOptionArrangementVariantSelector<OT>(side, arr),
        ]),
      ) as unknown as Record<
        K,
        ReturnType<typeof createOptionArrangementVariantSelector>
      >,
    });
  };
}

export function createOptionSideFactory<OT extends string>(
  optionCategories: TestOptionCategories<OT>,
  selector: Selector<any>,
) {
  const createStrategy = (category: OT, variant: OptionVariant) =>
    new OptionsCanvasStrategy(optionCategories.categories[category], variant);
  const createCategoryStrategies = (category: OT) => ({
    first: createStrategy(category, 'first'),
    second: createStrategy(category, 'second'),
  });
  const optionCanvasStrategies = Object.fromEntries(
    ObjectTransformer.typedKeys(optionCategories.categories).map((e) => [
      e,
      createCategoryStrategies(e),
    ]),
  ) as Record<OT, ReturnType<typeof createCategoryStrategies>>;

  const createCategoryComponents = (
    category: OT,
    side: 'left' | 'right',
    selector: GroupSelector<
      OptionVariant,
      any,
      { first: Selector<'first'>; second: Selector<'second'> }
    >,
  ) => {
    return LayoutingView.fixed(
      {
        selector,
        layout: {
          subComponents: ViewDefinitionCollection.Create({
            first: CanvasContentView.fixed(
              selector.first,
              optionCanvasStrategies[category].first,
              new ViewContentContainerFactory({
                horizontal:
                  horizontalContainerAlignMap[side][
                    optionCategories.alignment.horizontal
                  ],
                vertical: 'center',
              }),
            ),
            second: CanvasContentView.fixed(
              selector.second,
              optionCanvasStrategies[category].second,
              new ViewContentContainerFactory({
                horizontal:
                  horizontalContainerAlignMap[side][
                    optionCategories.alignment.horizontal
                  ],
                vertical: 'center',
              }),
            ),
          }),
          boxes: ViewBoxes.Define((builder) => ({
            variant: builder.fixed('first', 'second'),
          })),
        } as const,
        contentComponent: (containerProvider, boxProvider) => {
          return () =>
            containerProvider.createContainer(
              <>{boxProvider.createBox('variant')}</>,
            );
        },
      },
      new ViewContentContainerFactory({
        horizontal:
          horizontalContainerAlignMap[side][
            optionCategories.alignment.horizontal
          ],
        vertical: 'start',
      }),
    );
  };
  const createSideVariantComponents = (side: 'left' | 'right') => {
    const sideSelector = (selector as any)[
      side === 'left' ? 'optionsLeft' : 'optionsRight'
    ];
    return ViewDefinitionCollection.Create(
      Object.fromEntries(
        optionCategories.arrangementVariations.map((arr) => [
          arr.name,
          LayoutingView.fixed({
            selector:
              sideSelector[
                arr.name as Exclude<
                  keyof typeof sideSelector,
                  'selectorInstance'
                >
              ],
            layout: {
              subComponents: ViewDefinitionCollection.Create(
                Object.fromEntries(
                  arr.items.map((item) => [
                    item,
                    createCategoryComponents(
                      item,
                      side,
                      sideSelector[
                        arr.name as Exclude<
                          keyof typeof sideSelector,
                          'selectorInstance'
                        >
                      ][item],
                    ),
                  ]),
                ),
              ),
              boxes: ViewBoxes.Define((builder) =>
                Object.fromEntries(
                  arr.items.map((item) => [item, builder.fixed(item)]),
                ),
              ),
            },
            contentComponent: (containerProvider, boxProvider) => {
              return () => {
                return containerProvider.createContainer(
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {arr.items
                      .map((item) => [boxProvider.createBox(item)])
                      .reduce((acc, c) => [
                        ...c,
                        optionCategories.categoryDivider ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent:
                                horizontalAlignMap[side][
                                  optionCategories.alignment.horizontal
                                ],
                            }}
                          >
                            <DrawableMediaComponent
                              media={optionCategories.categoryDivider.drawable}
                            />
                          </div>
                        ) : (
                          <></>
                        ),
                        ...acc,
                      ])}
                  </div>,
                );
              };
            },
          }),
        ]),
      ),
    );
  };
  return (side: 'left' | 'right') => {
    return LayoutingView.fixed({
      selector: (selector as any)[
        side === 'left' ? 'optionsLeft' : 'optionsRight'
      ],
      layout: {
        subComponents: createSideVariantComponents(side),
        boxes: ViewBoxes.Define((builder) => ({
          options: builder.fixed(
            ...optionCategories.arrangementVariations.map((arr) => arr.name),
          ),
        })),
      } as const,
      contentComponent: (containerProvider, boxProvider) => {
        return () => {
          return containerProvider.createContainer(
            <>{boxProvider.createBox('options')}</>,
          );
        };
      },
    });
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    '& p': {
      margin: 0,
    },
  },
}));
function TipsContainer({
  value,
  containerRef,
}: {
  value: string;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}) {
  const classes = useStyles();
  return (
    <div
      dangerouslySetInnerHTML={{ __html: value }}
      ref={containerRef}
      className={classes.root}
    />
  );
}
export function createOptionTipsContainerViewWrapper<OT extends string>(
  context: TestBaseContext,
  options: TestOptionCategories<OT>,
  selector: GroupSelector<
    OptionsScreenData<OT>,
    any,
    { tips: Selector<TipsContent> }
  >,
  contentViewFactory: () => ViewDefinition,
) {
  return createOptionContainerView(
    context,
    options,
    selector,
    contentViewFactory,
  );
}
export function createOptionTipsContainerView<OT extends string>(
  context: TestBaseContext,
  options: TestOptionCategories<OT>,
  selector: GroupSelector<
    OptionsScreenData<OT>,
    any,
    { tips: Selector<TipsContent> }
  >,
  contentViewFactory: () => ViewDefinition,
) {
  return LayoutingView.growing({
    selector,
    layout: {
      subComponents: ViewDefinitionCollection.Create({
        tips: HTMLContentView.fixed(
          selector.tips,
          ({ containerRef, value }) => {
            return (
              <TipsContainer
                value={value?.content ?? ''}
                containerRef={containerRef}
              />
            );
            //return <span ref={containerRef} style={{ ...value?.textStyle?.createStyle() }}>{value?.content ?? ""}</span>;
          },
        ),
        content: contentViewFactory(), // createOptionContainerView(context, options, selector, contentViewFactory)
      }),
      boxes: ViewBoxes.Define(
        (builder) =>
          ({
            tips: builder.fixed('tips'),
            content: builder.growing('content'),
          }) as const,
      ),
    } as const,
    contentComponent: (containerProvider, boxProvider) => {
      return () => {
        return containerProvider.createContainer(
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ width: '100%', flexGrow: 1, display: 'flex' }}>
              {boxProvider.createBox('content', (phase, style) =>
                phase !== 'render'
                  ? { ...style, height: undefined, flex: 'auto' }
                  : style,
              )}
            </div>
            <div style={{ width: '100%', flexGrow: 0 }}>
              {boxProvider.createBox('tips')}
            </div>
          </div>,
        );
      };
    },
  });
}

export function createOptionContainerView<OT extends string>(
  context: TestBaseContext,
  options: TestOptionCategories<OT>,
  selector: Selector<any>,
  contentViewFactory: () => ViewDefinition,
) {
  const sideFactory = createOptionSideFactory(options, selector);

  return LayoutingView.growing({
    selector,
    layout: {
      subComponents: ViewDefinitionCollection.Create({
        left: sideFactory('left'),
        right: sideFactory('right'),
        content: createOptionTipsContainerView(
          context,
          options,
          selector as any,
          contentViewFactory,
        ), // contentViewFactory()
      }),
      boxes: ViewBoxes.Define((builder) => ({
        optionsLeft: builder.fixed('left'),
        optionsRight: builder.fixed('right'),
        content: builder.growing('content'),
      })),
    } as const,
    contentComponent: (containerProvider, boxProvider) => {
      return () => {
        return containerProvider.createContainer(
          <div style={{ width: '100%', height: '100%', display: 'flex' }}>
            <div
              style={{
                padding: '10px',
                ...(context.deviceInfo.mode === 'mobile'
                  ? {
                      paddingLeft: 'calc(env(safe-area-inset-left)+10px)',
                      minWidth: '5%',
                      backgroundColor: '#EFEFEF',
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }
                  : undefined),
                height: '100%',
                flexGrow: 0,
                display: 'flex',
                alignItems: positionAlignMap[options.alignment.vertical],
                justifyContent:
                  horizontalAlignMap.left[options.alignment.horizontal],
              }}
              onTouchStart={
                context.deviceInfo.mode === 'mobile'
                  ? ControlEventListener((e) => {
                      return OptionsStereotypes.Side.Left.controlOccurred(
                        e.map((c) => (c as any).nativeEvent as MouseEvent),
                      );
                    })
                  : undefined
              }
            >
              {boxProvider.createBox('optionsLeft')}
            </div>
            <div style={{ height: '100%', flexGrow: 1 }}>
              {boxProvider.createBox('content')}
            </div>
            <div
              style={{
                padding: '10px',
                ...(context.deviceInfo.mode === 'mobile'
                  ? {
                      paddingRight: 'calc(env(safe-area-inset-right)+10px)',
                      minWidth: '5%',
                      backgroundColor: '#EFEFEF',
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }
                  : undefined),
                height: '100%',
                flexGrow: 0,
                display: 'flex',
                alignItems: positionAlignMap[options.alignment.vertical],
                justifyContent:
                  horizontalAlignMap.right[options.alignment.horizontal],
              }}
              onTouchStart={
                context.deviceInfo.mode === 'mobile'
                  ? ControlEventListener((e) => {
                      return OptionsStereotypes.Side.Right.controlOccurred(
                        e.map((c) => (c as any).nativeEvent as MouseEvent),
                      );
                    })
                  : undefined
              }
            >
              {boxProvider.createBox('optionsRight')}
            </div>
          </div>,
        );
      };
    },
  });
}
