import { IatLoaderSequence } from '../../../features/tests/IAT/loader/iat-loader-sequence';
import { CreateTestResourceLoader } from '../../../features/subject/testRunner/media/test-resource-loader';
import { IatMediaItemSelector } from '../../../features/tests/IAT/loader/loading/iat-media-item-selector';
import { MediaItemSnapshot } from '../../../API';
import { NewGQL } from '../../../GQL';
import { S3TestLoadingManager } from '../../../features/subject/testRunner/media/media-resource-loader';
import { DefaultPhaseRunner } from '../../../features/subject/testRunner/media/sequence-tasks';
import { Iat } from '../../../features/tests/IAT/types';
import { TestBaseContext } from '../../../features/subject/testRunner/state/baseTestContext/context/ContextTypes';
import { IatRunnerConfig } from '../../../features/tests/IAT/runner/iat-runner-config';
import { fakeTestContext } from './fake-context';
import { TestType } from '../../../features/subject/types';
import { Podt } from '../../../features/tests/PODT/types';
import { PodtLoaderSequence } from '../../../features/tests/PODT/loader/podt-loader-sequence';
import {
  PodtMediaItemResponseSelector,
  PodtMediaItemScenesSelector,
  PodtMediaItemTargetSelector,
} from '../../../features/tests/PODT/loader/loading/podt-media-item-selectors';
import { Podta } from '../../../features/tests/PODTA/types';
import { PodtaLoaderSequence } from '../../../features/tests/PODTA/loader/podta-loader-sequence';

export function loadRealIat(id: string, context?: TestBaseContext) {
  return IatRunnerConfig.load(
    context ?? fakeTestContext(TestType.IAT, id),
    NewGQL.NO_AUTH_CLIENT,
    S3TestLoadingManager((state) => {
      console.log('state listener', state);
    }),
  );
}

export function loadMockPodt(podt: Podt, context?: TestBaseContext) {
  return PodtLoaderSequence.performSequence(
    {
      load: {
        testContextFactory: async () =>
          context ?? fakeTestContext(TestType.PODT, podt.id),
        loader: CreateTestResourceLoader(
          () => Promise.resolve(podt),
          (test, loadingManager) => {
            const collect = [
              ...(PodtMediaItemScenesSelector.collect(
                test,
              ) as MediaItemSnapshot[]),
              ...(PodtMediaItemTargetSelector.collect(
                test,
              ) as MediaItemSnapshot[]),
              ...(PodtMediaItemResponseSelector.collect(
                test,
              ) as MediaItemSnapshot[]),
              ...((test.fixation?.mediaItem
                ? [test.fixation.mediaItem]
                : []) as MediaItemSnapshot[]),
            ];
            loadingManager.attachItems(...collect);
            return loadingManager.loadCache();
          },
        ).init(
          NewGQL.NO_AUTH_CLIENT,
          S3TestLoadingManager((state) => {
            console.log('state listener', state);
          }),
        ),
      },
      arrange: ({ accessor, parameters, pool }) => ({
        pool,
        accessor,
        parameters: parameters.determine(accessor.testContext),
      }),
    },
    new DefaultPhaseRunner(),
  );
}
export function loadMockPodta(podta: Podta, context?: TestBaseContext) {
  return PodtaLoaderSequence.performSequence(
    {
      load: {
        testContextFactory: async () =>
          context ?? fakeTestContext(TestType.PODTA, podta.id),
        loader: CreateTestResourceLoader(
          () => Promise.resolve(podta),
          (test, loadingManager) => {
            const collect = [
              ...(PodtMediaItemScenesSelector.collect(
                test,
              ) as MediaItemSnapshot[]),
              ...(PodtMediaItemTargetSelector.collect(
                test,
              ) as MediaItemSnapshot[]),
              ...(PodtMediaItemResponseSelector.collect(
                test,
              ) as MediaItemSnapshot[]),
              ...((test.fixation?.mediaItem
                ? [test.fixation.mediaItem]
                : []) as MediaItemSnapshot[]),
            ];
            loadingManager.attachItems(...collect);
            return loadingManager.loadCache();
          },
        ).init(
          NewGQL.NO_AUTH_CLIENT,
          S3TestLoadingManager((state) => {
            console.log('state listener', state);
          }),
        ),
      },
      arrange: ({ accessor, parameters, pool }) => ({
        pool,
        accessor,
        parameters: parameters.determine(accessor.testContext),
      }),
    },
    new DefaultPhaseRunner(),
  );
}

export function loadMockIat(iat: Iat, context?: TestBaseContext) {
  return IatLoaderSequence.performSequence(
    {
      load: {
        testContextFactory: async () =>
          context ?? fakeTestContext(TestType.IAT, iat.id),
        loader: CreateTestResourceLoader(
          () => Promise.resolve(iat),
          (test, loadingManager) => {
            const collect = IatMediaItemSelector.collect(
              test,
            ) as MediaItemSnapshot[];
            loadingManager.attachItems(...collect);
            return loadingManager.loadCache();
          },
        ).init(
          NewGQL.NO_AUTH_CLIENT,
          S3TestLoadingManager((state) => {
            console.log('state listener', state);
          }),
        ),
      },
      arrange: ({ accessor, parameters, pool }) => ({
        pool,
        accessor,
        parameters: parameters.determine(accessor.testContext),
      }),
    },
    new DefaultPhaseRunner(),
  );
}
