import { styled } from '@mui/styles';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import React from 'react';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'LightCoral',
    color: 'rgba(0, 0, 0, 1)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    border: '1px solid Crimson',
  },
}));
