import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TestType } from '../subject/types';

export const resultsApi = createApi({
  reducerPath: 'resultsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_RESULTS_DOWNLOADER_URL,
  }),
  tagTypes: ['Result'],
  endpoints: (builder) => ({
    generateResults: builder.mutation<
      void,
      { testType: TestType; testId: string; accessToken: string; email: string }
    >({
      query: (arg) => ({
        url: `/results-generator/${arg.testType}/${arg.testId}?email=${encodeURIComponent(arg.email)}`,
        method: 'POST',
        headers: {
          authorization: arg.accessToken,
        },
      }),
    }),
  }),
});

export const { useGenerateResultsMutation } = resultsApi;
