import React, { FC } from 'react';
import { Podt } from '../../types';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { TextDisplay } from '../../../../../components/TextDisplay';
import { BorderBox } from '../../../../../components/BorderBox';
import { translationInfoMap } from '../../../../subject/testRunner/state/baseTestContext/app-configuration/translations/translation-map';

export const PodtGameSettingsView: FC<{ test: Podt }> = ({ test }) => {
  return (
    <Stack spacing={3}>
      <Stack spacing={5} direction="row">
        <TextDisplay
          label="Language"
          value={
            test.language ? translationInfoMap[test.language].languageName : '-'
          }
          suffix=""
        />
        <TextDisplay
          label="Inter Trial Interval"
          value={test.interTrialInterval}
          suffix="ms"
        />
        <TextDisplay
          label="Counterbalancing"
          value={test.counterbalancing ? 'Yes' : 'No'}
        />
        <TextDisplay
          label="Response Position"
          value={test.style.optionsPosition}
        />
        <TextDisplay label="Width" value={test.style.maxWidth} suffix="%" />
        <TextDisplay
          label="Background"
          value={test.style.backgroundColour}
          suffix={
            <Box
              sx={{
                borderRadius: '50%',
                border: 1,
                height: 24,
                width: 24,
                bgcolor: test.style.backgroundColour,
              }}
            />
          }
        />
      </Stack>
      <BorderBox label="Keyboard">
        <Stack direction="row" spacing={3}>
          <TextDisplay label="Weapon Button" value={test.keyboard.left} />
          <TextDisplay label="Object Button" value={test.keyboard.right} />
          <TextDisplay label="Start Button" value={test.keyboard.confirm} />
        </Stack>
      </BorderBox>
      <BorderBox label="Welcome screen">
        <div dangerouslySetInnerHTML={{ __html: test.instructions.desktop }} />
      </BorderBox>
      <BorderBox label="End screen">
        <div
          dangerouslySetInnerHTML={
            test.endScreen?.desktop
              ? { __html: test.endScreen.desktop }
              : undefined
          }
        />
      </BorderBox>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography color="textSecondary">
            Feedback Screen Messages:
          </Typography>
        </Grid>
        {test.trialFeedback ? (
          <React.Fragment>
            <Grid item xs={12}>
              <TextDisplay
                label="Points Size"
                value={test.trialFeedback.pointsFontsize}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item>
                  <TextDisplay
                    label="Hit"
                    valueProps={{
                      style: {
                        color: test.trialFeedback?.hitText.fontStyle.color,
                      },
                    }}
                    value={test.trialFeedback?.hitText.value}
                    suffix={`${test.trialFeedback?.hitText.weight} Punkte`}
                  />
                </Grid>
                <Grid item>
                  <TextDisplay
                    label="Miss"
                    valueProps={{
                      style: {
                        color: test.trialFeedback?.missText.fontStyle.color,
                      },
                    }}
                    value={test.trialFeedback?.missText.value}
                    suffix={`${test.trialFeedback?.missText.weight} Punkte`}
                  />
                </Grid>
                <Grid item>
                  <TextDisplay
                    label="Correct Rejction"
                    valueProps={{
                      style: {
                        color:
                          test.trialFeedback?.correctRejectionText.fontStyle
                            .color,
                      },
                    }}
                    value={test.trialFeedback?.correctRejectionText.value}
                    suffix={`${test.trialFeedback?.correctRejectionText.weight} Punkte`}
                  />
                </Grid>
                <Grid item>
                  <TextDisplay
                    label="False Alarm"
                    valueProps={{
                      style: {
                        color:
                          test.trialFeedback?.falseAlarmText.fontStyle.color,
                      },
                    }}
                    value={test.trialFeedback?.falseAlarmText.value}
                    suffix={`${test.trialFeedback?.falseAlarmText.weight} Punkte`}
                  />
                </Grid>
                <Grid item>
                  <TextDisplay
                    label="No Response"
                    valueProps={{
                      style: {
                        color:
                          test.trialFeedback?.noResponseText.fontStyle.color,
                      },
                    }}
                    value={test.trialFeedback?.noResponseText.value}
                    suffix={`${test.trialFeedback?.noResponseText.weight} Punkte`}
                  />
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <Grid item xs={12}>
            <Typography sx={{ fontStyle: 'italic' }}>
              No Feedback shown
            </Typography>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};
