import { TestResourceAccessor } from '../../../../subject/testRunner/media/test-resource-loader';
import { Podta } from '../../../PODTA/types';
import { Podt } from '../../types';
import { TestType } from '../../../../subject/types';
import { TextStyleDescriptor } from '../../../../subject/testRunner/media/text-drawer';
import {
  AnnotatedDataPipe,
  MetaDataMediaSelector,
} from '../../../../subject/testRunner/media/drawable/drawable-collector';
import { BlockType, MediaItemSnapshot } from '../../../../../API';
import {
  DrawableMediaInstance,
  transformMediaItemData,
} from '../../../../subject/testRunner/media/MediaData';

import { PodtSceneBox, PodtSceneMedia } from './podt-scene-media';

export function LoadPodtMedia(
  accessor: TestResourceAccessor<Podta | Podt>,
  type: TestType.PODT | TestType.PODTA,
) {
  const stimulusFactory = accessor.createDrawableFactory(
    'fullSize',
    TextStyleDescriptor.ofFontStyle(accessor.modelData.style.stimulusTextStyle),
  );
  const responseFactory = accessor.createDrawableFactory(
    'fullSize',
    TextStyleDescriptor.ofFontStyle(accessor.modelData.response.fontStyle),
  );
  return MetaDataMediaSelector.of(accessor.modelData).nestSelectors(
    (subSelector) => {
      return {
        targets: AnnotatedDataPipe.of(
          subSelector()
            .selectRaw('targets')
            .selectMap('category', (d) => d.map((de) => [de, de.label]))
            .select('blockType', {
              practiceStimuliPool: BlockType.PRACTICE,
              testStimuliPool: BlockType.NON_PRACTICE,
            } as const)
            .select('variant', {
              dangerousStimuli: 'dangerous' as const,
              nonDangerousStimuli: 'non-dangerous' as const,
            } as const)
            .selectMap('index', (d) =>
              d.map((v, i) => [v, i] as [typeof v, number]),
            )
            .mapValues(
              (v) =>
                stimulusFactory.createInstance(
                  transformMediaItemData(v as MediaItemSnapshot),
                ) as DrawableMediaInstance,
            )
            .collect(),
        ),
        response: subSelector()
          .selectRaw('response')
          .select('variant', { active: 'first', passive: 'second' } as const)
          .mapValues((v) =>
            responseFactory.createInstance(
              transformMediaItemData(v as MediaItemSnapshot),
            ),
          )
          .collectMappedFirst('variant'),
        scenes: AnnotatedDataPipe.of(
          subSelector()
            .selectRaw('scenes')
            .selectMap('index', (d) => d.scenes.map((v, i) => [v, i]))
            .mapValues(
              (v) =>
                new PodtSceneMedia(
                  stimulusFactory.createInstance(
                    transformMediaItemData(v.mediaItem as MediaItemSnapshot),
                  ),
                  v.boxes.map((b) =>
                    type === TestType.PODT
                      ? PodtSceneBox.of(
                          b as Podt['scenes']['scenes'][number]['boxes'][number],
                        )
                      : PodtSceneBox.ofPodta(
                          b as Podta['scenes']['scenes'][number]['boxes'][number],
                        ),
                  ),
                ),
            )
            .collect(),
        ),
        fixation: accessor.modelData.fixation?.mediaItem
          ? stimulusFactory.createInstance(
              transformMediaItemData(
                accessor.modelData.fixation.mediaItem as MediaItemSnapshot,
              ),
            )
          : undefined,
      } as const;
    },
  );
}
