import React, { FC, PropsWithChildren } from 'react';
import { Box, BoxProps, Collapse, Typography } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';

type BorderBoxProps = PropsWithChildren<{
  label?: React.ReactNode;
  boxProps?: BoxProps;
  showContent?: boolean;
  none?: boolean;
}>;

export const BorderBox: FC<BorderBoxProps> = (props) => (
  <Box display="flex" flexDirection="column" flexGrow={1}>
    <Typography sx={{ color: 'text.secondary' }}>{props.label}</Typography>
    {typeof props.showContent === 'boolean' ? (
      <TransitionGroup>
        {props.showContent && (
          <Collapse>
            <Box
              sx={{
                p: 1,
                border: 0.1,
                borderColor: 'lightgray',
                borderRadius: 1,
                ...props.boxProps?.sx,
              }}
            >
              {props.children}
            </Box>
          </Collapse>
        )}
      </TransitionGroup>
    ) : (
      <Box
        sx={{
          p: 1,
          border: props.none ? undefined : 0.1,
          borderColor: 'lightgray',
          borderRadius: 1,
          fontStyle: props.none ? 'italic' : undefined,
          ...props.boxProps?.sx,
        }}
      >
        {props.children}
      </Box>
    )}
  </Box>
);
