import React, { FC } from 'react';
import { BorderBox } from '../../../../../components/BorderBox';

interface WysiwygScreenViewProps {
  content?: string;
  label: string;
}

export const WysiwygScreenView: FC<WysiwygScreenViewProps> = (props) => {
  return (
    <BorderBox label={props.label} none={!props.content}>
      {props.content ? (
        <div dangerouslySetInnerHTML={{ __html: props.content }} />
      ) : (
        `No ${props.label} enabled`
      )}
    </BorderBox>
  );
};
