import * as uuid from 'uuid';
import React, { FC } from 'react';
import { BasicDimension, SizedArea } from '../../media/drawable-dimensions';
import { MeasureRendererContextProvider } from './measure-renderer.context';
import { ReactRootWrapper } from './react-dom-wrapper';

export interface MeasurerController {
  renderElement(element: FC, descriptor?: MeasureSizeDescriptor): () => void;

  close(): void;
}

interface MeasureSizeDescriptor {
  width: 'unset' | 'max';
  height: 'unset' | 'max';
}

function MeasurerControllerComponent(props: {
  size: BasicDimension;
  controllerCB: (
    controller: (element: FC, descriptor?: MeasureSizeDescriptor) => () => void,
  ) => void;
}) {
  const [elements, setElements] = React.useState<
    { id: string; element: FC; descriptor: MeasureSizeDescriptor }[]
  >([]);
  React.useEffect(() => {
    props.controllerCB((element: FC, descriptor): (() => void) => {
      const id = uuid.v4();
      setElements((es) => [
        ...es,
        {
          id,
          element,
          descriptor: descriptor ?? { width: 'unset', height: 'unset' },
        },
      ]);
      return () => {
        setElements((es) => es.filter((e) => e.id !== id));
      };
    });
  }, [setElements, props]);
  return (
    <>
      <MeasureRendererContextProvider
        contextProps={{ measurePhase: true, maxSize: props.size }}
      >
        {elements.map(({ element: Element, id, descriptor }) => (
          <div
            key={id}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              ...SizedArea.dimPx(props.size).toStyle('max'),
              width:
                descriptor?.width === 'max'
                  ? props.size.width + 'px'
                  : undefined,
              height:
                descriptor?.height === 'max'
                  ? props.size.height + 'px'
                  : undefined,
            }}
          >
            <Element key={id} />
          </div>
        ))}
      </MeasureRendererContextProvider>
    </>
  );
}

export async function createIndependentDOMMeasurer(
  size: BasicDimension,
): Promise<MeasurerController> {
  const uniqId = uuid.v4();
  const containerRootElement = document.createElement('div');
  containerRootElement.style.position = 'absolute';
  containerRootElement.style.left = '0px';
  containerRootElement.style.top = '0px';
  containerRootElement.style.visibility = 'hidden';
  containerRootElement.id = uniqId;
  document.body.appendChild(containerRootElement);
  return new Promise<MeasurerController>((resolve) => {
    const reactRootWrapper = new ReactRootWrapper(containerRootElement);
    reactRootWrapper.render(
      <MeasurerControllerComponent
        size={size}
        controllerCB={(c) => {
          resolve({
            renderElement: (element: React.FC, descriptor): (() => void) => {
              return c(element, descriptor);
            },
            close() {
              reactRootWrapper.unmount();
              document.body.removeChild(containerRootElement);
            },
          });
        }}
      />,
    );
  });
}
