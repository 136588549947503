import { Alert } from '@mui/lab';
import * as React from 'react';

export function StagingAlert({ absolute }: { absolute?: boolean }) {
  const isStaging = window.location.hostname.includes('dev.mindset');

  return isStaging ? (
    <Alert
      severity="error"
      style={
        absolute
          ? {
              position: 'absolute',
              zIndex: 9999,
              margin: '-1rem',
              left: '40%',
            }
          : undefined
      }
    >
      STAGING ENVIRONMENT
    </Alert>
  ) : (
    <></>
  );
}
