import { ControlInputMonitor } from '../../../subject/testRunner/controls/control-monitor';
import {
  ControlHandlerManager,
  DOMEventControlHandler,
  TimeoutControlHandler,
} from '../../../subject/testRunner/controls/control-handlers';
import { WswStereotypes } from '../loader/wsw-controls';

export const WSWControlFactory = () => {
  return (inputMonitor: ControlInputMonitor) => {
    const confirmEventHandler = new DOMEventControlHandler(
      inputMonitor,
      WswStereotypes.Confirm,
    );
    const timeoutHandler = new TimeoutControlHandler(
      inputMonitor,
      WswStereotypes.TimeoutEvent,
    );
    const proceedEventHandler = new DOMEventControlHandler(
      inputMonitor,
      WswStereotypes.Answer.Proceed,
    );
    const skipEventHandler = new DOMEventControlHandler(
      inputMonitor,
      WswStereotypes.Answer.Skip,
    );
    const answerEventHandler = new DOMEventControlHandler(
      inputMonitor,
      WswStereotypes.Answer.Answer,
    );
    return new ControlHandlerManager([
      confirmEventHandler,
      proceedEventHandler,
      skipEventHandler,
      answerEventHandler,
      timeoutHandler,
    ]);
  };
};
