import {
  WswQuestionInstance,
  WswQuestionSetInstance,
} from '../model/questions/question-set-factory';
import { DrawableMediaInstance } from '../../../../subject/testRunner/media/MediaData';
import { WswConstructedGroup } from '../structure/wsw-structure';
import { QuestionAnswerEvent, WswStereotypes } from '../wsw-controls';
import {
  ControlOccurrenceProcessor,
  DefaultTransitionAction,
} from '../../../../subject/testRunner/graph-state/graph-action';
import { WSWModifiers } from './wsw-modifiers';
import { GQLQueryInstance } from '../../../../../GQL';
import {
  CreateWswResultMutationVariables,
  WswResult,
} from '../../../../../API';
import { TransitionModifier } from '../../../../subject/testRunner/graph-state/transition-modifiers';
import {
  WswDoubleStimulus,
  WswEmptyStimulus,
  WswQuestionData,
  WswSingleStimulus,
} from '../view-data/wsw-question-stimulus';
import { MapScreenTreeNode } from '../../../../subject/testRunner/graph/nodes/map-screen-tree-node';
import { MapPhaseTreeNode } from '../../../../subject/testRunner/graph/nodes/map-phase-tree-node';

type TreeQuestionConfig = {
  externalStimulus?: WswSingleStimulus | WswDoubleStimulus | WswEmptyStimulus;
  showPlainStimulusBeforeQuestion: boolean;
  groupIndication?: WswConstructedGroup;
  resultQuery: (
    control: ControlOccurrenceProcessor<QuestionAnswerEvent>,
    order: 'before' | 'after' | 'conditional',
    questionIndex: number,
  ) => GQLQueryInstance<CreateWswResultMutationVariables, WswResult>;
};

function createQuestionListPhase(
  questions: WswQuestionInstance[],
  order: 'before' | 'after',
  questionConfig: TreeQuestionConfig,
  treeConfig: { questionSetPhase: MapPhaseTreeNode },
) {
  const questionsListPhaseNode = new MapPhaseTreeNode(
    'questions-' + order,
    treeConfig.questionSetPhase,
  ).connectParent(order === 'before' ? WSWModifiers.default : undefined);

  const questionsNodeList = questions.flatMap(
    (questionInstance, questionIndex) => {
      const resultNodes: MapScreenTreeNode[] = [];

      const questionStimulus =
        questionConfig.externalStimulus ??
        (questionInstance.stimulus
          ? new WswSingleStimulus(
              questionInstance.stimulus as DrawableMediaInstance,
              undefined,
              'group-construction',
            )
          : new WswEmptyStimulus());

      if (questionConfig.showPlainStimulusBeforeQuestion) {
        const stimulusNode = new MapScreenTreeNode(
          `questions-start-${questionIndex}-stimulus`,
          questionsListPhaseNode,
          new WswQuestionData(
            questionStimulus,
            null,
            questionConfig.groupIndication,
          ),
        )
          .controlRequest(
            WswStereotypes.TimeoutEvent.defineTransition(
              questionInstance.timeout,
              () => DefaultTransitionAction.next(WSWModifiers.default),
            ),
          )
          .connectParent(
            questionIndex === 0 ? WSWModifiers.default : undefined,
          );
        resultNodes.push(stimulusNode);
      }
      const questionNode = new MapScreenTreeNode(
        `questions-start-${questionIndex}`,
        questionsListPhaseNode,
        new WswQuestionData(
          questionStimulus,
          questionInstance,
          questionConfig.groupIndication,
        ),
      )
        .controlRequest(
          WswStereotypes.Answer.Answer.defineTransition(undefined, (control) =>
            DefaultTransitionAction.result(
              WSWModifiers.default,
              questionConfig.resultQuery(control, order, questionIndex),
            ),
          ),
        )
        .connectParent(
          questionIndex === 0 && !questionConfig.showPlainStimulusBeforeQuestion
            ? WSWModifiers.default
            : undefined,
        );
      resultNodes.push(questionNode);
      return resultNodes;
    },
  );

  if (questionsNodeList.length > 0) {
    questionsNodeList.reduce((acc, c) =>
      c.transitionFrom(WSWModifiers.default, acc),
    );
  }

  return {
    questionsListPhaseNode,
    questionsNodeList,
  };
}

export function createQuestionSetSubTree(
  questionSet: WswQuestionSetInstance,
  parentNode: MapPhaseTreeNode,
  questionConfig: TreeQuestionConfig,
  treeConfig: {
    nodeAfter?: MapScreenTreeNode | MapPhaseTreeNode;
    parentConnector?: TransitionModifier;
  },
) {
  const questionSetPhaseNode = new MapPhaseTreeNode(
    'questions',
    parentNode,
  ).connectParent(treeConfig.parentConnector);
  let questionsStartPhaseNode: MapPhaseTreeNode | undefined;
  if (questionSet.questionsBefore.length > 0) {
    questionsStartPhaseNode = createQuestionListPhase(
      questionSet.questionsBefore,
      'before',
      questionConfig,
      { questionSetPhase: questionSetPhaseNode },
    ).questionsListPhaseNode;
  }

  if (questionSet.conditionalQuestion) {
    const { questionsListPhaseNode: questionsAfterPhaseNode } =
      createQuestionListPhase(
        questionSet.questionsAfter,
        'after',
        questionConfig,
        { questionSetPhase: questionSetPhaseNode },
      );

    const questionConditionalPhase = new MapPhaseTreeNode(
      'conditional-question-phase',
      questionSetPhaseNode,
    ).connectParent(questionsStartPhaseNode ? undefined : WSWModifiers.default);

    const questionInstance = questionSet.conditionalQuestion;

    const questionStimulus =
      questionConfig.externalStimulus ??
      (questionInstance.stimulus
        ? new WswSingleStimulus(
            questionInstance.stimulus as DrawableMediaInstance,
            undefined,
            'group-construction',
          )
        : new WswEmptyStimulus());

    let stimulusNode: MapScreenTreeNode | undefined;
    if (questionConfig.showPlainStimulusBeforeQuestion) {
      stimulusNode = new MapScreenTreeNode(
        'conditional-question-stimulus',
        questionConditionalPhase,
        new WswQuestionData(
          questionStimulus,
          null,
          questionConfig.groupIndication,
        ),
      )
        .controlRequest(
          WswStereotypes.TimeoutEvent.defineTransition(
            questionInstance.timeout,
            () => DefaultTransitionAction.next(WSWModifiers.default),
          ),
        )
        .connectParent(WSWModifiers.default);
    }
    const conditionalQuestionTreeNode = new MapScreenTreeNode(
      'conditional-question',
      questionConditionalPhase,
      new WswQuestionData(
        questionStimulus,
        questionInstance,
        questionConfig.groupIndication,
      ),
    )
      .controlRequest(
        WswStereotypes.Answer.Proceed.defineTransition(undefined, (control) =>
          DefaultTransitionAction.result(
            WSWModifiers.default,
            questionConfig.resultQuery(control, 'conditional', 0),
          ),
        ),
      )
      .controlRequest(
        WswStereotypes.Answer.Skip.defineTransition(undefined, (control) =>
          DefaultTransitionAction.result(
            WSWModifiers.skip,
            questionConfig.resultQuery(control, 'conditional', 0),
          ),
        ),
      )
      .connectParent(stimulusNode ? undefined : WSWModifiers.default);
    if (stimulusNode) {
      conditionalQuestionTreeNode.transitionFrom(
        WSWModifiers.default,
        stimulusNode,
      );
    }
    if (questionsStartPhaseNode) {
      questionConditionalPhase.transitionFrom(
        WSWModifiers.default,
        questionsStartPhaseNode,
      );
    }
    questionConditionalPhase.transition(
      WSWModifiers.default,
      questionsAfterPhaseNode,
    );
    if (treeConfig.nodeAfter) {
      questionConditionalPhase.transition(
        WSWModifiers.skip,
        treeConfig.nodeAfter,
      );
    }
  }
  if (treeConfig.nodeAfter) {
    questionSetPhaseNode.transition(WSWModifiers.default, treeConfig.nodeAfter);
  }
  return {
    questionSetPhaseNode,
  };
}
