import { TestStep } from './testEditor/types';
import { validateAllSteps } from './testEditor/utils';
import { useEffect, useState } from 'react';
import { FormikValues } from 'formik';

export function useIsTestComplete<T extends FormikValues>(
  steps: TestStep[],
  test: T | undefined,
) {
  const [hasErrors, setHasErrors] = useState(true);

  useEffect(() => {
    if (test) {
      const errors = validateAllSteps(
        test,
        steps.map((s) => s.editor),
      );
      setHasErrors(errors.some((e) => e));
    } else {
      setHasErrors(true);
    }
  }, [steps, test]);

  return !hasErrors;
}
