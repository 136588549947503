import React, { FC, useState } from 'react';
import { useField } from 'formik';
import { MediaItemSnapshotInput } from '../../../API';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Add,
  AutoFixNormal,
  CancelOutlined,
  ContentCut,
  Delete,
} from '@mui/icons-material';
import StyledTextInput from '../../../components/input/StyledTextInput';
import InfoMessage from '../../../components/InfoMessage';
import { createEmptyMediaItemSnapshot } from '../initalMediaItemEditorStates';
import Divider from '@mui/material/Divider';

interface TextPickerDialogProps {
  fieldName: string;
  dialogOpen: boolean;
  onClose: () => void;
}

const tokenizers = [
  {
    name: 'Comma',
    regex: /,/,
  },
  {
    name: 'Semicolon',
    regex: /;/,
  },
  {
    name: 'New Line',
    regex: /\r|\n/,
  },
];

function MagicImportView({
  done,
}: {
  done: (result: string[] | undefined) => void;
}) {
  const [input, setInput] = useState('');
  const [tokenizerSelection, setTokenizerSelection] = useState(tokenizers[0]);
  const results = input
    ? input
        .trim()
        .split(tokenizerSelection.regex)
        .map((v) => v.trim())
        .filter((v) => v !== '')
    : undefined;
  return (
    <Stack spacing={1} sx={{ minWidth: 600 }}>
      <TextField
        size="small"
        value={input}
        multiline
        rows={6}
        placeholder="Paste text here..."
        onPaste={(e) => {
          setInput(e.clipboardData.getData('text/plain'));
        }}
        onChange={(evt) => setInput(evt.target.value)}
        onDrop={(e) => {
          setInput(e.dataTransfer.getData('text/plain'));
        }}
        InputProps={{
          endAdornment: input ? (
            <IconButton
              sx={{
                alignSelf: 'flex-start',
                marginTop: '-0.65em',
                marginRight: '-0.8em',
              }}
              onClick={() => setInput('')}
            >
              <CancelOutlined />
            </IconButton>
          ) : undefined,
        }}
      />
      <Box sx={{ margin: '0.75em', alignSelf: 'flex-end' }}>
        <Select
          size="small"
          value={tokenizerSelection.name}
          onChange={(event) =>
            setTokenizerSelection(
              tokenizers.find((t) => t.name === event.target.value)!,
            )
          }
        >
          {tokenizers.map((t) => (
            <MenuItem value={t.name}>
              <Box
                display="flex"
                sx={{ justifyContent: 'center', gap: '0.2em' }}
              >
                <ContentCut fontSize="small" />
                {t.name}
              </Box>
            </MenuItem>
          ))}
        </Select>
        {results && <Button onClick={() => done(results)}>Ok</Button>}
        <Button onClick={() => done(undefined)}>Cancel</Button>
      </Box>
      {results && (
        <Stack spacing={1} sx={{ overflow: 'auto' }}>
          <Typography sx={{ fontWeight: 'bold' }}>Preview</Typography>
          {results.map((r) => {
            return (
              <>
                <TextField value={r} multiline rows={3} disabled />
                <Divider />
              </>
            );
          })}
        </Stack>
      )}
    </Stack>
  );
}
export const TextPickerDialog: FC<TextPickerDialogProps> = (props) => {
  const [, { value: mediaItems }, helpers] = useField<MediaItemSnapshotInput[]>(
    props.fieldName,
  );
  const removeMediaItem = (index: number) =>
    helpers.setValue(mediaItems.filter((_, i) => i !== index));

  const addEmptyMediaItem = () =>
    helpers.setValue([...mediaItems, createEmptyMediaItemSnapshot()]);

  const [showMagicImport, setShowMagicImport] = useState(false);

  return (
    <Dialog maxWidth="xl" open={props.dialogOpen} onClose={props.onClose}>
      <DialogTitle sx={{ flexDirection: 'column' }}>
        Pick Texts
        {/*    <InfoMessage message="You can paste comma seperated values into the first text field and press the wand button." />*/}
      </DialogTitle>
      <DialogContent sx={{ overflow: 'auto' }}>
        {showMagicImport && (
          <MagicImportView
            done={(result) => {
              if (result) {
                helpers.setValue([
                  ...mediaItems,
                  ...result?.map((t) => {
                    const item = createEmptyMediaItemSnapshot();
                    item.text = t;
                    return item;
                  }),
                ]);
              }
              setShowMagicImport(false);
            }}
          />
        )}
        {!showMagicImport && (
          <>
            <InfoMessage
              sx={{ paddingBottom: 2 }}
              message="Please make sure the texts are not too long since they have to fit on a mobile screen."
            />
            <Stack
              direction="row"
              spacing={1}
              sx={{ mb: 2, justifyContent: 'flex-end', width: '100%' }}
            >
              <IconButton
                onClick={() => setShowMagicImport(true)}
                title="Add comma seperated texts"
              >
                <AutoFixNormal />
              </IconButton>
            </Stack>
            <Stack spacing={1} sx={{ mb: 1 }}>
              {mediaItems?.map((_, i) => (
                <Stack direction="row" key={`textMediaItem-${i}`}>
                  <StyledTextInput
                    sx={{ minWidth: 500 }}
                    multiline
                    name={`${props.fieldName}[${i}].text`}
                    label={''}
                  />
                  <IconButton onClick={() => removeMediaItem(i)}>
                    <Delete />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
            <Button startIcon={<Add />} onClick={addEmptyMediaItem}>
              Add Text
            </Button>
          </>
        )}
      </DialogContent>
      {!showMagicImport && (
        <DialogActions>
          <Button onClick={props.onClose} variant="contained" size="small">
            Use Texts
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
