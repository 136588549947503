import React, { FC } from 'react';
import { useField } from 'formik';
import {
  WSWAttributeCategoryInput,
  WswStimulusAttributeVariant,
} from '../../../../../API';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
} from '@mui/material';
import StyledTextInput from '../../../../../components/input/StyledTextInput';
import { Add, Delete } from '@mui/icons-material';
import { TransitionGroup } from 'react-transition-group';
import { WSWAttributeMediaItemPicker } from '../../../../media/picker/WSWAttributeMediaItemPicker';

interface WSWAttributeCategoryInputProps {
  fieldName: string;
  variantName: string;
  mode: WswStimulusAttributeVariant;
}

export const WSWAttributeCategoryInputComponent: FC<
  WSWAttributeCategoryInputProps
> = ({ fieldName, mode, variantName }) => {
  const [, { value: categories }, helpers] = useField<
    WSWAttributeCategoryInput[] | undefined
  >(fieldName);
  const [, , variantHelpers] = useField<
    WswStimulusAttributeVariant | undefined | null
  >(variantName);

  const addAttribute = () => {
    if (categories) {
      helpers.setValue([...categories, { mediaSnaps: [], name: '' }]);
    } else {
      helpers.setValue([{ mediaSnaps: [], name: '' }]);
    }
  };

  const removeAttribute = (attributeIndex: number) => {
    if (categories) {
      if (categories.length - 1 === 0) {
        variantHelpers.setValue(null);
      }
      helpers.setValue(categories.filter((_, i) => i !== attributeIndex));
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TransitionGroup>
        {categories?.map((wswAttributCat, index) => (
          <Collapse key={`wsw-attribute-cat-${index}`}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                pl: 2,
                mb: '1em',
              }}
            >
              <Card sx={{ width: '100%', minWidth: '300px' }}>
                <CardHeader
                  action={
                    <IconButton
                      edge="start"
                      sx={{ ml: '0.5em' }}
                      onClick={() => removeAttribute(index)}
                    >
                      <Delete />
                    </IconButton>
                  }
                  title={
                    <StyledTextInput
                      name={`${fieldName}[${index}].name`}
                      label="Name"
                      InputProps={{
                        startAdornment: (
                          <Avatar sx={{ width: 24, height: 24, mr: '0.5em' }}>
                            {index + 1}
                          </Avatar>
                        ),
                      }}
                    />
                  }
                />
                <CardContent>
                  <Box minWidth={300}>
                    <WSWAttributeMediaItemPicker
                      name={`${fieldName}[${index}].mediaSnaps`}
                      pickerScope={mode}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Collapse>
        ))}
      </TransitionGroup>
      <Button
        size="small"
        startIcon={<Add />}
        onClick={addAttribute}
        sx={{ ml: 6 }}
      >
        Add Category
      </Button>
    </Box>
  );
};
