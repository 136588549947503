import React, { FunctionComponent } from 'react';
import { Chip, alpha, ChipProps } from '@mui/material';
import { TestStatus as TestStatusEnum } from '../../../../API';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  testStatus: {
    fontSize: 14,
    fontWeight: 'normal',
    color: theme.palette.grey[900],
  },
  draft: {
    border: `1px ${theme.palette.warning.light} solid`,
    backgroundColor: alpha(theme.palette.warning.light, 0.2),
  },
  live: {
    border: `1px ${theme.palette.success.main} solid`,
    backgroundColor: alpha(theme.palette.success.main, 0.2),
  },
  published: {
    border: `1px ${theme.palette.warning.dark} solid`,
    backgroundColor: alpha(theme.palette.warning.dark, 0.2),
  },
  closed: {
    border: `1px ${theme.palette.error.dark} solid`,
    backgroundColor: alpha(theme.palette.error.dark, 0.2),
  },
}));

interface TestStatusComponentProps {
  value: TestStatusEnum;
  chipProps?: ChipProps;
}

const TestStatusComponent: FunctionComponent<TestStatusComponentProps> = ({
  value,
  chipProps,
}) => {
  const classes = useStyles();
  switch (value) {
    case TestStatusEnum.DRAFT:
      return (
        <Chip
          label="draft"
          className={clsx(classes.testStatus, classes.draft)}
          {...chipProps}
        />
      );
    case TestStatusEnum.PUBLISHED:
      return (
        <Chip
          label="published"
          className={clsx(classes.testStatus, classes.published)}
          {...chipProps}
        />
      );
    case TestStatusEnum.CLOSED:
      return (
        <Chip
          label="closed"
          className={clsx(classes.testStatus, classes.closed)}
          {...chipProps}
        />
      );
    case TestStatusEnum.LIVE:
      return (
        <Chip
          label="live"
          className={clsx(classes.testStatus, classes.live)}
          {...chipProps}
        />
      );
    default:
      return <></>;
  }
};

export default TestStatusComponent;
