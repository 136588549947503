export const removeTagFromMedia = /* GraphQL */ `
  mutation DeleteMediaItemTagWithoutCircular(
    $input: DeleteMediaItemTagsInput!
    $condition: ModelMediaItemTagsConditionInput
  ) {
    deleteMediaItemTags(input: $input, condition: $condition) {
      id
      tagId
      createdAt
      updatedAt
    }
  }
`;

export const createMediaItemTagWithoutCircular = /* GraphQL */ `
  mutation CreateMediaItemTagWithoutCircular(
    $input: CreateMediaItemTagsInput!
    $condition: ModelMediaItemTagsConditionInput
  ) {
    createMediaItemTags(input: $input, condition: $condition) {
      id
      tagId
      createdAt
      updatedAt
      tag {
        id
        name
      }
    }
  }
`;

export const updateMediaItemWithoutCircular = /* GraphQL */ `
  mutation UpdateMediaItemWithoutCircular(
    $input: UpdateMediaItemInput!
    $condition: ModelMediaItemConditionInput
  ) {
    updateMediaItem(input: $input, condition: $condition) {
      id
      image {
        fullSize {
          width
          height
          key
        }
        thumbNail {
          width
          height
          key
        }
      }
      source
      createdAt
      updatedAt
    }
  }
`;

export const createMediaItemWithoutCircular = /* GraphQL */ `
  mutation CreateMediaItemWithoutCircular(
    $input: CreateMediaItemInput!
    $condition: ModelMediaItemConditionInput
  ) {
    createMediaItem(input: $input, condition: $condition) {
      id
      image {
        fullSize {
          width
          height
          key
        }
        thumbNail {
          width
          height
          key
        }
      }
      source
      originalFileName
      createdAt
      updatedAt
    }
  }
`;

export const publicRegisterUser = `mutation PublicRegisterUser(
  $name: String!
  $email: String!
  $institution: String!
  $additionalInformation: String
  $confirmedUsagePolicy: Boolean!
  $researchGoal: String!
) {
registerUser(
    name: $name
    email: $email
    institution: $institution
    additionalInformation: $additionalInformation
    confirmedUsagePolicy: $confirmedUsagePolicy
    researchGoal: $researchGoal
  ) {
    __typename
  }
}`;
