import React, { FunctionComponent, PropsWithChildren } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Tooltip } from '@mui/material';
import { Info } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  section: {
    flex: 1,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    padding: 14,
    minHeight: 50,
    position: 'relative',
    marginTop: theme.spacing(0.5),
    '& >:nth-child(2)': {
      marginTop: theme.spacing(0.25),
    },
    "& >div[class*='section']:nth-child(2)": {
      marginTop: theme.spacing(1),
    },
    "& >div[class*='section']:last-child": {
      marginBottom: 0,
    },
  },
  label: {
    display: 'block',
    position: 'absolute',
    width: 'fit-content',
    transform: 'translate(-7px, -25px)',
    maxWidth: 'calc(94% - 20px)',
    padding: '2px 5px',
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.64)',
    backgroundImage:
      'linear-gradient(rgba(255,255,255,1), rgba(255,255,255,1), rgba(255,255,255,0))',
    borderRadius: 4,
  },
  bigLabel: {
    color: 'rgba(0, 0, 0, 0.64)',
    display: 'block',
    fontSize: '1rem',
    paddingBottom: theme.spacing(2),
    "& > svg[class*='toolTipIcon']": {
      float: 'right',
      position: 'relative',
      right: 0,
      top: 0,
    },
  },
  toolTipIcon: {
    display: 'inline-block',
    color: '#5c5c5c',
    position: 'absolute',
    right: 5,
    top: -12,
  },
}));

type SectionProps = PropsWithChildren<{
  label?: string;
  bigLabel?: boolean;
  toolTip?: string;
  error?: boolean;
}>;

const Section: FunctionComponent<SectionProps> = (props) => {
  const { label, children, toolTip, bigLabel } = props;
  const classes = useStyles();

  const toolTipIcon = toolTip && (
    <Tooltip
      placement="top"
      title={toolTip}
      aria-labelledby={label}
      className={classes.toolTipIcon}
    >
      <Info />
    </Tooltip>
  );
  const labelEl = label && (
    <label
      aria-label={label}
      className={bigLabel ? classes.bigLabel : classes.label}
    >
      {label}
      {bigLabel ? toolTipIcon : null}
    </label>
  );

  return (
    <div className={classes.section}>
      {labelEl}
      {children}
      {!bigLabel ? toolTipIcon : null}
    </div>
  );
};

export default Section;
