import { TimeMeasuredValue } from '../../../../../events/TimedEvent';
import React from 'react';
import { ControlOccurrence } from '../../../../../subject/testRunner/controls/control-stereotypes';
import { QuestionAnswerEvent } from '../../wsw-controls';
import { ControlOccurrenceEvent } from '../../../../../subject/testRunner/controls/control-monitor';

export function QuestionAnswerListener(
  listener: (
    e: TimeMeasuredValue<React.MouseEvent>,
  ) => ControlOccurrence<QuestionAnswerEvent>,
) {
  return (e: React.MouseEvent) => {
    const measured = TimeMeasuredValue.now(e);
    const occurrence = listener(measured);
    ControlOccurrenceEvent.dispatchControlOccurrence(occurrence, e.target);
  };
}
