import {
  AdminRemoveUserMutationVariables,
  FunctionBodyResponse,
  UserListElement,
  UserTestStatusMutationVariables,
  UserTestStatusResponse,
} from '../../API';
import React, { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { Delete } from '@mui/icons-material';
import { GQLQuery, NewGQL } from '../../GQL';
import { adminRemoveUser, userTestStatus } from '../../graphql/mutations';
import TestStatus from '../tests/testAdmin/testDetails/TestStatus';

function removeUserMutation(userId: string) {
  return NewGQL.DEFAULT_CLIENT.execute(
    GQLQuery.Mutate<AdminRemoveUserMutationVariables, FunctionBodyResponse>(
      adminRemoveUser,
    ).create({
      userId,
    }),
  );
}

function useRemoveUser() {
  const [loading, setLoading] = useState(false);
  const removeUser = async (userId: string) => {
    setLoading(true);
    await removeUserMutation(userId);
    setLoading(false);
  };
  return { removeUser, loading };
}

type DeleteUserDialogProps = {
  user: UserListElement;
  onSuccess: () => void;
};

function useUsersTestOwnerInformation(userId: string, dialogOpen: boolean) {
  const [state, setState] = React.useState<
    | { type: 'loading' | 'idle' }
    | { type: 'error'; error: any }
    | { type: 'success'; data: UserTestStatusResponse }
  >({ type: 'idle' });
  React.useEffect(() => {
    if (dialogOpen && state.type === 'idle') {
      setState({ type: 'loading' });
      NewGQL.DEFAULT_CLIENT.execute(
        GQLQuery.Mutate<
          UserTestStatusMutationVariables,
          UserTestStatusResponse
        >(userTestStatus).create({
          userId,
        }),
      )
        .then((res) => {
          setState({ type: 'success', data: res });
        })
        .catch((error) => {
          setState({ type: 'error', error });
        });
    }
  }, [dialogOpen, state.type, userId]);
  return state;
}

export const DeleteUserDialog: FC<DeleteUserDialogProps> = ({
  user,
  onSuccess,
}) => {
  const [open, setOpen] = useState(false);
  const userTestOwnerStatus = useUsersTestOwnerInformation(user.sub, open);
  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const { removeUser, loading } = useRemoveUser();

  const handleDeleteUser = async () => {
    await removeUser(user.sub);
    onSuccess();
    closeDialog();
  };

  return (
    <>
      <GridActionsCellItem
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
        showInMenu={false}
        placeholder=""
        icon={<Delete />}
        label="Delete"
        onClick={openDialog}
      />
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {loading
              ? 'Searching for Tests of the User'
              : 'The User has created the following tests.\n\n' +
                `Are you sure you want to delete the user ${user.email}?`}
          </DialogContentText>
          {userTestOwnerStatus.type === 'success' && (
            <List>
              {userTestOwnerStatus.data.ownedTests.map((test) => (
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        {test.testType} {test.testName}
                        <TestStatus
                          value={test.testStatus}
                          chipProps={{ size: 'small', sx: { ml: 1 } }}
                        />
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
        {loading || userTestOwnerStatus.type === 'loading' ? (
          <LinearProgress />
        ) : (
          <DialogActions>
            <Button onClick={closeDialog} variant="contained" color="inherit">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteUser}
            >
              Delete User
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
