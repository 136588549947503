import { AmpScreenData } from '../loader/view-data/amp-screen-data';
import {
  DisplayData,
  Optional,
  OptionalValue,
} from '../../../subject/testRunner/screens/selectors/data-wrapper';
import { ArrangementVariant } from '../../../subject/testRunner/media/options-test';
import { SelectorTree } from '../../../subject/testRunner/screens/selectors/selector-tree';
import { DrawableMediaInstance } from '../../../subject/testRunner/media/MediaData';
import { InstructionsContent } from '../../../subject/testRunner/screens/basic-views/test-instructions-view';
import { defineOptionSideSelectorFactory } from '../../../subject/testRunner/screens/basic-views/option-test-views';

import { StimulusContent } from '../../../subject/testRunner/screens/basic-views/stimulus-content';
import { TipsContent } from '../../../subject/testRunner/screens/basic-views/tips-content';
import { AmpOptionsData } from '../loader/view-data/amp-options-data';
import { AmpInstructionsData } from '../loader/view-data/amp-instructions-data';
import { AmpOptionsType } from '../loader/structure/amp-options-type';
import { defineContentSelector } from '../../../subject/testRunner/screens/selectors/content-selector';
import { defineGroupSelector } from '../../../subject/testRunner/screens/selectors/group-selector';

const optionSelectorFactory = defineOptionSideSelectorFactory<
  'attribute',
  'attribute'
>(new ArrangementVariant<AmpOptionsType>(['attribute']));

const ampRootSelector = defineGroupSelector({
  selectGroupData(
    input: AmpScreenData,
  ): OptionalValue<AmpInstructionsData | AmpOptionsData> {
    return input instanceof AmpInstructionsData ||
      input instanceof AmpOptionsData
      ? Optional.value(input)
      : Optional.none();
  },
  children: {
    instructions: defineContentSelector({
      selectRenderData: (input: AmpInstructionsData | AmpOptionsData) => {
        return input instanceof AmpInstructionsData && input.scope === 'test'
          ? DisplayData.displayData<InstructionsContent>(input.content)
          : DisplayData.hide<InstructionsContent>();
      },
    }),
    blockInstructions: defineContentSelector({
      selectRenderData: (input: AmpInstructionsData | AmpOptionsData) => {
        return input instanceof AmpInstructionsData && input.scope === 'block'
          ? DisplayData.displayData<InstructionsContent>(input.content)
          : DisplayData.hide<InstructionsContent>();
      },
    }),
    options: defineGroupSelector({
      selectGroupData(
        input: AmpInstructionsData | AmpOptionsData,
      ): OptionalValue<AmpOptionsData> {
        return input instanceof AmpOptionsData
          ? Optional.value(input)
          : Optional.none();
      },
      children: {
        tips: defineContentSelector({
          selectRenderData: (input: AmpOptionsData) => {
            return input.tips
              ? DisplayData.displayData<TipsContent>(input.tips)
              : DisplayData.hide<TipsContent>();
          },
        }),
        stimulus: defineContentSelector({
          selectRenderData: (input: AmpOptionsData) => {
            if (!(input.content instanceof InstructionsContent)) {
              return DisplayData.displayData<DrawableMediaInstance | undefined>(
                input.content?.stimulus,
              );
            }
            return DisplayData.hide<DrawableMediaInstance | undefined>();
          },
        }),
        fail: defineContentSelector({
          selectRenderData: (input: AmpOptionsData) => {
            if (
              !(input.content instanceof InstructionsContent) &&
              input.content instanceof StimulusContent
            ) {
              return DisplayData.displayData<undefined>(undefined);
            }
            return DisplayData.hide<undefined>();
          },
        }),
        instructions: defineContentSelector({
          selectRenderData: (input: AmpOptionsData) => {
            return input.content instanceof InstructionsContent
              ? DisplayData.displayData<InstructionsContent>(input.content)
              : DisplayData.hide<InstructionsContent>();
          },
        }),
        optionsLeft: optionSelectorFactory('left'),
        optionsRight: optionSelectorFactory('right'),
      },
    }),
  },
});

export const AMPSelectorTree = new SelectorTree('amp', ampRootSelector);
