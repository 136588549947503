import React, { FC } from 'react';
import {
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogContent,
  Snackbar,
} from '@mui/material';
import { Alert } from '@mui/lab';
import { useIsTestComplete } from '../testComplete.hook';
import { hasRunnerConfiguration } from '../../../subject/testRunner/runner-config-map';
import ErrorBoundary from '../../../errorHandeling/ErrorBoundary';
import { StagePreviewContainer } from '../../../subject/testRunner/stage/preview/StagePreviewContainer';
import { TestType } from '../../../subject/types';
import { standAloneProvider } from '../../../subject/testRunner/state/TestProviderMap';
import { useIsAdmin } from '../../../../auth/auth.hook';

interface SuccessSnackBarProps {
  showSuccessBar: boolean;
  onClose: () => void;
  severity: 'success' | 'error';
  test: any;
  steps: any;
  testType: TestType;
}

export const SuccessSnackBar: FC<SuccessSnackBarProps> = (props) => {
  const isAdmin = useIsAdmin();
  const [showPreview, setShowPreview] = React.useState(false);

  const probandTestUrl = standAloneProvider.createTestUrl(
    {
      testType: props.testType,
      testId: props.test.id,
    },
    'preview',
    true,
    props.test.language ? { lang: props.test.language } : {},
  );

  const testIsComplete = useIsTestComplete(props.steps, props.test);
  return (
    <React.Fragment>
      <Snackbar
        open={props.showSuccessBar}
        autoHideDuration={2000}
        onClose={props.onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert
          onClose={props.onClose}
          severity={props.severity}
          sx={{ width: '100%' }}
        >
          {props.severity === 'success' ? (
            <React.Fragment>
              <AlertTitle>Success</AlertTitle>
              Saved test successfully!
              {testIsComplete && (
                <Box display="flex">
                  {isAdmin && (
                    <Button
                      sx={{ mb: '2px' }}
                      onClick={() => setShowPreview(true)}
                    >
                      Preview Scientist
                    </Button>
                  )}
                  <Button
                    sx={{ mb: '2px' }}
                    target="_blank"
                    href={probandTestUrl}
                  >
                    Preview Respondent
                  </Button>
                </Box>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <AlertTitle>Error</AlertTitle>
              Could not save test!
            </React.Fragment>
          )}
        </Alert>
      </Snackbar>
      {showPreview && hasRunnerConfiguration(props.testType) && (
        <Dialog
          open={showPreview}
          onClose={() => setShowPreview((p) => !p)}
          fullWidth
          maxWidth={false}
        >
          <DialogContent>
            <ErrorBoundary>
              <StagePreviewContainer
                test={props.test}
                testType={props.testType}
              />
            </ErrorBoundary>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};
