import React, { FC } from 'react';
import { Box } from '@mui/material';
import { PresentationPhaseInputComponent } from '../../components/presentationPhase/PresentationPhaseInputComponent';
import { EditorStepTitle } from '../../../../../components/grid';
import * as yup from 'yup';
import { ConditionalQuestionSetSchema } from '../../components/conditionalQuestionSet/ConditionalQuestionSet';
import { OptionalInstructionsSchema } from '../../../../subject/testRunner/screens/OptionalInstructionsScreen';

export const WswLearningPhaseStepSchema = yup.object().shape({
  learningPhase: yup.object({
    instructionsScreen: OptionalInstructionsSchema,
    feedbackScreen: OptionalInstructionsSchema,
    questionOffsetTimeout: yup
      .number()
      .notRequired()
      .integer('must be integer')
      .nullable()
      .default(undefined),
    questionsSet: ConditionalQuestionSetSchema,
  }),
});

export const WswLearningPhasesStep: FC = () => {
  return (
    <Box>
      <EditorStepTitle prefix={4} title="Learning Phase" />
      <PresentationPhaseInputComponent
        fieldName="learningPhase"
        label="Learning Phase"
      />
    </Box>
  );
};
