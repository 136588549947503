import { TestType } from '../types';
import { ControlInputMonitor } from './controls/control-monitor';
import { ControlHandlerManager } from './controls/control-handlers';
import { SelectorTree } from './screens/selectors/selector-tree';
import { TestBaseContext } from './state/baseTestContext/context/ContextTypes';
import { NewGQLClient } from '../../../GQL';
import {
  MediaResourceLoadingManager,
  TestMediaResourceMap,
} from './media/media-resource-loader';
import { TestTree } from './graph/test-tree';
import { TestResourceAccessor } from './media/test-resource-loader';
import { BaseTest } from '../../tests/types';
import { ObjectTransformer } from './media/drawable/drawable-collector';
import { ResultTableColumn } from '../../tests/IAT/runner/result-preview-table';
import { TreeSequence } from './graph/tree-sequence/tree-sequence';
import { TestScreenComposer } from './screens/composer/test-screen-composer';

export interface RunnerConfiguration<T extends BaseTest> {
  resultPreviewTable?: ResultTableColumn<any>[];
  testType: TestType;
  controlFactory: (
    structure: any,
  ) => (inputMonitor: ControlInputMonitor) => ControlHandlerManager;
  selectorTree: SelectorTree<any>;
  createViews: (structure: any) => TestScreenComposer;
  load: (
    testContext: TestBaseContext,
    gql: NewGQLClient,
    mediaManager: MediaResourceLoadingManager<TestMediaResourceMap>,
  ) => Promise<{
    tree: TestTree;
    sequence: TreeSequence;
    structure: { accessor: TestResourceAccessor<T> };
  }>;
}

export function createRunnerConfiguration<T extends BaseTest>(
  testType: TestType,
  configuration: Omit<RunnerConfiguration<T>, 'testType'>,
) {
  return {
    testType,
    ...configuration,
  };
}

export function defineRunnerMap(...configurations: RunnerConfiguration<any>[]) {
  return ObjectTransformer.fromTypedEntries(
    configurations.map((c) => [c.testType, c]),
  );
}
