import { DimensionNameMap, SizedArea } from '../media/drawable-dimensions';
import React, { FC } from 'react';
import { Observable } from 'rxjs';
import { OptionalValue } from './selectors/data-wrapper';
import { DisplayStateProcessor } from './composer/screen-instance/display-state-processor';
import { MeasurableValue } from './composer/screen-instance/measurable-value';

export interface ViewContainerFactory {
  createContainer(
    processor: DisplayStateProcessor<any>,
    buildInner: (
      dataStream: Observable<MeasurableValue<any>>,
      stateStream: Observable<MeasurableValue<OptionalValue<any>>>,
    ) => FC,
    size: SizedArea,
  ): FC;
}

const alignmentFlexMap = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
};

export class ViewContentContainerFactory implements ViewContainerFactory {
  constructor(public alignment: DimensionNameMap<'start' | 'center' | 'end'>) {}

  createContainer(
    processor: DisplayStateProcessor<any>,
    buildInner: (
      dataStream: Observable<MeasurableValue<any>>,
      stateStream: Observable<MeasurableValue<OptionalValue<any>>>,
    ) => React.FC,
    size: SizedArea,
  ): FC {
    return () => {
      const containerRef = React.useRef<HTMLDivElement | null>(null);
      React.useEffect(() => {
        const subscription = processor.displayStream.subscribe({
          next: (v) => {
            containerRef.current!.style.visibility =
              v.value === 'display' ? 'visible' : 'hidden';
            v.measureProcessTime();
          },
        });
        return () => subscription.unsubscribe();
      }, []);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const ContentComponent = React.useMemo(
        () => buildInner(processor.dataStream, processor.stateStream),
        [],
      );
      return (
        <div
          ref={containerRef}
          style={{
            position: 'absolute',
            display: 'flex',
            overflow: 'visible',
            visibility: 'hidden',
            ...size.toSizeModeStyle(),
            justifyContent: alignmentFlexMap[this.alignment.horizontal],
            alignItems: alignmentFlexMap[this.alignment.vertical],
          }}
        >
          <ContentComponent />
        </div>
      );
    };
  }
}
