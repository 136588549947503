import React from 'react';
import { TestsMetaData, TestType } from '../subject/types';
import { useTestAdminNav } from './testAdmin/utils';
import { useSelector } from 'react-redux';
import { useAppAuthenticator } from '../../auth/app-authenticator.hook';
import {
  AsyncAppThunk,
  RootState,
  useAppDispatch,
} from '../../app/redux/store';
import { BaseTest } from './types';
import {
  Card,
  CardContent,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { formatDate } from '../../dateUtils';
import TestStatusComponent from './testAdmin/testDetails/TestStatus';

interface TestWidgetProps<T> {
  type: TestType;
  fetchTestsThunk: AsyncAppThunk<T[]>;
}

export function TestWidget<T>({ type, fetchTestsThunk }: TestWidgetProps<T>) {
  const { navigateToList, navigateToDetails } = useTestAdminNav(type);

  const { user } = useAppAuthenticator();

  const dispatch = useAppDispatch();

  const selected = useSelector((state: RootState) => ({
    loading: state[type].isLoading as boolean,
    tests: (Object.values(state[type].tests) as BaseTest[]).filter(
      (test) => test.owner === user?.username,
    ),
  }));

  React.useEffect(() => {
    // @ts-ignore
    dispatch(fetchTestsThunk());
  }, [dispatch, fetchTestsThunk]);

  return (
    <Card sx={{ position: 'relative' }}>
      {selected.loading && (
        <LinearProgress sx={{ position: 'absolute', width: '100%' }} />
      )}
      <CardContent>
        <Link
          color="primary"
          variant="h6"
          onClick={() => navigateToList()}
          sx={{ cursor: 'pointer' }}
          title={TestsMetaData[type].longName}
        >
          {TestsMetaData[type].shortName}
        </Link>
        <List sx={{ overflow: 'auto', height: 150, position: 'relative' }}>
          {selected.tests.map((test) => (
            <ListItem
              divider={true}
              sx={{ pl: 0 }}
              button
              key={test.id}
              onClick={() => navigateToDetails(test.id)}
              secondaryAction={
                <Tooltip title={'Last update'} placement="bottom-start">
                  <Typography color="textSecondary" variant="body2">
                    {formatDate(test.updatedAt)}
                  </Typography>
                </Tooltip>
              }
            >
              <Tooltip title={'Name of test'} placement="bottom-start">
                <ListItemText
                  primary={
                    <React.Fragment>
                      {test.name || 'No Name'}
                      <TestStatusComponent
                        value={test.status}
                        chipProps={{ sx: { ml: 2, height: 20 } }}
                      />
                    </React.Fragment>
                  }
                />
              </Tooltip>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
