import { Chip, ChipProps } from '@mui/material';
import { FC } from 'react';
import { UserRegistrationStatus } from '../../API';

export const userRegistrationStatusMap: Record<
  UserRegistrationStatus,
  { label: string; color: ChipProps['color'] }
> = {
  PENDING: { label: 'Pending', color: 'primary' },
  CONFIRMED: { label: 'Confirmed', color: 'success' },
  DECLINED: { label: 'Declined', color: 'error' },
};

export const UserRegistrationStatusChip: FC<{
  status: UserRegistrationStatus;
  size?: ChipProps['size'];
}> = ({ status, size }) => {
  const { label, color } = userRegistrationStatusMap[status];
  return <Chip label={label} color={color} size={size} />;
};
