import { ObjectSchema } from 'yup';
import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';
import _ from 'lodash';
import { BaseTest } from '../../types';
import { removeKeysDeep } from '../utils';
import {
  MediaItemSnapshot,
  MediaItemSnapshotScope,
  TestStatus,
} from '../../../../API';
import { EditorMode } from './types';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { keyboardSchema } from '../../../../components/input/KeybordPicker';
import { TestType } from '../../../subject/types';
import { InitialPodtCreatorState } from '../../PODT/creator/initialState';
import InitialIatCreatorState from '../../IAT/creator/initialState';
import InitialWswCreatorState from '../../WSW/creator/initialState';
import InitialAmpEditorState from '../../AMP/creator/initialState';
import { InitialPodtaCreatorState } from '../../PODTA/creator/initialState';
import { instructionsSchema } from './instructionsSchema';
import { Storage } from 'aws-amplify';
import { v4 } from 'uuid';
import { enqueueSnackbar } from 'notistack';

export function validateAllSteps<C extends FormikValues>(
  values: C,
  steps: { validationSchema?: ObjectSchema<any> }[],
) {
  const errors = [];
  for (let i = 0; i < steps.length; i++) {
    errors[i] = !_.isEmpty(validateStep(values, steps[i]));
  }
  return errors;
}

function isImageMediaItemSnapshotByFields(
  input: Record<string, any>,
): input is MediaItemSnapshot {
  return (
    'id' in input &&
    'scope' in input &&
    'image' in input &&
    'tags' in input &&
    input.image !== null &&
    'fullSize' in input.image &&
    'thumbNail' in input.image
  );
}

function scanRecursivelyForPrivateImages(
  targetObject: Record<string, any>,
  currentPath: string = '',
): {
  mediaItem: MediaItemSnapshot & {
    image: Exclude<MediaItemSnapshot['image'], null | undefined>;
  };
  path: string;
}[] {
  if (typeof targetObject === 'object' && targetObject !== null) {
    if (isImageMediaItemSnapshotByFields(targetObject)) {
      const targetMediaItem = targetObject;
      if (
        targetMediaItem.image &&
        targetMediaItem.scope === MediaItemSnapshotScope.PRIVATE
      ) {
        return [
          {
            mediaItem: targetMediaItem as MediaItemSnapshot & {
              image: Exclude<MediaItemSnapshot['image'], null | undefined>;
            },
            path: currentPath,
          },
        ];
      } else {
        return [];
      }
    }
    return Object.entries(targetObject).flatMap(([key, value]) => {
      const newPath = currentPath ? `${currentPath}.${key}` : key;

      if (Array.isArray(value)) {
        return value.flatMap((element, i) =>
          scanRecursivelyForPrivateImages(element, `${newPath}[${i}]`),
        );
      }

      if (typeof value === 'object' && value !== null) {
        return scanRecursivelyForPrivateImages(value, newPath);
      }

      return [];
    });
  }
  return [];
}

function duplicateMediaItemSnapshot(snapshot: {
  mediaItem: MediaItemSnapshot & {
    image: Exclude<MediaItemSnapshot['image'], null | undefined>;
  };
  path: string;
}) {
  const uuidRegex = /(\w{8}-\w{4}-4\w{3}-[89ab]\w{3}-\w{12})/;

  const newFullSizeKey = snapshot.mediaItem.image.fullSize.key.replace(
    uuidRegex,
    v4(),
  );

  const showErrorSnackbar = () =>
    enqueueSnackbar(
      'Error while duplicating test, test might not work correctly',
      { variant: 'error' },
    );
  Storage.copy(
    { key: snapshot.mediaItem.image.fullSize.key },
    {
      key: newFullSizeKey,
      level: 'public',
    },
  ).catch(showErrorSnackbar);

  const newThumbnailKey = snapshot.mediaItem.image.thumbNail.key.replace(
    uuidRegex,
    v4(),
  );

  Storage.copy(
    { key: snapshot.mediaItem.image.thumbNail.key },
    {
      key: newThumbnailKey,
      level: 'public',
    },
  ).catch(showErrorSnackbar);

  const newSnapshot = {
    ...snapshot.mediaItem,
    id: v4(),
    image: {
      ...snapshot.mediaItem.image,
      fullSize: {
        ...snapshot.mediaItem.image.fullSize,
        key: newFullSizeKey,
      },
      thumbNail: {
        ...snapshot.mediaItem.image.thumbNail,
        key: newThumbnailKey,
      },
    },
  };

  return {
    mediaItem: newSnapshot,
    path: snapshot.path,
  };
}

/**
 * this function steps recursively through the test object
 * and duplicates all media items that have scope PRIVATE into s3
 */
function duplicateTestMediaItems<T extends BaseTest>(test: T) {
  const copiedTest = JSON.parse(JSON.stringify(test));

  const privateMediaItems = scanRecursivelyForPrivateImages(copiedTest);

  privateMediaItems.forEach(({ mediaItem, path }) => {
    const newMediaItemSnaphot = duplicateMediaItemSnapshot({
      mediaItem,
      path,
    });

    _.set(copiedTest, path, newMediaItemSnaphot.mediaItem);
  });

  return copiedTest;
}

export function testToTestInput<T extends BaseTest, C>(
  { owner, ...test }: T,
  mode: EditorMode,
): C {
  const isDuplicate = mode === 'duplicate';

  return {
    ...(removeKeysDeep(
      isDuplicate ? duplicateTestMediaItems(test) : test,
      '__typename',
      'createdAt',
      'updatedAt',
      'owner',
      'tableData',
    ) as unknown as C),
    id: mode === 'edit' ? test.id : undefined,
    name: isDuplicate ? `COPY OF ${test.name}` : test.name,
    status: TestStatus.DRAFT,
  };
}

interface EditorParams {
  mode: EditorMode;
  testId: string;
  step: number;
}

export function useEditorParams(): EditorParams {
  const { mode, testId, step } = useParams<'mode' | 'testId' | 'step'>();
  return {
    mode: mode as EditorMode,
    testId: testId as 'new' | string,
    step: step ? Number(step) : 0,
  };
}

export function validateStep<C extends FormikValues>(
  values: C,
  step: { validationSchema?: ObjectSchema<any> },
) {
  try {
    validateYupSchema(values, step.validationSchema, true, values);
  } catch (err) {
    return yupToFormErrors(err);
  }
  return {};
}

export const BasicGameSettingsSchema = {
  name: Yup.string().required('name is required'),
  description: Yup.string().required('description is required'),
  keyboard: keyboardSchema,
  instructions: instructionsSchema,
  interTrialInterval: Yup.number().required('required'),
};

export const initialStatesMap: { [key in TestType]: object } = {
  [TestType.AMP]: InitialAmpEditorState,
  [TestType.IAT]: InitialIatCreatorState,
  [TestType.WSW]: InitialWswCreatorState,
  [TestType.PODT]: InitialPodtCreatorState,
  [TestType.PODTA]: InitialPodtaCreatorState,
  [TestType.Example]: {},
};
