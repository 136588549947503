import { createApi } from '@reduxjs/toolkit/query/react';
import { MediaItem, Tag } from '../types';
import {
  listMediaItemsWithoutCircular,
  listMediaItemTagsWithoutCircular,
  listTagsWithoutCircular,
} from '../../../graphql/customQueries';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import GQL, { GQLQuery, NewGQL } from '../../../GQL';
import {
  CreateMediaItemMutationVariables,
  CreateMediaItemTagsMutationVariables,
  CreateTagInput,
  CreateTagMutationVariables,
  DeleteMediaItemTagsMutationVariables,
  DeleteTagInput,
  DeleteTagMutationVariables,
  ImageInstanceInput,
  MediaItemTags,
  RemoveMediaItemMutationVariables,
  RemoveMediaItemTagMutationVariables,
  RemoveMediaItemTagResponse,
  RemoveMediaItemTagResultType,
  UpdateMediaItemMutationVariables,
} from '../../../API';
import {
  createTag,
  deleteTag,
  removeMediaItem,
  removeMediaItemTag,
} from '../../../graphql/mutations';
import {
  createMediaItemTagWithoutCircular,
  createMediaItemWithoutCircular,
  removeTagFromMedia,
  updateMediaItemWithoutCircular,
} from '../../../graphql/customMutations';
import ImageToolz from '../../../ImageToolz';
import { v4 as uuid } from 'uuid';
import { Storage } from 'aws-amplify';

interface LinkMediaItemsTagsInput {
  mediaItemIds: string[];
  tags: Tag[];
  existingMediaItemTags: Record<string, string[]>;
}

interface UnlinkMediaTagInput {
  id: string;
}

interface CreateMediaItemInput {
  file: File;
  tags: Tag[];
  source: string;
}

export interface SetSourceInput {
  mediaItemIds: string[];
  source: string;
}

export const mediaApi = createApi({
  reducerPath: 'mediaApi',
  baseQuery: graphqlRequestBaseQuery({
    url: '/graphql',
  }),
  tagTypes: ['MediaItem', 'Tag'],
  endpoints: (builder) => ({
    getMediaItems: builder.query<MediaItem[], void>({
      queryFn: async () => ({
        data: await GQL.list<MediaItem>(listMediaItemsWithoutCircular),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'MediaItem' as const, id })),
              { type: 'MediaItem', id: 'LIST' },
            ]
          : [{ type: 'MediaItem', id: 'LIST' }],
    }),
    getTags: builder.query<Tag[], void>({
      keepUnusedDataFor: 120,
      queryFn: async () => ({
        data: (await GQL.list<Tag>(listTagsWithoutCircular)).sort((a, b) =>
          a.name.localeCompare(b.name),
        ),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Tag' as const, id })),
              { type: 'Tag', id: 'LIST' },
            ]
          : [{ type: 'Tag', id: 'LIST' }],
    }),
    createMediaItems: builder.mutation<MediaItem[], CreateMediaItemInput[]>({
      queryFn: async (args, api) => {
        const result = await Promise.all(
          args.map((input) => createMediaItem(input, api.getState())),
        );
        return { data: result.map((r) => r.data) };
      },
      invalidatesTags: [
        { type: 'MediaItem', id: 'LIST' },
        { type: 'Tag', id: 'LIST' },
      ],
    }),
    deleteMediaItem: builder.mutation<
      string,
      { id: string; fullSizeKey: string; thumbNailKey: string }
    >({
      queryFn: async (input) => {
        await Storage.remove(input.fullSizeKey);
        await Storage.remove(input.thumbNailKey);

        const removeMediaItemQuery = GQLQuery.Mutate<
          RemoveMediaItemMutationVariables,
          string
        >(removeMediaItem);

        await NewGQL.DEFAULT_CLIENT.execute(
          removeMediaItemQuery.create({ mediaItemId: input.id }),
        );
        return {
          data: input.id,
        };
      },
      invalidatesTags: [
        { type: 'MediaItem', id: 'LIST' },
        { type: 'Tag', id: 'LIST' },
      ],
    }),
    createTag: builder.mutation<Tag, CreateTagInput>({
      queryFn: async (arg) => ({
        data: await GQL.mutate<Tag, CreateTagMutationVariables>(createTag, {
          input: { name: arg.name },
        }),
      }),
      invalidatesTags: [{ type: 'Tag', id: 'LIST' }],
    }),
    linkMediaItemsTags: builder.mutation<void, LinkMediaItemsTagsInput>({
      queryFn: async (arg) => {
        for (const tag of arg.tags) {
          await Promise.all(
            arg.mediaItemIds
              .filter(
                (mId) => !arg.existingMediaItemTags[mId]?.includes(tag.id),
              )
              .map((mId) => {
                return GQL.mutate<Tag, CreateMediaItemTagsMutationVariables>(
                  createMediaItemTagWithoutCircular,
                  {
                    input: {
                      tagId: tag.id,
                      mediaItemId: mId,
                    },
                  },
                );
              }),
          );
        }
        arg.mediaItemIds.map(async (mId) => {
          await GQL.mutate<MediaItem, UpdateMediaItemMutationVariables>(
            updateMediaItemWithoutCircular,
            {
              input: {
                id: mId,
                tagsString: [
                  ...(arg.existingMediaItemTags[mId] || []),
                  ...arg.tags.map((t) => t.id),
                ].join(', '),
              },
            },
          );
        });
        return { data: undefined };
      },
      invalidatesTags: () => [{ type: 'MediaItem', id: 'LIST' }],
    }),
    unlinkMediaItemTag: builder.mutation<void, UnlinkMediaTagInput>({
      queryFn: async (arg) => {
        // get join table items for mediaItems and tags
        const mediaItemTags = await GQL.list<MediaItemTags>(
          listMediaItemTagsWithoutCircular,
          { filter: { id: { eq: arg.id } } },
        );

        // iterate through join table items and remove tagId from mediaItem's tagsIds
        await Promise.all(
          mediaItemTags.map((mt) => {
            return GQL.mutate<MediaItem, UpdateMediaItemMutationVariables>(
              updateMediaItemWithoutCircular,
              {
                input: {
                  id: arg.id,
                  tagsString: JSON.parse(`[${mt.mediaItem.tagsString}]`)
                    .filter((tId: string) => tId !== mt.tagId)
                    .join(', '),
                },
              },
            );
          }),
        );

        // remove items from join table
        await GQL.mutate<MediaItem, DeleteMediaItemTagsMutationVariables>(
          removeTagFromMedia,
          { input: { id: arg.id } },
        );
        return { data: undefined };
      },
      invalidatesTags: () => [{ type: 'MediaItem', id: 'LIST' }],
    }),
    deleteTag: builder.mutation<void, DeleteTagInput>({
      queryFn: async (arg) => {
        await GQL.mutate<{ id: string }, DeleteTagMutationVariables>(
          deleteTag,
          { input: { id: arg.id } },
        );
        return { data: undefined };
      },
      invalidatesTags: () => [{ type: 'Tag', id: 'LIST' }],
    }),
    deleteTagCascade: builder.mutation<
      RemoveMediaItemTagResponse,
      RemoveMediaItemTagMutationVariables
    >({
      queryFn: async (arg) => {
        const result = await NewGQL.DEFAULT_CLIENT.execute(
          GQLQuery.Mutate<
            RemoveMediaItemTagMutationVariables,
            RemoveMediaItemTagResponse
          >(removeMediaItemTag).create({
            dryRun: arg.dryRun,
            tagId: arg.tagId,
          }),
        );
        return { data: result };
      },
      invalidatesTags: (result) =>
        result?.type === RemoveMediaItemTagResultType.DELETED
          ? [{ type: 'Tag', id: 'LIST' }]
          : [],
    }),
    setSource: builder.mutation<void, SetSourceInput>({
      queryFn: async (arg) => {
        await Promise.all(
          arg.mediaItemIds.map((mId) =>
            GQL.mutate<MediaItem, UpdateMediaItemMutationVariables>(
              updateMediaItemWithoutCircular,
              { input: { id: mId, source: arg.source } },
            ),
          ),
        );

        return { data: undefined };
      },
      invalidatesTags: () => [{ type: 'MediaItem', id: 'LIST' }],
    }),
  }),
});

export async function uploadImageToStorage(
  file: File,
): Promise<{ fullSize: ImageInstanceInput; thumbNail: ImageInstanceInput }> {
  const imageTools = new ImageToolz();

  const fullSizeDimensions = await imageTools.getDimensions(file);

  const thumbNailBlob = await imageTools.resize(
    file,
    {
      width: 80,
      height: 80,
    },
    0.5,
  );

  const thumbNailDimensions = await imageTools.getDimensions(thumbNailBlob);

  const fileExtension: string | undefined = file.name.split('.')[1];
  const contentType = file.type;
  const id = uuid();

  const storageResultFullSize = (await Storage.put(
    `fullSize/${id}.${fileExtension}`,
    file,
    { contentType },
  )) as {
    key: string;
  };
  const storageResultThumbNail = (await Storage.put(
    `thumbNail/${id}.${fileExtension}`,
    thumbNailBlob,
    {
      contentType,
    },
  )) as { key: string };
  return {
    fullSize: {
      ...fullSizeDimensions,
      ...storageResultFullSize,
    },
    thumbNail: {
      ...thumbNailDimensions,
      ...storageResultThumbNail,
    },
  };
}

// any because else circular reference of rootstate
async function createMediaItem(
  arg: CreateMediaItemInput,
  state: any,
): Promise<{ data: MediaItem }> {
  const { fullSize, thumbNail } = await uploadImageToStorage(arg.file);

  const mediaItem = await GQL.mutate<
    MediaItem,
    CreateMediaItemMutationVariables
  >(createMediaItemWithoutCircular, {
    input: {
      originalFileName: arg.file.name,
      source: arg.source,
      tagsIds: arg.tags.map((t) => t.id),
      image: {
        fullSize,
        thumbNail,
      },
    },
  });

  for (const tag of arg.tags) {
    await GQL.mutate<Tag, CreateMediaItemTagsMutationVariables>(
      createMediaItemTagWithoutCircular,
      {
        input: {
          tagId: tag.id,
          mediaItemId: mediaItem.id,
        },
      },
    );
  }

  return { data: mediaItem };
}

export const {
  useGetMediaItemsQuery,
  useGetTagsQuery,
  useCreateTagMutation,
  useLinkMediaItemsTagsMutation,
  useUnlinkMediaItemTagMutation,
  useCreateMediaItemsMutation,
  useSetSourceMutation,
  useDeleteTagMutation,
  useDeleteTagCascadeMutation,
  useDeleteMediaItemMutation,
} = mediaApi;
