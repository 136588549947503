import {
  createSlice,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import {
  createWswAsync,
  deleteWswAsync,
  fetchWswTest,
  fetchWswTests,
  updateWswAsync,
  updateWswStatusAsync,
} from './wsw.thunk';
import { TestType } from '../../../subject/types';
import { createInitialTestSliceState } from '../../utils';
import { Wsw } from '../types';

export const wswSlice = createSlice({
  name: TestType.WSW,
  // @ts-ignore // Todo neu_manu
  initialState: createInitialTestSliceState<Wsw, any>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWswTest.fulfilled, (state, action) => {
        state.tests[action.payload.id] = action.payload;
      })
      .addCase(fetchWswTests.fulfilled, (state, action) => {
        state.tests = action.payload
          .sort(
            (a, b) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
          )
          .reduce((result: { [id: string]: Wsw }, currentValue: Wsw | null) => {
            if (currentValue) {
              result[currentValue.id] = currentValue;
            }
            return result;
          }, {});
      })
      .addCase(deleteWswAsync.fulfilled, (state, action) => {
        const tests = { ...state.tests };
        delete tests[action.payload];
        state.tests = tests;
      })
      .addMatcher(
        isAnyOf(
          createWswAsync.fulfilled,
          updateWswAsync.fulfilled,
          updateWswStatusAsync.fulfilled,
        ),
        (state, action) => {
          state.tests[action.payload.id] = action.payload;
        },
      )
      .addMatcher(isPending(), (state, action) => {
        if (action.type.toUpperCase().includes(TestType.IAT)) {
          state.isLoading = true;
        }
      })
      .addMatcher(isFulfilled(), (state) => {
        state.isLoading = false;
      })
      .addMatcher(isRejected(), (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

export default wswSlice.reducer;
