import { BaseTest } from '../../../tests/types';
import { TestBaseContext } from '../state/baseTestContext/context/ContextTypes';
import {
  MediaResourceLoadingManager,
  MediaResourceStorage,
  TestMediaResourceLoader,
  TestMediaResourceMap,
} from './media-resource-loader';
import { GQLQuery, NewGQLClient } from '../../../../GQL';
import { TextStyleDescriptor } from './text-drawer';
import {
  DrawableInstanceFactory,
  ImageSize,
  RenderableTextInstanceFactory,
  TextMediaData,
} from './MediaData';
import { StyleableTextTemplate } from '../../../../API';

export interface TestResourceLoaderInstance<T extends BaseTest> {
  load(testContext: TestBaseContext): Promise<TestResourceAccessor<T>>;
}

export class TestResourceLoader<T extends BaseTest> {
  loadModel: (gql: NewGQLClient, id: string) => Promise<T>;
  loadMedia: TestMediaResourceLoader<T>;

  constructor(
    loadModel: (gql: NewGQLClient, id: string) => Promise<T>,
    loadMedia: TestMediaResourceLoader<T>,
  ) {
    this.loadModel = loadModel;
    this.loadMedia = loadMedia;
  }

  init(
    gql: NewGQLClient,
    loader: MediaResourceLoadingManager<TestMediaResourceMap>,
  ): TestResourceLoaderInstance<T> {
    return {
      load: async (testContext: TestBaseContext) => {
        const modelData = await this.loadModel(
          gql,
          testContext.testIdentity.testId,
        );
        return new TestResourceAccessor(
          testContext,
          modelData,
          await this.loadMedia(modelData, loader),
        );
      },
    };
  }
}
export function GQLTestQueryLoader<T extends BaseTest>(
  query: GQLQuery<{ id: string }, T>,
) {
  return (gql: NewGQLClient, id: string) => query.execute(gql, { id });
}

export function CreateTestResourceLoader<T extends BaseTest>(
  modelLoader: TestResourceLoader<T>['loadModel'],
  mediaLoader: TestMediaResourceLoader<T>,
): TestResourceLoader<T> {
  return new TestResourceLoader<T>(modelLoader, mediaLoader);
}

export class TestResourceAccessor<T extends BaseTest> {
  modelData: T;
  resourceStorage: MediaResourceStorage<TestMediaResourceMap>;

  constructor(
    public testContext: TestBaseContext,
    modelData: T,
    resourceStorage: MediaResourceStorage<TestMediaResourceMap>,
  ) {
    this.modelData = modelData;
    this.resourceStorage = resourceStorage;
  }

  createDrawableFactory(
    imageSize?: ImageSize,
    textStyle?: TextStyleDescriptor,
  ) {
    return DrawableInstanceFactory.create(this.resourceStorage, {
      image: imageSize,
      text: textStyle
        ? this.testContext.logic.deriveTextStyle(textStyle)
        : undefined,
    });
  }

  createRenderableFactory(textStyle: TextStyleDescriptor) {
    return new RenderableTextInstanceFactory(
      this.testContext.logic.deriveTextStyle(textStyle),
    );
  }

  loadStyledText(
    styledText: StyleableTextTemplate,
    type: 'drawable' | 'renderable',
  ) {
    const textStyle = TextStyleDescriptor.ofFontStyle(styledText!.fontStyle!);
    const textMediaData = new TextMediaData(
      styledText.desktop!,
      styledText.desktop!,
    );
    return (
      type === 'drawable'
        ? this.createDrawableFactory(undefined, textStyle)
        : this.createRenderableFactory(textStyle)
    ).createInstance(textMediaData);
  }
}
