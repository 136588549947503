import { createTestCrudMap, createTestResultCrudMap } from '../../../../../GQL';
import { Podta, PodtaResult } from '../../types';
import {
  CreatePodtaResultMutationVariables,
  CreatePodtaTestMutationVariables,
  DeletePodtaResultMutationVariables,
  DeletePodtaTestMutation,
  GetPodtaResultQueryVariables,
  GetPodtaTestQueryVariables,
  ListPodtaResultsQueryVariables,
  ListPodtaTestsQueryVariables,
  PodtaResultsByTestIdQueryVariables,
  UpdatePodtaResultMutationVariables,
  UpdatePodtaTestMutationVariables,
} from '../../../../../API';
import {
  createPodtaResult,
  createPodtaTest,
  deletePodtaResult,
  deletePodtaTest,
  updatePodtaResult,
  updatePodtaTest,
} from '../../../../../graphql/mutations';
import {
  getPodtaResult,
  listPodtaResults,
  listPodtaTests,
  podtaResultsByTestId,
} from '../../../../../graphql/queries';
import { getPublicPodta } from '../../../../../graphql/customQueries';

export const PodtaQueries = createTestCrudMap<Podta>()((query) => ({
  Get: query.Get<GetPodtaTestQueryVariables>(getPublicPodta),
  List: query.List<ListPodtaTestsQueryVariables>(listPodtaTests),
  Create: query.Mutate<CreatePodtaTestMutationVariables>(createPodtaTest),
  Update: query.Mutate<UpdatePodtaTestMutationVariables>(updatePodtaTest),
  Delete: query.Mutate<DeletePodtaTestMutation>(deletePodtaTest),
}));

export const PodtaResultQueries = createTestResultCrudMap<PodtaResult>()(
  (query) => ({
    ListByTest:
      query.List<PodtaResultsByTestIdQueryVariables>(podtaResultsByTestId),
    List: query.List<ListPodtaResultsQueryVariables>(listPodtaResults),
    Get: query.Get<GetPodtaResultQueryVariables>(getPodtaResult),
    Update: query.Mutate<UpdatePodtaResultMutationVariables>(updatePodtaResult),
    Delete: query.Mutate<DeletePodtaResultMutationVariables>(deletePodtaResult),
    Create: query.Mutate<CreatePodtaResultMutationVariables>(createPodtaResult),
  }),
);
