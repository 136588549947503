import React, { FC } from "react";
import { ConditionalQuestion } from "../../../../../../API";
import { Box, Card, Grid, Typography } from "@mui/material";
import { TextDisplay } from "../../../../../../components/TextDisplay";

interface ConditionalQuestionViewProps {
  conditionalQuestion?: ConditionalQuestion;
}

export const ConditionalQuestionView: FC<ConditionalQuestionViewProps> = (props) => {
  return (
    <Box>
      <Typography
        color="textSecondary"
        sx={{ display: "flex", alignItems: "center", mb: 1 }}
      >
        Conditional Question
      </Typography>
      <Card variant="outlined" sx={{ mb: 2, p: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextDisplay
              label="Question Text"
              value={props.conditionalQuestion?.questionText}
            />
          </Grid>
          <Grid item xs={6}>
            <TextDisplay
              label="Confirm Text"
              value={props.conditionalQuestion?.confirmText}
            />
          </Grid>
          <Grid item xs={6}>
            <TextDisplay
              label={"Decline Text"}
              value={props.conditionalQuestion?.declineText}
            />
          </Grid>
          <Grid item xs={12}>
            <TextDisplay
              value={props.conditionalQuestion?.confirmText}
              label="Show next question if"
            />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
