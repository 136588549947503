import { GQLQuery } from '../../GQL';
import {
  createTestFinalization,
  updateTestFinalization,
} from '../../graphql/mutations';
import {
  CreateTestFinalizationMutationVariables,
  TestFinalization,
  UpdateTestFinalizationMutationVariables,
} from '../../API';

export const TestFinalizationQueries = {
  Create: GQLQuery.Mutate<
    CreateTestFinalizationMutationVariables,
    TestFinalization
  >(createTestFinalization),
  Update: GQLQuery.Mutate<
    UpdateTestFinalizationMutationVariables,
    TestFinalization
  >(updateTestFinalization),
};
