import React, { useState } from 'react';
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AllInclusive as AllInclusiveIcon,
  Lock,
  LockOpen,
} from '@mui/icons-material';
import * as Yup from 'yup';
import {
  MediaItemSnapshotInput,
  PodtBoxInput,
  PodtSceneInput,
} from '../../../../../../API';
import Loading from '../../../../../../components/Loading';
import StyledIconButton from '../../../../../../components/input/StyledIconButton';
import { SceneSelection } from './SceneSelection';
import { useScenesCache } from './scenesCache.hook';
import { useFormikPodtSceneBoxes } from './formikPodtBoxes.hook';
import { useField } from 'formik';

export type SceneSettingsState = {
  mediaItem: MediaItemSnapshotInput;
  boxes: PodtBoxInput[];
};

export const PodtSceneBoxesValidationSchema = Yup.object().shape({
  scenes: Yup.object().shape({
    scenes: Yup.array().of(
      Yup.object().shape({
        boxes: Yup.array().min(1, 'every scene has to have boxes'),
      }),
    ),
  }),
});

const PodtSceneSettings = () => {
  const [, { value: scenes = [] }] =
    useField<PodtSceneInput[]>('scenes.scenes');

  const scenesCache = useScenesCache(scenes);

  const firstScene = scenes[0];

  const maxHeight = firstScene.mediaItem.image?.fullSize.height ?? 0;

  const {
    error,
    touched,
    setScenesBoxes,
    applyBoxesToAllScenes,
    lockBoxesHeight,
  } = useFormikPodtSceneBoxes('scenes.scenes');

  const [staticHeight, setStaticHeight] = useState<{
    isLocked: boolean;
    value: number;
  }>({
    isLocked: false,
    value: 300,
  });

  const lockHeight = (height?: number) => {
    if (height) {
      setStaticHeight({ value: Math.min(height, maxHeight), isLocked: true });
    } else {
      setStaticHeight((prevState) => ({ ...prevState, isLocked: true }));
    }
    lockBoxesHeight(height ?? staticHeight.value);
  };

  const unlockHeight = () => {
    setStaticHeight((prevState) => ({ ...prevState, isLocked: false }));
  };

  const lockHeightInput = (
    <TextField
      type="number"
      label="Static Height"
      onChange={(v) => lockHeight(Number(v.target.value))}
      value={staticHeight.value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Typography>px</Typography>
            <Tooltip
              title={
                staticHeight.isLocked
                  ? 'Unlock height for all boxes'
                  : 'Lock height for all boxes'
              }
            >
              <IconButton
                onClick={
                  staticHeight.isLocked ? unlockHeight : () => lockHeight()
                }
                size="large"
              >
                {staticHeight.isLocked ? <Lock /> : <LockOpen />}
              </IconButton>
            </Tooltip>
            <StyledIconButton
              label={'Apply boxes to all scenes'}
              onClick={applyBoxesToAllScenes}
            >
              <AllInclusiveIcon />
            </StyledIconButton>
          </InputAdornment>
        ),
      }}
    />
  );

  return scenesCache === undefined ? (
    <Loading />
  ) : (
    <Grid container spacing={3}>
      {touched && error && error.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="caption" color="error">
            You must set up at least one box for every scene
          </Typography>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {lockHeightInput}
      </Grid>
      <Grid item xs={12}>
        <SceneSelection
          scenes={scenes}
          scenesCache={scenesCache}
          setBoxes={setScenesBoxes}
          staticHeight={staticHeight}
        />
      </Grid>
    </Grid>
  );
};

export default PodtSceneSettings;
