import { TestInvocationParams } from './test-invocation-params';
import { TestBaseContextParsingError } from '../context/TestBaseContextParsingError';
import { SurveyProviderLookupMap } from '../../TestProviderMap';
import { TestMode, TestModeList } from '../TestMode';

export interface BaseProviderParams {
  providerName: string; // i.e. limesurvey or unipark...
  mode: TestMode; // indicates weather to record results
  debug: boolean; // indicates if debug infos are shown
}
export type BaseProviderConfigurationParamKeys =
  | Exclude<keyof BaseProviderParams, 'providerName'>
  | 'provider';
/**
 * Gets most basic values from url.
 * To identify the provider and the mode it is running in.
 *
 * @param params Intended for passing fake params or the current url params
 */
export function getBaseProviderParams(
  { configurationParams }: TestInvocationParams,
  surveyProviderLookupMap: SurveyProviderLookupMap,
): BaseProviderParams {
  const providerName = configurationParams.get('provider');

  // eigentlich TestMode | null aber hier nut TestMode
  // damit TestModeList.includes(mode)'s typing nicht failt
  const mode = configurationParams.get('mode') as TestMode;

  const debug = configurationParams.get('debug');

  if (
    providerName === null ||
    surveyProviderLookupMap[providerName] === undefined
  ) {
    throw new TestBaseContextParsingError('not supported provider');
  }

  if (!TestModeList.includes(mode)) {
    throw new TestBaseContextParsingError('not supported mode');
  }

  return {
    providerName,
    mode,
    debug: debug === 'true',
  };
}
