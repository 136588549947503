import { AmpStructure } from '../structure/amp-structure';
import { DefaultTestTree } from '../../../../subject/testRunner/graph/test-tree';
import { OptionsStereotypes } from '../../../../subject/testRunner/controls/control-stereotypes';
import { DefaultTransitionAction } from '../../../../subject/testRunner/graph-state/graph-action';
import { AmpInstructionsData } from '../view-data/amp-instructions-data';
import { AMPModifiers } from './amp-modifiers';
import { createAMPBlockPhasesTrees } from './amp-block-tree';
import { MapScreenTreeNode } from '../../../../subject/testRunner/graph/nodes/map-screen-tree-node';
import { MapPhaseTreeNode } from '../../../../subject/testRunner/graph/nodes/map-phase-tree-node';

export function createAmpTree(ampStructure: AmpStructure) {
  const root = new MapPhaseTreeNode('amp', null);
  const start = createAmpStartPhaseTree(ampStructure, root);

  const blocks = createAMPBlockPhasesTrees(ampStructure, root).transitionFrom(
    AMPModifiers.default,
    start,
  );

  createAmpEndPhaseTree(ampStructure, root).transitionFrom(
    AMPModifiers.default,
    blocks,
  );
  return new DefaultTestTree(AMPModifiers, root);
}

function createAmpStartPhaseTree(
  ampStructure: AmpStructure,
  root: MapPhaseTreeNode,
) {
  const start = new MapPhaseTreeNode('start', root).connectParent(
    AMPModifiers.default,
  );

  new MapScreenTreeNode(
    'welcome',
    start,
    new AmpInstructionsData({
      content: ampStructure.welcomePhase.content.content,
      nextButton: ampStructure.welcomePhase.content.nextButton,
    }),
  )
    .connectParent(AMPModifiers.default)
    .controlRequest(
      OptionsStereotypes.Confirm.UI.defineTransition(undefined, () =>
        DefaultTransitionAction.next(AMPModifiers.default),
      ),
    );
  return start;
}

function createAmpEndPhaseTree(
  ampStructure: AmpStructure,
  root: MapPhaseTreeNode,
) {
  return new MapScreenTreeNode(
    'end-screen',
    root,
    new AmpInstructionsData({
      content: ampStructure.endPhase.content.content,
      finish: true,
      nextButton: ampStructure.endPhase.content.nextButton,
    }),
  )
    .controlRequest(
      OptionsStereotypes.Confirm.UI.defineTransition(undefined, () =>
        DefaultTransitionAction.next(AMPModifiers.default),
      ),
    )
    .connectParent();
}
