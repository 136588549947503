import { AsyncThunk, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import iatReducer from '../../features/tests/IAT/redux/iat.slice';
import ampReducer from '../../features/tests/AMP/redux/amp.slice';
import podtReducer from '../../features/tests/PODT/redux/podt.slice';
import podtaReducer from '../../features/tests/PODTA/redux/podta.slice';
import wswReducer from '../../features/tests/WSW/redux/wsw.slice';
import { mediaApi } from '../../features/media/redux/mediaApi';
import { TestType } from '../../features/subject/types';
import { surveyProviderApi } from '../../features/survey-providers/surveyProviderApi';
import { filesApi } from '../../features/files/filesApi';
import { resultsApi } from '../../features/results/resultsApi';
import { userRegistrationApi } from '../../features/users/registrationsApi';

const store = configureStore({
  reducer: {
    [mediaApi.reducerPath]: mediaApi.reducer,
    [surveyProviderApi.reducerPath]: surveyProviderApi.reducer,
    [userRegistrationApi.reducerPath]: userRegistrationApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [resultsApi.reducerPath]: resultsApi.reducer,
    [TestType.IAT]: iatReducer,
    [TestType.AMP]: ampReducer,
    [TestType.PODT]: podtReducer,
    [TestType.PODTA]: podtaReducer,
    [TestType.WSW]: wswReducer,
    // mocking reducer for example test
    [TestType.Example]: iatReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      filesApi.middleware,
      mediaApi.middleware,
      resultsApi.middleware,
      surveyProviderApi.middleware,
      userRegistrationApi.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export type AsyncAppThunk<Returned, ThunkArg = void> = AsyncThunk<
  Returned,
  ThunkArg,
  { state: RootState }
>;

export default store;
