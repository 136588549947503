import React, { FC } from 'react';
import { useUpdateSurveyProviderMutation } from './surveyProviderApi';
import { UpsertSurveyProviderDialog } from './UpsertSurveyProviderDialog';
import { Edit } from '@mui/icons-material';
import { SurveyProvider } from '../../API';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { omit } from 'lodash';

function omitModelFields<T extends Record<string, unknown>>(value: T) {
  return omit(value, 'createdAt', 'updatedAt', '__typename', 'owner') as Omit<
    T,
    'createdAt' | 'updatedAt' | '__typename' | 'owner'
  >;
}

export const UpdateSurveyProviderDialog: FC<{
  onSuccess: () => void;
  surveyProvider: SurveyProvider;
}> = ({ onSuccess, surveyProvider }) => {
  const [updateSurveyProvider, { isLoading }] =
    useUpdateSurveyProviderMutation();
  return (
    <UpsertSurveyProviderDialog
      initialValues={{
        ...omitModelFields(surveyProvider),
        snippetConfiguration: omitModelFields(
          surveyProvider.snippetConfiguration,
        ),
        setupDocumentation: omitModelFields({
          ...surveyProvider.setupDocumentation,
          steps: surveyProvider.setupDocumentation.steps.map((step) =>
            omitModelFields({
              ...step,
              image: step.image ? omitModelFields(step.image) : null,
            }),
          ),
        }),
      }}
      button={(onClick) => (
        <GridActionsCellItem
          placeholder=""
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          showInMenu={false}
          icon={<Edit />}
          label="Edit"
          disabled={isLoading}
          onClick={onClick}
        />
      )}
      title="Update Survey Provider"
      actionLabel="Update Survey Provider"
      isLoading={isLoading}
      onSave={(input) =>
        updateSurveyProvider({
          input: {
            ...input,
            id: surveyProvider.id,
          },
        })
          .unwrap()
          .then((res) => {
            onSuccess();
            return res;
          })
      }
    />
  );
};
