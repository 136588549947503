interface VariableDefinition {
  name: string;
  description: string;
}

function escapeRegExp(text: string) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export class EditorVariables<N extends string> {
  constructor(public definitions: Record<N, VariableDefinition>) {
    Object.values(definitions).forEach((v) => {
      if (!/^[A-Za-z0-9_]+$/.test((v as VariableDefinition).name)) {
        throw new Error(
          'Invalid variable name: ' + (v as VariableDefinition).name,
        );
      }
    });
  }

  replace(replaceConfig: Record<N, string>, text: string): string {
    const inverseMap: Record<string, N> = (
      Object.entries(this.definitions) as [N, VariableDefinition][]
    ).reduce(
      (acc, [key, value]) => ({ ...acc, [value.name]: key }),
      {} as Record<string, N>,
    );
    const replaceFunction = (m: string) => {
      const matched = m.replace(/#/g, '');
      if (inverseMap[matched]) {
        return replaceConfig[inverseMap[matched]];
      }
      throw Error('Variable not Defined! Cannot Replace ' + matched);
    };
    const regex = new RegExp(
      `${Object.keys(inverseMap)
        .map((k) => `(#(${escapeRegExp(k)})#)`)
        .join('|')}`,
      'g',
    );
    return text.replace(regex, replaceFunction);
  }

  defineInstance(replaceConfig: Record<N, string>): EditorReplaceInstance<N> {
    return new EditorReplaceInstance(this, replaceConfig);
  }
}

class EditorReplaceInstance<N extends string> {
  constructor(
    private editorVariables: EditorVariables<N>,
    private replaceConfig: Record<N, string>,
  ) {}

  replace(text: string): string {
    return this.editorVariables.replace(this.replaceConfig, text);
  }
}

export const IatVariables = new EditorVariables({
  attributeLeft: {
    name: 'ATTRIBUTE_LEFT',
    description: 'Attribute Left',
  },
  attributeRight: {
    name: 'ATTRIBUTE_RIGHT',
    description: 'Attribute Right',
  },
  targetLeft: {
    name: 'TARGET_LEFT',
    description: 'Target Left',
  },
  targetRight: {
    name: 'TARGET_RIGHT',
    description: 'Target Right',
  },
});

export const AmpVariables = new EditorVariables({
  attributeLeft: {
    name: 'ATTRIBUTE_LEFT',
    description: 'Attribute Left',
  },
  attributeRight: {
    name: 'ATTRIBUTE_RIGHT',
    description: 'Attribute Right',
  },
});

export const PodtVariables = new EditorVariables({
  weaponButton: {
    name: 'WEAPON_BUTTON',
    description: 'button for weapon',
  },
  objectButton: {
    name: 'OBJECT_BUTTON',
    description: 'button for object',
  },
});

export const PodtaVariables = new EditorVariables({
  weaponButton: {
    name: 'WEAPON_BUTTON',
    description: 'button for weapon',
  },
  objectButton: {
    name: 'OBJECT_BUTTON',
    description: 'button for object',
  },
});

export const WswVariables = new EditorVariables({
  group1: {
    name: 'GROUP_1',
    description:
      'Replaced with label indicator of first group (or error message if not existing)',
  },
  group2: {
    name: 'GROUP_2',
    description:
      'Replaced with label indicator of second group (or error message if not existing)',
  },
  group3: {
    name: 'GROUP_3',
    description:
      'Replaced with label indicator of third group (or error message if not existing)',
  },
  group4: {
    name: 'GROUP_4',
    description:
      'Replaced with label indicator of fourth group (or error message if not existing)',
  },
});
