import React, { FC } from 'react';
import { TestType } from '../../subject/types';
import InitialIatCreatorState, { iatTemplates } from './creator/initialState';
import IatGameSettings, {
  IatGameSettingsSchema,
} from './creator/steps/IatGameSettings';
import IatBlockSettings, {
  IatBlockSettingsSchema,
} from './creator/steps/IatBlockSettings';
import IatStimuliSettings, {
  IatStimuliSettingsSchema,
} from './creator/steps/IatStimuliSettings';
import {
  createIatAsync,
  deleteIatAsync,
  deleteIatResultsAsync,
  fetchIatResults,
  fetchIatTest,
  fetchIatTests,
  updateIatAsync,
  updateIatStatusAsync,
} from './redux/iat.thunk';
import TestAdmin from '../testAdmin/TestAdmin';
import { IatGameSettingsView } from './view/steps/IatGameSettingsView';
import { IatBlockSettingsView } from './view/steps/IatBlockSettingsView';
import { IatStimuliSettingsView } from './view/steps/IatStimuliSettingsView';
import { Iat } from './types';
import { RequireAuth } from '../../../auth/RequiredAuth';

const IatAdmin: FC = () => {
  return (
    <RequireAuth>
      <TestAdmin
        type={TestType.IAT}
        templates={iatTemplates}
        thunks={{
          loadTest: fetchIatTest,
          loadTests: fetchIatTests,
          createTest: createIatAsync,
          updateTest: updateIatAsync,
          updateTestStatus: updateIatStatusAsync,
          deleteTest: deleteIatAsync,
        }}
        results={{
          load: fetchIatResults,
          deleteItems: deleteIatResultsAsync,
        }}
        steps={[
          {
            name: 'Test',
            editor: {
              component: IatGameSettings,
              validationSchema: IatGameSettingsSchema,
            },
            view: ({ test }) => <IatGameSettingsView test={test as Iat} />,
          },
          {
            name: 'Block',
            editor: {
              component: IatBlockSettings,
              validationSchema: IatBlockSettingsSchema,
            },
            view: ({ test }) => <IatBlockSettingsView test={test as Iat} />,
          },
          {
            name: 'Stimuli',
            editor: {
              component: IatStimuliSettings,
              validationSchema: IatStimuliSettingsSchema,
            },

            view: ({ test }) => <IatStimuliSettingsView test={test as Iat} />,
          },
        ]}
        editor={{
          initialState: InitialIatCreatorState,
        }}
      />
    </RequireAuth>
  );
};

export default IatAdmin;
