import { Paper } from '@mui/material';
import React from 'react';
import { RequireAuth } from '../../auth/RequiredAuth';
import { BroadcastMailHistoryTable } from './BroadcastMailHistoryTable';

export function BroadcastMailScreen() {
  return (
    <RequireAuth>
      <Paper sx={{ minHeight: 600, padding: '1.5em' }}>
        <BroadcastMailHistoryTable />
      </Paper>
    </RequireAuth>
  );
}
