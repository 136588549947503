import { ViewBoxes } from '../../../subject/testRunner/screens/layouting-view';
import { StimulusCanvasStrategy } from '../../../subject/testRunner/screens/composer/scaler/canvas-strategy';
import React from 'react';
import { TestInstructionsView } from '../../../subject/testRunner/screens/basic-views/test-instructions-view';
import { OptionsStereotypes } from '../../../subject/testRunner/controls/control-stereotypes';
import { Optional } from '../../../subject/testRunner/screens/selectors/data-wrapper';
import { StateHolder } from '../../../subject/testRunner/graph-state/state-holder';
import { PODTSelectorTree } from './podt-selectors';
import { PodtStructure } from '../loader/structure/podt-structure';
import { createOptionTipsContainerViewWrapper } from '../../../subject/testRunner/screens/basic-views/option-test-views';
import { PodtResultComponentFactory } from './views/podt-result-component';
import { LazyCanvasContentView } from '../../../subject/testRunner/screens/composer/views/lazy-view';
import { LazyStimulusCanvasStrategy } from '../../../subject/testRunner/screens/composer/scaler/lazy-canvas-strategies';
import {
  PODTBlockPointsSlice,
  PODTReactionSlice,
} from '../loader/tree/podt-tree-state';
import { TestScreenComposer } from '../../../subject/testRunner/screens/composer/test-screen-composer';
import { ViewDefinitionCollection } from '../../../subject/testRunner/screens/composer/view-definition-collection';
import { HTMLStateView } from '../../../subject/testRunner/screens/composer/views/html-state-view';
import { HTMLContentView } from '../../../subject/testRunner/screens/composer/views/html-content-view';
import { CanvasContentView } from '../../../subject/testRunner/screens/composer/views/canvas-content-view';
import { LayoutingView } from '../../../subject/testRunner/screens/composer/views/layouting-view';

function createOptionsContentView(structure: PodtStructure) {
  const PodtResultComponent = PodtResultComponentFactory(structure);
  return LayoutingView.growing({
    selector: PODTSelectorTree.rootSelector.options,
    layout: {
      subComponents: ViewDefinitionCollection.Create({
        stimulus: CanvasContentView.growing(
          PODTSelectorTree.rootSelector.options.stimulus,
          new StimulusCanvasStrategy(),
        ),
        lazyStimulus: LazyCanvasContentView.growing(
          PODTSelectorTree.rootSelector.options.lazyStimulus,
          new LazyStimulusCanvasStrategy(),
        ),
        result: structure.accessor.modelData.trialFeedback
          ? HTMLStateView.growing(
              PODTSelectorTree.rootSelector.options.result,
              PodtResultComponent,
              {
                selectState: (holder: StateHolder) => {
                  return Optional.value({
                    points: holder.get(PODTBlockPointsSlice).data.points,
                    text: holder.get(PODTReactionSlice).data.type,
                  });
                },
              },
            )
          : HTMLContentView.growing(
              PODTSelectorTree.rootSelector.options.result,
              ({ value, containerRef }) => {
                return <div ref={containerRef} />;
              },
            ),
        instructions: TestInstructionsView({
          selector: PODTSelectorTree.rootSelector.options.instructions,
        }),
        instructionsWithButton: TestInstructionsView({
          selector:
            PODTSelectorTree.rootSelector.options.instructionsWithButton,
          control:
            structure.accessor.testContext.deviceInfo.mode === 'mobile'
              ? (OptionsStereotypes.Confirm.Control as any)
              : undefined,
          delay:
            structure.accessor.testContext.deviceInfo.mode === 'mobile'
              ? 3
              : undefined,
        }),
      }),
      boxes: ViewBoxes.Define(
        (builder) =>
          ({
            content: builder.growing(
              'stimulus',
              'result',
              'instructions',
              'instructionsWithButton',
              'lazyStimulus',
            ),
          }) as const,
      ),
    } as const,
    contentComponent: (containerProvider, boxProvider) => {
      return () => {
        return containerProvider.createContainer(
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ width: '100%', flexGrow: 1 }}>
              {boxProvider.createBox('content')}
            </div>
          </div>,
        );
      };
    },
  });
}

export function createPodtViewMap(structure: PodtStructure) {
  return TestScreenComposer.Create(
    LayoutingView.growing({
      selector: PODTSelectorTree.rootSelector,
      layout: {
        subComponents: ViewDefinitionCollection.Create({
          instructions: LayoutingView.growing({
            selector: PODTSelectorTree.rootSelector.instructions,
            layout: {
              subComponents: ViewDefinitionCollection.Create({
                content: TestInstructionsView({
                  selector: PODTSelectorTree.rootSelector.instructions,
                  control: OptionsStereotypes.Confirm.UI,
                }),
              }),
              boxes: ViewBoxes.Define((builder) => ({
                content: builder.growing('content'),
              })),
            },
            contentComponent: (containerProvider, boxProvider) => {
              return () => {
                return containerProvider.createContainer(
                  <div
                    style={{
                      width:
                        structure.accessor.testContext.deviceInfo.mode ===
                        'mobile'
                          ? '100%'
                          : structure.accessor.modelData.style.maxWidth + '%',
                      height: '100%',
                      backgroundColor:
                        structure.accessor.modelData.style.backgroundColour ??
                        'white',
                    }}
                  >
                    {boxProvider.createBox('content')}
                  </div>,
                  (_, style) => ({
                    ...style,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor:
                      structure.accessor.modelData.style.backgroundColour ??
                      'white',
                  }),
                );
              };
            },
          }),
          options: createOptionTipsContainerViewWrapper(
            structure.accessor.testContext,
            structure.config.options,
            PODTSelectorTree.rootSelector.options,
            () => createOptionsContentView(structure),
          ),
        }),
        boxes: ViewBoxes.Define((builder) => ({
          content: builder.growing('instructions', 'options'),
        })),
      } as const,
      contentComponent: (containerProvider, boxProvider) => {
        return () => {
          return containerProvider.createContainer(
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor:
                  structure.accessor.modelData.style.backgroundColour ??
                  'white',
              }}
            >
              {boxProvider.createBox('content')}
            </div>,
            (_, style) => ({
              ...style,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
                structure.accessor.modelData.style.backgroundColour ?? 'white',
            }),
          );
        };
      },
    }),
  );
}
