import React, { useState } from 'react';
import { GridRowId, GridSelectionModel } from '@mui/x-data-grid-pro';
import _ from 'lodash';
import { MediaItem, Tag } from '../types';
import { useCreateMediaItemsMutation } from '../redux/mediaApi';

export interface ImageState {
  id: string;
  localUrl: string;
  image: File;
  tags: Tag[];
  source: string;
}

interface FilesMap {
  [key: string]: ImageState;
}

const transformFilesToFilesMap = (files: File[], source: string): FilesMap =>
  files.reduce((newFilesMap, file) => {
    if (!newFilesMap[file.name]) {
      newFilesMap[file.name] = {
        id: file.name,
        localUrl: URL.createObjectURL(file),
        image: file,
        tags: [],
        source,
      };
    }
    return newFilesMap;
  }, {} as FilesMap);

export default function useCreateMediaItem() {
  const [createMediaItems, { isLoading: isCreateLoading }] =
    useCreateMediaItemsMutation();
  const [selectedImagesIds, setSelectedImagesIds] =
    React.useState<GridSelectionModel>([]);
  const [filesMap, setFilesMap] = useState<FilesMap>({});

  const [source, setSource] = React.useState('');

  const removeTagFromImage = (imageId: GridRowId, tagIdToRemove: string) =>
    setFilesMap((prev) => ({
      ...prev,
      [imageId]: {
        ...prev[imageId],
        tags: prev[imageId].tags.filter((t) => t.id !== tagIdToRemove),
      },
    }));

  const applyTagsToAll = (tags: Tag[]) =>
    setFilesMap(
      Object.entries(filesMap).reduce((newFilesMap, [fileName, imageState]) => {
        if (selectedImagesIds.includes(fileName)) {
          newFilesMap[fileName] = {
            ...imageState,
            tags: _.union(imageState.tags, tags),
          };
        } else {
          newFilesMap[fileName] = {
            ...imageState,
          };
        }

        return newFilesMap;
      }, {} as FilesMap),
    );

  const applyTagToAll = (tag: Tag) => applyTagsToAll([tag]);

  const applySourceAll = () =>
    setFilesMap(
      Object.entries(filesMap).reduce((newFilesMap, [fileName, imageState]) => {
        if (selectedImagesIds.includes(fileName)) {
          newFilesMap[fileName] = {
            ...imageState,
            source,
          };
        } else {
          newFilesMap[fileName] = { ...imageState };
        }
        return newFilesMap;
      }, {} as FilesMap),
    );

  const resetDropZone = () => {
    setFilesMap({});
  };

  const uploadCurrentFiles = async (): Promise<MediaItem[]> => {
    const input = Object.values(filesMap).map((v) => {
      return {
        file: v.image,
        tags: v.tags,
        source: v.source,
      };
    });
    const result = await createMediaItems(input).unwrap();
    resetDropZone();
    return result;
  };

  return {
    loading: isCreateLoading,
    resetDropZone,
    currentFiles: Object.values(filesMap),
    updateCurrentFiles: (files: File[]) => {
      setFilesMap(transformFilesToFilesMap(files, source));
      setSelectedImagesIds(files.map((f) => f.name));
    },
    uploadCurrentFiles,
    applyTagsToAll,
    applyTagToAll,
    removeTagFromImage,
    setSelectedImagesIds,
    selectedImagesIds,
    source,
    setSource,
    applySourceAll,
  };
}
