import React, { FC } from 'react';
import { GroupConstructionInputComponent } from '../../components/groupConstruction/GroupConstructionInputComponent';
import { Box, Button, Collapse, Grid } from '@mui/material';
import { EditorStepTitle } from '../../../../../components/grid';
import InfoMessage from '../../../../../components/InfoMessage';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

const FormattedMsg: FC = () => {
  return (
    <div>
      Groups: Manipulated and/or pre-defined groups for categorization of
      targets (and potentially respondents), e.g., minimal groups or artificial
      groups. The category of the target will be presented to each respondent in
      the learning and the test phase. Target group membership will be indicated
      by a colored line framing the image of the respective stimulus in each
      trial. Respondent group membership will be indicated by a colored and
      labeled flag in the upper right corner of the screen. Below you can define
      the frequency of between-conditions that define when respondents will be
      categorized as group members.
      <br />
      You can choose whether and when the respondent will be randomly assigned
      to one of the manipulated groups:
      <ul>
        <li>
          <b>condition 1:</b> not at all
        </li>
        <li>
          <b>condition 2:</b> before learning phase
        </li>
        <li>
          <b>condition 3:</b> after learning phase
        </li>
      </ul>
      If more than one condition is greater than 0, then the conditions are
      between-respondents.
      <br />
      If only one condition is chosen (and others are 0), there is no between
      respondent condition. That means each respondent will receive the same
      instructions on the group categorization.
      <br />
      For example, if the condition “no group assignment” = 1, and the
      conditions “group assignment before learning phase” = 0, and the condition
      “group assignment after learning phase” = 0 then all respondents are in
      the condition “no group assignment” and none of them will be assigned a
      member of one of the manipulated groups respectively.
    </div>
  );
};
export const WswGroupSettingsStep: FC = () => {
  const [open, setOpen] = React.useState(false);

  const handleChange = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <Box>
      <EditorStepTitle prefix={2} title="Groups" />
      <Box bgcolor={'#fdfdfd'} border={'solid lightgrey'} borderRadius={1}>
        <Collapse in={open} collapsedSize={40}>
          <InfoMessage message={<FormattedMsg />} />
        </Collapse>
        <Grid container justifyContent={'center'}>
          <Button onClick={handleChange} fullWidth>
            {open ? (
              <ExpandLess style={{ color: grey[700] }} />
            ) : (
              <ExpandMore style={{ color: grey[700] }} />
            )}
          </Button>
        </Grid>
      </Box>
      <GroupConstructionInputComponent fieldName="groupConstruction" />
    </Box>
  );
};
