import React, { PropsWithChildren } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Popover, Stack, Typography } from '@mui/material';
import { FormatSize } from '@mui/icons-material';
import StyledIconButton from './input/StyledIconButton';
import SliderWithNumberInput from './input/SliderWithNumberInput';
import ColorInput from './input/ColorInput';
import StyledSelect from './input/StyledSelect';
import TextAlignmentButtonGroup from './input/TextAlignmentButtonGroup';
import InfoMessage from './InfoMessage';
import { FontFaceFamilyMap } from './fontUtils';

type TextStylePopoverProps = {
  label?: string;
  name: string;
  withAlignment?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    padding: theme.spacing(2),
  },
}));

export default function TextStyleDialog({
  name,
  children,
  withAlignment,
  label,
}: PropsWithChildren<TextStylePopoverProps>) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? 'text-style-popover' : undefined;

  return (
    <div>
      <StyledIconButton label={label ?? 'Text-Styles'} onClick={handleClick}>
        {children}
      </StyledIconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container spacing={3} className={classes.popover}>
          <Grid item xs={12}>
            <ColorInput name={name + '.color'} label={'Font Color'} />
          </Grid>
          <Grid item xs={12}>
            <StyledSelect name={name + '.fontFace'} label={'Font'}>
              {Object.entries(FontFaceFamilyMap).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </StyledSelect>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={4}>
              <SliderWithNumberInput
                label={'Font Size'}
                name={name + '.fontSize'}
                icon={<FormatSize />}
              />
              <InfoMessage
                message={
                  <Typography>
                    Varies the size and font of text used as stimuli/ attributes
                    <br />
                    (text for questions and instructions cannot be adapted
                    because they are fit to screen sizes)
                  </Typography>
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {withAlignment && (
              <TextAlignmentButtonGroup name={name + '.alignment'} />
            )}
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
}
