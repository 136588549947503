import React, { FC } from 'react';
import { ImageInstanceInput, PodtScenesInput } from '../../../../../../API';
import S3Image from '../../../../../../components/S3Image';
import MediaOffscreenCanvas from '../../../../../subject/MediaOffscreenCanvas';
import { SceneSettingsState } from './PodtSceneSettings';
import { List, ListItem } from '@mui/material';
import SceneBoxEditor from '../../SceneBoxEditor';

interface EditableSceneListProps {
  scenes: PodtScenesInput['scenes'];
  scenesCache: { [id: string]: MediaOffscreenCanvas };
  setBoxes: (id: string) => (boxes: SceneSettingsState['boxes']) => void;
  staticHeight: { isLocked: boolean; value: number };
}

export const SceneSelection: FC<EditableSceneListProps> = ({
  scenes,
  scenesCache,
  staticHeight,
  setBoxes,
}) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  const currentScene = scenes[activeIndex];

  const podtSceneInput = scenes[activeIndex];
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <List style={{ paddingTop: 0, height: 450, overflow: 'auto' }} dense>
        {scenes.map((scene, sceneIndex) => (
          <ListItem
            dense
            button
            onClick={() => setActiveIndex(sceneIndex)}
            selected={sceneIndex === activeIndex}
          >
            <S3Image
              fullWidth
              imgInstance={
                scene.mediaItem.image?.thumbNail as ImageInstanceInput
              }
            />
          </ListItem>
        ))}
      </List>
      <div style={{ marginLeft: 24 }}>
        {currentScene.mediaItem.id && (
          <SceneBoxEditor
            key={`sceneBoxEditor${activeIndex}`}
            withMenu
            active={true}
            state={podtSceneInput}
            offscreenCanvas={scenesCache[podtSceneInput.mediaItem.id ?? '']}
            setBoxes={setBoxes(currentScene.mediaItem.id)}
            isLockedHeight={staticHeight.isLocked}
          />
        )}
      </div>
    </div>
  );
};
