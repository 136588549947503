import React, { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { TextDisplay } from '../../../../../components/TextDisplay';
import { BorderBox } from '../../../../../components/BorderBox';
import { Amp } from '../../types';
import { translationInfoMap } from '../../../../subject/testRunner/state/baseTestContext/app-configuration/translations/translation-map';

export const AmpGameSettingsView: FC<{ test: Amp }> = ({ test }) => {
  return (
    <div>
      <Stack spacing={3}>
        <Stack spacing={5} direction="row">
          <TextDisplay
            label="Language"
            value={
              test.language
                ? translationInfoMap[test.language].languageName
                : '-'
            }
            suffix=""
          />
          <TextDisplay
            label="Attributes-Position"
            value={test.style.optionsPosition}
          />
          <TextDisplay label="Width" value={test.style.maxWidth} suffix="%" />
          <TextDisplay
            label="Background"
            value={test.style.backgroundColour}
            suffix={
              <Box
                sx={{
                  borderRadius: '50%',
                  border: 1,
                  height: 24,
                  width: 24,
                  bgcolor: test.style.backgroundColour,
                }}
              />
            }
          />
        </Stack>
        <BorderBox label="Keyboard">
          <Stack direction="row" spacing={3}>
            <TextDisplay label="Left Button" value={test.keyboard.left} />

            <TextDisplay label="Right Button" value={test.keyboard.right} />

            <TextDisplay label="Start Button" value={test.keyboard.confirm} />
          </Stack>
        </BorderBox>
        <BorderBox label="Welcome screen">
          <div
            dangerouslySetInnerHTML={{ __html: test.instructions.desktop }}
          />
        </BorderBox>
        <BorderBox label="End screen">
          <div
            dangerouslySetInnerHTML={
              test.endScreen?.desktop
                ? { __html: test.endScreen.desktop }
                : undefined
            }
          />
        </BorderBox>
      </Stack>
    </div>
  );
};
