import {
  ImageMediaData,
  MediaInstance,
  TextMediaData,
} from '../../../../subject/testRunner/media/MediaData';
import {
  CreateWswResultMutationVariables,
  StimulusType,
  WswGroupIndicationResultInput,
} from '../../../../../API';
import {
  WswConstructedGroup,
  WswGroupCondition,
  WswStructure,
} from '../structure/wsw-structure';
import { QuestionAnswerEvent } from '../wsw-controls';
import { WswResultQueries } from '../loading/wsw.queries';
import { omit } from 'lodash';
import { ControlOccurrenceProcessor } from '../../../../subject/testRunner/graph-state/graph-action';
import { v4 as uuid } from 'uuid';
import { QuestionAnswer } from '../model/questions/question-answer';

export function determineStimulusType(stimulus: MediaInstance | null) {
  if (stimulus === null) {
    return StimulusType.Empty;
  }
  if (stimulus.data instanceof TextMediaData) {
    return StimulusType.Text;
  }
  if (stimulus.data instanceof ImageMediaData) {
    return StimulusType.Image;
  }
  throw new Error('Unknown stimuli type');
}

export function createGroupIndicationResult(
  group: WswConstructedGroup,
  show: boolean,
): WswGroupIndicationResultInput {
  return {
    groupIndicatorShow: show,
    groupIndicationCategory: group.group.name!,
    groupIndicationIndex: group.index,
    groupIndicationColor: group.color,
  };
}

export const groupConditionMap: Record<
  WswGroupCondition['type'] | '',
  number | null
> = {
  '': null,
  'after-learning': 3,
  'before-learning': 2,
  never: 1,
};

export function createWSWResultQuery(
  structure: WswStructure,
  control: ControlOccurrenceProcessor<QuestionAnswerEvent>,
  params: Omit<
    CreateWswResultMutationVariables['input'],
    | 'testId'
    | 'status'
    | 'device'
    | 'survey'
    | 'timing'
    | 'groupCondition'
    | 'clientId'
    | keyof QuestionAnswer
  > &
    Partial<QuestionAnswer>,
) {
  return WswResultQueries.Create.create({
    input: {
      testId: structure.accessor.modelData.id,
      clientId: uuid(),
      status: structure.accessor.modelData.status,
      device: omit(structure.accessor.testContext.deviceInfo, 'mode'),
      survey: structure.accessor.testContext.logic.resultEntry,
      timing: control.reactionTimeProcessor.toResultTiming(),
      ...control.occurrence.trigger.value.answer,
      groupCondition:
        groupConditionMap[structure.config.groups?.groupCondition?.type ?? ''],
      ...params,
    },
  });
}
