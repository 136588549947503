import { scaleAndInitializeRunner } from '../utils/runner-mocks/scale-mock-test-ui';
import React from 'react';
import { IatScreenData } from '../../features/tests/IAT/loader/view-data/iat-screen-data';
import { RenderDataType } from '../../features/subject/testRunner/screens/selectors/data-wrapper';
import { uniq } from 'lodash';
import { IatOptionsData } from '../../features/tests/IAT/loader/view-data/iat-options-data';
import { StimulusContent } from '../../features/subject/testRunner/screens/basic-views/stimulus-content';
import { DrawableTextMediaInstance } from '../../features/subject/testRunner/media/MediaData';
import { Stack, Typography } from '@mui/material';
import { ViewInstance } from '../../features/subject/testRunner/screens/composer/screen-instance/view-instance';
import { PodtScreenData } from '../../features/tests/PODT/loader/view-data/podt-screen-data';
import { PodtOptionsData } from '../../features/tests/PODT/loader/view-data/podt-options-data';

class MockViewInstance extends ViewInstance {
  constructor(instance: ViewInstance) {
    super(
      instance.viewScaler,
      (instance as MockViewInstance).nodeDataMap,
      (instance as MockViewInstance).contentFactory,
    );
  }

  static getNodeDataMap(instance: ViewInstance) {
    return (instance as MockViewInstance).nodeDataMap;
  }
}

export function LoadedTestInspector({
  loadedTest,
}: {
  loadedTest: Awaited<ReturnType<typeof scaleAndInitializeRunner>>;
}) {
  const nodeMap = React.useMemo(
    () =>
      MockViewInstance.getNodeDataMap(
        loadedTest.screenController.mainViewController.viewInstance,
      ),
    [loadedTest.screenController.mainViewController.viewInstance],
  );
  const groupedStimuli = React.useMemo(() => {
    const nodeMapMap = (
      nodeMap as unknown as { map: Map<IatScreenData, RenderDataType<any>> }
    ).map;
    const stimuliTexts = uniq(
      Array.from(nodeMapMap.keys())
        .filter(
          (k) =>
            'screenData' in k &&
            k.screenData instanceof IatOptionsData &&
            k.screenData.content instanceof StimulusContent,
        )
        .map(
          (k) =>
            (
              ((k as any).screenData as IatOptionsData)
                .content as StimulusContent
            ).stimulus,
        )
        .filter((c) => c instanceof DrawableTextMediaInstance),
    );
    console.log(
      Array.from(nodeMapMap.keys()).filter(
        (k) =>
          'screenData' in k &&
          k.screenData instanceof IatOptionsData &&
          k.screenData.content instanceof StimulusContent,
      ),
    );
    const optionTexts = uniq(
      Array.from(nodeMapMap.keys())
        .filter(
          (k) => 'screenData' in k && k.screenData instanceof IatOptionsData,
        )
        .flatMap((k) =>
          [
            ((k as any).screenData as IatOptionsData).options.attribute?.left
              .mediaInstance,
            ((k as any).screenData as IatOptionsData).options.attribute?.right
              .mediaInstance,
            ((k as any).screenData as IatOptionsData).options.target?.left
              .mediaInstance,
            ((k as any).screenData as IatOptionsData).options.target?.right
              .mediaInstance,
          ].filter((c) => !!c),
        ),
    );
    return {
      stimuliTexts,
      optionTexts: optionTexts as DrawableTextMediaInstance[],
    };
  }, [nodeMap]);
  console.log(groupedStimuli.optionTexts);
  return (
    <div>
      <Typography variant="h3">Loaded Test</Typography>
      <Stack direction="row">
        {[...groupedStimuli.optionTexts, ...groupedStimuli.stimuliTexts].map(
          (stimulus) => (
            <div>
              <MediaItemCanvas
                mediaInstance={stimulus as DrawableTextMediaInstance}
              />
            </div>
          ),
        )}
      </Stack>
    </div>
  );
}
export function LoadedPodtaTestInspector({
  loadedTest,
}: {
  loadedTest: Awaited<ReturnType<typeof scaleAndInitializeRunner>>;
}) {
  const nodeMap = React.useMemo(
    () =>
      MockViewInstance.getNodeDataMap(
        loadedTest.screenController.mainViewController.viewInstance,
      ),
    [loadedTest.screenController.mainViewController.viewInstance],
  );
  const groupedStimuli = React.useMemo(() => {
    const nodeMapMap = (
      nodeMap as unknown as { map: Map<PodtScreenData, RenderDataType<any>> }
    ).map;
    const stimuliTexts = uniq(
      Array.from(nodeMapMap.keys())
        .filter(
          (k) =>
            'screenData' in k &&
            k.screenData instanceof PodtOptionsData &&
            k.screenData.content instanceof StimulusContent,
        )
        .map(
          (k) =>
            (
              ((k as any).screenData as PodtOptionsData)
                .content as StimulusContent
            ).stimulus,
        )
        .filter((c) => c instanceof DrawableTextMediaInstance),
    );
    console.log(
      Array.from(nodeMapMap.keys()).filter(
        (k) =>
          'screenData' in k &&
          k.screenData instanceof PodtOptionsData &&
          k.screenData.content instanceof StimulusContent,
      ),
    );
    const optionTexts = uniq(
      Array.from(nodeMapMap.keys())
        .filter(
          (k) => 'screenData' in k && k.screenData instanceof PodtOptionsData,
        )
        .flatMap((k) =>
          [
            ((k as any).screenData as PodtOptionsData).options.response?.left
              .mediaInstance,
            ((k as any).screenData as PodtOptionsData).options.response?.right
              .mediaInstance,
          ].filter((c) => !!c),
        ),
    );
    return {
      stimuliTexts,
      optionTexts: optionTexts as DrawableTextMediaInstance[],
    };
  }, [nodeMap]);
  console.log(groupedStimuli.optionTexts);
  return (
    <div>
      <Typography variant="h3">Loaded Test</Typography>
      <Stack direction="row">
        {[...groupedStimuli.optionTexts, ...groupedStimuli.stimuliTexts].map(
          (stimulus) => (
            <div>
              <MediaItemCanvas
                mediaInstance={stimulus as DrawableTextMediaInstance}
              />
            </div>
          ),
        )}
      </Stack>
    </div>
  );
}

function MediaItemCanvas({
  mediaInstance,
}: {
  mediaInstance: DrawableTextMediaInstance;
}) {
  return (
    <canvas
      ref={(c) => {
        c
          ?.getContext('2d')
          ?.drawImage(
            mediaInstance.drawable.imageSource,
            0,
            0,
            mediaInstance.drawable.dimension.width,
            mediaInstance.drawable.dimension.height,
          );
      }}
      width={mediaInstance.drawable.dimension.width}
      height={mediaInstance.drawable.dimension.height}
      style={{ border: '1px solid red' }}
    />
  );
}
