import React, { FC } from "react";
import { WSWTest } from "../../../../../API";
import { WswPresentationPhaseView } from "./WswPresentationPhaseView";

interface WswLearningPhaseViewProps {
  test: WSWTest;
}

export const WswLearningPhaseView: FC<WswLearningPhaseViewProps> = (props) => {
  return (
    <React.Fragment>
      {props.test.learningPhase &&
        <WswPresentationPhaseView
          phase={props.test.learningPhase}
          label={"Learningphase"}
        />}
    </React.Fragment>
  );
};
