import React, { FC } from 'react';
import {
  ConditionalAnswerType,
  ConditionalQuestionInput,
} from '../../../../../API';
import { Box, Collapse, Grid, Tooltip, Typography } from '@mui/material';
import StyledTextInput from '../../../../../components/input/StyledTextInput';
import StyledSelect from '../../../../../components/input/StyledSelect';
import { createEmptyConditionalQuestion } from '../../../testAdmin/testEditor/initialEditorStates';
import { useField } from 'formik';
import { TransitionGroup } from 'react-transition-group';
import { InfoOutlined } from '@mui/icons-material';
import { FormikNullableCheckbox } from '../../../../../components/input/FormikNullableCheckbox';

export const ConditionalQuestion: FC<{ fieldName: string }> = ({
  fieldName,
}) => {
  const [, { value: conditionalQuestion, error }, helpers] = useField<
    ConditionalQuestionInput | undefined | null
  >(fieldName);

  return (
    <Box sx={{ pl: 1 }}>
      <Typography
        color="textSecondary"
        sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
      >
        Conditional Question&nbsp;
        <FormikNullableCheckbox
          label={''}
          onChange={(checked) =>
            helpers.setValue(checked ? createEmptyConditionalQuestion() : null)
          }
          value={Boolean(conditionalQuestion)}
        />
        <Tooltip
          placement={'right'}
          title={
            'You can define an answer to the question that leads to further questions on the condition, which you define under “Show next question”, or to the next trial if the condition is not met (e.g., “Have you seen this person before?” yes /no)'
          }
        >
          <InfoOutlined
            sx={{
              color: 'warning.dark',
              bgcolor: 'white',
              position: 'relative',
              left: '-1em',
            }}
          />
        </Tooltip>
      </Typography>
      <Box sx={{ pl: 2 }}>
        {typeof error === 'string' && (
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        )}
        <TransitionGroup>
          {conditionalQuestion && (
            <Collapse>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <StyledTextInput
                    label="Question Text"
                    name={`${fieldName}.questionText`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledTextInput
                    label="Confirm Text"
                    name={`${fieldName}.confirmText`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledTextInput
                    label={'Decline Text'}
                    name={`${fieldName}.declineText`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledSelect
                    name={`${fieldName}.answerType`}
                    label="Show next question if"
                  >
                    <option value={ConditionalAnswerType.CONFIRM}>
                      {'Confirm'}
                    </option>
                    <option value={ConditionalAnswerType.DECLINE}>
                      {'Decline'}
                    </option>
                  </StyledSelect>
                </Grid>
              </Grid>
            </Collapse>
          )}
        </TransitionGroup>
      </Box>
    </Box>
  );
};
