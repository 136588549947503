import React, { useEffect } from 'react';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './auth.css';
import { useNavigate, useLocation } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';
import logo from '../features/tests/testAdmin/testList/navigation/logo.png';
import { Container, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  welcome: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    minHeight: '100vh',
    overflow: 'hidden',
  },
  loginMask: {
    margin: '0 auto',
    maxHeight: 440,
    [theme.breakpoints.down('lg')]: {
      maxHeight: 480,
    },
  },
}));

export function LoginScreen() {
  const classes = useStyles();
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();

  let from = location.state?.from?.pathname || '/';

  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  return (
    <Container
      sx={{
        pt: 8,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <img src={logo} alt="logo" />
      <Typography variant="h4" sx={{ my: 3 }}>
        Welcome to MIND.set
      </Typography>
      <div className={classes.loginMask}>
        <Authenticator loginMechanisms={['email']} hideSignUp={true} />
      </div>
    </Container>
  );
}
