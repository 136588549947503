import { DataSelectorBuilder } from '../../../data-selector-builder';
import { Iat } from '../../types';

/**
 * Collects all existing MediaItemSnapshots in an IAT Test Model
 * */
export const IatMediaItemSelector = DataSelectorBuilder.Create<Iat>()
  .select('attributeCategories', 'targetCategories')
  .select('categoryA', 'categoryB')
  .selectFlat('label', 'practiceStimuliPool', 'testStimuliPool')
  .selectExisting();
