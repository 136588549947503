import {
  CreateTestResourceLoader,
  GQLTestQueryLoader,
} from '../../../../subject/testRunner/media/test-resource-loader';
import {
  ConditionalQuestionSet,
  MediaItemSnapshot,
  WswStimulusAttributeVariant,
} from '../../../../../API';
import { WswQueries } from './wsw.queries';

export const WSWResourceLoader = CreateTestResourceLoader(
  GQLTestQueryLoader(WswQueries.Get),
  (test, loadingManager) => {
    const mainMediaItems =
      test.mainAttribute.stimuliVariant === WswStimulusAttributeVariant.Image
        ? test.mainAttribute.categories?.flatMap((c) => c.mediaSnaps) ?? []
        : [];
    const additionalMediaItems =
      test.additionalAttribute?.stimuliVariant ===
      WswStimulusAttributeVariant.Image
        ? test.additionalAttribute.categories?.flatMap((c) => c.mediaSnaps) ??
          []
        : [];
    const criteriaMediaItems =
      test.criteriaAttribute.stimuliVariant ===
      WswStimulusAttributeVariant.Image
        ? test.criteriaAttribute.categories?.flatMap((c) => c.mediaSnaps) ?? []
        : [];
    const groupFakeQuestionItems = extractQuestionMediaItemsFromSet(
      test.groupConstruction?.fakeQuestions,
    );
    const testPhaseQuestionItems = extractQuestionMediaItemsFromSet(
      test.testPhase.questionsSet,
    );

    const groupManipulationQuestionItems =
      test.groupConstruction?.groups?.flatMap((g) =>
        extractQuestionMediaItemsFromSet(g.questionSet),
      ) ?? [];
    const distractionItems = extractQuestionMediaItemsFromSet(
      test.distractionPhase?.questionsSet,
    );

    loadingManager.attachItems(
      ...([
        ...mainMediaItems,
        ...additionalMediaItems,
        ...criteriaMediaItems,
        ...groupFakeQuestionItems,
        ...groupManipulationQuestionItems,
        ...distractionItems,
        ...testPhaseQuestionItems,
      ] as MediaItemSnapshot[]),
    );
    return loadingManager.loadCache();
  },
);

function extractQuestionMediaItemsFromSet(
  set?: ConditionalQuestionSet | null | undefined,
): MediaItemSnapshot[] {
  return [
    ...((set?.questionsAfter
      ?.flatMap((v) => [
        v.mediaItemSnapshot,
        ...(v.imageMultipleChoice?.options ?? []),
      ])
      .filter((v) => !!v) as MediaItemSnapshot[]) ?? []),
    ...((set?.questionsBefore
      ?.flatMap((v) => [
        v.mediaItemSnapshot,
        ...(v.imageMultipleChoice?.options ?? []),
      ])
      .filter((v) => !!v) as MediaItemSnapshot[]) ?? []),
  ];
}
