import {
  ConditionalAnswerType,
  ConditionalQuestionSet,
  QuestionType,
  TestQuestion,
  WswQuestionType,
} from '../../../../../../API';
import { MindSetRandomUtils } from '../../../../pickingSet/picking-utils';
import { TestBaseContext } from '../../../../../subject/testRunner/state/baseTestContext/context/ContextTypes';
import { WswQuestionSetType } from '../../types';
import { QuestionSetLocator } from './question-set-locator';
import { WswTestConditionalQuestionInstance } from './wsw-test-conditional-question-instance';
import { WswTestScaleQuestionInstance } from './wsw-test-scale-question-instance';
import { WswTestImageMCQuestionInstance } from './wsw-test-image-multiple-choice-question-instance';
import { WswTestMCQuestionInstance } from './wsw-test-multiple-choice-question-instance';
import { WswTestTextQuestionInstance } from './wsw-test-text-question-instance';
import { WswMediaPool } from '../../loading/load-wsw-media';

export type WswQuestionInstance =
  | WswTestTextQuestionInstance
  | WswTestScaleQuestionInstance
  | WswTestConditionalQuestionInstance
  | WswTestMCQuestionInstance
  | WswTestImageMCQuestionInstance;

export interface WswQuestionSetInstance {
  startTimeout: number;
  questionsBefore: WswQuestionInstance[];
  conditionalQuestion: WswTestConditionalQuestionInstance | null;
  questionsAfter: WswQuestionInstance[];
}

/*export interface*/

export class WswQuestionSetFactory {
  pool: WswMediaPool['questions'];

  questionSetType: WswQuestionSetType;
  questionSetIdentifier: string | null;
  questionSet: ConditionalQuestionSet;

  constructor(
    public readonly testContext: TestBaseContext,
    pool: WswMediaPool['questions'],
    public answersPool: WswMediaPool['answers'],
    questionSetType: WswQuestionSetType,
    questionSetIdentifier: string | null,
    questionSet: ConditionalQuestionSet,
  ) {
    this.pool = pool;
    this.questionSetType = questionSetType;
    this.questionSetIdentifier = questionSetIdentifier;
    this.questionSet = questionSet;
  }

  buildInstance(startTimeout = 0): WswQuestionSetInstance {
    const questionSetLocator = new QuestionSetLocator(
      this.questionSetType +
        (this.questionSetIdentifier ? '-' + this.questionSetIdentifier : ''),
      this.questionSet.questionsBefore.length,
    );
    const conditionalQuestion: WswTestConditionalQuestionInstance | null = this
      .questionSet.conditionalQuestion
      ? new WswTestConditionalQuestionInstance(
          this.questionSet.conditionalQuestion.questionText!,
          null,
          {
            left: this.questionSet.conditionalQuestion.confirmText!,
            right: this.questionSet.conditionalQuestion.declineText!,
          },
          this.questionSet.conditionalQuestion.answerType ===
          ConditionalAnswerType.CONFIRM
            ? 'left'
            : 'right',
          questionSetLocator.locateConditional(
            JSON.stringify(this.questionSet.conditionalQuestion),
          ),
          0,
        )
      : null;

    const createQuestionList = (
      questions: TestQuestion[] | undefined,
      order: 'before' | 'after',
    ) => {
      return MindSetRandomUtils.shuffleArrayMaybe(
        questions?.map((question, quI) => {
          const presentable = this.pool
            .select({
              questionSetType: this.questionSetType,
              questionSetIdentifier: this.questionSetIdentifier ?? '',
              questionOrder: order,
              index: quI,
            })
            .collect()[0];
          const type = question.type!;
          if (type === QuestionType.SCALE) {
            let values = Array.from(
              { length: question.scale!.max!.value! },
              (_, i) => ({
                value: i + question.scale!.min!.value!,
                position: i,
              }),
            );
            if (question.scale!.invert) {
              values = values.reverse();
            }
            return new WswTestScaleQuestionInstance(
              question.questionText!,
              presentable?.value ?? null,
              {
                left: question.scale!.invert
                  ? question.scale!.max!.name!
                  : question.scale!.min!.name!,
                right: question.scale!.invert
                  ? question.scale!.min!.name!
                  : question.scale!.max!.name!,
              },
              values,
              question.noAnswer ?? null,
              questionSetLocator.locate(
                order,
                quI,
                WswQuestionType.RatingScala,
                JSON.stringify(question),
              ),
              question.stimulusTimeout ?? 0,
            );
          }
          if (type === QuestionType.TEXT) {
            return new WswTestTextQuestionInstance(
              this.testContext,
              question.questionText!,
              presentable?.value ?? null,
              questionSetLocator.locate(
                order,
                quI,
                WswQuestionType.FreeText,
                JSON.stringify(question),
              ),
              question.stimulusTimeout ?? 0,
            );
          }
          if (type === QuestionType.MULTIPLE_CHOICE) {
            const answers = MindSetRandomUtils.shuffleArrayMaybe(
              question.multiple!.options!.map((answer, aIdx) => ({
                answer,
                position: aIdx,
              })),
              question.multiple!.randomized!,
            );
            return new WswTestMCQuestionInstance(
              question.questionText!,
              presentable?.value ?? null,
              answers,
              question.noAnswer ?? null,
              questionSetLocator.locate(
                order,
                quI,
                WswQuestionType.MultipleChoice,
                JSON.stringify(question),
              ),
              question.stimulusTimeout ?? 0,
            );
          }
          if (type === QuestionType.IMAGE_MULTIPLE_CHOICE) {
            const answers = MindSetRandomUtils.shuffleArrayMaybe(
              question.imageMultipleChoice!.options!.map((answer, aIdx) => ({
                answer,
                answerPresentable: this.answersPool
                  .select({
                    questionSetType: this.questionSetType,
                    questionSetIdentifier: this.questionSetIdentifier ?? '',
                    questionOrder: order,
                    index: quI,
                    optionIndex: aIdx,
                  })
                  .collect()[0].value!,
                position: aIdx,
              })),
              question.imageMultipleChoice!.randomized!,
            );
            return new WswTestImageMCQuestionInstance(
              question.questionText!,
              presentable?.value ?? null,
              answers,
              question.noAnswer ?? null,
              questionSetLocator.locate(
                order,
                quI,
                WswQuestionType.MultipleChoice,
                JSON.stringify(question),
              ),
              question.stimulusTimeout ?? 0,
            );
          }
          throw new Error('Invalid question type: ' + question.type);
        }) ?? [],
        !!this.questionSet.randomizeSequence,
      );
    };

    const questionsBefore = createQuestionList(
      this.questionSet.questionsBefore,
      'before',
    );
    const questionsAfter = createQuestionList(
      this.questionSet.questionsAfter,
      'after',
    );

    return {
      startTimeout,
      questionsBefore,
      conditionalQuestion,
      questionsAfter,
    };
  }
}
