import { TextAlignment } from '../../../../../API';
import {
  createCounterBalancingArranger,
  StandardOptionArranger,
  TestOptionCategories,
} from '../../../../subject/testRunner/media/options-test';
import { TextStyleDescriptor } from '../../../../subject/testRunner/media/text-drawer';
import { TestResourceAccessor } from '../../../../subject/testRunner/media/test-resource-loader';
import { Amp } from '../../types';
import { createDefaultFontStyle } from '../../../testAdmin/testEditor/initialEditorStates';
import { AmpMediaPool } from '../loading/amp-media-loader';
import { AmpBlockConfig } from './helper-types';
import {
  AmpBlockDescriptor,
  AmpBlockPhase,
  describeAmpBlock,
  prepareAmpBlockTexts,
} from './amp-block-structure';
import { AmpPicker } from './amp-picker';
import {
  determineLROptionsTestPickingStrategy,
  extractDeviceOptionsPosition,
  extractTextContent,
  TestInstructionsPhase,
  optionCategoryBuilder,
} from '../../../../subject/testRunner/utils/structure-utils';

export interface AmpStructure {
  accessor: TestResourceAccessor<Amp>;
  pool: AmpMediaPool;
  options: TestOptionCategories<'attribute'>;
  welcomePhase: TestInstructionsPhase;
  blockPhases: AmpBlockPhase[];
  endPhase: TestInstructionsPhase;
}

export function createAmpStructure(
  accessor: TestResourceAccessor<Amp>,
  pool: AmpMediaPool,
): AmpStructure {
  const arranger = createCounterBalancingArranger(null);

  const ampOptions = new TestOptionCategories(
    {
      attribute: optionCategoryBuilder('attribute', pool.attribute, arranger),
    },
    {
      horizontal: 'out',
      vertical: extractDeviceOptionsPosition(
        accessor.testContext,
        accessor.modelData.style,
      ),
    },
    arranger as StandardOptionArranger,
  );

  const ampPicker = new AmpPicker(
    determineLROptionsTestPickingStrategy(
      accessor.modelData.probabilityWithoutReplacement,
    ),
    ampOptions,
    pool,
  );

  function createAmpBlockPhase(block: AmpBlockConfig): AmpBlockPhase {
    const blockDescriptor: AmpBlockDescriptor = describeAmpBlock(
      ampOptions,
      block,
    );
    const trials = ampPicker.pickBlock(blockDescriptor);
    const instructionTexts = prepareAmpBlockTexts(
      accessor.testContext,
      block,
      blockDescriptor,
    );

    return {
      fixation: accessor.modelData.fixation
        ? {
            interval: accessor.modelData.fixation.interval,
            mediaItem: pool.fixation,
          }
        : undefined,
      mask: pool.mask,
      blockDescriptor,
      trialStimuli: trials,
      instructions: {
        welcomeScreenContent: {
          content: instructionTexts.welcomeScreenContent as string,
          nextButton:
            accessor.testContext.deviceInfo.mode === 'mobile'
              ? accessor.modelData.nextLabel
              : null,
        },
        tips: block.tips
          ? {
              text: instructionTexts.tips ?? '',
              style: accessor.testContext.logic.deriveTextStyle(
                TextStyleDescriptor.ofFontStyle(
                  block.tips.fontStyle ??
                    createDefaultFontStyle(TextAlignment.CENTER),
                ),
              ),
            }
          : null,
      },
    };
  }

  return {
    accessor,
    pool,
    options: ampOptions,
    welcomePhase: {
      content: {
        content: extractTextContent(
          accessor.testContext,
          accessor.modelData.instructions,
        ),
        nextButton: accessor.modelData.startLabel ?? 'Start',
      },
    },
    blockPhases: accessor.modelData.blocks.map(createAmpBlockPhase),
    endPhase: {
      content: {
        content: extractTextContent(
          accessor.testContext,
          accessor.modelData.endScreen,
        ),
        nextButton: accessor.modelData.finishLabel ?? 'Finish',
      },
    },
  };
}
