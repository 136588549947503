import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { useField } from 'formik';
import { ScaleQuestionInput } from '../../../../../API';
import { FormikCheckboxField } from '../../../../../components/input/FormikCheckboxField';
import { BoundaryInput } from '../../../../../components/input/BoundaryInput';

export const ScaleQuestionComponent: FC<{ fieldName: string }> = ({
  fieldName,
}) => {
  const [, { value: scaleQuestion }] = useField<ScaleQuestionInput>(fieldName);
  return (
    <Stack direction="row" spacing={2} sx={{ mt: 2, pl: 3 }}>
      <FormikCheckboxField
        name={`${fieldName}.invert`}
        label="Invert Scale"
        size="small"
        sx={{ width: 330 }}
      />
      <Stack
        direction={scaleQuestion.invert ? 'row-reverse' : 'row'}
        spacing={2}
      >
        <BoundaryInput fieldName={`${fieldName}.min`} label="Min" />
        <BoundaryInput fieldName={`${fieldName}.max`} label="Max" />
      </Stack>
    </Stack>
  );
};
