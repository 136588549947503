import { UserRegistration } from '../../API';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  useConfirmUserRegistrationMutation,
  useDenyUserRegistrationMutation,
} from './registrationsApi';
import { roles, UserRole } from '../../auth/user-role';
import React from 'react';
import { format } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import { enqueueSnackbar } from 'notistack';

export function RegistrationRequestDialog({
  request,
  closeDialog,
}: {
  request: UserRegistration;
  closeDialog: (reload?: boolean) => void;
}) {
  const [denyRegistration, { isLoading: isDenyLoading }] =
    useDenyUserRegistrationMutation();
  const [confirmRegistration, { isLoading: isConfirmLoading }] =
    useConfirmUserRegistrationMutation();
  const [choosenRole, setChoosenRole] = React.useState<UserRole>();
  return (
    <Dialog
      open
      onClose={() => {
        closeDialog(false);
      }}
      maxWidth={'lg'}
      fullWidth
    >
      <DialogTitle>User Registration</DialogTitle>
      <DialogContent sx={{ width: '100%' }}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ width: '25%' }}>
                  Date of Registration
                </TableCell>
                <TableCell>
                  {format(new Date(request.createdAt), 'dd.MM.yyyy')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{request.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>E-Mail</TableCell>
                <TableCell>{request.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Institution</TableCell>
                <TableCell>{request.institution}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Research Goal</TableCell>
                <TableCell>
                  {request.researchGoal.split('\n').map((l) => (
                    <Typography sx={{ display: 'block' }}>{l}</Typography>
                  ))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Additional Information</TableCell>
                <TableCell>
                  {request.additionalInformation
                    ?.split('\n')
                    .map((l) => (
                      <Typography sx={{ display: 'block' }}>{l}</Typography>
                    )) ?? ''}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          {choosenRole && (
            <Autocomplete
              disableClearable
              renderInput={(params) => <TextField {...params} label="Groups" />}
              options={roles}
              value={choosenRole}
              onChange={(_, newGroup) => setChoosenRole(newGroup as UserRole)}
            />
          )}
        </Stack>
      </DialogContent>
      {isDenyLoading || isConfirmLoading ? (
        <LinearProgress />
      ) : (
        <DialogActions>
          <Button
            onClick={() => {
              closeDialog(false);
            }}
            variant="contained"
            color="inherit"
          >
            Cancel
          </Button>
          {!choosenRole && (
            <Button
              disabled={isDenyLoading || isConfirmLoading}
              startIcon={
                isDenyLoading ? <CircularProgress size="1em" /> : undefined
              }
              onClick={() => {
                denyRegistration({ registrationId: request.id })
                  .then((res) => {
                    console.log('denied registration', res);
                    enqueueSnackbar('Registration denied successfully', {
                      variant: 'success',
                    });
                    closeDialog(true);
                  })
                  .catch((err) => {
                    enqueueSnackbar('Failed to deny registration', {
                      variant: 'error',
                      persist: true,
                    });
                    console.error('Failed to deny registration', err);
                  });
              }}
              variant="contained"
              color="warning"
            >
              Deny
            </Button>
          )}
          <Button
            variant="contained"
            disabled={isDenyLoading || isConfirmLoading}
            startIcon={
              isConfirmLoading ? <CircularProgress size="1em" /> : undefined
            }
            onClick={() => {
              if (!choosenRole) {
                setChoosenRole('extern');
              } else {
                confirmRegistration({
                  registrationId: request.id,
                  groups: [choosenRole],
                })
                  .then((res) => {
                    console.log('confirmed registration', res);
                    enqueueSnackbar('Registration confirmed successfully', {
                      variant: 'success',
                    });
                    closeDialog(true);
                  })
                  .catch((err) => {
                    enqueueSnackbar('Failed to confirm registration', {
                      variant: 'error',
                      persist: true,
                    });
                    console.error('failed to confirm registration', err);
                  });
              }
            }}
          >
            Accept
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
