import {
  BasicDimension,
  DrawableDimension,
  DrawableMetrics,
} from '../../../media/drawable-dimensions';
import { DrawableMediaHandle } from '../../../media/MediaData';
import { DimensionReducer } from '../../dom-measure/dimension-reducer';
import MediaOffscreenCanvas from '../../../../MediaOffscreenCanvas';
import { displayRatio } from '../../basic-components/media-instance-components';

export interface LazyCanvasStrategy<R> {
  minSize: (data: R[]) => BasicDimension;
  scaleSize: (maxSize: BasicDimension, data: R[]) => BasicDimension;
  scaler: (
    scaleSize: BasicDimension,
    data: R[],
  ) => {
    scale: (data: R) => DrawableMediaHandle;
  };
}

export class LazyStimulusCanvasStrategy
  implements LazyCanvasStrategy<DrawableMediaHandle>
{
  minSize(data: DrawableMediaHandle[]): BasicDimension {
    return DrawableDimension.NullSize;
  }

  scaleSize(
    maxSize: BasicDimension,
    data: DrawableMediaHandle[],
  ): BasicDimension {
    const maxCanvasSize = DimensionReducer.max().process(
      ...data.map((di) => di.size),
    ).currentDimension;
    return DimensionReducer.min().process(maxSize).process(maxCanvasSize)
      .currentDimension;
  }

  scaler(
    scaleSize: BasicDimension,
    data: DrawableMediaHandle[],
  ): { scale: (data: DrawableMediaHandle) => DrawableMediaHandle } {
    // const maxCanvasSize = DimensionReducer.max().process(...data.map(di => di.drawable.dimension)).currentDimension;
    const imageMax = DimensionReducer.max().process(
      ...data.map((td) => td.size),
    ).currentDimension;
    const imageScaleFactor =
      imageMax.width > scaleSize.width || imageMax.height > scaleSize.height
        ? Math.min(
            scaleSize.width / imageMax.width,
            scaleSize.height / imageMax.height,
          )
        : 1;
    return {
      scale: (sData) => {
        return {
          size: DrawableDimension.multiply(scaleSize, displayRatio),
          create: () => {
            const mediaCanvas = new MediaOffscreenCanvas(
              DrawableDimension.multiply(scaleSize, displayRatio).toInt(),
            );
            const prerendered = sData.create();
            mediaCanvas.drawImgSourceCenter(
              prerendered.drawable.imageSource,
              DrawableDimension.multiply(
                prerendered.drawable.dimension,
                imageScaleFactor,
              ).toInt(),
              displayRatio,
            );
            return {
              data: prerendered.data,
              drawable: {
                dimension: DrawableMetrics.fromDimension(
                  DrawableDimension.multiply(scaleSize, displayRatio),
                ),
                imageSource: mediaCanvas.canvas,
              },
            };
          },
        };
      },
    };
  }
}
