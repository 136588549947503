import { SizeState } from './size-state';
import { BasicDimension } from '../../../media/drawable-dimensions';
import { ViewInstance } from '../screen-instance/view-instance';
import { View } from '../views/view';
import { SelectorNodeMap } from '../../selectors/selector-tree';
import { TestScreenComposer } from '../test-screen-composer';
import { ViewScalerOperation } from './view-scaler-operation';
import { TestScreenInstance } from '../screen-instance/test-screen-instance';

export interface ViewScaler {
  sizeState: SizeState;
  maxSize: (size: BasicDimension) => ViewScaler;
  size: (size: BasicDimension) => ViewScaler;

  measureMin(): Promise<ViewScaler>;

  measureInner(): Promise<ViewScaler>;

  scale(): Promise<ViewScaler>;

  readonly pathName: string;
  readonly view: View;
  viewInstance: ViewInstance;
}

export class TestScreenScaler {
  readonly viewScalerMap: Map<View, ViewScaler>;

  constructor(
    public readonly composer: TestScreenComposer,
    public readonly selectorNodeMap: SelectorNodeMap,
  ) {
    this.viewScalerMap = new Map();
    composer.views.forEach((view) => {
      this.viewScalerMap.set(
        view,
        view.createViewScaler(this.viewScalerMap, selectorNodeMap),
      );
    });
  }

  async scaleTestScreen(
    stageSize: BasicDimension,
  ): Promise<TestScreenInstance> {
    this.viewScalerMap
      .get(this.composer.mainView)!
      .maxSize(stageSize)
      .size(stageSize);
    const operationSequence = ViewScalerOperation.processSizeDependencies(
      this.composer.mainView,
      this.viewScalerMap,
    );
    await operationSequence.reduce(async (acc, c) => {
      await acc;
      return c.perform();
    }, Promise.resolve());
    const viewInstanceMap = new Map<View, ViewInstance>(
      Array.from(this.viewScalerMap.entries()).map(([k, v]) => [
        k,
        v.viewInstance,
      ]),
    );
    return new TestScreenInstance(viewInstanceMap, this.composer.mainView);
  }
}
