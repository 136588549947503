import React, { FC } from 'react';
import { TestType } from '../../subject/types';
import InitialWswCreatorState, { wswTemplates } from './creator/initialState';
import {
  createWswAsync,
  deleteWswAsync,
  fetchWswTest,
  fetchWswTests,
  updateWswAsync,
  updateWswStatusAsync,
} from './redux/wsw.thunk';
import TestAdmin from '../testAdmin/TestAdmin';
import WswGameSettingsStep, {
  WswGameSettingsSchema,
} from './creator/steps/WswGameSettingsStep';
import { WswGameSettingsView } from './view/steps/WswGameSettingsView';
import { WswGroupSettingsStep } from './creator/steps/WswGroupSettingsStep';
import {
  WswAttributesStep,
  WswAttributeStepSchema,
} from './creator/steps/WswAttributesStep';
import {
  WswDistractionPhaseStep,
  WswDistractionPhaseStepSchema,
} from './creator/steps/WswDistractionPhaseStep';
import {
  WswLearningPhasesStep,
  WswLearningPhaseStepSchema,
} from './creator/steps/WswLearningPhasesStep';
import {
  WswTestPhaseStep,
  WswTestPhaseStepSchema,
} from './creator/steps/WswTestPhaseStep';
import { WswGroupSettingsView } from './view/steps/WswGroupSettingsView';
import { WswAttributesStepView } from './view/steps/WswAttributesStepView';
import { WswLearningPhaseView } from './view/steps/WswLearningPhaseView';
import { WswDistractionPhaseView } from './view/steps/WswDistractionPhaseView';
import { WswTestPhaseView } from './view/steps/WswTestPhaseView';
import { WswGroupConstructionSchema } from './components/groupConstruction/GroupConstructionInputComponent';
import { Wsw } from './types';
import { WSWTest } from '../../../API';

const WswAdmin: FC = () => {
  return (
    <TestAdmin
      templates={wswTemplates}
      type={TestType.WSW}
      thunks={{
        loadTest: fetchWswTest,
        loadTests: fetchWswTests,
        createTest: createWswAsync,
        updateTest: updateWswAsync,
        updateTestStatus: updateWswStatusAsync,
        deleteTest: deleteWswAsync,
      }}
      results={{
        // @ts-ignore // Todo
        load: () => {},
        // @ts-ignore // Todo
        deleteItems: () => {},
      }}
      steps={[
        {
          name: 'Test',
          editor: {
            component: WswGameSettingsStep,
            validationSchema: WswGameSettingsSchema,
          },
          view: ({ test }) => <WswGameSettingsView test={test as Wsw} />,
        },
        {
          name: 'Groups',
          editor: {
            component: WswGroupSettingsStep,
            validationSchema: WswGroupConstructionSchema,
          },
          view: ({ test }) => <WswGroupSettingsView test={test as WSWTest} />,
        },
        {
          name: 'Attributes',
          editor: {
            component: WswAttributesStep,
            validationSchema: WswAttributeStepSchema,
          },
          view: ({ test }) => <WswAttributesStepView test={test as WSWTest} />,
        },
        {
          name: 'Learning Phase',
          editor: {
            component: WswLearningPhasesStep,
            validationSchema: WswLearningPhaseStepSchema,
          },
          view: ({ test }) => <WswLearningPhaseView test={test as WSWTest} />,
        },
        {
          name: 'Distraction Phase',
          editor: {
            component: WswDistractionPhaseStep,
            validationSchema: WswDistractionPhaseStepSchema,
          },
          view: ({ test }) => (
            <WswDistractionPhaseView test={test as WSWTest} />
          ),
        },
        {
          name: 'Test Phase',
          editor: {
            component: WswTestPhaseStep,
            validationSchema: WswTestPhaseStepSchema,
          },
          view: ({ test }) => <WswTestPhaseView test={test as WSWTest} />,
        },
      ]}
      editor={{
        initialState: InitialWswCreatorState,
      }}
    />
  );
};

export default WswAdmin;
