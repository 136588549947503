import { PickingSet } from '../PickingSet';

export class MappingPickingSet<T, E> implements PickingSet<T> {
  constructor(
    private delegateSet: PickingSet<E>,
    private mapper: (item: E) => T,
  ) {}

  validate() {
    this.delegateSet.validate();
  }

  get name() {
    return this.delegateSet.name;
  }

  pick() {
    return this.mapper(this.delegateSet.pick());
  }
}
