import React, { FC } from 'react';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import StyledSelect from '../../../../../components/input/StyledSelect';
import {
  PickingStrategy,
  WSWAttributeInput,
  WSWAttributeType,
  WswStimulusAttributeVariant,
} from '../../../../../API';
import { WSWAttributeCategoryInputComponent } from './WSWAttributeCategoryInputComponent';
import { useField } from 'formik';
import { TransitionGroup } from 'react-transition-group';
import _ from 'lodash';
import { PickingStrategyToolTip } from '../../../../../components/input/PickingStrategyToolTip';
import { Cancel, InfoOutlined } from '@mui/icons-material';
import { FormikNullableCheckbox } from '../../../../../components/input/FormikNullableCheckbox';
import { TypeToolTip } from '../../../../../components/TypeToolTip';

interface WswAttributeInputComponentProps {
  fieldName: string;
  label: string;
  optional?: boolean;
  fixedPickingStrategy?: PickingStrategy;
  fixedAttributeType?: WSWAttributeType;
  groupConstructionEnabled: boolean;
  toolTip?: string;
  pickToolTip?: string;
}

export const WswAttributeInputComponent: FC<
  WswAttributeInputComponentProps
> = ({
  fieldName,
  label,
  optional,
  fixedAttributeType,
  fixedPickingStrategy,
  groupConstructionEnabled,
  toolTip,
  pickToolTip,
}) => {
  const [, { value: attribute }, helpers] = useField<
    WSWAttributeInput | undefined | null
  >(fieldName);

  const setStimuliVariant = (stimuliVariant: WswStimulusAttributeVariant) => {
    if (attribute) {
      helpers.setValue({ ...attribute, stimuliVariant });
    }
  };

  console.log(fieldName, attribute);
  const remove = () => helpers.setValue(null);

  const add = () =>
    helpers.setValue({
      type: WSWAttributeType.STIMULI,
      pickingStrategy: PickingStrategy.PERMUTATION,
      categories: [],
    });

  return (
    <Box>
      {optional ? (
        <Typography sx={{ mb: 2 }} variant="h6" color="textSecondary">
          {label}&nbsp;
          {toolTip && (
            <Tooltip placement={'right'} title={toolTip}>
              <InfoOutlined
                sx={{
                  color: 'warning.dark',
                  bgcolor: 'white',
                  position: 'relative',
                  // left: "-1em"
                }}
              />
            </Tooltip>
          )}
          <FormikNullableCheckbox
            label={''}
            onChange={(checked) => (checked ? add() : remove())}
            value={Boolean(attribute)}
          />
        </Typography>
      ) : (
        <Stack direction="row">
          <Typography sx={{ mb: 2 }} variant="h6" color="textSecondary">
            {label}
          </Typography>
          {toolTip && (
            <Tooltip placement={'right'} title={toolTip}>
              <InfoOutlined
                sx={{
                  color: 'warning.dark',
                  bgcolor: 'white',
                  position: 'relative',
                }}
              />
            </Tooltip>
          )}
        </Stack>
      )}

      {attribute?.stimuliVariant !== null &&
      attribute?.stimuliVariant !== undefined ? (
        <Stack spacing={2}>
          <TransitionGroup>
            {attribute && (
              <Collapse>
                <Box sx={{ display: 'flex' }}>
                  <Box width="50%" pr={1}>
                    {fixedAttributeType ? (
                      <StyledSelect
                        disabled={Boolean(fixedAttributeType)}
                        name={`${fieldName}.type`}
                        label={'Type'}
                        toolTip={`Type cannot be changed for the ${label}`}
                      >
                        <option value={fixedAttributeType}>
                          {_.startCase(_.toLower(fixedAttributeType))}
                        </option>
                      </StyledSelect>
                    ) : (
                      <Stack direction="row" alignItems="center">
                        <StyledSelect
                          disabled
                          name={`${fieldName}.type`}
                          label={'Type'}
                          toolTip={<TypeToolTip />}
                        >
                          <option value={WSWAttributeType.STIMULI}>
                            Stimuli
                          </option>
                          <option
                            value={WSWAttributeType.GROUPS}
                            disabled={!groupConstructionEnabled}
                          >
                            Groups
                          </option>
                        </StyledSelect>
                        <IconButton
                          onClick={() => {
                            helpers.setValue({
                              ...attribute,
                              categories: [],
                              stimuliVariant: undefined,
                              type: WSWAttributeType.STIMULI,
                            });
                          }}
                          title="Reset type selection"
                        >
                          <Cancel />
                        </IconButton>
                      </Stack>
                    )}
                  </Box>
                  <Box width="50%">
                    {fixedPickingStrategy ? (
                      <StyledSelect
                        disabled={Boolean(fixedPickingStrategy)}
                        name={`${fieldName}.pickingStrategy`}
                        label={'Picking Strategy'}
                        toolTip={`Picking Strategy cannot be changed for the ${label}`}
                      >
                        <option value={fixedPickingStrategy}>
                          {_.startCase(_.toLower(fixedPickingStrategy))}
                        </option>
                      </StyledSelect>
                    ) : (
                      <StyledSelect
                        name={`${fieldName}.pickingStrategy`}
                        label={'Picking Strategy'}
                        toolTip={<PickingStrategyToolTip />}
                      >
                        <option value={PickingStrategy.PERMUTATION}>
                          Permutation
                        </option>
                        <option value={PickingStrategy.WITH_REPLACEMENT}>
                          With replacement
                        </option>
                        <option value={PickingStrategy.WITHOUT_REPLACEMENT}>
                          Without replacement
                        </option>
                        <option
                          value={PickingStrategy.REFILLING_WITHOUT_REPLACEMENT}
                        >
                          Refilling without replacement
                        </option>
                      </StyledSelect>
                    )}
                  </Box>
                </Box>
              </Collapse>
            )}
          </TransitionGroup>
          <TransitionGroup>
            {attribute.stimuliVariant &&
              attribute?.type === WSWAttributeType.STIMULI && (
                <Collapse>
                  <WSWAttributeCategoryInputComponent
                    fieldName={`${fieldName}.categories`}
                    variantName={`${fieldName}.stimuliVariant`}
                    mode={attribute.stimuliVariant}
                  />
                </Collapse>
              )}
          </TransitionGroup>
        </Stack>
      ) : (
        <div>
          {attribute && (
            <Stack direction="row" alignItems="center" spacing={2}>
              {pickToolTip && (
                <Tooltip placement={'right'} title={pickToolTip}>
                  <InfoOutlined
                    sx={{
                      color: 'warning.dark',
                      bgcolor: 'white',
                      position: 'relative',
                      // left: "-1em"
                    }}
                  />
                </Tooltip>
              )}
              <Typography color={'textSecondary'}>Pick: </Typography>
              <Stack>
                {!fixedAttributeType && groupConstructionEnabled && (
                  <Button
                    onClick={() => {
                      helpers.setValue({
                        type: WSWAttributeType.GROUPS,
                        pickingStrategy: PickingStrategy.WITH_REPLACEMENT,
                        stimuliVariant: WswStimulusAttributeVariant.Image,
                      });
                    }}
                    size="small"
                    sx={{ justifyContent: 'flex-start' }}
                  >
                    Groups
                  </Button>
                )}
                <Button
                  onClick={() => {
                    setStimuliVariant(WswStimulusAttributeVariant.Image);
                  }}
                  size="small"
                  sx={{ justifyContent: 'flex-start' }}
                >
                  Images
                </Button>
                <Button
                  onClick={() => {
                    setStimuliVariant(WswStimulusAttributeVariant.Text);
                  }}
                  size="small"
                  sx={{ justifyContent: 'flex-start' }}
                >
                  Texts
                </Button>
              </Stack>
            </Stack>
          )}
        </div>
      )}
    </Box>
  );
};
