import React, { FC } from 'react';
import { Iat } from '../../types';
import { Stack } from '@mui/material';
import { BorderBox } from '../../../../../components/BorderBox';
import { CategoryPreviewDisplay } from '../../../../../API';
import { TextDisplay } from '../../../../../components/TextDisplay';
import { ColorDisplay } from '../../../../../components/display/ColorDisplay';
import { translationInfoMap } from '../../../../subject/testRunner/state/baseTestContext/app-configuration/translations/translation-map';

const categoryPreviewMap: { [key in CategoryPreviewDisplay]: string } = {
  [CategoryPreviewDisplay.INSTRUCTIONS]: 'In instructions',
  [CategoryPreviewDisplay.STAND_ALONE]: 'In seperate screen',
  [CategoryPreviewDisplay.NONE]: 'None',
};

export const IatGameSettingsView: FC<{ test: Iat }> = ({ test }) => {
  return (
    <Stack spacing={3}>
      <Stack spacing={5} direction="row">
        <TextDisplay
          label="Language"
          value={
            test.language ? translationInfoMap[test.language].languageName : '-'
          }
          suffix=""
        />
        <TextDisplay
          label="Category Preview"
          value={categoryPreviewMap[test.previewCategories]}
        />
        <TextDisplay
          label="Counterbalancing"
          value={test.counterbalancing ? 'Yes' : 'No'}
        />
        <TextDisplay
          label="Attributes-Position"
          value={test.style.optionsPosition}
        />
        <TextDisplay label="Width" value={test.style.maxWidth} suffix="%" />
        <ColorDisplay
          label={'Background'}
          value={test.style.backgroundColour}
        />
      </Stack>
      <BorderBox label="Keyboard">
        <Stack direction="row" spacing={3}>
          <TextDisplay label="Left Button" value={test.keyboard.left} />
          <TextDisplay label="Right Button" value={test.keyboard.right} />
          <TextDisplay label="Start Button" value={test.keyboard.confirm} />
        </Stack>
      </BorderBox>
      <BorderBox label="Welcome screen">
        <div
          className="emptyNewLineFilled"
          dangerouslySetInnerHTML={{ __html: test.instructions.desktop }}
        />
      </BorderBox>
      <BorderBox label="End screen">
        <div
          className="emptyNewLineFilled"
          dangerouslySetInnerHTML={
            test.endScreen?.desktop
              ? { __html: test.endScreen.desktop }
              : undefined
          }
        />
      </BorderBox>
    </Stack>
  );
};
