import { View } from '../views/view';
import { Observable } from 'rxjs';
import { TestFlowSnapshot } from '../../../graph-state/test-director';
import { TestScreenInstanceController } from './test-screen-instance-controller';
import { ViewInstanceController } from './view-instance-controller';
import { ViewInstance } from './view-instance';

export class TestScreenInstance {
  readonly viewInstanceMap: Map<View, ViewInstance>;

  constructor(
    viewInstanceMap: Map<View, ViewInstance>,
    public mainView: View,
  ) {
    this.viewInstanceMap = viewInstanceMap;
  }

  createScreenController(
    flowStream: Observable<TestFlowSnapshot>,
  ): TestScreenInstanceController {
    const viewControllerMap = new Map<View, ViewInstanceController>();
    this.viewInstanceMap.forEach((value, key) => {
      viewControllerMap.set(
        key,
        value.createInstanceController(viewControllerMap, flowStream),
      );
    });
    return new TestScreenInstanceController(
      viewControllerMap.get(this.mainView)!,
      flowStream,
    );
  }
}
