import React, { FC } from 'react';
import LockPerson from '@mui/icons-material/LockPerson';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { TestPermissions } from './TestPermissions';
import { useIsAdminOrTestOwner } from '../../../../auth/auth.hook';
import { TestPermissionsProps } from './types';

export const TestPermissionsAccordion: FC<TestPermissionsProps> = (props) => {
  const isAdminOrTestOwner = useIsAdminOrTestOwner(props.ownerId ?? '');
  return isAdminOrTestOwner ? (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box
          sx={{
            width: '33%',
            flexShrink: 0,
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          <Avatar
            sx={{
              height: 24,
              width: 24,
              bgcolor: 'primary.main',
              mr: 2,
            }}
          >
            <LockPerson fontSize="small" />
          </Avatar>
          Permissions
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <TestPermissions {...props} />
      </AccordionDetails>
    </Accordion>
  ) : null;
};
