import { SliceSnapshot, StateHolder } from './state-holder';
import { GQLQueryInstance } from '../../../../GQL';
import { Observer } from 'rxjs';
import { ControlOccurrence } from '../controls/control-stereotypes';
import { TransitionAction } from './graph-action';
import { ReactionTimeProcessor } from '../controls/reaction-time-processor';
import { TreeSequence } from '../graph/tree-sequence/tree-sequence';
import { TreeTransition } from '../graph/tree-sequence/tree-transition';
import { NodePathEntry } from '../graph/tree-sequence/node-path-entry';

export interface ResultQueue {
  enqueueResult(queryInstance: GQLQueryInstance<any, any>): void;
}

export interface TestFlowSnapshot {
  screenNode: NodePathEntry;
  stateHolder: StateHolder;
  timeProcessor: ReactionTimeProcessor;
}

export class TestDirector {
  private stateHolder = new StateHolder();
  private currentNode: NodePathEntry;
  private currentTiming: ReactionTimeProcessor;

  constructor(
    private treeSequence: TreeSequence,
    private flowObserver: Observer<TestFlowSnapshot>,
    private resultQueue: ResultQueue,
  ) {
    this.currentNode = this.processTransition(
      treeSequence.start.get(treeSequence.tree.modifiers.default)!,
    );
    this.currentTiming = ReactionTimeProcessor.StartProcessor();
    this.informObservers();
  }
  getStateHolder() {
    return this.stateHolder;
  }
  public externStateChange(node: NodePathEntry) {
    this.currentNode = node;
    this.currentTiming = ReactionTimeProcessor.StartProcessor();
    this.informObservers();
  }

  processControl(occurrence: ControlOccurrence<any>) {
    const controlTransition =
      this.currentNode.screenData.controlTransitions.find(
        (ct) => ct.controlRequest.stereotype === occurrence.stereotype,
      );
    if (controlTransition) {
      this.currentTiming.processTime(
        'controlStart',
        occurrence.trigger.occurredOn,
      );
      this.processAction(
        controlTransition.actionFactory.instanceAction(
          {
            occurrence,
            reactionTimeProcessor: this.currentTiming,
          },
          this.stateHolder,
        ),
      );
    }
  }

  protected processTransition(
    transition: TreeTransition,
    ...stateSnapshots: SliceSnapshot<[]>[]
  ) {
    this.moveTreeUp(transition);
    stateSnapshots.forEach((snap) => {
      this.stateHolder.upsert(snap);
    });
    this.moveTreeDown(transition);
    return this.treeSequence.nodeMap.get(transition.screen)!;
  }

  protected moveTreeDown(transition: TreeTransition) {
    transition.in.forEach((tn) => {
      if (tn.stateSlice) {
        this.stateHolder.init(tn.stateSlice);
      }
    });
    if (transition.screen.stateSlice) {
      this.stateHolder.init(transition.screen.stateSlice);
    }
  }

  protected moveTreeUp(transition: TreeTransition) {
    transition.out.forEach((tn) => {
      if (tn.stateSlice) {
        this.stateHolder.erase(tn.stateSlice);
      }
    });
  }

  protected informObservers() {
    this.flowObserver.next({
      screenNode: this.currentNode,
      stateHolder: this.stateHolder,
      timeProcessor: this.currentTiming,
    });
  }

  protected processAction(transitionAction: TransitionAction) {
    if (transitionAction.result) {
      this.resultQueue.enqueueResult(transitionAction.result);
    }
    const transition = this.currentNode.transitions.get(
      transitionAction.transitionModifier,
    );
    if (!transition) {
      this.tearDownTest();
    } else {
      this.currentNode = this.processTransition(
        transition,
        ...transitionAction.stateModifications,
      );
      this.currentTiming = ReactionTimeProcessor.StartProcessor();
      this.informObservers();
    }
  }

  protected tearDownTest() {
    this.flowObserver.complete();
  }
}
