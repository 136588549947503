import React, { PropsWithChildren } from 'react';
import {
  TestInvocationParams,
  testInvocationParamsFromBrowserURI,
} from './testRunner/state/baseTestContext/invocation-params/test-invocation-params';
import { useLocation, useParams } from 'react-router-dom';
import {
  installOrLookupRuntimeAppConfiguration,
  RunnerAppConfiguration,
} from './testRunner/state/baseTestContext/app-configuration/runner-app-configuration';
import { createAppConfigSettingsFromInvocationParams } from './testRunner/state/baseTestContext/invocation-params/runner-app-configuration-settings';

export function useTestInvocationParams(): TestInvocationParams {
  const params = useParams();
  const location = useLocation();
  const [testInvocationParams] = React.useState(() =>
    testInvocationParamsFromBrowserURI(
      params,
      new URLSearchParams(location.search),
    ),
  );
  /*  const params = useParams();
  const location = useLocation();
  return React.useMemo(
    () =>
      testInvocationParamsFromBrowserURI(
        params,
        new URLSearchParams(location.search),
      ),
    [params, location.search],
  );*/
  return testInvocationParams;
}

export function useInstallRuntimeAppConfiguration() {
  const params = useTestInvocationParams();
  const appConfig = React.useMemo(() => {
    return installOrLookupRuntimeAppConfiguration({
      params: createAppConfigSettingsFromInvocationParams(params),
    });
  }, [params]);
  return { testInvocationParams: params, appConfiguration: appConfig };
}

export type RunnerExecutionContextState = {
  readonly testInvocationParams: TestInvocationParams;
  readonly appConfiguration: RunnerAppConfiguration;
};

const RunnerExecutionContext = React.createContext<RunnerExecutionContextState>(
  undefined as unknown as RunnerExecutionContextState,
);

export function useRunnerExecutionContext() {
  const context = React.useContext(RunnerExecutionContext);
  const params = useTestInvocationParams();
  if (context === undefined) {
    console.warn(
      'useRunnerExecutionContext must be used within a RunnerExecutionContextProvider',
    );
    const appConfig = installOrLookupRuntimeAppConfiguration({
      params: createAppConfigSettingsFromInvocationParams(params),
    });
    return {
      testInvocationParams: params,
      appConfiguration: appConfig,
    };
  }
  return context;
}

export function RunnerExecutionContextProvider({
  children,
}: PropsWithChildren<{}>) {
  const { testInvocationParams, appConfiguration } =
    useInstallRuntimeAppConfiguration();
  const contextValue = React.useMemo(
    () => ({ testInvocationParams, appConfiguration }),
    [testInvocationParams, appConfiguration],
  );
  return (
    <RunnerExecutionContext.Provider value={contextValue}>
      {children}
    </RunnerExecutionContext.Provider>
  );
}
