export interface TimedEvent<E extends Event> {
  timestamp: number;
  event: E;
}

export class WrappedEvent implements TimedEvent<Event> {
  private constructor(
    public event: Event,
    public timestamp: number,
  ) {}

  static wrap(event: Event) {
    return new WrappedEvent(event, performance.now());
  }
}
export interface TimedType {
  readonly occurredOn: number;
}
export interface TimedValue<V> extends TimedType {
  readonly value: V;
}
export class TimeMeasuredValue<V> implements TimedValue<V> {
  constructor(
    readonly value: V,
    readonly occurredOn: number,
  ) {}

  map<W>(mapper: (value: V) => W) {
    return new TimeMeasuredValue(mapper(this.value), this.occurredOn);
  }
  static now<V>(value: V): TimeMeasuredValue<V> {
    return new TimeMeasuredValue<V>(value, performance.now());
  }
}
export function timeValue<V>(value: V): TimedValue<V> {
  return {
    occurredOn: performance.now(),
    value,
  };
}

export function wrapTimedValue<V>(
  value: V,
  wrappedTime: TimedValue<any>,
): TimedValue<V> {
  return {
    value,
    occurredOn: wrappedTime.occurredOn,
  };
}
