import { Stack, Typography } from '@mui/material';

export function SurveyTestSetupInstructionsIntroduction() {
  return (
    <Stack spacing={1}>
      <Typography color="text.secondary">
        To integrate a MIND.set Test in a survey of a survey tool you have to
        add HTML-Code to a question in the survey.
      </Typography>
      <Typography color="text.secondary">
        The survey tool will show the question and overlay it with the HTML of
        MIND.set
      </Typography>
    </Stack>
  );
}
