import { DataSelectorBuilder } from '../../../data-selector-builder';
import { Amp } from '../../types';

export const AmpMediaItemCategorySelector = DataSelectorBuilder.Create<Amp>()
  .select('primes', 'targets')
  .select('categories')
  .selectMap('practiceStimuliPool', 'testStimuliPool')
  .selectExisting();

export const AmpMediaItemAttributeCategorySelector =
  DataSelectorBuilder.Create<Amp>()
    .select('attributeCategories')
    .select('categoryA', 'categoryB')
    .selectExisting();
