import { TestProvider } from '../baseTestContext/context/ContextTypes';
import { BaseTestIdentity } from '../testIdentity/types';
import { TestsMetaData } from '../../../types';

export const PROVIDER_NAME_URL_PARAM = 'provider';
export type URLParamValue =
  | string
  | string[]
  | { encode: boolean; value: string | string[] };
export function createBaseTestUrl(
  provider: TestProvider,
  testIdentity: BaseTestIdentity,
  params: Record<string, string | string[]>,
) {
  const testIdentityUrlPart = `/run/${
    TestsMetaData[testIdentity.testType].identifier
  }/${testIdentity.testId}`;
  const paramsUrlPart = [
    [PROVIDER_NAME_URL_PARAM, provider.providerName],
    ...Object.entries(params),
  ]
    .flatMap(([key, val]): [string, { encode: boolean; value: string }][] => {
      const value =
        Array.isArray(val) || typeof val === 'string'
          ? { encode: true, value: val }
          : val;
      return Array.isArray(value.value)
        ? value.value.map(
          (v) =>
            [key, { encode: value.encode, value: v }] as [
              string,
              { encode: boolean; value: string },
            ],
        )
        : ([[key, value]] as [string, { encode: boolean; value: string }][]);
    })
    .map(
      ([key, val]) =>
        `${encodeURIComponent(key)}=${val.encode ? encodeURIComponent(val.value) : val.value}`,
    )
    .join('&');
  const providerUrlPart = `?${paramsUrlPart}`;

  return testIdentityUrlPart + providerUrlPart;
}
