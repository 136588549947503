import { translations } from '../../state/baseTestContext/app-configuration/translations/translation-builder';

export const TestInitErrorTranslations = translations.scope(
  'Initialization Error',
  {
    InvalidTestURL: translations.text('Invalid Test URL', {
      en: 'The test is referenced by an invalid url. Please make sure you included the right codesnippet.',
    }),
    InvalidProbandID: translations.scope('Invalid Respondent ID', {
      message: translations.textList('Invalid Respondent ID', {
        en: [
          'Could not identify id of the respondent.',
          'If the survey (e.g. in limesurvey) is in preview mode this behaviour is expected - just press "Proceed".',
          'If the survey is published there is an error in the codesnippet.',
        ],
      }),
      button: translations.text('Proceed with generated id', {
        en: 'Proceed with generated id',
      }),
    }),

    TestLoadingError: translations.scope('Errors Loading test', {
      message: translations.text('Test Loading Error', {
        en: 'Please make sure that you have set your time correctly on the device and that you have a stable internet connection. Then load the test again.',
        de: 'Bitte stellen Sie sicher, dass Sie Ihre Zeit auf dem Gerät korrekt eingestellt haben und dass Sie eine stabile Internetverbindung haben. Laden Sie den Test anschließend erneut.',
      }),
      button: translations.text('Reload Button', {
        en: 'Reload',
        de: 'Neu laden',
      }),
    }),
    TestCanvasMemoryError: translations.scope('Errors Canvas Memory', {
      messages: translations.textList('Canvas Memory Error', {
        en: [
          'Your IPhone had an error while loading the test. Please try to reload by pressing "Reload".',
          'If this happens again, it might be due to an incompatible version of your iOS and Safari.',
        ],
        de: [
          'Ihr IPhone hatte einen Fehler beim Laden des Tests. Bitte versuchen Sie erneut zu laden indem Sie auf "Reload" drücken.',
          'Falls dies weiterhin auftritt, kann dies mit einer nicht unterstützten Version von iOS und Safari zusammenhängen.',
        ],
      }),
      button: translations.text('Reload Button', {
        en: 'Reload',
        de: 'Neu laden',
      }),
    }),
  },
);
