import { DataSelectorBuilder } from '../../../data-selector-builder';
import { Podt } from '../../types';
import { Podta } from '../../../PODTA/types';

export const PodtMediaItemScenesSelector = DataSelectorBuilder.Create<
  Podt | Podta
>()
  .select('scenes')
  .select('scenes')
  .selectMap('mediaItem')
  .selectExisting();

export const PodtMediaItemTargetSelector = DataSelectorBuilder.Create<
  Podt | Podta
>()
  .select('targets')
  .selectMap('practiceStimuliPool', 'testStimuliPool')
  .selectFlat('dangerousStimuli', 'nonDangerousStimuli')
  .selectExisting();

export const PodtMediaItemResponseSelector = DataSelectorBuilder.Create<
  Podt | Podta
>()
  .select('response')
  .select('active', 'passive')
  .selectExisting();
