import { StageSetupStage } from './StageSetupStage';

const blockedSetupStates = ['init', 'done', 'finish-results'];

export function installBeforeLeaveTestPrompt(setupState: StageSetupStage) {
  const handler = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = '';
  };
  if (blockedSetupStates.includes(setupState)) {
    window.addEventListener('beforeunload', handler);
    return () => {
      window.removeEventListener('beforeunload', handler);
    };
  }
  return () => {};
}
