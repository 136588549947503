import {
  ConditionalAnswerType,
  CreateWSWTestInput,
  FontFace,
  MediaItemSnapshotScope,
  OptionsPosition,
  PickingStrategy,
  QuestionType,
  TestStatus,
  TextAlignment,
  WSWAttributeType,
} from '../../../../API';
import {
  createDefaultStyle,
  createEmptyWswGroupConstructions,
} from '../../testAdmin/testEditor/initialEditorStates';
import { Wsw } from '../types';

export const wswTemplates: Wsw[] = [
  {
    id: 'd7c2d2d7-25e5-436f-8aa8-fc559e21c405',
    name: 'Arab/White',
    description: 'Arab/white, 24 Trials Lernphase, 36 Trials Testphase',
    instructions: {
      __typename: 'StyleableTextTemplate',
      desktop:
        '<p><span style="font-family: Arial;">Nun wollen wir Sie bitten, an einem kognitiven Test teilzunehmen.  </span></p>\n<p><span style="font-family: Arial;">In dieser Aufgabe bilden Sie sich einen persönlichen Eindruck von Personen, die unterschiedlichen sozialen Kategorien zugeordnet werden können.  </span></p>\n<p><span style="font-family: Arial;">Hier werden Ihnen verschiedene Personen mit Bildern und Beschreibungen vorgestellt. Bitte konzentrieren Sie sich für diese Aufgabe und beachten Sie alle Personen.  </span></p>\n<p><span style="font-family: Arial;">Der Test hat insgesamt zwei Runden.  </span></p>\n<p><span style="font-family: Arial;">Drücken Sie auf START, um mit der ersten Runde zu beginnen.   </span></p>\n',
      mobile: null,
      fontStyle: null,
    },
    style: {
      maxWidth: 100,
      backgroundColour: 'white',
      stimulusTextStyle: {
        __typename: 'FontStyle',
        fontSize: 20,
        color: 'black',
        alignment: TextAlignment.CENTER,
        fontFace: FontFace.ARIAL,
      },
      mobileStimulusTextStyle: {
        __typename: 'FontStyle',
        fontSize: 14,
        color: 'black',
        alignment: TextAlignment.LEFT,
        fontFace: FontFace.ARIAL,
      },
      optionsPosition: OptionsPosition.TOP,
      optionsPositionMobile: OptionsPosition.MIDDLE,
    },
    status: TestStatus.DRAFT,
    endScreen: {
      desktop:
        '<p><span style="font-size: 16px;font-family: Arial;">Damit ist der kognitive Test beendet.  </span></p>\n<p><span style="font-size: 16px;font-family: Arial;">Es folgen nun noch einige weitere Fragen zu verschiedenen Themen.  </span></p>\n<p><span style="font-size: 16px;font-family: Arial;">Wenn Sie auf WEITER drücken, kommen Sie automatisch zu dem Fragebogen zurück.   </span></p>\n',
      mobile: null,
      fontStyle: null,
      __typename: 'StyleableTextTemplate',
    },
    attributeCombinations: [
      {
        mainCategory: 0,
        additionalCategory: null,
        criteriaCategory: 0,
        amount: 6,
        multiplicator: 1,
        __typename: 'AttributeCombination',
      },
      {
        mainCategory: 1,
        additionalCategory: null,
        criteriaCategory: 0,
        amount: 6,
        multiplicator: 1,
        __typename: 'AttributeCombination',
      },
      {
        mainCategory: 0,
        additionalCategory: null,
        criteriaCategory: 1,
        amount: 6,
        multiplicator: 1,
        __typename: 'AttributeCombination',
      },
      {
        mainCategory: 1,
        additionalCategory: null,
        criteriaCategory: 1,
        amount: 6,
        multiplicator: 1,
        __typename: 'AttributeCombination',
      },
      {
        mainCategory: 0,
        additionalCategory: null,
        criteriaCategory: null,
        amount: 6,
        multiplicator: 1,
        __typename: 'AttributeCombination',
      },
      {
        mainCategory: 1,
        additionalCategory: null,
        criteriaCategory: null,
        amount: 6,
        multiplicator: 1,
        __typename: 'AttributeCombination',
      },
    ],
    mainAttribute: {
      stimuliVariant: 'Image',
      type: 'STIMULI',
      pickingStrategy: 'PERMUTATION',
      categories: [
        {
          name: 'Arab',
          mediaSnaps: [
            {
              id: '59e396bf-90e3-4b3b-ab2e-6fae1adf607d',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/1c677154-54c4-4455-903d-7783dd09f856.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/1c677154-54c4-4455-903d-7783dd09f856.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_01_17.jpg',
            },
            {
              id: 'f1795356-1bd8-49df-8f9e-f074bdd24913',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/8a83115e-da51-4987-848e-386b1aa1c237.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/8a83115e-da51-4987-848e-386b1aa1c237.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_01_09.jpg',
            },
            {
              id: '4494cfb7-a4a7-4dce-8b02-e77e1b7b03ce',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/14d472a2-d7c0-4ca0-a7f7-e646d76b0ded.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/14d472a2-d7c0-4ca0-a7f7-e646d76b0ded.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_01_08.jpg',
            },
            {
              id: '2eb4158e-0dc8-4ff9-9625-9132007c3e9a',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/f5c7a5f6-bf77-4789-aedc-04eab6dd5b25.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/f5c7a5f6-bf77-4789-aedc-04eab6dd5b25.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_01_19.jpg',
            },
            {
              id: 'dca0a9e9-9b4f-4bb4-ab7a-7e9a03999ce8',
              tags: [
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/7840346c-9355-4997-9c7c-5505ad48d29b.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/7840346c-9355-4997-9c7c-5505ad48d29b.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_01_22.jpg',
            },
            {
              id: '239d914b-4a26-487d-8a63-0a9b3c863fea',
              tags: [
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/dd1e5547-89b7-4d54-b59b-cd500fe4aff4.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/dd1e5547-89b7-4d54-b59b-cd500fe4aff4.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_01_05.jpg',
            },
            {
              id: '5055a286-aaaa-4a20-b24c-1ec02866d3f4',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/d3d77039-bfb2-4072-877d-0ecb846a0eac.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/d3d77039-bfb2-4072-877d-0ecb846a0eac.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_01_21.jpg',
            },
            {
              id: '80731c76-14b9-4336-b019-159f32faa34e',
              tags: [
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/30a43b45-f378-4cdc-89c7-5ded687bfde7.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/30a43b45-f378-4cdc-89c7-5ded687bfde7.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_01_14.jpg',
            },
            {
              id: '845469f2-f1c6-4a36-b1a4-087c37ab1b89',
              tags: [
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/7ad07274-d743-4e0a-b060-d86f4a1f27ad.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/7ad07274-d743-4e0a-b060-d86f4a1f27ad.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_01_02.jpg',
            },
            {
              id: 'ca948182-4863-4e14-9867-860db6ec39e8',
              tags: [
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/5051b2ce-e18b-4c23-ab9e-945d91e835c4.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/5051b2ce-e18b-4c23-ab9e-945d91e835c4.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_01_01.jpg',
            },
            {
              id: '28261ad8-92fa-4ce1-b18b-8c9351e40b7c',
              tags: [
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/953b9a9f-4443-4bac-afd5-849ef144658e.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/953b9a9f-4443-4bac-afd5-849ef144658e.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_01_04.jpg',
            },
            {
              id: 'ae379a0d-e4eb-4ae1-ab06-f4c1e4e680ba',
              tags: [
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/7dbd6734-e11b-429d-bbb6-f3b92b025e19.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/7dbd6734-e11b-429d-bbb6-f3b92b025e19.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_01_03.jpg',
            },
            {
              id: '7472f23e-ee4f-4576-b2a0-b6efae47d4b2',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/96d0980f-ee75-4e29-99f0-83881688f592.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/96d0980f-ee75-4e29-99f0-83881688f592.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_01_20.jpg',
            },
            {
              id: 'cdc58a07-4a8e-44e2-b1c4-1e4d09789a8e',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/0f5649fa-95fd-42ee-b3ab-20d408e55a0d.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/0f5649fa-95fd-42ee-b3ab-20d408e55a0d.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_01_10.jpg',
            },
            {
              id: '2281b20b-c4bd-488c-8685-fb14d28bd4d4',
              tags: [
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/389b7b83-4802-44bf-b284-2c26962489a9.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/389b7b83-4802-44bf-b284-2c26962489a9.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_01_06.jpg',
            },
            {
              id: '62fc16b8-3927-4f0c-adb9-28d7de39e279',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/f8221000-8e22-432b-a0da-bd3098e5bd02.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/f8221000-8e22-432b-a0da-bd3098e5bd02.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_01_16.jpg',
            },
            {
              id: 'e552a4de-2ff0-440b-aed9-2000faba01d4',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/527da73d-56a9-43af-b8ff-fd8157a0ae3b.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/527da73d-56a9-43af-b8ff-fd8157a0ae3b.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_01_18.jpg',
            },
            {
              id: 'ff5bbf5e-4441-4ec2-b403-df6d26351845',
              tags: [
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/d7f2b27f-5b84-4137-9c4c-fc189f4634f3.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/d7f2b27f-5b84-4137-9c4c-fc189f4634f3.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_01_12.jpg',
            },
          ],
        },
        {
          name: 'White',
          mediaSnaps: [
            {
              id: 'e709422c-c9e7-454f-b2d1-02d274f92860',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/15442f75-e76f-4ea4-844b-0450563d07e6.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/15442f75-e76f-4ea4-844b-0450563d07e6.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_02_24.jpg',
            },
            {
              id: 'de38197f-971a-46da-b50f-03c2964a70ac',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/e0254b1c-c5a0-4e52-a6af-d2179936a44b.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/e0254b1c-c5a0-4e52-a6af-d2179936a44b.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_02_03.jpg',
            },
            {
              id: '19f5c0ef-48e4-49e8-920d-35cec777670c',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/44836b84-20e5-46bd-be0c-3f2e2f9f6c98.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/44836b84-20e5-46bd-be0c-3f2e2f9f6c98.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_02_22.jpg',
            },
            {
              id: '0a9821f2-020e-4e89-8c20-9cf18f0c5053',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/310ddf82-e33e-4bd2-a805-22e5c1ee4287.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/310ddf82-e33e-4bd2-a805-22e5c1ee4287.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_02_06.jpg',
            },
            {
              id: '04d1f47e-f464-4bba-9615-d6a9afa46db4',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/93895440-7845-4cc6-a657-b5450efdf9d7.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/93895440-7845-4cc6-a657-b5450efdf9d7.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_02_19.jpg',
            },
            {
              id: '5ebcbf23-eda6-44d0-a237-dbefedec9677',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                  name: 'practise',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/8bcbe90e-e21f-4903-8cdc-c6c51b0403bd.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/8bcbe90e-e21f-4903-8cdc-c6c51b0403bd.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_02_16.jpg',
            },
            {
              id: 'ae02e1e3-2ab1-4ad3-8a54-2f28d3e380ec',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/a5ca2d63-3815-4ebf-96ee-72e14a29a50c.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/a5ca2d63-3815-4ebf-96ee-72e14a29a50c.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_02_01.jpg',
            },
            {
              id: 'df0064d7-9b5b-4df5-8743-6dd48dadfa89',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/ebabf30b-1627-4bf1-8af7-7bd00c5e6dd1.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/ebabf30b-1627-4bf1-8af7-7bd00c5e6dd1.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_02_20.jpg',
            },
            {
              id: 'ee484c23-7a1a-41ca-9b07-00509b09fa1b',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/8488d79b-293e-4516-bafb-daa685b5b365.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/8488d79b-293e-4516-bafb-daa685b5b365.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_02_14.jpg',
            },
            {
              id: '890837c5-5244-4e8e-b4b4-aa792cecbf3a',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/54a2dc6b-34ac-46e4-a54a-105f8f8989fe.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/54a2dc6b-34ac-46e4-a54a-105f8f8989fe.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_02_10.jpg',
            },
            {
              id: '1e51d43c-c7c9-4fe7-bfae-1e5a1f3ddb27',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/1da8b434-b032-4c7a-ba46-8d8c835c0455.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/1da8b434-b032-4c7a-ba46-8d8c835c0455.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_02_11.jpg',
            },
            {
              id: '16272730-0600-49e9-a278-328c16350a91',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 621,
                  height: 800,
                  key: 'fullSize/3a210908-8c10-4538-a766-dd34059fd242.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/3a210908-8c10-4538-a766-dd34059fd242.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_02_21.jpg',
            },
            {
              id: '162ddc57-137c-4b7e-86e6-3e9e30495659',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/a2727a13-0f43-4e3e-944d-c4ffd92d5184.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/a2727a13-0f43-4e3e-944d-c4ffd92d5184.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_02_18.jpg',
            },
            {
              id: 'ac08b178-5448-4a7d-a151-4b76fa20ee50',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/2498382b-30dc-4b69-8bf0-1b8a179a9832.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/2498382b-30dc-4b69-8bf0-1b8a179a9832.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_02_13.jpg',
            },
            {
              id: '8394a289-aef4-40aa-9256-ad0d890f9af1',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/c06e08b3-93dc-4e68-8221-d7bde540acf1.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/c06e08b3-93dc-4e68-8221-d7bde540acf1.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_02_05.jpg',
            },
            {
              id: '1fe1dc27-d0b7-4c2a-86f2-650a54a3ae51',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/b6dd36fd-8c21-419e-8bb0-39a3d6c97c8a.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/b6dd36fd-8c21-419e-8bb0-39a3d6c97c8a.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_02_09.jpg',
            },
            {
              id: '56e0a0d6-9ff3-4737-8f96-ed8f589f2267',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/2ec6077a-865b-493e-ac89-b511f11ae87f.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/2ec6077a-865b-493e-ac89-b511f11ae87f.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_02_07.jpg',
            },
            {
              id: '36e71c60-17d4-48bb-baa9-b6bffdbe078f',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                },
              ],
              text: null,
              scope: MediaItemSnapshotScope.PUBLIC,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/b030c8a5-d018-4bdb-bdbb-1ff145b8d6cd.jpg',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/b030c8a5-d018-4bdb-bdbb-1ff145b8d6cd.jpg',
                },
              },
              originalFileName: 'dpd_fa_ma_02_04.jpg',
            },
          ],
        },
      ],
    },
    criteriaAttribute: {
      stimuliVariant: 'Text',
      type: 'STIMULI',
      pickingStrategy: 'PERMUTATION',
      categories: [
        {
          name: 'Not Threatening',
          mediaSnaps: [
            {
              id: 'fa749139-0da0-472d-bbb5-1caa5c667a4c',
              tags: [],
              text: 'S. H. sieht immer das Beste in anderen Menschen. In Momenten, in denen andere sich gemein verhalten, überlegt er, was dazu geführt haben könnte.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: 'd1436f37-9af5-4d31-8998-324509988b3c',
              tags: [],
              text: 'U. T. setzt sich für benachteiligte Kinder ein. Er organisiert private Spendengalas, um Nachhilfe und Mittagessen für sozial schwache Schulkinder zu finanzieren.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: '5e77ef54-36db-487a-8978-5bf5f1593491',
              tags: [],
              text: 'G. T. hat ein offenes Ohr für seine Mitmenschen. Viele suchen ihn auf, wenn es ihnen schlecht geht, um mit ihm zu reden.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: '919068e9-b6f8-4b93-ba92-4f68c8e5e477',
              tags: [],
              text: 'K. T. repariert Fahrräder für ein Kinderheim. In einer freien Werkstatt baut er alte Fahrräder um, so dass sie wieder nutzbar sind.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: '08782cf8-9153-4ec8-b848-1a2f204b4f13',
              tags: [],
              text: 'K. D. kümmert sich gut um seine Bekannten. Er fährt auch mal eine Stunde zum Bahnhof, um andere in kalten Winternächten abzuholen.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: '8866b59a-c292-4f25-a547-e1fc4ae8a0ea',
              tags: [],
              text: 'F. T. hilft Obdachlosen auf der Straße. Morgens bringt er heißen Kaffee zu ihrem Schlafplatz nahe seiner Wohnung, damit sie etwas Warmes trinken können.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: '010b6dcf-8d42-4101-bcc7-94322c0dd435',
              tags: [],
              text: 'H. B. bezieht andere in seine Entscheidungen mit ein. Bevor er Dinge beschließt, achtet er darauf, dass alle Beteiligten damit einverstanden sind.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: '3a26749f-baa0-43be-b7b9-651188c24f5e',
              tags: [],
              text: 'G. P. engagiert sich ehrenamtlich gegen Hassnachrichten. Sein Netzwerk unterstützt Betroffene und berät sie, wie sie mit Anfeindungen im Internet umgehen können.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: '943063d5-d79b-4342-85c4-01f312c78ace',
              tags: [],
              text: 'I. P. leitet Anti-Aggressions-Trainings für Jugendliche. In kostenlosen Kursen in Freizeitzentren bringt er ihnen bei mit Gefühlen umzugehen und Konflikte zu lösen.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: '3f65f289-0014-421a-a379-a3932ae4b114',
              tags: [],
              text: 'H. C. hat eine beruhigende Wirkung auf andere. Wenn es Streit gibt, wird er von anderen hinzugezogen, um zwischen den Personen zu schlichten.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: '033f2cce-7da7-4a1d-9eb5-4ba3c07831cb',
              tags: [],
              text: 'J. B. ist für seine Kollegen immer da. Wenn sie krank sind, besucht er sie und bringt Suppe mit, damit sie sich besser fühlen.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: '3446941c-109e-4bd6-9254-5fcdb40cbcaa',
              tags: [],
              text: 'G. B. möchte keinen anderen Lebewesen schaden. Selbst wenn sich Mücken auf ihm niederlassen, wedelt er sie weg, damit sie ihn nicht stechen.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
          ],
        },
        {
          name: 'Threatening',
          mediaSnaps: [
            {
              id: 'd0f5fcf1-bc2b-410c-a74d-26bc35645138',
              tags: [],
              text: 'K. F. ist ein sehr guter Kampfsportler. Wenn er von anderen genervt ist, droht er damit ihnen alle Knochen einzeln zu brechen. ',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: '167651df-7a27-49bc-aac3-1e8ebdbfed9c',
              tags: [],
              text: 'H. B. beschimpft Personen, wenn er sich genervt fühlt. Schon bei Kleinigkeiten wird er laut und wirft anderen heftige Beleidigungen an den Kopf.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: '2b927e6e-b7cc-4249-aa5b-e75f09cf2bf2',
              tags: [],
              text: 'R. V. lässt seinen Arbeitsfrust an anderen aus. Dann schreit er seine Kollegen an und demütigt sie vor den Augen aller anderer.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: '639719b8-7557-4933-a3d5-d47bdde506ea',
              tags: [],
              text: 'F. T. hat seine Kollegen gut im Griff. Wenn er jemanden nicht mag, stiftet er die ganze Belegschaft an, die Person bloßzustellen.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: 'b6bd70b6-5c64-4c2e-98e6-4bcac400042a',
              tags: [],
              text: 'K. J. schikaniert seine Mitarbeiter. Als Abteilungsleiter kommentiert er ihre Arbeit abfällig und lässt sie auch mal vor aller Augen den Boden wischen.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: 'd8c1bcef-7dd5-4fff-a15c-0ecb03d2079c',
              tags: [],
              text: 'L. P. zerstört öffentliches Eigentum. Er zerschlägt mit Steinen die Scheiben von Bushaltestellen und tritt auf der Straße gegen Lampen, Schilder oder Ampeln.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: 'c6b198d3-8ac9-443d-98a0-b84244388a75',
              tags: [],
              text: 'J. P. besprüht regelmäßig die Hauswand seines Nachbarn. Er beschimpft ihn wüst, er solle die Gegend verlassen und droht ihm Gewalt an.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: '94445c2e-fcda-42c6-a8a5-22b3296fc6c0',
              tags: [],
              text: 'B. W. beleidigt andere im Internet. Er schreibt heftige Schimpfwörter in Kommentare und droht damit, private Adressen online zu veröffentlichen.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: 'db435788-e73b-4ab7-b682-c19cf7a0f2ce',
              tags: [],
              text: 'H. F. macht sich lauthals über andere lustig. Dafür kommentiert er öffentlich ihr Aussehen oder ihre Intelligenz, um die Betroffenen zu beschämen.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: '5ca314ef-8e7b-4e37-b9af-b1db1c8dc39d',
              tags: [],
              text: 'G. F. bestimmt alles in seinem Freundeskreis. Wenn er nicht möchte, dass andere Teil davon sind, stiftet er alle an sie auszuschließen.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: 'e2d84104-eac2-43c1-bb93-899b445845c9',
              tags: [],
              text: 'H. K. pöbelt Passanten auf der Straße an. An schlechten Tagen provoziert er andere lauthals und schreit, sie sollen ihm aus dem Weg gehen.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
            {
              id: '7384bad7-be18-4efe-9fc3-edd1dfbb3c04',
              tags: [],
              text: 'W. F. kann sich schwer kontrollieren. Selbst über ganz unbedeutende Missgeschicke wird er so ärgerlich, dass er Gegenstände umherwirft und auch Personen damit trifft.',
              image: null,
              originalFileName: null,
              __typename: 'MediaItemSnapshot',
              scope: MediaItemSnapshotScope.PUBLIC,
            },
          ],
        },
      ],
    },
    additionalAttribute: null,
    groupConstruction: null,
    learningPhase: {
      __typename: 'PresentationPhase',
      instructionsScreen: {
        __typename: 'StyleableTextTemplate',
        desktop:
          '<p><span style="font-family: Arial;">In der folgenden Runde sehen Sie Gesichter und Kurzbeschreibungen von 24 Personen. Davon sind einige weiße und andere arabisch aussehende Männer. </span></p>\n<p><span style="font-family: Arial;">Bilden Sie sich bitte zunächst einen allgemeinen Eindruck von den weißen und den arabisch aussehenden Männern.  </span></p>\n<p>&nbsp;</p>\n<p><span style="font-family: Arial;">In dieser Runde beurteilen Sie, wie sympathisch Sie die Person jeweils finden.  </span></p>\n<p><span style="font-family: Arial;">Drücken Sie nun auf WEITER, um mit der Aufgabe zu beginnen.   </span></p>\n',
        mobile: null,
        fontStyle: null,
      },
      feedbackScreen: {
        __typename: 'StyleableTextTemplate',
        desktop:
          '<p><span style="font-family: Arial;">Die erste Runde haben Sie beendet. Das haben Sie sehr gut gemacht.</span></p>\n<p><span style="font-family: Arial;">Drücken Sie WEITER, um die nächste Runde zu beginnen.  </span></p>\n',
        mobile: null,
        fontStyle: null,
      },
      questionsSet: {
        __typename: 'ConditionalQuestionSet',
        randomizeSequence: false,
        conditionalQuestion: null,
        questionsAfter: [],
        questionsBefore: [
          {
            __typename: 'TestQuestion',
            noAnswer: null,
            type: QuestionType.SCALE,
            questionText: 'Wie sympathisch finden Sie diese Person? ',
            multiple: null,
            scale: {
              __typename: 'ScaleQuestion',
              invert: false,
              min: {
                name: 'gar nicht ',
                value: 1,
                __typename: 'Boundary',
              },
              max: {
                name: 'sehr',
                value: 7,
                __typename: 'Boundary',
              },
            },
            mediaItemSnapshot: null,
            stimulusTimeout: 10,
          },
        ],
      },
      questionOffsetTimeout: 2000,
    },
    testPhase: {
      instructionsScreen: {
        __typename: 'StyleableTextTemplate',
        desktop:
          '<p><span style="font-family: Arial;">In der folgenden Runde sehen Sie die Personen erneut, gemeinsam mit einigen neuen Personen, die Ihnen unbekannt sind. </span></p>\n<p><span style="font-family: Arial;">Nun möchten wir von Ihnen wissen:  </span></p>\n<p><span style="font-family: Arial;">1. Haben Sie die Person zuvor gesehen?  </span></p>\n<p><span style="font-family: Arial;">2. Wenn ja, war diese Person bedrohlich oder nicht bedrohlich? </span></p>\n<p><span style="font-family: Arial;">Bitte konzentrieren Sie sich auch für diese Aufgabe. Bearbeiten Sie sie so gut wie möglich, selbst wenn Sie sich an einige Personen nicht</span><br><span style="font-family: Arial;">erinnern können. In jedem Fall sind Ihre Antworten für die Beantwortung unserer Forschungsfrage wichtig.  </span></p>\n<p><span style="font-family: Arial;">Drücken Sie WEITER, um mit der Aufgabe zu beginnen.  </span></p>\n',
        mobile: null,
        fontStyle: null,
      },
      feedbackScreen: {
        __typename: 'StyleableTextTemplate',
        desktop:
          '<p><span style="font-family: Arial;">Vielen Dank, dass Sie Ihr Bestes gegeben haben! </span></p>\n<p><span style="font-family: Arial;">Viele Personen können sich nur an einige Gesichter und Beschreibungen von Personen erinnern. Ihre Antworten helfen uns herauszufinden, was gut erinnert wird.   </span></p>\n',
        mobile: null,
        fontStyle: null,
      },
      questionsSet: {
        randomizeSequence: true,
        conditionalQuestion: {
          questionText: 'Haben Sie diese Person zuvor gesehen?',
          confirmText: 'Ja',
          declineText: 'Nein',
          answerType: 'CONFIRM',
        },
        questionsBefore: [],
        questionsAfter: [
          {
            noAnswer: null,
            type: 'MULTIPLE_CHOICE',
            questionText: 'War diese Person...',
            multiple: {
              randomized: true,
              options: ['bedrohlich', 'nicht bedrohlich'],
            },
            scale: null,
            mediaItemSnapshot: null,
            stimulusTimeout: 10,
          },
        ],
      },
      questionOffsetTimeout: 500,
    },
    distractionPhase: null,
    startLabel: 'Start',
    nextLabel: 'Weiter',
    finishLabel: 'Weiter',
    createdAt: '2023-07-31T16:20:58.070Z',
    updatedAt: '2023-08-09T13:13:17.285Z',
  } as unknown as Wsw,
];

const initialState: CreateWSWTestInput = {
  name: '',
  instructions: {
    desktop: '',
  },
  endScreen: {
    desktop: '',
  },
  description: '',
  style: createDefaultStyle(),
  status: TestStatus.DRAFT,
  additionalAttribute: {
    type: WSWAttributeType.STIMULI,
    pickingStrategy: PickingStrategy.PERMUTATION,
    categories: [],
  },
  criteriaAttribute: {
    type: WSWAttributeType.STIMULI,
    pickingStrategy: PickingStrategy.PERMUTATION,
    categories: [],
  },
  attributeCombinations: [],
  distractionPhase: {
    questionsSet: {
      questionsBefore: [],
      questionsAfter: [],
      randomizeSequence: true,
    },
  },
  groupConstruction: createEmptyWswGroupConstructions(),
  learningPhase: {
    questionOffsetTimeout: 0,
    questionsSet: {
      randomizeSequence: true,
      questionsBefore: [],
      questionsAfter: [],
    },
  },
  mainAttribute: {
    categories: [],
    pickingStrategy: PickingStrategy.PERMUTATION,
    type: WSWAttributeType.STIMULI,
  },
  testPhase: {
    questionOffsetTimeout: 0,
    feedbackScreen: {
      desktop: '',
    },
    instructionsScreen: {
      desktop: '',
    },
    questionsSet: {
      randomizeSequence: true,
      questionsBefore: [],
      questionsAfter: [],
      conditionalQuestion: {
        answerType: ConditionalAnswerType.CONFIRM,
        confirmText: 'Confirm',
        declineText: 'Decline',
        questionText: '...',
      },
    },
  },
  startLabel: 'Start',
  nextLabel: 'Next',
  finishLabel: 'Finish',
};

export default initialState;
