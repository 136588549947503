import React, { FC } from 'react';
import { Box } from '@mui/material';
import { EditorStepTitle } from '../../../../../components/grid';
import { PresentationPhaseInputComponent } from '../../components/presentationPhase/PresentationPhaseInputComponent';
import * as yup from 'yup';
import { ConditionalQuestionSetSchema } from '../../components/conditionalQuestionSet/ConditionalQuestionSet';
import { OptionalInstructionsSchema } from '../../../../subject/testRunner/screens/OptionalInstructionsScreen';

export const WswTestPhaseStepSchema = yup.object().shape({
  testPhase: yup.object({
    instructionsScreen: OptionalInstructionsSchema,
    feedbackScreen: OptionalInstructionsSchema,
    questionOffsetTimeout: yup
      .number()
      .notRequired()
      .integer('must be integer')
      .nullable()
      .default(undefined),
    questionsSet: ConditionalQuestionSetSchema,
  }),
});

export const WswTestPhaseStep: FC = () => {
  return (
    <Box>
      <EditorStepTitle prefix={6} title="Test Phase" />
      <PresentationPhaseInputComponent
        fieldName="testPhase"
        label="Test Phase"
      />
    </Box>
  );
};
