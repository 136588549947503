import { TestInvocationParams } from '../invocation-params/test-invocation-params';
import { getIdentityFromQueryParams } from '../../testIdentity/testIdentity';
import { getDeviceInfo } from '../../deviceInfo/deviceInfo';
import { getBaseProviderParams } from '../invocation-params/baseProviderParams';
import { getSurveyProviderParams } from '../invocation-params/surveyProviderParams';
import { SurveyContext, TestBaseContext, TestProvider } from './ContextTypes';
import { createBaseTestUrl } from '../../testUrl/createTestUrl';
import { TestMode } from '../TestMode';
import { BaseTestIdentity } from '../../testIdentity/types';
import { FontFace } from '../../../../../../API';
import {
  ContextParameterRandom,
  DefaultParameterRandom,
} from '../../../../../tests/pickingSet/picking-utils';
import { TextMediaStyle, unitFontSize } from '../../../media/text-drawer';
import { SurveyProviderLookupMap } from '../../TestProviderMap';
import {
  createAppConfiguration,
  getInstalledRuntimeAppConfiguration,
  RunnerAppConfiguration,
} from '../app-configuration/runner-app-configuration';
import { createAppConfigSettingsFromInvocationParams } from '../invocation-params/runner-app-configuration-settings';

export function createBaseTestContext(
  params: TestInvocationParams,
  testProviderContext: SurveyContext,
  appConfiguration?: RunnerAppConfiguration,
  contextRandom: ContextParameterRandom = DefaultParameterRandom,
): TestBaseContext {
  const deviceInfo = getDeviceInfo();
  let testIdentity = getIdentityFromQueryParams(params);
  return {
    deviceInfo,
    providerContext: testProviderContext,
    testIdentity,
    appConfiguration:
      appConfiguration ??
      getInstalledRuntimeAppConfiguration() ??
      createAppConfiguration({
        params: createAppConfigSettingsFromInvocationParams(params),
      }),
    logic: {
      deriveTextStyle: (descriptor) => {
        const fontSize = descriptor.fontSize;
        if (deviceInfo.mode === 'mobile') {
          // TODO find suitable defaults for mobile
          return new TextMediaStyle(
            descriptor.fontFace ?? FontFace.ARIAL,
            fontSize ?? unitFontSize(1, 'em'),
            descriptor.color ?? 'black',
          );
        }
        return new TextMediaStyle(
          descriptor.fontFace ?? FontFace.ARIAL,
          fontSize ?? unitFontSize(1, 'em'),
          descriptor.color ?? 'black',
        );
      },
      parameterRandom: contextRandom,
      // TODO fix
      resultEntry: {
        probandId: testIdentity.probandId,
        testProviderName: testProviderContext.providerName ?? 'unknown',
        surveyId:
          testProviderContext.providerType === 'survey-tool'
            ? '' + testProviderContext.surveyId
            : null,
        surveyProbandId:
          testProviderContext.providerType === 'survey-tool'
            ? '' + testProviderContext.surveyProbandId
            : null,
      },
    },
  };
}

export interface SurveyParameterVariableNames {
  surveyId: string;
  surveyProbandId: string;
}

export function createSurveyToolProvider(
  providerName: string,
  variableNames: SurveyParameterVariableNames,
): TestProvider {
  return {
    createTestUrl(
      testIdentity: BaseTestIdentity,
      mode: TestMode,
      debug?: boolean,
      additionalParams?: Record<string, string | string[]>,
    ): string {
      return createBaseTestUrl(this, testIdentity, {
        mode,
        debug: `${debug ?? false}`,
        ...Object.fromEntries(
          Object.entries(variableNames).map(([key, value]) => [
            key,
            { encode: false, value: value },
          ]),
        ),
        ...additionalParams,
      });
    },
    createContext: (
      urlParams: TestInvocationParams,
      surveyProviderLookupMap: SurveyProviderLookupMap,
      strictMode,
      appConfiguration,
    ) =>
      createBaseTestContext(
        urlParams,
        {
          ...getSurveyProviderParams(
            urlParams,
            surveyProviderLookupMap,
            strictMode,
          ),
          providerType: 'survey-tool',
        },
        appConfiguration,
      ),
    providerName,
    providerType: 'survey-tool',
  };
}

export function createStandAloneProvider(providerName: string): TestProvider {
  return {
    createTestUrl(
      testIdentity: BaseTestIdentity,
      mode: TestMode,
      debug?: boolean,
      additionalParams?: Record<string, string | string[]>,
    ): string {
      return createBaseTestUrl(this, testIdentity, {
        mode,
        debug: `${debug ?? false}`,
        ...additionalParams,
      });
    },
    createContext: (
      urlParams: TestInvocationParams,
      surveyProviderLookupMap: SurveyProviderLookupMap,
      _,
      appConfiguration,
    ) =>
      createBaseTestContext(
        urlParams,
        {
          ...getBaseProviderParams(urlParams, surveyProviderLookupMap),
          providerType: 'standalone',
        },
        appConfiguration,
      ),
    providerName,
    providerType: 'standalone',
  };
}
