import React, { FC } from 'react';
import { Box, Typography, TypographyProps } from '@mui/material';

interface TextDisplayProps {
  label?: string;
  labelColor?: string;
  valueColor?: string;
  valueProps?: TypographyProps;
  value?: React.ReactNode;
  suffix?: string | number | JSX.Element;
}

export const TextDisplay: FC<TextDisplayProps> = ({
  label,
  value,
  labelColor,
  valueColor,
  suffix,
  valueProps,
}) => (
  <div>
    <Typography variant="caption" color={labelColor ?? 'text.secondary'}>
      {label && `${label}:`}
    </Typography>
    <Box sx={{ display: 'flex' }}>
      <Typography color={labelColor ?? 'text.primary'} {...valueProps}>
        {value}&nbsp;
      </Typography>
      {typeof suffix === 'string' && (
        <Typography variant="inherit" color="textSecondary">
          {suffix}
        </Typography>
      )}
      {typeof suffix !== 'string' && suffix}
    </Box>
  </div>
);
