import { TestBaseContext } from '../state/baseTestContext/context/ContextTypes';
import { KeyboardSettings } from '../../../tests/types';
import { ControlInputMonitor } from './control-monitor';
import {
  ControlHandlerManager,
  DOMEventControlHandler,
  KeyControlHandler,
  TimeoutControlHandler,
} from './control-handlers';
import { OptionsStereotypes } from './control-stereotypes';

// handles the options control for left and right
export function CreateOptionTestsControlHandlerFactory(
  context: TestBaseContext,
  keyboardSettings: KeyboardSettings,
) {
  return (inputMonitor: ControlInputMonitor) => {
    const uiEventHandler = new DOMEventControlHandler(
      inputMonitor,
      OptionsStereotypes.Confirm.UI,
    );
    const confirmKeyHandler =
      context.deviceInfo.mode === 'desktop'
        ? new KeyControlHandler(
            inputMonitor,
            OptionsStereotypes.Confirm.Control as any,
            keyboardSettings.confirm,
          )
        : new DOMEventControlHandler(
            inputMonitor,
            OptionsStereotypes.Confirm.Control as any,
          );
    const leftKeyHandler =
      context.deviceInfo.mode === 'desktop'
        ? new KeyControlHandler(
            inputMonitor,
            OptionsStereotypes.Side.Left as any,
            keyboardSettings.left,
          )
        : new DOMEventControlHandler(
            inputMonitor,
            OptionsStereotypes.Side.Left as any,
          );
    const rightKeyHandler =
      context.deviceInfo.mode === 'desktop'
        ? new KeyControlHandler(
            inputMonitor,
            OptionsStereotypes.Side.Right as any,
            keyboardSettings.right,
          )
        : new DOMEventControlHandler(
            inputMonitor,
            OptionsStereotypes.Side.Right as any,
          );
    const timeoutHandler = new TimeoutControlHandler(
      inputMonitor,
      OptionsStereotypes.TimeoutEvent,
    );
    return new ControlHandlerManager([
      uiEventHandler,
      confirmKeyHandler,
      leftKeyHandler,
      rightKeyHandler,
      timeoutHandler,
    ]);
  };
}
