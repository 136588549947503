import React, { FC } from 'react';
import { TestType } from '../../subject/types';
import TestAdmin from '../testAdmin/TestAdmin';
import { PodtBlockSettingsView } from '../PODT/view/steps/PodtBlockSettingsView';
import {
  createPodtaAsync,
  deletePodtaAsync,
  deletePodtaResultsAsync,
  fetchPodtaResults,
  fetchPodtaTest,
  fetchPodtaTests,
  updatePodtaAsync,
  updatePodtaStatusAsync,
} from './redux/podta.thunk';
import PodtStimuliSettings, {
  PodtStimuliSettingsSchema,
} from '../PODT/creator/steps/PodtStimuliSettings';
import PodtGameSettings, {
  PodtGameSettingsSchema,
} from '../PODT/creator/steps/PodtGameSettings';
import { PodtGameSettingsView } from '../PODT/view/steps/PodtGameSettingsView';
import PodtBlockSettings, {
  PodtBlockSettingsSchema,
} from '../PODT/creator/steps/PodtBlockSettings';
import { PodtStimuliSettingsView } from '../PODT/view/steps/PodtStimuliSettingsView';
import { PodtSceneBoxesValidationSchema } from '../PODT/creator/steps/scenes/PodtSceneSettings';
import {
  InitialPodtaCreatorState,
  podtaTemplates,
} from './creator/initialState';
import { PodtaSceneSettings } from './creator/steps/PodtaSceneSettings';
import { PodtaSceneSettingsView } from './view/PodtaSceneSettingsView';
import { Podt } from '../PODT/types';
import { Podta } from './types';

const PodtaAdmin: FC = () => (
  <TestAdmin
    templates={podtaTemplates}
    type={TestType.PODTA}
    thunks={{
      loadTest: fetchPodtaTest,
      loadTests: fetchPodtaTests,
      createTest: createPodtaAsync,
      updateTest: updatePodtaAsync,
      updateTestStatus: updatePodtaStatusAsync,
      deleteTest: deletePodtaAsync,
    }}
    results={{
      load: fetchPodtaResults,
      deleteItems: deletePodtaResultsAsync,
    }}
    steps={[
      {
        name: 'Test',
        editor: {
          component: () => <PodtGameSettings counterbalancing={false} />,
          validationSchema: PodtGameSettingsSchema,
        },
        view: (props) => <PodtGameSettingsView test={props.test as Podt} />,
      },
      {
        name: 'Block',
        editor: {
          component: PodtBlockSettings,
          validationSchema: PodtBlockSettingsSchema,
        },
        view: (props) => <PodtBlockSettingsView test={props.test as Podt} />,
      },
      {
        name: 'Stimuli',
        editor: {
          component: () => <PodtStimuliSettings podta={true} />,
          validationSchema: PodtStimuliSettingsSchema,
        },
        view: (props) => <PodtStimuliSettingsView test={props.test as Podt} />,
      },
      {
        name: 'Scene',
        editor: {
          component: PodtaSceneSettings,
          validationSchema: PodtSceneBoxesValidationSchema,
        },
        view: (props) => <PodtaSceneSettingsView test={props.test as Podta} />,
      },
    ]}
    editor={{
      initialState: InitialPodtaCreatorState,
    }}
  />
);

export default PodtaAdmin;
