import { TestType } from '../../../subject/types';
import React from 'react';
import { io } from 'socket.io-client';
import { Socket } from 'socket.io-client';
import { TestResultsStatus } from '../../../../API';

export type ResultGeneratorStatus = {
  testId: string;
  testType: TestType;
  testStatusEntry: TestResultsStatus | null;
  changed: string;
} & (
  | { status: 'queued' }
  | { status: 'not-found' }
  | { status: 'ready'; generated: string }
  | {
      status: 'generating';
      progress: {
        rowCount: number;
        rowsDone: number;
        fileSize: number | null;
        uploadProgress: number | null;
      };
    }
  | { status: 'failed'; error: string }
);
export type TestResultsStatusContextType = {
  subscribeTest(
    testType: TestType,
    testId: string,
    listener: (status: ResultGeneratorStatus) => void,
  ): () => void;
};

const TestResultsStatusContext =
  React.createContext<TestResultsStatusContextType>({
    subscribeTest: () => () => {},
  });

type SocketSubscription = {
  testType: TestType;
  testId: string;
  listener: (status: ResultGeneratorStatus) => void;
};

type SocketSubscriptionController = {
  subscriptions: SocketSubscription[];
  socket: Socket | null;
};
export function TestResultsStatusContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [subscriptionController, setSubscriptionController] =
    React.useState<SocketSubscriptionController>();
  React.useEffect(() => {
    console.log('subscribe to socket in context');
    const socket = io(process.env.REACT_APP_RESULTS_DOWNLOADER_URL ?? '', {
      path: '/results-generator-status',
      autoConnect: true,
      reconnection: true,
    });
    socket.connect();
    const socketController: SocketSubscriptionController = {
      socket: null,
      subscriptions: [],
    };
    socket.on('connect', () => {
      socketController.socket = socket;
      socketController.subscriptions.forEach((sub) => {
        socket.emit('subscribe-test-status', {
          testId: sub.testId,
          testType: sub.testType,
        });
      });
    });
    socket.on('status-update', (status: ResultGeneratorStatus) => {
      socketController.subscriptions.forEach((sub) => {
        if (sub.testId === status.testId && sub.testType === status.testType) {
          sub.listener(status);
        }
      });
    });
    setSubscriptionController(socketController);
    return () => {
      console.log('unsubscribe from socket in context');
      socket.disconnect();
    };
  }, []);
  if (!subscriptionController) {
    return <></>;
  }
  return (
    <TestResultsStatusContext.Provider
      value={{
        subscribeTest: (
          testType: TestType,
          testId: string,
          listener: (status: ResultGeneratorStatus) => void,
        ): (() => void) => {
          subscriptionController.subscriptions.push({
            testType,
            testId,
            listener,
          });
          if (subscriptionController.socket !== null) {
            subscriptionController.socket.emit('subscribe-test-status', {
              testId,
              testType,
            });
          }
          return () => {
            subscriptionController.subscriptions =
              subscriptionController.subscriptions.filter(
                (sub) => sub.testId !== testId && sub.testType !== testType,
              );
            if (subscriptionController.socket !== null) {
              subscriptionController.socket.emit('unsubscribe-test-status', {
                testId,
                testType,
              });
            }
          };
        },
      }}
    >
      {children}
    </TestResultsStatusContext.Provider>
  );
}

export function useTestResultSubscription() {
  return React.useContext(TestResultsStatusContext);
}
