import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import CodeSnippet from '../../components/SetupInstructions/CodeSnippet';
import { createCodeSnippet } from '../../components/SetupInstructions/utils';
import { TestType } from '../subject/types';

export type ShareCodeSnippetScreenQueryArgs = {
  testType: TestType;
  testUrl: string;
};

export function ShareCodeSnippetScreen() {
  const query = new URLSearchParams(useLocation().search);
  const params: ShareCodeSnippetScreenQueryArgs = {
    testType: query.get('testType') as TestType,
    testUrl: query.get('testUrl') as string,
  };
  const testName = query.get('testName') ?? 'Unknown Test';
  return (
    <Container
      sx={{
        pt: 8,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Card>
        <CardHeader
          title={`Code Snippet for ${params.testType} Test ${testName}`}
        >
          Share Code Snippet
        </CardHeader>
        <CardContent>
          <Stack spacing={2}>
            <Typography>
              Copy the code below and insert it into your survey
            </Typography>
            {!params.testUrl || !params.testType ? (
              <Typography color={(theme) => theme.palette.error.main}>
                Invalid share link
              </Typography>
            ) : (
              <CodeSnippet
                codeSnippet={createCodeSnippet(params.testType, params.testUrl)}
              />
            )}
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
}
