import { TransitionModifier } from './transition-modifiers';
import { GQLQueryInstance } from '../../../../GQL';
import { SliceSnapshot, StateHolder } from './state-holder';
import { ControlOccurrence } from '../controls/control-stereotypes';
import { ReactionTimeProcessor } from '../controls/reaction-time-processor';

export interface TransitionAction {
  readonly transitionModifier: TransitionModifier;
  readonly result?: GQLQueryInstance<any, any>;
  readonly stateModifications: SliceSnapshot<any>[];
}

export interface ControlOccurrenceProcessor<Trigger> {
  occurrence: ControlOccurrence<Trigger>;
  reactionTimeProcessor: ReactionTimeProcessor;
}

export class DefaultTransitionAction implements TransitionAction {
  constructor(
    public readonly transitionModifier: TransitionModifier,
    public readonly stateModifications: SliceSnapshot<any>[],
    public readonly result?: GQLQueryInstance<any, any>,
  ) {}

  static next(
    modifier: TransitionModifier,
    ...states: SliceSnapshot<any>[]
  ): TransitionAction {
    return new DefaultTransitionAction(modifier, states);
  }
  static result(
    modifier: TransitionModifier,
    resultQuery: GQLQueryInstance<any, any>,
    ...states: SliceSnapshot<any>[]
  ): TransitionAction {
    return new DefaultTransitionAction(modifier, states, resultQuery);
  }
}

export interface TransitionActionFactory<Trigger> {
  instanceAction(
    control: ControlOccurrenceProcessor<Trigger>,
    stateHolder: StateHolder,
  ): TransitionAction;
}

export function createActionFactory<Trigger>(
  fun: (control: ControlOccurrenceProcessor<Trigger>) => TransitionAction,
): TransitionActionFactory<Trigger> {
  return {
    instanceAction(
      control: ControlOccurrenceProcessor<Trigger>,
    ): TransitionAction {
      return fun(control);
    },
  };
}
