import { TestResourceAccessor } from '../../../../subject/testRunner/media/test-resource-loader';
import { Iat } from '../../types';
import { TextStyleDescriptor } from '../../../../subject/testRunner/media/text-drawer';
import {
  AnnotatedDataPipe,
  MetaDataMediaSelector,
} from '../../../../subject/testRunner/media/drawable/drawable-collector';
import {
  DrawableMediaInstance,
  transformMediaItemData,
} from '../../../../subject/testRunner/media/MediaData';
import {
  BlockType,
  MediaItemSnapshot,
  StyleableTextTemplate,
} from '../../../../../API';

/*
transforms all MediaItemSnapshots in an IAT Test Model in DrawableMediaInstances and annotates them with meta values (e.g. category or pool) in AnnotatedPools
 */
export function LoadIatMedia(accessor: TestResourceAccessor<Iat>) {
  const factoryMap = buildFactoryMap(accessor);

  const createMediaStruct = (type: 'test' | 'preview') =>
    MetaDataMediaSelector.of(accessor.modelData)
      .select('category', {
        attributeCategories: 'attribute',
        targetCategories: 'target',
      } as const)
      .select('variant', { categoryA: 'first', categoryB: 'second' } as const)
      .nestSelectors((subSelect, root: MetaDataMediaSelector<Iat, {}>) => ({
        options: {
          categories: subSelect()
            .selectRaw('label')
            .splitMetaKey('category', {
              attribute: (h) =>
                h
                  .mapValues((v) =>
                    factoryMap.attribute[type].createInstance(
                      transformMediaItemData(v as MediaItemSnapshot),
                    ),
                  )
                  .collectMappedFirst('variant'),
              target: (h) =>
                h
                  .mapValues((v) =>
                    factoryMap.target[type].createInstance(
                      transformMediaItemData(v as MediaItemSnapshot),
                    ),
                  )
                  .collectMappedFirst('variant'),
            }),
          divider: root
            .selectRaw('categoryDivider')
            .mapValues((v) => {
              const template = { ...v };
              if (template.fontStyle) {
                if (accessor.testContext.deviceInfo.mode === 'mobile') {
                  template.fontStyle.fontSize = 13;
                }
              }
              return accessor.loadStyledText(
                template as StyleableTextTemplate,
                'drawable',
              ) as DrawableMediaInstance;
            })
            .collectFirst(),
        },
        presentations: AnnotatedDataPipe.of(
          subSelect()
            .select('blockType', {
              testStimuliPool: BlockType.NON_PRACTICE,
            } as const)
            .selectMap('index', (d) => d.map((v, i) => [v, i]))
            .mapValues(
              (v) =>
                factoryMap.stimuli[type].createInstance(
                  transformMediaItemData(v as MediaItemSnapshot),
                ) as DrawableMediaInstance,
            )
            .collect(),
        ),
      }));

  return {
    test: createMediaStruct('test'),
  };
}

export type IatMediaPool = ReturnType<typeof LoadIatMedia>;

function createFactories(
  accessor: TestResourceAccessor<Iat>,
  textStyle: TextStyleDescriptor,
) {
  return {
    test: accessor.createDrawableFactory('fullSize', textStyle),
    preview: accessor.createDrawableFactory('thumbNail', textStyle),
  };
}

function createFactoryMap(
  accessor: TestResourceAccessor<Iat>,
  styles: Record<'attribute' | 'target' | 'stimuli', TextStyleDescriptor>,
) {
  return {
    attribute: createFactories(accessor, styles.attribute),
    target: createFactories(accessor, styles.target),
    stimuli: createFactories(accessor, styles.stimuli),
  };
}

function buildFactoryMap(accessor: TestResourceAccessor<Iat>) {
  return createFactoryMap(accessor, {
    attribute: TextStyleDescriptor.ofFontStyle(
      accessor.testContext.deviceInfo.mode === 'desktop'
        ? accessor.modelData.attributeCategories.fontStyle
        : { ...accessor.modelData.attributeCategories.fontStyle, fontSize: 15 },
    ),
    target: TextStyleDescriptor.ofFontStyle(
      accessor.testContext.deviceInfo.mode === 'desktop'
        ? accessor.modelData.targetCategories.fontStyle
        : { ...accessor.modelData.targetCategories.fontStyle, fontSize: 15 },
    ),
    stimuli: TextStyleDescriptor.ofFontStyle(
      accessor.modelData.style.stimulusTextStyle,
    ),
  });
}
