import { Check, HourglassEmpty, PlayArrow } from '@mui/icons-material';
import React from 'react';
import {
  Box,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { Subject } from 'rxjs';
import { TestLoadingState } from '../../loading-state';
import { TestBaseContext } from '../../state/baseTestContext/context/ContextTypes';
import { Alert } from '@mui/lab';
import { useRunnerAppTranslator } from '../../state/baseTestContext/app-configuration/translations/translator.hook';
import { translations } from '../../state/baseTestContext/app-configuration/translations/translation-builder';
import { Translations } from '../../state/baseTestContext/app-configuration/translations/app-translations';

function PhaseProgressDisplay({
  state,
}: {
  state: {
    type: 'active';
    progress?: undefined | { current: number; total: number };
  };
}) {
  return (
    <Box display="flex" width="100%" alignItems="center">
      <LinearProgress style={{ flexGrow: 1 }} />
      {state.progress && (
        <Typography variant="caption">
          {state.progress.current}/{state.progress.total}
        </Typography>
      )}
    </Box>
  );
}

const StateIconMap = {
  done: <Check />,
  pending: <HourglassEmpty />,
  active: <PlayArrow />,
} as const;

function TestLoadingPhase({
  description,
  state,
}: {
  description: string;
  state:
    | { type: 'done' | 'pending' }
    | {
        type: 'active';
        progress?: undefined | { current: number; total: number };
      };
}) {
  return (
    <ListItem>
      <ListItemAvatar>{StateIconMap[state.type]}</ListItemAvatar>
      <ListItemText
        primary={description}
        secondaryTypographyProps={{
          component: 'div',
        }}
        secondary={
          state.type === 'active' ? (
            <PhaseProgressDisplay state={state} />
          ) : undefined
        }
      />
    </ListItem>
  );
}

export function TestLoadingSpinner({
  stateStream,
  testContext,
}: {
  stateStream: Subject<TestLoadingState>;
  testContext: TestBaseContext;
}) {
  const [loadingState, setLoadingState] = React.useState<TestLoadingState>();
  const screenTranslator = useRunnerAppTranslator(
    Translations.App.Loading.TestLoadingScreen,
  );
  console.log(screenTranslator, Translations.App.Loading.TestLoadingScreen);
  React.useEffect(() => {
    const sub = stateStream.subscribe((value) => setLoadingState(value));
    return () => sub.unsubscribe();
  }, [stateStream]);
  return (
    <Box
      width="100%"
      height="100%"
      justifyContent="center"
      display="flex"
      alignItems="center"
    >
      <Box>
        {testContext.providerContext.mode === 'preview' && (
          <Box sx={{ margin: '1em' }}>
            <Alert severity="warning">
              {screenTranslator.previewModeWarning.mapTranslation((text) => (
                <>
                  {text}
                  <br />
                </>
              ))}
            </Alert>
          </Box>
        )}
        {screenTranslator.instructions.translate().flatMap((paragraphs) =>
          paragraphs
            .map((text) => (
              <Typography variant="body1" textAlign="center">
                {text}
              </Typography>
            ))
            .concat([<br />]),
        )}
        <TestLoadingPhase
          description={screenTranslator.phases.data.translate().join(' / ')}
          state={{ type: loadingState?.stage === 'data' ? 'active' : 'done' }}
        />
        <TestLoadingPhase
          description={screenTranslator.phases.media.translate().join(' / ')}
          state={
            loadingState?.stage === 'media'
              ? {
                  type: 'active',
                  progress: {
                    current: loadingState.loaded,
                    total: loadingState.total,
                  },
                }
              : { type: loadingState?.stage === 'arrange' ? 'done' : 'pending' }
          }
        />
        <TestLoadingPhase
          description={screenTranslator.phases.arrange.translate().join(' / ')}
          state={{
            type: loadingState?.stage === 'arrange' ? 'active' : 'pending',
          }}
        />
      </Box>
    </Box>
  );
}
/*
const testLoadingScreenTranslationScope = StaticTranslationScope.define(
  'TestLoadingScreen',
  'Test Loading Screen',
);
const testLoadingScreenTranslations = {
  previewModeWarning: testLoadingScreenTranslationScope.textTranslation(
    'previewModeWarning',
    {
      en: 'This test is executed in preview mode. No Results will be persisted',
      de: 'Dieser Test wird im Previewmodus ausgeführt. Es werden keine Ergebnisse gespeichert.',
    },
  ),
  instructions: testLoadingScreenTranslationScope.textArrayTranslation(
    'instructions',
    {
      en: [
        'This experiment is loading and might take some time.',
        'Please do not close this window.',
      ],
      de: [
        'Das Experiment wird geladen, was einige Zeit (teilweise wenige Minuten) in Anspruch nehmen kann.',
        'Bitte schließen Sie dieses Fenster nicht.',
      ],
    },
    'Initial instructions',
  ),
  phases: {
    data: testLoadingScreenTranslationScope.textTranslation(
      'phases.data',
      {
        en: 'Preparing experiment',
        de: 'Experiment vorbereiten',
      },
      'Loading Phase Prepare Data',
    ),
    media: testLoadingScreenTranslationScope.textTranslation(
      'phases.media',
      {
        en: 'Loading stimuli',
        de: 'Lade Stimuli',
      },
      'Stimuli Downloads',
    ),
    arrange: testLoadingScreenTranslationScope.textTranslation(
      'phases.arrange',
      {
        en: 'Finalizing',
        de: 'Finalisiere',
      },
      'Finalizing Test Setup',
    ),
  },
};*/

export const TestLoadingScreenTranslations = translations.scope(
  'TestLoadingScreen',
  {
    previewModeWarning: translations.text(
      'Warning that test is executed in Preview Mode',
      {
        en: 'This test is executed in preview mode. No Results will be persisted',
        de: 'Dieser Test wird im Previewmodus ausgeführt. Es werden keine Ergebnisse gespeichert.',
      },
    ),
    instructions: translations.textList('Initial instructions', {
      en: [
        'This experiment is loading and might take some time.',
        'Please do not close this window.',
      ],
      de: [
        'Das Experiment wird geladen, was einige Zeit (teilweise wenige Minuten) in Anspruch nehmen kann.',
        'Bitte schließen Sie dieses Fenster nicht.',
      ],
    }),
    phases: translations.scope('Loading Phases', {
      data: translations.text('Loading Phase Prepare Data', {
        en: 'Preparing experiment',
        de: 'Experiment vorbereiten',
      }),
      media: translations.text('Stimuli Downloads', {
        en: 'Loading stimuli',
        de: 'Lade Stimuli',
      }),
      arrange: translations.text('Finalizing Test Setup', {
        en: 'Finalizing',
        de: 'Finalisiere',
      }),
    }),
  },
);
