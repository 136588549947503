import React, { FC } from "react";
import { PresentationPhase, WSWTest } from "../../../../../API";
import { WswPresentationPhaseView } from "./WswPresentationPhaseView";

interface WswDistractionPhaseViewProps {
  test: WSWTest;
}

export const WswDistractionPhaseView: FC<WswDistractionPhaseViewProps> = (props) => {
  return (
    <React.Fragment>
      {props.test.distractionPhase &&
        <WswPresentationPhaseView
          phase={props.test.distractionPhase as unknown as PresentationPhase}
          label={"Distractionphase"}
        />}
    </React.Fragment>
  );
};
