import {
  createSlice,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import { Iat, IatResult } from '../types';
import {
  createIatAsync,
  deleteIatAsync,
  fetchIatResults,
  fetchIatTest,
  fetchIatTests,
  updateIatAsync,
  updateIatStatusAsync,
} from './iat.thunk';
import { TestType } from '../../../subject/types';
import { createInitialTestSliceState } from '../../utils';

export const iatSlice = createSlice({
  name: TestType.IAT,
  initialState: createInitialTestSliceState<Iat, IatResult>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIatTest.fulfilled, (state, action) => {
        state.tests[action.payload.id] = action.payload;
      })
      .addCase(fetchIatTests.fulfilled, (state, action) => {
        state.tests = action.payload
          .sort(
            (a, b) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
          )
          .reduce((result: { [id: string]: Iat }, currentValue: Iat | null) => {
            if (currentValue) {
              result[currentValue.id] = currentValue;
            }
            return result;
          }, {});
      })
      .addCase(fetchIatResults.fulfilled, (state, action) => {
        state.results[action.payload.testId] = action.payload;
      })
      .addCase(deleteIatAsync.fulfilled, (state, action) => {
        const tests = { ...state.tests };
        delete tests[action.payload];
        state.tests = tests;
      })
      /* .addCase(deleteIatResultsAsync.fulfilled, (state, action) => {*/
      // if (state.results[action.payload.testId]) {
      //   state.results[action.payload.testId].results = state.results[action.payload.testId].results.filter(
      //     (r) => !action.payload.resultsIds.includes(r.id)
      //   );
      //   state.results[action.payload.testId].tableView.results = state.results[
      //     action.payload.testId
      //   ].tableView.results.filter((r) => !action.payload.resultsIds.includes(r.id));
      // }
      /*})*/
      .addMatcher(
        isAnyOf(
          createIatAsync.fulfilled,
          updateIatAsync.fulfilled,
          updateIatStatusAsync.fulfilled,
        ),
        (state, action) => {
          state.tests[action.payload.id] = action.payload;
        },
      )
      .addMatcher(isPending(), (state, action) => {
        if (action.type.toUpperCase().includes(TestType.IAT)) {
          state.isLoading = true;
        }
      })
      .addMatcher(isFulfilled(), (state) => {
        state.isLoading = false;
      })
      .addMatcher(isRejected(), (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

export default iatSlice.reducer;
