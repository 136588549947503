import { DrawableMediaInstance } from '../../../../subject/testRunner/media/MediaData';
import {
  PodtaBoxInput,
  PodtaBoxPosition,
  PodtBoxInput,
} from '../../../../../API';
import { Podt } from '../../types';
import { Podta } from '../../../PODTA/types';

export class PodtSceneBox {
  constructor(
    public readonly left: number,
    public readonly top: number,
    public readonly width: number,
    public readonly height: number,
    public readonly side?: PodtaBoxPosition,
  ) {}

  toResultBox(): PodtBoxInput | PodtaBoxInput {
    return this.side
      ? {
          height: this.height,
          left: this.left,
          top: this.top,
          width: this.width,
          position: this.side,
        }
      : {
          height: this.height,
          left: this.left,
          top: this.top,
          width: this.width,
        };
  }

  static of(box: Podt['scenes']['scenes'][number]['boxes'][number]) {
    return new PodtSceneBox(box.left, box.top, box.width, box.height);
  }

  static ofPodta(box: Podta['scenes']['scenes'][number]['boxes'][number]) {
    return new PodtSceneBox(
      box.left,
      box.top,
      box.width,
      box.height,
      box.position,
    );
  }
}

export class PodtBaseSceneMedia {
  constructor(public readonly instance: DrawableMediaInstance) {}
}

export class PodtSceneMedia extends PodtBaseSceneMedia {
  constructor(
    instance: DrawableMediaInstance,
    public readonly boxes: PodtSceneBox[],
  ) {
    super(instance);
  }
}
