import * as yup from 'yup';
import {
  CreateSurveyProviderInput,
  SurveyProviderSnippetType,
  UpdateSurveyProviderInput,
} from '../../API';
import { Form, Formik, useField } from 'formik';
import {
  Box,
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import StyledTextInput from '../../components/input/StyledTextInput';
import { LoadingIcon } from '../../components/utils/LoadingIcon';
import React from 'react';
import { FormikNullableCheckbox } from '../../components/input/FormikNullableCheckbox';
import { InfoOutlined } from '@mui/icons-material';
import { TransitionGroup } from 'react-transition-group';
import { camelCase } from 'lodash';
import { SurveyProviderDocumentationStepsContainer } from './SurveyProviderDocumentationStepsContainer';

const surveyProviderValidationSchema = yup.object<CreateSurveyProviderInput>({
  name: yup.string().min(2).required(),
  description: yup.string(),
  snippetConfiguration: yup
    .object({
      snippetType: yup
        .mixed()
        .oneOf(Object.values(SurveyProviderSnippetType))
        .required(),
      probandIdReplacer: yup.string().min(3).required(),
      surveyIdReplacer: yup.string().min(3).nullable().default(null),
    })
    .required(),
});

function SnippetSurveyIdInput() {
  const [, { value: surveyIdReplacer }, helpers] = useField(
    'snippetConfiguration.surveyIdReplacer',
  );

  return (
    <>
      <Box sx={{ pl: 1 }}>
        <FormikNullableCheckbox
          label={'Survey ID Variable'}
          onChange={(checked) => helpers.setValue(checked ? '' : null)}
          value={surveyIdReplacer !== null}
        />
        <Tooltip
          placement={'right'}
          title={`In case this survey provider provides a variable for the survey/test id, you can provide it's name here. Otherwise the survey id will be asked for in the setup process and included in the code-snippet`}
        >
          <InfoOutlined
            sx={{
              color: 'warning.dark',
              bgcolor: 'white',
              position: 'relative',
              // left: '-1em',
            }}
          />
        </Tooltip>
      </Box>
      <TransitionGroup>
        {surveyIdReplacer !== null && (
          <Collapse>
            <StyledTextInput
              name={'snippetConfiguration.surveyIdReplacer'}
              label={'Survey ID Variable'}
            />
          </Collapse>
        )}
      </TransitionGroup>
    </>
  );
}

function SurveyProviderNameSlugInput({ generate }: { generate: boolean }) {
  const [, slugMeta, slugHelpers] = useField('slug');
  const [, , nameHelpers] = useField('name');
  return (
    <>
      <StyledTextInput
        required
        name={'name'}
        label={'Name'}
        onChange={(value) => {
          const stringValue =
            typeof value === 'string' ? value : value.target.value;
          if (generate) {
            slugHelpers.setValue(camelCase(stringValue));
          }
          nameHelpers.setValue(stringValue);
        }}
      />
      <StyledTextInput
        name={'slug'}
        disabled
        toolTip="The slug is used internally for technical reasons and is generated from the initial name of this provider"
        label={slugMeta.value ? '' : 'Slug'}
        required
      />
    </>
  );
}

export function SurveyProviderForm({
  onSave,
  onCancel,
  loading,
  actionLabel,
  initialValues,
}: {
  onSave: (input: CreateSurveyProviderInput) => Promise<unknown>;
  loading: boolean;
  actionLabel: string;
  onCancel: () => void;
  initialValues?: Partial<UpdateSurveyProviderInput>;
}) {
  return (
    <Formik<CreateSurveyProviderInput>
      initialValues={
        {
          name: '',
          description: '',
          snippetConfiguration: {
            snippetType: SurveyProviderSnippetType.IFRAME,
            probandIdReplacer: '',
            surveyIdReplacer: null,
          },
          setupDocumentation: { steps: [] },
          slug: '',
          ...initialValues,
        } as CreateSurveyProviderInput
      }
      validationSchema={surveyProviderValidationSchema}
      onSubmit={onSave}
    >
      {(props) => (
        <Form>
          <DialogContent>
            <Stack spacing={2}>
              <SurveyProviderNameSlugInput generate={!initialValues?.slug} />
              <StyledTextInput
                name={'description'}
                label={'Description'}
                multiline
                rows={2}
              />
              <Divider />
              <div>
                <Typography variant="h5">Variables-Configuration</Typography>
                <Typography variant="body2" color="text.secondary">
                  The survey provider will replace the variables i.e.
                  #probandId# and "#surveyId#" with the actual values of the
                  proband and survey id.
                  <br />
                  MIND.set will then be able to identify the respondent and the
                  survey.
                </Typography>
              </div>
              <SnippetSurveyIdInput />
              <StyledTextInput
                required
                name={'snippetConfiguration.probandIdReplacer'}
                label={'Proband ID Variable'}
              />
              <Divider />
              <Typography variant="h5">Setup Instructions</Typography>
              <SurveyProviderDocumentationStepsContainer />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={loading}
              onClick={onCancel}
              variant="contained"
              color="inherit"
            >
              Cancel
            </Button>
            <Button
              disabled={loading || !props.isValid}
              startIcon={loading ? <LoadingIcon /> : undefined}
              variant="contained"
              onClick={() => props.submitForm()}
            >
              {actionLabel}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}
