import {
  DataGridPro,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import {
  BroadcastMail,
  BroadcastMailStatus,
  FunctionBodyResponse,
  ListBroadcastMailsQueryVariables,
  SendBroadcastMailMutationVariables,
  UpdateBroadcastMailMutationVariables,
} from '../../API';
import { Chip, IconButton } from '@mui/material';
import { Cached } from '@mui/icons-material';
import React from 'react';
import { GQLQuery, NewGQL } from '../../GQL';
import { listBroadcastMails } from '../../graphql/queries';
import { formatDate } from '../../dateUtils';
import {
  BroadcastMailDialog,
  CreateBroadcastMailDialogButton,
} from './BroadcastMailDialog';
import {
  sendBroadcastMail,
  updateBroadcastMail,
} from '../../graphql/mutations';
import { enqueueSnackbar } from 'notistack';

const columns: GridColDef<BroadcastMail>[] = [
  {
    headerName: 'Sended',
    type: 'date',
    field: 'createdAt',
    minWidth: 150,
    renderCell: (params) =>
      params.row.status === BroadcastMailStatus.SENT ? (
        formatDate(params.value as string)
      ) : (
        <Chip label={'Draft'} size="small" color="info" />
      ),
  },
  {
    headerName: 'Sender',
    field: 'sender',
  },
  {
    headerName: 'Subject',
    field: 'subject',
    flex: 1,
  },
  {
    headerName: 'Message',
    field: 'content',
    flex: 3,
  },
  { headerName: 'ID', field: 'id', flex: 1 },
];
export function BroadcastMailHistoryTable() {
  const [selected, setSelected] = React.useState<BroadcastMail>();
  const [data, setData] = React.useState<BroadcastMail[]>();

  const fetchData = React.useCallback(() => {
    NewGQL.DEFAULT_CLIENT.execute(
      GQLQuery.List<ListBroadcastMailsQueryVariables, BroadcastMail>(
        listBroadcastMails,
      ).create({}),
    ).then((result) => {
      setData(result);
    });
  }, []);
  React.useEffect(() => {
    if (!data) {
      fetchData();
    }
  }, [data, fetchData]);

  return (
    <>
      <DataGridPro
        disableSelectionOnClick
        loading={false}
        getRowId={(row) => row.id}
        columns={columns}
        onRowClick={(params) => {
          if (params.row.status === BroadcastMailStatus.DRAFT) {
            setSelected(params.row);
          }
        }}
        initialState={{
          columns: {
            columnVisibilityModel: { id: false, updatedAt: false },
          },
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }],
          },
          filter: {
            /*          filterModel: {
            items: [
              {
                columnField: 'status',
                operatorValue: 'equals',
                value: 'PENDING',
              },
            ],
          },*/
          },
        }}
        rows={data ?? []}
        components={{
          Toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarFilterButton
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
                placeholder=""
                sx={{ m: 1 }}
              />
              <GridToolbarColumnsButton
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
                placeholder=""
              />
              <CreateBroadcastMailDialogButton
                refresh={() => {
                  fetchData();
                }}
              />
              <IconButton
                onClick={() => {
                  fetchData();
                }}
                sx={{ ml: 'auto' }}
              >
                <Cached />
              </IconButton>
            </GridToolbarContainer>
          ),
        }}
      />
      {selected && (
        <BroadcastMailDialog
          cancel={() => setSelected(undefined)}
          initialValue={{
            subject: selected.subject,
            sender: selected.sender,
            content: selected.content,
            id: selected.id,
          }}
          saveDraft={(mail) => {
            return NewGQL.DEFAULT_CLIENT.execute(
              GQLQuery.Mutate<
                UpdateBroadcastMailMutationVariables,
                BroadcastMail
              >(updateBroadcastMail).create({
                input: {
                  content: mail.content,
                  subject: mail.subject,
                  sender: mail.sender,
                  id: mail.id!,
                },
              }),
            )
              .then((res) => {
                fetchData();
                setSelected(undefined);
              })
              .catch((err) => {
                console.error('Error sending broadcast mail', err);
                enqueueSnackbar('Failed to safe draft', {
                  variant: 'error',
                });
              });
          }}
          sendMail={async (content) => {
            await NewGQL.DEFAULT_CLIENT.execute(
              GQLQuery.Mutate<
                SendBroadcastMailMutationVariables,
                FunctionBodyResponse
              >(sendBroadcastMail).create({
                content: content.content,
                subject: content.subject,
                sender: content.sender,
              }),
            )
              .then((res) => {
                console.log('Sent broadcast mail', res);
                fetchData();
                setSelected(undefined);
              })
              .catch((err) => {
                console.error('Error sending broadcast mail', err);
                enqueueSnackbar('Failed to send mail', {
                  variant: 'error',
                });
              });
          }}
        />
      )}
    </>
  );
}
