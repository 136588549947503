import { LoadedTestExecution } from './LoadedTestExecution';
import React, { MutableRefObject } from 'react';
import { ControlInputMonitor } from '../../controls/control-monitor';
import { Observer } from 'rxjs';
import {
  TestDirector,
  TestFlowSnapshot,
} from '../../graph-state/test-director';
import { GQLQueryInstance } from '../../../../../GQL';

export function ParticipantStage({
  loadedTest,
  containerRef,
}: {
  loadedTest: LoadedTestExecution;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}) {
  React.useEffect(() => {
    const inputMonitor = new ControlInputMonitor(containerRef.current!);
    const manager = loadedTest.runnerConfig.controlFactory(
      loadedTest.structure,
    )(inputMonitor);

    const subjectSub = loadedTest.subject.subscribe({
      next: (value) => {
        manager
          .raceRequests(
            value.screenNode.screenData.controlTransitions
              .filter(
                (ct) =>
                  ct.predicate === undefined ||
                  ct.predicate(director.getStateHolder()),
              )
              .map((ctt) => ctt.controlRequest),
            value.timeProcessor,
          )
          .subscribe({
            next: (value1) => {
              director.processControl(value1);
            },
          });
      },
    });

    const flowObserver: Observer<TestFlowSnapshot> = {
      next: (value) => {
        loadedTest.subject.next(value);
      },
      complete: () => {
        loadedTest.subject.complete();
      },
      error: (err) => {
        loadedTest.subject.error(err);
      },
    };
    const director = new TestDirector(loadedTest.treeSequence, flowObserver, {
      enqueueResult(queryInstance: GQLQueryInstance<any, any>) {
        loadedTest.resultManager.enqueueResult(queryInstance);
      },
    });
    return () => {
      loadedTest.screenController.close();
      subjectSub.unsubscribe();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    containerRef,
    loadedTest.runnerConfig,
    loadedTest.screenController,
    loadedTest.structure,
    loadedTest.subject,
    loadedTest.treeSequence,
  ]);
  const RootComponent = loadedTest.screenController.mainViewController.element;
  return <RootComponent />;
}
