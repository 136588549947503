import { PickingSet } from '../PickingSet';
import { PickingStrategy } from '../../../../API';
import { createPickingSet } from '../picking-strategy';

export class NestedPickingSet<T> implements PickingSet<T> {
  private constructor(
    private delegateSet: PickingSet<PickingSet<T>>,
    private subSets: PickingSet<T>[],
    public readonly name?: string,
  ) {}

  pick(): T {
    return this.delegateSet.pick().pick();
  }

  validate(): void {
    this.delegateSet.validate();
    this.subSets.forEach((subSet) => subSet.validate());
  }

  static Create<T>(
    name: string,
    strategy: PickingStrategy,
    subSets: PickingSet<T>[],
  ) {
    return new NestedPickingSet(
      createPickingSet(name, strategy, subSets),
      subSets,
      name,
    );
  }
}
