import React, { useMemo } from 'react';
import { Paper, Typography } from '@mui/material';
import {
  DataGridPro,
  GridActionsCellItem,
  GridActionsColDef,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { Edit, FileCopy } from '@mui/icons-material';
import { TestsMetaData, TestType } from '../../../subject/types';
import { useSelector } from 'react-redux';
import {
  AsyncAppThunk,
  RootState,
  useAppDispatch,
} from '../../../../app/redux/store';
import { TestStatus } from '../../../../API';
import { BaseTest } from '../../types';
import { useTestAdminNav } from '../utils';
import { testListColumns } from './testListColumns';
import { TestListToolbarRenderer } from './TestListToolbar';
import { CustomFilterPanel } from './CustomFilterPanel';
import { useAppAuthenticator } from '../../../../auth/app-authenticator.hook';
import { getUserTestPermissions } from '../../../../auth/auth.hook';

interface TestListProps<T> {
  type: TestType;
  templates: T[];
  loadTestsThunk: AsyncAppThunk<T[]>;
  deleteTestThunk: AsyncAppThunk<string, string>;
}

export function TestList<T extends BaseTest>({
  type,
  loadTestsThunk,
  templates,
}: TestListProps<T>) {
  const { user } = useAppAuthenticator();
  const dispatch = useAppDispatch();

  const { navigateToEditor, navigateToDetails } = useTestAdminNav(type);

  React.useEffect(() => {
    dispatch(loadTestsThunk());
  }, [dispatch, loadTestsThunk]);

  const selected = useSelector((state: RootState) => ({
    isLoading: state[type].isLoading as boolean,
    testList: Object.values(state[type].tests) as unknown as T[],
  }));

  const actions: GridActionsColDef = useMemo(
    () => ({
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params: GridRowParams) => {
        const t = params.row as T;
        const actionElements = [];
        const { canViewTest, canEditTest } = getUserTestPermissions(
          user,
          params.row,
        );
        if (canViewTest) {
          actionElements.push(
            <GridActionsCellItem
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
              placeholder=""
              icon={<FileCopy />}
              label="Duplicate"
              onClick={() => navigateToEditor('duplicate', t.id)}
              showInMenu
            />,
          );
        }
        if (canEditTest && t.status === TestStatus.DRAFT) {
          actionElements.push(
            <GridActionsCellItem
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
              icon={<Edit />}
              label="Edit"
              onClick={() => navigateToEditor('edit', t.id)}
              showInMenu
              placeholder=""
            />,
          );
        }
        return actionElements;
      },
    }),
    [navigateToEditor, user],
  );

  const columns = useMemo(() => [actions, ...testListColumns], [actions]);

  return (
    <div>
      <Typography variant="h5" sx={{ pl: 2 }}>
        {TestsMetaData[type].shortName}
      </Typography>
      <Typography color="textSecondary" sx={{ pl: 2, mb: 2 }}>
        {TestsMetaData[type].longName}
      </Typography>
      <Paper>
        <DataGridPro
          style={{ height: '88vh' }}
          loading={selected.isLoading}
          components={{
            Toolbar: TestListToolbarRenderer(
              type,
              templates,
              (template?: BaseTest) =>
                navigateToEditor('create', undefined, template),
            ),
            FilterPanel: () => <CustomFilterPanel />,
          }}
          columns={columns}
          rows={selected.testList}
          onRowClick={(r) => navigateToDetails(r.id as string)}
        />
      </Paper>
    </div>
  );
}
