import React from 'react';
import { Box, Checkbox, Grid, Stack, Tooltip, Typography } from '@mui/material';
import StyledTextInput from '../../../../../components/input/StyledTextInput';
import { EditorStepTitle } from '../../../../../components/grid';
import { Wysiwyg } from '../../../../../components/input/wysiwyg/Wysiwyg';
import ColorInput from '../../../../../components/input/ColorInput';
import StyledNumberInput from '../../../../../components/input/StyledNumberInput';
import TextStyleDialog from '../../../../../components/TextStyleDialog';
import { useField } from 'formik';
import { FontStyleInput, TextAlignment } from '../../../../../API';
import { createDefaultFontStyle } from '../../../testAdmin/testEditor/initialEditorStates';
import { WswVariables } from '../../../text-replacer/textReplacer';
import { InfoOutlined, TextFormat } from '@mui/icons-material';
import * as Yup from 'yup';
import { instructionsSchema } from '../../../testAdmin/testEditor/instructionsSchema';
import { TestLanguagePicker } from '../../../../../components/input/TestLanguagePicker';

export const WswGameSettingsSchema = Yup.object().shape({
  name: Yup.string().required('name is required'),
  description: Yup.string().required('description is required'),
  instructions: instructionsSchema,
  endScreen: instructionsSchema,
});

const WswGameSettingsStep = () => {
  const [, { value: mobileStyle }, mobileStyleHelpers] =
    useField<FontStyleInput | null>('style.mobileStimulusTextStyle');

  return (
    <Box>
      <EditorStepTitle prefix={1} title="Test Settings" />
      <Grid container spacing={3}>
        <Grid item container spacing={3} direction="row">
          <Grid item xs={5}>
            <StyledTextInput name="name" label="Name" />
          </Grid>
          <Grid item xs={5}>
            <StyledTextInput
              name="description"
              label="Description"
              multiline
              toolTip={
                'Text will be shown only in MINDset, not visible for respondents.'
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Box flex={1}>
              <TestLanguagePicker />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={3}>
            <Wysiwyg
              name="instructions"
              label="Welcome Screen"
              toolTip={
                'Text will be shown as Welcome message on the first page when respondents enter the test.'
              }
              variables={WswVariables}
            />
            <Wysiwyg
              name="endScreen"
              label="End Screen"
              toolTip={
                'Text will be shown at the end before respondents leave the test (e.g., last screen before redirection to survey).'
              }
              variables={WswVariables}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" spacing={3}>
            <StyledNumberInput
              name="style.maxWidth"
              label="Width"
              unit="%"
              toolTip={
                'The setting “width” only has an impact on the width of the desktop version. Mobile width is always 100%.'
              }
            />
            <div>
              <ColorInput name="style.backgroundColour" label="Background" />
            </div>
            <Stack direction="row" spacing={3} mt={8} alignItems="flex-start">
              <Box>
                <Typography variant="caption" color="textSecondary">
                  Default Text-Attribute Style
                </Typography>
                <Tooltip
                  title={
                    'Size of the text attributes displayed during the trials. You cannot change the text style of instructions or questions.'
                  }
                  placement="left-start"
                >
                  <InfoOutlined
                    sx={{
                      color: 'warning.dark',
                      bgcolor: 'white',
                      position: 'absolute',
                      top: -10,
                    }}
                  />
                </Tooltip>
                <TextStyleDialog
                  label={'Text-Attribute Style'}
                  name={'style.stimulusTextStyle'}
                >
                  <TextFormat />
                </TextStyleDialog>
              </Box>
              <Box>
                <Typography variant="caption" color="textSecondary">
                  Mobile Text-Attribute Style
                  <Checkbox
                    sx={{ padding: 0, margin: 0 }}
                    checked={Boolean(mobileStyle)}
                    size="small"
                    onChange={(_, checked) =>
                      mobileStyleHelpers.setValue(
                        checked
                          ? createDefaultFontStyle(TextAlignment.LEFT)
                          : null,
                      )
                    }
                  />
                </Typography>
                {Boolean(mobileStyle) && (
                  <TextStyleDialog
                    label={'Mobile Text-Attribute Style'}
                    name={'style.mobileStimulusTextStyle'}
                  >
                    <TextFormat />
                  </TextStyleDialog>
                )}
              </Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <StyledTextInput
              fullWidth
              name={'startLabel'}
              label={'Start Label'}
            />
            <StyledTextInput
              fullWidth
              name={'nextLabel'}
              label={'Next Label'}
            />
            <StyledTextInput
              fullWidth
              name={'finishLabel'}
              label={'Finish Label'}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WswGameSettingsStep;
