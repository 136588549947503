import React, { FunctionComponent } from 'react';
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  SelectProps,
  Tooltip,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { ErrorMessage, useField } from 'formik';
import makeStyles from '@mui/styles/makeStyles';
import { UiInput } from './types';
import { InfoOutlined } from '@mui/icons-material';

const StyledSelect: FunctionComponent<
  UiInput<string> & SelectProps & { toolTip?: React.ReactNode }
> = (props) => {
  const classes = useStyles();

  const [input, meta, helpers] = useField<typeof props.value>(props.name);

  const onChangeTextField = (
    ev: React.ChangeEvent<{ name?: string; value: any }>,
  ) => {
    const { value } = ev.target;
    if (typeof props.onChange === 'function') {
      props.onChange(value);
    }
    helpers.setValue(value);
  };

  input.onChange = onChangeTextField;

  return (
    <FormControl fullWidth className={classes.formControl}>
      <InputLabel htmlFor={`select-${props.name}`}>
        {props.label || props.name}
      </InputLabel>
      <MuiSelect
        native
        fullWidth
        disabled={props.disabled}
        name={props.name}
        inputProps={{
          name: props.name,
          id: `select-${props.name}`,
        }}
        value={props.value || meta.value}
        // @ts-ignore
        onChange={onChangeTextField}
      >
        {props.children}
      </MuiSelect>
      {meta.error && (
        <Typography variant="caption" color="error">
          <ErrorMessage name={props.name} />
        </Typography>
      )}
      {props.toolTip && (
        <Tooltip
          componentsProps={{ tooltip: { style: { maxWidth: 'none' } } }}
          title={props.toolTip}
          placement="left-start"
        >
          <InfoOutlined
            sx={{
              color: 'warning.dark',
              bgcolor: 'white',
              position: 'absolute',
              top: -10,
              right: -10,
            }}
          />
        </Tooltip>
      )}
    </FormControl>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      position: 'relative',
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        background: 'white',
        marginLeft: theme.spacing(-0.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(0.5),
      },
    },
  }),
);

export default StyledSelect;
