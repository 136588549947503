import { Button, CircularProgress } from '@mui/material';
import { useGenerateResultsMutation } from '../../../results/resultsApi';
import { TestType } from '../../../subject/types';
import React, { PropsWithChildren } from 'react';
import { useAccessToken } from '../../../../auth/app-authenticator.hook';

export function TriggerDownloadButton({
  testType,
  testId,
  children,
  icon,
  email,
}: PropsWithChildren<{
  testType: TestType;
  testId: string;
  icon: React.ReactElement;
  email: string;
}>) {
  const accessToken = useAccessToken();

  const [generateResults, { isLoading }] = useGenerateResultsMutation();
  const handleGenerateResults = () => {
    if (accessToken) {
      generateResults({
        testType,
        testId,
        accessToken,
        email,
      });
    }
  };

  return (
    <Button
      color="inherit"
      startIcon={isLoading ? <CircularProgress /> : icon}
      onClick={handleGenerateResults}
    >
      {children}
    </Button>
  );
}
