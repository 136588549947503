import { Wsw } from '../types';
import GQL from '../../../../GQL';
import {
  CreateWSWTestInput,
  CreateWSWTestMutationVariables,
  DeleteWSWTestMutationVariables,
  GetWSWTestQueryVariables,
  TestStatus,
  UpdateWSWTestInput,
  UpdateWSWTestMutationVariables,
} from '../../../../API';
import { getWSWTest, listWSWTests } from '../../../../graphql/queries';
import {
  createWSWTest,
  deleteWSWTest,
  updateWSWTest,
} from '../../../../graphql/mutations';
import { createAsyncAppThunk } from '../../../../app/redux/thunk';

export const fetchWswTest = createAsyncAppThunk<Wsw, string>('iat/get', (id) =>
  GQL.get<GetWSWTestQueryVariables, Wsw>(getWSWTest, { id }),
);

export const fetchWswTests = createAsyncAppThunk('wsw/list', () =>
  GQL.list<Wsw>(listWSWTests),
);
export const updateWswAsync = createAsyncAppThunk<Wsw, UpdateWSWTestInput>(
  'wsw/update',
  (input) =>
    GQL.mutate<Wsw, UpdateWSWTestMutationVariables>(updateWSWTest, { input }),
);

export const updateWswStatusAsync = createAsyncAppThunk<
  Wsw,
  { testId: string; status: TestStatus }
>('wsw/update/status', ({ status, testId }) =>
  GQL.mutate<Wsw, UpdateWSWTestMutationVariables>(updateWSWTest, {
    input: { id: testId, status },
  }),
);

export const createWswAsync = createAsyncAppThunk<Wsw, CreateWSWTestInput>(
  'wsw/create',
  (arg) => {
    return GQL.mutate<Wsw, CreateWSWTestMutationVariables>(createWSWTest, {
      input: arg,
    });
  },
);
export const deleteWswAsync = createAsyncAppThunk<string, string>(
  'wsw/delete',
  async (testId) => {
    await GQL.mutate<Wsw, DeleteWSWTestMutationVariables>(deleteWSWTest, {
      input: { id: testId },
    });
    return testId;
  },
);
