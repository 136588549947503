import React from 'react';
import {
  BasicDimension,
  DrawableDimension,
  SizedArea,
} from '../../media/drawable-dimensions';
import {
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { StyledTooltip } from './StyledTooltip';
import { ScreenSizeSelect } from './ScreenSizeSelect';
import { Check } from '@mui/icons-material';

export function PreviewConfig({
  configure,
}: {
  configure: (config: PreviewConfigType) => void;
}) {
  const [screenSize, setScreenSize] = React.useState<BasicDimension>(() => {
    return new DrawableDimension(
      Math.round(window.innerWidth * 0.55),
      Math.round(window.innerHeight * 0.75),
    );
  });
  const [mode, setMode] = React.useState<'mobile' | 'desktop'>('desktop');

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          ...SizedArea.dimPx(screenSize).toSizeModeStyle(),
          border: '1px dashed black',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid container spacing={3} style={{ textAlign: 'center' }}>
          <Grid
            item
            xs={12}
            style={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <ToggleButtonGroup
              size="small"
              value={mode}
              onChange={(_, v) => {
                if (v === 'mobile') {
                  setScreenSize({ ...screenSize, width: 610, height: 300 });
                }
                setMode(v);
              }}
              exclusive
            >
              <StyledTooltip
                arrow
                placement={'left'}
                title={
                  <div style={{ whiteSpace: 'pre-line' }}>
                    {'Please note that the text input questions are not displayed correctly in the mobile preview.\n ' +
                      'This is a workaround for some phone types in order to display the text input correctly for the respondent.'}
                  </div>
                }
              >
                <ToggleButton value="mobile" key="value">
                  mobile
                </ToggleButton>
              </StyledTooltip>
              <ToggleButton value="desktop" key="desktop">
                desktop
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <TextField
              type="number"
              value={screenSize.width}
              onChange={(e) =>
                setScreenSize({
                  ...screenSize,
                  width: Number.parseInt(e.target.value),
                })
              }
              label="width"
            />
            <TextField
              type="number"
              value={screenSize.height}
              onChange={(e) =>
                setScreenSize({
                  ...screenSize,
                  height: Number.parseInt(e.target.value),
                })
              }
              label="height"
            />
            <ScreenSizeSelect select={(s) => setScreenSize(s)} />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <IconButton
              onClick={() => {
                configure({
                  screenSize,
                  mode,
                  persistResults: false,
                });
              }}
            >
              <Check />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export type PreviewConfigType = {
  mode: 'desktop' | 'mobile';
  screenSize: BasicDimension;
  persistResults: boolean;
};
