import { AmpStructure } from '../structure/amp-structure';
import { ControlOccurrenceProcessor } from '../../../../subject/testRunner/graph-state/graph-action';
import { AmpResultQueries } from '../loading/amp.queries';
import { v4 as uuid } from 'uuid';
import { omit } from 'lodash';
import { AmpTrialPhaseDescriptor } from './amp-trial-tree';

export function buildAmpCreateResultQuery(
  ampStructure: AmpStructure,
  trialDescriptor: AmpTrialPhaseDescriptor,
) {
  const { blockIndex, block, trialIndex, trial } = trialDescriptor;
  return (
    control: ControlOccurrenceProcessor<any>,
    action: 'first' | 'second',
  ) => {
    return AmpResultQueries.Create.create({
      input: {
        clientId: uuid(),
        device: omit(ampStructure.accessor.testContext.deviceInfo, 'mode'),
        testId: ampStructure.accessor.modelData.id,
        status: ampStructure.accessor.modelData.status,
        survey: ampStructure.accessor.testContext.logic.resultEntry,
        timing: control.reactionTimeProcessor.toResultTiming(),
        blockIndex,
        blockName: block.blockDescriptor.blockName,
        trialIndex: trialIndex,
        actionResultName: action,
        counterBalancingGroup: ampStructure.options.arranger.cbGroup,
        blockType: block.blockDescriptor.blockType,
        ...control.occurrence.toResultData(),
        prime: {
          label: trial.prime.metaData.category,
          mediaItem: trial.prime.value.data.toMediaItemSnapshot(),
        },
        target: {
          label: trial.target.metaData.category,
          mediaItem: trial.target.value.data.toMediaItemSnapshot(),
        },

        actionResultStimulus:
          block.blockDescriptor.options.attribute!.left.optionVariant === action
            ? block.blockDescriptor.options.attribute!.left.mediaInstance.data.toMediaItemSnapshot()
            : block.blockDescriptor.options.attribute!.right.mediaInstance.data.toMediaItemSnapshot(),
        attributeLeft:
          block.blockDescriptor.options.attribute!.left.optionVariant,
        attributeLeftStimulus:
          block.blockDescriptor.options.attribute!.left.mediaInstance.data.toMediaItemSnapshot(),
        attributeRight:
          block.blockDescriptor.options.attribute!.right.optionVariant,
        attributeRightStimulus:
          block.blockDescriptor.options.attribute!.right.mediaInstance.data.toMediaItemSnapshot(),
      },
    });
  };
}
