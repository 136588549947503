import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Input from '@mui/material/Input';
import { useField } from 'formik';
import * as Yup from 'yup';

const useStyles = makeStyles({
  root: {
    width: 250,
  },
  input: {
    width: 42,
  },
});

type SliderWithNumberInputProps = {
  label: string;
  name: string;
  icon: JSX.Element;
  initialValue?: number;
};

export default function SliderWithNumberInput({
  label,
  name,
  icon,
}: SliderWithNumberInputProps) {
  const classes = useStyles();
  const [, meta, helpers] = useField<number>(name);
  const [value, setValue] = React.useState<string>(String(meta.value || ''));

  const handleSliderChange = (_: any, newValue: number | number[]) => {
    helpers.setValue(newValue as number);
    setValue('' + newValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    Yup.number()
      .min(10)
      .max(100)
      .validate(event.target.value)
      .catch(() => meta.value)
      .then((v) => helpers.setValue(v as number));
  };

  return (
    <div className={classes.root}>
      <Typography id="input-slider" gutterBottom>
        {label}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>{icon}</Grid>
        <Grid item xs>
          <Slider
            value={meta.value}
            min={10}
            max={100}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <Input
            className={classes.input}
            value={value}
            margin="dense"
            onChange={handleInputChange}
            inputProps={{
              step: 10,
              min: 0,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
