import { random, shuffle } from 'lodash';
import { BlockType } from '../../API';

export type Pair<A> = [A, A];
export type ReadonlyPair<A> = readonly [A, A];

export const tupleOf = <A, B>(first: A, second: B) => [first, second] as [A, B];
export const copyPair = <A>(pair: Pair<A> | ReadonlyPair<A>) =>
  [...pair] as Pair<A>;
export const reversePair = <A>(pair: Pair<A> | ReadonlyPair<A>) =>
  [...pair].reverse() as Pair<A>;
export const reversePairIf = <A>(
  pair: Pair<A> | ReadonlyPair<A>,
  condition: boolean,
) => (condition ? reversePair(pair) : copyPair(pair)) as Pair<A>;
export const pairOf = <A>(first: A, second: A) => [first, second] as Pair<A>;
export const applyIf = <A>(val: A, condition: boolean, op: (a: A) => A): A =>
  condition ? op(val) : val;

export const zipArrays = <A>(...sources: A[][]) =>
  sources[0].flatMap((_, i) => sources.map((set) => set[i]));
export const zippedConcat = <A, B>(
  first: readonly A[],
  second: B[] | readonly B[],
) => first.map((v, i) => [v, second[i]] as [A, B]);

export const prepend = <A, B>(second: B[] | readonly B[], ...first: A[]) =>
  first.map((v, i) => [v, second[i]] as [A, B]);

export const mapToEntry = <K extends string, T>(
  keys: readonly K[],
  map: (d: K) => T,
) => TypeObject.fromEntries(keys.map((d) => [d, map(d)]));

export const identity = <A>(v: A) => v;

export const TypeObject = {
  fromEntries: <A extends string, B>(entries: [A, B][]) =>
    Object.fromEntries(entries) as { [n in A]: B },
  extFromEntries: <A extends string, C extends { [n in A]: any }>(
    entries: [A, C[A]][],
  ) => Object.fromEntries(entries) as C,
  entries: <A extends string, B>(obj: { [k in A]?: B }) =>
    Object.entries(obj) as [A, B][],
  fromTuples: <A extends string, B>(keys: A[] | readonly A[], values: B[]) =>
    TypeObject.fromEntries(prepend(values, ...keys)),
  keys: <A extends string>(obj: { [keys in A]: any }): A[] =>
    Object.keys(obj) as A[],
};

export const spreadNone = <T>(value: T | undefined | null) =>
  value ? [value] : [];

export function delay(ms: number) {
  return new Promise<undefined>((resolve) => setTimeout(resolve, ms));
}

export function isDefined<T>(input: T | undefined): input is T {
  return input !== undefined;
}

export function isReallyDefined<T>(input: T | undefined | null): input is T {
  return input !== undefined && input !== null;
}

export const MediaItemConfig = {
  image: {
    thumb: false,
  },
  text: {},
};

type BlockTypePoolSet<A, B> = {
  label: A;
  practiceStimuliPool: B;
  testStimuliPool: B;
};

export const expandBlockPoolType = <A, B, C extends object>(
  poolSet: BlockTypePoolSet<A, B>,
  transform: (v: B) => C[],
) =>
  (
    [
      ['practiceStimuliPool', BlockType.PRACTICE],
      ['testStimuliPool', BlockType.NON_PRACTICE],
    ] as [keyof typeof poolSet, BlockType][]
  ).flatMap((k) =>
    transform(poolSet[k[0]] as B).map((c) => ({
      label: poolSet.label,
      blockType: k[1],
      ...c,
    })),
  );

export const randomElement = <T>(source: T[]): T =>
  source[random(0, source.length - 1, false)];
// type RandomPicker<T, A> = (source: T[], amount: number, transformer: (e: T) => A) => A[];

const genericPicker = <T, A>(
  source: () => T,
  amount: number,
  transformer: (e: T) => A,
) => {
  return Array.from({ length: amount }, () => transformer(source()));
};

export const RefillingPicker = <T, A>(
  source: T[],
  amount: number,
  transformer: (e: T) => A,
) => {
  const state: T[] = [];
  return genericPicker(
    () => {
      if (state.length === 0) state.push(...shuffle(source));
      return state.pop() as T;
    },
    amount,
    transformer,
  );
};

export const ReplacingPicker = <T, A>(
  source: T[],
  amount: number,
  transformer: (e: T) => A,
) => genericPicker(() => randomElement(source), amount, transformer);
