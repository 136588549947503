import { PickingStrategy, PodtaBoxPosition } from '../../../../API';
import { MindSetRandomUtils } from '../../../tests/pickingSet/picking-utils';
import { NewGeneratingPickingSet } from '../../../tests/pickingSet/GeneratingPickingSet';
import { newPickNCollect } from '../../../tests/pickingSet/picking-set';
import { PickingBehaviours } from '../../../tests/pickingSet/picking-behaviours';
import { ValidConstraints } from '../../../tests/pickingSet/setConstraints/valid-constraints';
import { PlainPickingSet } from '../../../tests/pickingSet/PlainPickingSet/PlainPickingSet';
import { createPickingSet } from '../../../tests/pickingSet/picking-strategy';
import { CombinedPickingSet } from '../../../tests/pickingSet/CombinedPickingSet/CombinedPickingSet';
import { NestedPickingSet } from '../../../tests/pickingSet/NestPickingSet/NestPickingSet';
import { MappingPickingSet } from '../../../tests/pickingSet/CombinedPickingSet/MappingPickingSet';
import { PodtMediaPool } from '../loader/loading/podt-loader';
import { DrawableDimension } from '../../../media/drawable-dimensions';
import MediaOffscreenCanvas from '../../../subject/MediaOffscreenCanvas';
import { DrawableMediaHandle } from '../../../subject/testRunner/media/MediaData';
import { PodtaMediaPool } from '../../PODTA/loader/loading/podta-loader';
import { PodtSceneBox } from '../loader/loading/podt-scene-media';
import { PodtBlockDescriptor } from '../loader/structure/podt-block-structure';

export interface PodtStimulus {
  emptyScenes: PodtSceneStimulus[];
  scene: PodtSceneBoxStimulus;
  target: ReturnType<PodtMediaPool['targets']['collect']>[number];
}

export type PodtPickerIntervalConfig = Record<
  'min' | 'max' | 'stepSize',
  number
>;
export class PodtSceneStimulus {
  constructor(
    public scene: ReturnType<PodtMediaPool['scenes']['collect']>[number],
    public interval: number,
  ) {}
}

export class PodtSceneBoxStimulus extends PodtSceneStimulus {
  constructor(
    scene: ReturnType<PodtMediaPool['scenes']['collect']>[number],
    interval: number,
    public box: PodtSceneBox,
  ) {
    super(scene, interval);
  }

  drawInstance(target: PodtStimulus['target']): DrawableMediaHandle {
    const drawable = this.scene.value.instance.drawable;
    const dimension = drawable.dimension;
    const box = this.box;
    const targetDrawable = target.value.drawable;
    const targetDimension = DrawableDimension.toInt(
      new DrawableDimension(
        (box.height / targetDrawable.dimension.height) *
          targetDrawable.dimension.width,
        box.height,
      ),
    );
    const targetPosition = {
      y: box.top,
      x: Math.round(
        box.left + (box.width - targetDimension.width) * Math.random(),
      ),
    };
    const data = this.scene.value.instance.data;
    return {
      size: dimension,
      create: () => {
        const offscreenCanvas = new MediaOffscreenCanvas(dimension);
        offscreenCanvas.drawImgSourceCenter(drawable.imageSource, dimension);
        offscreenCanvas.drawImageSource(
          targetDrawable.imageSource,
          targetPosition,
          targetDimension,
        );
        return {
          data,
          drawable: {
            dimension,
            imageSource: offscreenCanvas.canvas,
          },
        };
      },
    };
  }
}

export function createEmptyScenesPicker(
  pool: PodtaMediaPool,
  maxScenesPerTrial: number,
  intervalConfig: PodtPickerIntervalConfig,
) {
  const pickSceneInterval = (
    scene: ReturnType<PodtMediaPool['scenes']['collect']>[number],
  ) => {
    return new PodtSceneStimulus(
      scene,
      MindSetRandomUtils.randomInt(
        intervalConfig.min,
        intervalConfig.max,
        intervalConfig.stepSize,
      ),
    );
  };
  const emptyScenePicker = new MappingPickingSet(
    createPickingSet(
      'empty-scene-picker',
      PickingStrategy.REFILLING_WITHOUT_REPLACEMENT,
      pool.scenes.collect(),
    ),
    pickSceneInterval,
  );
  return new NewGeneratingPickingSet(
    () =>
      newPickNCollect(
        emptyScenePicker,
        MindSetRandomUtils.randomInt(1, maxScenesPerTrial),
      ),
    'empty-scenes-picker',
  );
}
export function collectBlockTargets(
  block: PodtBlockDescriptor,
  pool: PodtMediaPool,
) {
  return pool.targets
    .select({
      blockType: block.blockType,
    })
    .collectGrouped(['category', 'variant']);
}
export function createPodtScenePicker(
  pool: PodtaMediaPool,
  intervalConfig: PodtPickerIntervalConfig,
  index: number,
) {
  return PlainPickingSet.Create(
    'target-scene-picker-' + index,
    PickingBehaviours.WithRefill,
    pool.scenes.collect().map((scene) => ({
      scene,
      boxPicker: createPickingSet(
        'box-set',
        PickingStrategy.WITH_REPLACEMENT,
        scene.value.boxes,
      ),
      timeoutPicker: () =>
        MindSetRandomUtils.randomInt(
          intervalConfig.min,
          intervalConfig.max,
          intervalConfig.stepSize,
        ),
    })),
    ValidConstraints.EqualsTimes,
  );
}

export function createPodtaScenePicker(
  pool: PodtaMediaPool,
  intervalConfig: PodtPickerIntervalConfig,
  index: number,
) {
  return PlainPickingSet.Create(
    'target-scene-picker-' + index,
    PickingBehaviours.WithRefill,
    pool.scenes.collect().map((scene) => ({
      scene,
      boxPicker: NestedPickingSet.Create(
        'boxes-set',
        PickingStrategy.REFILLING_WITHOUT_REPLACEMENT,
        [
          createPickingSet(
            'boxes-left-set',
            PickingStrategy.WITH_REPLACEMENT,
            scene.value.boxes.filter((b) => b.side === PodtaBoxPosition.LEFT),
          ),
          createPickingSet(
            'boxes-right-set',
            PickingStrategy.WITH_REPLACEMENT,
            scene.value.boxes.filter((b) => b.side === PodtaBoxPosition.RIGHT),
          ),
        ],
      ),
      timeoutPicker: () =>
        MindSetRandomUtils.randomInt(
          intervalConfig.min,
          intervalConfig.max,
          intervalConfig.stepSize,
        ),
    })),
    ValidConstraints.EqualsTimes,
  );
}

export function pickPodtOrPodtaBlock(
  block: PodtBlockDescriptor,
  pool: PodtMediaPool,
  maxScenesPerTrial: number,
  intervalConfig: PodtPickerIntervalConfig,
  pickingStrategy: PickingStrategy,
  scenePickerFactory: (
    pool: PodtaMediaPool,
    intervalConfig: PodtPickerIntervalConfig,
    index: number,
  ) =>
    | ReturnType<typeof createPodtScenePicker>
    | ReturnType<typeof createPodtaScenePicker>,
): PodtStimulus[] {
  const emptyScenesPicker = createEmptyScenesPicker(
    pool,
    maxScenesPerTrial,
    intervalConfig,
  );
  // Jede Hauptszene targetCats.length*2*n mal
  const trialSet = NestedPickingSet.Create(
    'trial-picker',
    PickingStrategy.REFILLING_WITHOUT_REPLACEMENT,
    collectBlockTargets(block, pool).map((targetSet, targetSetIndex) => {
      const scenePicker = scenePickerFactory(
        pool,
        intervalConfig,
        targetSetIndex,
      );
      const sceneBoxPicker = new NewGeneratingPickingSet(() => {
        const { scene, boxPicker, timeoutPicker } = scenePicker.pick();
        return new PodtSceneBoxStimulus(
          scene,
          timeoutPicker(),
          boxPicker.pick(),
        );
      }, 'scene-box-picker');
      const targetPickingSet = createPickingSet(
        'target-set',
        pickingStrategy,
        targetSet,
      );
      return new CombinedPickingSet({
        target: targetPickingSet,
        scene: sceneBoxPicker,
        emptyScenes: emptyScenesPicker,
      });
    }),
  );
  return MindSetRandomUtils.shuffleArray(
    newPickNCollect(trialSet, block.amountTrials),
  ) as any;
}
export function pickPodtBlockNew(
  block: PodtBlockDescriptor,
  pool: PodtMediaPool,
  maxScenesPerTrial: number,
  intervalConfig: PodtPickerIntervalConfig,
  pickingStrategy: PickingStrategy,
) {
  return pickPodtOrPodtaBlock(
    block,
    pool,
    maxScenesPerTrial,
    intervalConfig,
    pickingStrategy,
    createPodtScenePicker,
  );
}
export function pickPodtaBlockNew(
  block: PodtBlockDescriptor,
  pool: PodtMediaPool,
  maxScenesPerTrial: number,
  intervalConfig: PodtPickerIntervalConfig,
  pickingStrategy: PickingStrategy,
) {
  return pickPodtOrPodtaBlock(
    block,
    pool,
    maxScenesPerTrial,
    intervalConfig,
    pickingStrategy,
    createPodtaScenePicker,
  );
}
