import React from 'react';
import * as Yup from 'yup';
import MediaItemsPicker from '../../../../media/picker/MidiaItemsPicker/MediaItemsPicker';
import { MediaItemSnapshotInput } from '../../../../../API';
import MediaItemComponent from '../../../../media/MediaItemComponent';
import { EditorStepTitle } from '../../../../../components/grid';
import TextStyleDialog from '../../../../../components/TextStyleDialog';
import { Build } from '@mui/icons-material';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useField } from 'formik';
import InfoMessage from '../../../../../components/InfoMessage';
import StyledNumberInput from '../../../../../components/input/StyledNumberInput';

const createCategorySchema = (
  categoriesName: 'targetCategories' | 'attributeCategories',
  variant: 'A' | 'B',
) =>
  Yup.object().shape({
    testStimuliPool: Yup.array().test(
      'stimuli',
      'Have to be equal size',
      function (v) {
        const values = v as MediaItemSnapshotInput[];
        if (values && values.length > 0) {
          const image = Boolean(values.find((m) => m.image));
          const text = Boolean(values.find((m) => m.text));
          if (image && text) {
            return this.createError({
              message: 'sets can not have different types',
              path: this.path,
            });
          }
        } else {
          return this.createError({
            message: 'chose a stimuli set',
            path: this.path,
          });
        }
        return true;
      },
    ),
  });

const createCategoriesSchema = (
  categoriesName: 'targetCategories' | 'attributeCategories',
) =>
  Yup.object().shape({
    categoryA: createCategorySchema(categoriesName, 'A'),
    categoryB: createCategorySchema(categoriesName, 'B'),
  });

export const IatStimuliSettingsSchema = Yup.object()
  .shape({
    attributeCategories: createCategoriesSchema('attributeCategories'),
    targetCategories: createCategoriesSchema('targetCategories'),
  })
  .test('same size', 'must have same size', function (values) {
    const amountAttributeA =
      values?.attributeCategories?.categoryA?.testStimuliPool?.length ?? 0;
    const amountAttributeB =
      values?.attributeCategories?.categoryB?.testStimuliPool?.length ?? 0;

    const amountTargetA =
      values?.targetCategories?.categoryA?.testStimuliPool?.length ?? 0;
    const amountTargetB =
      values?.targetCategories?.categoryB?.testStimuliPool?.length ?? 0;

    const allSameSize = Boolean(
      [amountAttributeA, amountAttributeB, amountTargetA, amountTargetB].reduce(
        (a, b) => (a === b ? a : NaN),
      ),
    );

    if (!allSameSize) {
      let message = 'All sets have to be equal size';
      return this.createError({ path: 'categories', message });
    }

    return true;
  });

const IatStimuliSettings = () => {
  const [, { value: targetCategoriesColor }] = useField<string | undefined>(
    'targetCategories.fontStyle.color',
  );
  const [, { value: targetCategoryA }] = useField<MediaItemSnapshotInput>(
    'targetCategories.categoryA.label',
  );
  const [, { value: targetCategoryB }] = useField<MediaItemSnapshotInput>(
    'targetCategories.categoryB.label',
  );

  const [, { value: attributeCategoriesColor }] = useField<string | undefined>(
    'attributeCategories.fontStyle.color',
  );
  const [, { value: attributeCategoryA }] = useField<MediaItemSnapshotInput>(
    'attributeCategories.categoryA.label',
  );
  const [, { value: attributeCategoryB }] = useField<MediaItemSnapshotInput>(
    'attributeCategories.categoryB.label',
  );

  const [, { value: stimulusTextColor }] = useField<string | undefined>(
    'style.stimulusTextStyle.color',
  );
  const [, { value: bgColor }] = useField<string | undefined>(
    'style.backgroundColour',
  );

  const [, { error }] = useField<string | undefined>('categories');

  return (
    <Box>
      <Box display="flex" alignItems="flex-start">
        <EditorStepTitle prefix={3} title="Stimuli Settings" />
        <TextStyleDialog
          label={'Stimuli Text Style'}
          name={'style.stimulusTextStyle'}
        >
          <Build />
        </TextStyleDialog>
      </Box>
      <Stack spacing={2}>
        <Box sx={{ width: 400 }}>
          <StyledNumberInput
            name="interTrialInterval"
            label="Inter Trial Interval"
            unit="ms"
            toolTip={'Time in ms between two trials'}
          />
        </Box>
        <InfoMessage
          sx={{ mb: 2 }}
          message="Choose either a set of images from the media pool or a set of words as stimuli. When the number of stimuli for each category is not identical, there will be an error message! Separate words using commas."
        />
        <Stack direction="row" spacing={3}>
          <MediaItemComponent
            label="Target Category A Label"
            mediaItem={targetCategoryA}
            color={targetCategoriesColor}
            bgColor={bgColor}
          />
          <MediaItemsPicker
            name="targetCategories.categoryA.testStimuliPool"
            label="Target Category A Stimuli Set"
            color={stimulusTextColor}
            bgColor={bgColor}
          />
        </Stack>
        {error && <Typography color="error">{error}</Typography>}
        <Divider />
        <Stack direction="row" spacing={3}>
          <MediaItemComponent
            label="Target Category B Label"
            mediaItem={targetCategoryB}
            color={targetCategoriesColor}
            bgColor={bgColor}
          />
          <MediaItemsPicker
            name="targetCategories.categoryB.testStimuliPool"
            label="Target Category B Stimuli Set"
            color={stimulusTextColor}
            bgColor={bgColor}
          />
        </Stack>
        {error && <Typography color="error">{error}</Typography>}
        <Divider />
        <Stack direction="row" spacing={3}>
          <MediaItemComponent
            label="Attribute Category A Label"
            mediaItem={attributeCategoryA}
            color={attributeCategoriesColor}
            bgColor={bgColor}
          />
          <MediaItemsPicker
            name="attributeCategories.categoryA.testStimuliPool"
            label="Attribute Category A Stimuli Set"
            color={stimulusTextColor}
            bgColor={bgColor}
          />
        </Stack>
        {error && <Typography color="error">{error}</Typography>}
        <Divider />
        <Stack direction="row" spacing={3}>
          <MediaItemComponent
            label="Attribute Category B Label"
            mediaItem={attributeCategoryB}
            color={attributeCategoriesColor}
            bgColor={bgColor}
          />
          <MediaItemsPicker
            name="attributeCategories.categoryB.testStimuliPool"
            label="Attribute Category B Stimuli Set"
            color={stimulusTextColor}
            bgColor={bgColor}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default IatStimuliSettings;
