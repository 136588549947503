import { AmpStructure } from '../structure/amp-structure';
import { AMPModifiers } from './amp-modifiers';
import { AmpInstructionsData } from '../view-data/amp-instructions-data';
import { InstructionsContent } from '../../../../subject/testRunner/screens/basic-views/test-instructions-view';
import { OptionsStereotypes } from '../../../../subject/testRunner/controls/control-stereotypes';
import { DefaultTransitionAction } from '../../../../subject/testRunner/graph-state/graph-action';
import { BlockPhaseDescriptor } from '../../../../subject/testRunner/utils/tree-utils';
import { AmpBlockPhase } from '../structure/amp-block-structure';
import { createAmpSingleTrialPhaseTree } from './amp-trial-tree';
import { MapScreenTreeNode } from '../../../../subject/testRunner/graph/nodes/map-screen-tree-node';
import { MapPhaseTreeNode } from '../../../../subject/testRunner/graph/nodes/map-phase-tree-node';

export function createAMPBlockPhasesTrees(
  ampStructure: AmpStructure,
  root: MapPhaseTreeNode,
) {
  const blocks = new MapPhaseTreeNode('blocks', root).connectParent();

  const blockNodes = ampStructure.blockPhases.map((block, blockIndex) => {
    const blockNode = new MapPhaseTreeNode(
      'block-' + blockIndex,
      blocks,
    ).connectParent(blockIndex === 0 ? AMPModifiers.default : undefined);
    return createAMPSingleBlockPhaseTree(ampStructure, {
      blockNode,
      block,
      blockIndex,
    });
  });
  blockNodes.reduce((acc, c) => c.transitionFrom(AMPModifiers.default, acc));
  return blocks;
}

function createAMPSingleBlockPhaseTree(
  ampStructure: AmpStructure,
  blockDescriptor: BlockPhaseDescriptor<AmpBlockPhase>,
) {
  const { blockNode, block } = blockDescriptor;
  const instructionsNode = new MapScreenTreeNode(
    'instructions',
    blockNode,
    new AmpInstructionsData(
      new InstructionsContent(
        block.instructions.welcomeScreenContent.content,
        block.instructions.welcomeScreenContent.nextButton,
      ),
      'block',
    ),
  )
    .connectParent(AMPModifiers.default)
    .controlRequest(
      OptionsStereotypes.Confirm.Control.defineTransition(undefined, () =>
        DefaultTransitionAction.next(AMPModifiers.default),
      ),
    );

  const trialsNode = new MapPhaseTreeNode('trials', blockNode)
    .connectParent()
    .transitionFrom(AMPModifiers.default, instructionsNode);

  const trialNodes = block.trialStimuli.map((trial, trialIndex) => {
    return createAmpSingleTrialPhaseTree(ampStructure, {
      ...blockDescriptor,
      trialIndex,
      trial,
      trialNode: new MapPhaseTreeNode(
        'trial-' + trialIndex,
        trialsNode,
      ).connectParent(trialIndex === 0 ? AMPModifiers.default : undefined),
    });
  });
  trialNodes.reduce((acc, c) => c.transitionFrom(AMPModifiers.default, acc));
  return blockNode;
}
