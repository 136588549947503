import {
  ViewContainerFactory,
  ViewContentContainerFactory,
} from '../../view-content-container';
import { SelectorNodeMap } from '../../selectors/selector-tree';
import { ViewScaler } from '../scaler/view-scaler';
import { View } from './view';
import { StateSelector } from '../state-selector';
import { Selector } from '../../selectors/selector';

export abstract class AbstractView implements View {
  parent?: View;
  children: View[] = [];
  readonly containerFactory: ViewContainerFactory;
  public name: string = '';

  constructor(
    public readonly grows: boolean,
    public readonly selector: Selector<any>,
    containerFactory?: ViewContainerFactory,
    public readonly stateSelector?: StateSelector<any>,
  ) {
    this.containerFactory =
      containerFactory ??
      new ViewContentContainerFactory({
        vertical: 'center',
        horizontal: 'center',
      });
  }

  get pathName() {
    return (this.parent?.pathName ?? '') + '.' + this.name;
  }

  abstract createViewScaler(
    viewScalerMap: Map<View, ViewScaler>,
    selectorNodeMap: SelectorNodeMap,
  ): ViewScaler;

  init(name: string, parent?: View) {
    this.name = name;
    this.parent = parent ?? this.parent;
    this.initView(name);
    this.children = this.initChildren(name);
    this.children.forEach((ch) => {
      ch.parent = this;
    });
    return this;
  }

  protected initView(name: string) {}

  protected initChildren(name: string): View[] {
    return [];
  }
}
