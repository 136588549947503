import React, { FunctionComponent } from 'react';
import { useField } from 'formik';
import { TextField, TextFieldProps } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { MediaItemSnapshotInput, MediaItemSnapshotScope } from '../../API';
import { UiInput } from './types';

type CommaSeparatedTextStimuliPickerProps = UiInput<MediaItemSnapshotInput[]> &
  TextFieldProps;

const CommaSeparatedTextStimuliPicker: FunctionComponent<
  CommaSeparatedTextStimuliPickerProps
> = (props) => {
  const [, meta, helpers] = useField<typeof props.value>(props.name);

  const [localValue, setLocalValue] = React.useState(
    meta.value
      ? meta.value.map((s, i) => (i === 0 ? s.text : ` ${s.text}`)).join(', ')
      : '',
  );

  const onChangeTextField = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(ev.target.value);
    const values = ev.target.value.split(',');

    const mediaItemsSnaps: MediaItemSnapshotInput[] = values
      .map((s) => {
        const textSnapshot: MediaItemSnapshotInput = {
          id: uuid(),
          scope: MediaItemSnapshotScope.PRIVATE,
          text: s.trim(),
          tags: [],
        };
        return textSnapshot;
      })
      .filter((m) => m.text);
    helpers.setValue(mediaItemsSnaps);
  };

  return (
    <TextField
      fullWidth
      multiline
      value={localValue}
      onChange={onChangeTextField}
      error={meta.touched && meta.error !== undefined}
      helperText={(meta.touched && meta.error) || ''}
      {...props}
    />
  );
};

export default CommaSeparatedTextStimuliPicker;
