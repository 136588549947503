import React, { FC } from 'react';
import { Box } from '@mui/material';
import { TextDisplay } from '../TextDisplay';

interface ColorDisplayProps {
  label: string;
  value?: string | null;
}

export const ColorDisplay: FC<ColorDisplayProps> = (props) => {
  return (
    <TextDisplay
      label={props.label}
      value={props.value}
      suffix={
        <Box
          sx={{
            borderRadius: '50%',
            border: 1,
            height: 24,
            width: 24,
            bgcolor: props.value,
          }}
        />
      }
    />
  );
};
