import React, { useMemo } from 'react';
import { useIsAdmin } from '../../auth/auth.hook';
import { RequireAuth } from '../../auth/RequiredAuth';
import { Storage } from 'aws-amplify';
import {
  DataGridPro,
  GridActionsCellItem,
  GridEnrichedColDef,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { Paper } from '@mui/material';
import { DocumentationFile } from '../../API';
import { useConfirm } from 'material-ui-confirm';
import { Delete, Download } from '@mui/icons-material';
import { useDeleteFileMutation, useListFilesQuery } from './filesApi';
import { CreateDocumentationFileDialog } from './CreateDocumentationFileDialog';

const Files = () => {
  const isAdmin = useIsAdmin();
  const confirm = useConfirm();
  const { data: files, isLoading: isListLoading } = useListFilesQuery();
  const [deleteFile, { isLoading: isDeleteLoading }] = useDeleteFileMutation();

  const isLoading = isListLoading || isDeleteLoading;

  const columns: GridEnrichedColDef<DocumentationFile>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 2,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 2,
      },
      {
        field: 'actions',
        type: 'actions',
        flex: 1,
        getActions: (params) => [
          ...(isAdmin
            ? [
                <GridActionsCellItem
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                  showInMenu={false}
                  placeholder=""
                  label={'Delete'}
                  icon={<Delete />}
                  onClick={() => {
                    confirm({
                      title: 'Are you sure you want to delete this file?',
                      description: 'This action is irreversible.',
                    })
                      .then(() => deleteFile(params.row))
                      .catch(() => {});
                  }}
                />,
                <GridActionsCellItem
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                  showInMenu={false}
                  placeholder=""
                  label={'Download'}
                  icon={<Download />}
                  onClick={async () => {
                    const link = document.createElement('a');
                    const url = await Storage.get(params.row.s3Key);
                    link.href = url as string;
                    link.target = '_blank';
                    link.click();
                  }}
                />,
              ]
            : [
                <GridActionsCellItem
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                  showInMenu={false}
                  placeholder=""
                  label={'Download'}
                  icon={<Download />}
                  onClick={async () => {
                    const link = document.createElement('a');
                    const url = await Storage.get(params.row.s3Key);
                    link.href = url as string;
                    link.target = '_blank';
                    link.click();
                  }}
                />,
              ]),
        ],
      },
    ],
    [confirm, deleteFile, isAdmin],
  );

  return (
    <RequireAuth>
      <Paper sx={{ minHeight: 600 }}>
        <DataGridPro
          columns={columns}
          rows={files ?? []}
          loading={isLoading}
          components={{
            Toolbar: () => (
              <GridToolbarContainer>
                {isAdmin && <CreateDocumentationFileDialog />}
                <GridToolbarFilterButton
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                  placeholder=""
                  sx={{ m: 1 }}
                />
              </GridToolbarContainer>
            ),
          }}
        />
      </Paper>
    </RequireAuth>
  );
};

export default Files;
