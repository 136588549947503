import { PickingSet } from '../PickingSet';
import { PickingError } from './PickingError';

export class PickingSetError extends PickingError {
  constructor(
    public pickingSet: PickingSet<any>,
    message: string,
    public parentException?: PickingError,
  ) {
    super(message);
  }
}
