import { PickingStrategy } from '../../../../API';
import { weightedSet } from '../../pickingSet/weightedSet/WeightedSet';
import { newPickNCollect } from '../../pickingSet/picking-set';
import { createPickingSet } from '../../pickingSet/picking-strategy';
import { CombinedPickingSet } from '../../pickingSet/CombinedPickingSet/CombinedPickingSet';
import { NestedPickingSet } from '../../pickingSet/NestPickingSet/NestPickingSet';
import { Presentable } from '../../Presentable/Presentable';

import { WswMediaPool } from '../loader/loading/load-wsw-media';

type WswAttributeType = 'main' | 'additional' | 'criteria';

export interface WSWCategory {
  name: string;
  presentations: Presentable[];
}

export interface WSWAttribute {
  pickingStrategy: PickingStrategy;
  categories: WSWCategory[];
}

export interface WSWAttributeCombination {
  mainAttribute: number;
  additionalAttribute: number | null;
  criteriaAttribute: number | null;
  amount: number;
}

export interface WSWAttributeConfiguration {
  pickingStrategy: PickingStrategy;
}
export interface WSWPhaseConfiguration {
  main: WSWAttributeConfiguration;
  additional: WSWAttributeConfiguration | null;
  criteria: WSWAttributeConfiguration;
  combinations: WSWAttributeCombination[];
}

function createAttributePickersNew(
  type: WswAttributeType,
  pickingStrategy: PickingStrategy,
  pool: WswMediaPool['attributes'],
) {
  return pool
    .select({
      type,
    })
    .collectGrouped(['category'])
    .map((cat, catIndex) =>
      createPickingSet(
        'attribute-picker-' + type + '-' + catIndex,
        pickingStrategy,
        cat,
      ),
    );
}

export function pickWSWUniverseNew(
  configuration: WSWPhaseConfiguration,
  pool: WswMediaPool['attributes'],
) {
  const pickers = {
    main: createAttributePickersNew(
      'main',
      configuration.main.pickingStrategy,
      pool,
    ),
    additional: configuration.additional
      ? createAttributePickersNew(
          'additional',
          configuration.additional.pickingStrategy,
          pool,
        )
      : null,
    criteria: createAttributePickersNew(
      'criteria',
      configuration.criteria.pickingStrategy,
      pool,
    ),
  };
  const distractPicker = createPickingSet(
    'distract-picker',
    PickingStrategy.WITH_REPLACEMENT,
    [null],
  );

  const weightedPickingSet = weightedSet(
    configuration.combinations.map((combination) => [
      new CombinedPickingSet({
        mainAttribute: pickers.main[combination.mainAttribute],
        additionalAttribute:
          (combination.additionalAttribute !== null
            ? pickers.additional?.[combination.additionalAttribute]
            : undefined) ?? distractPicker,
        criteriaAttribute:
          combination.criteriaAttribute === null
            ? distractPicker
            : pickers.criteria[combination.criteriaAttribute],
      }),
      combination.amount,
    ]),
  );
  const combinedSets = NestedPickingSet.Create(
    'combination-picker',
    PickingStrategy.PERMUTATION,
    weightedPickingSet,
  );
  return newPickNCollect(combinedSets, weightedPickingSet.length);
}
