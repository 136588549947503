import {
  createStandAloneProvider,
  createSurveyToolProvider,
} from './baseTestContext/context/baseTestContext';
import { TestProvider } from './baseTestContext/context/ContextTypes';
import { TestInvocationParams } from './baseTestContext/invocation-params/test-invocation-params';
import { PROVIDER_NAME_URL_PARAM } from './testUrl/createTestUrl';
import { DeviceMode, DeviceModeList } from './deviceInfo/DeviceMode';
import { useGetSurveyProvidersNoAuthQuery } from '../../../survey-providers/surveyProviderApi';
import { useMemo } from 'react';
import { ConstructionDebugger } from '../debug/construction-debugger';
import { RunnerAppConfiguration } from './baseTestContext/app-configuration/runner-app-configuration';

export const limeSurveyProvider = createSurveyToolProvider('limeSurvey', {
  surveyId: '{SID}',
  surveyProbandId: '{SAVEDID}',
});

export const uniparkProvider = createSurveyToolProvider('unipark', {
  surveyId: '#syid#',
  surveyProbandId: '#lfdn#',
});

export const efsProvider = createSurveyToolProvider('efs', {
  surveyId: '#syid#',
  surveyProbandId: '#lfdn#',
});
/*
export const surveyToolProviders = [
  limeSurveyProvider,
  uniparkProvider,
  efsProvider,
];*/

export const standAloneProvider = createStandAloneProvider('standAlone');
/*

export const TestProviderMap: Record<string, TestProvider> = Object.fromEntries(
  [standAloneProvider, ...surveyToolProviders].map((p) => [p.providerName, p]),
);
*/

export const OVERRIDE_DEVICE_TYPE_URL_PARAM = 'enforceDeviceType';

export type SurveyProviderLookupMap = Record<string, TestProvider>;
export function useSurveyProviderLookupMap(variables?: {
  surveyId?: string;
}): SurveyProviderLookupMap | undefined {
  const { data: surveyProviders, error } = useGetSurveyProvidersNoAuthQuery();
  return useMemo(() => {
    if (error) {
      return { [standAloneProvider.providerName]: standAloneProvider };
    }
    return surveyProviders
      ? {
          ...Object.fromEntries(
            surveyProviders.map(
              (sP) =>
                [
                  sP.slug,
                  createSurveyToolProvider(sP.slug, {
                    surveyId:
                      sP.snippetConfiguration.surveyIdReplacer ??
                      variables?.surveyId ??
                      '',
                    surveyProbandId: sP.snippetConfiguration.probandIdReplacer,
                  }),
                ] as const,
            ),
          ),
          [standAloneProvider.providerName]: standAloneProvider,
        }
      : undefined;
  }, [error, surveyProviders]);
}
export function createFromUrl(
  urlParams: TestInvocationParams,
  surveyProviderLookupMap: SurveyProviderLookupMap,
  strictMode = true,
  appConfiguration?: RunnerAppConfiguration,
) {
  const provider =
    surveyProviderLookupMap[
      urlParams.configurationParams.get(PROVIDER_NAME_URL_PARAM) ?? ''
    ];
  if (provider === undefined) {
    throw new Error(
      'no provider found for provider name ' +
        (urlParams.configurationParams.get(PROVIDER_NAME_URL_PARAM) ?? ''),
    );
  }
  let testBaseContext = provider.createContext(
    urlParams,
    surveyProviderLookupMap,
    strictMode,
    appConfiguration,
  );
  const deviceTypeOverride = urlParams.configurationParams.get(
    OVERRIDE_DEVICE_TYPE_URL_PARAM,
  );
  if (deviceTypeOverride !== null) {
    if (!DeviceModeList.includes(deviceTypeOverride as any)) {
      throw new Error('invalid device type override: ' + deviceTypeOverride);
    }
    if (testBaseContext.providerContext.debug) {
      ConstructionDebugger.initDebug();
    }
    testBaseContext = {
      ...testBaseContext,
      deviceInfo: {
        ...testBaseContext.deviceInfo,
        mode: deviceTypeOverride as DeviceMode,
      },
    };
  }
  return testBaseContext;
}
