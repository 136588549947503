import { Subject } from 'rxjs';
import { TestFlowSnapshot } from '../../graph-state/test-director';
import React from 'react';

export function ControlDisplay({
  subject,
}: {
  subject: Subject<TestFlowSnapshot>;
}) {
  const [state, setState] = React.useState<TestFlowSnapshot>();
  React.useEffect(() => {
    const subs = subject.subscribe((next) => {
      setState(next);
    });
    return () => subs.unsubscribe();
  }, [subject]);
  return (
    <ul>
      {state &&
        state.screenNode.node.screenData.controlTransitions.map((ct) => {
          return (
            <li>
              {ct.controlRequest.stereotype.name} -{' '}
              {/*ct.actionFactory.instanceAction(undefined as any, new StateHolder()).transitionModifier.code ?? "default"*/}
            </li>
          );
        })}
    </ul>
  );
}
