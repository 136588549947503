import { standAloneProvider } from '../../../features/subject/testRunner/state/TestProviderMap';
import { TestsMetaData, TestType } from '../../../features/subject/types';
import { createTestInvocationParams } from '../../../features/subject/testRunner/state/baseTestContext/invocation-params/test-invocation-params';

export function fakeTestContext(testType: TestType, testId: string) {
  return standAloneProvider.createContext(
    createTestInvocationParams({
      testId,
      testType: TestsMetaData[testType].identifier,
      configurationParams: {
        provider: 'standAlone',
        mode: 'preview',
        debug: 'true',
      },
    }),
    { standAlone: standAloneProvider },
    true,
  );
}
