import { BehaviorSubject, Observable } from 'rxjs';
import {
  BasicDimension,
  DrawableDimension,
} from '../../media/drawable-dimensions';
import React, { FC, MutableRefObject } from 'react';

export interface MeasurableComponent {
  element: FC;
  sizeSubscriber: Observable<BasicDimension | null>;
}

export type MeasureRefComponent<E extends HTMLElement> = {
  containerRef: MutableRefObject<E | null>;
};

export function createMeasurableWrapperComponent<E extends HTMLElement>(
  WrappedComponent: FC<MeasureRefComponent<E>>,
): MeasurableComponent {
  const sizeSubject = new BehaviorSubject<BasicDimension | null>(null);
  const Wrapper = () => {
    const containerRef = React.useRef<E | null>(null);
    React.useEffect(() => {
      const current = containerRef.current;
      if (current) {
        sizeSubject.next(
          DrawableDimension.fromBoundingRect(current.getBoundingClientRect()),
        );
      }
    });
    return <WrappedComponent containerRef={containerRef} />;
  };
  return { element: Wrapper, sizeSubscriber: sizeSubject };
}
