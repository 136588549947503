import { ObjectTransformer } from '../../media/drawable/drawable-collector';
import { View } from './views/view';

export class ViewDefinition {
  readonly view: View;
  readonly allViews: View[];

  constructor(view: View, allViews: View[]) {
    this.view = view;
    this.allViews = allViews;
  }

  static Leaf(view: View) {
    return new ViewDefinition(view, [view]);
  }

  static Group(view: View, subs: ViewDefinitionCollection<any>) {
    return new ViewDefinition(view, [view, ...subs.allViews]);
  }
}

export type ViewDefinitionMap<N extends string> = Record<N, ViewDefinition>;

export class ViewDefinitionCollection<N extends string> {
  constructor(public definitionMap: ViewDefinitionMap<N>) {}

  get allViews() {
    return ObjectTransformer.typedEntries(this.definitionMap).flatMap(
      ([, d]) => d.allViews,
    );
  }

  static Create<N extends string>(map: ViewDefinitionMap<N>) {
    return new ViewDefinitionCollection(map);
  }
}
