import { View } from './composer/views/view';
import { LayoutBox } from './composer/boxes/layout-box';
import { FC } from 'react';
import { ViewScaler } from './composer/scaler/view-scaler';
import { ObjectTransformer } from '../media/drawable/drawable-collector';
import { ViewBoxesScaler } from './composer/scaler/view-boxes-scaler';
import { BoxProvider } from './composer/boxes/box-provider';
import { DefaultViewBoxesScaler } from './composer/scaler/default-view-boxes-scaler';
import { ContainerProvider } from './composer/views/render-container-provider';

export interface LayoutBoxBuilder<N extends string> {
  growing: (...views: N[]) => LayoutBox;
  fixed: (...views: N[]) => LayoutBox;
}

export class ViewBoxes<T extends string> {
  public boxes: LayoutBox[];
  private parentView?: View;

  constructor(public boxMap: Record<T, LayoutBox>) {
    this.boxes = Object.values(boxMap);
  }

  get name(): string {
    return `${this.parentView?.pathName ?? 'unknown'}:boxes`;
  }

  initBoxes(parentView: View): ViewBoxes<T> {
    this.parentView = parentView;
    return this;
  }

  createBoxesScaler(viewScalerMap: Map<View, ViewScaler>): ViewBoxesScaler {
    return new DefaultViewBoxesScaler(this, viewScalerMap);
  }

  static Define<N extends string, T extends string>(
    factory: (builder: LayoutBoxBuilder<N>) => Record<T, LayoutBox>,
  ): (map: Record<N, View>) => ViewBoxes<T> {
    const builder = (map: Record<N, View>) =>
      ({
        fixed: (...views) =>
          new LayoutBox(
            false,
            views.map((v) => map[v]),
          ),
        growing: (...views) =>
          new LayoutBox(
            true,
            views.map((v) => map[v]),
          ),
      }) as LayoutBoxBuilder<N>;
    return (map: Record<N, View>) =>
      new ViewBoxes<T>(
        ObjectTransformer.of(factory(builder(map))).mapValues((v, k) =>
          v.init(k),
        ).result,
      );
  }
}

export interface BoxRenderProvider<T extends string> extends BoxProvider<T> {}

export type LayoutContentComponentFactory<T extends string> = (
  containerProvider: ContainerProvider,
  boxProvider: BoxProvider<T>,
) => FC;
