import React, { FC } from "react";
import { WSWTest } from "../../../../../API";
import { WswPresentationPhaseView } from "./WswPresentationPhaseView";

interface WswTestPhaseViewProps {
  test: WSWTest;
}

export const WswTestPhaseView: FC<WswTestPhaseViewProps> = (props) => {
  return (
    <React.Fragment>
      {props.test.testPhase &&
        <WswPresentationPhaseView
          phase={props.test.testPhase}
          label={"Testphase"}
        />}
    </React.Fragment>
  );
};
