import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  Tooltip,
  Typography,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import {
  MediaItemSnapshotInput,
  PodtaBoxInput,
  PodtaBoxPosition,
} from '../../../../../API';
import MediaOffscreenCanvas from '../../../../subject/MediaOffscreenCanvas';
import { TypeObject } from '../../../../subject/utils';
import { boxColors, createPodtaCanvasEditor } from './podtaCanvasFactory';

export type PodtaSceneSettingsState = {
  mediaItem: MediaItemSnapshotInput;
  boxes: PodtaBoxInput[];
};

type SceneBoxEditorProps = {
  state: PodtaSceneSettingsState;
  setBoxes: (boxes: PodtaBoxInput[]) => any;
  offscreenCanvas: MediaOffscreenCanvas;
  withMenu: boolean;
  isLockedHeight?: boolean;
  key: string;
  active?: boolean;
};

type BoxListItemProps = {
  selected: boolean;
  deleteItem: (index: number) => void;
  dimensions: { width: number; height: number };
  box: PodtaBoxInput;
  index: number;
  toggleSelection: (index: number) => void;
  setBoxPosition: (pos: PodtaBoxPosition) => void;
};

const BoxListItem: FunctionComponent<BoxListItemProps> = ({
  index,
  toggleSelection,
  selected,
  box,
  deleteItem,
  setBoxPosition,
}) => {
  return (
    <ListItem
      key={index}
      button
      onClick={() => toggleSelection(index)}
      selected={selected}
    >
      <ListItemAvatar>
        <Avatar
          style={{
            width: 24,
            height: 24,
            color: boxColors[index],
            backgroundColor: boxColors[index],
          }}
        />
      </ListItemAvatar>
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="change pos"
          onClick={() => {
            setBoxPosition(
              box.position === PodtaBoxPosition.LEFT
                ? PodtaBoxPosition.RIGHT
                : PodtaBoxPosition.LEFT,
            );
          }}
          size="small"
        >
          <Typography fontSize="inherit">{box.position.charAt(0)}</Typography>
        </IconButton>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => deleteItem(index)}
          size="small"
        >
          <Delete fontSize="small" />
        </IconButton>
      </ListItemSecondaryAction>
      <Typography>{`(${box.width},${box.height})`}</Typography>
    </ListItem>
  );
};

const PodtaSceneBoxEditor: FunctionComponent<SceneBoxEditorProps> = ({
  state,
  setBoxes,
  offscreenCanvas,
  isLockedHeight,
  withMenu,
  key,
  active,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [boxSelection, setBoxSelection] = useState<number>(0);

  const addBox = () => {
    setBoxSelection(state.boxes.length);
    setBoxes([
      ...state.boxes,
      {
        left: 100,
        top: 100,
        width: state.boxes[state.boxes.length - 1]?.width || 300,
        height: state.boxes[state.boxes.length - 1]?.height || 300,
        position: PodtaBoxPosition.LEFT,
      },
    ]);
  };

  const removeBox = (index: number) => () => {
    setBoxes(state.boxes.filter((_v, i) => i !== index));
  };

  useEffect(() => {
    if (active && canvasRef.current) {
      const c = canvasRef.current;
      const canvasHandler = createPodtaCanvasEditor(
        canvasRef.current,
        state,
        offscreenCanvas,
        setBoxes,
        boxSelection,
        isLockedHeight,
      );
      TypeObject.keys(canvasHandler).forEach((eventListenerKey) =>
        withMenu
          ? c.addEventListener(
              eventListenerKey,
              canvasHandler[eventListenerKey],
            )
          : (e: any) => e.preventDefault(),
      );
      return () =>
        TypeObject.keys(canvasHandler).forEach((evListenerMapKey) =>
          c.removeEventListener(
            evListenerMapKey,
            canvasHandler[evListenerMapKey],
          ),
        );
    }
  }, [
    active,
    boxSelection,
    isLockedHeight,
    offscreenCanvas,
    setBoxes,
    state,
    state.boxes,
    withMenu,
  ]);

  const canvasHeight = 450;
  const listWidth = 220;
  const listTranslate =
    (offscreenCanvas.width * canvasHeight) / offscreenCanvas.height / 2 +
    listWidth / 2;

  return (
    <div
      key={key}
      style={{
        height: canvasHeight,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {!withMenu && state.mediaItem.image ? (
        <canvas
          ref={canvasRef}
          width={offscreenCanvas.width}
          height={offscreenCanvas.height}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      ) : (
        <React.Fragment>
          <canvas
            ref={canvasRef}
            width={offscreenCanvas.width}
            height={offscreenCanvas.height}
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
          {withMenu && (
            <List
              style={{
                width: listWidth,
                // overflowY: "auto",
                position: 'absolute',
                transform: `translate(${listTranslate}px)`,
              }}
            >
              {state.boxes.map((b, i) => (
                <BoxListItem
                  setBoxPosition={(pos) =>
                    setBoxes(
                      state.boxes.map((b, bi) => {
                        if (bi !== i) {
                          return b;
                        }
                        const cWidth = canvasRef.current?.width ?? 0;
                        const newLeft = cWidth - b.left - b.width;
                        return { ...b, left: newLeft, position: pos };
                      }),
                    )
                  }
                  dimensions={{
                    width: offscreenCanvas.width,
                    height: offscreenCanvas.height,
                  }}
                  selected={boxSelection === i}
                  box={b}
                  index={i}
                  deleteItem={removeBox(i)}
                  toggleSelection={setBoxSelection}
                />
              ))}
              <ListItem button onClick={addBox}>
                <ListItemAvatar>
                  <Tooltip title="Add Box">
                    <Avatar>
                      <Add fontSize="small" />
                    </Avatar>
                  </Tooltip>
                </ListItemAvatar>
              </ListItem>
            </List>
          )}
        </React.Fragment>
      )}
    </div>
  );
};
export default PodtaSceneBoxEditor;
