import { TranslationStoreResult } from './translation-store';

export type TranslationKeyValueTypeMap = { text: string; textArray: string[] };

export const translationValueTypeHandlerMap = {
  text: (value: TranslationStoreResult) => {
    if (value === undefined) return undefined;
    return Array.isArray(value) ? value[0] : value;
  },
  textArray: (value: TranslationStoreResult) => {
    if (value === undefined) return undefined;
    return Array.isArray(value) ? value : [value];
  },
} satisfies {
  [k in keyof TranslationKeyValueTypeMap]: (
    value: TranslationStoreResult,
  ) => TranslationKeyValueTypeMap[k] | undefined;
};
