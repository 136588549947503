import {
  ConditionalAnswerType,
  ConditionalQuestionSetInput,
  DistractionPhaseInput,
  FontFace,
  FontStyleInput,
  GroupConstructionInput,
  KeyboardSettingsInput,
  MediaItemSnapshotInput,
  MediaItemSnapshotScope,
  OptionsPosition,
  PresentationPhaseInput,
  QuestionType,
  TestQuestionInput,
  TestStyleInput,
  TextAlignment,
  TimedMediaItemInput,
  WeightedStyledTextInput,
  WSWGroupInput,
} from '../../../../API';
import { v4 as uuid } from 'uuid';

export const createEmptyPhase = ():
  | DistractionPhaseInput
  | PresentationPhaseInput => ({
  questionsSet: createEmptyConditionalQuestionSet(),
});

export const createEmptyConditionalQuestion = () => ({
  answerType: ConditionalAnswerType.CONFIRM,
  confirmText: 'Confirm',
  declineText: 'Decline',
  questionText: 'Type your question here',
});

export const createEmptyConditionalQuestionSet =
  (): ConditionalQuestionSetInput => ({
    randomizeSequence: true,
    questionsBefore: [],
    questionsAfter: [],
  });

export const createEmptyWswGroupConstructions = (): GroupConstructionInput => ({
  conditions: {
    condition1: 0,
    condition2: 1,
    condition3: 2,
  },
  fakeQuestions: {
    randomizeSequence: true,
    questionsBefore: [],
    questionsAfter: [],
  },
  groups: [],
  randomizeGroupColors: true,
});

export const createEmptyWswGroup = (): WSWGroupInput => ({
  color: '#fff',
  name: 'New Group',
  probability: 1,
  questionSet: {
    questionsBefore: [],
    questionsAfter: [],
    conditionalQuestion: null,
    randomizeSequence: true,
  },
});

export const createEmptyTestQuestion = (
  type: QuestionType,
): TestQuestionInput => {
  switch (type) {
    case QuestionType.MULTIPLE_CHOICE:
      return {
        type,
        questionText: '',
        mediaItemSnapshot: null,
        scale: null,
        stimulusTimeout: 0,
        multiple: {
          options: ['First Option', 'Second Option'],
          randomized: true,
        },
      };
    case QuestionType.IMAGE_MULTIPLE_CHOICE:
      return {
        type,
        questionText: '',
        mediaItemSnapshot: null,
        scale: null,
        stimulusTimeout: 0,
        imageMultipleChoice: {
          options: [],
          randomized: true,
        },
      };
    case QuestionType.SCALE:
      return {
        type,
        mediaItemSnapshot: null,
        questionText: '',
        multiple: null,
        stimulusTimeout: 0,
        scale: {
          invert: false,
          min: { name: 'Min', value: 1 },
          max: { name: 'Max', value: 11 },
        },
      };
    case QuestionType.TEXT:
      return {
        type,
        mediaItemSnapshot: null,
        questionText: '',
        multiple: null,
        scale: null,
        stimulusTimeout: 0,
      };
    default:
      return {
        type,
        mediaItemSnapshot: null,
        questionText: '',
        multiple: null,
        scale: null,
        stimulusTimeout: 0,
      };
  }
};

export const createEmptyKeyboardSettings = (): KeyboardSettingsInput => ({
  left: '',
  right: '',
  confirm: '',
});

export const createDefaultFontStyle = (
  alignment: TextAlignment,
): FontStyleInput => ({
  fontFace: FontFace.ARIAL,
  fontSize: 14,
  color: 'black',
  alignment,
});

export const createWeightedStyledTextInput = (
  initialValue: string,
  weight: number,
  alignment = TextAlignment.CENTER,
): WeightedStyledTextInput => ({
  value: initialValue,
  fontStyle: createDefaultFontStyle(alignment),
  weight,
});

export const createDefaultStyle = (): TestStyleInput => ({
  maxWidth: 100,
  backgroundColour: 'white',
  stimulusTextStyle: createDefaultFontStyle(TextAlignment.CENTER),
  mobileStimulusTextStyle: null,
  optionsPosition: OptionsPosition.TOP,
  optionsPositionMobile: OptionsPosition.MIDDLE,
});

export const createEmptyMediaItemSnapshot = (): MediaItemSnapshotInput => ({
  id: uuid(),
  tags: [],
  scope: MediaItemSnapshotScope.PRIVATE,
});

export const createEmptyDefaultFixation = (): TimedMediaItemInput => ({
  interval: 500,
  mediaItem: createEmptyMediaItemSnapshot(),
});
