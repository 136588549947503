import { PickingStrategy, TextAlignment } from '../../../../../API';
import { Podt } from '../../types';
import { PodtMediaPool, PodtOptionsType } from '../loading/podt-loader';

import { createDefaultFontStyle } from '../../../testAdmin/testEditor/initialEditorStates';
import { TestResourceAccessor } from '../../../../subject/testRunner/media/test-resource-loader';
import {
  createCounterBalancingArranger,
  StandardOptionArranger,
  TestOptionCategories,
  TestOptionCategory,
} from '../../../../subject/testRunner/media/options-test';
import {
  TextMediaStyle,
  TextStyleDescriptor,
  UnitFontSizes,
} from '../../../../subject/testRunner/media/text-drawer';
import { Podta } from '../../../PODTA/types';
import { pickPodtaBlockNew, pickPodtBlockNew } from '../../creator/podt-picker';
import {
  determineCounterBalancingGroup,
  determineLROptionsTestPickingStrategy,
  TestInstructionsPhase,
} from '../../../../subject/testRunner/utils/structure-utils';
import {
  describePodtBlock,
  PodtBlockDescriptor,
  PodtBlockPhase,
  preparePodtBlockTexts,
} from './podt-block-structure';

export interface PodtOrPodtaStructure<P extends Podt | Podta> {
  accessor: TestResourceAccessor<P>;
  config: PodtGlobalConfig;
  pool: PodtMediaPool;
  welcomePhase: TestInstructionsPhase;
  blockPhases: PodtBlockPhase[];
  endPhase: TestInstructionsPhase;
}

export type PodtStructure = PodtOrPodtaStructure<Podt>;
export type PodtaStructure = PodtOrPodtaStructure<Podta>;

export interface TrialFeedback {
  correctRejectionText: TrialFeedbackEntry;
  hitText: TrialFeedbackEntry;
  missText: TrialFeedbackEntry;
  falseAlarmText: TrialFeedbackEntry;
  noResponseText: TrialFeedbackEntry;
  pointStyle: TextMediaStyle;
}

export interface TrialFeedbackEntry {
  style: TextMediaStyle;
  text: string;
  pointDifference: number;
}

export interface PodtGlobalConfig {
  pickingStrategy: PickingStrategy;
  options: TestOptionCategories<PodtOptionsType>;
  maxScenesPerTrial: number;
  trialFeedback: TrialFeedback | undefined;
}

export function createPodtStructure(
  accessor: TestResourceAccessor<Podt>,
  pool: PodtMediaPool,
): PodtStructure {
  return createPodtOrPodtaStructure<Podt>(
    accessor,
    pool,
    pickPodtBlockNew,
    true,
  );
}

export function createPodtOrPodtaStructure<P extends Podt | Podta>(
  accessor: TestResourceAccessor<P>,
  pool: PodtMediaPool,
  picker: P extends Podt ? typeof pickPodtBlockNew : typeof pickPodtaBlockNew,
  counterBalancing: boolean,
): PodtOrPodtaStructure<P> {
  const podt = accessor.modelData;
  const podtConfig = createPodtGlobalConfig(accessor, pool, counterBalancing);

  function createPodtBlockPhase(block: Podt['blocks'][number]): PodtBlockPhase {
    const blockDescriptor: PodtBlockDescriptor = describePodtBlock(
      podtConfig,
      block,
    );
    const trials = picker(
      blockDescriptor,
      pool,
      podt.scenes.maxAmountScenesPerTrial,
      podt.scenes.intervalConfig,
      podt.probabilityWithoutReplacement
        ? PickingStrategy.REFILLING_WITHOUT_REPLACEMENT
        : PickingStrategy.WITH_REPLACEMENT,
    );
    const instructionTexts = preparePodtBlockTexts(
      accessor.testContext,
      block,
      blockDescriptor.options,
      podt.keyboard,
    );

    return {
      blockIndex: block.index,
      blockName: block.name,
      blockType: block.type,
      options: blockDescriptor.options,
      intermediate: {
        fixation: pool.fixation
          ? {
              presentable: pool.fixation,
              interval: accessor.modelData.fixation!.interval,
            }
          : undefined,
      },
      trialStimuli: trials,
      instructions: {
        welcomeScreen: {
          content: instructionTexts.welcomeScreen as string,
          nextButton: accessor.modelData.nextLabel ?? 'Next',
        },
        preparationScreen: block.preparationScreen
          ? {
              content: instructionTexts.preparationScreen as string,
              interval: block.preparationScreen.interval,
            }
          : null,
        tips: block.tips
          ? {
              text: instructionTexts.tips ?? '',
              style: accessor.testContext.logic.deriveTextStyle(
                TextStyleDescriptor.ofFontStyle(
                  block.tips.fontStyle ??
                    createDefaultFontStyle(TextAlignment.CENTER),
                ),
              ),
            }
          : null,
      },
    };
  }

  return {
    config: podtConfig,
    accessor,
    pool,
    welcomePhase: {
      content: {
        content:
          accessor.testContext.deviceInfo.mode === 'mobile'
            ? podt.instructions.mobile ?? podt.instructions.desktop
            : podt.instructions.desktop,
        nextButton: podt.startLabel ?? 'Start',
      },
    },
    blockPhases: podt.blocks.map(createPodtBlockPhase),
    endPhase: {
      content: {
        content:
          accessor.testContext.deviceInfo.mode === 'mobile'
            ? podt.endScreen?.mobile ?? podt.endScreen?.desktop ?? ''
            : podt.endScreen?.desktop ?? '',
        nextButton: podt.finishLabel ?? 'Finish',
      },
    },
  };
}

function createPodtGlobalConfig(
  accessor: TestResourceAccessor<Podt | Podta>,
  pool: PodtMediaPool,
  counterBalancing: boolean,
): PodtGlobalConfig {
  const optionArranger = createCounterBalancingArranger(
    counterBalancing
      ? determineCounterBalancingGroup(accessor.modelData.counterbalancing)
      : null,
  );
  const trialFeedbackModel = accessor.modelData.trialFeedback;
  const createEntry = (
    item: Exclude<keyof TrialFeedback, 'pointStyle'>,
  ): TrialFeedbackEntry => {
    return {
      pointDifference: trialFeedbackModel![item]!.weight,
      style: accessor.testContext.logic.deriveTextStyle(
        TextStyleDescriptor.ofFontStyle(trialFeedbackModel![item].fontStyle),
      ),
      text: trialFeedbackModel![item].value,
    };
  };
  const trialFeedback: TrialFeedback | undefined = trialFeedbackModel
    ? {
        pointStyle: accessor.testContext.logic.deriveTextStyle(
          new TextStyleDescriptor(
            undefined,
            trialFeedbackModel.pointsFontsize
              ? UnitFontSizes.px(trialFeedbackModel.pointsFontsize)
              : undefined,
            undefined,
            undefined,
          ),
        ),
        correctRejectionText: createEntry('correctRejectionText'),
        hitText: createEntry('hitText'),
        falseAlarmText: createEntry('falseAlarmText'),
        noResponseText: createEntry('noResponseText'),
        missText: createEntry('missText'),
      }
    : undefined;
  return {
    pickingStrategy: determineLROptionsTestPickingStrategy(
      accessor.modelData.probabilityWithoutReplacement,
    ),
    options: new TestOptionCategories<PodtOptionsType>(
      {
        response: new TestOptionCategory<'response'>(
          {
            mediaInstance: pool.response.first.value,
            optionVariant: 'first',
            optionCategory: 'response',
          },
          {
            mediaInstance: pool.response.second.value,
            optionVariant: 'second',
            optionCategory: 'response',
          },
          optionArranger,
        ),
      },
      {
        horizontal: 'in',
        vertical:
          accessor.testContext.deviceInfo.mode === 'mobile' &&
          accessor.modelData.style.optionsPositionMobile
            ? accessor.modelData.style.optionsPositionMobile
            : accessor.modelData.style.optionsPosition,
      },
      optionArranger as StandardOptionArranger,
    ),
    maxScenesPerTrial: accessor.modelData.scenes.maxAmountScenesPerTrial,
    trialFeedback,
  };
}
