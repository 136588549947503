import {
  createSlice,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import { Podta, PodtaResult } from '../types';
import { createInitialTestSliceState } from '../../utils';
import { TestType } from '../../../subject/types';
import {
  createPodtaAsync,
  deletePodtaAsync,
  fetchPodtaResults,
  fetchPodtaTest,
  fetchPodtaTests,
  updatePodtaAsync,
  updatePodtaStatusAsync,
} from './podta.thunk';

export const podtaSlice = createSlice({
  name: TestType.PODTA,
  initialState: createInitialTestSliceState<Podta, PodtaResult>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPodtaTest.fulfilled, (state, action) => {
        state.tests[action.payload.id] = action.payload;
      })
      .addCase(fetchPodtaTests.fulfilled, (state, action) => {
        state.tests = action.payload
          .sort(
            (a, b) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
          )
          .reduce(
            (result: { [id: string]: Podta }, currentValue: Podta | null) => {
              if (currentValue) {
                result[currentValue.id] = currentValue;
              }
              return result;
            },
            {},
          );
      })
      .addCase(fetchPodtaResults.fulfilled, (state, action) => {
        state.results[action.payload.testId] = action.payload;
      })
      .addCase(deletePodtaAsync.fulfilled, (state, action) => {
        const tests = { ...state.tests };
        delete tests[action.payload];
        state.tests = tests;
      })
      .addMatcher(
        isAnyOf(
          createPodtaAsync.fulfilled,
          updatePodtaAsync.fulfilled,
          updatePodtaStatusAsync.fulfilled,
        ),
        (state, action) => {
          state.tests[action.payload.id] = action.payload;
        },
      )
      .addMatcher(isPending(), (state, action) => {
        if (action.type.toUpperCase().includes(TestType.PODTA)) {
          state.isLoading = true;
        }
      })
      .addMatcher(isFulfilled(), (state) => {
        state.isLoading = false;
      })
      .addMatcher(isRejected(), (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

export default podtaSlice.reducer;
