import {
  DisplayData,
  Optional,
  OptionalValue,
} from '../../selectors/data-wrapper';
import { StateSelector } from '../state-selector';
import {
  asapScheduler,
  Observable,
  observeOn,
  Subject,
  Subscription,
} from 'rxjs';
import { TestFlowSnapshot } from '../../../graph-state/test-director';
import { ScreenNodeDataMap } from '../../selectors/selector-tree';
import { isEqual } from 'lodash';

import { MeasurableValue } from './measurable-value';

export class DisplayStateProcessor<D> {
  private display: 'display' | 'hide' = 'hide';
  private data: OptionalValue<D> = Optional.none<D>();
  private state: OptionalValue<any> = Optional.none();
  private stateSelector?: StateSelector<any>;

  readonly displayStream: Subject<MeasurableValue<'display' | 'hide'>> =
    new Subject<MeasurableValue<'display' | 'hide'>>();
  readonly dataStream: Subject<MeasurableValue<D>> = new Subject<
    MeasurableValue<D>
  >();
  readonly stateStream: Subject<MeasurableValue<OptionalValue<any>>> =
    new Subject<MeasurableValue<OptionalValue<any>>>();

  private flowSubscription: Subscription;

  constructor(
    protected code: string,
    flowStream: Observable<TestFlowSnapshot>,
    private nodeDataMap: ScreenNodeDataMap<D>,
    stateSelector?: StateSelector<any>,
  ) {
    this.stateSelector = stateSelector;
    this.flowSubscription = flowStream
      .pipe(observeOn(asapScheduler))
      .subscribe({
        next: (value) => {
          this.processValue(value);
        },
        complete: () => {
          this.displayStream.complete();
          this.dataStream.complete();
          this.stateStream.complete();
        },
        error: (e) => {
          this.displayStream.error(e);
          this.dataStream.error(e);
          this.stateStream.error(e);
        },
      });
  }

  protected processValue(value: TestFlowSnapshot) {
    const nodeData = this.nodeDataMap.getData(value.screenNode.node);
    if (!DisplayData.isNone(nodeData)) {
      if (DisplayData.isHide(nodeData)) {
        if (this.display !== 'hide') {
          this.display = 'hide';
          this.displayStream.next({
            value: this.display,
            measureProcessTime: (time) =>
              value.timeProcessor.processTime('renderEnd', time),
          });
        }
      } else {
        if (this.display !== 'display') {
          this.display = 'display';
          this.displayStream.next({
            value: this.display,
            measureProcessTime: (time) =>
              value.timeProcessor.processTime('renderEnd', time),
          });
          // console.log("process node", this.code, "dispatched display");
        }
        if (Optional.isValue(nodeData[1])) {
          if (
            Optional.isNone(this.data) ||
            !isEqual(this.data, nodeData[1][1])
          ) {
            this.data = nodeData[1];
            this.dataStream.next({
              value: nodeData[1][1],
              measureProcessTime: (time) =>
                value.timeProcessor.processTime('renderEnd', time),
            });
          }
        }
        if (this.stateSelector) {
          const currentState = this.stateSelector.selectState(
            value.stateHolder,
          );
          if (
            currentState[0] !== this.state[0] ||
            (Optional.isValue(currentState) &&
              (Optional.isNone(this.state) ||
                !isEqual(this.state[1], currentState[1])))
          ) {
            this.state = currentState;
            this.stateStream.next({
              value: currentState,
              measureProcessTime: (time) =>
                value.timeProcessor.processTime('renderEnd', time),
            });
          }
        }
      }
    } else {
      if (this.display !== 'hide') {
        this.display = 'hide';
        this.displayStream.next({
          value: this.display,
          measureProcessTime: (time) =>
            value.timeProcessor.processTime('renderEnd', time),
        });
      }
    }
  }

  close() {
    this.displayStream.complete();
    this.dataStream.complete();
    this.stateStream.complete();
    this.flowSubscription.unsubscribe();
  }
}
