import { StateSlice } from '../../../../subject/testRunner/graph-state/state-holder';

export const PODTBlockPointsSlice = new StateSlice('block-points', () => ({
  points: 0,
}));
export const PODTReactionSlice = new StateSlice('reaction', () => ({
  type: undefined as
    | undefined
    | 'noResponseText'
    | 'missText'
    | 'hitText'
    | 'correctRejectionText'
    | 'falseAlarmText',
}));
