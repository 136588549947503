import { MeasurableComponent } from './measurable-component';
import React, { FC } from 'react';

import { StyleCB } from '../composer/views/render-container-provider';

export class MeasurableComponentHolder {
  private component?: MeasurableComponent;
  readonly componentSignal: Promise<void>;
  private signalCallback!: () => void;

  constructor(
    public code: string,
    public factory: (styleCB?: StyleCB) => MeasurableComponent,
  ) {
    this.componentSignal = new Promise<void>((resolve) => {
      this.signalCallback = resolve;
    });
  }

  get measurableComponent(): MeasurableComponent {
    if (!this.component) {
      throw new Error(
        'measure component ' + this.component + ' has not been created',
      );
    }
    return this.component;
  }

  /*
  TODO: CAUTION: styleCB works only on first call to create for each name. As current data flow implies that create will be called excactly once for each box on each provider, this is no problem. It might cause strange errors if this constraint is violated or removed though
   */
  create(styleCB?: StyleCB): FC {
    if (!this.component) {
      this.component = this.factory(styleCB);
      this.signalCallback();
    }
    return this.component.element;
  }
}

export class MeasurableContainerHolder {
  private component?: MeasurableComponent;
  readonly componentSignal: Promise<void>;
  private signalCallback!: () => void;

  constructor(
    public code: string,
    public factory: (
      content: React.ReactNode,
      styleCB?: StyleCB,
    ) => MeasurableComponent,
  ) {
    this.componentSignal = new Promise<void>((resolve) => {
      this.signalCallback = resolve;
    });
  }

  get measurableComponent(): MeasurableComponent {
    if (!this.component) {
      throw new Error(
        'measure component ' + this.component + ' has not been created',
      );
    }
    return this.component;
  }

  /*
  TODO: CAUTION: styleCB works only on first call to create for each name. As current data flow implies that create will be called excactly once for each box on each provider, this is no problem. It might cause strange errors if this constraint is violated or removed though
   */
  create(content: React.ReactNode, styleCB?: StyleCB): JSX.Element {
    if (!this.component) {
      this.component = this.factory(content, styleCB);
      this.signalCallback();
    }
    const ElementComp = this.component.element;
    return <ElementComp />;
  }
}
