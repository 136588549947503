import React, { FC } from "react";
import { MultipleChoiceQuestion } from "../../../../../../API";
import { Box, Typography } from "@mui/material";

interface MultipleChoiceQuestionViewProps {
  multipleChoiceQuestion: MultipleChoiceQuestion;
}

export const MultipleChoiceQuestionView: FC<MultipleChoiceQuestionViewProps> = (props) => {
  return (
    <Box sx={{pl: 1}}>
      <Typography variant="caption" color="textSecondary">
        Options {props.multipleChoiceQuestion.randomized && "(Randomized)"}
      </Typography>
      <Box sx={{pl: 1}}>
        {props.multipleChoiceQuestion.options?.map(
          (o) => <Typography variant="body2">{o}</Typography>)
        }
      </Box>
    </Box>
  );
};
