import { FC } from 'react';
import { DisplayStateProcessor } from './display-state-processor';
import { Observable } from 'rxjs';
import { OptionalValue } from '../../selectors/data-wrapper';
import { ScreenNodeDataMap } from '../../selectors/selector-tree';
import { TestFlowSnapshot } from '../../../graph-state/test-director';
import { SizedArea } from '../../../media/drawable-dimensions';
import { ViewInstance } from './view-instance';
import { MeasurableValue } from './measurable-value';

export class ViewInstanceController {
  readonly element: FC;
  readonly displayStateProcessor: DisplayStateProcessor<any>;

  constructor(
    public viewInstance: ViewInstance,
    contentFactory: (
      dataStream: Observable<MeasurableValue<any>>,
      stateStream: Observable<MeasurableValue<OptionalValue<any>>>,
    ) => FC,
    nodeDataMap: ScreenNodeDataMap<any>,
    public flowStream: Observable<TestFlowSnapshot>,
  ) {
    this.displayStateProcessor = new DisplayStateProcessor<any>(
      viewInstance.viewScaler.view.pathName,
      flowStream,
      nodeDataMap,
      viewInstance.viewScaler.view.stateSelector,
    );
    this.element =
      viewInstance.viewScaler.view.containerFactory.createContainer(
        this.displayStateProcessor,
        contentFactory,
        SizedArea.dimPx(viewInstance.viewScaler.sizeState.size),
      );
  }

  close() {
    this.displayStateProcessor.close();
  }
}
