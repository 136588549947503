import React from 'react';
import { Box, Button, Grid, IconButton, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ErrorMessage, useField } from 'formik';
import { Add } from '@mui/icons-material';
import * as Yup from 'yup';
import StyledTextInput from '../../../../../components/input/StyledTextInput';
import {
  BlockType,
  PodtBlockInput,
  PodtTargetCategoryInput,
  TextAlignment,
} from '../../../../../API';
import { PodtCreatorState } from '../types';
import BlockSettings from '../../../testAdmin/testEditor/BlockSettings';
import PreparationScreenPickerWithToggle from '../../../../../components/input/PreparationScreenPickerWithToggle';
import {
  EditorStepTitle,
  InfoMessageGrid,
} from '../../../../../components/grid';
import { createDefaultFontStyle } from '../../../testAdmin/testEditor/initialEditorStates';
import { TestType } from '../../../../subject/types';
import { PodtVariables } from '../../../text-replacer/textReplacer';
import { instructionsSchema } from '../../../testAdmin/testEditor/instructionsSchema';

export const PodtBlockSettingsSchema = Yup.object().shape({
  targets: Yup.array()
    .min(1)
    .of(
      Yup.object().shape({
        label: Yup.string().required('chose a label for the category'),
      }),
    ),
  blocks: Yup.array()
    .min(1)
    .of(
      Yup.object().shape({
        name: Yup.string().required('block name required'),
        instructions: instructionsSchema,
        type: Yup.string().required('required'),
        amountTrials: Yup.number().required('amount trials required'),
      }),
    ),
});

const PodtBlockSettings = () => {
  const [, { value: blocks }, blockHelpers] =
    useField<PodtBlockInput[]>('blocks');
  const [, { value: targets }, targetsHelpers] =
    useField<PodtTargetCategoryInput[]>('targets');

  const addBlock = () => {
    const updatedBlocks: PodtCreatorState['blocks'] = [
      ...blocks,
      {
        index: blocks.length,
        name: '',
        instructions: {
          desktop: '',
        },
        amountTrials: 20,
        preparationScreen: {
          screen: { desktop: '' },
          interval: 500,
        },
        tips: {
          desktop: '',
          fontStyle: createDefaultFontStyle(TextAlignment.CENTER),
        },
        type: BlockType.NON_PRACTICE,
      },
    ];
    blockHelpers.setValue(updatedBlocks);
  };

  const targetCategories = (
    <Stack spacing={3} direction="row">
      {targets.map((category, categoryIndex) => {
        const targetCategoryPath = `targets[${categoryIndex}]`;
        return (
          <Box width={250} key={`targetCategories-${categoryIndex}`}>
            <StyledTextInput
              name={`${targetCategoryPath}.label`}
              label={`Target Category ${categoryIndex + 1}`}
              InputProps={{
                endAdornment:
                  categoryIndex !== 0 ? (
                    <IconButton
                      onClick={() =>
                        targetsHelpers.setValue(
                          targets.filter((c, i) => i !== categoryIndex),
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : undefined,
              }}
            />
          </Box>
        );
      })}
      <Button
        onClick={() => {
          const updatedTarget: PodtTargetCategoryInput[] = [
            ...targets,
            {
              label: '',
              practiceStimuliPool: {
                dangerousStimuli: [],
                nonDangerousStimuli: [],
              },
              testStimuliPool: {
                dangerousStimuli: [],
                nonDangerousStimuli: [],
              },
            },
          ];
          targetsHelpers.setValue(updatedTarget);
        }}
        startIcon={<Add />}
        title="Add"
        variant="contained"
        color="primary"
      >
        Add Target
      </Button>
    </Stack>
  );

  return (
    <Box>
      <EditorStepTitle prefix={2} title="Block Settings" />
      <Stack spacing={3}>
        {targetCategories}
        {blocks.map((block, index) => (
          <React.Fragment key={`block${index}`}>
            <Box width={'50%'}>
              <BlockSettings
                name={block.name}
                testType={TestType.PODT}
                index={index}
                amountTrialsDivisibleBy={Object.values(targets).length * 2}
                removeBlock={() =>
                  blockHelpers.setValue(blocks.filter((c, i) => i !== index))
                }
                variables={PodtVariables}
              >
                <Grid item container xs={12}>
                  <PreparationScreenPickerWithToggle
                    blockName={`blocks[${index}]`}
                    toggleOn={Boolean(block.preparationScreen)}
                    variables={PodtVariables}
                  />
                </Grid>
              </BlockSettings>
              <Button
                onClick={addBlock}
                startIcon={<Add />}
                title="Add"
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
              >
                Add Block
              </Button>
            </Box>
            <ErrorMessage key={`m-block${index}`} name={`blocks[${index}]`}>
              {() => (
                <InfoMessageGrid variant="error" message="Error in Block" />
              )}
            </ErrorMessage>
          </React.Fragment>
        ))}
      </Stack>
    </Box>
  );
};

export default PodtBlockSettings;
