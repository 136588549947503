import React, { FunctionComponent, useEffect, useState } from 'react';
import { ErrorMessage, useField } from 'formik';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { UiInput } from '../types';
import { SmartPhoneButtonPicker } from './SmartPhoneButtonPicker';

const useStyles = makeStyles((theme) => ({
  button: {
    '& *': {
      cursor: 'pointer!important',
    },
  },
  pressButtonIcon: {
    animation: `$pulse 2s infinite alternate ${theme.transitions.easing.easeInOut}`,
    display: 'inline-block',
    width: '2rem',
    marginRight: 16,
  },
  '@keyframes pulse': {
    '30%': { transform: 'scale(0.9)' },
    '80%': { transform: 'scale(1.05)' },
    '100%': { transform: 'scale(1)' },
    '0%': { transform: 'scale(1)' },
  },
}));

const PressButtonIcon: FunctionComponent = () => (
  <svg version="1.1" viewBox="0 0 25.66 25.66">
    <g>
      <path
        d="M6.357,8.51C6.181,7.914,6.012,7.317,5.885,6.745C5.05,6.251,4.482,5.352,4.482,4.313
                c0-1.563,1.271-2.833,2.833-2.833s2.833,1.271,2.833,2.833c0,0.215-0.029,0.422-0.074,0.623c0.271,0.597,0.49,1.269,0.682,1.952
                c0.54-0.72,0.872-1.605,0.872-2.575C11.628,1.93,9.698,0,7.315,0S3.003,1.93,3.003,4.313C3.003,6.365,4.438,8.074,6.357,8.51z"
      />
      <path
        d="M3.378,18.721c1.063-1.149,2.256-0.699,3.853-0.456c1.372,0.211,2.723-0.174,2.633-0.915
                c-0.142-1.199-0.342-1.735-0.797-3.288c-0.363-1.235-1.052-3.461-1.679-5.589c-0.84-2.847-1.083-4.169,0.035-4.499
                c1.205-0.351,1.896,1.361,2.522,3.735c0.713,2.702,1.088,3.895,1.298,3.832c0.37-0.106-0.136-1.262,0.832-1.547
                c1.21-0.352,1.444,0.594,1.783,0.499c0.339-0.102,0.224-1.06,1.189-1.342c0.97-0.28,1.457,0.914,1.857,0.795
                c0.396-0.117,0.387-0.548,0.985-0.719c0.599-0.178,2.853,0.831,4.143,5.238c1.619,5.541-0.205,6.571,0.348,8.447l-7.227,2.748
                c-0.585-1.407-2.397-1.511-4-2.408c-1.615-0.909-2.712-2.68-6.921-2.594C2.649,20.69,2.724,19.428,3.378,18.721z"
      />
    </g>
  </svg>
);

export const stripKeyValue = (s?: string): string => {
  if (s) {
    if (s.includes('Key')) {
      return s.substring(3, 4);
    }
    if (s.includes('Digit')) {
      return s.substring(5, 6);
    }
  }
  return s || '';
};

interface KeyboardButtonPickerProps extends UiInput<string> {
  description: string;
  toolTip?: string;
  disabled?: boolean;
  mx?: number;
  active?: 'left' | 'right';
  leftLabel?: string;
  rightLabel?: string;
  onFlipLabels?: (l: string, r: string) => void;
}

const KeyboardButtonPicker: FunctionComponent<KeyboardButtonPickerProps> = ({
  name,
  label,
  description,
  value,
  disabled,
  mx,
  active,
  leftLabel,
  rightLabel,
  onFlipLabels,
}) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [, meta, helpers] = useField<typeof value>(name);

  useEffect(() => {
    const keyListener = (ev: KeyboardEvent) => {
      helpers.setValue(ev.code);
      setShowDialog(false);
    };

    if (showDialog) {
      document.addEventListener('keydown', keyListener);
    }
    return () => document.removeEventListener('keydown', keyListener);
  }, [helpers, showDialog]);

  return (
    <Box>
      <TextField
        sx={{ mx }}
        className={classes.button}
        fullWidth
        label={label}
        disabled={disabled}
        value={stripKeyValue(meta.value as string)}
        onClick={() => setShowDialog(true)}
        error={meta.touched && meta.error !== undefined}
        InputProps={{
          readOnly: true,
          endAdornment: <Edit />,
        }}
      />
      <Typography variant="caption" color="error" component="div">
        <ErrorMessage name={name} />
      </Typography>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>{`Select the "${label}"`}</DialogTitle>
        <DialogContent>
          <Box>
            <Typography color="textSecondary" sx={{ mb: 1 }}>
              Desktop:
            </Typography>
            <Typography>
              <i aria-labelledby={label} className={classes.pressButtonIcon}>
                <PressButtonIcon />
              </i>
              {description}
            </Typography>
            {meta.value && (
              <Typography color="primary" sx={{ mb: 2, ml: 6 }}>
                currently set to: {stripKeyValue(meta.value)}
              </Typography>
            )}
            <Divider sx={{ my: 2 }} />
            <Typography color="textSecondary" sx={{ mb: 1 }}>
              Mobile:
            </Typography>
            <SmartPhoneButtonPicker
              leftLabel={leftLabel ?? 'left'}
              rightLabel={rightLabel ?? 'right'}
              onFlipLabels={onFlipLabels}
              active={active}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default KeyboardButtonPicker;
