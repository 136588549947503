import React, { FC } from 'react';
import { TestStatus } from '../../../../API';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  LinearProgress,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import TestStatusComponent from './TestStatus';
import { useNavigate } from 'react-router-dom';
import {
  ChevronLeft,
  CloseOutlined,
  Delete,
  Download,
  Edit,
  FileCopy,
  FileUpload,
  ResetTv,
  Visibility,
} from '@mui/icons-material';

import { BaseTest } from '../../types';
import { TestType } from '../../../subject/types';
import ErrorBoundary from '../../../errorHandeling/ErrorBoundary';
import { isDefined } from '../../../subject/utils';
import MenuIcon from '@mui/icons-material/Menu';
import { hasRunnerConfiguration } from '../../../subject/testRunner/runner-config-map';
import { StagePreviewContainer } from '../../../subject/testRunner/stage/preview/StagePreviewContainer';
import { TestResultsAlert } from '../testResults/TestResultsAlert';
import {
  useAuthUserTestPermissions,
  useIsAdmin,
} from '../../../../auth/auth.hook';

interface TestDetailsBarProps {
  test?: BaseTest;
  testType: TestType;
  loading: boolean;
  onClickDuplicate: () => void;
  onClickDelete: () => void;
  onClickEdit: () => void;
  onClickPublish: () => void;
  onClickLive: () => void;
  onClickClose: () => void;
  onClickSetup: () => void;
  previewUrl?: string;
  standaloneUrl?: string;
}

interface ActionButton {
  onClick: (e: React.MouseEvent<any>) => void;
  icon: JSX.Element;
  text: string;
}

export const TestDetailsBar: FC<TestDetailsBarProps> = (props) => {
  const isAdmin = useIsAdmin();
  const navigate = useNavigate();

  const { canEditTest, canViewTest, canViewTestResults, canDeleteTest } =
    useAuthUserTestPermissions(props.test);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [showPreview, setShowPreview] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );

  const handleDownloadJson = () => {
    const jsonString = JSON.stringify(props.test, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${props.test?.name}.json`;
    a.click();

    // Clean up by revoking the URL
    URL.revokeObjectURL(url);
  };

  const buttons: (ActionButton | undefined)[] = [
    canEditTest || canViewTest
      ? {
          onClick: handleDownloadJson,
          text: 'Export',
          icon: <Download />,
        }
      : undefined,
    canEditTest && props.test?.status === TestStatus.DRAFT
      ? {
          onClick: props.onClickEdit,
          icon: <Edit />,
          text: 'Edit',
        }
      : undefined,
    canEditTest || canViewTest
      ? {
          onClick: props.onClickDuplicate,
          text: 'Duplicate',
          icon: <FileCopy />,
        }
      : undefined,
    canDeleteTest
      ? {
          onClick: props.onClickDelete,
          text: 'Delete',
          icon: <Delete />,
        }
      : undefined,
    canEditTest && props.test?.status === TestStatus.DRAFT
      ? {
          onClick: props.onClickPublish,
          icon: <FileUpload />,
          text: 'Publish',
        }
      : undefined,
    canEditTest && props.test?.status === TestStatus.PUBLISHED
      ? {
          onClick: props.onClickLive,
          icon: <FileUpload />,
          text: ' Go Live',
        }
      : undefined,
    canEditTest &&
    (props.test?.status === TestStatus.LIVE ||
      props.test?.status === TestStatus.PUBLISHED)
      ? {
          onClick: props.onClickClose,
          icon: <CloseOutlined />,
          text: ' End Test',
        }
      : undefined,
    canEditTest &&
    (props.test?.status === TestStatus.LIVE ||
      props.test?.status === TestStatus.PUBLISHED)
      ? {
          onClick: props.onClickSetup,
          icon: <ResetTv />,
          text: ' Setup',
        }
      : undefined,
    props.previewUrl
      ? { onClick: handleClick, icon: <Visibility />, text: ' Preview' }
      : undefined,
  ];

  return (
    <Box sx={{ flexGrow: 1, mb: 2 }}>
      <AppBar position="static" color="inherit">
        <Toolbar style={{ position: 'relative' }}>
          {props.loading && (
            <LinearProgress
              sx={{
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            />
          )}
          <IconButton edge="start" onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ mr: 2 }}>
            <b>{props.test?.name ?? 'Loading'}</b>
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
            {props.test?.status && (
              <TestStatusComponent
                value={props.test.status}
                chipProps={{ size: 'small' }}
              />
            )}
          </Box>
          {!props.loading && (
            <>
              <Stack
                direction="row"
                spacing={2}
                sx={{ display: { xs: 'none', lg: 'flex' } }}
              >
                {buttons.filter(isDefined).map((button) => (
                  <Button
                    onClick={button.onClick}
                    startIcon={button.icon}
                    key={`test-actions-button-${button.text}`}
                  >
                    {button.text}
                  </Button>
                ))}
              </Stack>
              <Box
                sx={{
                  flexGrow: 1,
                  justifyContent: 'flex-end',
                  display: { xs: 'flex', lg: 'none' },
                }}
              >
                <IconButton
                  size="large"
                  onClick={(evt) => setAnchorElNav(evt.target as HTMLElement)}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={() => setAnchorElNav(null)}
                  sx={{
                    display: { xs: 'block', lg: 'none' },
                  }}
                >
                  {buttons.filter(isDefined).map((button) => (
                    <MenuItem
                      key={button.text}
                      onClick={(evt) => {
                        setAnchorElNav(null);
                        button.onClick(evt);
                      }}
                    >
                      <ListItemIcon>{button.icon}</ListItemIcon>
                      <Typography textAlign="center">{button.text}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              {props.previewUrl && (
                <Menu
                  id="preview-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'preview-menu',
                  }}
                >
                  {hasRunnerConfiguration(props.testType) && (
                    <MenuItem
                      component={Link}
                      onClick={() => handleClose()}
                      target="_blank"
                      href={props.previewUrl}
                    >
                      Respondent
                    </MenuItem>
                  )}
                  {isAdmin && (
                    <MenuItem
                      onClick={() => {
                        setShowPreview(!showPreview);
                        handleClose();
                      }}
                    >
                      Scientist
                    </MenuItem>
                  )}
                </Menu>
              )}

              {props.test &&
                showPreview &&
                hasRunnerConfiguration(props.testType) && (
                  <Dialog
                    open={showPreview}
                    onClose={() => setShowPreview((p) => !p)}
                    fullWidth
                    maxWidth={false}
                  >
                    <DialogContent>
                      <ErrorBoundary>
                        <StagePreviewContainer
                          test={props.test}
                          testType={props.testType}
                        />
                      </ErrorBoundary>
                    </DialogContent>
                  </Dialog>
                )}
            </>
          )}
        </Toolbar>
      </AppBar>
      <TestResultsAlert
        testType={props.testType}
        test={props.test}
        userOwnsTest={canViewTestResults}
      />
    </Box>
  );
};
