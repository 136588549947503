import React, { FC } from "react";
import { Typography } from "@mui/material";

export const TypeToolTip: FC = () => (
  <ul>
    <li>
      <Typography color="inherit" variant="body1">Stimuli</Typography>
      <Typography color="inherit" variant="body2">
        Each main attribute will be paired with a stimulus (text or picture).
      </Typography>
    </li>
    <br />
    <li>
      <Typography color="inherit" variant="body1">Groups</Typography>
      <Typography color="inherit" variant="body2">
        Only available if you defined experimental groups in Step 2 (tab: “Groups”).<br />
        Each main attribute will be assigned to one of the groups.<br />
        Group membership of the attribute will be indicated by label and colored frame.
      </Typography>
    </li>
  </ul>
);