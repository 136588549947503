import { ScreenTreeNode } from '../nodes/screen-tree-node';
import { PhaseTreeNode } from '../nodes/phase-tree-node';
import { TreeNode } from '../nodes/tree-node';

export class SortedTreeNode {
  name: string;
  treeNode: ScreenTreeNode | PhaseTreeNode;
  children: SortedTreeNode[] = [];

  constructor(name: string, treeNode: ScreenTreeNode | PhaseTreeNode) {
    this.name = name;
    this.treeNode = treeNode;
  }

  format(indent = ''): string {
    return `${indent}${this.name}:\n${this.children
      .map((c) => c.format(indent + '  '))
      .join('\n')}`;
  }

  includes(screenNode: TreeNode) {
    let currentNode = screenNode as TreeNode | null;
    while (currentNode) {
      if (currentNode.pathName === this.treeNode.pathName) {
        return true;
      }
      currentNode = currentNode.parent;
    }
    return false;
  }

  insert(path: PhaseTreeNode[], screen: ScreenTreeNode) {
    if (path.length > 0) {
      const childPhase = path[0];
      let childSortedNode = this.children.find(
        (p) => p.treeNode.pathName === childPhase.pathName,
      );
      if (!childSortedNode) {
        childSortedNode = new SortedTreeNode(
          childPhase.displayName,
          childPhase,
        );
        this.children.push(childSortedNode);
      }
      childSortedNode.insert(path.slice(1), screen);
    } else {
      if (!this.children.some((c) => c.treeNode.pathName === screen.pathName)) {
        this.children.push(new SortedTreeNode(screen.displayName, screen));
      }
    }
  }
}
