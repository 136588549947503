import { arabicTranslationInfo } from './languages/arabic';
import { germanTranslationInfo } from './languages/german';
import { englishTranslationInfo } from './languages/english';
import { turkishTranslationInfo } from './languages/turkish';
import { spanishTranslationInfo } from './languages/spanish';
import { frenchTranslationInfo } from './languages/french';
import { hungarianTranslationInfo } from './languages/hungarian';
import { czechTranslationInfo } from './languages/czech';
import { dutchTranslationInfo } from './languages/dutch';
export type TranslationInfo = {
  languageName: string;
  languageCode: string;
  direction: 'ltr' | 'rtl';
  translations: Record<string, string | string[]>;
};

export type TranslationInfoMap = Record<string, TranslationInfo>;

export const translationInfoMap: TranslationInfoMap = {
  ar: arabicTranslationInfo,
  de: germanTranslationInfo,
  en: englishTranslationInfo,
  tr: turkishTranslationInfo,
  es: spanishTranslationInfo,
  fr: frenchTranslationInfo,
  hu: hungarianTranslationInfo,
  ce: czechTranslationInfo,
  nl: dutchTranslationInfo,
};
