import { DefaultTestTree } from '../../../../subject/testRunner/graph/test-tree';
import { DefaultTransitionAction } from '../../../../subject/testRunner/graph-state/graph-action';
import { WswStereotypes } from '..//wsw-controls';
import { WswStructure } from '../structure/wsw-structure';
import { WSWModifiers } from './wsw-modifiers';
import { createGroupPhaseTree } from './wsw-tree-group-phase';
import { createDistractionPhaseTree } from './wsw-tree-distraction-phase';
import { createPresentationPhaseTree } from './wsw-tree-presentation-phase';
import { WswInstructionsData } from '../view-data/wsw-instructions-data';
import { MapScreenTreeNode } from '../../../../subject/testRunner/graph/nodes/map-screen-tree-node';
import { MapPhaseTreeNode } from '../../../../subject/testRunner/graph/nodes/map-phase-tree-node';

export function createWswTree(structure: WswStructure) {
  const root = new MapPhaseTreeNode('wsw', null);

  const start = new MapPhaseTreeNode('start', root).connectParent(
    WSWModifiers.default,
  );

  new MapScreenTreeNode(
    'welcome',
    start,
    new WswInstructionsData(structure.welcomePhase.content),
  )
    .controlRequest(
      WswStereotypes.Confirm.defineTransition(undefined, () =>
        DefaultTransitionAction.next(WSWModifiers.default),
      ),
    )
    .connectParent(WSWModifiers.default);

  let groupConstructionPhase:
    | ReturnType<typeof createGroupPhaseTree>
    | undefined;
  if (
    structure.groupConstructionPhase &&
    structure.groupConstructionPhase.position === 'before-learning'
  ) {
    groupConstructionPhase = createGroupPhaseTree(
      root,
      structure,
      structure.groupConstructionPhase,
    );
    start.transition(WSWModifiers.default, groupConstructionPhase);
  }

  const learningPhase = createPresentationPhaseTree(
    root,
    structure,
    structure.learningPhase,
    structure.groupConstructionPhase?.position === 'before-learning'
      ? structure.groupConstructionPhase.resultingGroup
      : undefined,
  );
  if (groupConstructionPhase) {
    groupConstructionPhase.transition(WSWModifiers.default, learningPhase);
  } else {
    start.transition(WSWModifiers.default, learningPhase);
  }

  if (
    structure.groupConstructionPhase &&
    structure.groupConstructionPhase.position === 'after-learning'
  ) {
    groupConstructionPhase = createGroupPhaseTree(
      root,
      structure,
      structure.groupConstructionPhase,
    );
    learningPhase.transition(WSWModifiers.default, groupConstructionPhase);
  }

  let distractionPhaseNode:
    | ReturnType<typeof createDistractionPhaseTree>
    | undefined;
  if (structure.distractionPhase) {
    distractionPhaseNode = createDistractionPhaseTree(
      root,
      structure,
      structure.distractionPhase,
      structure.groupConstructionPhase?.position !== undefined
        ? structure.groupConstructionPhase.resultingGroup
        : undefined,
    );
    if (
      groupConstructionPhase &&
      structure.groupConstructionPhase?.position === 'after-learning'
    ) {
      groupConstructionPhase.transition(
        WSWModifiers.default,
        distractionPhaseNode,
      );
    } else {
      learningPhase.transition(WSWModifiers.default, distractionPhaseNode);
    }
  }

  const testPhase = createPresentationPhaseTree(
    root,
    structure,
    structure.testPhase,
    structure.groupConstructionPhase?.position !== undefined
      ? structure.groupConstructionPhase.resultingGroup
      : undefined,
  );
  if (distractionPhaseNode) {
    distractionPhaseNode.transition(WSWModifiers.default, testPhase);
  } else if (
    groupConstructionPhase &&
    structure.groupConstructionPhase?.position === 'after-learning'
  ) {
    groupConstructionPhase.transition(WSWModifiers.default, testPhase);
  } else {
    learningPhase.transition(WSWModifiers.default, testPhase);
  }

  const endPhase = new MapPhaseTreeNode('end', root).connectParent();
  new MapScreenTreeNode(
    'end',
    endPhase,
    new WswInstructionsData({ ...structure.endPhase.content, finish: true }),
  )
    .connectParent(WSWModifiers.default)
    .transitionFrom(WSWModifiers.default, testPhase)
    .controlRequest(
      WswStereotypes.Confirm.defineTransition(undefined, () => {
        return DefaultTransitionAction.next(WSWModifiers.default);
      }),
    );
  return new DefaultTestTree(WSWModifiers, root);
}
