import React from 'react';
import { renderMediaItem } from './picker/pickerUtils';
import { MediaItemSnapshotInput } from '../../API';
import { theme } from '../../theme';
import { Box, Typography } from '@mui/material';
import { MediaItem } from './types';

export const mediaItemStyles = {
  display: 'inline-flex',
  position: 'relative',
  padding: 55,
  // height: 55,
  background: 'transparent',
  borderRadius: '50%',
  border: '1px solid rgba(0,0,0,0.12)',
  margin: 0,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: '4px 0',
  },
  '& img': {
    height: 112,
  },
  '& p': {
    fontWeight: 'bold',
    display: 'inline-block',
    position: 'absolute',
    width: '95%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    margin: 0,
    padding: 0,
    textAlign: 'center',
    lineHeight: '190%',
  },
};

type MediaItemProps = {
  mediaItem: MediaItem | MediaItemSnapshotInput;
  fullWidth?: boolean;
  key?: string;
  label?: string;
  color?: string | null;
  bgColor?: string | null;
  fullSizeImage?: 'container' | 'image';
};

export default function MediaItemComponent({
  mediaItem,
  key,
  label,
  color,
  bgColor,
  fullSizeImage,
}: MediaItemProps): JSX.Element {
  const mediaItemStyle = {
    color: color || 'black',
    background: bgColor || 'white',
    paddingLeft: 4,
  };
  return (
    <Box>
      {label && (
        <Typography key={`title-${key}`} color="textSecondary">
          {label}
        </Typography>
      )}
      <Box key={key} style={mediaItemStyle}>
        {renderMediaItem(
          mediaItem,
          !!fullSizeImage,
          undefined,
          fullSizeImage === 'container',
        )}
      </Box>
    </Box>
  );
}
