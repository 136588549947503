import { PickingStrategy } from '../../../../../API';
import { TestOptionCategories } from '../../../../subject/testRunner/media/options-test';
import { AmpOptionsType } from './amp-options-type';
import { AmpMediaPool } from '../loading/amp-media-loader';
import { AmpBlockDescriptor } from './amp-block-structure';
import { NestedPickingSet } from '../../../pickingSet/NestPickingSet/NestPickingSet';
import { createPickingSet } from '../../../pickingSet/picking-strategy';
import { newPickNCollect } from '../../../pickingSet/picking-set';
import { CombinedPickingSet } from '../../../pickingSet/CombinedPickingSet/CombinedPickingSet';

export class AmpPicker {
  constructor(
    protected pickingStrategy: PickingStrategy,
    protected options: TestOptionCategories<AmpOptionsType>,
    protected pool: AmpMediaPool,
  ) {}

  pickBlock(block: AmpBlockDescriptor) {
    const targetPool = NestedPickingSet.Create(
      'target',
      PickingStrategy.REFILLING_WITHOUT_REPLACEMENT,
      this.pool.targets
        .select({ blockType: block.blockType })
        .collectGrouped(['category'])
        .map((p, index) =>
          createPickingSet(`target-category-${index}`, this.pickingStrategy, p),
        ),
    );

    const primePool = NestedPickingSet.Create(
      'prime',
      PickingStrategy.REFILLING_WITHOUT_REPLACEMENT,
      this.pool.primes
        .select({ blockType: block.blockType })
        .collectGrouped(['category'])
        .map((p, index) =>
          createPickingSet(`prime-category-${index}`, this.pickingStrategy, p),
        ),
    );

    return newPickNCollect(
      new CombinedPickingSet(
        {
          prime: primePool,
          target: targetPool,
        },
        'amp-trial-set',
      ),
      block.amountTrials,
    );
  }
}
