import {
  Image,
  ImageInstance,
  MediaItemSnapshot,
  MediaItemSnapshotInput,
  MediaItemSnapshotScope,
} from '../../../../API';
import { BasicDimension, DrawableMetrics } from './drawable-dimensions';
import { TextDrawableBuilder, TextMediaStyle } from './text-drawer';
import {
  MediaResourceStorage,
  TestMediaResourceMap,
} from './media-resource-loader';
import { FC, MutableRefObject } from 'react';
import { v4 as uuid } from 'uuid';

export type TextMediaItem = MediaItemSnapshot & {
  text: string;
};

export type ImageMediaItem = MediaItemSnapshot & {
  id: string;
  image: Image;
};

export function isImageType(
  mediaItem: MediaItemSnapshot,
): mediaItem is ImageMediaItem {
  return !!mediaItem.image /*&& !!mediaItem.id*/;
}

export function isTextType(
  mediaItem: MediaItemSnapshot,
): mediaItem is TextMediaItem {
  return mediaItem.text !== null && mediaItem.text !== undefined;
}

export interface MediaTag {
  identifier: string;
  name: string;
}

export interface MediaImageInstance {
  dimension: BasicDimension;
  key: string;
}

export interface MediaImage {
  fullSize: MediaImageInstance;
  thumbNail: MediaImageInstance;
}

export type ImageSize = 'fullSize' | 'thumbNail';

export interface MediaDrawable {
  dimension: DrawableMetrics;
  imageSource: CanvasImageSource;
}

export interface MediaInstanceFactory {
  createInstance(data: MediaData): MediaInstance;
}

export type DrawableFactoryConfig = {
  image?: ImageSize;
  text?: TextMediaStyle;
};

abstract class InstanceTypeFactory {
  protected cache: Record<string, DrawableMediaInstance> = {};

  protected retrieveCache(key: string, build: () => DrawableMediaInstance) {
    if (!this.cache[key]) {
      this.cache[key] = build();
    }
    return this.cache[key];
  }

  abstract createInstance(
    mediaData: MediaData,
  ): DrawableMediaInstance | undefined;
}

class ImageInstanceTypeFactory extends InstanceTypeFactory {
  constructor(
    private resourceStorage: MediaResourceStorage<TestMediaResourceMap>,
    private size: ImageSize,
  ) {
    super();
  }

  createInstance(mediaData: MediaData): DrawableMediaInstance | undefined {
    if (mediaData instanceof ImageMediaData) {
      return this.retrieveCache(mediaData.identifier, () => {
        const imageElement = mediaData.image[this.size];
        return new ImageMediaInstance(mediaData, this.size, {
          imageSource: this.resourceStorage.getResource(
            'image',
            imageElement.key,
          ).resourceData,
          dimension: DrawableMetrics.fromDimension(imageElement.dimension),
        });
      });
    }
    return undefined;
  }
}

class TextInstanceTypeFactory extends InstanceTypeFactory {
  protected weightedDimensionsMemory: DrawableMetrics[] = [];

  constructor(private style: TextMediaStyle) {
    super();
  }

  createInstance(mediaData: MediaData): DrawableMediaInstance | undefined {
    if (mediaData instanceof TextMediaData) {
      return this.retrieveCache(mediaData.identifier, () => {
        return new DrawableTextMediaInstance(
          mediaData,
          this.style,
          new TextDrawableBuilder(mediaData.text, this.style, {
            getAllProcessedDimensions: () => this.weightedDimensionsMemory,
            processWeightedDimension: (dim) => {
              this.weightedDimensionsMemory.push(dim);
            },
          }).build(),
        );
      });
    }
    return undefined;
  }
}

export interface MediaInstanceFactoryBuilder<M extends MediaInstance> {
  (resourceStorage: MediaResourceStorage<TestMediaResourceMap>): Omit<
    MediaInstanceFactory,
    'createInstance'
  > & {
    createInstance: (data: MediaData) => M;
  };
}

export class DrawableInstanceFactory implements MediaInstanceFactory {
  private subFactories: InstanceTypeFactory[];

  constructor(subFactories: InstanceTypeFactory[]) {
    this.subFactories = subFactories;
  }

  static create(
    resourceStorage: MediaResourceStorage<TestMediaResourceMap>,
    config: DrawableFactoryConfig,
  ) {
    const subFactories: InstanceTypeFactory[] = [];
    if (config.image) {
      subFactories.push(
        new ImageInstanceTypeFactory(resourceStorage, config.image),
      );
    }
    if (config.text) {
      subFactories.push(new TextInstanceTypeFactory(config.text));
    }
    return new DrawableInstanceFactory(subFactories);
  }

  static builder(
    config: DrawableFactoryConfig,
  ): MediaInstanceFactoryBuilder<DrawableMediaInstance> {
    return (resourceStorage: MediaResourceStorage<TestMediaResourceMap>) =>
      DrawableInstanceFactory.create(resourceStorage, config);
  }

  createInstance(data: MediaData): DrawableMediaInstance {
    for (const subFac of this.subFactories) {
      const result = subFac.createInstance(data);
      if (result) {
        return result;
      }
    }
    throw new Error('MediaData ' + data + ' is not supported by this factory');
  }
}

export class RenderableTextInstanceFactory implements MediaInstanceFactory {
  protected cache: Record<string, TextMediaInstance> = {};

  protected retrieveCache(key: string, build: () => TextMediaInstance) {
    if (!this.cache[key]) {
      this.cache[key] = build();
    }
    return this.cache[key];
  }

  constructor(private style: TextMediaStyle) {}

  static builder(
    style: TextMediaStyle,
  ): MediaInstanceFactoryBuilder<TextMediaInstance> {
    return (resourceStorage: MediaResourceStorage<TestMediaResourceMap>) =>
      new RenderableTextInstanceFactory(style);
  }

  createInstance(data: MediaData): TextMediaInstance {
    if (data instanceof TextMediaData) {
      return this.retrieveCache(
        data.identifier,
        () => new TextMediaInstance(data, this.style),
      );
    }
    throw new Error('Only text data is supported by this factory');
  }
}

export interface MediaInstance {
  readonly data: MediaData;
  readonly meta?: MediaMeta;
}

export class MediaMeta {
  private metaMap: Record<string, any> = {};

  appendMeta<T>(key: string, data: T) {
    this.metaMap[key] = data;
    return this;
  }

  findMeta<T>(key: string): T | undefined {
    return this.metaMap[key];
  }

  static decorateInstance<M extends MediaInstance>(drawable: M): M {
    return {
      ...drawable,
      meta: new MediaMeta(),
    };
  }
}

export interface DrawableMediaHandle {
  size: BasicDimension;
  create: () => DrawableMediaInstance;
}

export interface DrawableMediaInstance extends MediaInstance {
  readonly drawable: MediaDrawable;
}

export interface LayoutableMediaInstance extends MediaInstance {
  createRenderComponent(): FC<{
    componentRef?: MutableRefObject<HTMLElement | null>;
  }>;
}

export abstract class MediaData {
  identifier: string;

  constructor(identifier: string) {
    this.identifier = identifier;
  }

  abstract toMediaItemSnapshot(): MediaItemSnapshotInput;

  get textValue(): string | null {
    return null;
  }
}

export function transformMediaItemData(
  mediaItem: MediaItemSnapshot,
): ImageMediaData | TextMediaData {
  if (isImageType(mediaItem)) {
    return ImageMediaData.fromMediaItem(mediaItem);
  }
  if (isTextType(mediaItem)) {
    return TextMediaData.fromMediaItem(mediaItem);
  }
  console.error('invalid media item', mediaItem);
  throw new Error(
    'invalid media item ' + mediaItem.id + ': neither text nor image',
  );
}

export class ImageMediaData extends MediaData {
  constructor(
    identifier: string,
    public image: MediaImage,
    public tags: MediaTag[],
    public originalFilename: string,
  ) {
    super(identifier);
  }

  static fromMediaItem(mediaItem: ImageMediaItem) {
    const readImageInstance = ({ width, height, key }: ImageInstance) => {
      return {
        dimension: {
          width,
          height,
        },
        key,
      } as MediaImageInstance;
    };
    return new ImageMediaData(
      mediaItem.id,
      {
        fullSize: readImageInstance(mediaItem.image!.fullSize!),
        thumbNail: readImageInstance(mediaItem.image!.thumbNail!),
      },
      mediaItem.tags?.map((tag) => ({
        identifier: tag.id!,
        name: tag.name!,
      })) ?? [],
      mediaItem.originalFileName ?? '',
    );
  }

  toMediaItemSnapshot(): MediaItemSnapshotInput {
    return {
      image: {
        fullSize: {
          key: this.image.fullSize.key,
          width: this.image.fullSize.dimension.width,
          height: this.image.fullSize.dimension.height,
        },
        thumbNail: {
          key: this.image.thumbNail.key,
          width: this.image.thumbNail.dimension.width,
          height: this.image.thumbNail.dimension.height,
        },
      },
      scope: MediaItemSnapshotScope.PRIVATE,
      id: this.identifier,
      tags: this.tags.map((t) => ({
        id: t.identifier,
        name: t.name,
      })),
      originalFileName: this.originalFilename,
    };
  }
}

export class TextMediaData extends MediaData {
  constructor(
    identifier: string,
    public text: string,
  ) {
    super(identifier);
  }

  static fromMediaItem(mediaItem: TextMediaItem) {
    return new TextMediaData(mediaItem.text, mediaItem.text);
  }

  get textValue(): string {
    return this.text;
  }

  toMediaItemSnapshot(): MediaItemSnapshotInput {
    return {
      text: this.text,
      tags: [],
      id: uuid(),
      scope: MediaItemSnapshotScope.PRIVATE,
    };
  }
}

export class ImageMediaInstance implements DrawableMediaInstance {
  constructor(
    public data: ImageMediaData,
    public size: ImageSize,
    public drawable: MediaDrawable,
  ) {}
}

export class TextMediaInstance implements MediaInstance {
  constructor(
    public data: TextMediaData,
    public textStyle: TextMediaStyle,
  ) {}
}

export class DrawableTextMediaInstance
  extends TextMediaInstance
  implements DrawableMediaInstance
{
  constructor(
    data: TextMediaData,
    textStyle: TextMediaStyle,
    public drawable: MediaDrawable,
  ) {
    super(data, textStyle);
  }
}
