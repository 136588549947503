import React, { FC } from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';
import { InfoIcon } from '../../../../components/display/Icons';
import { TestPermissionType } from './types';
import { Delete } from '@mui/icons-material';
import { testPermissionDescriptionMap } from './testPermissionDescriptionMap';

type TestPermissionRowProps = {
  permission: TestPermissionType;
  emails: string[];
  onRemoveEmail?: (email: string) => void;
};

export const TestPermissionRow: FC<TestPermissionRowProps> = ({
  permission,
  emails,
  onRemoveEmail,
}) => {
  return (
    <div>
      <Tooltip
        title={testPermissionDescriptionMap[permission]}
        // placement="top"
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography color="text.secondary">{permission}</Typography>
          <InfoIcon iconProps={{ sx: { fontSize: '16px' } }} />
        </Stack>
      </Tooltip>
      <Stack>
        {emails.length === 0 ? (
          <Typography variant="caption" color="text.secondary">
            no user invited
          </Typography>
        ) : (
          emails.map((email, index) => (
            <Stack direction="row" key={`${email}-${index}`} spacing={2}>
              <Typography variant="body2" sx={{ width: 200 }}>
                {email}
              </Typography>
              {onRemoveEmail && (
                <Tooltip title={`remove "${email}" from "${permission}"`}>
                  <Delete
                    fontSize="small"
                    color="action"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => onRemoveEmail(email)}
                  />
                </Tooltip>
              )}
            </Stack>
          ))
        )}
      </Stack>
    </div>
  );
};
