import React, { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { SyncAlt } from '@mui/icons-material';
import { useField } from 'formik';
import makeStyles from '@mui/styles/makeStyles';
import { CategoryVariant, IatCategoriesInput } from '../../../../../API';
import MediaItemComponent from '../../../../media/MediaItemComponent';
import StyledIconButton from '../../../../../components/input/StyledIconButton';

type IatCategorySwitcherProps = {
  variantName: string;
  categoryName: string;
  label: string;
  disabled?: boolean;
};

const CategorySwitcher: FunctionComponent<IatCategorySwitcherProps> = ({
  label,
  variantName,
  categoryName,
  disabled,
}) => {
  const classes = useStyles();

  const [, variantMeta, variantHelpers] = useField<CategoryVariant | null>(
    variantName,
  );
  const [, categoryMeta] = useField<IatCategoriesInput>(categoryName);
  const [, { value: bgColor }] = useField<string>('style.backgroundColour');

  const switchPositions = () => {
    if (variantMeta.value === CategoryVariant.REVERSED) {
      variantHelpers.setValue(CategoryVariant.DEFAULT);
    }
    if (variantMeta.value === CategoryVariant.DEFAULT) {
      variantHelpers.setValue(CategoryVariant.REVERSED);
    }
  };

  return variantMeta.value ? (
    <>
      <Typography variant="overline">{label}</Typography>
      <div className={classes.categorySwitcher}>
        <div className={classes.media}>
          <Typography variant="h6" color="textSecondary">
            Left:
          </Typography>
          <MediaItemComponent
            fullWidth
            mediaItem={
              variantMeta.value === CategoryVariant.DEFAULT
                ? categoryMeta.value.categoryA.label
                : categoryMeta.value.categoryB.label
            }
            color={categoryMeta.value.fontStyle.color}
            bgColor={bgColor}
          />
        </div>
        <StyledIconButton
          label="Switch"
          onClick={switchPositions}
          disabled={disabled}
        >
          <SyncAlt />
        </StyledIconButton>
        <div className={classes.media}>
          <Typography variant="h6" color="textSecondary">
            Right:
          </Typography>
          <MediaItemComponent
            fullWidth
            mediaItem={
              variantMeta.value === CategoryVariant.DEFAULT
                ? categoryMeta.value.categoryB.label
                : categoryMeta.value.categoryA.label
            }
            color={categoryMeta.value.fontStyle.color}
            bgColor={bgColor}
          />
        </div>
      </div>
    </>
  ) : null;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    categorySwitcher: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& button': {
        margin: theme.spacing(0, 1),
      },
    },
    media: {
      display: 'flex',
      flex: 1,
      textAlign: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      '& > div': {
        marginTop: theme.spacing(1),
      },
    },
  }),
);

export default CategorySwitcher;
