import React from 'react';
import { TestType } from '../../subject/types';
import { BaseTest } from '../types';
import { Dialog, DialogContent, Tab, Tabs, Typography } from '@mui/material';
import { TestStep } from '../testAdmin/testEditor/types';
import { useSearchParams } from 'react-router-dom';
import { SurveyTestSetupInstructions } from './SurveyTestSetupInstructions';
import { TestProviderType } from '../../subject/testRunner/state/baseTestContext/context/ContextTypes';
import { StandaloneSetupInstructions } from './StandaloneSetupInstructions';

type TestSetupInstructionsProps = {
  testType: TestType;
  steps: TestStep[];
  test?: BaseTest;
};

export default function TestSetupInstructions({
  test,
  testType,
  steps,
}: TestSetupInstructionsProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const onClose = () => {
    const newParams = { ...searchParams };
    searchParams.delete('setup');
    setSearchParams(newParams, { replace: true });
  };

  const setupSearchParam = searchParams.get('setup');

  const [mode, setMode] = React.useState('survey-tool' as TestProviderType);

  return (
    <Dialog
      maxWidth={'lg'}
      open={setupSearchParam === 'true'}
      onClose={onClose}
      fullWidth
    >
      <DialogContent>
        {test && (
          <React.Fragment>
            <Typography variant="h4" sx={{ mb: 2 }}>
              Setup Instructions
            </Typography>
            <Typography>
              You can distribute this test either in a standalone version
              providing a link or integrate it into a survey of a survey tool
            </Typography>
            <Tabs
              sx={{ marginBottom: '1em' }}
              value={mode}
              onChange={(_, value) => {
                setMode(value);
              }}
            >
              <Tab label="Survey Tool" value="survey-tool" />
              <Tab label="Standalone" value="standalone" />
            </Tabs>
            {mode === 'standalone' && (
              <StandaloneSetupInstructions testType={testType} test={test} />
            )}
            {mode === 'survey-tool' && (
              <SurveyTestSetupInstructions test={test} testType={testType} />
            )}
          </React.Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
}
