import React, { FC, ReactNode } from 'react';
import { useField } from 'formik';
import { WSWGroupInput } from '../../../../../API';
import { createEmptyWswGroup } from '../../../testAdmin/testEditor/initialEditorStates';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import ColorInput from '../../../../../components/input/ColorInput';
import StyledNumberInput from '../../../../../components/input/StyledNumberInput';
import { Add, Delete, ExpandMore, InfoOutlined } from '@mui/icons-material';
import { ConditionalQuestionSet } from '../conditionalQuestionSet/ConditionalQuestionSet';
import StyledTextInput from '../../../../../components/input/StyledTextInput';
import { WswVariables } from '../../../text-replacer/textReplacer';
import { OptionalInstructionsScreen } from '../../../../subject/testRunner/screens/OptionalInstructionsScreen';
import { ColorDisplay } from '../../../../../components/display/ColorDisplay';

interface WswGroupsComponentProps {
  fieldName: string;
  toolTip?: string | ReactNode;
}

export const WswGroupsComponent: FC<WswGroupsComponentProps> = (props) => {
  const [, meta, helpers] = useField<WSWGroupInput[]>(props.fieldName);

  const addGroup = () => {
    helpers.setValue([...meta.value, createEmptyWswGroup()]);
  };

  const removeGroup = (groupIndex: number) => {
    helpers.setValue(meta.value.filter((_, i) => i !== groupIndex));
  };

  return (
    <Box>
      <Typography variant="h6" color="textSecondary" position="relative">
        Groups
        {props.toolTip && (
          <Tooltip placement={'right'} title={props.toolTip}>
            <InfoOutlined
              sx={{
                color: 'warning.dark',
                bgcolor: 'white',
                position: 'absolute',
                top: '4px',
              }}
            />
          </Tooltip>
        )}
      </Typography>
      {typeof meta.error === 'string' &&
        Boolean(meta.touched && meta.error) && (
          <Typography sx={{ color: 'error.main' }}>{meta.error}</Typography>
        )}
      <Box sx={{ pl: 1 }}>
        <Grid container spacing={2}>
          {meta.value?.map((group, gIndex) => {
            const groupPath = `${props.fieldName}[${gIndex}]`;
            return (
              <Grid item xs={6} key={`group-index-${gIndex}`}>
                <Accordion
                  defaultExpanded
                  sx={{
                    border: 'solid',
                    borderColor: group.color,
                    borderWidth: '1px',
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <Typography sx={{ mr: 3 }}>
                          {gIndex + 1}. {group.name}
                        </Typography>
                        <ColorDisplay label="" value={group.color} />
                      </Box>
                      <IconButton
                        size="small"
                        onClick={() => removeGroup(gIndex)}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack spacing={2} direction="row" sx={{ mb: 2 }}>
                      <StyledTextInput
                        style={{ width: 200 }}
                        label="Name"
                        name={`${groupPath}.name`}
                        toolTip={
                          'Choose the label that will represent the targets’ group membership during learning and test phase.\nRespondents will see the label of their own group on a flag in the upper right corner'
                        }
                      />
                      <ColorInput
                        name={`${groupPath}.color`}
                        label="Color"
                        toolTip={
                          'Choose the colors that will represent the targets’ group membership during learning and test phase” (also color of respondents’ group membership in condition 1 and 2); Respondents will see the color of their own group on a flag in the upper right corner.'
                        }
                        // TODO NR. 11
                      />
                      <StyledNumberInput
                        label="Probability"
                        name={`${groupPath}.probability`}
                        toolTip={
                          'Probability determines the relative frequency of respondents in each group\n(between-respondent design; e.g., if probability for Group A is 1 and probability of Group B is 2, twice as many respondents who enter the test will be assigned to Group B compared to Group A)'
                        }
                      />
                    </Stack>
                    <OptionalInstructionsScreen
                      fieldName={`${groupPath}.feedbackScreen`}
                      checkboxLabel={'Text-Display (e.g. Feedback Screen)'}
                      dialogLabel={'Group Feedback'}
                      variables={WswVariables}
                    />
                    <ConditionalQuestionSet
                      fieldName={`${groupPath}.questionSet`}
                      label="Question Set"
                      withConditionalQuestion={false}
                      withImageInQuestions
                      withQuestionTimeout={true}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          })}
        </Grid>
        <Button
          startIcon={<Add />}
          onClick={addGroup}
          variant="contained"
          sx={{ mt: 2 }}
          size="small"
        >
          Add Group
        </Button>
      </Box>
    </Box>
  );
};
