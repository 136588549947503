import makeStyles from '@mui/styles/makeStyles';
import { Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import MediaItemComponent from '../../../media/MediaItemComponent';
import { TextMediaData } from '../../../subject/testRunner/media/MediaData';
import { IatMediaPool } from '../loader/loading/load-iat-media';
import { MindSetRandomUtils } from '../../pickingSet/picking-utils';

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  categoryContainer: {
    borderRadius: 5,
    // padding: theme.spacing(1),
    boxShadow: theme.shadows[2],
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('sm')]: {
      // padding: theme.spacing(1.5),
      width: '47.5%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  category: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
  },
  stimuli: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

export function createCategoryPreviewElement(
  pool: IatMediaPool,
  counterBalancing: boolean,
) {
  const categories = pool.test.options.categories;
  const createPool = (
    category: keyof typeof categories,
    variant: 'first' | 'second',
  ) => ({
    label: categories[category][variant].value,
    items: pool.test.presentations
      .select({
        category,
        variant,
      })
      .collect(),
  });
  const attributeOrder: ('first' | 'second')[] = counterBalancing
    ? MindSetRandomUtils.shuffleArray(['first', 'second'])
    : ['first', 'second'];
  const targetOrder: ('first' | 'second')[] = counterBalancing
    ? MindSetRandomUtils.shuffleArray(['first', 'second'])
    : ['first', 'second'];
  const pools = [
    ...attributeOrder.map((o) => createPool('attribute', o)),
    ...targetOrder.map((o) => createPool('target', o)),
  ];

  return () => {
    const classes = useStyles();

    return (
      <div className={classes.main}>
        <Grid container spacing={2} sx={{ width: '100%' }}>
          {pools.map((t) => {
            if (t.items[0].value.data instanceof TextMediaData) {
              return (
                <Grid
                  key={t.label.data.identifier}
                  item
                  md={12}
                  xl={6}
                  sx={{ width: '100%' }}
                >
                  <Card sx={{ width: '100%' }} variant="outlined">
                    <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                      <Grid
                        container
                        alignItems={'center'}
                        justifyContent={'center'}
                        alignContent={'center'}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <MediaItemComponent
                            mediaItem={t.label.data.toMediaItemSnapshot()}
                          />
                        </Grid>
                        <div>
                          {t.items
                            .map((item) => item.value.data.textValue)
                            .join(', ')}
                        </div>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            } else {
              return (
                <Grid
                  key={t.label.data.identifier}
                  item
                  md={12}
                  xl={6}
                  sx={{ width: '100%' }}
                >
                  <Card sx={{ width: '100%' }} variant="outlined">
                    <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                      <Grid
                        container
                        spacing={3}
                        alignItems={'center'}
                        justifyContent={'center'}
                        alignContent={'center'}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <MediaItemComponent
                            mediaItem={t.label.data.toMediaItemSnapshot()}
                          />
                        </Grid>
                        <Grid
                          item
                          container
                          spacing={2}
                          alignItems="center"
                          justifyItems="center"
                        >
                          {t.items.map((s) => (
                            <Grid
                              key={s.value.data.identifier}
                              item
                              xs={2}
                              sm={2}
                              md={2}
                              lg={2}
                              xl={2}
                            >
                              <div
                                style={{ width: '100%' }}
                                ref={(e) => {
                                  e?.appendChild(
                                    s.value.drawable
                                      .imageSource as HTMLImageElement,
                                  );
                                  (
                                    s.value.drawable
                                      .imageSource as HTMLImageElement
                                  ).style.width = '100%';
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            }
          })}
        </Grid>
      </div>
    );
  };
}
