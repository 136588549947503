import React, { FC } from 'react';
import { RegisterUserMutationVariables } from '../../API';
import { Button, Stack, Typography } from '@mui/material';

export const RegistrationInstructions: FC<{
  name: string;
  currentData: Partial<RegisterUserMutationVariables>;
  next: (
    updateData: Partial<RegisterUserMutationVariables>,
    done: boolean,
  ) => void;
}> = ({ next }) => {
  return (
    <Stack spacing={2}>
      <Typography variant="subtitle1">
        Registrierung auf der MIND.set Plattform
      </Typography>
      <Typography variant="body2">
        Ich dachte hier vielleicht erstmal paar einleitende Worte hin. Also
        vllt. was MIND.set ist und für wen es geeignet ist bzw. welche
        Zielgruppe Zugang kriegt.
      </Typography>
      <Typography variant="body2">
        Und dann was zum Procedere (also, dass das erst manuell geprüft wird und
        etwas dauern kann).
      </Typography>
      <Typography variant="body2">
        Kann aber vllt. auch einfach über das Formular in Schritt 2, falls es
        nicht so viel ist
      </Typography>

      <Stack
        direction="row"
        sx={{ width: '100%', justifyContent: 'space-between' }}
      >
        <div />
        <Button
          onClick={() => {
            next({}, false);
          }}
        >
          Next
        </Button>
      </Stack>
    </Stack>
  );
};
