import {
  WswConstructedGroup,
  WswLearningPhase,
  WswStructure,
  WswTestPresentationPhase,
} from '../structure/wsw-structure';
import { WswContentType, WswTestPhase } from '../../../../../API';
import { WSWModifiers } from './wsw-modifiers';
import { QuestionAnswerEvent, WswStereotypes } from '../wsw-controls';
import {
  ControlOccurrenceProcessor,
  DefaultTransitionAction,
} from '../../../../subject/testRunner/graph-state/graph-action';
import {
  createGroupIndicationResult,
  createWSWResultQuery,
} from './wsw-tree-utils';
import { createQuestionSetSubTree } from './wsw-tree-question-set';
import { WswInstructionsData } from '../view-data/wsw-instructions-data';
import {
  WswDoubleStimulus,
  WswEmptyStimulus,
  WswQuestionData,
  WswSingleStimulus,
} from '../view-data/wsw-question-stimulus';
import { MapScreenTreeNode } from '../../../../subject/testRunner/graph/nodes/map-screen-tree-node';
import { MapPhaseTreeNode } from '../../../../subject/testRunner/graph/nodes/map-phase-tree-node';

export function createPresentationPhaseTree(
  root: MapPhaseTreeNode,
  structure: WswStructure,
  presentationPhase: WswLearningPhase | WswTestPresentationPhase,
  groupIndication?: WswConstructedGroup,
) {
  const phase =
    structure.learningPhase === presentationPhase
      ? WswTestPhase.LearningPhase
      : WswTestPhase.TestPhase;

  const presentationPhaseNode = new MapPhaseTreeNode(
    phase,
    root,
  ).connectParent();

  const presentationPhaseTrials = new MapPhaseTreeNode(
    'trials',
    presentationPhaseNode,
  ).connectParent(
    presentationPhase.startInstructions ? undefined : WSWModifiers.default,
  );

  const trialPhaseNodes = presentationPhase.trials.map(
    (presentationTrial, index) => {
      const presentationTrialPhaseNode = new MapPhaseTreeNode(
        'trial-' + index,
        presentationPhaseTrials,
      ).connectParent(index === 0 ? WSWModifiers.default : undefined);

      const primaryStimulus = new WswSingleStimulus(
        presentationTrial.combination.primary,
        presentationTrial.combination.color ?? undefined,
      );
      const questionStimulus = presentationPhase.combinationType.secondary
        ? new WswDoubleStimulus(
            primaryStimulus,
            new WswSingleStimulus(presentationTrial.combination.secondary!),
          )
        : primaryStimulus;

      const presentationTrialStimulusNode = new MapScreenTreeNode(
        'stimulus',
        presentationTrialPhaseNode,
        new WswQuestionData(questionStimulus, null, groupIndication),
      )
        .controlRequest(
          WswStereotypes.TimeoutEvent.defineTransition(
            presentationTrial.questionSet.startTimeout,
            () => DefaultTransitionAction.next(WSWModifiers.default),
          ),
        )
        .connectParent(WSWModifiers.default);

      const createResultQuery = (
        control: ControlOccurrenceProcessor<QuestionAnswerEvent>,
      ) => {
        let phasePosition = 0;
        if (phase === WswTestPhase.LearningPhase) {
          phasePosition =
            structure.groupConstructionPhase?.position === 'before-learning'
              ? 1
              : 0;
        } else {
          phasePosition = structure.distractionPhase ? 2 : 1;
          if (structure.groupConstructionPhase) {
            phasePosition = phasePosition + 1;
          }
        }
        let indicationShown =
          structure.groupConstructionPhase?.position === 'before-learning' ||
          (phase === WswTestPhase.TestPhase &&
            structure.groupConstructionPhase?.position === 'after-learning');

        return createWSWResultQuery(structure, control, {
          groupIndication: structure.groupConstructionPhase
            ? createGroupIndicationResult(
                structure.groupConstructionPhase.resultingGroup,
                indicationShown,
              )
            : undefined,
          phase,
          position: phasePosition,
          trialIndex: index,
          resultStimulus: null,
          contentType: WswContentType.Combination,
          attributeCombination: presentationTrial.combination.resultEntry,
        });
      };
      const interTrialBlankNode = new MapScreenTreeNode(
        'blank',
        presentationTrialPhaseNode,
        new WswQuestionData(new WswEmptyStimulus(), null, groupIndication),
      )
        .controlRequest(
          WswStereotypes.TimeoutEvent.defineTransition(1000, () =>
            DefaultTransitionAction.next(WSWModifiers.default),
          ),
        )
        .connectParent();

      const questionSetSubTree = createQuestionSetSubTree(
        presentationTrial.questionSet,
        presentationTrialPhaseNode,
        {
          externalStimulus: questionStimulus,
          groupIndication,
          showPlainStimulusBeforeQuestion: false,
          resultQuery: (control) => createResultQuery(control),
        },
        {
          nodeAfter: interTrialBlankNode,
        },
      );

      presentationTrialStimulusNode.transition(
        WSWModifiers.default,
        questionSetSubTree.questionSetPhaseNode,
      );
      return presentationTrialPhaseNode;
    },
  );
  if (trialPhaseNodes.length > 0) {
    trialPhaseNodes.reduce((acc, c) =>
      c.transitionFrom(WSWModifiers.default, acc),
    );
  }
  if (presentationPhase.startInstructions) {
    new MapScreenTreeNode(
      'start-instructions',
      presentationPhaseNode,
      new WswInstructionsData(
        presentationPhase.startInstructions,
        groupIndication,
      ),
    )
      .controlRequest(
        WswStereotypes.Confirm.defineTransition(undefined, () =>
          DefaultTransitionAction.next(WSWModifiers.default),
        ),
      )
      .connectParent(WSWModifiers.default)
      .transition(WSWModifiers.default, presentationPhaseTrials);
  }

  if (presentationPhase.endInstructions) {
    new MapScreenTreeNode(
      'end-instructions',
      presentationPhaseNode,
      new WswInstructionsData(
        presentationPhase.endInstructions,
        groupIndication,
      ),
    )
      .controlRequest(
        WswStereotypes.Confirm.defineTransition(undefined, () =>
          DefaultTransitionAction.next(WSWModifiers.default),
        ),
      )
      .connectParent()
      .transitionFrom(WSWModifiers.default, presentationPhaseTrials);
  }
  return presentationPhaseNode;
}
