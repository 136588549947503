import React, { FC } from 'react';
import { MediaItemSnapshotInput, TestQuestion } from '../../../../../../API';
import { Card, Stack, Typography } from '@mui/material';
import { questionTypeFormatMap } from '../utils';
import { TextDisplay } from '../../../../../../components/TextDisplay';
import { MultipleChoiceQuestionView } from './MultipleChoiceQuestionView';
import { ScaleQuestionView } from './ScaleQuestionView';
import { renderMediaItem } from '../../../../../media/picker/pickerUtils';
import { ImageMultipleChoiceQuestionView } from './ImageMultipleChoiceQuestionView';

interface TestQuestionViewProps {
  index: number;
  testQuestion: TestQuestion;
}

export const TestQuestionView: FC<TestQuestionViewProps> = (props) => {
  return (
    <Card variant="outlined" sx={{ mb: 2, p: 1 }}>
      <Typography sx={{ mb: 0.5 }}>
        Question {props.index + 1} (
        {props.testQuestion.type &&
          questionTypeFormatMap[props.testQuestion.type]}
        )
      </Typography>
      <Stack sx={{ pl: 1 }} spacing={1}>
        <TextDisplay
          label="Question Text"
          value={props.testQuestion.questionText}
        />
        <Stack direction="row" spacing={3} alignItems="flex-start">
          {props.testQuestion.mediaItemSnapshot && (
            <TextDisplay
              label="Stimulus"
              value={renderMediaItem(
                props.testQuestion.mediaItemSnapshot as MediaItemSnapshotInput,
                false,
              )}
            />
          )}
          {props.testQuestion.stimulusTimeout !== null &&
            props.testQuestion.stimulusTimeout !== undefined && (
              <TextDisplay
                label="Stimulus Timeout"
                value={props.testQuestion.stimulusTimeout}
              />
            )}
        </Stack>
        {props.testQuestion.multiple && (
          <MultipleChoiceQuestionView
            multipleChoiceQuestion={props.testQuestion.multiple}
          />
        )}
        {props.testQuestion.imageMultipleChoice && (
          <ImageMultipleChoiceQuestionView
            imageMCQuestion={props.testQuestion.imageMultipleChoice}
          />
        )}
        {props.testQuestion.scale && (
          <ScaleQuestionView scaleQuestion={props.testQuestion.scale} />
        )}
        {props.testQuestion.noAnswer && (
          <TextDisplay label="No answer" value={props.testQuestion.noAnswer} />
        )}
      </Stack>
    </Card>
  );
};
