import React, { CSSProperties } from 'react';
import { SizedArea } from '../../../media/drawable-dimensions';

import { ProviderPhases } from '../../measure-phases';

export type StyleCB = (
  phase: ProviderPhases,
  style: React.CSSProperties,
) => React.CSSProperties;

export interface ContainerProvider {
  /**
   *
   * @param content to render
   * @param styleCB to apply to container. during measure phase, the styling of the container might defer from the runner runtime.
   */
  createContainer(content: React.ReactNode, styleCB?: StyleCB): JSX.Element;
}

export class DefaultRenderContainerProvider implements ContainerProvider {
  constructor(
    public code: string,
    protected size: SizedArea,
  ) {}

  createContainer(content: React.ReactNode, styleCB?: StyleCB): JSX.Element {
    const styleWrap = styleCB ?? ((_: string, s: CSSProperties) => s);
    return (
      <div style={styleWrap('render', { ...this.size.toSizeModeStyle() })}>
        {content}
      </div>
    );
  }
}
