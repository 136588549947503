import { InstructionsContent } from '../../../../subject/testRunner/screens/basic-views/test-instructions-view';
import { ControlTransition } from '../../../../subject/testRunner/controls/control-transition';
import { PodtScreenData } from './podt-screen-data';

export class PodtInstructionsData extends PodtScreenData {
  content: InstructionsContent;

  constructor(
    content: InstructionsContent,
    controlTransitions?: ControlTransition<any, any>[],
  ) {
    super(controlTransitions);
    this.content = content;
  }
}
