import * as React from 'react';
import {
  GridCellParams,
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterOperator,
} from '@mui/x-data-grid-pro';
import _ from 'lodash';
import { PlainTagAutoComplete } from '../PlainTagAutoComplete';

function TagEqualInputValue(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;
  return (
    <PlainTagAutoComplete
      value={item.value}
      onChangeValue={(tagName) => applyValue({ ...item, value: tagName })}
    />
  );
}

export const tagEqualOperators: GridFilterOperator[] = [
  {
    label: 'equal',
    value: 'tag-equal',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params: GridCellParams): boolean => {
        if (_.isString(params.value) && _.isString(filterItem.value)) {
          const tagsArray = params.value.split(', ');
          const filterValue = filterItem.value;

          return tagsArray.some((t) => t === filterValue);
        }
        return false;
      };
    },
    InputComponent: TagEqualInputValue,
    InputComponentProps: { type: 'number' },
  },
];
