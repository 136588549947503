import { TestBaseContext } from '../../../../subject/testRunner/state/baseTestContext/context/ContextTypes';
import { WswVariables } from '../../../text-replacer/textReplacer';
import { WswConstructedGroup } from './wsw-structure';
import { WSWAttributeType, WswStimulusType } from '../../../../../API';

export function chooseTemplate(
  context: TestBaseContext,
  template: { desktop: string; mobile?: string | null },
  groups: null | WswConstructedGroup[],
) {
  const createIndicatorLabel = (group: WswConstructedGroup) =>
    `<span style="background-color: ${group.color}; padding: 5px; border-radius: 3px;">${group.group.name}</span>`;
  const createGroupEntry = (index: number) => {
    if (groups === null || groups.length <= index) {
      return `<span style="color: red; font-weight: bold;">ERROR NO GROUP ${
        index + 1
      } defined</span>`;
    }
    return createIndicatorLabel(groups[index]);
  };
  const replacer = WswVariables.defineInstance({
    group1: createGroupEntry(0),
    group2: createGroupEntry(1),
    group3: createGroupEntry(2),
    group4: createGroupEntry(3),
  });
  if (context.deviceInfo.mode === 'mobile') {
    return replacer.replace(template.mobile ?? template.desktop);
  }
  return replacer.replace(template.desktop);
}

export function toAttributeResultType(type: WSWAttributeType): WswStimulusType {
  return type === WSWAttributeType.GROUPS
    ? WswStimulusType.Group
    : WswStimulusType.Stimulus;
}
