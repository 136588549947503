import {
  QuestionImageContext,
  WswTestQuestionInstance,
} from './wsw-test-question-instance';
import { MediaItemSnapshot, WswQuestionType } from '../../../../../../API';
import {
  DrawableMediaInstance,
  MediaInstance,
} from '../../../../../subject/testRunner/media/MediaData';
import { QuestionLocator } from './question-locator';
import React, { useCallback } from 'react';
import { DimensionReducer } from '../../../../../subject/testRunner/screens/dom-measure/dimension-reducer';
import MediaOffscreenCanvas from '../../../../../subject/MediaOffscreenCanvas';
import { displayRatio } from '../../../../../subject/testRunner/screens/basic-components/media-instance-components';
import { Box, IconButton, Link, Paper, Stack } from '@mui/material';
import { QuestionAnswerListener } from './question-answer-listener';
import { WswStereotypes } from '../../wsw-controls';
import { BasicDimension } from '../../../../../media/drawable-dimensions';
import { ChevronLeft } from '@mui/icons-material';
import { ChevronRight } from '@mui/icons-material';

function WSWImageMCOptionView({
  answer,
  canvasDimension,
  onClick,
}: {
  onClick: (e: any) => void;
  answer: {
    answer: {
      answer: MediaItemSnapshot;
      answerPresentable: DrawableMediaInstance;
      position: number;
    };
    mediaCanvas: MediaOffscreenCanvas;
  };
  canvasDimension: BasicDimension;
}) {
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  React.useEffect(() => {
    const context = canvasRef.current?.getContext('2d');
    if (context) {
      context.drawImage(answer.mediaCanvas.canvas, 0, 0);
    }
  }, [answer, canvasDimension]);
  return (
    <Box sx={{ padding: '10px', cursor: 'pointer' }}>
      <Paper sx={{ padding: '5px' }}>
        <canvas
          onClick={onClick}
          ref={canvasRef}
          style={{
            width: `${canvasDimension.width}px`,
            height: `${canvasDimension.height}px`,
            backgroundColor: 'transparent',
          }}
          width={canvasDimension.width * displayRatio}
          height={canvasDimension.height * displayRatio}
        />
      </Paper>
    </Box>
  );
}

function ImageAnswerContainer({
  scaledAnswers,
  canvasDimension,
  questionInstance,
}: {
  scaledAnswers: {
    answer: {
      answer: MediaItemSnapshot;
      answerPresentable: DrawableMediaInstance;
      position: number;
    };
    mediaCanvas: MediaOffscreenCanvas;
  }[];
  canvasDimension: BasicDimension;
  questionInstance: WswTestImageMCQuestionInstance;
}) {
  const boxRef = React.useRef<HTMLElement | null>(null);
  const stackRef = React.useRef<HTMLDivElement | null>(null);
  const scrollIndicatorLeftRef = React.useRef<HTMLElement | null>(null);
  const scrollIndicatorRightRef = React.useRef<HTMLElement | null>(null);

  const updateIndicatorVisibility = useCallback(() => {
    const scrollLeft = boxRef.current?.scrollLeft;
    const stackBoundingRect = stackRef.current?.getBoundingClientRect();
    const scrollWidth = stackRef.current?.scrollWidth;
    const leftCurrent = scrollIndicatorLeftRef.current;
    const rightCurrent = scrollIndicatorRightRef.current;
    if (scrollLeft !== undefined && stackBoundingRect && scrollWidth) {
      const leftPosition = scrollLeft;
      const rightPosition = scrollLeft + stackBoundingRect.width;

      const leftVisible = leftPosition >= canvasDimension.width / 2;
      const rightVisible =
        rightPosition < scrollWidth - canvasDimension.width / 2;

      if (leftCurrent) {
        leftCurrent.style.display = leftVisible ? 'flex' : 'none';
      }
      if (rightCurrent) {
        rightCurrent.style.display = rightVisible ? 'flex' : 'none';
      }
    }
  }, [canvasDimension.width]);

  React.useEffect(() => {
    updateIndicatorVisibility();
  }, [updateIndicatorVisibility]);

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        maxWidth: '95vw',
      }}
    >
      <Box
        ref={boxRef}
        onScroll={() => {
          updateIndicatorVisibility();
        }}
        sx={{
          width: '100%',
          position: 'relative',
          maxWidth: '95vw',
          overflowX: 'auto',
        }}
      >
        <Stack
          ref={stackRef}
          onScroll={() => {
            updateIndicatorVisibility();
          }}
          direction="row"
          spacing={1}
        >
          {scaledAnswers.map((value, i) => (
            <>
              <WSWImageMCOptionView
                key={'image-mc-answer-' + i}
                canvasDimension={canvasDimension}
                answer={value}
                onClick={QuestionAnswerListener((e) => {
                  return WswStereotypes.Answer.Answer.controlOccurred(
                    e.map((c) => ({
                      event: (c as any).nativeEvent as MouseEvent,
                      answer: questionInstance.toAnswer(
                        value.answer.answer.id,
                        false,
                        value.answer.position,
                        value.answer.answerPresentable.data.toMediaItemSnapshot()
                          .originalFileName ?? undefined,
                      ),
                    })),
                  );
                })}
              />
            </>
          ))}
        </Stack>
      </Box>
      <Box
        ref={scrollIndicatorLeftRef}
        sx={{
          position: 'absolute',
          top: '0',
          display: 'none',
          alignItems: 'center',
          background:
            'linear-gradient(90deg, rgb(200, 200, 200) 0%, rgba(200,200,200,0) 100%)',
          height: '95%',
          marginTop: '0.4em',
          borderTopRightRadius: '40%',
          borderBottomRightRadius: '40%',
        }}
      >
        <IconButton
          onClick={() => {
            boxRef.current!.scrollBy({
              behavior: 'smooth',
              left: -canvasDimension.width,
            });
          }}
        >
          <ChevronLeft />
        </IconButton>
      </Box>
      <Box
        ref={scrollIndicatorRightRef}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          display: 'none',
          alignItems: 'center',
          background:
            'linear-gradient(90deg, rgb(200, 200, 200) 0%, rgba(200,200,200,0) 100%)',
          height: '95%',
          marginTop: '0.4em',
          borderTopLeftRadius: '40%',
          borderBottomLeftRadius: '40%',
        }}
      >
        <IconButton
          onClick={() => {
            boxRef.current!.scrollBy({
              behavior: 'smooth',
              left: canvasDimension.width,
            });
          }}
        >
          <ChevronRight />
        </IconButton>
      </Box>
    </Box>
  );
}
// let counter = 0;
export class WswTestImageMCQuestionInstance extends WswTestQuestionInstance {
  readonly type: WswQuestionType.ImageMultipleChoice =
    WswQuestionType.ImageMultipleChoice;

  private scaleCache:
    | {
        screenHeight: number;
        canvasDimension: BasicDimension;
        answers: {
          answer: {
            answer: MediaItemSnapshot;
            answerPresentable: DrawableMediaInstance;
            position: number;
          };
          mediaCanvas: MediaOffscreenCanvas;
        }[];
      }
    | undefined = undefined;
  constructor(
    questionText: string,
    stimulus: MediaInstance | null,
    public readonly answers: {
      answer: MediaItemSnapshot;
      answerPresentable: DrawableMediaInstance;
      position: number;
    }[],
    public readonly noAnswer: string | null,
    questionLocator: QuestionLocator,
    timeout: number,
  ) {
    super(
      WswQuestionType.ImageMultipleChoice,
      questionText,
      stimulus,
      questionLocator,
      timeout,
    );
  }

  createComponent(context: QuestionImageContext): React.FC {
    const screenHeight = context.maxSize().height;

    if (
      this.scaleCache === undefined ||
      this.scaleCache.screenHeight !== screenHeight
    ) {
      const targetHeight = Math.floor(screenHeight * 0.3);
      const maxDimension = DimensionReducer.max().process(
        ...this.answers.map((di) => di.answerPresentable.drawable.dimension),
      ).currentDimension;
      const targetWidth = Math.min(
        Math.floor((targetHeight / maxDimension.height) * maxDimension.width),
        maxDimension.width,
      );
      const canvasDimension = { width: targetWidth, height: targetHeight };
      const scaledAnswers = this.answers.map((answer) => {
        return {
          answer,
          mediaCanvas: context.scaleImage(
            answer.answerPresentable as DrawableMediaInstance,
            canvasDimension,
          ),
        };
      });
      this.scaleCache = {
        screenHeight,
        canvasDimension,
        answers: scaledAnswers,
      };
    }
    return () => {
      return (
        <React.Fragment>
          <ImageAnswerContainer
            scaledAnswers={this.scaleCache!.answers}
            canvasDimension={this.scaleCache!.canvasDimension}
            questionInstance={this}
          />
          {this.noAnswer && (
            <Link
              sx={{ margin: '1em', cursor: 'pointer' }}
              onClick={QuestionAnswerListener((e) => {
                return WswStereotypes.Answer.Answer.controlOccurred(
                  e.map((c) => ({
                    event: (c as any).nativeEvent as MouseEvent,
                    answer: this.toAnswer(this.noAnswer!, true, -1),
                  })),
                );
              })}
            >
              {this.noAnswer}
            </Link>
          )}
        </React.Fragment>
      );
    };
  }
}
