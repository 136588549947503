import { useAppAuthenticator } from '../../auth/app-authenticator.hook';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import React from 'react';

export function LogoutScreen() {
  const { signOut } = useAppAuthenticator();

  const navigate = useNavigate();

  React.useEffect(() => {
    signOut();
    localStorage.clear();
    navigate('/login');
  }, [navigate, signOut]);

  return (
    <Box
      display="flex"
      sx={{ width: '100%', height: '100%' }}
      alignItems="center"
      justifyContent="center"
    >
      <Typography>Sie werden ausgeloggt...</Typography>
    </Box>
  );
}
