import { TestType } from '../../../subject/types';

export const testPermissions = [
  'owner',
  'view-test',
  'edit-test',
  'view-results',
] as const;

export type TestPermissionType = (typeof testPermissions)[number];

export type TestPermissionsProps = {
  testType: TestType;
  testId: string;
  ownerId: string | undefined | null;
  testViewersIds: (string | null)[] | undefined | null;
  testEditorsIds: (string | null)[] | undefined | null;
  resultsViewersIds: (string | null)[] | undefined | null;
  onReloadTest: () => void;
};
