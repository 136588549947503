export class TimeoutEvent extends CustomEvent<{ timeout: number }> {
  static Create(timeout: number) {
    return new TimeoutEvent('timeout', {
      detail: {
        timeout,
      },
    });
  }
}

export interface TimeoutHandler {
  cancel: () => void;
}
export function scheduleTimeout(
  timeout: number,
  action: () => void,
): TimeoutHandler {
  const identifier = setTimeout(action, timeout);
  return {
    cancel() {
      clearTimeout(identifier);
    },
  };
}

export class CancelableTimeoutPromise extends Promise<void> {
  private cancelHandler?: () => void;
  constructor(timeout: number) {
    super((resolve, reject) => {
      const handler = scheduleTimeout(timeout, () => resolve());
      this.cancelHandler = () => {
        handler.cancel();
        reject('cancelled');
      };
    });
  }

  cancel() {
    this.cancelHandler?.();
  }
}

export function awaitTimeout(timeout: number) {
  return new CancelableTimeoutPromise(timeout);
}
