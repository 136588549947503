import { StimulusType, WswQuestionType } from '../../../../../../API';
import {
  DrawableMediaInstance,
  MediaInstance,
} from '../../../../../subject/testRunner/media/MediaData';
import { determineStimulusType } from '../../tree/wsw-tree-utils';
import { FC } from 'react';
import { QuestionLocator } from './question-locator';
import { QuestionAnswer } from './question-answer';
import { BasicDimension } from '../../../../../media/drawable-dimensions';
import MediaOffscreenCanvas from '../../../../../subject/MediaOffscreenCanvas';

export interface QuestionImageContext {
  maxSize: () => BasicDimension;
  scaleImage: (
    drawable: DrawableMediaInstance,
    size: BasicDimension,
  ) => MediaOffscreenCanvas;
}

export abstract class WswTestQuestionInstance {
  readonly timeout: number;
  readonly type: WswQuestionType;
  readonly questionText: string;
  readonly stimulus: MediaInstance | null;
  public readonly questionLocator: QuestionLocator;

  constructor(
    type: WswQuestionType,
    questionText: string,
    stimulus: MediaInstance | null,
    questionLocator: QuestionLocator,
    timeout: number,
  ) {
    this.type = type;
    this.questionText = questionText;
    this.stimulus = stimulus;
    this.questionLocator = questionLocator;
    this.timeout = timeout;
  }

  toAnswer(
    answer: string,
    noAnswer: boolean,
    answerOptionIndex?: number,
    imageAnswerOriginalFileName?: string,
  ): QuestionAnswer {
    const stimulusType = determineStimulusType(this.stimulus);
    return {
      ...this.questionLocator,
      resultStimulus: {
        stimulusId:
          stimulusType === StimulusType.Image
            ? this.stimulus?.data!.identifier!
            : null,
        stimulusTags:
          stimulusType === StimulusType.Image
            ? this.stimulus!.data!.toMediaItemSnapshot().tags.map(
                (tag) => tag.name,
              )
            : [],
        stimulusTextValue:
          stimulusType === StimulusType.Text
            ? this.stimulus!.data!.textValue
            : null,
        stimulusType,
        stimulusOriginalFilename:
          this.stimulus?.data.toMediaItemSnapshot().originalFileName ?? null,
      },
      answerOptionIndex: answerOptionIndex ?? null,
      noAnswer,
      questionAnswer: answer,
      questionImageAnswerOriginalFileName: imageAnswerOriginalFileName,
    };
  }

  abstract createComponent(questionImageContext: QuestionImageContext): FC;
}
