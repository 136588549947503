import { BaseTest } from '../../types';
import { NewTestEditorProps } from './types';
import { RootState, useAppDispatch } from '../../../../app/redux/store';
import { useTestAdminNav } from '../utils';
import { useSelector } from 'react-redux';
import { initialStatesMap, testToTestInput, useEditorParams } from './utils';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

type TestEditorConfig<
  T extends BaseTest,
  C extends { id?: string | null },
  U,
> = Omit<NewTestEditorProps<T, C, U>, ''>;

export function useEditorState<
  T extends BaseTest,
  C extends { id?: string | null },
  U,
>(config: TestEditorConfig<T, C, U>) {
  const { state } = useLocation();
  const dispatch = useAppDispatch();

  const { testType, thunks } = config;

  const { navigateToEditor, navigateToDetails, navigateToList } =
    useTestAdminNav(testType);

  const [step, setStep] = React.useState(0);

  const { mode, testId } = useEditorParams();

  const [errors, setErrors] = React.useState<boolean[]>([]);

  const [apiError, setApiError] = React.useState(false);

  const [showSuccessBar, setShowSuccessBar] = React.useState(false);
  const handleShowSuccessBar = () => setShowSuccessBar(true);
  const handleCloseSuccessBar = () => {
    setShowSuccessBar(false);
    setApiError(false);
  };

  // Todo neu_manu (bad types)
  const oldTest =
    useSelector((state: RootState) => state[testType].tests[testId]) ??
    (state as unknown as T | undefined);

  const isLoading = useSelector(
    (state: RootState) => state[testType].isLoading as boolean,
  );

  React.useEffect(() => {
    if (testId !== 'new') {
      dispatch(thunks.loadTest(testId));
    }
  }, [dispatch, testId, thunks]);

  const initialValues = oldTest
    ? (testToTestInput(oldTest, mode) as C)
    : (initialStatesMap[testType] as C);

  const createTest = useCallback(
    (testInput: C) =>
      dispatch(thunks.createTest(testInput))
        // .then(unwrapResult)
        .then((createdTest) => {
          if (createdTest.meta.requestStatus === 'fulfilled') {
            navigateToEditor('edit', (createdTest.payload as T).id);
          }
          return createdTest;
        }),
    [dispatch, navigateToEditor, thunks],
  );

  const updateTest = useCallback(
    (testInput: C) => dispatch(thunks.updateTest(testInput as unknown as U)),
    [dispatch, thunks],
  );

  const saveTest = React.useCallback(
    (testToSave: C) =>
      mode === 'edit'
        ? updateTest(testToSave).then((res) => {
            handleShowSuccessBar();
            if ('error' in res) {
              setApiError(true);
            }
          })
        : createTest(testToSave).then((res) => {
            handleShowSuccessBar();
            if ('error' in res) {
              setApiError(true);
            }
          }),
    [createTest, mode, updateTest],
  );

  return {
    testId,
    testType,
    mode,
    isLoading,
    initialValues,
    stepIndex: step,
    activeStep: config.steps[step],
    nextStep: () => setStep((p) => p + 1),
    prevStep: () => setStep((p) => p - 1),
    goToStep: (i: number) => setStep(i),
    saveTest,
    onClickCancel: () =>
      mode === 'edit' ? navigateToDetails(testId) : navigateToList(),
    errors,
    setErrors,
    showSuccessBar,
    hideSuccessBar: handleCloseSuccessBar,
    apiError,
  };
}
