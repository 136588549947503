import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Amplify, Analytics, I18n } from 'aws-amplify';
import App from './app/App';
import awsExports from './aws-exports';
import Log from './features/logger';
import { Provider as StoreProvider } from 'react-redux';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './features/errorHandeling/ErrorBoundary';
import store from './app/redux/store';
import ThemeProvider from './theme';
import { Authenticator } from '@aws-amplify/ui-react';
import { ConfirmOptions, ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';
import { PlaygroundApp } from './playground/playground-app';
// import '@fontsource/overpass-mono';
import '@fontsource/overpass';

const executionMode = process.env.REACT_APP_EXECUTION_MODE;

if (executionMode === 'playground') {
  Amplify.configure(awsExports);
  Analytics.disable();

  LicenseInfo.setLicenseKey(
    '5cf0cc25ef4758e5cea5256a5f554e1eT1JERVI6MzIyMjIsRVhQSVJZPTE2Njc5OTg4MzMwMDAsS0VZVkVSU0lPTj0x',
  );

  window.onunhandledrejection = function (
    this: WindowEventHandlers,
    ev: PromiseRejectionEvent,
  ) {
    Log.error('UNHANDLED_REJECTION', ev.reason);
  };

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );

  root.render(<PlaygroundApp />);
} else {
  Amplify.configure(awsExports);

  Analytics.disable();

  LicenseInfo.setLicenseKey(
    '5cf0cc25ef4758e5cea5256a5f554e1eT1JERVI6MzIyMjIsRVhQSVJZPTE2Njc5OTg4MzMwMDAsS0VZVkVSU0lPTj0x',
  );

  window.onunhandledrejection = function (
    this: WindowEventHandlers,
    ev: PromiseRejectionEvent,
  ) {
    Log.error('UNHANDLED_REJECTION', ev.reason);
  };

  I18n.setLanguage('en');

  const defaultConfirmOptions: ConfirmOptions = {
    cancellationButtonProps: { variant: 'contained', color: 'inherit' },
    confirmationButtonProps: { variant: 'contained' },
    contentProps: { sx: { whiteSpace: 'pre-wrap' } },
  };

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );

  root.render(
    <Authenticator.Provider>
      <BrowserRouter>
        <ErrorBoundary>
          <StoreProvider store={store}>
            <ThemeProvider>
              <ConfirmProvider defaultOptions={defaultConfirmOptions}>
                <SnackbarProvider
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  autoHideDuration={2000}
                />
                <App />
              </ConfirmProvider>
            </ThemeProvider>
          </StoreProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </Authenticator.Provider>,
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);w
