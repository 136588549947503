import { GridColDef } from '@mui/x-data-grid-pro';
import { Typography } from '@mui/material';
import TestStatusComponent from '../testDetails/TestStatus';
import { TestStatus } from '../../../../API';
import { formatDate } from '../../../../dateUtils';
import React from 'react';

export const testListColumns: GridColDef[] = [
  {
    hide: true,
    field: 'id',
    headerName: 'ID',
    width: 300,
    type: 'string',
    renderCell: (params) => (
      <Typography sx={{ fontSize: 11 }}>{params.value}</Typography>
    ),
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
    type: 'string',
    renderCell: (params) => (
      <Typography sx={{ fontSize: 17 }}>{params.value}</Typography>
    ),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 300,
    type: 'string',
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 140,
    type: 'string',
    renderCell: (params) => (
      <TestStatusComponent
        value={params.value as TestStatus}
        chipProps={{ size: 'small' }}
      />
    ),
  },
  {
    hide: true,
    field: 'createdAt',
    headerName: 'Created At',
    width: 250,
    type: 'date',
    valueFormatter: (params) => formatDate(params.value as string),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 250,
    type: 'date',
    valueFormatter: (params) => formatDate(params.value as string),
  },
];
