import { View } from '../views/view';
import { ViewScaler } from '../scaler/view-scaler';
import { BoxHandler } from './box-handler';
import { PathAccessor } from '../path-accessor';

export class LayoutBox {
  public _name: string = '';
  private pathAccessor?: PathAccessor;

  get name() {
    return this.pathAccessor?.pathName ?? this._name;
  }
  constructor(
    public grows: boolean,
    public views: View[],
  ) {}
  init(name: string, pathAccessor?: PathAccessor) {
    this._name = name;
    this.pathAccessor = pathAccessor;
    return this;
  }
  createBoxHandler(viewScalerMap: Map<View, ViewScaler>): BoxHandler {
    return new BoxHandler(this, viewScalerMap);
  }
}
