import React, { FC } from 'react';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { useField } from 'formik';
import WysiwygEditor from './WysiwygEditor';
import { InfoOutlined } from '@mui/icons-material';
import InfoMessage from '../../InfoMessage';
import { VariablesDisplay } from '../../../features/tests/text-replacer/VariablesDisplay';
import { EditorVariables } from '../../../features/tests/text-replacer/textReplacer';
import { useConfirm } from 'material-ui-confirm';

interface WysiwygProps {
  name: string;
  label?: string;
  toolTip?: string;
  variables?: EditorVariables<string>;
}

export const Wysiwyg: FC<WysiwygProps> = ({
  label,
  name,
  toolTip,
  variables,
}) => {
  const [showEditorDialog, setShowEditorDialog] = React.useState(false);
  const toggleEditor = () => setShowEditorDialog((p) => !p);
  const confirm = useConfirm();
  const [, meta] = useField<string | undefined>(`${name}.desktop`);
  const [, { value: mobile }, mobileHelpers] = useField<
    string | null | undefined
  >(`${name}.mobile`);
  console.log(`mobile value ${label}`, mobile);
  const mobileValueExists = typeof mobile === 'string';
  const [showMobileContent, setShowMobileContent] =
    React.useState(mobileValueExists);

  const [, { value: width }] = useField<number | undefined>('style.maxWidth');

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <Dialog open={showEditorDialog} onClose={toggleEditor} maxWidth="xl">
        <DialogTitle>
          <div>Set the content for {label}</div>
          <div style={{ fontSize: 'small' }}>
            <InfoMessage
              message={
                <React.Fragment>
                  In case a text is being pasted in the text editor there might
                  appear some unwanted linebreaks. In order to fix this delete
                  the linebreak with the return button.
                  <br />
                  If you want to add normal linebreaks please use{' '}
                  <b>"Ctrl+Enter"</b>.
                </React.Fragment>
              }
            />
          </div>
        </DialogTitle>
        <DialogContent>
          {variables && <VariablesDisplay variables={variables} />}
          <Typography color="text.secondary">
            <b>Desktop</b>
          </Typography>
          <WysiwygEditor
            name={`${name}.desktop`}
            label={label ?? ''}
            focusOnMount
          />
          <Typography color="text.secondary" sx={{ mt: 2 }}>
            <b>
              Mobile
              <Checkbox
                sx={{ mb: '2px' }}
                onChange={(_, checked) => {
                  if (!checked) {
                    confirm({
                      title: 'Delete Mobile Content',
                      description: `Are you sure you want to delete the mobile content for ${label}?`,
                      confirmationButtonProps: { color: 'error' },
                    })
                      .then(() => {
                        setShowMobileContent(checked);
                        mobileHelpers.setValue(null);
                      })
                      .catch(() => {
                        // do nothing
                      });
                  } else {
                    setShowMobileContent(checked);
                  }
                }}
                checked={showMobileContent || mobileValueExists}
              />
            </b>
          </Typography>
          {(showMobileContent || mobileValueExists) && (
            <Box>
              <WysiwygEditor name={`${name}.mobile`} label={label ?? ''} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  pt: 3,
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    border: 1,
                    borderRadius: 2,
                    display: 'flex',
                    width: 360,
                    height: 190,
                    borderColor: 'text.secondary',
                    pt: 1,
                    pb: 1,
                    pr: 1,
                    pl: 0.5,
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ border: 1, height: '40%', mr: 0.5 }} />
                  <Box
                    sx={{
                      border: 1,
                      display: 'flex',
                      flexGrow: 1,
                      borderColor: 'text.secondary',
                      height: '100%',
                      justifyContent: 'center',
                      overflow: 'auto',
                    }}
                  >
                    <Box sx={{ px: 1, width: `${width}%` ?? '100%' }}>
                      <div
                        dangerouslySetInnerHTML={
                          mobile ? { __html: mobile } : undefined
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={toggleEditor}>
            Use Text
          </Button>
        </DialogActions>
      </Dialog>
      <FormControl variant="outlined" fullWidth error={Boolean(meta.error)}>
        <InputLabel
          sx={{ backgroundColor: 'white', px: 0.5 }}
          htmlFor={`wysiwyg-${name}`}
          shrink={true}
        >
          {label}
        </InputLabel>
        <Card
          id={`wysiwyg-${name}`}
          sx={{
            width: '100%',
            px: 2,
            cursor: 'pointer',
            height: '100%',
            minHeight: 60,
            borderColor: (theme) =>
              meta.error ? theme.palette.error.main : undefined,
          }}
          variant="outlined"
          onClick={toggleEditor}
          dangerouslySetInnerHTML={{
            __html: `<div class="emptyNewLineFilled">${meta.value ?? ''}</div>`,
          }}
        />
        {meta.error && (
          <Typography color="error" variant="caption" sx={{ pl: 2 }}>
            {meta.error}
          </Typography>
        )}
      </FormControl>
      {toolTip && (
        <Tooltip title={toolTip} placement="left-start">
          <InfoOutlined
            sx={{
              color: 'warning.dark',
              bgcolor: 'white',
              position: 'absolute',
              top: -10,
              right: -10,
            }}
          />
        </Tooltip>
      )}
    </Box>
  );
};
