import React from 'react';
import { SurveyProvider } from '../../API';
import { IconButton, Paper } from '@mui/material';
import { Cached, Delete } from '@mui/icons-material';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridEnrichedColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { formatDate } from '../../dateUtils';
import { RequireAuth } from '../../auth/RequiredAuth';
import {
  useDeleteSurveyProviderMutation,
  useGetSurveyProvidersQuery,
} from './surveyProviderApi';
import { CreateSurveyProviderDialog } from './CreateSurveyProviderDialog';
import { useConfirm } from 'material-ui-confirm';
import { LoadingIcon } from '../../components/utils/LoadingIcon';
import { UpdateSurveyProviderDialog } from './UpdateSurveyProviderDialog';

const columns: GridColDef<SurveyProvider>[] = [
  { headerName: 'ID', field: 'id', flex: 1 },
  {
    headerName: 'Name',
    field: 'name',
    flex: 1,
  },
  {
    headerName: 'Slug',
    field: 'slug',
    flex: 1,
  },
  {
    headerName: 'Description',
    field: 'description',
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    flex: 2,
    type: 'dateTime',
    renderCell: (params) => formatDate(params.value as string),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated at',
    flex: 2,
    type: 'dateTime',
    renderCell: (params) => formatDate(params.value as string),
  },
];

export const SurveyProvidersScreen = () => {
  const confirm = useConfirm();

  const {
    data: surveyProviders,
    isLoading,
    refetch,
  } = useGetSurveyProvidersQuery();

  const [deleteSurveyProvider, { isLoading: isDeleteLoading }] =
    useDeleteSurveyProviderMutation();

  const enrichedColumns: GridEnrichedColDef<SurveyProvider>[] = [
    ...columns,
    {
      field: 'actions',
      type: 'actions',
      getActions: (params) => [
        <UpdateSurveyProviderDialog
          onSuccess={refetch}
          surveyProvider={params.row}
        />,
        <GridActionsCellItem
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          showInMenu={false}
          placeholder=""
          icon={<Delete />}
          label="Delete"
          disabled={isDeleteLoading || isLoading}
          onClick={() => {
            confirm({
              description: `Do you really want to delete survey provider ${params.row.name}?`,
              confirmationText: 'Delete',
              confirmationButtonProps: {
                color: 'error',
              },
            })
              .catch((err) => {
                if (err === undefined) {
                  return 'cancel' as const;
                }
                throw err;
              })
              .then(async (res) => {
                if (res !== 'cancel') {
                  await deleteSurveyProvider({ input: { id: params.row.id } });
                  await refetch();
                }
              });
          }}
        />,
      ],
    },
  ];

  return (
    <RequireAuth>
      <Paper sx={{ minHeight: 600 }}>
        <DataGridPro
          disableSelectionOnClick
          loading={isLoading}
          columns={enrichedColumns}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                createdAt: false,
                updatedAt: false,
              },
            },
          }}
          rows={surveyProviders ?? []}
          components={{
            Toolbar: () => (
              <GridToolbarContainer>
                <CreateSurveyProviderDialog onSuccess={refetch} />
                <GridToolbarFilterButton
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                  placeholder=""
                  sx={{ m: 1 }}
                />
                <GridToolbarColumnsButton
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                  placeholder=""
                />
                <IconButton
                  disabled={isLoading}
                  onClick={refetch}
                  sx={{ ml: 'auto' }}
                >
                  {isLoading ? <LoadingIcon /> : <Cached />}
                </IconButton>
              </GridToolbarContainer>
            ),
          }}
        />
      </Paper>
    </RequireAuth>
  );
};
