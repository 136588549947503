export const listMediaItemsWithoutCircular = /* GraphQL */ `
  query ListMediaItemsWithTags(
    $filter: ModelMediaItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMediaItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tagsString
        tags {
          items {
            id
            tagId
            tag {
              id
              name
            }
          }
        }
        image {
          fullSize {
            width
            height
            key
          }
          thumbNail {
            width
            height
            key
          }
        }
        source
        originalFileName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listMediaItemTagsWithoutCircular = /* GraphQL */ `
  query ListMediaItemTagsWithoutCircular(
    $filter: ModelMediaItemTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMediaItemTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        mediaItem {
          id
          tags {
            items {
              id
              tagId
              tag {
                id
                name
              }
            }
          }
          image {
            fullSize {
              width
              height
              key
            }
            thumbNail {
              width
              height
              key
            }
          }
          source
          originalFileName
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const getMediaItemWithoutCircular = /* GraphQL */ `
  query GetMediaItemWithoutCircular($id: ID!) {
    getMediaItem(id: $id) {
      id
      originalFileName
      image {
        fullSize {
          width
          height
          key
        }
        thumbNail {
          width
          height
          key
        }
      }
      source
      tags {
        items {
          id
          tagId
          createdAt
          updatedAt
          tag {
            id
            name
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const listTagsWithoutCircular = /* GraphQL */ `
  query ListTagsWithoutCircular(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        mediaItems {
          items {
            id
          }
        }
      }
      nextToken
    }
  }
`;

export const getPublicIat = `
  query GetPublicIat($id: ID!) {
    getIatTest(id: $id) {
    id
    name
    language
    description
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    interTrialInterval
    counterbalancing
    probabilityWithoutReplacement
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    previewCategories
    blocks {
      index
      type
      name
      amountTrials
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      categoryLabelsInInstructions
      attributeCategoryVariant
      targetCategoryVariant
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      __typename
    }
    targetCategories {
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      categoryA {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      categoryB {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    categoryDivider {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    attributeCategories {
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      categoryA {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      categoryB {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    startLabel
    nextLabel
    finishLabel
    feedback
    feedbackInterval
    createdAt
    updatedAt
    __typename
  }
}
`;

export const getPublicAmp = `
query GetPublicAmp($id: ID!) {
 getAmpTest(id: $id) {
    id
    name
    language
    description
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    counterbalancing
    probabilityWithoutReplacement
    interTrialInterval
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    attributeCategories {
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      categoryA {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      categoryB {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    fixation {
      interval
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    primes {
      interval
      categories {
        label
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    targets {
      interval
      categories {
        label
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    mask {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    blankInterval
    blocks {
      index
      name
      type
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      amountTrials
      attributeVariant
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      __typename
    }
    startLabel
    nextLabel
    finishLabel
    createdAt
    updatedAt
    __typename
  }
}
`;

export const getPublicPodt = `
query GetPublicPodt($id: ID!) {
 getPodtTest(id: $id) {
    id
    name
    description
    language
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    counterbalancing
    probabilityWithoutReplacement
    interTrialInterval
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment        fontFace
        __typename
      }
      __typename
    }
    response {
      timeout
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      active {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      passive {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    scenes {
      maxAmountScenesPerTrial
      scenes {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        boxes {
          top
          left
          width
          height
          __typename
        }
        __typename
      }
      intervalConfig {
        min
        max
        stepSize
        __typename
      }
      __typename
    }
    targets {
      label
      practiceStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      testStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    fixation {
      interval
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    trialFeedback {
      correctRejectionText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      hitText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      missText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      falseAlarmText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      noResponseText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      pointsFontsize
      __typename
    }
    blocks {
      index
      name
      type
      amountTrials
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment        fontFace
        __typename
      }
        __typename
      }
      preparationScreen {
        interval
        screen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment        fontFace
        __typename
      }
          __typename
        }
        __typename
      }
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment        fontFace
        __typename
      }
        __typename
      }
      __typename
    }
    showCategoriesAfterInstructions
    startLabel
    nextLabel
    finishLabel
    createdAt
    updatedAt
    __typename
  }
}
`;

export const getPublicPodta = `
query GetPublicPodta($id: ID!) {
  getPodtaTest(id: $id) {
    id
    name
    language
    description
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    counterbalancing
    probabilityWithoutReplacement
    interTrialInterval
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment        fontFace
        __typename
      }
      __typename
    }
    response {
      timeout
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      active {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      passive {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    scenes {
      maxAmountScenesPerTrial
      scenes {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        boxes {
          top
          left
          width
          height
          position
          __typename
        }
        __typename
      }
      intervalConfig {
        min
        max
        stepSize
        __typename
      }
      __typename
    }
    targets {
      label
      practiceStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      testStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    fixation {
      interval
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    trialFeedback {
      correctRejectionText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      hitText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      missText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      falseAlarmText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      noResponseText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      pointsFontsize
      __typename
    }
    blocks {
      index
      name
      type
      amountTrials
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment        fontFace
        __typename
      }
        __typename
      }
      preparationScreen {
        interval
        screen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment        fontFace
        __typename
      }
          __typename
        }
        __typename
      }
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment        fontFace
        __typename
      }
        __typename
      }
      __typename
    }
    showCategoriesAfterInstructions
    startLabel
    nextLabel
    finishLabel
    createdAt
    updatedAt
    __typename
  }
}
`;

export const getPublicWsw = `
query GetPublicWSW($id: ID!) {
 getWSWTest(id: $id) {
    id
    name
    description
    language
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment        fontFace
        __typename
      }
      __typename
    }
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment        fontFace
        __typename
      }
      __typename
    }
    attributeCombinations {
      mainCategory
      additionalCategory
      criteriaCategory
      amount
      multiplicator
      __typename
    }
    mainAttribute {
      stimuliVariant
      type
      pickingStrategy
      categories {
        name
        mediaSnaps {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    criteriaAttribute {
      stimuliVariant
      type
      pickingStrategy
      categories {
        name
        mediaSnaps {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    additionalAttribute {
      stimuliVariant
      type
      pickingStrategy
      categories {
        name
        mediaSnaps {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    groupConstruction {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment        fontFace
        __typename
      }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment        fontFace
        __typename
      }
        __typename
      }
      fakeQuestions {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          stimulusTimeout

          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          stimulusTimeout

          __typename
        }
        __typename
      }
      conditions {
        condition1
        condition2
        condition3
        __typename
      }
      groups {
        name
        color
        questionSet {
          randomizeSequence
          conditionalQuestion {
            questionText
            confirmText
            declineText
            answerType
            __typename
          }
          questionsAfter {
            noAnswer
            type
            questionText
            multiple {
              randomized
              options
              __typename
            }
            imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
            scale {
              invert
              min {
                name
                value
                __typename
              }
              max {
                name
                value
                __typename
              }
              __typename
            }
            mediaItemSnapshot {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            stimulusTimeout

            __typename
          }
          questionsBefore {
            noAnswer
            type
            questionText
            multiple {
              randomized
              options
              __typename
            }
            imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
            scale {
              invert
              min {
                name
                value
                __typename
              }
              max {
                name
                value
                __typename
              }
              __typename
            }
            mediaItemSnapshot {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            stimulusTimeout

            __typename
          }
          __typename
        }
        probability
        feedbackScreen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment        fontFace
        __typename
      }
          __typename
        }
        __typename
      }
      randomizeGroupColors
      __typename
    }
    learningPhase {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment        fontFace
        __typename
      }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment        fontFace
        __typename
      }
        __typename
      }
      questionsSet {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          stimulusTimeout

          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          stimulusTimeout

          __typename
        }
        __typename
      }
      questionOffsetTimeout
      __typename
    }
    testPhase {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment        fontFace
        __typename
      }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment        fontFace
        __typename
      }
        __typename
      }
      questionsSet {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          stimulusTimeout

          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          stimulusTimeout

          __typename
        }
        __typename
      }
      questionOffsetTimeout
      __typename
    }
    distractionPhase {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment        fontFace
        __typename
      }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment        fontFace
        __typename
      }
        __typename
      }
      questionsSet {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          stimulusTimeout

          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          stimulusTimeout

          __typename
        }
        __typename
      }
      __typename
    }
    startLabel
    nextLabel
    finishLabel
    createdAt
    updatedAt
    __typename
  }
}
`;
