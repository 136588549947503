import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { TestStep } from '../testEditor/types';
import { BaseTest } from '../../types';

interface TestDetailsStepAccordionProps<T extends BaseTest> {
  test: T;
  stepIndex: number;
  step: TestStep;
}

export function TestDetailsStepAccordion<T extends BaseTest>({
  test,
  step,
  stepIndex,
}: TestDetailsStepAccordionProps<T>) {
  return step.view ? (
    <Accordion defaultExpanded={stepIndex === 0}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box
          sx={{
            width: '33%',
            flexShrink: 0,
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          <Avatar
            sx={{
              height: 24,
              width: 24,
              bgcolor: 'primary.main',
              mr: 2,
            }}
          >
            {stepIndex + 1}
          </Avatar>
          {step.name}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <step.view test={test} />
      </AccordionDetails>
    </Accordion>
  ) : (
    <React.Fragment />
  );
}
