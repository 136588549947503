import {
  BasicDimension,
  DrawableDimension,
} from '../../media/drawable-dimensions';
import React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';

function defineScreenSize(width: number, height: number, factor = 0.75) {
  return {
    dimension: DrawableDimension.toInt({
      width: width * factor,
      height: height * factor,
    }),
    factor,
    description: `${width} x ${height} (*${factor})`,
  };
}

const screenSizes: ReturnType<typeof defineScreenSize>[] = [
  defineScreenSize(1920, 1080),
  defineScreenSize(1366, 768),
  defineScreenSize(360, 640),
  defineScreenSize(414, 896),
  defineScreenSize(1536, 864),
  defineScreenSize(375, 667),
];

export function ScreenSizeSelect({
  select,
}: {
  select: (size: BasicDimension) => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  return (
    <div>
      <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
        <AspectRatioIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {screenSizes.map((size) => (
          <MenuItem
            onClick={() => {
              select(size.dimension);
            }}
          >
            {size.description}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
