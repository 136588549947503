import React, { FunctionComponent } from 'react';
import { useField } from 'formik';
import {
  Box,
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
} from '@mui/material';
import { UiInput } from './types';
import { InfoOutlined } from '@mui/icons-material';

type StyledNumberInputProps = UiInput<number> &
  TextFieldProps & {
    unit?: string;
    size?: 'small';
    toolTip?: string;
  };

const StyledNumberInput: FunctionComponent<StyledNumberInputProps> = (
  props,
) => {
  const { name, toolTip, unit, ...rest } = props;
  const [, meta, helpers] = useField<typeof rest.value>(name);

  const onChangeNumberField = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof rest.onChange === 'function') {
      rest.onChange(Number(ev.target.value));
    }
    helpers.setValue(Number(ev.target.value));
  };

  return (
    <Box position="relative">
      <TextField
        fullWidth
        size={props.size}
        type="number"
        value={meta.value}
        disabled={props.disabled}
        onChange={onChangeNumberField}
        error={meta.touched && meta.error !== undefined}
        helperText={(meta.touched && meta.error) || ''}
        InputProps={
          unit
            ? {
                endAdornment: (
                  <InputAdornment position="end">{unit}</InputAdornment>
                ),
              }
            : undefined
        }
        {...props}
      />
      {toolTip && (
        <Tooltip title={toolTip} placement="left-start">
          <InfoOutlined
            sx={{
              color: 'warning.dark',
              bgcolor: 'white',
              position: 'absolute',
              top: -10,
              right: -10,
            }}
          />
        </Tooltip>
      )}
    </Box>
  );
};

export default StyledNumberInput;
