import React, { FunctionComponent } from 'react';
import { ErrorMessage, useField } from 'formik';
import { Grid, Typography } from '@mui/material';
import Toggle from './Toggle';
import { PodtPreparationScreenInput } from '../../API';
import { getDefaultPreparationScreen } from '../../features/tests/PODT/creator/initialState';
import { EditorVariables } from '../../features/tests/text-replacer/textReplacer';
import StyledNumberInput from './StyledNumberInput';
import { Wysiwyg } from './wysiwyg/Wysiwyg';

const PreparationScreenPickerWithToggle: FunctionComponent<{
  blockName: string;
  toggleOn: boolean;
  variables?: EditorVariables<string>;
}> = ({ blockName, toggleOn, variables }) => {
  const [, meta] = useField(`${blockName}.preparation`);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Toggle<PodtPreparationScreenInput>
          name={`${blockName}.preparationScreen`}
          label="Show PreparationScreen"
          left="No"
          right="Yes"
          onToggleOn={() => getDefaultPreparationScreen()}
          hideError
        />
      </Grid>
      {toggleOn && (
        <>
          <Grid item xs={12}>
            <StyledNumberInput
              name={`${blockName}.preparationScreen.interval`}
              label="Preparation Interval"
              unit="ms"
            />
          </Grid>
          <Grid item xs={12}>
            <Wysiwyg
              name={`${blockName}.preparationScreen.screen`}
              label="Preparation Content"
              variables={variables}
            />
          </Grid>
        </>
      )}
      {meta.error && (
        <Typography variant="caption" color="error">
          <ErrorMessage name={`${blockName}.preparationScreen`} />
        </Typography>
      )}
    </Grid>
  );
};

export default PreparationScreenPickerWithToggle;
