import {
  ImageMediaData,
  ImageSize,
  MediaData,
  transformMediaItemData,
} from './MediaData';
import { MediaItemSnapshot } from '../../../../API';
import { uniqBy } from 'lodash';
import { Storage } from 'aws-amplify';
import { delay, isDefined } from '../../utils';
import { BaseTest } from '../../../tests/types';
import { TestLoadingListener } from '../loading-state';

interface MediaResource<D> {
  type: string;
  key: string;
  mediaItem: MediaData;
  resourceData: D;
}

export interface ImageMediaResource extends MediaResource<CanvasImageSource> {
  type: 'image';
  mediaItem: ImageMediaData;
  imageType: ImageSize;
}

export type MediaResourceRequest<M extends MediaResource<any>> = Omit<
  M,
  'resourceData'
>;

export interface MediaResourceHandler<M extends MediaResource<any>> {
  type: M['type'];

  handleRequest(mediaItem: MediaData): MediaResourceRequest<M>[] | undefined;

  loadResource(request: MediaResourceRequest<M>): Promise<M>;
}

export type ImageResourceRequester = (
  key: string,
) => Promise<CanvasImageSource>;

export type ImageUrlRetriever = (key: string) => Promise<string>;
export type ImageUrlLoader = (url: string) => Promise<CanvasImageSource>;

export interface ImgUrlRequesterConfig {
  urlRetriever: ImageUrlRetriever;
  urlLoader: ImageUrlLoader;
}

export function CreateUrlRequestConfig(
  retriever: ImageUrlRetriever,
  loader: ImageUrlLoader,
) {
  return {
    urlRetriever: retriever,
    urlLoader: loader,
  };
}

export function UrlImgResourceRequester(
  config: ImgUrlRequesterConfig,
): ImageResourceRequester {
  return async (key: string) => {
    const url = await config.urlRetriever(key);
    let image = undefined as undefined | CanvasImageSource;
    let amountOfTries = 0;
    while (image === undefined) {
      amountOfTries = amountOfTries + 1;
      try {
        image = await config.urlLoader(url);
      } catch (e) {
        await delay(500);
      }
      if (amountOfTries > 3) {
        throw new Error('Could not load image after several retries');
      }
    }
    return image;
  };
}

export function S3UrlRetriever(): ImageUrlRetriever {
  return async (key: string) => {
    let imageUrl = undefined as undefined | string;
    let amountOfTries = 0;
    while (imageUrl === undefined) {
      amountOfTries++;
      try {
        imageUrl = (await Storage.get(key)) as string;
      } catch (e) {
        await delay(500);
      }
      if (amountOfTries > 3) {
        throw new Error('Could not resolve image url after several retries');
      }
    }
    return imageUrl;
  };
}

export function NetworkImageUrlLoader(): ImageUrlLoader {
  return async (url: string) => {
    const img = new Image();
    const drawer = new Promise<CanvasImageSource>((resolve, reject) => {
      img.onload = () => {
        resolve(img);
      };
      img.onerror = reject;
    });
    img.src = url;
    img.crossOrigin = 'anonymous';
    return drawer;
  };
}

export function NetworkImageResourceRequester(
  urlRetriever: ImageUrlRetriever,
): ImageResourceRequester {
  return UrlImgResourceRequester(
    CreateUrlRequestConfig(urlRetriever, NetworkImageUrlLoader()),
  );
}

export function S3ImageResourceRequester(): ImageResourceRequester {
  return NetworkImageResourceRequester(S3UrlRetriever());
}

export class ImageResourceHandler
  implements MediaResourceHandler<ImageMediaResource>
{
  constructor(private requester: ImageResourceRequester) {}

  type: 'image' = 'image';

  handleRequest(
    mediaItem: MediaData,
  ): MediaResourceRequest<ImageMediaResource>[] | undefined {
    if (mediaItem instanceof ImageMediaData) {
      const imageItem = mediaItem.image;
      const createRequest = (
        type: ImageSize,
      ): MediaResourceRequest<ImageMediaResource> => ({
        type: 'image',
        imageType: type,
        mediaItem,
        key: imageItem[type]!.key!,
      });
      return (['fullSize'] as ImageSize[])
        .filter((t) => !!imageItem[t])
        .map((t) => createRequest(t));
    }
  }

  async loadResource(
    request: MediaResourceRequest<ImageMediaResource>,
  ): Promise<ImageMediaResource> {
    const resourceData = await this.requester(request.key);
    return { ...request, resourceData };
  }
}

class MediaResourceCache<M extends MediaResource<any>> {
  private cacheMap: Record<string, M>;

  constructor(entries: M[]) {
    this.cacheMap = Object.fromEntries(entries.map((e) => [e.key, e]));
  }

  get entries(): M[] {
    return Object.values(this.cacheMap);
  }

  getEntry(key: string) {
    return this.cacheMap[key];
  }
}

class MediaResourceLoaderManager<M extends MediaResource<any>> {
  private requests: MediaResourceRequest<M>[] = [];

  constructor(
    public name: string,
    private handler: MediaResourceHandler<M>,
  ) {}

  get type() {
    return this.handler.type;
  }

  attachRequest(request: MediaResourceRequest<M>) {
    this.requests.push(request);
  }

  handleItemRequests(...items: (MediaItemSnapshot | MediaData)[]) {
    this.requests.push(
      ...items
        .map((i) => (i instanceof MediaData ? i : transformMediaItemData(i)))
        .flatMap((i) => this.handler.handleRequest(i))
        .filter(isDefined),
    );
  }

  loadResourceCache(listener: (current: number, total: number) => void): {
    amount: number;
    result: Promise<MediaResourceCache<M>>;
  } {
    const amount = uniqBy(this.requests, (r) => r.key).length;
    let currentAmount = 0;
    return {
      amount,
      result: Promise.all(
        uniqBy(this.requests, (r) => r.key).map(async (r) => {
          const resource = await this.handler.loadResource(r);
          currentAmount++;
          listener(currentAmount, amount);
          return resource;
        }),
      ).then((elements) => new MediaResourceCache<M>(elements)),
    };
  }
}

export class MediaResourceLoadingManager<
  Map extends Record<string, MediaResource<any>>,
> {
  private loaderManagers: {
    [n in keyof Map]: MediaResourceLoaderManager<Map[n]>;
  };

  constructor(
    handlerMap: { [n in keyof Map]: MediaResourceHandler<Map[n]> },
    private listener?: TestLoadingListener,
  ) {
    this.loaderManagers = Object.fromEntries(
      Object.entries(handlerMap).map(([k, v]) => [
        k,
        new MediaResourceLoaderManager(k, v),
      ]),
    ) as any;
    listener?.({ stage: 'data' });
  }

  attachItems(...items: MediaItemSnapshot[]) {
    Object.values(this.loaderManagers).forEach((h) =>
      h.handleItemRequests(...items),
    );
  }

  async loadCache(): Promise<MediaResourceStorage<Map>> {
    this.listener?.({
      stage: 'media',
      total: 0,
      loaded: 0,
    });
    const loadingStates = [] as { current: number; total: number }[];
    const mediaResourceStorage = new MediaResourceStorage<Map>(
      Object.fromEntries(
        await Promise.all(
          Object.entries(this.loaderManagers).map(
            async ([k, v]: [
              k: keyof Map,
              v: MediaResourceLoaderManager<any>,
            ]) => {
              const loadingState = { current: 0, total: 0 };
              loadingStates.push(loadingState);
              const resourceCache = v.loadResourceCache((current, total) => {
                Object.assign(loadingState, { current, total });
                this.listener?.({
                  stage: 'media',
                  loaded: loadingStates.reduce((acc, c) => c.current + acc, 0),
                  total: loadingStates.reduce((acc, c) => c.total + acc, 0),
                });
              });
              loadingState.total = resourceCache.amount;
              return [k, await resourceCache.result];
            },
          ),
        ),
      ) as { [n in keyof Map]: MediaResourceCache<Map[n]> },
    );
    this.listener?.({
      stage: 'arrange',
    });
    return mediaResourceStorage;
  }

  static CreateTestManager(
    imageRequester: ImageResourceRequester,
    listener?: TestLoadingListener,
  ): MediaResourceLoadingManager<TestMediaResourceMap> {
    return new MediaResourceLoadingManager(
      {
        image: new ImageResourceHandler(imageRequester),
      },
      listener,
    );
  }
}

export function S3TestLoadingManager(
  listener?: TestLoadingListener,
): MediaResourceLoadingManager<TestMediaResourceMap> {
  return MediaResourceLoadingManager.CreateTestManager(
    S3ImageResourceRequester(),
    listener,
  );
}

export type TestMediaResourceMap = {
  image: ImageMediaResource;
};

export class MediaResourceStorage<
  Map extends Record<string, MediaResource<any>>,
> {
  private storageMap: { [n in keyof Map]: MediaResourceCache<Map[n]> };

  constructor(storageMap: { [n in keyof Map]: MediaResourceCache<Map[n]> }) {
    this.storageMap = storageMap;
  }

  getResource<K extends keyof Map>(type: K, key: string): Map[K] {
    const entry = this.storageMap[type].getEntry(key);
    if (entry === undefined) {
      throw new Error(
        `resource of type ${
          type as string
        } with key ${key} has not been loaded`,
      );
    }
    return entry;
  }
}

export type TestMediaResourceLoader<T extends BaseTest> = (
  test: T,
  loadingManager: MediaResourceLoadingManager<TestMediaResourceMap>,
) => Promise<MediaResourceStorage<TestMediaResourceMap>>;
