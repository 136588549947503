import { SelectorTree } from '../../../subject/testRunner/screens/selectors/selector-tree';
import {
  DisplayData,
  Optional,
  OptionalValue,
} from '../../../subject/testRunner/screens/selectors/data-wrapper';
import { InstructionsContent } from '../../../subject/testRunner/screens/basic-views/test-instructions-view';
import { WswScreenData } from '../loader/view-data/wsw-screen-data';
import { DrawableMediaInstance } from '../../../subject/testRunner/media/MediaData';
import { WswConstructedGroup } from '../loader/structure/wsw-structure';
import { WswTestQuestionInstance } from '../loader/model/questions/wsw-test-question-instance';
import { WswInstructionsData } from '../loader/view-data/wsw-instructions-data';
import {
  WswDoubleStimulus,
  WswQuestionData,
  WswQuestionStimulus,
  WswSingleStimulus,
} from '../loader/view-data/wsw-question-stimulus';
import { defineContentSelector } from '../../../subject/testRunner/screens/selectors/content-selector';
import { defineGroupSelector } from '../../../subject/testRunner/screens/selectors/group-selector';

const wswRootSelector = defineGroupSelector({
  selectGroupData(
    input: WswScreenData,
  ): OptionalValue<WswInstructionsData | WswQuestionData> {
    return input instanceof WswInstructionsData ||
      input instanceof WswQuestionData
      ? Optional.value(input)
      : Optional.none();
  },
  children: {
    probandGroup: defineContentSelector({
      selectRenderData: (input: WswScreenData) => {
        return input.probandGroup !== null
          ? DisplayData.displayData<WswConstructedGroup>(input.probandGroup)
          : DisplayData.hide<WswConstructedGroup>();
      },
    }),
    instructions: defineContentSelector({
      selectRenderData: (input: WswInstructionsData | WswQuestionData) => {
        return input instanceof WswInstructionsData
          ? DisplayData.displayData<InstructionsContent>(input.content)
          : DisplayData.hide<InstructionsContent>();
      },
    }),
    phaseQuestions: defineGroupSelector({
      selectGroupData(input: WswQuestionData | WswInstructionsData) {
        return input instanceof WswQuestionData
          ? Optional.value(input)
          : Optional.none();
      },
      children: {
        stimulus: defineGroupSelector({
          selectGroupData(input: WswQuestionData) {
            return Optional.value(input.questionStimulus);
          },
          children: {
            presentation: defineContentSelector({
              selectRenderData: (input: WswQuestionStimulus) => {
                return input instanceof WswSingleStimulus &&
                  input.scope === 'presentation'
                  ? DisplayData.displayData<WswSingleStimulus>(input)
                  : DisplayData.hide<WswSingleStimulus>();
              },
            }),
            distraction: defineContentSelector({
              selectRenderData: (input: WswQuestionStimulus) => {
                return input instanceof WswSingleStimulus &&
                  input.scope === 'distraction'
                  ? DisplayData.displayData<DrawableMediaInstance>(
                      input.instance as DrawableMediaInstance,
                    )
                  : DisplayData.hide<DrawableMediaInstance>();
              },
            }),
            'group-construction': defineContentSelector({
              selectRenderData(input: WswQuestionStimulus) {
                return input instanceof WswSingleStimulus &&
                  input.scope === 'group-construction'
                  ? DisplayData.displayData<DrawableMediaInstance>(
                      input.instance as DrawableMediaInstance,
                    )
                  : DisplayData.hide<DrawableMediaInstance>();
              },
            }),
            double: defineGroupSelector({
              selectGroupData(input: WswQuestionStimulus) {
                return input instanceof WswDoubleStimulus
                  ? Optional.value(input)
                  : Optional.none();
              },
              children: {
                primary: defineContentSelector({
                  selectRenderData: (input: WswDoubleStimulus) => {
                    return DisplayData.displayData<WswSingleStimulus>(
                      input.primaryStimulus,
                    );
                  },
                }),
                secondary: defineContentSelector({
                  selectRenderData: (input: WswDoubleStimulus) => {
                    return DisplayData.displayData<WswSingleStimulus>(
                      input.secondaryStimulus,
                    );
                  },
                }),
              },
            }),
          },
        }),
        question: defineContentSelector({
          selectRenderData: (input: WswQuestionData) => {
            return input.question !== null
              ? DisplayData.displayData<WswTestQuestionInstance>(input.question)
              : DisplayData.hide<WswTestQuestionInstance>();
          },
        }),
      },
    }),
  },
});
export const WSWSelectorTree = new SelectorTree('wsw', wswRootSelector);
