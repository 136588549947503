import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  Tooltip,
  Typography,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { SceneSettingsState } from './steps/scenes/PodtSceneSettings';
import { Unwrapped } from '../../IAT/types';
import MediaOffscreenCanvas from '../../../subject/MediaOffscreenCanvas';
import { TypeObject } from '../../../subject/utils';

const boxColors = [
  'rgba(104,30,199,0.5)',
  'rgba(48,206,27,0.5)',
  'rgba(251,13,45,0.5)',
  'rgba(239,215,12,0.5)',
];

type SceneBoxEditorProps = {
  state: SceneSettingsState;
  setBoxes: (boxes: SceneSettingsState['boxes']) => any;
  offscreenCanvas: MediaOffscreenCanvas;
  withMenu: boolean;
  isLockedHeight?: boolean;
  key: string;
  active?: boolean;
};

type BoxListItemProps = {
  selected: boolean;
  deleteItem: (index: number) => void;
  dimensions: { width: number; height: number };
  box: Unwrapped<SceneSettingsState['boxes']>;
  index: number;
  toggleSelection: (index: number) => void;
};

const BoxListItem: FunctionComponent<BoxListItemProps> = ({
  index,
  toggleSelection,
  selected,
  box,
  deleteItem,
}) => {
  return (
    <ListItem
      key={index}
      button
      onClick={() => toggleSelection(index)}
      selected={selected}
    >
      <ListItemAvatar>
        <Avatar
          style={{ color: boxColors[index], backgroundColor: boxColors[index] }}
        />
      </ListItemAvatar>
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => deleteItem(index)}
          size="large"
        >
          <Delete />
        </IconButton>
      </ListItemSecondaryAction>
      <Typography>{`(${box.width},${box.height})`}</Typography>
    </ListItem>
  );
};

const createCanvasEditor = (
  canvas: HTMLCanvasElement,
  state: SceneSettingsState,
  scene: MediaOffscreenCanvas,
  setState: SceneBoxEditorProps['setBoxes'],
  selection?: number,
  isLockHeight?: boolean,
) => {
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  const backgroundCanvas = document.createElement('canvas');
  const backgroundCtx = backgroundCanvas.getContext(
    '2d',
  ) as CanvasRenderingContext2D;
  backgroundCanvas.width = canvas.width;
  backgroundCanvas.height = canvas.height;
  const computedStyle = getComputedStyle(canvas);

  const ratios: RatioDimension = {
    width: parseInt(computedStyle.width.split('.')[0]) / scene.width,
    height: parseInt(computedStyle.height.split('.')[0]) / scene.height,
  };
  backgroundCtx.drawImage(scene.canvas, 0, 0, canvas.width, canvas.height);
  state.boxes.forEach((b, i) => {
    if (selection !== i) {
      backgroundCtx.save();
      const { left } = b;
      const { top } = b;
      backgroundCtx.fillStyle = boxColors[i];
      backgroundCtx.fillRect(left, top, b.width, b.height);
      backgroundCtx.restore();
    }
  });

  const selectedBox =
    selection !== undefined ? state.boxes[selection] : undefined;
  const mouseHandler = () => {
    let drag = false;
    let boxDrag = undefined as { x: number; y: number } | undefined;
    const coordinates = (e: MouseEvent) => ({
      x: (e.clientX - canvas.getBoundingClientRect().left) / ratios.width,
      y: (e.clientY - canvas.getBoundingClientRect().top) / ratios.height,
    });
    return {
      mousemove: (e: MouseEvent) => {
        const coords = coordinates(e);
        if (drag) {
          if (selectedBox) {
            if (!isLockHeight) {
              selectedBox.height = Math.max(
                60,
                Math.round(coords.y - selectedBox.top),
              );
            }
            selectedBox.width = Math.max(
              60,
              Math.round(coords.x - selectedBox.left),
            );
            draw();
          }
        }
        if (boxDrag) {
          if (selectedBox) {
            selectedBox.left = Math.min(
              scene.width - selectedBox.width,
              Math.max(0, Math.round(coords.x - boxDrag.x)),
            );
            selectedBox.top = Math.min(
              scene.height - selectedBox.height,
              Math.max(0, Math.round(coords.y - boxDrag.y)),
            );
            draw();
          }
        }
      },
      mousedown: (e: MouseEvent) => {
        const coords = coordinates(e);
        if (selectedBox) {
          const { left } = selectedBox;
          const { top } = selectedBox;
          const right = selectedBox.left + selectedBox.width;
          const bottom = selectedBox.top + selectedBox.height;
          if (boxDrag === undefined && !drag) {
            if (
              coords.x <= right &&
              coords.x >= right - 30 &&
              coords.y <= bottom &&
              coords.y >= bottom - 30
            ) {
              drag = true;
            } else if (
              coords.x <= right &&
              coords.x >= left &&
              coords.y <= bottom &&
              coords.y >= top
            ) {
              boxDrag = { x: coords.x - left, y: coords.y - top };
            }
          }
        }
      },
      mouseup: (_e: MouseEvent) => {
        if (drag || boxDrag) {
          drag = false;
          boxDrag = undefined;
          // draw();
          setState(
            state.boxes.map((b, i) =>
              i === selection && selectedBox ? selectedBox : b,
            ),
          );
        }
      },
      mouseenter: (e: MouseEvent) => {
        // console.log(e.buttons);
        if (e.buttons === 0 && (drag || boxDrag)) {
          drag = false;
          boxDrag = undefined;
          // draw();
          setState(
            state.boxes.map((b, i) =>
              i === selection && selectedBox ? selectedBox : b,
            ),
          );
        }
      },
      mouseleave: (e: MouseEvent) => {
        const coords = coordinates(e);
        if (drag) {
          if (selectedBox) {
            if (!isLockHeight) {
              selectedBox.height = Math.round(
                Math.min(coords.y, scene.height - 30) - selectedBox.top,
              );
            }
            selectedBox.width = Math.round(
              Math.min(coords.x, scene.width) - selectedBox.left,
            );
            // draw();
          }
        }
      },
    };
  };

  const draw = () => {
    ctx.drawImage(backgroundCanvas, 0, 0);
    if (selectedBox) {
      const { left } = selectedBox;
      const { top } = selectedBox;
      const right = selectedBox.left + selectedBox.width;
      const bottom = selectedBox.top + selectedBox.height;
      ctx.save();
      const handlePath = new Path2D(
        `M ${right} ${bottom} l -30 0 l 0 -30 l 30 0 z`,
      );
      ctx.fill(handlePath);
      ctx.strokeRect(left, top, selectedBox.width, selectedBox.height);
      ctx.fillStyle = boxColors[selection || 0];
      ctx.fillRect(left, top, selectedBox.width, selectedBox.height);
      const dimensionsAsString = `(${selectedBox.width}, ${selectedBox.height})`;
      ctx.font = '60px Arial';
      ctx.fillStyle = 'white';
      ctx.fillText(dimensionsAsString, left, top);
      ctx.restore();
    }
  };
  draw();
  return mouseHandler();
};

type RatioDimension = {
  width: number;
  height: number;
};

const SceneBoxEditor: FunctionComponent<SceneBoxEditorProps> = ({
  state,
  setBoxes,
  offscreenCanvas,
  isLockedHeight,
  withMenu,
  key,
  active,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [boxSelection, setBoxSelection] = useState<number>(0);

  const addBox = () => {
    setBoxSelection(state.boxes.length);
    setBoxes([
      ...state.boxes,
      {
        left: 100,
        top: 100,
        width: state.boxes[state.boxes.length - 1]?.width || 300,
        height: state.boxes[state.boxes.length - 1]?.height || 300,
      },
    ]);
  };

  const removeBox = (index: number) => () => {
    setBoxes(state.boxes.filter((_v, i) => i !== index));
  };

  useEffect(() => {
    if (active && canvasRef.current) {
      const c = canvasRef.current;
      const canvasHandler = createCanvasEditor(
        canvasRef.current,
        state,
        offscreenCanvas,
        setBoxes,
        boxSelection,
        isLockedHeight,
      );
      TypeObject.keys(canvasHandler).forEach((eventListenerKey) =>
        withMenu
          ? c.addEventListener(
              eventListenerKey,
              canvasHandler[eventListenerKey],
            )
          : (e: any) => e.preventDefault(),
      );
      return () =>
        TypeObject.keys(canvasHandler).forEach((evListenerMapKey) =>
          c.removeEventListener(
            evListenerMapKey,
            canvasHandler[evListenerMapKey],
          ),
        );
    }
  }, [
    active,
    boxSelection,
    isLockedHeight,
    offscreenCanvas,
    setBoxes,
    state,
    state.boxes,
    withMenu,
  ]);

  const canvasHeight = 450;
  const listWidth = 220;
  const listTranslate =
    (offscreenCanvas.width * canvasHeight) / offscreenCanvas.height / 2 +
    listWidth / 2;

  return (
    <div
      key={key}
      style={{
        height: canvasHeight,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {!withMenu && state.mediaItem.image ? (
        <canvas
          ref={canvasRef}
          width={offscreenCanvas.width}
          height={offscreenCanvas.height}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      ) : (
        <React.Fragment>
          <canvas
            ref={canvasRef}
            width={offscreenCanvas.width}
            height={offscreenCanvas.height}
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
          {withMenu && (
            <List
              style={{
                width: listWidth,
                // overflowY: "auto",
                position: 'absolute',
                transform: `translate(${listTranslate}px)`,
              }}
            >
              {state.boxes.map((b, i) => (
                <BoxListItem
                  dimensions={{
                    width: offscreenCanvas.width,
                    height: offscreenCanvas.height,
                  }}
                  selected={boxSelection === i}
                  box={b}
                  index={i}
                  deleteItem={removeBox(i)}
                  toggleSelection={setBoxSelection}
                />
              ))}
              <ListItem button onClick={addBox}>
                <ListItemAvatar>
                  <Tooltip title="Add Box">
                    <Avatar>
                      <Add fontSize="small" />
                    </Avatar>
                  </Tooltip>
                </ListItemAvatar>
              </ListItem>
            </List>
          )}
        </React.Fragment>
      )}
    </div>
  );
};
export default SceneBoxEditor;
