import React, { FunctionComponent } from 'react';
import { useField } from 'formik';
import { Button } from '@mui/material';

type DuplicateButtonProps = {
  label: string;
  from: string;
  to: string;
};

const DuplicateButton: FunctionComponent<DuplicateButtonProps> = ({
  label,
  from,
  to,
}) => {
  const [, fromMeta] = useField(from);
  const [, , toHelpers] = useField(to);
  return (
    <Button
      sx={{ width: 340 }}
      onClick={() => toHelpers.setValue(fromMeta.value)}
      color="primary"
    >
      Duplicate Practice- to Test-Pool
    </Button>
  );
};

export default DuplicateButton;
