import React, { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useAuthUserEmail } from '../auth/auth.hook';
import _ from 'lodash';
import { TestWidget } from './tests/TestWidget';
import { TestType } from './subject/types';
import { fetchIatTests } from './tests/IAT/redux/iat.thunk';
import { fetchAmpTests } from './tests/AMP/redux/amp.thunk';
import { fetchPodtTests } from './tests/PODT/redux/podt.thunk';
import { fetchPodtaTests } from './tests/PODTA/redux/podta.thunk';
import { fetchWswTests } from './tests/WSW/redux/wsw.thunk';
import { RequireAuth } from '../auth/RequiredAuth';

export const Dashboard: FC = () => {
  const userEmail = useAuthUserEmail();

  return (
    <RequireAuth>
      <Box sx={{ pt: 3 }}>
        <Typography variant="h4" sx={{ mb: 1 }}>
          Welcome {_.startCase(userEmail.split('@')[0])}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ mb: 3, ml: '2px' }}
        >
          These are the tests that you own.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={6}>
            <TestWidget type={TestType.IAT} fetchTestsThunk={fetchIatTests} />
          </Grid>
          <Grid item xs={12} xl={6}>
            <TestWidget type={TestType.AMP} fetchTestsThunk={fetchAmpTests} />
          </Grid>
          <Grid item xs={12} xl={6}>
            <TestWidget type={TestType.PODT} fetchTestsThunk={fetchPodtTests} />
          </Grid>
          <Grid item xs={12} xl={6}>
            <TestWidget
              type={TestType.PODTA}
              fetchTestsThunk={fetchPodtaTests}
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <TestWidget type={TestType.WSW} fetchTestsThunk={fetchWswTests} />
          </Grid>
        </Grid>
      </Box>
    </RequireAuth>
  );
};
