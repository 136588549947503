import { BasicDimension, SizedArea } from '../../../media/drawable-dimensions';

export class SizeStateDisplay {
  constructor(
    public readonly name: string,
    public readonly level: number,
  ) {}
  static Fallback = new SizeStateDisplay('', 0);
}
export class SizeState {
  stateDisplay: SizeStateDisplay = SizeStateDisplay.Fallback;

  private _maxSize?: BasicDimension;
  get maxSize(): BasicDimension {
    if (!this._maxSize) {
      throw new Error('Max size not set yet' + this.stateDisplay.name);
    }
    return this._maxSize;
  }
  set maxSize(maxSize: BasicDimension) {
    if (this._maxSize) {
      throw new Error('Max size already set ' + this.stateDisplay.name);
    }
    this._maxSize = maxSize;
  }

  private _minSize?: BasicDimension;
  get minSize(): BasicDimension {
    if (!this._minSize) {
      throw new Error('Min size not set yet' + this.stateDisplay.name);
    }
    return this._minSize;
  }
  set minSize(minSize: BasicDimension) {
    if (this._minSize) {
      throw new Error('Min size already set' + this.stateDisplay.name);
    }
    this._minSize = minSize;
  }
  private _size?: BasicDimension;
  get size(): BasicDimension {
    if (!this._size) {
      throw new Error('size not set yet' + this.stateDisplay.name);
    }
    return this._size;
  }
  set size(size: BasicDimension) {
    if (this._size) {
      throw new Error('size already set' + this.stateDisplay.name);
    }
    this._size = size;
  }
  private _innerSize?: SizedArea;
  get innerSize(): SizedArea {
    if (!this._innerSize) {
      throw new Error('innerSize not set yet' + this.stateDisplay.name);
    }
    return this._innerSize;
  }
  set innerSize(innerSize: SizedArea) {
    if (this._innerSize) {
      throw new Error('innerSize already set' + this.stateDisplay.name);
    }
    this._innerSize = innerSize;
  }
}
