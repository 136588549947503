import React from 'react';
import { Box, Button, Stack } from '@mui/material';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import { useField } from 'formik';
import * as Yup from 'yup';
import { Build } from '@mui/icons-material';
import { IatBlockInput } from '../../../../../API';
import { createDefault7Blocks } from '../blockFactory';
import {
  EditorSectionTitle,
  EditorStepTitle,
} from '../../../../../components/grid';
import MediaItemPicker from '../../../../media/picker/MediaItemPicker';
import TextStyleDialog from '../../../../../components/TextStyleDialog';
import StyleableTextInput from '../../../../../components/input/StyleableTextInput';
import { IatBlocksInput } from './IatBlocks';
import { instructionsSchema } from '../../../testAdmin/testEditor/instructionsSchema';

const categorySchema = Yup.object().shape({
  label: Yup.object().test('hasStimulus', 'Add a Label.', (value: any) =>
    Boolean(value.image || value.text),
  ),
});

const categoriesSchema = Yup.object().shape({
  categoryA: categorySchema,
  categoryB: categorySchema,
});

export const IatBlockSettingsSchema = Yup.object().shape({
  counterbalancing: Yup.boolean(),
  blocks: Yup.array()
    .min(1)
    .of(
      Yup.object().shape({
        name: Yup.string().required('required'),
        type: Yup.string().required('required'),
        instructions: instructionsSchema,
        amountTrials: Yup.number().required('required').min(1),
        // tips: Yup.object()
        //   .nullable()
        //   .shape({
        //     desktop: Yup.string().required('required'),
        //   }),
      }),
    ),
  attributeCategories: categoriesSchema,
  targetCategories: categoriesSchema,
});

const IatBlockSettings = () => {
  const [, , helpers] = useField<IatBlockInput[]>('blocks');
  return (
    <Box>
      <EditorStepTitle prefix={2} title="Block Settings" />
      <Stack spacing={4}>
        <Stack direction="row" spacing={3}>
          <MediaItemPicker
            name="targetCategories.categoryA.label"
            label="Target Category A Label"
            colorFieldName="targetCategories.fontStyle.color"
            bgColorFieldName="style.backgroundColour"
          />
          <MediaItemPicker
            name="targetCategories.categoryB.label"
            label="Target Category B Label"
            colorFieldName="targetCategories.fontStyle.color"
            bgColorFieldName="style.backgroundColour"
          />
          <TextStyleDialog
            withAlignment
            name={'targetCategories.fontStyle'}
            label="Font Style"
          >
            <Box mt={1}>
              <Build />
            </Box>
          </TextStyleDialog>
        </Stack>
        <Box width={584}>
          <StyleableTextInput
            name={'categoryDivider'}
            label={'Category Separator'}
            bgcolorField={'style.backgroundColour'}
          />
        </Box>
        <Stack direction="row" spacing={3}>
          <MediaItemPicker
            name="attributeCategories.categoryA.label"
            label="Attribute Category A Label"
            colorFieldName={'attributeCategories.fontStyle.color'}
            bgColorFieldName={'style.backgroundColour'}
          />
          <MediaItemPicker
            name="attributeCategories.categoryB.label"
            label="Attribute Category B Label"
            colorFieldName={'attributeCategories.fontStyle.color'}
            bgColorFieldName={'style.backgroundColour'}
          />
          <TextStyleDialog
            withAlignment
            name={'attributeCategories.fontStyle'}
            label="Font Style"
          >
            <Build />
          </TextStyleDialog>
        </Stack>

        <EditorSectionTitle subtitle={'Blocks'} />
        <div style={{ margin: '-40px 0 0 100px' }}>
          <Button
            startIcon={<DynamicFeedIcon />}
            onClick={() => helpers.setValue(createDefault7Blocks())}
          >
            Fill blocks with standard values
          </Button>
        </div>
        <IatBlocksInput fieldName={'blocks'} />
      </Stack>
    </Box>
  );
};

export default IatBlockSettings;
