import { createRunnerConfiguration } from '../../../subject/testRunner/runner-configuration';
import { TestType } from '../../../subject/types';
import { AMPSelectorTree } from '../views/amp-selectors';
import { AmpStructure } from '../loader/structure/amp-structure';
import { createAmpViewMap } from '../views/amp-views';
import { AmpResourceLoader } from '../loader/loading/amp-loader';
import { DefaultPhaseRunner } from '../../../subject/testRunner/media/sequence-tasks';
import { AmpResultPreviewTable } from './amp-result-preview.table';
import { AmpLoaderSequence } from '../loader/amp-loader-sequence';
import { CreateOptionTestsControlHandlerFactory } from '../../../subject/testRunner/controls/options-control-handler-factory';

export const AmpRunnerConfig = createRunnerConfiguration(TestType.AMP, {
  selectorTree: AMPSelectorTree,
  resultPreviewTable: AmpResultPreviewTable,
  controlFactory: (structure: AmpStructure) =>
    CreateOptionTestsControlHandlerFactory(
      structure.accessor.testContext,
      structure.accessor.modelData.keyboard,
    ),
  createViews: createAmpViewMap,
  load: (testContext, gql, mediaManager) => {
    return AmpLoaderSequence.performSequence(
      {
        load: {
          testContextFactory: async () => testContext,
          loader: AmpResourceLoader.init(gql, mediaManager),
        },
        arrange: ({ accessor, parameters, pool }) => ({
          pool,
          accessor,
          parameters: parameters.determine(accessor.testContext),
        }),
      },
      new DefaultPhaseRunner(),
    );
  },
});
