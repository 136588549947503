import { first, Observable } from 'rxjs';

export function awaitFirst<T, S extends T = T>(
  observable: Observable<T>,
  predicate?: (value: T, index: number, source: Observable<T>) => value is S,
): Promise<S> {
  let completed = false;
  return new Promise((resolve, reject) => {
    observable.pipe(predicate ? first(predicate) : first()).subscribe({
      next: (value) => {
        completed = true;
        resolve(value as S);
      },
      complete: () => {
        if (!completed) {
          reject('no next happend');
        }
      },
      error: (err) => {
        if (!completed) {
          reject(err);
        }
      },
    });
  });
}
