import { TestInvocationParams } from '../baseTestContext/invocation-params/test-invocation-params';
import * as uuid from 'uuid';
import { TestIdentity } from './types';
import { TestIdentityError } from './TestIdentityError';
import { getTestTypeByIdentifier } from '../../../types';
import { TestInterface } from '../../../../tests/types';
import { GQLClient } from '../../../../../GQL';

/**
 * Gets values for identifying a test from the url
 * and throws if any of the values are missing.
 *
 * @param urlParams to potentially fake params or,
 * in most cases just pass the current params.
 *
 * @return TestIdentity
 *    {
 *      testId,
 *      testType,
 *      probandId (generated)
 *    }
 */
export function getIdentityFromQueryParams({
  pathParams,
}: TestInvocationParams): TestIdentity {
  if (!pathParams.testId) {
    throw new TestIdentityError('no testId specified');
  }
  if (!uuid.validate(pathParams.testId)) {
    throw new TestIdentityError('test is not a uuid');
  }
  if (!pathParams.testType) {
    throw new TestIdentityError('no testType specified');
  }
  const testType = getTestTypeByIdentifier(pathParams.testType);
  if (!testType) {
    throw new TestIdentityError('unknown testType');
  }
  return {
    testId: pathParams.testId,
    testType,
    probandId: uuid.v4(),
  };
}

export type TestDataLoader<T extends TestInterface<any>> = (
  gql: GQLClient,
  id: string,
) => Promise<T>;

export function createTestDataLoader<T extends TestInterface<any>>(
  query: string,
) {
  return (gql: GQLClient, id: string) => {
    return gql.get<{ id: string }, T>(query, { id });
  };
}
