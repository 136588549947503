import MediaOffscreenCanvas from "../../../../subject/MediaOffscreenCanvas";
import { PodtaSceneSettingsState } from "./PodtaSceneBoxEditor";
import { PodtaBoxInput, PodtaBoxPosition } from "../../../../../API";

type RatioDimension = {
  width: number;
  height: number;
};

export const boxColors = ["rgba(104,30,199,0.5)", "rgba(48,206,27,0.5)", "rgba(251,13,45,0.5)", "rgba(239,215,12,0.5)"];

export const createPodtaCanvasEditor = (
  canvas: HTMLCanvasElement,
  state: PodtaSceneSettingsState,
  scene: MediaOffscreenCanvas,
  setState: (boxes: PodtaBoxInput[]) => any,
  selection?: number,
  isLockHeight?: boolean
) => {
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
  const backgroundCanvas = document.createElement("canvas");
  const backgroundCtx = backgroundCanvas.getContext("2d") as CanvasRenderingContext2D;
  backgroundCanvas.width = canvas.width;
  backgroundCanvas.height = canvas.height;
  const computedStyle = getComputedStyle(canvas);

  const ratios: RatioDimension = {
    width: parseInt(computedStyle.width.split(".")[0]) / scene.width,
    height: parseInt(computedStyle.height.split(".")[0]) / scene.height
  };
  backgroundCtx.drawImage(scene.canvas, 0, 0, canvas.width, canvas.height);

  // vertical line
  backgroundCtx.beginPath();
  backgroundCtx.moveTo(backgroundCanvas.width / 2, 0);
  backgroundCtx.lineTo(backgroundCanvas.width / 2, backgroundCanvas.height);
  backgroundCtx.lineWidth = 8;
  backgroundCtx.strokeStyle = "rgba(255, 255, 255, 0.8)";
  backgroundCtx.stroke();

  state.boxes.forEach((b, i) => {
    if (selection !== i) {
      backgroundCtx.save();
      const { left } = b;
      const { top } = b;
      backgroundCtx.fillStyle = boxColors[i];
      backgroundCtx.fillRect(left, top, b.width, b.height);
      backgroundCtx.restore();
    }
  });

  const selectedBox = selection !== undefined ? state.boxes[selection] : undefined;
  const mouseHandler = () => {
    let drag = false;
    let boxDrag = undefined as { x: number; y: number } | undefined;
    const coordinates = (e: MouseEvent) => ({
      x: (e.clientX - canvas.getBoundingClientRect().left) / ratios.width,
      y: (e.clientY - canvas.getBoundingClientRect().top) / ratios.height
    });
    return {
      mousemove: (e: MouseEvent) => {
        const coords = coordinates(e);
        if (drag) {
          if (selectedBox) {
            if (!isLockHeight) {
              selectedBox.height = Math.max(60, Math.round(coords.y - selectedBox.top));
            }
            const width = Math.max(60, Math.round(coords.x - selectedBox.left));
            if (selectedBox.left + width > scene.width * (selectedBox.position === PodtaBoxPosition.LEFT ? 0.5 : 1)) {
              selectedBox.width = Math.round(scene.width * (selectedBox.position === PodtaBoxPosition.LEFT ? 0.5 : 1) - selectedBox.left);
            } else {
              selectedBox.width = width;
            }
            draw();
          }
        }
        if (boxDrag) {
          if (selectedBox) {
            selectedBox.left = Math.min(scene.width * (selectedBox.position === PodtaBoxPosition.LEFT ? 0.5 : 1) - selectedBox.width, Math.max(selectedBox.position === PodtaBoxPosition.LEFT ? 0 : scene.width*0.5, Math.round(coords.x - boxDrag.x)));
            selectedBox.top = Math.min(
              scene.height - selectedBox.height,
              Math.max(0, Math.round(coords.y - boxDrag.y))
            );
            draw();
          }
        }
      },
      mousedown: (e: MouseEvent) => {
        const coords = coordinates(e);
        if (selectedBox) {
          const { left } = selectedBox;
          const { top } = selectedBox;
          const right = selectedBox.left + selectedBox.width;
          const bottom = selectedBox.top + selectedBox.height;
          if (boxDrag === undefined && !drag) {
            if (coords.x <= right && coords.x >= right - 30 && coords.y <= bottom && coords.y >= bottom - 30) {
              drag = true;
            } else if (coords.x <= right && coords.x >= left && coords.y <= bottom && coords.y >= top) {
              boxDrag = { x: coords.x - left, y: coords.y - top };
            }
          }
        }
      },
      mouseup: (_e: MouseEvent) => {
        if (drag || boxDrag) {
          drag = false;
          boxDrag = undefined;
          // draw();
          setState(state.boxes.map((b, i) => (i === selection && selectedBox ? selectedBox : b)));
        }
      },
      mouseenter: (e: MouseEvent) => {
        if (e.buttons === 0 && (drag || boxDrag)) {
          drag = false;
          boxDrag = undefined;
          // draw();
          setState(state.boxes.map((b, i) => (i === selection && selectedBox ? selectedBox : b)));
        }
      },
      mouseleave: (e: MouseEvent) => {
        const coords = coordinates(e);
        if (drag) {
          if (selectedBox) {
            if (!isLockHeight) {
              selectedBox.height = Math.round(Math.min(coords.y, scene.height - 30) - selectedBox.top);
            }
            selectedBox.width = Math.round(Math.min(coords.x, scene.width * (selectedBox.position === PodtaBoxPosition.LEFT ? 0.5 : 1)) - selectedBox.left);
            // draw();
          }
        }
      }
    };
  };

  const draw = () => {
    ctx.drawImage(backgroundCanvas, 0, 0);
    if (selectedBox) {
      const { left } = selectedBox;
      const { top } = selectedBox;
      const right = selectedBox.left + selectedBox.width;
      const bottom = selectedBox.top + selectedBox.height;
      ctx.save();
      const handlePath = new Path2D(`M ${right} ${bottom} l -30 0 l 0 -30 l 30 0 z`);
      ctx.fill(handlePath);
      ctx.strokeRect(left, top, selectedBox.width, selectedBox.height);
      ctx.fillStyle = boxColors[selection || 0];
      ctx.fillRect(left, top, selectedBox.width, selectedBox.height);
      const dimensionsAsString = `(${selectedBox.width}, ${selectedBox.height})`;
      ctx.font = "60px Arial";
      ctx.fillStyle = "white";
      ctx.fillText(dimensionsAsString, left, top);
      ctx.restore();
    }
  };
  draw();
  return mouseHandler();
};
