import { MediaItem } from '../types';
import React, { FC } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { NewImageUploader } from '../uploader/NewImageUploader';
import { Chip, Typography } from '@mui/material';
import { Tag } from '../../../API';
import MediaItemComponent from '../MediaItemComponent';
import { tagEqualOperators } from '../pool/MediaPoolFilters';
import { CustomFilterPanel } from '../../tests/testAdmin/testList/CustomFilterPanel';

export const mediaPoolColumns: GridColDef[] = [
  {
    field: 'Value',
    headerName: 'Media-Item',
    sortable: false,
    filterable: false,
    width: 180,
    renderCell: (params) => (
      <MediaItemComponent mediaItem={params.row as MediaItem} />
    ),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    width: 300,
    filterOperators: tagEqualOperators,
    valueGetter: (params) =>
      (params.row as any)?.tags?.items
        ?.map((t: { tag: Tag }) => t.tag.name)
        .join(', '),
    renderCell: (params) =>
      (params.row as MediaItem)?.tags?.items?.map((tag) => {
        return (
          <Chip style={{ marginRight: 2 }} size="small" label={tag?.tag.name} />
        );
      }),
  },
  {
    hide: true,
    field: 'originalFileName',
    headerName: 'Original file name',
    width: 200,
  },
  {
    hide: true,
    field: 'updatedAt',
    headerName: 'Updated',
    type: 'date',
    width: 300,
    renderCell: (params) => (
      <div>
        <Typography variant="subtitle1">
          {new Date(params.row.updatedAt).toLocaleDateString()}
        </Typography>
        <Typography variant="subtitle2">
          {new Date(params.row.updatedAt).toLocaleTimeString()}
        </Typography>
      </div>
    ),
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 320,
    hide: true,
  },
];

interface PickerTableProps {
  mediaItems: MediaItem[];
  selectedMediaItems: MediaItem[];
  onSelectionChange: (mediaItems: MediaItem[]) => void;
  isLoading: boolean;
  onMediaItemsUploaded?: (mediaItems: MediaItem[]) => void;
  singleSelect?: boolean;
  withoutUpload?: boolean;
  withoutDisabled?: boolean;
  toolbarExtras?: React.ReactNode;
}

export const PickerTable: FC<PickerTableProps> = ({
  selectedMediaItems,
  onSelectionChange,
  isLoading,
  mediaItems,
  onMediaItemsUploaded,
  singleSelect,
  withoutUpload,
  toolbarExtras,
}) => {
  const handleSelectionModelChange = (selectionModel: GridSelectionModel) =>
    onSelectionChange(mediaItems.filter((m) => selectionModel.includes(m.id)));

  return (
    <DataGridPro
      disableMultipleSelection={singleSelect}
      style={{ width: 700, height: '80vh' }}
      checkboxSelection={!singleSelect}
      loading={isLoading}
      selectionModel={selectedMediaItems.map((s) => s.id)}
      onSelectionModelChange={handleSelectionModelChange}
      components={{
        Toolbar: () => (
          <GridToolbarContainer>
            {!withoutUpload && (
              <NewImageUploader onMediaItemsUploaded={onMediaItemsUploaded} />
            )}
            <GridToolbarFilterButton
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
              placeholder=""
            />
            <GridToolbarColumnsButton
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
              placeholder=""
            />
            {toolbarExtras}
          </GridToolbarContainer>
        ),
        FilterPanel: () => <CustomFilterPanel />,
      }}
      rowHeight={100}
      columns={mediaPoolColumns}
      rows={mediaItems}
    />
  );
};
