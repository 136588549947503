import { TestInvocationParams } from './test-invocation-params';
import { TestBaseContextParsingError } from '../context/TestBaseContextParsingError';
import {
  BaseProviderParams,
  getBaseProviderParams,
} from './baseProviderParams';
import { SurveyProviderLookupMap } from '../../TestProviderMap';

interface SurveyProviderParams extends BaseProviderParams {
  // id of the survey at the survey provider to identify
  // and link the test of the platform with the test of the surveyProvider
  surveyId: string;
  // id of the proband at the surey provider to identify
  // and link proband of the platform with the proband of the surveyProvider
  surveyProbandId: string;
}

export type SurveyProviderParamsKeys =
  | Exclude<keyof SurveyProviderParams, 'providerName'>
  | 'provider';
/**
 * Gets values of the surveyProvider from the url
 * in order to identify the test of the surveyProvider and proband
 * then joins this with the baseProviderParams
 *
 * @param params Intended for passing fake params or the current url params
 */
export function getSurveyProviderParams(
  params: TestInvocationParams,
  surveyProviderLookupMap: SurveyProviderLookupMap,
  strictMode: boolean,
): SurveyProviderParams {
  const surveyId = params.configurationParams.get('surveyId');

  if (surveyId === null || !Number.isInteger(Number.parseInt(surveyId))) {
    throw new TestBaseContextParsingError(
      'surveyId does not exists or is not a number: ' + surveyId,
    );
  }

  let surveyProbandId = params.configurationParams.get('surveyProbandId');

  if (
    surveyProbandId === null ||
    !Number.isInteger(Number.parseInt(surveyProbandId))
  ) {
    if (!strictMode) {
      surveyProbandId = '-999999999';
    } else {
      throw new TestBaseContextParsingError(
        'surveyProbandId  does not exists or is not a number: ' +
          surveyProbandId,
      );
    }
  }

  return {
    ...getBaseProviderParams(params, surveyProviderLookupMap),
    surveyId,
    surveyProbandId,
  };
}
