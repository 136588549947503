import { InstructionsContent } from '../../../../subject/testRunner/screens/basic-views/test-instructions-view';
import { WswConstructedGroup } from '../structure/wsw-structure';
import { ControlTransition } from '../../../../subject/testRunner/controls/control-transition';
import { WswScreenData } from './wsw-screen-data';

export class WswInstructionsData extends WswScreenData {
  content: InstructionsContent;

  constructor(
    content: InstructionsContent,
    probandGroup = null as WswConstructedGroup | null,
    controlTransitions?: ControlTransition<any, any>[],
  ) {
    super(probandGroup, controlTransitions);
    this.content = content;
  }
}
