import { Translations } from '../../features/subject/testRunner/state/baseTestContext/app-configuration/translations/app-translations';
import { omit } from 'lodash';
import { StaticTranslationKey } from '../../features/subject/testRunner/state/baseTestContext/app-configuration/translations/translation-key';
import {
  StaticTranslationScope,
  TranslationScopeMap,
} from '../../features/subject/testRunner/state/baseTestContext/app-configuration/translations/translation-scope';
import * as csv from 'export-to-csv';

type TranslationTreeBranch = {
  type: 'branch';
  scope: StaticTranslationScope;
  children: TranslationTreeNode[];
};
type TranslationTreeLeaf = { type: 'leaf'; key: StaticTranslationKey<any> };

type TranslationTreeNode = TranslationTreeLeaf | TranslationTreeBranch;
// @ts-ignore
const csvConfig = csv.mkConfig({ useKeysAsHeaders: true });
function buildTranslationTree(rootNode: typeof Translations.App) {
  function recurseTranslations(
    translations: TranslationScopeMap<any>,
  ): TranslationTreeNode {
    return {
      type: 'branch',
      scope: translations.scope,
      children: Object.values(omit(translations, 'scope')).map((v) =>
        v instanceof StaticTranslationKey
          ? { type: 'leaf', key: v }
          : recurseTranslations(v),
      ),
    };
  }
  return recurseTranslations(rootNode);
}
function buildTranslationMapCode(rootNode: typeof Translations.App) {
  const tree = buildTranslationTree(rootNode);
  function flattenTree(node: TranslationTreeNode): TranslationTreeLeaf[] {
    if (node.type === 'leaf') {
      return [node];
    }
    return node.children.flatMap(flattenTree);
  }
  const leafs = flattenTree(tree);
  return `
   const translationMap = {
      ${leafs.map((leaf) => {
        const key = leaf.key.key;
        const values = Array.isArray(leaf.key.defaults.getValue('en'))
          ? '[' +
            (leaf.key.defaults.getValue('en') as string[])
              .map((v) => `'${v}'`)
              .join(', ') +
            ']'
          : `'${leaf.key.defaults.getValue('en')}'`;
        return `"${key}": ${values}`;
      })}
    };
   `;
}

function buildTranslationsCSV(rootNode: typeof Translations.App) {
  const tree = buildTranslationTree(rootNode);
  function flattenTree(node: TranslationTreeNode): TranslationTreeLeaf[] {
    if (node.type === 'leaf') {
      return [node];
    }
    return node.children.flatMap(flattenTree);
  }
  const leafs = flattenTree(tree);
  const columns = leafs.map((leaf) => {
    const value = leaf.key.defaults.getValue('de');
    return {
      'Translation Key': leaf.key.key,
      'Translation Name/Description': leaf.key.name,
      'Translation Value': Array.isArray(value) ? value.join('\n') : value,
    };
  });
  // @ts-ignore
  return csv.asString(csv.generateCsv(csvConfig)(columns));
}
function scalarToArray(value: string | string[]) {
  return typeof value === 'string' ? [value] : value;
}
function TreeNodeDisplay({
  node,
  level = 0,
}: {
  level?: number;
  node: TranslationTreeNode;
}) {
  if (node.type === 'leaf') {
    return (
      <tr>
        <td
          style={{
            paddingLeft: `${level * 8}px`,
            /*    borderLeft: '1px dashed black',*/
            fontWeight: 'bold',
            verticalAlign: 'top',
            height: '100%',
          }}
        >
          {/*          {Array.from({ length: level }).map(() => (
            <div
              style={{
                display: 'inline-flex',
                width: '8px',
                height: '100%',
                borderLeft: '1px dashed black',
              }}
            ></div>
          ))}*/}
          <div>{node.key.key}</div>
        </td>
        <td style={{ verticalAlign: 'top' }}>{node.key.name}</td>
        <td>
          <ul>
            {scalarToArray(node.key.defaults.getValue('en')).map((value) => (
              <li>{value}</li>
            ))}
          </ul>
        </td>
      </tr>
    );
  }
  return (
    <>
      <tr style={{ fontStyle: 'italic' }}>
        <td style={{ paddingLeft: `${level * 8}px` }}>{node.scope.path}</td>
        <td>{node.scope.name}</td>
        <td></td>
      </tr>

      {node.children.map((child) => (
        <TreeNodeDisplay node={child} level={level + 1} />
      ))}
    </>
  );
}
export function TranslationTreePlayground() {
  console.log(buildTranslationTree(Translations.App));
  return (
    <>
      <table>
        <thead>
          <tr style={{ fontWeight: 'bold' }}>
            <td>Key</td>
            <td>Name/Description</td>
            <td>Default Values</td>
          </tr>
        </thead>
        <tbody>
          <TreeNodeDisplay node={buildTranslationTree(Translations.App)} />
        </tbody>
      </table>
      <pre>{buildTranslationMapCode(Translations.App)}</pre>
      <pre>{buildTranslationsCSV(Translations.App)}</pre>
    </>
  );
}
