import { TreeSequence } from '../../../graph/tree-sequence/tree-sequence';
import { TestFlowSnapshot } from '../../../graph-state/test-director';
import React from 'react';
import { ScreenTreeNode } from '../../../graph/nodes/screen-tree-node';
import { SortedTree } from '../../../graph/sorted-tree/sorted-tree';
import { SortedTreeNode } from '../../../graph/sorted-tree/sorted-tree-node';
import { Box, Stack } from '@mui/material';
import { DesktopMac, Folder, FolderOpen } from '@mui/icons-material';
import { PhaseTreeNode } from '../../../graph/nodes/phase-tree-node';

function NestedTreeSequencePhaseNodeItem({
  sortedNode,
  activate,
  currentNode,
}: {
  sortedNode: SortedTreeNode & { treeNode: PhaseTreeNode };
  activate: (node: ScreenTreeNode) => void;
  currentNode: ScreenTreeNode | undefined;
}) {
  const [open, setOpen] = React.useState(false);
  const isActive = currentNode && sortedNode.includes(currentNode);
  return (
    <Stack>
      <Box
        display="flex"
        onClick={() => setOpen(!open)}
        alignItems="center"
        style={{ gap: '0.5em', cursor: 'pointer' }}
      >
        {open || isActive ? (
          <FolderOpen fontSize="small" />
        ) : (
          <Folder fontSize="small" />
        )}
        <span style={{ fontWeight: isActive ? 'bold' : undefined }}>
          {sortedNode.name}
        </span>
      </Box>
      <Box
        sx={{ paddingLeft: '10px', paddingTop: '3px', paddingBottom: '3px' }}
      >
        <Box
          sx={{
            padding: open || isActive ? 0 : '0.2em',
            borderLeft: '1px dashed #DEDEDE',
          }}
        >
          <Box
            sx={{
              paddingLeft: open || isActive ? '1em' : '0.5em',
            }}
          >
            {sortedNode.children.length > 0 &&
              (open || isActive) &&
              sortedNode.children.map((sN) => (
                <NestedTreeSequenceNodeItem
                  currentNode={currentNode}
                  activate={activate}
                  sortedNode={sN}
                />
              ))}
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}

function NestedTreeSequenceScreenNodeItem({
  sortedNode,
  activate,
  currentNode,
}: {
  sortedNode: SortedTreeNode & { treeNode: ScreenTreeNode };
  currentNode: ScreenTreeNode | undefined;
  activate: (node: ScreenTreeNode) => void;
}) {
  const isActive = currentNode && sortedNode.includes(currentNode);
  return (
    <Stack spacing={1}>
      <Box
        display="flex"
        alignItems="center"
        style={{ gap: '0.5em', cursor: 'pointer' }}
        onClick={() => activate(sortedNode.treeNode)}
      >
        <DesktopMac
          fontSize="small"
          style={{ color: isActive ? 'green' : 'blue' }}
        />
        <span
          style={{
            fontWeight: isActive ? 'bold' : undefined,
          }}
        >
          {sortedNode.name}
        </span>
      </Box>
    </Stack>
  );
}

function NestedTreeSequenceNodeItem({
  sortedNode,
  activate,
  currentNode,
}: {
  sortedNode: SortedTreeNode;
  activate: (node: ScreenTreeNode) => void;
  currentNode: ScreenTreeNode | undefined;
}) {
  return sortedNode.treeNode.type === 'phase' ? (
    <NestedTreeSequencePhaseNodeItem
      activate={activate}
      sortedNode={sortedNode as any}
      currentNode={currentNode}
    />
  ) : (
    <NestedTreeSequenceScreenNodeItem
      activate={activate}
      sortedNode={sortedNode as any}
      currentNode={currentNode}
    />
  );
}

export function NestedTreeSequenceNavigator({
  treeSequence,
  currentNode,
  activate,
}: {
  treeSequence: TreeSequence;
  activate: (node: ScreenTreeNode) => void;
  currentNode: TestFlowSnapshot | undefined;
}) {
  const sortedTree = React.useMemo(() => {
    return SortedTree.create(treeSequence);
  }, [treeSequence]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Stack spacing={2}>
          <NestedTreeSequenceNodeItem
            activate={activate}
            currentNode={currentNode?.screenNode?.node}
            sortedNode={sortedTree.rootNode}
          />
        </Stack>
      </div>
    </div>
  );
}
