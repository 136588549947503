import { StateSlice } from '../../graph-state/state-holder';
import { ControlTransition } from '../../controls/control-transition';
import { TransitionModifier } from '../../graph-state/transition-modifiers';
import { ScreenTreeNode } from './screen-tree-node';
import { PhaseTreeNode } from './phase-tree-node';
import { TestScreenRequest } from '../test-screen-request';
import { MapTreeNode } from './map-tree-node';

export class MapScreenTreeNode extends MapTreeNode implements ScreenTreeNode {
  readonly type = 'screen';

  // readonly controlTransitions: ControlTransition<any, any>[] = [];

  constructor(
    name: string,
    public readonly parent: PhaseTreeNode,
    public screenData: TestScreenRequest,
    stateSlice?: StateSlice<any>,
  ) {
    super(name, parent, stateSlice);
  }

  controlRequest(ct: ControlTransition<any, any>): MapScreenTreeNode {
    // this.controlTransitions.push(ct);
    this.screenData.transiteTo(ct);
    return this;
  }

  screen(modifier: TransitionModifier): [PhaseTreeNode[], ScreenTreeNode] {
    return [[], this];
  }
}
