export class StateSlice<Type extends object> {
  constructor(
    public name: string,
    private defaultDataFactory: () => Type,
  ) {}

  createDefault(): SliceSnapshot<Type> {
    return this.createSnapshot(this.defaultDataFactory());
  }

  createSnapshot(data: Type): SliceSnapshot<Type> {
    return new SliceSnapshot<Type>(this, data);
  }
}

export class SliceSnapshot<Type extends object> {
  constructor(
    public slice: StateSlice<Type>,
    public data: Type,
  ) {}
}

export class StateHolder {
  protected sliceMap: Map<StateSlice<any>, SliceSnapshot<any>> = new Map();

  init<T extends object>(slice: StateSlice<T>): SliceSnapshot<T> {
    if (!this.sliceMap.has(slice)) {
      this.sliceMap.set(slice, slice.createDefault());
    }
    return this.sliceMap.get(slice) as SliceSnapshot<T>;
  }

  get<T extends object>(slice: StateSlice<T>): SliceSnapshot<T> {
    return this.sliceMap.get(slice) ?? slice.createDefault();
  }

  erase<T extends object>(slice: StateSlice<T>) {
    if (this.sliceMap.has(slice)) {
      this.sliceMap.delete(slice);
    }
  }

  upsert<T extends object>(snapshot: SliceSnapshot<T>) {
    this.sliceMap.set(snapshot.slice, snapshot);
    return this.sliceMap.get(snapshot.slice);
  }
}
