import {
  CreateTestResourceLoader,
  GQLTestQueryLoader,
} from '../../../../subject/testRunner/media/test-resource-loader';
import { MediaItemSnapshot } from '../../../../../API';
import { AmpQueries } from './amp.queries';

import {
  AmpMediaItemAttributeCategorySelector,
  AmpMediaItemCategorySelector,
} from './amp-media-item-selectors';

export const AmpResourceLoader = CreateTestResourceLoader(
  GQLTestQueryLoader(AmpQueries.Get),
  (test, loadingManager) => {
    const collect = [
      ...(AmpMediaItemCategorySelector.collect(test) as MediaItemSnapshot[]),
      ...(AmpMediaItemAttributeCategorySelector.collect(
        test,
      ) as MediaItemSnapshot[]),
      ...((test.fixation?.mediaItem
        ? [test.fixation.mediaItem]
        : []) as MediaItemSnapshot[]),
      ...((test.mask ? [test.mask] : []) as MediaItemSnapshot[]),
    ];
    loadingManager.attachItems(...collect);
    return loadingManager.loadCache();
  },
);
