import { TestPermissionType } from './types';

export const testPermissionDescriptionMap: Record<TestPermissionType, string> =
  {
    owner:
      'The owner has all permissions for the test. He can create, read, update, delete the test and its results.',
    'view-results':
      "All users with 'view-results' permission can download the test-results. This does not include the permission the view the test",
    'view-test':
      "All users with 'view-test' permission can read the test. This does not include the permission to read the results.",
    'edit-test':
      "All users with 'edit-test' permission can read and update the test. This does not include the permission to read the results.",
  };
