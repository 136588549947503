import hash from 'object-hash';
import React from 'react';
import { MediaItemSnapshotInput } from '../../API';
import MediaOffscreenCanvas, { TextStyle } from './MediaOffscreenCanvas';

export interface CacheEntry {
  mediaItem: MediaItemSnapshotInput;
  config: UnknownMediaItemConfig;
  offscreenCanvas: MediaOffscreenCanvas;
}

export interface MediaItemCache {
  prerender: (
    mediaItem: MediaItemSnapshotInput,
    config: UnknownMediaItemConfig,
  ) => Promise<CacheEntry>;
}

export interface UnknownMediaItemConfig {
  image: {
    thumb: boolean;
  };
  text: TextStyle;
}

const cacheHolder = <A, C>(
  keyExtractor: (key: A) => any,
  factory: (key: A) => C,
) => {
  const cache: { [id: string]: C } = {};
  return {
    getOrCreate(key: A) {
      const keyHash = hash(keyExtractor(key));
      if (!cache[keyHash]) {
        cache[keyHash] = factory(key);
      }
      return cache[keyHash];
    },
  };
};

const mediaCacheHolder = (
  factory: (
    key: [MediaItemSnapshotInput, UnknownMediaItemConfig],
  ) => Promise<CacheEntry>,
) =>
  cacheHolder<
    [MediaItemSnapshotInput, UnknownMediaItemConfig],
    Promise<CacheEntry>
  >((key) => [key[0].id, key[1]], factory);

export const createCache = (): MediaItemCache => {
  const cache = mediaCacheHolder((key) =>
    key[0].text
      ? Promise.resolve<CacheEntry>({
          mediaItem: key[0],
          config: key[1],
          offscreenCanvas: MediaOffscreenCanvas.ofMediaItemText(
            key[0],
            key[1].text,
          ),
        })
      : MediaOffscreenCanvas.ofMediaItemImage(
          key[0],
          key[1].image.thumb,
        ).then<CacheEntry>((canvas) => ({
          mediaItem: key[0],
          config: key[1],
          offscreenCanvas: canvas,
        })),
  );
  return {
    prerender: (
      mediaItem: MediaItemSnapshotInput,
      config: UnknownMediaItemConfig,
    ): Promise<CacheEntry> => {
      return cache.getOrCreate([mediaItem, config]);
    },
  };
};

export const createDerivedCache = (
  cache: MediaItemCache,
  converter: (cacheEntry: CacheEntry) => MediaOffscreenCanvas,
): MediaItemCache => {
  const derivedCache = mediaCacheHolder((key) =>
    cache.prerender(key[0], key[1]).then((entry) => ({
      mediaItem: entry.mediaItem,
      config: entry.config,
      offscreenCanvas: converter(entry),
    })),
  );
  return {
    prerender: (
      mediaItem: MediaItemSnapshotInput,
      config: UnknownMediaItemConfig,
    ): Promise<CacheEntry> => {
      return derivedCache.getOrCreate([mediaItem, config]);
    },
  };
};

export const MediaCacheContext = React.createContext<MediaItemCache>(
  createCache(),
);
