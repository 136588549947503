import React, { FC } from 'react';
import { RouteProps } from 'react-router-dom';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from '@mui/material';
import {
  useDeleteTagCascadeMutation,
  useGetTagsQuery,
} from '../redux/mediaApi';
import {
  DataGridPro,
  GridActionsCellItem,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { Delete } from '@mui/icons-material';
import { Tag } from '../types';
import { RequireAuth } from '../../../auth/RequiredAuth';
import {
  RemoveMediaItemTagMutationVariables,
  RemoveMediaItemTagResponse,
} from '../../../API';
import { GQLQuery, NewGQL } from '../../../GQL';
import { removeMediaItemTag } from '../../../graphql/mutations';

function RemoveTagConfirmDialog({
  tagToDelete,
  closeDialog,
  deleteTag,
  deleteIsLoading,
}: {
  tagToDelete: Tag | null;
  closeDialog: () => void;
  deleteIsLoading: boolean;
  deleteTag: (id: string) => Promise<RemoveMediaItemTagResponse>;
}) {
  const [associatedItems, setAssociatedItems] = React.useState<string[]>();
  React.useEffect(() => {
    let cancelled = false;
    if (tagToDelete) {
      const fetchResult = async () => {
        const result = await NewGQL.DEFAULT_CLIENT.execute(
          GQLQuery.Mutate<
            RemoveMediaItemTagMutationVariables,
            RemoveMediaItemTagResponse
          >(removeMediaItemTag).create({
            dryRun: true,
            tagId: tagToDelete.id,
          }),
        );
        if (!cancelled) {
          setAssociatedItems(result.associatedMediaItems);
        }
      };
      fetchResult();
    }
    return () => {
      cancelled = true;
    };
  }, [tagToDelete]);

  return (
    <Dialog onClose={closeDialog} open={Boolean(tagToDelete)}>
      <DialogTitle>
        Delete Tag <Chip label={tagToDelete?.name} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to delete the tag{' '}
          <Chip label={tagToDelete?.name} size="small" />
        </DialogContentText>
        <DialogContentText>
          If you delete this Tag it will be still contained in the existing
          Tests. No Data will be lost.
        </DialogContentText>
        {associatedItems?.length ? (
          <DialogContentText color="error">
            Attention: There are {associatedItems!.length} elements of the media
            pool connected to this tag. The tag will be removed from the
            tag-list of all media pool elements.
          </DialogContentText>
        ) : (
          <></>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={deleteIsLoading} onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          disabled={
            !tagToDelete || associatedItems === undefined || deleteIsLoading
          }
          startIcon={
            !tagToDelete || deleteIsLoading || associatedItems === undefined ? (
              <CircularProgress size="1em" />
            ) : undefined
          }
          onClick={() => {
            if (tagToDelete) {
              deleteTag(tagToDelete.id).then(() => {
                closeDialog();
              });
            }
          }}
          color="error"
        >
          Delete Tag
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const TagsSettings: FC<RouteProps> = () => {
  const { data = [], isFetching } = useGetTagsQuery();
  const [deleteTag, { isLoading: deleteIsLoading }] =
    useDeleteTagCascadeMutation();
  const [tagToDelete, setTagToDelete] = React.useState<Tag | null>(null);
  const closeDialog = () => setTagToDelete(null);

  return (
    <RequireAuth>
      <RemoveTagConfirmDialog
        key={tagToDelete?.id ?? ''}
        tagToDelete={tagToDelete}
        closeDialog={closeDialog}
        deleteIsLoading={deleteIsLoading}
        deleteTag={(id: string) => {
          return deleteTag({ dryRun: false, tagId: id }).unwrap();
        }}
      />
      <Paper>
        <DataGridPro
          style={{ height: '94vh' }}
          className={'noSelect'}
          disableSelectionOnClick={true}
          loading={isFetching || deleteIsLoading}
          rowHeight={100}
          columns={[
            {
              field: 'actions',
              type: 'actions',
              getActions: (params: GridRowParams) => {
                return [
                  <GridActionsCellItem
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                    placeholder=""
                    icon={<Delete />}
                    label="Delete"
                    onClick={() => setTagToDelete(params.row as Tag)}
                    showInMenu
                  />,
                ];
              },
            },
            {
              field: 'name',
              headerName: 'Tag Name',
              type: 'string',
              width: 300,
            },
            {
              field: 'in_use',
              headerName: 'In use',
              valueGetter: (params) =>
                (params.row.mediaItems?.items?.length ?? 0) > 0,
              type: 'boolean',
              width: 300,
            },
          ]}
          rows={data ?? []}
        />
      </Paper>
    </RequireAuth>
  );
};
