import { useField } from "formik";
import { AttributeCombinationInput, WSWAttributeInput, WSWAttributeType, WSWGroupInput } from "../../../../../API";
import { Box, Button, Grid, Popover, Typography } from "@mui/material";
import React, { FC } from "react";
import { StrategyFactories } from "../../../pickingSet/picking-strategy";
import { Check } from "@mui/icons-material";

export interface WswAttributeIndicationHoverState {
  mainAttribute?: number;
  additionalAttribute?: number;
  criteriaAttribute?: number | null;
}

export type WswAttributeIndicationListener = (state: WswAttributeIndicationHoverState | undefined) => void;

export function WswStimuliAttributeCategorySummary({
                                                     attributeKey,
                                                     categoryIndex,
                                                     categoryKey,
                                                     setAttributeIndication
                                                   }: { attributeKey: string, categoryIndex: number, categoryKey: keyof AttributeCombinationInput; setAttributeIndication: WswAttributeIndicationListener }) {

  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleShowPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setPopoverAnchorEl(null);
  };

  const popoverOpen = Boolean(popoverAnchorEl);
  const popOverId = popoverOpen ? "simple-popover" : undefined;

  const [, { value: attribute }] = useField<WSWAttributeInput>(attributeKey);
  const [, { value: combinations }] = useField<AttributeCombinationInput[]>("attributeCombinations");
  const category = attribute.categories![categoryIndex];
  const stimuliSum = React.useMemo(() => category.mediaSnaps.length, [category]);
  const combinationsSum = React.useMemo(() => combinations.filter((comb) => comb[categoryKey] === categoryIndex).map(comb => comb.amount).reduce((acc, c) => acc + c, 0), [categoryIndex, categoryKey, combinations]);
  const validation = React.useMemo(() => {
    const constraint = StrategyFactories[attribute.pickingStrategy][1];
    if (constraint.checkValid(combinationsSum, stimuliSum)) {
      return {
        type: "valid"
      } as const;
    } else {
      return {
        type: "error",
        error: constraint.describeError(combinationsSum, stimuliSum)
      } as const
    }
  }, [attribute.pickingStrategy, combinationsSum, stimuliSum]);
  return (
    <Box
      sx={{":hover": {border: 1, borderColor: (theme) => theme.palette.primary.main, borderRadius: 1, pr: 1}}}
      display="flex"
      onMouseOver={() => {
        setAttributeIndication({ [attributeKey as any]: categoryIndex });
      }}
      onMouseLeave={() => {
        setAttributeIndication(undefined);
      }}
    >
      <Box flexGrow={1} style={{ paddingLeft: "5px" }}>{category.name}</Box>
      <Box>
        <Box display="flex" alignItems="center">
          {validation.type === "error" &&
            <Button size="small" color="error" aria-describedby={popOverId} onClick={handleShowPopover}>Error</Button>}
          {validation.type === "valid" && <Check sx={{ mr: 0.5 }} color="success" />}
          <Box>{combinationsSum}/{stimuliSum}</Box>
        </Box>
        <Popover
          id={popOverId}
          open={popoverOpen}
          anchorEl={popoverAnchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
        >
          <Box sx={{pr: 4, pt: 1}}>
            <Typography sx={{ml: 2}} color="textSecondary">Error Description</Typography>
            <ul style={{marginTop: -0.5}}>
              <li><Typography>
                Pool <u>{category.name}</u>&nbsp;of <u>{attributeKey}</u> contains
                <Typography sx={{ display: "inline-flex" }} color="error">&nbsp;{stimuliSum}</Typography>
                &nbsp;items
              </Typography></li>
              <li><Typography>Combinations sum up to {combinationsSum}</Typography></li>
              {validation.type === "error" && <li><Typography>{validation.error}</Typography></li>}
              <li><Typography>Or change the pools size</Typography></li>
            </ul>
          </Box>
        </Popover>
      </Box>
      </Box>
  );
}

function WswCriteriaDistraktorRow({ setAttributeIndication }: { setAttributeIndication: WswAttributeIndicationListener }) {
  // const [, { value: attribute }] = useField<WSWAttributeInput>("criteriaAttribute");
  const [, { value: combinations }] = useField<AttributeCombinationInput[]>("attributeCombinations");
  const combinationSum = React.useMemo(() => combinations.filter(c => c.criteriaCategory === null).reduce((acc, c) => acc + c.amount, 0), [combinations]);
  return <Box sx={{":hover": {border: 1, borderColor: (theme) => theme.palette.primary.main, borderRadius: 1, pr: 1}}} display="flex" onMouseOver={() => {
    setAttributeIndication({ criteriaAttribute: null });
  }} onMouseLeave={() => {
    setAttributeIndication(undefined);
  }} >
    <Box flexGrow={1} style={{ paddingLeft: "5px" }}><span style={{ fontStyle: "italic" }}>Distraktor</span></Box>
    <Box>{combinationSum}/n</Box>
  </Box>;
}

export const WswStimuliAttributeSummary: FC<{ attributeKey: string, attributeName: string, categoryKey: keyof AttributeCombinationInput; setAttributeIndication: WswAttributeIndicationListener }> = ({
                                                                                                                                                                                                        categoryKey,
                                                                                                                                                                                                        attributeKey,
                                                                                                                                                                                                        attributeName,
                                                                                                                                                                                                        setAttributeIndication
                                                                                                                                                                                                      }) => {
  const [, { value: attribute }] = useField<WSWAttributeInput>(attributeKey);
  const [, { value: combinations }] = useField<AttributeCombinationInput[]>("attributeCombinations");
  const categories = attribute.categories!;
  const stimuliSum = React.useMemo(() => categories.map(v => v.mediaSnaps.length).reduce((acc, c) => acc + c, 0), [categories]);
  const combinationsSum = React.useMemo(() => combinations.map((comb) => comb.amount).reduce((acc, c) => acc + c, 0), [combinations]);
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex">
        <Box flexGrow={1}>{attributeName} ({attribute.pickingStrategy})</Box>
        <Box>{combinationsSum}/{stimuliSum}</Box>
      </Box>
      {categories.map((_, cI) =>
        <WswStimuliAttributeCategorySummary categoryKey={categoryKey} attributeKey={attributeKey} categoryIndex={cI}
                                            setAttributeIndication={setAttributeIndication} />
      )}
      {attributeKey === "criteriaAttribute" &&
        <WswCriteriaDistraktorRow setAttributeIndication={setAttributeIndication} />}
    </Box>
  );
};

export function WswGroupAttributeCategorySummary({
                                                   attributeKey,
                                                   categoryIndex,
                                                   categoryKey,
                                                   setAttributeIndication
                                                 }: { attributeKey: string, categoryIndex: number, categoryKey: keyof AttributeCombinationInput; setAttributeIndication: WswAttributeIndicationListener }) {
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleShowPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setPopoverAnchorEl(null);
  };

  const popoverOpen = Boolean(popoverAnchorEl);
  const popOverId = popoverOpen ? "simple-popover" : undefined;

  const [, { value: attribute }] = useField<WSWAttributeInput>(attributeKey);
  const [, { value: combinations }] = useField<AttributeCombinationInput[]>("attributeCombinations");
  const [, { value: groups }] = useField<WSWGroupInput[] | undefined | null>("groupConstruction.groups");

  const category = groups![categoryIndex];
  const stimuliSum = 1;
  const combinationsSum = React.useMemo(() => combinations.filter((comb) => comb[categoryKey] === categoryIndex).map(comb => comb.amount).reduce((acc, c) => acc + c, 0), [categoryIndex, categoryKey, combinations]);
  const validation = React.useMemo(() => {
    const constraint = StrategyFactories[attribute.pickingStrategy][1];
    if (constraint.checkValid(combinationsSum, stimuliSum)) {
      return {
        type: "valid"
      } as const;
    } else {
      return {
        type: "error",
        error: constraint.describeError(combinationsSum, stimuliSum)
      } as const
    }
  }, [attribute.pickingStrategy, combinationsSum, stimuliSum]);

  return (
    <Box
      display="flex"
      sx={{":hover": {border: 1, borderColor: (theme) => theme.palette.primary.main, borderRadius: 1, pr: 1}}}
      onMouseOver={() => {
        setAttributeIndication({ [attributeKey as any]: categoryIndex });
      }}
      onMouseLeave={() => {
        setAttributeIndication(undefined);
      }}
    >
      <Box flexGrow={1} style={{ paddingLeft: "5px" }}>{category.name}</Box>
      <Box>
        <Box display="flex" alignItems="center">
          {validation.type === "error" &&
            <Button size="small" color="error" aria-describedby={popOverId} onClick={handleShowPopover}>Error</Button>}
          {validation.type === "valid" && <Check sx={{ mr: 0.5 }} color="success" />}
          <Box>{combinationsSum}/{stimuliSum}</Box>
        </Box>
        <Popover
          id={popOverId}
          open={popoverOpen}
          anchorEl={popoverAnchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
        >
          <Box sx={{ pr: 4, pt: 1 }}>
            <Typography sx={{ ml: 2 }} color="textSecondary">Error Description</Typography>
            <ul style={{ marginTop: -0.5 }}>
              <li><Typography>
                Pool <u>{category.name}</u>&nbsp;of <u>{attributeKey}</u> contains
                <Typography sx={{ display: "inline-flex" }} color="error">&nbsp;{stimuliSum}</Typography>
                &nbsp;items
              </Typography></li>
              <li><Typography>Combinations sum up to {combinationsSum}</Typography></li>
              {validation.type === "error" && <li><Typography>{validation.error}</Typography></li>}
              <li><Typography>Or change the pools size</Typography></li>
            </ul>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
}

export const WswGroupAttributeSummary: FC<{ attributeKey: string, attributeName: string, categoryKey: keyof AttributeCombinationInput; setAttributeIndication: WswAttributeIndicationListener }> = ({
                                                                                                                                                                                                      categoryKey,
                                                                                                                                                                                                      attributeKey,
                                                                                                                                                                                                      attributeName,
                                                                                                                                                                                                      setAttributeIndication
                                                                                                                                                                                                    }) => {
  const [, { value: attribute }] = useField<WSWAttributeInput>(attributeKey);
  const [, { value: combinations }] = useField<AttributeCombinationInput[]>("attributeCombinations");
  const [, { value: groups }] = useField<WSWGroupInput[] | undefined | null>("groupConstruction.groups");

  const stimuliSum = groups!.length;
  const combinationsSum = React.useMemo(() => combinations.map(comb => comb.amount).reduce((acc, c) => acc + c, 0), [combinations]);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex">
        <Box flexGrow={1}>{attributeName} ({attribute.pickingStrategy})</Box>
        <Box>{combinationsSum}/{stimuliSum}</Box>
      </Box>
      {groups!.map((_, cI) => <WswGroupAttributeCategorySummary categoryKey={categoryKey} attributeKey={attributeKey}
                                                                categoryIndex={cI}
                                                                setAttributeIndication={setAttributeIndication} />)}
      {attributeKey === "criteriaAttribute" && <WswCriteriaDistraktorRow setAttributeIndication={setAttributeIndication} />}
    </Box>
  );
};


function WswAttributeSummary({
                               attributeKey,
                               attributeName,
                               categoryKey,
                               setAttributeIndication
                             }: { attributeKey: string, attributeName: string, categoryKey: keyof AttributeCombinationInput; setAttributeIndication: WswAttributeIndicationListener }) {
  const [, { value: attribute }] = useField<WSWAttributeInput>(attributeKey);
  return attribute.type === WSWAttributeType.STIMULI
    ? <WswStimuliAttributeSummary
      categoryKey={categoryKey}
      attributeKey={attributeKey}
      attributeName={attributeName}
      setAttributeIndication={setAttributeIndication}
    />
    : <WswGroupAttributeSummary
      attributeKey={attributeKey}
      attributeName={attributeName}
      categoryKey={categoryKey}
      setAttributeIndication={setAttributeIndication}
    />;
}

export function WswAttributeCombinationSummaryComponent({ setAttributeIndication }: { setAttributeIndication: WswAttributeIndicationListener }) {
  const [, { value: additionalAttribute }] = useField<WSWAttributeInput | undefined | null>("additionalAttribute");

  return (
    <Grid item container xs={12}>
      <WswAttributeSummary
        attributeKey="mainAttribute"
        categoryKey="mainCategory"
        attributeName="Main Attribute"
        setAttributeIndication={setAttributeIndication}
      />
      {additionalAttribute &&
        <WswAttributeSummary
          attributeKey="additionalAttribute"
          categoryKey="additionalCategory"
          attributeName="Additional Attribute"
          setAttributeIndication={setAttributeIndication}
        />}
      <WswAttributeSummary
        attributeKey="criteriaAttribute"
        categoryKey="criteriaCategory"
        attributeName="Criteria Attribute"
        setAttributeIndication={setAttributeIndication}
      />
    </Grid>
  );
}
