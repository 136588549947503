import { WswTestQuestionInstance } from './wsw-test-question-instance';
import { WswQuestionType } from '../../../../../../API';
import { MediaInstance } from '../../../../../subject/testRunner/media/MediaData';
import { QuestionLocator } from './question-locator';
import React from 'react';
import { Stack } from '@mui/material';
import { RunnerButton } from '../../../../../subject/testRunner/RunnerButton';
import { QuestionAnswerListener } from './question-answer-listener';
import { WswStereotypes } from '../../wsw-controls';

export class WswTestConditionalQuestionInstance extends WswTestQuestionInstance {
  readonly type: WswQuestionType.Confirm = WswQuestionType.Confirm;

  constructor(
    questionText: string,
    stimulus: MediaInstance | null,
    public readonly sides: Record<'left' | 'right', string>,
    public readonly confirm: 'left' | 'right',
    questionLocator: QuestionLocator,
    timeout: number,
  ) {
    super(
      WswQuestionType.RatingScala,
      questionText,
      stimulus,
      questionLocator,
      timeout,
    );
  }

  createComponent(): React.FC {
    return () => {
      return (
        <Stack direction="row" spacing={2}>
          <RunnerButton
            buttonProps={{
              label: this.sides.left,
              onClick: QuestionAnswerListener((e) => {
                return (
                  this.confirm === 'left'
                    ? WswStereotypes.Answer.Proceed
                    : WswStereotypes.Answer.Skip
                ).controlOccurred(
                  e.map((c) => ({
                    event: (c as any).nativeEvent as MouseEvent,
                    answer: this.toAnswer(this.sides.left, false, 0),
                  })),
                );
              }),
            }}
          />
          <RunnerButton
            buttonProps={{
              label: this.sides.right,
              onClick: QuestionAnswerListener((e) => {
                return (
                  this.confirm === 'right'
                    ? WswStereotypes.Answer.Proceed
                    : WswStereotypes.Answer.Skip
                ).controlOccurred(
                  e.map((c) => ({
                    event: (c as any).nativeEvent as MouseEvent,
                    answer: this.toAnswer(this.sides.right, false, 1),
                  })),
                );
              }),
            }}
          />
        </Stack>
      );
    };
  }
}
