/**
 * in this example we will create a simple runner that will run a single test
 * the test will show an image in each trial, that has to be rated by "good", "neutral" or "bad".
 * the test starts with an instructions screen and ends with a thank you screen.
 * before each trial comes a preparation screen.
 **/
import { createRunnerConfiguration } from '../../subject/testRunner/runner-configuration';
import { TestType } from '../../subject/types';
import {
  ControlHandlerManager,
  KeyControlHandler,
} from '../../subject/testRunner/controls/control-handlers';
import { ControlStereotype } from '../../subject/testRunner/controls/control-stereotypes';
import {
  DefaultPhaseRunner,
  SequencePhaseBuilder,
  SequenceTaskBuilder,
} from '../../subject/testRunner/media/sequence-tasks';
import { TestBaseContext } from '../../subject/testRunner/state/baseTestContext/context/ContextTypes';
import {
  CreateTestResourceLoader,
  TestResourceAccessor,
  TestResourceLoaderInstance,
} from '../../subject/testRunner/media/test-resource-loader';
import { BaseTest } from '../types';
import {
  FontFace,
  MediaItemSnapshot,
  MediaItemSnapshotScope,
  TestStatus,
  TextAlignment,
} from '../../../API';
import { TextStyleDescriptor } from '../../subject/testRunner/media/text-drawer';
import {
  DrawableMediaInstance,
  transformMediaItemData,
} from '../../subject/testRunner/media/MediaData';
import { MindSetRandomUtils } from '../pickingSet/picking-utils';
import { ControlTransition } from '../../subject/testRunner/controls/control-transition';
import {
  InstructionsContent,
  TestInstructionsView,
} from '../../subject/testRunner/screens/basic-views/test-instructions-view';
import { DefaultTestTree } from '../../subject/testRunner/graph/test-tree';
import { DefaultTransitionAction } from '../../subject/testRunner/graph-state/graph-action';
import { defineTransitionModifiers } from '../../subject/testRunner/graph-state/transition-modifiers';
import { SelectorTree } from '../../subject/testRunner/screens/selectors/selector-tree';
import {
  DisplayData,
  Optional,
  OptionalValue,
} from '../../subject/testRunner/screens/selectors/data-wrapper';
import { ViewBoxes } from '../../subject/testRunner/screens/layouting-view';
import { StimulusCanvasStrategy } from '../../subject/testRunner/screens/composer/scaler/canvas-strategy';
import { sequenceTree } from '../../subject/testRunner/graph/tree-sequence/tree-sequence';
import { MapScreenTreeNode } from '../../subject/testRunner/graph/nodes/map-screen-tree-node';
import { MapPhaseTreeNode } from '../../subject/testRunner/graph/nodes/map-phase-tree-node';
import { TestScreenRequest } from '../../subject/testRunner/graph/test-screen-request';
import { TestScreenComposer } from '../../subject/testRunner/screens/composer/test-screen-composer';
import { ViewDefinitionCollection } from '../../subject/testRunner/screens/composer/view-definition-collection';
import {
  HTMLContentComponent,
  HTMLContentView,
} from '../../subject/testRunner/screens/composer/views/html-content-view';
import { CanvasContentView } from '../../subject/testRunner/screens/composer/views/canvas-content-view';
import { LayoutingView } from '../../subject/testRunner/screens/composer/views/layouting-view';
import { defineContentSelector } from '../../subject/testRunner/screens/selectors/content-selector';
import { defineGroupSelector } from '../../subject/testRunner/screens/selectors/group-selector';

// step 1: define the controls
const positiveControlStereotype = new ControlStereotype<KeyboardEvent, void>(
  'positive',
);
const neutralControlStereotype = new ControlStereotype<KeyboardEvent, void>(
  'neutral',
);
const negativeControlStereotype = new ControlStereotype<KeyboardEvent, void>(
  'negative',
);
const confirmControlStereotype = new ControlStereotype<KeyboardEvent, void>(
  'confirm',
);

const exampleRootSelector = defineGroupSelector({
  selectGroupData(
    input: ExampleScreenData,
  ): OptionalValue<ExampleInstructionsData | ExampleStimulusData> {
    return input instanceof ExampleInstructionsData ||
      input instanceof ExampleStimulusData
      ? Optional.value(input)
      : Optional.none();
  },
  children: {
    instructions: defineContentSelector({
      selectRenderData: (
        input: ExampleInstructionsData | ExampleStimulusData,
      ) => {
        return input instanceof ExampleInstructionsData
          ? DisplayData.displayData<InstructionsContent>(input.content)
          : DisplayData.hide<InstructionsContent>();
      },
    }),
    groupStimulus: defineGroupSelector({
      selectGroupData(
        input: ExampleScreenData,
      ): OptionalValue<ExampleInstructionsData | ExampleStimulusData> {
        return input instanceof ExampleStimulusData
          ? Optional.value(input)
          : Optional.none();
      },
      children: {
        stimulus: defineContentSelector({
          selectRenderData: (input: ExampleStimulusData) => {
            return DisplayData.displayData<DrawableMediaInstance | undefined>(
              input.stimulus,
            );
          },
        }),
        options: defineContentSelector({
          selectRenderData: () => {
            return DisplayData.displayData<undefined>(undefined);
          },
        }),
      },
    }),
  },
});

const OptionsComponent: HTMLContentComponent<void> = ({ containerRef }) => {
  return (
    <div ref={containerRef} style={{ display: 'flex', gap: '16px' }}>
      <div>pos</div>
      <div>neutral</div>
      <div>neg</div>
    </div>
  );
};

function createExampleViewMap(
  structure: ReturnType<typeof createExampleStructure>,
) {
  return TestScreenComposer.Create(
    LayoutingView.growing({
      selector: ExampleSelectorTree.rootSelector,
      layout: {
        subComponents: ViewDefinitionCollection.Create({
          instructions: TestInstructionsView({
            selector: ExampleSelectorTree.rootSelector.instructions,
          }),
          groupStimulusContainer: LayoutingView.growing({
            selector: ExampleSelectorTree.rootSelector.groupStimulus,
            layout: {
              subComponents: ViewDefinitionCollection.Create({
                content: CanvasContentView.growing(
                  ExampleSelectorTree.rootSelector.groupStimulus.stimulus,
                  new StimulusCanvasStrategy(),
                ),
                options: HTMLContentView.fixed(
                  ExampleSelectorTree.rootSelector.groupStimulus.options,
                  OptionsComponent,
                ),
              }),
              boxes: ViewBoxes.Define((builder) => ({
                content: builder.growing('content'),
                options: builder.fixed('options'),
              })),
            },
            contentComponent: (containerProvider, boxProvider) => {
              return () => {
                return containerProvider.createContainer(
                  <div
                    style={{ padding: '24px', width: '100%', height: '100%' }}
                  >
                    <div>{boxProvider.createBox('content')}</div>
                    <div>{boxProvider.createBox('options')}</div>
                  </div>,
                  (phase, style) => ({
                    ...style,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    // padding: '24px',
                    // border: 'solid red',
                  }),
                );
              };
            },
          }),
        }),
        boxes: ViewBoxes.Define((builder) => ({
          content: builder.growing('instructions', 'groupStimulusContainer'),
        })),
      },
      contentComponent: (containerProvider, boxProvider) => {
        return () => {
          return containerProvider.createContainer(
            <div style={{ width: '100%', height: '100%', padding: '24px' }}>
              {boxProvider.createBox('content')}
            </div>,
            (phase, style) => ({
              ...style,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white',
              padding: '24px',
              // border: 'solid red',
            }),
          );
        };
      },
    }),
  );
}

const ExampleSelectorTree = new SelectorTree('example', exampleRootSelector);

export const ExampleModifiers = defineTransitionModifiers([]);

class ExampleScreenData implements TestScreenRequest {
  constructor(
    readonly controlTransitions: ControlTransition<any, any>[] = [],
  ) {}
  transiteTo(...controlTransitions: ControlTransition<any, any>[]): this {
    this.controlTransitions.push(...controlTransitions);
    return this;
  }
}

class ExampleInstructionsData extends ExampleScreenData {
  content: InstructionsContent;

  constructor(
    content: InstructionsContent,
    controlTransitions?: ControlTransition<any, any>[],
  ) {
    super(controlTransitions);
    this.content = content;
  }
}

class ExampleStimulusData extends ExampleScreenData {
  constructor(
    public readonly stimulus: DrawableMediaInstance,
    controlTransitions?: ControlTransition<any, any>[],
  ) {
    super(controlTransitions);
  }
}

function createExampleTree(
  structure: ReturnType<typeof createExampleStructure>,
) {
  const root = new MapPhaseTreeNode('example', null);

  const welcome = new MapScreenTreeNode(
    'welcome',
    root,
    new ExampleInstructionsData({
      content: structure.welcomePhase.welcomeScreenContent.content,
      nextButton: structure.welcomePhase.welcomeScreenContent.nextButton,
    }),
  )
    .connectParent(ExampleModifiers.default)
    .controlRequest(
      confirmControlStereotype.defineTransition(undefined, () =>
        DefaultTransitionAction.next(ExampleModifiers.default),
      ),
    );

  const trialsNode = new MapPhaseTreeNode('trials', root)
    .connectParent()
    .transitionFrom(ExampleModifiers.default, welcome);

  structure.trials
    .map((trial, trialIndex) => {
      return new MapScreenTreeNode(
        `trial-${trialIndex}`,
        trialsNode,
        new ExampleStimulusData(trial),
      )
        .connectParent(trialIndex === 0 ? ExampleModifiers.default : undefined)
        .controlRequest(
          positiveControlStereotype.defineTransition(undefined, () =>
            DefaultTransitionAction.next(ExampleModifiers.default),
          ),
        )
        .controlRequest(
          negativeControlStereotype.defineTransition(undefined, () =>
            DefaultTransitionAction.next(ExampleModifiers.default),
          ),
        )
        .controlRequest(
          neutralControlStereotype.defineTransition(undefined, () =>
            DefaultTransitionAction.next(ExampleModifiers.default),
          ),
        );
    })
    .reduce((acc, c) => c.transitionFrom(ExampleModifiers.default, acc));

  new MapScreenTreeNode(
    'end',
    root,
    new ExampleInstructionsData({
      content: structure.endPhase.welcomeScreenContent.content,
      finish: true,
      nextButton: 'end',
    }),
  )
    .connectParent()
    .controlRequest(
      confirmControlStereotype.defineTransition(undefined, () =>
        DefaultTransitionAction.next(ExampleModifiers.default),
      ),
    )
    .transitionFrom(ExampleModifiers.default, trialsNode);

  return new DefaultTestTree(ExampleModifiers, root);
}

type ExampleTestType = BaseTest & {
  instructions: string;
  end: string;
  mediaItems: MediaItemSnapshot[];
};

function createExampleStructure(
  accessor: TestResourceAccessor<ExampleTestType>,
  pool: ReturnType<typeof loadExampleMedia>,
) {
  const examplePicker = MindSetRandomUtils.shuffleArray(pool);

  return {
    accessor,
    pool,
    welcomePhase: {
      welcomeScreenContent: {
        content: accessor.modelData.instructions,
        nextButton: 'Start',
      },
    },
    trials: examplePicker,
    endPhase: {
      welcomeScreenContent: {
        content: accessor.modelData.end,
      },
    },
  };
}

function loadExampleMedia(accessor: TestResourceAccessor<ExampleTestType>) {
  const factory = accessor.createDrawableFactory(
    'fullSize',
    TextStyleDescriptor.ofFontStyle({
      alignment: TextAlignment.CENTER,
      color: 'black',
      fontFace: FontFace.ARIAL,
      fontSize: 16,
    }),
  );
  return accessor.modelData.mediaItems.map((m) =>
    factory.createInstance(transformMediaItemData(m)),
  );
}

const exampleResourceLoader = CreateTestResourceLoader(
  () => Promise.resolve(MockExampleTest),
  (test, loadingManager) => {
    const collect: MediaItemSnapshot[] = test.mediaItems;
    loadingManager.attachItems(...collect);
    return loadingManager.loadCache();
  },
);

export const ExampleRunnerConfig = createRunnerConfiguration(TestType.Example, {
  selectorTree: ExampleSelectorTree,
  // step 1: define the controls
  controlFactory: () => {
    return (inputMonitor) => {
      const positiveKeyHandler = new KeyControlHandler(
        inputMonitor,
        positiveControlStereotype,
        'KeyA',
      );

      const neutralKeyHandler = new KeyControlHandler(
        inputMonitor,
        neutralControlStereotype,
        'KeyB',
      );

      const negativeKeyHandler = new KeyControlHandler(
        inputMonitor,
        negativeControlStereotype,
        'KeyL',
      );

      const confirmKeyHandler = new KeyControlHandler(
        inputMonitor,
        confirmControlStereotype,
        'Space',
      );

      return new ControlHandlerManager([
        positiveKeyHandler,
        neutralKeyHandler,
        negativeKeyHandler,
        confirmKeyHandler,
      ]);
    };
  },
  createViews: createExampleViewMap,
  load: (testContext, gql, mediaManager) => {
    return SequencePhaseBuilder.Builder<{
      testContextFactory: () => Promise<TestBaseContext>;
      loader: TestResourceLoaderInstance<ExampleTestType>;
    }>()
      .start(
        'load',
        SequenceTaskBuilder.Builder<{
          testContextFactory: () => Promise<TestBaseContext>;
          loader: TestResourceLoaderInstance<ExampleTestType>;
        }>()
          .start('retrieve', async ({ testContextFactory, loader }) => {
            const testContext = await testContextFactory();
            return {
              testContext,
              accessor: await loader.load(testContext),
            };
          })
          .chainTask('load', ({ accessor }) => ({
            accessor,
            pool: loadExampleMedia(accessor),
          })),
      )
      .chain(
        'arrange',
        SequenceTaskBuilder.Builder<{
          pool: ReturnType<typeof loadExampleMedia>;
          accessor: TestResourceAccessor<ExampleTestType>;
        }>()
          .start('arrange', ({ accessor, pool }) =>
            createExampleStructure(accessor, pool),
          )
          .chainTask('tree', (structure) => ({
            structure,
            tree: createExampleTree(structure),
          }))
          .chainTask('tree-sequence', (data) => ({
            ...data,
            sequence: sequenceTree(data.tree),
          })),
      )
      .createPhases()
      .performSequence(
        {
          load: {
            testContextFactory: async () => testContext,
            loader: exampleResourceLoader.init(gql, mediaManager),
          },
          arrange: ({ accessor, pool }) => ({
            pool,
            accessor,
          }),
        },
        new DefaultPhaseRunner(),
      );
  },
});

export const MockExampleTest: ExampleTestType = {
  id: '1',
  description: 'description',
  name: 'example',
  instructions: 'instructions',
  end: 'end',
  mediaItems: [
    {
      id: 'c42dc15c-516a-40f2-9076-5f56a7f572a8',
      tags: [],
      scope: MediaItemSnapshotScope.PUBLIC,
      text: null,
      image: {
        fullSize: {
          width: 900,
          height: 1600,
          key: 'fullSize/97764cde-1986-4fb7-9225-9f8bbd30790f.21',
          __typename: 'ImageInstance',
        },
        thumbNail: {
          width: 45,
          height: 80,
          key: 'thumbNail/97764cde-1986-4fb7-9225-9f8bbd30790f.21',
          __typename: 'ImageInstance',
        },
        __typename: 'Image',
      },
      originalFileName: 'WhatsApp Image 2023-06-18 at 18.21.57.jpeg',
      __typename: 'MediaItemSnapshot',
    },
    {
      id: 'e709422c-c9e7-454f-b2d1-02d274f92860',
      tags: [],
      scope: MediaItemSnapshotScope.PUBLIC,
      text: null,
      image: {
        fullSize: {
          width: 622,
          height: 800,
          key: 'fullSize/15442f75-e76f-4ea4-844b-0450563d07e6.jpg',
          __typename: 'ImageInstance',
        },
        thumbNail: {
          width: 62,
          height: 80,
          key: 'thumbNail/15442f75-e76f-4ea4-844b-0450563d07e6.jpg',
          __typename: 'ImageInstance',
        },
        __typename: 'Image',
      },
      originalFileName: 'dpd_fa_ma_02_24.jpg',
      __typename: 'MediaItemSnapshot',
    },
    {
      id: '04d1f47e-f464-4bba-9615-d6a9afa46db4',
      tags: [],
      scope: MediaItemSnapshotScope.PUBLIC,
      text: null,
      image: {
        fullSize: {
          width: 622,
          height: 800,
          key: 'fullSize/93895440-7845-4cc6-a657-b5450efdf9d7.jpg',
          __typename: 'ImageInstance',
        },
        thumbNail: {
          width: 62,
          height: 80,
          key: 'thumbNail/93895440-7845-4cc6-a657-b5450efdf9d7.jpg',
          __typename: 'ImageInstance',
        },
        __typename: 'Image',
      },
      originalFileName: 'dpd_fa_ma_02_19.jpg',
      __typename: 'MediaItemSnapshot',
    },
  ],
  status: TestStatus.DRAFT,
  createdAt: '2021-03-09T14:00:00.000Z',
  updatedAt: '2021-03-09T14:00:00.000Z',
};
