import { GQLQueryInstance } from '../../../../GQL';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';

export interface ResultTableColumn<E> {
  name: string;
  accessor: (result: E) => string;
  collapsed?: boolean;
}

export function PreviewResultTable({
  results,
  columns,
}: {
  results: GQLQueryInstance<any, any>[];
  columns: ResultTableColumn<any>[];
}) {
  const [collapsedState] = React.useState(() => {
    return columns.map((c) => !!c.collapsed);
  });
  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns.map((col, cIdx) => (
            <TableCell
              style={
                collapsedState[cIdx]
                  ? { textOverflow: 'ellipsis', maxWidth: '50px' }
                  : undefined
              }
              title={col.name}
            >
              {col.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {results.map((result) => (
          <TableRow>
            {columns.map((col, cIdx) => (
              <TableCell
                style={
                  collapsedState[cIdx]
                    ? { textOverflow: 'ellipsis', maxWidth: '50px' }
                    : undefined
                }
              >
                {col.accessor(result.variables.input)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
