import UAParser from 'ua-parser-js';
import { FC } from 'react';
import { DeviceInfoError } from './DeviceInfoError';
import { DeviceType } from './DeviceType';
import { DeviceMode } from './DeviceMode';
import { DeviceInfoInterface } from './DeviceInfoInterface';

const supportedDevices: Record<DeviceMode, DeviceType[]> = {
  desktop: ['smarttv', undefined], // DeviceTypes indicating a desktop pc
  mobile: ['mobile', 'tablet'], // DeviceTypes indicating phone or tablet
};

/**
 * Checks if the current device is amongst the supported devices,
 * else throws an DeviceInfoError, then reduces to mobile or desktop.
 *
 * @param input DeviceType of ua-parser-js library
 * @return "mobile" | "desktop"
 */
function getCurrentDeviceMode(input: DeviceType): DeviceMode {
  const resultMode = Object.entries(supportedDevices).reduce(
    (acc: DeviceMode | undefined, [mode, deviceTypes]) => {
      if (deviceTypes.includes(input)) {
        return mode as DeviceMode;
      }
      return acc;
    },
    undefined,
  );

  if (resultMode === undefined) {
    throw new DeviceInfoError('device is not supported');
  }
  return resultMode;
}

/**
 * Singleton-Pattern,
 * memorizing the current DeviceInfo in order to only calculate one
 */
let deviceInfoSingleton: DeviceInfoInterface | undefined = undefined;

/**
 * Gets device info from ua-parser-js library
 * then gets mode ("mobile" or "desktop")
 * to join with the rest of the device-info
 */
export function getDeviceInfo(): DeviceInfoInterface {
  if (deviceInfoSingleton === undefined) {
    const deviceInfo = new UAParser().getResult();

    deviceInfoSingleton = {
      ...deviceInfo,
      mode: getCurrentDeviceMode(deviceInfo.device.type as DeviceType),
    };
  }
  return deviceInfoSingleton;
}

export function createDeviceDependantComponent<P>(
  componentMap: Record<DeviceMode, FC<P>>,
) {
  return componentMap[getDeviceInfo().mode];
}
