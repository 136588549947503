import { TrialPhaseDescriptor } from '../../../../subject/testRunner/utils/tree-utils';
import { AmpBlockPhase } from '../structure/amp-block-structure';
import { AmpMediaPool } from '../loading/amp-media-loader';
import { AmpStructure } from '../structure/amp-structure';
import { DefaultTransitionAction } from '../../../../subject/testRunner/graph-state/graph-action';
import { AmpOptionsData } from '../view-data/amp-options-data';
import { StimulusContent } from '../../../../subject/testRunner/screens/basic-views/stimulus-content';
import { OptionsStereotypes } from '../../../../subject/testRunner/controls/control-stereotypes';
import { AMPModifiers } from './amp-modifiers';
import { TextMediaStyle } from '../../../../subject/testRunner/media/text-drawer';
import { TransitionModifier } from '../../../../subject/testRunner/graph-state/transition-modifiers';
import { buildAmpCreateResultQuery } from './amp-result-query-builder';
import { MapScreenTreeNode } from '../../../../subject/testRunner/graph/nodes/map-screen-tree-node';
import { MapPhaseTreeNode } from '../../../../subject/testRunner/graph/nodes/map-phase-tree-node';

export type AmpTrialPhaseDescriptor = TrialPhaseDescriptor<
  AmpBlockPhase,
  {
    prime: AmpMediaPool['primes']['values'][number];
    target: AmpMediaPool['targets']['values'][number];
  }
>;

export function createAmpSingleTrialPhaseTree(
  ampStructure: AmpStructure,
  trialDescriptor: AmpTrialPhaseDescriptor,
) {
  const { trialNode, block } = trialDescriptor;

  const tipsContent = block.instructions.tips
    ? {
        content: block.instructions.tips.text,
        textStyle: block.instructions.tips.style,
      }
    : null;

  const fixationNode = createTrialFixationNode(block, trialNode, tipsContent);

  const primeNode = createTrialPrimeNode(
    ampStructure,
    trialDescriptor,
    tipsContent,
    fixationNode ? undefined : AMPModifiers.default,
  );

  if (fixationNode) {
    fixationNode.transition(AMPModifiers.default, primeNode);
  }

  const blankNode = createTrialBlankNode(
    ampStructure,
    trialDescriptor,
    tipsContent,
  ).transitionFrom(AMPModifiers.default, primeNode);

  const targetNode = createTrialTargetNode(
    ampStructure,
    trialDescriptor,
    tipsContent,
  ).transitionFrom(AMPModifiers.default, blankNode);

  const maskNode = createTrialMaskNode(
    ampStructure,
    trialDescriptor,
    tipsContent,
  ).transitionFrom(AMPModifiers.default, targetNode);

  createTrialInterTrialNode(
    ampStructure,
    trialDescriptor,
    tipsContent,
  ).transitionFrom(AMPModifiers.default, maskNode);

  return trialNode;
}

function createTrialFixationNode(
  block: AmpBlockPhase,
  trialNode: MapPhaseTreeNode,
  tipsContent: {
    textStyle: TextMediaStyle;
    content: string;
  } | null,
) {
  let fixationNode: MapScreenTreeNode | undefined = undefined;
  if (block.fixation) {
    fixationNode = new MapScreenTreeNode(
      'fixation',
      trialNode,
      new AmpOptionsData(
        tipsContent,
        block.blockDescriptor.options,
        new StimulusContent(block.fixation.mediaItem!),
      ),
    )
      .controlRequest(
        OptionsStereotypes.TimeoutEvent.defineTransition(
          block.fixation.interval,
          (e) => DefaultTransitionAction.next(AMPModifiers.default),
        ),
      )
      .connectParent(AMPModifiers.default);
  }
  return fixationNode;
}

function createTrialPrimeNode(
  ampStructure: AmpStructure,
  { trialNode, block, trial }: AmpTrialPhaseDescriptor,
  tipsContent: {
    textStyle: TextMediaStyle;
    content: string;
  } | null,
  connectModifier: TransitionModifier | undefined,
) {
  return new MapScreenTreeNode(
    'prime',
    trialNode,
    new AmpOptionsData(
      tipsContent,
      block.blockDescriptor.options,
      new StimulusContent(trial.prime.value),
    ),
  )
    .connectParent(connectModifier)
    .controlRequest(
      OptionsStereotypes.TimeoutEvent.defineTransition(
        ampStructure.accessor.modelData.primes.interval,
        () => DefaultTransitionAction.next(AMPModifiers.default),
      ),
    );
}

function createTrialBlankNode(
  ampStructure: AmpStructure,
  { trialNode, block }: AmpTrialPhaseDescriptor,
  tipsContent: {
    textStyle: TextMediaStyle;
    content: string;
  } | null,
) {
  return new MapScreenTreeNode(
    'blank',
    trialNode,
    new AmpOptionsData(tipsContent, block.blockDescriptor.options, null),
  )
    .connectParent()
    .controlRequest(
      OptionsStereotypes.TimeoutEvent.defineTransition(
        ampStructure.accessor.modelData.blankInterval,
        () => DefaultTransitionAction.next(AMPModifiers.default),
      ),
    );
}

function createTrialTargetNode(
  ampStructure: AmpStructure,
  { trialNode, block, trial }: AmpTrialPhaseDescriptor,
  tipsContent: {
    textStyle: TextMediaStyle;
    content: string;
  } | null,
) {
  return new MapScreenTreeNode(
    'target',
    trialNode,
    new AmpOptionsData(
      tipsContent,
      block.blockDescriptor.options,
      new StimulusContent(trial.target.value),
    ),
  )
    .connectParent()
    .controlRequest(
      OptionsStereotypes.TimeoutEvent.defineTransition(
        ampStructure.accessor.modelData.targets.interval,
        () => DefaultTransitionAction.next(AMPModifiers.default),
      ),
    );
}

function createTrialMaskNode(
  ampStructure: AmpStructure,
  trialDescriptor: AmpTrialPhaseDescriptor,
  tipsContent: {
    textStyle: TextMediaStyle;
    content: string;
  } | null,
) {
  const { trialNode, block } = trialDescriptor;

  const createResult = buildAmpCreateResultQuery(ampStructure, trialDescriptor);

  return new MapScreenTreeNode(
    'mask',
    trialNode,
    new AmpOptionsData(
      tipsContent,
      block.blockDescriptor.options,
      new StimulusContent(block.mask),
    ),
  )
    .connectParent()

    .controlRequest(
      OptionsStereotypes.Side.Left.defineTransition(undefined, (control) =>
        DefaultTransitionAction.result(
          AMPModifiers.default,
          createResult(
            control,
            block.blockDescriptor.options.attribute!.left.optionVariant,
          ),
        ),
      ),
    )
    .controlRequest(
      OptionsStereotypes.Side.Right.defineTransition(undefined, (control) =>
        DefaultTransitionAction.result(
          AMPModifiers.default,
          createResult(
            control,
            block.blockDescriptor.options.attribute!.right.optionVariant,
          ),
        ),
      ),
    );
}

function createTrialInterTrialNode(
  ampStructure: AmpStructure,
  { trialNode, block }: AmpTrialPhaseDescriptor,
  tipsContent: {
    textStyle: TextMediaStyle;
    content: string;
  } | null,
) {
  return new MapScreenTreeNode(
    'intertrial-blank',
    trialNode,
    new AmpOptionsData(tipsContent, block.blockDescriptor.options, null),
  )
    .connectParent()
    .controlRequest(
      OptionsStereotypes.TimeoutEvent.defineTransition(
        ampStructure.accessor.modelData.interTrialInterval,
        () => DefaultTransitionAction.next(AMPModifiers.default),
      ),
    );
}
