import React, { PropsWithChildren, ReactNode } from 'react';
import { Avatar, Grid, GridProps, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InfoMessage, { InfoMessageProps } from '../InfoMessage';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 5),
  },
  stepTitle: {
    '& .MuiAvatar-root': {
      width: 55,
      height: 55,
      float: 'left',
      marginRight: theme.spacing(2),
      fontWeight: 'bolder',
      fontSize: 36,
      transform: 'translateY(-15%)',
    },
  },
}));

// ROW BREAK

export const GridRowBreak = () => (
  <div style={{ flexBasis: '100%', height: 0 }} />
);

// STEP CONTAINER

export const StepGridContainer = ({ children }: PropsWithChildren<any>) => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={3}
      className={classes.container}
      sx={{ border: 'solid' }}
    >
      {children}
    </Grid>
  );
};

// TITLE

interface TitleGridProps {
  prefix: string | number;
  title: string;
}

export const EditorStepTitle = ({ prefix, title }: TitleGridProps) => {
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center', mb: 3 }}>
      <Avatar sx={{ height: 28, width: 28 }}>{prefix}</Avatar>
      <Typography variant="h5">{title}</Typography>
    </Stack>
  );
};

// SUBTITLE

interface SubTitleGridProps extends GridProps {
  subtitle: string | ReactNode;
}

export const EditorSectionTitle = (props: SubTitleGridProps) => (
  <Typography variant="h6" sx={{ mb: 1 }} color="textSecondary">
    {props.subtitle}
  </Typography>
);

// INFO MESSAGE

export const InfoMessageGrid = (props: InfoMessageProps) => (
  <Grid item xs={12} md={3} style={{ marginLeft: 'auto' }}>
    <InfoMessage {...props} />
  </Grid>
);

// DESCRIPTION

export const DescriptionGrid = (props: PropsWithChildren<any> & GridProps) => (
  <Grid item xs={12} md={9} {...props}>
    {props.children}
  </Grid>
);

// TEXT EDITOR

export const TextEditorGrid = (props: PropsWithChildren<any> & GridProps) => (
  <Grid
    item
    xs={12}
    md={7}
    style={{ display: 'flex', ...props.styles }}
    {...props}
  >
    {props.children}
  </Grid>
);

// MULTIPLE MEDIA PICKER

export const MediaPickerGrid = (props: PropsWithChildren<any> & GridProps) => (
  <Grid item xs={12} md={7} {...props}>
    {props.children}
  </Grid>
);

// INPUT

export const InputGrid = (props: PropsWithChildren<any> & GridProps) => (
  <Grid item xs={12} md={2} {...props}>
    {props.children}
  </Grid>
);

// MEDIA ITEM

export const MediaItemGrid = (props: PropsWithChildren<any> & GridProps) => (
  <Grid item xs={12} md={3} {...props}>
    {props.children}
  </Grid>
);
