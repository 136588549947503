import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import StyledTextInput from '../../../../components/input/StyledTextInput';
import StyledSelect from '../../../../components/input/StyledSelect';
import { BlockType } from '../../../../API';
import StyledNumberSelect from '../../../../components/input/StyledNumberSelect';
import { ExpandMore } from '@mui/icons-material';
import { useField } from 'formik';
import { BlockInterface } from '../../types';
import { TestType } from '../../../subject/types';
import { Wysiwyg } from '../../../../components/input/wysiwyg/Wysiwyg';
import { EditorVariables } from '../../text-replacer/textReplacer';

type BlockSettingsProps = PropsWithChildren<{
  accumulationText?: string;
  amountTrialsDivisibleBy: number;
  index: number;
  removeBlock: () => void;
  counterBalancing?: boolean;
  testType: TestType;
  name: string;
  variables?: EditorVariables<string>;
}>;

const BlockSettings: FunctionComponent<BlockSettingsProps> = ({
  amountTrialsDivisibleBy,
  index,
  accumulationText,
  removeBlock,
  children,
  name,
  variables,
}) => {
  const blockPath = `blocks[${index}]`;
  const [, { error }] = useField<BlockInterface>(blockPath);

  return (
    <Box
      sx={
        error
          ? {
              border: 1,
              borderColor: 'red',
              borderWidth: '0.1',
              borderRadius: '4px',
            }
          : undefined
      }
    >
      <Accordion key={blockPath} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography sx={{ display: 'inline-flex' }}>
            {`Block ${index + 1}`}
            &nbsp;
            <Typography variant="inherit" color="textSecondary">
              ({name})
            </Typography>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StyledTextInput name={`${blockPath}.name`} label="Block-Name" />
            </Grid>
            <Grid item xs={12}>
              <StyledSelect name={`${blockPath}.type`} label="Block-Type">
                <option aria-label="None" value={undefined} />
                <option value={BlockType.PRACTICE}>Practice</option>
                <option value={BlockType.NON_PRACTICE}>No practice</option>
              </StyledSelect>
            </Grid>
            <Grid item xs={12}>
              <StyledNumberSelect
                name={`${blockPath}.amountTrials`}
                label="# of Trials"
                max={200}
                accumulationText={accumulationText}
                divisibleBy={amountTrialsDivisibleBy}
              />
            </Grid>
            {children}
            <Grid item xs={12}>
              <Wysiwyg
                name={`${blockPath}.instructions`}
                label="Block-Instructions"
                variables={variables}
              />
            </Grid>
            <Grid item xs={12}>
              <Wysiwyg
                name={`${blockPath}.tips`}
                label="Tips"
                toolTip={
                  'Additional info on the bottom of the screen during trials'
                }
                variables={variables}
              />
            </Grid>
            {index !== 0 && (
              <Grid item xs={12} sx={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={removeBlock}
                  startIcon={<DeleteIcon />}
                  title="Delete"
                >
                  Delete Block
                </Button>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default BlockSettings;
