import {
  SequencePhaseBuilder,
  SequenceTaskBuilder,
} from '../../../subject/testRunner/media/sequence-tasks';
import { TestBaseContext } from '../../../subject/testRunner/state/baseTestContext/context/ContextTypes';
import {
  TestResourceAccessor,
  TestResourceLoaderInstance,
} from '../../../subject/testRunner/media/test-resource-loader';
import { Iat } from '../types';
import { OptionArranger } from '../../../subject/testRunner/media/options-test';
import { createIatStructure } from './structure/iat-structure';
import { createIatTree } from './tree/iat-tree';
import { IatMediaPool, LoadIatMedia } from './loading/load-iat-media';
import { IatParametersDefinition } from './loading/iat-parameters-definition';
import { sequenceTree } from '../../../subject/testRunner/graph/tree-sequence/tree-sequence';
export const IatLoaderSequence = SequencePhaseBuilder.Builder<{
  testContextFactory: () => Promise<TestBaseContext>;
  loader: TestResourceLoaderInstance<Iat>;
}>()
  .start(
    'load',
    SequenceTaskBuilder.Builder<{
      testContextFactory: () => Promise<TestBaseContext>;
      loader: TestResourceLoaderInstance<Iat>;
    }>()
      .start('retrieve', async ({ testContextFactory, loader }) => {
        const testContext = await testContextFactory();
        return {
          testContext,
          accessor: await loader.load(testContext),
        };
      })
      .chainTask('load', ({ accessor }) => ({
        accessor,
        pool: LoadIatMedia(accessor),
      }))
      .chainTask('determine-parameters', ({ accessor, pool }) => ({
        pool,
        accessor,
        parameters: IatParametersDefinition.createManager(accessor),
      })),
  )
  .chain(
    'arrange',
    SequenceTaskBuilder.Builder<{
      pool: IatMediaPool;
      accessor: TestResourceAccessor<Iat>;
      parameters: Record<'optionsArranger', OptionArranger>;
    }>()
      // picking of items arranging texts and images, etc.
      .start('arrange', ({ accessor, pool, parameters }) =>
        createIatStructure(accessor, pool),
      )
      // transformation in generic runner structure
      .chainTask('tree', (structure) => ({
        structure,
        tree: createIatTree(structure),
      }))
      .chainTask('tree-sequence', (data) => ({
        ...data,
        sequence: sequenceTree(data.tree),
      })),
  )
  .createPhases();
