import { SelectorNodeMap } from '../../selectors/selector-tree';
import { CanvasStrategy } from '../scaler/canvas-strategy';
import { ViewContainerFactory } from '../../view-content-container';
import { ViewScaler } from '../scaler/view-scaler';
import { View } from './view';
import { AbstractView } from './abstract-view';
import { ViewDefinition } from '../view-definition-collection';
import { SizedArea } from '../../../media/drawable-dimensions';
import { ViewInstance } from '../screen-instance/view-instance';
import React from 'react';
import { displayRatio } from '../../basic-components/media-instance-components';
import { AbstractViewScaler } from '../scaler/abstract-view-scaler';
import { Selector } from '../../selectors/selector';

export class CanvasContentViewScaler extends AbstractViewScaler {
  protected canvasStrategy: CanvasStrategy<any>;

  constructor(
    viewScalerMap: Map<View, ViewScaler>,
    view: CanvasContentView<any>,
    selectorNodeMap: SelectorNodeMap,
  ) {
    super(viewScalerMap, view, selectorNodeMap);
    this.canvasStrategy = view.canvasStrategy;
  }

  async measureInner(): Promise<ViewScaler> {
    this.sizeState.innerSize = SizedArea.dimPx(
      this.canvasStrategy.scaleSize(
        this.sizeState.size,
        this.selectorNodeMap
          .getNodeMap(this.view.selector)
          .getDisplayDataSet()
          .map((d) => d[1][1]),
      ),
    );
    return this;
  }

  async measureMin(): Promise<ViewScaler> {
    this.sizeState.minSize = this.canvasStrategy.minSize(
      this.selectorNodeMap.getNodeMap(this.view.selector).getDisplayDataSet(),
    );
    return this;
  }

  async scale(): Promise<ViewScaler> {
    const displayDataSet = this.selectorNodeMap
      .getNodeMap(this.view.selector)
      .getDisplayDataSet();
    const scaler = this.canvasStrategy.scaler(
      this.sizeState.innerSize.toDim(),
      displayDataSet.map((dd) => dd[1][1]),
    );
    const dataDrawableMap = new Map(
      displayDataSet.map((dd) => [dd[1][1], scaler.scale(dd[1][1])]),
    );
    this.viewInstance = new ViewInstance(
      this,
      this.selectorNodeMap.getNodeMap(this.view.selector),
      (_, dataStream) => {
        const canvasSize = this.sizeState.innerSize.toDim();
        return () => {
          const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
          React.useEffect(() => {
            const dataSubscription = dataStream.subscribe({
              next: (value) => {
                const context = canvasRef.current!.getContext('2d')!;
                context.clearRect(
                  0,
                  0,
                  canvasSize.width * displayRatio,
                  canvasSize.height * displayRatio,
                );
                context.drawImage(
                  dataDrawableMap.get(value.value)!.imageSource,
                  0,
                  0,
                ); //, canvasSize.width, canvasSize.height);
                value.measureProcessTime();
              },
            });
            return () => dataSubscription.unsubscribe();
          }, []);
          return (
            <canvas
              ref={canvasRef}
              style={{
                ...this.sizeState.innerSize.rounded().toSizeModeStyle(),
                backgroundColor: 'transparent',
              }}
              width={
                (this.sizeState.innerSize.rounded().width?.value ?? 0) *
                displayRatio
              }
              height={
                (this.sizeState.innerSize.rounded().height?.value ?? 0) *
                displayRatio
              }
            />
          );
        };
      },
    );
    return this;
  }
}

export class CanvasContentView<R> extends AbstractView {
  constructor(
    grows: boolean,
    selector: Selector<R>,
    public canvasStrategy: CanvasStrategy<R>,
    containerFactory?: ViewContainerFactory,
  ) {
    super(grows, selector, containerFactory);
  }

  createViewScaler(
    viewScalerMap: Map<View, ViewScaler>,
    selectorNodeMap: SelectorNodeMap,
  ): ViewScaler {
    return new CanvasContentViewScaler(viewScalerMap, this, selectorNodeMap);
  }

  static growing<R>(
    selector: Selector<R>,
    strategy: CanvasStrategy<R>,
    containerFactory?: ViewContainerFactory,
  ) {
    return ViewDefinition.Leaf(
      new CanvasContentView(true, selector, strategy, containerFactory),
    );
  }

  static fixed<R>(
    selector: Selector<R>,
    strategy: CanvasStrategy<R>,
    containerFactory?: ViewContainerFactory,
  ) {
    return ViewDefinition.Leaf(
      new CanvasContentView(false, selector, strategy, containerFactory),
    );
  }
}
