import React, { FC } from 'react';
import { DistractionPhaseInputComponent } from '../../components/distractionPhase/DistractionPhaseInputComponent';
import { Box } from '@mui/material';
import { EditorStepTitle } from '../../../../../components/grid';
import * as yup from 'yup';
import { ConditionalQuestionSetSchema } from '../../components/conditionalQuestionSet/ConditionalQuestionSet';
import InfoMessage from '../../../../../components/InfoMessage';
import { OptionalInstructionsSchema } from '../../../../subject/testRunner/screens/OptionalInstructionsScreen';

export const WswDistractionPhaseStepSchema = yup
  .object()
  .required()
  .shape({
    distractionPhase: yup
      .object()
      .notRequired()
      .nullable()
      .default(null)
      .shape({
        instructionsScreen: OptionalInstructionsSchema,
        feedbackScreen: OptionalInstructionsSchema,
        questionOffsetTimeout: yup
          .number()
          .notRequired()
          .integer('must be integer')
          .nullable()
          .default(undefined),
        questionsSet: ConditionalQuestionSetSchema,
      }),
  });

export const WswDistractionPhaseStep: FC = () => {
  return (
    <Box>
      <EditorStepTitle prefix={5} title="Distraction Phase" />
      <InfoMessage
        message={
          'These questions will be displayed between learning and test phase.'
        }
      />
      <DistractionPhaseInputComponent
        fieldName="distractionPhase"
        label="Distraction Phase"
      />
    </Box>
  );
};
