import React, { FunctionComponent } from 'react';
import { Stack, Typography } from '@mui/material';
import { ErrorMessage, useField } from 'formik';
import * as Yup from 'yup';
import Toggle from './Toggle';
import { MediaItemSnapshotScope, TimedMediaItemInput } from '../../API';
import MediaItemPicker from '../../features/media/picker/MediaItemPicker';
import StyledNumberInput from './StyledNumberInput';
import { Build } from '@mui/icons-material';
import TextStyleDialog from '../TextStyleDialog';

export const FixationWithToggleSchema = Yup.object()
  .nullable()
  .test('fixation', 'dayum', function (v) {
    const fixationFromForm = v as TimedMediaItemInput;
    if (fixationFromForm && fixationFromForm.interval) {
      if (
        !fixationFromForm.mediaItem.image &&
        !fixationFromForm.mediaItem.text
      ) {
        return this.createError({
          message: 'pick fixation',
          path: `${this.path}`,
        });
      }
    }
    return true;
  });

const FixationPickerWithToggle: FunctionComponent<{
  fixationOn: boolean;
  stylesEditable: boolean;
}> = ({ fixationOn, stylesEditable }) => {
  const [, meta] = useField('fixation');

  return (
    <Stack spacing={2}>
      <Toggle<TimedMediaItemInput>
        name="fixation"
        label="Show Fixation"
        left="No"
        right="Yes"
        onToggleOn={() => ({
          interval: 500,
          mediaItem: {
            id: '',
            ownerEmail: '',
            scope: MediaItemSnapshotScope.PRIVATE,
            tags: [],
          },
        })}
        hideError
      />
      <Stack direction="row" spacing={2}>
        {fixationOn && meta.value && (
          <React.Fragment>
            <MediaItemPicker
              name="fixation.mediaItem"
              label="Fixation Stimulus"
              bgColorFieldName={'style.backgroundColour'}
            />
            <StyledNumberInput
              name="fixation.interval"
              label="Fixation Interval"
              unit="ms"
            />
            {stylesEditable && (
              <TextStyleDialog
                label={'Category Style'}
                name={'style.stimulusTextStyle'}
              >
                <Build />
              </TextStyleDialog>
            )}
          </React.Fragment>
        )}
        {meta.error && (
          <Typography variant="caption" color="error">
            <ErrorMessage name="fixation" />
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default FixationPickerWithToggle;
