import React from 'react';
import { Box, LinearProgress, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

export function TimerBar(props: {
  sx?: SxProps<Theme>;
  seconds: number;
  onFinish?: () => void;
}) {
  const [progress, setProgress] = React.useState(100);

  React.useEffect(() => {
    const tickSize = 100 / props.seconds;
    const interval = setInterval(() => {
      setProgress(() => {
        const result = progress - tickSize;
        if (result <= 0) {
          props.onFinish?.();
        }
        return result >= 0 ? result : 0;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [progress, props, props.seconds]);

  return (
    <Box
      sx={props.sx}
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: 38,
      }}
    >
      <Typography
        color="darkblue"
        sx={{
          position: 'absolute',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          zIndex: 1,
        }}
      >
        {Math.ceil((progress / 100) * props.seconds)}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{ height: 38, borderRadius: 1 }}
      />
    </Box>
  );
}
