import { WswTestQuestionInstance } from './wsw-test-question-instance';
import { WswQuestionType } from '../../../../../../API';
import { MediaInstance } from '../../../../../subject/testRunner/media/MediaData';
import { QuestionLocator } from './question-locator';
import React from 'react';
import { Button, ButtonGroup, Link } from '@mui/material';
import { QuestionAnswerListener } from './question-answer-listener';
import { WswStereotypes } from '../../wsw-controls';

export class WswTestMCQuestionInstance extends WswTestQuestionInstance {
  readonly type: WswQuestionType.MultipleChoice =
    WswQuestionType.MultipleChoice;

  constructor(
    questionText: string,
    stimulus: MediaInstance | null,
    public readonly answers: { answer: string; position: number }[],
    public readonly noAnswer: string | null,
    questionLocator: QuestionLocator,
    timeout: number,
  ) {
    super(
      WswQuestionType.MultipleChoice,
      questionText,
      stimulus,
      questionLocator,
      timeout,
    );
  }

  createComponent(): React.FC {
    return () => {
      return (
        <React.Fragment>
          <ButtonGroup
            variant="outlined"
            color="inherit"
            size="large"
            sx={{ mb: this.noAnswer ? 1 : undefined }}
          >
            {this.answers.map((value, i) => (
              <Button
                key={'scale-answer-' + i}
                onClick={QuestionAnswerListener((e) => {
                  return WswStereotypes.Answer.Answer.controlOccurred(
                    e.map((c) => ({
                      event: (c as any).nativeEvent as MouseEvent,
                      answer: this.toAnswer(
                        value.answer,
                        false,
                        value.position,
                      ),
                    })),
                  );
                })}
              >
                {value.answer}
              </Button>
            ))}
          </ButtonGroup>
          {this.noAnswer && (
            <Link
              sx={{ cursor: 'pointer' }}
              onClick={QuestionAnswerListener((e) => {
                return WswStereotypes.Answer.Answer.controlOccurred(
                  e.map((c) => ({
                    event: (c as any).nativeEvent as MouseEvent,
                    answer: this.toAnswer(this.noAnswer!, true, -1),
                  })),
                );
              })}
            >
              {this.noAnswer}
            </Link>
          )}
        </React.Fragment>
      );
    };
  }
}
