import React, { FC } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { Build } from "@mui/icons-material";
import TextStyleDialog from "../../../../../components/TextStyleDialog";
import { useField } from "formik";
import { WeightedStyledTextInput } from "../../../../../API";

interface PodtFeedbackMessagInputProps {
  name: string,
  label: string,
  bgcolor?: string | null,
  textValueDisabled?: boolean
}

export const PodtFeedbackMessageInput: FC<PodtFeedbackMessagInputProps> = ({ name, label, bgcolor, textValueDisabled }) => {
  const [, meta, helpers] = useField<WeightedStyledTextInput>(name);

  return (
    <TextField
      disabled={textValueDisabled}
      label={textValueDisabled ? undefined : label}
      sx={{ bgcolor }}
      value={meta?.value?.value ?? null}
      onChange={(e) =>
        helpers.setValue({
          ...meta.value,
          value: e.target.value
        })
      }
      fullWidth
      InputProps={{
        style: { color: meta.value?.fontStyle?.color },
        endAdornment:
          <InputAdornment position={"end"}>
            <TextField
              sx={{ width: 88, mr: 2 }}
              variant="standard"
              value={meta?.value?.weight ?? null }
              type="number"
              InputProps={{
                style: { color: meta.value?.fontStyle?.color },
                endAdornment: " Points"
              }}
              onChange={(e) =>
                helpers.setValue({
                  ...meta.value,
                  weight: Number(e.target.value)
                })
              }
            />
            <TextStyleDialog label={label} name={name + ".fontStyle"}>
              <Build />
            </TextStyleDialog>
          </InputAdornment>
      }}
    />
  );
};
