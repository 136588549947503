import { DefaultTransitionAction } from '../../../../subject/testRunner/graph-state/graph-action';
import { Podt } from '../../types';
import { PodtOrPodtaStructure } from '../structure/podt-structure';
import { Podta } from '../../../PODTA/types';
import { PODTModifiers } from './podt-modifiers';
import { PODTBlockPointsSlice } from './podt-tree-state';
import { PodtBlockPhase } from '../structure/podt-block-structure';
import { TextMediaStyle } from '../../../../subject/testRunner/media/text-drawer';
import { InstructionsContent } from '../../../../subject/testRunner/screens/basic-views/test-instructions-view';
import { OptionsStereotypes } from '../../../../subject/testRunner/controls/control-stereotypes';
import { createBlockTrialsNode } from './podt-trial-tree';
import { PodtOptionsData } from '../view-data/podt-options-data';
import { MapScreenTreeNode } from '../../../../subject/testRunner/graph/nodes/map-screen-tree-node';
import { MapPhaseTreeNode } from '../../../../subject/testRunner/graph/nodes/map-phase-tree-node';

export function createPodtBlockPhases(
  root: MapPhaseTreeNode,
  start: MapPhaseTreeNode,
  structure: PodtOrPodtaStructure<Podt | Podta>,
) {
  const blocks = new MapPhaseTreeNode('blocks', root)
    .connectParent()
    .transitionFrom(PODTModifiers.default, start);
  const blockNodes = structure.blockPhases.map((block, blockIndex) => {
    const blockNode = new MapPhaseTreeNode(
      'block-' + blockIndex,
      blocks,
      PODTBlockPointsSlice,
    ).connectParent(blockIndex === 0 ? PODTModifiers.default : undefined);
    const tipsContent = block.instructions.tips
      ? {
          content: block.instructions.tips.text,
          textStyle: block.instructions.tips.style,
        }
      : null;
    const instructionsNode = createBlockInstructionNode(
      blockNode,
      tipsContent,
      block,
      structure,
    );
    let preparationNode = createBlockPreparationNode(
      block,
      blockNode,
      tipsContent,
      instructionsNode,
    );
    createBlockTrialsNode(
      blockNode,
      preparationNode ?? instructionsNode,
      block,
      tipsContent,
      structure,
    );
    return blockNode;
  });
  blockNodes.reduce((acc, c) => c.transitionFrom(PODTModifiers.default, acc));
  return blocks;
}

function createBlockInstructionNode(
  blockNode: MapPhaseTreeNode,
  tipsContent: { textStyle: TextMediaStyle; content: string } | null,
  block: PodtBlockPhase,
  structure: PodtOrPodtaStructure<Podt | Podta>,
) {
  return new MapScreenTreeNode(
    'instructions',
    blockNode,
    new PodtOptionsData(
      tipsContent,
      block.options,
      new InstructionsContent(
        block.instructions.welcomeScreen.content,
        structure.accessor.modelData.nextLabel,
      ),
    ),
  )
    .controlRequest(
      OptionsStereotypes.Confirm.Control.defineTransition(undefined, () =>
        DefaultTransitionAction.next(PODTModifiers.default),
      ),
    )
    .connectParent(PODTModifiers.default);
}

function createBlockPreparationNode(
  block: PodtBlockPhase,
  blockNode: MapPhaseTreeNode,
  tipsContent: { textStyle: TextMediaStyle; content: string } | null,
  instructionsNode: MapScreenTreeNode,
) {
  let preparationNode: MapScreenTreeNode | undefined = undefined;
  if (block.instructions.preparationScreen) {
    preparationNode = new MapScreenTreeNode(
      'preparation',
      blockNode,
      new PodtOptionsData(
        tipsContent,
        block.options,
        new InstructionsContent(
          block.instructions.preparationScreen.content,
          null,
        ),
      ),
    )
      .controlRequest(
        OptionsStereotypes.TimeoutEvent.defineTransition(
          block.instructions.preparationScreen.interval,
          () => DefaultTransitionAction.next(PODTModifiers.default),
        ),
      )
      .transitionFrom(PODTModifiers.default, instructionsNode)
      .connectParent();
  }
  return preparationNode;
}
