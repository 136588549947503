import React, { FC } from 'react';
import { ConditionalQuestionSet } from '../../../../../../API';
import { Box, Typography } from '@mui/material';
import { ConditionalQuestionView } from './ConditionalQuestionView';
import { TestQuestionView } from './TestQuestionView';

interface ConditionalQuestionSetViewProps {
  conditionalQuestionSet?: ConditionalQuestionSet | undefined | null;
  label: string;
}

export const ConditionalQuestionSetView: FC<ConditionalQuestionSetViewProps> = (
  props,
) => {
  return props.conditionalQuestionSet ? (
    <Box>
      <Typography color="textSecondary" sx={{ mb: 1 }}>
        {props.label}
        {props.conditionalQuestionSet.randomizeSequence && ' (Randomized)'}
      </Typography>
      <Box>
        {props.conditionalQuestionSet.questionsBefore?.map((q, qi) => (
          <TestQuestionView
            key={`test-questions-view-${qi}`}
            index={qi}
            testQuestion={q}
          />
        ))}
      </Box>
      <Box sx={{ pl: 1, width: '50%' }}>
        {props.conditionalQuestionSet.conditionalQuestion && (
          <ConditionalQuestionView
            conditionalQuestion={
              props.conditionalQuestionSet.conditionalQuestion
            }
          />
        )}
        {props.conditionalQuestionSet.conditionalQuestion &&
          props.conditionalQuestionSet.questionsAfter && (
            <Typography
              color="textSecondary"
              sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
            >
              Follow up Question
            </Typography>
          )}
        <Box>
          {props.conditionalQuestionSet.questionsAfter?.map((q, qi) => (
            <TestQuestionView
              key={`test-questions-view-${qi}`}
              index={qi}
              testQuestion={q}
            />
          ))}
        </Box>
      </Box>
    </Box>
  ) : (
    <React.Fragment />
  );
};
