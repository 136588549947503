import { TestType } from '../../subject/types';
import { BaseTest } from '../types';
import {
  Divider,
  IconButton,
  LinearProgress,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { TestMode } from '../../subject/testRunner/state/baseTestContext/TestMode';
import { standAloneProvider } from '../../subject/testRunner/state/TestProviderMap';
import { CopyAll, OpenInBrowser } from '@mui/icons-material';
import React, { PropsWithChildren } from 'react';
import { Alert } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';

function StandaloneURLComponent({
  mode,
  test,
  testType,
  children,
  staticLanguage,
}: PropsWithChildren<{
  mode: TestMode;
  testType: TestType;
  test: BaseTest;
  staticLanguage?: string;
}>) {
  const url = `${window.location.origin}${standAloneProvider.createTestUrl(
    { testType, testId: test.id },
    mode,
    mode === 'preview',
    {
      lang: staticLanguage ?? [],
    },
  )}`;
  return (
    <Stack spacing={2}>
      {children}
      <Stack direction="row" sx={{ width: '100%' }}>
        <TextField sx={{ flex: 1 }} value={url} />
        <IconButton
          title="Copy Link"
          sx={{ alignSelf: 'center', marginLeft: '1em' }}
          onClick={async () => {
            try {
              await navigator.clipboard.writeText(url);
              enqueueSnackbar('Link copied to clipboard', {
                variant: 'success',
              });
            } catch (err) {
              enqueueSnackbar('Failed to copy link to clipboard', {
                variant: 'error',
              });
            }
          }}
        >
          <CopyAll />
        </IconButton>
        <IconButton
          sx={{ alignSelf: 'center' }}
          title="Open link in new tab"
          component={Link}
          href={url}
          target="_blank"
        >
          <OpenInBrowser />
        </IconButton>
      </Stack>
    </Stack>
  );
}

export function StandaloneSetupInstructions({
  test,
  testType,
}: {
  testType: TestType;
  test?: BaseTest;
}) {
  return test ? (
    <Stack spacing={2}>
      <StandaloneURLComponent
        mode="preview"
        testType={testType}
        test={test}
        staticLanguage={test.language ?? undefined}
      >
        <Typography variant="h5" color="text.secondary">
          Preview Version
        </Typography>
        <Alert severity="warning">
          <span style={{ fontWeight: 'bold' }}>No </span> results will be
          persisted. Use this to validate your test configuration.
        </Alert>
      </StandaloneURLComponent>
      <Divider />
      <StandaloneURLComponent
        mode="execution"
        testType={testType}
        test={test}
        staticLanguage={test.language ?? undefined}
      >
        <Typography variant="h5" color="text.secondary">
          Execution Version
        </Typography>
        <Alert severity="error">
          Results will be persisted and can be downloaded later
        </Alert>
      </StandaloneURLComponent>
    </Stack>
  ) : (
    <LinearProgress />
  );
}
