import React from 'react';
import { useField } from 'formik';
import {
  Box,
  InputAdornment,
  Popover,
  TextField,
  TextFieldProps,
  Tooltip,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import { UiInput } from './types';
import makeStyles from '@mui/styles/makeStyles';
import { SketchPicker } from 'react-color';
import { InfoOutlined } from '@mui/icons-material';

const useStyles = makeStyles((theme) =>
  createStyles({
    colorDisplay: {
      paddingRight: theme.spacing(1),
    },
  }),
);

export function isValidColor(c: string): boolean {
  const isValidHexRgbaOrHsla = new RegExp(
    /^(#[\da-f]{3}|#[\da-f]{6}|rgba\(((\d{1,2}|1\d\d|2([0-4]\d|5[0-5]))\s*,\s*){2}((\d{1,2}|1\d\d|2([0-4]\d|5[0-5]))\s*)(,\s*(0\.\d+|1))\)|hsla\(\s*((\d{1,2}|[1-2]\d{2}|3([0-5]\d|60)))\s*,\s*((\d{1,2}|100)\s*%)\s*,\s*((\d{1,2}|100)\s*%)(,\s*(0\.\d+|1))\)|rgb\(((\d{1,2}|1\d\d|2([0-4]\d|5[0-5]))\s*,\s*){2}((\d{1,2}|1\d\d|2([0-4]\d|5[0-5]))\s*)|hsl\(\s*((\d{1,2}|[1-2]\d{2}|3([0-5]\d|60)))\s*,\s*((\d{1,2}|100)\s*%)\s*,\s*((\d{1,2}|100)\s*%)\))$/i,
  );
  const s = new Option().style;
  s.color = c.toLowerCase();
  const isValidColorName = String(s.color) === c.toLowerCase();
  return isValidColorName || isValidHexRgbaOrHsla.test(c);
}

export function isInvisibleColor(c: string): boolean {
  return (
    c === 'white' ||
    c === 'transparent' ||
    c.endsWith('0)') ||
    c?.toUpperCase() === '#FFF' ||
    c?.toUpperCase() === '#FFFFFF'
  );
}

export default function ColorInput({
  name,
  label,
  disabled,
  toolTip,
}: UiInput<string> & TextFieldProps & { toolTip?: string }): JSX.Element {
  const classes = useStyles();
  const [, meta, helpers] = useField<string>(name);

  const color =
    meta.value && meta.value !== '' && isValidColor(meta.value)
      ? meta.value
      : 'transparent';

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget as any);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const colorDisplay = (
    <InputAdornment position="end" className={classes.colorDisplay}>
      {isInvisibleColor(color) ? (
        <Brightness1OutlinedIcon style={{ color: '#DDD' }} />
      ) : (
        <Brightness1Icon style={{ color }} />
      )}
    </InputAdornment>
  );

  return (
    <Box position="relative">
      {/* <ColorPicker
        fullWidth
        id={`color-picker-${name}`}
        label={label}
        value={meta.value}
        onChange={onChangeColor}
        disabled={mode === "view" || disabled}
        error={meta.touched && meta.error !== undefined}
        helperText={(meta.touched && meta.error) || ""}
        autoComplete="off"
        InputLabelProps={{
          shrink: !!color && meta.value !== ""
        }}
        InputProps={{
          value: meta.value,
          endAdornment: colorDisplay
        }}
      />*/}
      <TextField
        fullWidth
        id={`color-picker-${name}`}
        label={label}
        onClick={handleClick}
        onChange={(e) => helpers.setValue(e.target.value)}
        disabled={disabled}
        error={meta.touched && meta.error !== undefined}
        helperText={(meta.touched && meta.error) || ''}
        autoComplete="off"
        InputLabelProps={{
          shrink: !!color && meta.value !== '',
        }}
        InputProps={{
          value: meta.value,
          endAdornment: colorDisplay,
        }}
      />
      {toolTip && (
        <Tooltip title={toolTip} placement="left-start">
          <InfoOutlined
            sx={{
              color: 'warning.dark',
              bgcolor: 'white',
              position: 'absolute',
              top: -10,
              right: -10,
            }}
          />
        </Tooltip>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SketchPicker
          color={meta.value}
          onChange={(color) => helpers.setValue(color.hex)}
        />
      </Popover>
    </Box>
  );
}
