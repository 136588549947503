import { OptionsScreenData } from '../../../../subject/testRunner/screens/basic-views/option-test-views';
import { TipsContent } from '../../../../subject/testRunner/screens/basic-views/tips-content';
import { OptionCategoriesArrangement } from '../../../../subject/testRunner/media/options-test';
import { InstructionsContent } from '../../../../subject/testRunner/screens/basic-views/test-instructions-view';
import { StimulusContent } from '../../../../subject/testRunner/screens/basic-views/stimulus-content';
import { TextMediaStyle } from '../../../../subject/testRunner/media/text-drawer';
import { ControlTransition } from '../../../../subject/testRunner/controls/control-transition';
import { AmpScreenData } from './amp-screen-data';

export class AmpOptionsData
  extends AmpScreenData
  implements OptionsScreenData<'attribute'>
{
  tips?: TipsContent;
  options: OptionCategoriesArrangement<'attribute'>;
  content: InstructionsContent | StimulusContent | null;

  constructor(
    tips: { content: string; textStyle: TextMediaStyle } | null,
    options: OptionCategoriesArrangement<'attribute'>,
    content: InstructionsContent | StimulusContent | null,
    controlTransitions?: ControlTransition<any, any>[],
  ) {
    super(controlTransitions);
    this.tips = tips;
    this.options = options;
    this.content = content;
  }
}
