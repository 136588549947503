import { format } from 'date-fns';

export function formatDate(
  dateToFormat: string | number | Date | undefined | null,
): string {
  if (
    dateToFormat === '' ||
    dateToFormat === null ||
    dateToFormat === undefined
  ) {
    return '';
  } else {
    return format(new Date(dateToFormat), 'dd.MM.yyyy - HH:mm');
  }
}
