import React, { FC } from "react";
import { Amp } from "../../types";
import { Avatar, Box, Card, CardContent, Chip, Stack } from "@mui/material";
import { TextDisplay } from "../../../../../components/TextDisplay";
import { BorderBox } from "../../../../../components/BorderBox";
import MediaItemComponent from "../../../../media/MediaItemComponent";

export const AmpBlockSettingsView: FC<{ test: Amp }> = ({ test }) => {
  return (
    <Box>
      <Stack direction="row" spacing={2}>
        <MediaItemComponent
          bgColor={test.style.backgroundColour}
          color={test.attributeCategories.fontStyle.color}
          mediaItem={test.attributeCategories.categoryA}
          label={"Attribute Category A"}
        />
        <MediaItemComponent
          bgColor={test.style.backgroundColour}
          color={test.attributeCategories.fontStyle.color}
          mediaItem={test.attributeCategories.categoryB}
          label={"Attribute Category B"}
        />
      </Stack>
      <Stack direction="row" spacing={2} sx={{mb: 2}}>
        {test.targets.categories.map((tc,tci) => (
          <TextDisplay
            label={`Prime Category ${tci + 1}`}
            value={tc.label}
          />
        ))}
      </Stack>
      {/*<TextDisplay label={"Attribute Category A"} value={test.attributeCategories.categoryA.}/>*/}
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {test.blocks.map((block, i) => {
          return (
            <Card
              variant="outlined"
              key={`block-${i}`}
              sx={{ m: 1, position: "relative", overflow: "visible", width: "49%" }}
            >
              <Chip
                size="small"
                label={block.type === "PRACTICE" ? "Practice" : "No Practice"}
                style={{ position: "absolute", top: 8, right: 8 }}
              />
              <Avatar
                sx={{
                  height: 24,
                  width: 24,
                  bgcolor: "darkGrey",
                  position: "absolute",
                  top: -8,
                  left: -8
                }}
              >
                {i + 1}
              </Avatar>
              <CardContent>
                <Stack spacing={1}>
                  <TextDisplay
                    label={"Name"}
                    value={block.name}
                  />
                  <TextDisplay
                    label={"# Trials"}
                    value={`${block.amountTrials} Trials`}
                  />
                  <BorderBox label="Instructions">
                    <div dangerouslySetInnerHTML={{ __html: block.instructions.desktop }} />
                  </BorderBox>
                  {block.tips?.desktop &&
                    <BorderBox label="Tips">
                      <div dangerouslySetInnerHTML={{ __html: block.tips.desktop }} />
                    </BorderBox>}
                </Stack>
              </CardContent>
            </Card>
          );
        })}
      </Box>
    </Box>
  );
};
