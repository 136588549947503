import { View } from '../views/view';
import { SizeState, SizeStateDisplay } from './size-state';
import { SelectorNodeMap } from '../../selectors/selector-tree';
import { BasicDimension } from '../../../media/drawable-dimensions';
import { ViewInstance } from '../screen-instance/view-instance';
import { ViewScaler } from './view-scaler';

export abstract class AbstractViewScaler implements ViewScaler {
  protected viewScalerMap: Map<View, ViewScaler>;

  sizeState: SizeState = new SizeState();

  constructor(
    viewScalerMap: Map<View, ViewScaler>,
    public view: View,
    protected selectorNodeMap: SelectorNodeMap,
  ) {
    this.viewScalerMap = viewScalerMap;
    this.sizeState.stateDisplay = new SizeStateDisplay(
      '[' + view + ']' + view.pathName,
      0,
    );
  }

  get pathName() {
    return this.view.pathName + ':scaler';
  }

  maxSize(size: BasicDimension) {
    this.sizeState.maxSize = size;
    this.cascadeMaxSize(size);
    return this;
  }

  protected cascadeMaxSize(size: BasicDimension) {}

  size(size: BasicDimension) {
    this.sizeState.size = size;
    this.cascadeSize(size);
    return this;
  }

  protected cascadeSize(size: BasicDimension) {}

  protected _viewInstance: ViewInstance | undefined;
  get viewInstance(): ViewInstance {
    if (!this._viewInstance) {
      throw new Error('No View instance for view yet ' + this.view.pathName);
    }
    return this._viewInstance;
  }

  set viewInstance(viewInstance: ViewInstance) {
    if (this._viewInstance) {
      throw new Error(
        'View instance for view already set ' + this.view.pathName,
      );
    }
    this._viewInstance = viewInstance;
  }

  abstract measureInner(): Promise<ViewScaler>;

  abstract measureMin(): Promise<ViewScaler>;

  abstract scale(): Promise<ViewScaler>;
}
