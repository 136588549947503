import { TestBaseContext } from '../../../../subject/testRunner/state/baseTestContext/context/ContextTypes';
import {
  PodtBlockBaseConfig,
  PodtBlockInstructionsConfig,
} from './helper-types';
import { OptionCategoriesArrangement } from '../../../../subject/testRunner/media/options-test';
import { PodtOptionsType } from '../loading/podt-loader';
import { Podt } from '../../types';
import { PodtVariables } from '../../../text-replacer/textReplacer';
import { BlockType, CategoryVariant } from '../../../../../API';
import {
  extractTextContent,
  InstructionScreenContent,
} from '../../../../subject/testRunner/utils/structure-utils';
import { TextMediaStyle } from '../../../../subject/testRunner/media/text-drawer';
import { DrawableMediaInstance } from '../../../../subject/testRunner/media/MediaData';
import { PodtStimulus } from '../../creator/podt-picker';
import { PodtGlobalConfig } from './podt-structure';

export interface PodtBlockDescriptor {
  blockType: BlockType;
  options: OptionCategoriesArrangement<PodtOptionsType>;
  amountTrials: number;
}

export interface PodtBlockPhase {
  blockIndex: number;
  blockName: string;
  blockType: BlockType;
  instructions: {
    welcomeScreen: InstructionScreenContent;
    preparationScreen: { content: string; interval: number } | null;
    tips: { text: string; style: TextMediaStyle } | null;
  };
  options: OptionCategoriesArrangement<PodtOptionsType>;
  intermediate: {
    fixation?: {
      presentable: DrawableMediaInstance;
      interval: number;
    };
  };
  trialStimuli: PodtStimulus[];
}

type PodtBlockConfig = PodtBlockBaseConfig & PodtBlockInstructionsConfig;

export function describePodtBlock(
  podtConfig: PodtGlobalConfig,
  block: PodtBlockConfig,
): PodtBlockDescriptor {
  return {
    amountTrials: block.amountTrials,
    blockType: block.type,
    options: podtConfig.options.arrange({ response: CategoryVariant.DEFAULT }),
  };
}

export function preparePodtBlockTexts(
  testContext: TestBaseContext,
  blockInstructions: PodtBlockInstructionsConfig,
  phaseOptions: OptionCategoriesArrangement<PodtOptionsType>,
  keyboardOptions: Pick<Podt['keyboard'], 'left' | 'right' | 'confirm'>,
): Record<keyof PodtBlockPhase['instructions'], string | null> {
  const replacer = PodtVariables.defineInstance({
    weaponButton: (phaseOptions.response!.left.optionVariant === 'first'
      ? keyboardOptions.left
      : keyboardOptions.right
    ).replace('Key', ''),
    objectButton: (phaseOptions.response!.left.optionVariant === 'second'
      ? keyboardOptions.left
      : keyboardOptions.right
    ).replace('Key', ''),
  });
  return {
    welcomeScreen: replacer.replace(
      extractTextContent(testContext, blockInstructions.instructions),
    ),
    preparationScreen: blockInstructions.preparationScreen
      ? replacer.replace(
          extractTextContent(
            testContext,
            blockInstructions.preparationScreen.screen,
          ),
        )
      : null,
    tips: blockInstructions.tips
      ? replacer.replace(
          extractTextContent(testContext, blockInstructions.tips),
        )
      : null,
  };
}
