import { useField } from "formik";
import { PodtSceneInput } from "../../../../../../API";
import { SceneSettingsState } from "./PodtSceneSettings";
import { useState } from "react";

export function useFormikPodtSceneBoxes(fieldName: string) {
  const [, meta, helpers] = useField<PodtSceneInput[]>(fieldName);

  const [currentBoxes, setCurrentBoxes] = useState<SceneSettingsState["boxes"]>(meta.value[0]?.boxes ?? []);

  const setScenesBoxes = (id: string) => (boxes: SceneSettingsState["boxes"]) => {
    setCurrentBoxes(boxes);
    helpers.setValue(
      meta.value.map((s) =>
        s.mediaItem.id !== id
          ? s
          : {
            ...s,
            boxes
          }
      )
    );
  };

  const applyBoxesToAllScenes = () => {
    helpers.setValue(meta.value.map((s) => ({
      ...s,
      boxes: currentBoxes
    })));
  };

  const lockBoxesHeight = (height: number) => {
    helpers.setValue(
      meta.value.map((s) => ({
        ...s,
        boxes: s.boxes.map((b) => ({ ...b, height }))
      }))
    );
  };

  return {
    scenes: meta.value,
    error: meta.error,
    touched: meta.touched,
    setScenesBoxes,
    applyBoxesToAllScenes,
    lockBoxesHeight
  };
}

