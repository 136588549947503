import React, { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { PresentationPhase } from '../../../../../API';
import { ConditionalQuestionSetView } from '../../components/conditionalQuestionSet/view/ConditionalQuestionSetView';
import { WysiwygScreenView } from '../../../../subject/testRunner/screens/basic-views/WysiwygScreenView';

interface WswPresentationPhaseViewProps {
  phase: PresentationPhase;
  label: string;
}

export const WswPresentationPhaseView: FC<WswPresentationPhaseViewProps> = (
  props,
) => {
  return (
    <Box>
      <Stack direction="row" spacing={3} sx={{ mb: 2 }}>
        <WysiwygScreenView
          content={props.phase.instructionsScreen?.desktop}
          label={'Instructions Screen'}
        />
        <WysiwygScreenView
          content={props.phase.feedbackScreen?.desktop}
          label={'Feedback Screen'}
        />
      </Stack>
      <ConditionalQuestionSetView
        label={'Questionset'}
        conditionalQuestionSet={props.phase.questionsSet}
      />
    </Box>
  );
};
