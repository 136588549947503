import { AbstractLayoutViewScaler } from './abstract-layout-view-scaler';
import { ViewBoxesScaler } from './view-boxes-scaler';
import { ContainerScaler } from './container-scaler';
import { View } from '../views/view';
import { ViewScaler } from './view-scaler';
import { SelectorNodeMap } from '../../selectors/selector-tree';
import { LayoutingView } from '../views/layouting-view';
import { DefaultContainerScaler } from './default-container-scaler';
import {
  DrawableDimension,
  SizedArea,
} from '../../../media/drawable-dimensions';
import { ViewInstance } from '../screen-instance/view-instance';
import { LayoutContentComponentFactory } from '../../layouting-view';

export class GrowingLayoutViewScaler extends AbstractLayoutViewScaler {
  protected viewBoxesScaler: ViewBoxesScaler;
  protected containerScaler: ContainerScaler;
  protected contentComponent: LayoutContentComponentFactory<any>;

  constructor(
    viewScalerMap: Map<View, ViewScaler>,
    selectorNodeMap: SelectorNodeMap,
    view: LayoutingView<any>,
  ) {
    super(viewScalerMap, view, selectorNodeMap);
    this.viewBoxesScaler = view.boxes.createBoxesScaler(viewScalerMap);
    this.containerScaler = new DefaultContainerScaler(
      this.view.pathName + '[container-scaler]',
      this.sizeState,
      'growing',
    );
    this.contentComponent = view.contentComponent;
  }

  async measureInner(): Promise<ViewScaler> {
    this.sizeState.innerSize = SizedArea.dimPx(this.sizeState.size);
    this.viewBoxesScaler.maxSize(this.sizeState.size);
    return this;
  }

  async measureMin(): Promise<ViewScaler> {
    this.sizeState.minSize = DrawableDimension.NullSize;
    return this;
  }

  async scale(): Promise<ViewScaler> {
    this.viewBoxesScaler.determineMin();

    await this.measureDOM(
      this.sizeState.maxSize,
      this.viewBoxesScaler.createMeasureProvider('size'),
      this.containerScaler.createMeasureProvider('size'),
    );

    this.viewInstance = new ViewInstance(
      this,
      this.selectorNodeMap.getNodeMap(this.view.selector),
      (viewControllerMap) => {
        return this.contentComponent(
          this.containerScaler.createRenderProvider(viewControllerMap),
          this.viewBoxesScaler.createRenderProvider(viewControllerMap),
        );
      },
    );
    return this;
  }
}
