import React from 'react';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { TextAlignment } from '../../API';
import { useField } from 'formik';

export default function TextAlignmentButtonGroup(props: { name: string }) {
  const [, meta, helpers] = useField<TextAlignment>(props.name);

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: TextAlignment | null,
  ) => {
    if (newAlignment) {
      helpers.setValue(newAlignment);
    }
  };

  return (
    <ToggleButtonGroup
      value={meta.value}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
    >
      <ToggleButton value={TextAlignment.LEFT} aria-label="left aligned">
        <FormatAlignLeftIcon />
      </ToggleButton>
      <ToggleButton value={TextAlignment.CENTER} aria-label="centered">
        <FormatAlignCenterIcon />
      </ToggleButton>
      <ToggleButton value={TextAlignment.RIGHT} aria-label="right aligned">
        <FormatAlignRightIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
