import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useRunnerAppTranslator } from '../../state/baseTestContext/app-configuration/translations/translator.hook';
import { translations } from '../../state/baseTestContext/app-configuration/translations/translation-builder';
import { Translations } from '../../state/baseTestContext/app-configuration/translations/app-translations';

export function MobileOrientationSetup({ onFinish }: { onFinish: () => void }) {
  const screenTranslator = useRunnerAppTranslator(
    Translations.App.Loading.MobileOrientationScreen,
  );
  return (
    <>
      <Box
        className="portraitCheckOrientation"
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          {screenTranslator.message.mapTranslation((txt) => (
            <Typography variant="body1" textAlign="center">
              {txt}
            </Typography>
          ))}
        </Box>
        <Box
          flexGrow={0}
          display="flex"
          justifyContent="center"
          paddingBottom="env(safe-area-inset-bottom)"
        >
          <Button
            onClick={() => {
              onFinish();
            }}
          >
            {screenTranslator.buttonLabelYes.translate().join(' / ')}
          </Button>
        </Box>
      </Box>
      <Box
        className="landscapeCheckOrientation"
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingBottom="env(safe-area-inset-bottom)"
      >
        <Box
          display="flex"
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          {screenTranslator.message.mapTranslation((txt) => (
            <Typography variant="body1" textAlign="center">
              {txt}
            </Typography>
          ))}
        </Box>
        <Button
          onClick={() => {
            onFinish();
          }}
        >
          {screenTranslator.buttonDone.translate().join(' / ')}
        </Button>
      </Box>
    </>
  );
}

export const OrientationTranslations = translations.scope(
  'Mobile Screen Orientation',
  {
    message: translations.text('Question Text', {
      en: 'Please turn your phone to landscape mode',
      de: ' Bitte drehen Sie ihr Handy ins Querformat',
    }),
    buttonLabelYes: translations.text(
      'Button saying, that it is already in landscape mode',
      {
        en: 'Already in landscape',
        de: 'Gerät ist bereits im Querformat',
      },
    ),
    buttonDone: translations.text('Button when done', {
      en: 'Done',
      de: 'Erledigt',
    }),
  },
);
