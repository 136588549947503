import { TreeNode } from './tree-node';
import { TransitionModifier } from '../../graph-state/transition-modifiers';
import { PhaseTreeNode } from './phase-tree-node';
import { ScreenTreeNode } from './screen-tree-node';
import { StateSlice } from '../../graph-state/state-holder';
import { MapScreenTreeNode } from './map-screen-tree-node';
import { MapPhaseTreeNode } from './map-phase-tree-node';

export abstract class MapTreeNode implements TreeNode {
  abstract readonly type: 'screen' | 'phase';
  transitions: Map<TransitionModifier, PhaseTreeNode | ScreenTreeNode> =
    new Map<TransitionModifier, PhaseTreeNode | ScreenTreeNode>();
  protected _displayName?: string;

  constructor(
    public readonly name: string,
    public readonly parent: PhaseTreeNode | null,
    public readonly stateSlice?: StateSlice<any>,
  ) {}
  withDisplayName(displayName: string) {
    this._displayName = displayName;
    return this;
  }
  get displayName() {
    return this._displayName ?? this.name;
  }
  get pathName() {
    return this.parent?.pathName
      ? this.parent.pathName + '.' + this.name
      : this.name;
  }

  transition(
    modifier: TransitionModifier,
    node: PhaseTreeNode | ScreenTreeNode,
  ) {
    this.transitions.set(modifier, node);
    return this;
  }

  transitionFrom(
    modifier: TransitionModifier,
    node: MapPhaseTreeNode | MapScreenTreeNode,
  ) {
    node.transition(modifier, this as any);
    return this;
  }

  assignParent(parent: MapPhaseTreeNode, startModifier?: TransitionModifier) {
    parent.addChild(this as any, startModifier);
    return this;
  }

  connectParent(startModifier?: TransitionModifier) {
    (this.parent as MapPhaseTreeNode).addChild(this as any, startModifier);
    return this;
  }

  next(
    modifier: TransitionModifier,
  ):
    | [(PhaseTreeNode | ScreenTreeNode)[], PhaseTreeNode | ScreenTreeNode]
    | null {
    if (this.transitions.has(modifier)) {
      return [[this as any], this.transitions.get(modifier)!];
    }

    const defaultTransition = [...this.transitions.entries()].find(
      ([k]) => k.code === undefined,
    )?.[1];
    if (defaultTransition) {
      return [[this as any], defaultTransition];
    }
    const parentNext = this.parent?.next(modifier);
    if (parentNext) {
      return [[this as any, ...parentNext[0]], parentNext[1]];
    }
    return null;
  }

  abstract screen(
    modifier: TransitionModifier,
  ): [PhaseTreeNode[], ScreenTreeNode];
}
