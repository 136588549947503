import {
  BlockType,
  CategoryPreviewDisplay,
  CategoryVariant,
  CreateIatTestInput,
  IatCategoriesInput,
  IatFeedbackType,
  LabeledStimuliPoolInput,
  MediaItemSnapshotScope,
  TestStatus,
  TextAlignment,
} from '../../../../API';
import {
  createDefaultFontStyle,
  createDefaultStyle,
} from '../../testAdmin/testEditor/initialEditorStates';
import { Iat } from '../types';

const createEmptyCategory = (): LabeledStimuliPoolInput => ({
  label: {
    id: '',
    scope: MediaItemSnapshotScope.PRIVATE,
    tags: [],
  },
  practiceStimuliPool: [],
  testStimuliPool: [],
});

const iatCategoriesInput = (): IatCategoriesInput => ({
  fontStyle: createDefaultFontStyle(TextAlignment.CENTER),
  categoryA: createEmptyCategory(),
  categoryB: createEmptyCategory(),
});

const InitialIatCreatorState: CreateIatTestInput = {
  feedback: IatFeedbackType.BLOCKING,
  startLabel: 'Start',
  nextLabel: 'Next',
  finishLabel: 'Finish',
  categoryDivider: {
    fontStyle: createDefaultFontStyle(TextAlignment.CENTER),
    desktop: '',
  },
  name: '',
  instructions: {
    desktop: '',
  },
  description: '',
  blocks: [
    {
      index: 0,
      name: '',
      type: BlockType.NON_PRACTICE,
      amountTrials: 10,
      instructions: {
        desktop: '',
      },
      targetCategoryVariant: CategoryVariant.DEFAULT,
      categoryLabelsInInstructions: false,
    },
  ],
  keyboard: {
    left: '',
    right: '',
    confirm: '',
  },
  targetCategories: iatCategoriesInput(),
  attributeCategories: iatCategoriesInput(),
  probabilityWithoutReplacement: true,
  counterbalancing: true,
  style: createDefaultStyle(),
  interTrialInterval: 250,
  previewCategories: CategoryPreviewDisplay.INSTRUCTIONS,
  status: TestStatus.DRAFT,
};
export const iatTemplates: Iat[] = [
  {
    id: '45aaea1f-f1c1-4632-be67-3795d0b9a8c2',
    name: 'IAT Standard Template ',
    description:
      'This IAT Standard Template is using the target categories BLACK and WHITE and the attribute categories GOOD and BAD.',
    instructions: {
      desktop:
        '<p style="text-align:center;"></p>\n<p style="text-align:center;"><span style="font-size: 24px;font-family: Mulish;">We would like to ask you to take part in a <strong>cognitive test</strong>.</span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Your task is to assign <strong>words </strong>and pictures of <strong>faces</strong> to the categories GOOD or BAD and WHITE or BLACK as quickly as possible.</span></p>\n<p style="text-align:center;"></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Here you can see all words and pictures of faces sorted by category.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">The cognitive test consists of multiple blocks, some are practice blocks and others are test blocks. </span></p>\n<p style="text-align:center;"></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">To begin, please click <strong>Start</strong>.</span></p>\n<p></p>\n',
      mobile:
        '<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">We would like to ask you to participate in a <strong>cognitive test</strong>.</span></p>\n<p style="text-align:center;"><span style="font-size: 16px;font-family: Mulish;">Your task is to assign <strong>words </strong>and pictures of <strong>faces</strong> to the categories GOOD or BAD and WHITE or</span> <span style="font-size: 16px;font-family: Mulish;">BLACK as quickly as possible.</span></p>\n<p style="text-align:center;"><span style="font-size: 16px;font-family: Mulish;">Here you can see all words and pictures of faces sorted by category.</span> <br><span style="font-size: 16px;font-family: Mulish;">The cognitive test consists of multiple blocks, some are practice blocks and others are test blocks. </span>&nbsp;</p>\n<p style="text-align:center;"><span style="font-size: 16px;font-family: Mulish;">To start, please click <strong>Start</strong>. </span></p>\n',
      fontStyle: null,
      __typename: 'StyleableTextTemplate',
    },
    keyboard: {
      left: 'KeyA',
      right: 'KeyL',
      confirm: 'Space',
      __typename: 'KeyboardSettings',
    },
    interTrialInterval: 250,
    counterbalancing: true,
    probabilityWithoutReplacement: true,
    style: {
      maxWidth: 80,
      backgroundColour: 'white',
      stimulusTextStyle: {
        fontSize: 60,
        color: 'black',
        alignment: 'CENTER',
        fontFace: 'ARIAL',
        __typename: 'FontStyle',
      },
      mobileStimulusTextStyle: null,
      optionsPosition: 'MIDDLE',
      optionsPositionMobile: 'MIDDLE',
      __typename: 'TestStyle',
    },
    status: 'DRAFT',
    previewCategories: 'INSTRUCTIONS',
    blocks: [
      {
        index: 0,
        type: 'PRACTICE',
        name: 'Categorize targets',
        amountTrials: 20,
        instructions: {
          desktop:
            '<p style="text-align:center;"></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">In the first block you will see faces of white and black men. </span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Decide as quickly as possible whether the <strong>faces </strong>belong in the <strong>WHITE </strong>or <strong>BLACK </strong>category.</span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">For <strong>#TARGET_LEFT#, </strong>press the <strong>A key</strong>.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">For <strong>#TARGET_RIGHT#</strong>, press the<strong> L key</strong>.</span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">React as quickly as possible and try to make as few mistakes as possible.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">If you make a mistake, a red cross will appear. Correct your reaction by quickly pressing the correct key.</span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Now place your fingers on the <strong>A </strong>and <strong>L keys</strong> and press the <strong>space bar </strong>to start the first block of exercise.</span></p>\n',
          mobile:
            '<p style="text-align:center;"><span style="font-family: Mulish;">In the first block you will see faces of white and black men. <br>Decide as quickly as possible whether the <strong>faces </strong>belong in the <strong>WHITE </strong>or <strong>BLACK </strong>category.</span></p>\n<p style="text-align:center;"><span style="font-family: Mulish;">For <strong>#TARGET_LEFT#</strong>, tap the <strong>left side </strong>of the screen.<br>For <strong>#TARGET_RIGHT#</strong>, tap the <strong>right side </strong>of the screen.</span></p>\n<p style="text-align:center;"><span style="font-family: Mulish;">React as quickly as possible and try to make as few mistakes as possible.<br>If you make a mistake, a red cross will appear. Correct your response by quickly selecting the correct button.</span></p>\n<p style="text-align:center;"><span style="font-family: Mulish;">Now press <strong>Next </strong>to start the first exercise block.</span></p>\n',
          fontStyle: null,
          __typename: 'StyleableTextTemplate',
        },
        categoryLabelsInInstructions: false,
        attributeCategoryVariant: null,
        targetCategoryVariant: 'DEFAULT',
        tips: {
          desktop: '<p>Use the A and L keys to answer.</p>\n',
          mobile: '<p>Click on the left or right side of the screen.</p>\n',
          fontStyle: null,
          __typename: 'StyleableTextTemplate',
        },
        __typename: 'IatBlock',
      },
      {
        index: 1,
        type: 'PRACTICE',
        name: 'Categorize Attributes',
        amountTrials: 20,
        instructions: {
          desktop:
            '<p style="text-align:center;"></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">In the next block, <strong>words</strong> appear in the center of the screen and the categories change to <strong>GOOD</strong> and <strong>BAD</strong>. </span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Your task is to decide whether the words fall into the GOOD or BAD category. </span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">For <strong>#ATTRIBUTE_LEFT#</strong>, press the <strong>A key</strong>.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">For <strong>#ATTRIBUTE_RIGHT#</strong>, press the <strong>L key</strong>.</span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Press the <strong>space bar </strong>to start the block.</span></p>\n',
          mobile:
            '<p style="text-align:center;"><span style="font-size: 16px;font-family: Mulish;">In the next block, <strong>words</strong> appear in the center of the screen and the categories change to <strong>GOOD</strong> and <strong>BAD</strong>.</span><br><span style="font-family: Mulish;">Your task is to decide whether the words fall into the GOOD or BAD category. </span></p>\n<p style="text-align:center;"><span style="font-family: Mulish;">For <strong>#ATTRIBUTE_LEFT#</strong>, tap the <strong>left side of the screen.</strong></span><br><span style="font-family: Mulish;">For<strong> #ATTRIBUTE_RIGHT#</strong>, tap the <strong>right side of the screen</strong>.</span></p>\n<p style="text-align:center;"><span style="font-family: Mulish;">Now press <strong>Next </strong>to start the block.</span></p>\n',
          fontStyle: null,
          __typename: 'StyleableTextTemplate',
        },
        categoryLabelsInInstructions: false,
        attributeCategoryVariant: 'DEFAULT',
        targetCategoryVariant: null,
        tips: null,
        __typename: 'IatBlock',
      },
      {
        index: 2,
        type: 'PRACTICE',
        name: 'Combined Block (Practice)',
        amountTrials: 20,
        instructions: {
          desktop:
            '<p style="text-align:center;"></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Now we start with a practice block that combines the previous tasks. You will see alternating<strong> faces </strong>of white or black men and good or bad <strong>words</strong>.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Respond using the same response keys:</span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">For<strong> #TARGET_LEFT# </strong>and<strong> #ATTRIBUTE_LEFT#</strong>, press the <strong>A key</strong>.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">For <strong>#TARGET_RIGHT#</strong> and<strong> #ATTRIBUTE_RIGHT#</strong>, press the <strong>L key</strong>.</span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">React as quickly as possible and try to make as few mistakes as possible.</span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Press the <strong>space bar</strong> to start the practice block.</span></p>\n',
          mobile:
            '<p style="text-align:center;"><span style="font-family: Mulish;">Now we start with a practice block that combines the previous tasks. You will see alternating<strong> faces </strong>of white or black men and good or bad <strong>words</strong>.<br>Respond using the same pattern:</span></p>\n<p style="text-align:center;"><span style="font-family: Mulish;">For <strong>#TARGET_LEFT#</strong> and <strong>#ATTRIBUTE_LEFT#</strong>, tap the <strong>left side </strong>of the screen.<br>For <strong>#TARGET_RIGHT#</strong> and<strong> #ATTRIBUTE_RIGHT#</strong>, tap the <strong>right side </strong>of the screen.</span></p>\n<p style="text-align:center;"><span style="font-family: Mulish;">React as quickly as possible and try to make as few mistakes as possible.</span></p>\n<p style="text-align:center;"><span style="font-family: Mulish;">Now press <strong>Next </strong>to start the block.</span></p>\n',
          fontStyle: null,
          __typename: 'StyleableTextTemplate',
        },
        categoryLabelsInInstructions: false,
        attributeCategoryVariant: 'DEFAULT',
        targetCategoryVariant: 'DEFAULT',
        tips: null,
        __typename: 'IatBlock',
      },
      {
        index: 3,
        type: 'NON_PRACTICE',
        name: 'Combined Block (Test)',
        amountTrials: 40,
        instructions: {
          desktop:
            '<p style="text-align:center;"></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">The practice block is now finished. The actual task begins.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Respond using the same response keys:</span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">For<strong> #TARGET_LEFT#</strong> and <strong>#ATTRIBUTE_LEFT#</strong>, press the<strong> A key</strong>.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">For <strong>#TARGET_RIGHT#</strong> and<strong> #ATTRIBUTE_RIGHT#</strong>, press the <strong>L key</strong>.</span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">React as quickly as possible and try to make as few mistakes as possible.</span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Press the <strong>space bar</strong> to start the task.</span></p>\n',
          mobile:
            '<p style="text-align:center;"><span style="font-family: Mulish;">The practice block is now finished. The actual task begins.<br>Respond using the same pattern:</span></p>\n<p style="text-align:center;"><span style="font-family: Mulish;">For <strong>#TARGET_LEFT# </strong>and <strong>#ATTRIBUTE_LEFT#</strong>, tap the <strong>left side </strong>of the screen.<br>For <strong>#TARGET_RIGHT#</strong> and <strong>#ATTRIBUTE_RIGHT#</strong>, tap the <strong>right side </strong>of the screen.</span></p>\n<p style="text-align:center;"><span style="font-family: Mulish;">React as quickly as possible and try to make as few mistakes as possible.</span></p>\n<p style="text-align:center;"><span style="font-family: Mulish;">Press <strong>Next </strong>to start the task.</span></p>\n',
          fontStyle: null,
          __typename: 'StyleableTextTemplate',
        },
        categoryLabelsInInstructions: false,
        attributeCategoryVariant: 'DEFAULT',
        targetCategoryVariant: 'DEFAULT',
        tips: null,
        __typename: 'IatBlock',
      },
      {
        index: 4,
        type: 'PRACTICE',
        name: 'Categorize Targets (Reversed)',
        amountTrials: 20,
        instructions: {
          desktop:
            '<p style="text-align:center;"></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">In the next block you will see faces of white and black men again, but the categorization is reversed. </span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Decide as quickly as possible whether the <strong>faces </strong>belong in the <strong>WHITE </strong>or <strong>BLACK </strong>category.</span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">For<strong> #TARGET_LEFT#</strong>, press the <strong>A key</strong>.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">For <strong>#TARGET_RIGHT#</strong>, press the<strong> L key</strong>.</span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Now press the <strong>space bar</strong> to start the block.</span></p>\n',
          mobile:
            '<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">In the next block you will see faces of white and black men again, but the categorisation is reversed. <br>Decideas quickly as possible whether the <strong>faces </strong>belong in the <strong>WHITE </strong>or <strong>BLACK </strong>category.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">For <strong>#TARGET_LEFT#</strong>, tap the <strong>left side </strong>of the screen.<br>For <strong>#TARGET_RIGHT#</strong>, tap the<strong> right side </strong>of the screen.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Press <strong>Next </strong>to start the task.</span></p>\n',
          fontStyle: null,
          __typename: 'StyleableTextTemplate',
        },
        categoryLabelsInInstructions: false,
        attributeCategoryVariant: null,
        targetCategoryVariant: 'REVERSED',
        tips: {
          desktop: '',
          mobile: null,
          fontStyle: null,
          __typename: 'StyleableTextTemplate',
        },
        __typename: 'IatBlock',
      },
      {
        index: 5,
        type: 'PRACTICE',
        name: 'Reversed Combined Block (Practice)',
        amountTrials: 20,
        instructions: {
          desktop:
            '<p style="text-align:center;"></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">In the next block, you will see again alternating <strong>faces</strong> and <strong>words</strong>, but the four categories appear in a new combination. </span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">The assignment of the faces remains the same as in the previous round. </span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">For<strong> #TARGET_LEFT# </strong>and <strong>#ATTRIBUTE_LEFT#</strong>, press the <strong>A key</strong>.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">For<strong> #TARGET_RIGHT#</strong> and <strong>#ATTRIBUTE_RIGHT#</strong>, press the<strong> L key</strong>.</span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">React as quickly as possible and try to make as few mistakes as possible.</span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Press the <strong>space bar</strong> to start the practice block.</span></p>\n',
          mobile:
            '<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">In the next block, you will see again alternating <strong>faces</strong> and <strong>words</strong>, but the four categories appear in a new combination. </span><br><span style="font-size: 18px;font-family: Mulish;">The assignment of the faces remains the same as in the previous round. </span>&nbsp;</p>\n<p style="text-align:center;"><span style="font-family: Mulish;">For <strong>#TARGET_LEFT# </strong>and <strong>#ATTRIBUTE_LEFT#</strong>, tap the <strong>left side </strong>of the screen.<br>For <strong>#TARGET_RIGHT#</strong> and<strong> #ATTRIBUTE_RIGHT#</strong>, tap the <strong>right side</strong> of the screen.</span></p>\n<p style="text-align:center;"><span style="font-family: Mulish;">Press <strong>Next </strong>to start the task.</span></p>\n',
          fontStyle: null,
          __typename: 'StyleableTextTemplate',
        },
        categoryLabelsInInstructions: false,
        attributeCategoryVariant: 'DEFAULT',
        targetCategoryVariant: 'REVERSED',
        tips: null,
        __typename: 'IatBlock',
      },
      {
        index: 6,
        type: 'NON_PRACTICE',
        name: 'Reversed Combined Block (Test)',
        amountTrials: 40,
        instructions: {
          desktop:
            '<p style="text-align:center;"></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">The practice block is now finished. The actual task begins.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Respond using the same response keys:</span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">For<strong> #TARGET_LEFT#</strong> and <strong>#ATTRIBUTE_LEFT#</strong>, press the<strong> A key</strong>.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">For <strong>#TARGET_RIGHT#</strong> and<strong> #ATTRIBUTE_RIGHT#</strong>, press the <strong>L key</strong>.</span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">React as quickly as possible and try to make as few mistakes as possible.</span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Press the <strong>space bar</strong> to start the task. </span></p>\n',
          mobile:
            '<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">The practice block is now finished. The actual task begings now.<br>Respond using the same pattern:</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">For <strong>#TARGET_LEFT# </strong>and <strong>#ATTRIBUTE_LEFT#</strong>, tap the <strong>left side</strong> of the screen.<br>For <strong>#TARGET_RIGHT#</strong> and <strong>#ATTRIBUTE_RIGHT#</strong>, tap the <strong>right side </strong>of the screen.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">React as quickly as possible and try to make as few mistakes as possible.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Press <strong>Next </strong>to start the task.   </span></p>\n',
          fontStyle: null,
          __typename: 'StyleableTextTemplate',
        },
        categoryLabelsInInstructions: false,
        attributeCategoryVariant: 'DEFAULT',
        targetCategoryVariant: 'REVERSED',
        tips: null,
        __typename: 'IatBlock',
      },
    ],
    targetCategories: {
      fontStyle: {
        fontSize: 50,
        color: 'black',
        alignment: 'CENTER',
        fontFace: 'ARIAL',
        __typename: 'FontStyle',
      },
      categoryA: {
        label: {
          id: '1dbe2d4b-8f2a-456a-aa84-4d57b3e23431',
          tags: [],
          scope: 'PRIVATE',
          text: 'WHITE',
          image: null,
          originalFileName: null,
          __typename: 'MediaItemSnapshot',
        },
        practiceStimuliPool: [],
        testStimuliPool: [
          {
            id: '1854aac9-4ba8-4330-bcee-930133b2dbf9',
            tags: [
              {
                id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                name: 'practise',
                __typename: 'TagSnapShot',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
                __typename: 'TagSnapShot',
              },
              {
                id: '05283c5d-417e-4167-8e84-dfa556e6d18e',
                name: 'DPD',
                __typename: 'TagSnapShot',
              },
              {
                id: '7cdf07a9-2720-4002-9c36-b0aef2522acd',
                name: 'Hannah_white',
                __typename: 'TagSnapShot',
              },
              {
                id: '7cdf07a9-2720-4002-9c36-b0aef2522acd',
                name: 'Hannah_white',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 623,
                height: 800,
                key: 'fullSize/956f1c46-8acc-45bb-84bb-61600dbd4349.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/956f1c46-8acc-45bb-84bb-61600dbd4349.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_02_12.jpg',
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '2f84ffc1-a130-4f40-bc45-a2a917af1a80',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
                __typename: 'TagSnapShot',
              },
              {
                id: '7cdf07a9-2720-4002-9c36-b0aef2522acd',
                name: 'Hannah_white',
                __typename: 'TagSnapShot',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 622,
                height: 800,
                key: 'fullSize/cf21d02e-9a14-4216-912d-64c48aff2a84.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/cf21d02e-9a14-4216-912d-64c48aff2a84.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_02_15.jpg',
            __typename: 'MediaItemSnapshot',
          },
          {
            id: 'de38197f-971a-46da-b50f-03c2964a70ac',
            tags: [
              {
                id: '7cdf07a9-2720-4002-9c36-b0aef2522acd',
                name: 'Hannah_white',
                __typename: 'TagSnapShot',
              },
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
                __typename: 'TagSnapShot',
              },
              {
                id: '05283c5d-417e-4167-8e84-dfa556e6d18e',
                name: 'DPD',
                __typename: 'TagSnapShot',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 622,
                height: 800,
                key: 'fullSize/e0254b1c-c5a0-4e52-a6af-d2179936a44b.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/e0254b1c-c5a0-4e52-a6af-d2179936a44b.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_02_03.jpg',
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '19f5c0ef-48e4-49e8-920d-35cec777670c',
            tags: [
              {
                id: '7cdf07a9-2720-4002-9c36-b0aef2522acd',
                name: 'Hannah_white',
                __typename: 'TagSnapShot',
              },
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
                __typename: 'TagSnapShot',
              },
              {
                id: '05283c5d-417e-4167-8e84-dfa556e6d18e',
                name: 'DPD',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 623,
                height: 800,
                key: 'fullSize/44836b84-20e5-46bd-be0c-3f2e2f9f6c98.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/44836b84-20e5-46bd-be0c-3f2e2f9f6c98.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_02_22.jpg',
            __typename: 'MediaItemSnapshot',
          },
          {
            id: 'a5760b79-0849-46e4-b6ee-042528bfde8a',
            tags: [
              {
                id: '7cdf07a9-2720-4002-9c36-b0aef2522acd',
                name: 'Hannah_white',
                __typename: 'TagSnapShot',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
              {
                id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                name: 'practise',
                __typename: 'TagSnapShot',
              },
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
              {
                id: '05283c5d-417e-4167-8e84-dfa556e6d18e',
                name: 'DPD',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 622,
                height: 800,
                key: 'fullSize/c7c7b07f-f543-41b7-a7a6-b6273ff05002.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/c7c7b07f-f543-41b7-a7a6-b6273ff05002.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_02_08.jpg',
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '5ebcbf23-eda6-44d0-a237-dbefedec9677',
            tags: [
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
              {
                id: '7cdf07a9-2720-4002-9c36-b0aef2522acd',
                name: 'Hannah_white',
                __typename: 'TagSnapShot',
              },
              {
                id: '7cdf07a9-2720-4002-9c36-b0aef2522acd',
                name: 'Hannah_white',
                __typename: 'TagSnapShot',
              },
              {
                id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                name: 'practise',
                __typename: 'TagSnapShot',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
                __typename: 'TagSnapShot',
              },
              {
                id: '05283c5d-417e-4167-8e84-dfa556e6d18e',
                name: 'DPD',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 623,
                height: 800,
                key: 'fullSize/8bcbe90e-e21f-4903-8cdc-c6c51b0403bd.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/8bcbe90e-e21f-4903-8cdc-c6c51b0403bd.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_02_16.jpg',
            __typename: 'MediaItemSnapshot',
          },
          {
            id: 'ac08b178-5448-4a7d-a151-4b76fa20ee50',
            tags: [
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
              {
                id: '7cdf07a9-2720-4002-9c36-b0aef2522acd',
                name: 'Hannah_white',
                __typename: 'TagSnapShot',
              },
              {
                id: '05283c5d-417e-4167-8e84-dfa556e6d18e',
                name: 'DPD',
                __typename: 'TagSnapShot',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 623,
                height: 800,
                key: 'fullSize/2498382b-30dc-4b69-8bf0-1b8a179a9832.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/2498382b-30dc-4b69-8bf0-1b8a179a9832.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_02_13.jpg',
            __typename: 'MediaItemSnapshot',
          },
          {
            id: 'd41a9ff1-59f3-4031-becb-52dcfec59454',
            tags: [
              {
                id: '7cdf07a9-2720-4002-9c36-b0aef2522acd',
                name: 'Hannah_white',
                __typename: 'TagSnapShot',
              },
              {
                id: '05283c5d-417e-4167-8e84-dfa556e6d18e',
                name: 'DPD',
                __typename: 'TagSnapShot',
              },
              {
                id: 'e9ee3266-ed81-40cd-a745-17cf84b9fea7',
                name: 'practise',
                __typename: 'TagSnapShot',
              },
              {
                id: '7cdf07a9-2720-4002-9c36-b0aef2522acd',
                name: 'Hannah_white',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 623,
                height: 800,
                key: 'fullSize/f3dff8d2-c383-4000-a533-331c7289903a.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/f3dff8d2-c383-4000-a533-331c7289903a.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_02_02.jpg',
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '56e0a0d6-9ff3-4737-8f96-ed8f589f2267',
            tags: [
              {
                id: '7cdf07a9-2720-4002-9c36-b0aef2522acd',
                name: 'Hannah_white',
                __typename: 'TagSnapShot',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
                __typename: 'TagSnapShot',
              },
              {
                id: '05283c5d-417e-4167-8e84-dfa556e6d18e',
                name: 'DPD',
                __typename: 'TagSnapShot',
              },
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 622,
                height: 800,
                key: 'fullSize/2ec6077a-865b-493e-ac89-b511f11ae87f.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/2ec6077a-865b-493e-ac89-b511f11ae87f.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_02_07.jpg',
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '41f8cd04-6343-4209-bf51-6c69de4107f9',
            tags: [
              {
                id: '7cdf07a9-2720-4002-9c36-b0aef2522acd',
                name: 'Hannah_white',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
              {
                id: '05283c5d-417e-4167-8e84-dfa556e6d18e',
                name: 'DPD',
                __typename: 'TagSnapShot',
              },
              {
                id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                name: 'Group 2',
                __typename: 'TagSnapShot',
              },
              {
                id: '2069f530-853b-452c-9396-3efd20ff5df7',
                name: 'Set2',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 623,
                height: 800,
                key: 'fullSize/1a2c4aff-234f-463f-976a-f6e19747fccb.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/1a2c4aff-234f-463f-976a-f6e19747fccb.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_02_25.jpg',
            __typename: 'MediaItemSnapshot',
          },
        ],
        __typename: 'LabeledStimuliPool',
      },
      categoryB: {
        label: {
          id: '4077b66e-b253-4af0-8f3f-d5c0737b4a4d',
          tags: [],
          scope: 'PRIVATE',
          text: 'BLACK',
          image: null,
          originalFileName: null,
          __typename: 'MediaItemSnapshot',
        },
        practiceStimuliPool: [],
        testStimuliPool: [
          {
            id: '95389de9-75f7-4849-889c-ddd5536d0e59',
            tags: [
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
              {
                id: '19dcefdd-8796-4748-b11c-9457ff3e3741',
                name: 'Hannah_black',
                __typename: 'TagSnapShot',
              },
              {
                id: '05283c5d-417e-4167-8e84-dfa556e6d18e',
                name: 'DPD',
                __typename: 'TagSnapShot',
              },
              {
                id: 'efffd293-30e2-4470-9781-70352bedfb50',
                name: 'group_03',
                __typename: 'TagSnapShot',
              },
              {
                id: 'd839f999-2c8b-46aa-8ea5-0fde4834fa09',
                name: 'Black',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 623,
                height: 800,
                key: 'fullSize/0c1312e7-442c-4adf-9740-cf6416505fec.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/0c1312e7-442c-4adf-9740-cf6416505fec.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_03_20.jpg',
            __typename: 'MediaItemSnapshot',
          },
          {
            id: 'f672f542-8ea4-41fe-a2ae-9aa44d0e3508',
            tags: [
              {
                id: 'efffd293-30e2-4470-9781-70352bedfb50',
                name: 'group_03',
                __typename: 'TagSnapShot',
              },
              {
                id: '05283c5d-417e-4167-8e84-dfa556e6d18e',
                name: 'DPD',
                __typename: 'TagSnapShot',
              },
              {
                id: '19dcefdd-8796-4748-b11c-9457ff3e3741',
                name: 'Hannah_black',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
              {
                id: 'd839f999-2c8b-46aa-8ea5-0fde4834fa09',
                name: 'Black',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 622,
                height: 800,
                key: 'fullSize/3ef64047-c611-48b1-8a53-42fc976bf14d.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/3ef64047-c611-48b1-8a53-42fc976bf14d.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_03_12.jpg',
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '2f1f9ecf-85c1-4e37-a0d9-75d99f49b5c9',
            tags: [
              {
                id: 'd839f999-2c8b-46aa-8ea5-0fde4834fa09',
                name: 'Black',
                __typename: 'TagSnapShot',
              },
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
              {
                id: 'efffd293-30e2-4470-9781-70352bedfb50',
                name: 'group_03',
                __typename: 'TagSnapShot',
              },
              {
                id: '19dcefdd-8796-4748-b11c-9457ff3e3741',
                name: 'Hannah_black',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
              {
                id: '05283c5d-417e-4167-8e84-dfa556e6d18e',
                name: 'DPD',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 623,
                height: 800,
                key: 'fullSize/84f4252d-2fb0-49e1-85aa-c18f7a23d46f.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/84f4252d-2fb0-49e1-85aa-c18f7a23d46f.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_03_15.jpg',
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '39faba31-3d76-4ce2-8d7a-a8c96b615e03',
            tags: [
              {
                id: '19dcefdd-8796-4748-b11c-9457ff3e3741',
                name: 'Hannah_black',
                __typename: 'TagSnapShot',
              },
              {
                id: 'd839f999-2c8b-46aa-8ea5-0fde4834fa09',
                name: 'Black',
                __typename: 'TagSnapShot',
              },
              {
                id: 'efffd293-30e2-4470-9781-70352bedfb50',
                name: 'group_03',
                __typename: 'TagSnapShot',
              },
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
              {
                id: '05283c5d-417e-4167-8e84-dfa556e6d18e',
                name: 'DPD',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 622,
                height: 800,
                key: 'fullSize/ddc89cbe-f7f4-4682-8452-f98e2541ff4d.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/ddc89cbe-f7f4-4682-8452-f98e2541ff4d.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_03_08.jpg',
            __typename: 'MediaItemSnapshot',
          },
          {
            id: 'f5b5b1e7-a208-4f3a-a459-cfce21f53166',
            tags: [
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
              {
                id: '05283c5d-417e-4167-8e84-dfa556e6d18e',
                name: 'DPD',
                __typename: 'TagSnapShot',
              },
              {
                id: 'efffd293-30e2-4470-9781-70352bedfb50',
                name: 'group_03',
                __typename: 'TagSnapShot',
              },
              {
                id: '19dcefdd-8796-4748-b11c-9457ff3e3741',
                name: 'Hannah_black',
                __typename: 'TagSnapShot',
              },
              {
                id: 'd839f999-2c8b-46aa-8ea5-0fde4834fa09',
                name: 'Black',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 622,
                height: 800,
                key: 'fullSize/8479e1e6-f160-4488-aa77-a01252983166.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/8479e1e6-f160-4488-aa77-a01252983166.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_03_18.jpg',
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '9847f645-8743-4a11-944a-a7b113024259',
            tags: [
              {
                id: 'd839f999-2c8b-46aa-8ea5-0fde4834fa09',
                name: 'Black',
                __typename: 'TagSnapShot',
              },
              {
                id: 'efffd293-30e2-4470-9781-70352bedfb50',
                name: 'group_03',
                __typename: 'TagSnapShot',
              },
              {
                id: '19dcefdd-8796-4748-b11c-9457ff3e3741',
                name: 'Hannah_black',
                __typename: 'TagSnapShot',
              },
              {
                id: '05283c5d-417e-4167-8e84-dfa556e6d18e',
                name: 'DPD',
                __typename: 'TagSnapShot',
              },
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 622,
                height: 800,
                key: 'fullSize/2eb8df16-990a-4525-a7de-ba691ff3ad6a.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/2eb8df16-990a-4525-a7de-ba691ff3ad6a.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_03_25.jpg',
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '62992be6-0cdc-4482-a418-d6d2d81114ff',
            tags: [
              {
                id: 'd839f999-2c8b-46aa-8ea5-0fde4834fa09',
                name: 'Black',
                __typename: 'TagSnapShot',
              },
              {
                id: 'efffd293-30e2-4470-9781-70352bedfb50',
                name: 'group_03',
                __typename: 'TagSnapShot',
              },
              {
                id: '05283c5d-417e-4167-8e84-dfa556e6d18e',
                name: 'DPD',
                __typename: 'TagSnapShot',
              },
              {
                id: '19dcefdd-8796-4748-b11c-9457ff3e3741',
                name: 'Hannah_black',
                __typename: 'TagSnapShot',
              },
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 622,
                height: 800,
                key: 'fullSize/4bcc1fd7-6d5d-4bbd-ae10-88e032951b9b.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/4bcc1fd7-6d5d-4bbd-ae10-88e032951b9b.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_03_19.jpg',
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '65deb3dc-681e-4c9f-8532-9b95495c6658',
            tags: [
              {
                id: 'efffd293-30e2-4470-9781-70352bedfb50',
                name: 'group_03',
                __typename: 'TagSnapShot',
              },
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
              {
                id: 'd839f999-2c8b-46aa-8ea5-0fde4834fa09',
                name: 'Black',
                __typename: 'TagSnapShot',
              },
              {
                id: '05283c5d-417e-4167-8e84-dfa556e6d18e',
                name: 'DPD',
                __typename: 'TagSnapShot',
              },
              {
                id: '19dcefdd-8796-4748-b11c-9457ff3e3741',
                name: 'Hannah_black',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 622,
                height: 800,
                key: 'fullSize/e23c6806-624f-421b-97c3-9ead83d89801.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/e23c6806-624f-421b-97c3-9ead83d89801.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_03_05.jpg',
            __typename: 'MediaItemSnapshot',
          },
          {
            id: 'ba9b7f46-d8c8-42ef-848e-8390d4986816',
            tags: [
              {
                id: 'd839f999-2c8b-46aa-8ea5-0fde4834fa09',
                name: 'Black',
                __typename: 'TagSnapShot',
              },
              {
                id: '05283c5d-417e-4167-8e84-dfa556e6d18e',
                name: 'DPD',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
              {
                id: '19dcefdd-8796-4748-b11c-9457ff3e3741',
                name: 'Hannah_black',
                __typename: 'TagSnapShot',
              },
              {
                id: 'efffd293-30e2-4470-9781-70352bedfb50',
                name: 'group_03',
                __typename: 'TagSnapShot',
              },
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 623,
                height: 800,
                key: 'fullSize/d40034a3-af83-4736-8560-e6be1d3555ae.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/d40034a3-af83-4736-8560-e6be1d3555ae.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_03_16.jpg',
            __typename: 'MediaItemSnapshot',
          },
          {
            id: 'c6193793-9b88-48aa-a78b-07853c7417b7',
            tags: [
              {
                id: 'efffd293-30e2-4470-9781-70352bedfb50',
                name: 'group_03',
                __typename: 'TagSnapShot',
              },
              {
                id: 'd839f999-2c8b-46aa-8ea5-0fde4834fa09',
                name: 'Black',
                __typename: 'TagSnapShot',
              },
              {
                id: '05283c5d-417e-4167-8e84-dfa556e6d18e',
                name: 'DPD',
                __typename: 'TagSnapShot',
              },
              {
                id: '19dcefdd-8796-4748-b11c-9457ff3e3741',
                name: 'Hannah_black',
                __typename: 'TagSnapShot',
              },
              {
                id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                name: 'Male',
                __typename: 'TagSnapShot',
              },
              {
                id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                name: 'Face',
                __typename: 'TagSnapShot',
              },
            ],
            scope: 'PUBLIC',
            text: null,
            image: {
              fullSize: {
                width: 623,
                height: 800,
                key: 'fullSize/34f6e18d-fedd-4098-a825-d13a3b45ac02.jpg',
                __typename: 'ImageInstance',
              },
              thumbNail: {
                width: 62,
                height: 80,
                key: 'thumbNail/34f6e18d-fedd-4098-a825-d13a3b45ac02.jpg',
                __typename: 'ImageInstance',
              },
              __typename: 'Image',
            },
            originalFileName: 'dpd_fa_ma_03_09.jpg',
            __typename: 'MediaItemSnapshot',
          },
        ],
        __typename: 'LabeledStimuliPool',
      },
      __typename: 'IatCategories',
    },
    categoryDivider: {
      desktop: 'OR',
      mobile: null,
      fontStyle: {
        fontSize: 18,
        color: 'black',
        alignment: 'CENTER',
        fontFace: 'ARIAL',
        __typename: 'FontStyle',
      },
      __typename: 'StyleableTextTemplate',
    },
    attributeCategories: {
      fontStyle: {
        fontSize: 50,
        color: 'black',
        alignment: 'CENTER',
        fontFace: 'ARIAL',
        __typename: 'FontStyle',
      },
      categoryA: {
        label: {
          id: '8621e03f-f680-4b51-8bac-ef844dc1ea43',
          tags: [],
          scope: 'PRIVATE',
          text: 'GOOD',
          image: null,
          originalFileName: null,
          __typename: 'MediaItemSnapshot',
        },
        practiceStimuliPool: [],
        testStimuliPool: [
          {
            id: '3782da0e-989e-4963-ad82-47fe3ab391ce',
            tags: [],
            scope: 'PRIVATE',
            text: 'happy',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
          {
            id: 'abefae15-437b-4609-b633-6a624b873f9d',
            tags: [],
            scope: 'PRIVATE',
            text: 'peace',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '7374e625-e25c-43f3-a6a0-1d3117f51ff4',
            tags: [],
            scope: 'PRIVATE',
            text: 'pleasure',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
          {
            id: 'cb703995-fe70-4c7a-ac0d-005e8c528e36',
            tags: [],
            scope: 'PRIVATE',
            text: 'splendid',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '53938421-891d-46a7-8c84-2e9777d7c492',
            tags: [],
            scope: 'PRIVATE',
            text: 'love',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
          {
            id: 'b36fc2b7-6018-4d6a-a43a-3db1047eea37',
            tags: [],
            scope: 'PRIVATE',
            text: 'laughter',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '29753963-66c4-4718-ba14-bf655a98be9f',
            tags: [],
            scope: 'PRIVATE',
            text: 'joy',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '10cec68d-17fc-40c9-bd40-9afd90f2300e',
            tags: [],
            scope: 'PRIVATE',
            text: 'wonderful',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '18c808e7-7065-4e0a-b1f2-37151ccae4fd',
            tags: [],
            scope: 'PRIVATE',
            text: 'relaxation',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '1ffc6fa0-7cf3-4973-a5f3-068e4b99ea9c',
            tags: [],
            scope: 'PRIVATE',
            text: 'fun',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
        ],
        __typename: 'LabeledStimuliPool',
      },
      categoryB: {
        label: {
          id: '20bc6640-9b76-46d9-95eb-b59df904615c',
          tags: [],
          scope: 'PRIVATE',
          text: 'BAD',
          image: null,
          originalFileName: null,
          __typename: 'MediaItemSnapshot',
        },
        practiceStimuliPool: [],
        testStimuliPool: [
          {
            id: '87edad6e-dda7-47e5-8cd8-95dc10c7eb82',
            tags: [],
            scope: 'PRIVATE',
            text: 'agony',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '9b74914d-f056-41b8-8ae8-71d3935309e8',
            tags: [],
            scope: 'PRIVATE',
            text: 'hurt',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
          {
            id: 'ea705667-b435-44e4-9732-8bfd468700c0',
            tags: [],
            scope: 'PRIVATE',
            text: 'failure',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '60d634d6-6ea8-42d5-8282-5ac3d206d13a',
            tags: [],
            scope: 'PRIVATE',
            text: 'malicious',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
          {
            id: 'f108ab9d-0f42-475b-84eb-c633ac7a68b3',
            tags: [],
            scope: 'PRIVATE',
            text: 'evil',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '86dd5baf-b9bf-4a15-b176-348263a4e470',
            tags: [],
            scope: 'PRIVATE',
            text: 'terrible',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '4533b121-b7ff-491e-bc34-083204a1b2e6',
            tags: [],
            scope: 'PRIVATE',
            text: 'cruel',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
          {
            id: '75c4504a-b2bc-4f8d-a972-19258fb1a602',
            tags: [],
            scope: 'PRIVATE',
            text: 'horrible',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
          {
            id: 'b728dc99-49fe-4c70-b378-aa15587d9fea',
            tags: [],
            scope: 'PRIVATE',
            text: 'panic',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
          {
            id: 'c1a385da-1ce4-4e05-86c7-e83d092c9023',
            tags: [],
            scope: 'PRIVATE',
            text: 'fight',
            image: null,
            originalFileName: null,
            __typename: 'MediaItemSnapshot',
          },
        ],
        __typename: 'LabeledStimuliPool',
      },
      __typename: 'IatCategories',
    },
    endScreen: {
      desktop:
        '<p style="text-align:center;"></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 24px;font-family: Mulish;"><strong>Thank you. </strong></span></p>\n<p></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">The cognitive test is now complete.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Please press <strong>Next</strong>.</span></p>\n',
      mobile:
        '<p style="text-align:center;"></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;"><strong>Thank you. </strong></span></p>\n<p style="text-align:center;"><span style="font-size: 16px;font-family: Mulish;">The cognitive test is now complete.</span></p>\n<p style="text-align:center;"><span style="font-size: 16px;font-family: Mulish;">Please press <strong>Next</strong>.</span>&nbsp;</p>\n',
      fontStyle: null,
      __typename: 'StyleableTextTemplate',
    },
    startLabel: 'Start',
    nextLabel: 'Next',
    finishLabel: 'Next',
    feedback: 'FEEDBACK_BLOCKING',
    owner: '7d53cdab-115d-4356-a3fe-b772a0c5be89',
    editors: [],
    viewers: [],
    resultViewers: [],
    createdAt: '2024-01-10T14:21:17.687Z',
    updatedAt: '2024-02-22T08:54:02.132Z',
    __typename: 'IatTest',
  } as Iat,
];
export default InitialIatCreatorState;
