import { useField } from 'formik';
import {
  SetupDocumentationStepType,
  SurveyProviderSetupDocumentationInput,
  SurveyProviderSetupDocumentationStepInput,
} from '../../API';
import {
  Avatar,
  Box,
  Button,
  ButtonProps,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from '@mui/lab';
import { SurveyProviderDocumentationStepInput } from './SurveyProviderDocumentationStepInput';
import { Add, Delete } from '@mui/icons-material';
import { useState } from 'react';

const documentationStepTypeMap: Record<
  SetupDocumentationStepType,
  { name: string; template: SurveyProviderSetupDocumentationStepInput }
> = {
  IMAGE: {
    name: 'Instruction Step',
    template: {
      textAfterContent: '',
      textBeforeContent: '',
      image: null,
      type: SetupDocumentationStepType.IMAGE,
    },
  },
  SNIPPET: {
    name: 'Instruction Step with code snippet',
    template: {
      textAfterContent: '',
      textBeforeContent: '',
      image: null,
      type: SetupDocumentationStepType.SNIPPET,
    },
  },
};

function DocumentStepTypeMenu({
  buttonProps,
  addStep,
}: {
  addStep: (input: SurveyProviderSetupDocumentationStepInput) => void;
  buttonProps: Partial<ButtonProps>;
}) {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>();

  return (
    <>
      <Button
        {...buttonProps}
        onClick={(evt) => setMenuAnchor(evt.currentTarget)}
      />
      <Menu
        open={!!menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorEl={menuAnchor}
      >
        {Object.values(documentationStepTypeMap).map(({ name, template }) => (
          <MenuItem
            onClick={() => {
              addStep(template);
              setMenuAnchor(null);
            }}
          >
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export function SurveyProviderDocumentationStepsContainer() {
  const stepFieldName = `setupDocumentation`;
  const [, meta, helpers] =
    useField<SurveyProviderSetupDocumentationInput>(stepFieldName);

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {meta.value.steps.map((_, i) => {
        return (
          <TimelineItem>
            <TimelineSeparator>
              <Avatar>{i + 1}</Avatar>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Stack spacing={2}>
                <Stack direction="row">
                  <Typography variant="h5">{`Step ${i + 1}`}</Typography>{' '}
                  <IconButton
                    onClick={() => {
                      helpers.setValue({
                        ...meta.value,
                        steps: meta.value.steps.filter((_, si) => si !== i),
                      });
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Stack>
                <Box sx={{ paddingLeft: '0.5em' }}>
                  <SurveyProviderDocumentationStepInput stepIndex={i} />
                </Box>
              </Stack>
            </TimelineContent>
          </TimelineItem>
        );
      })}
      <TimelineItem>
        <TimelineSeparator>
          <Avatar>
            <Add />
          </Avatar>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <DocumentStepTypeMenu
            addStep={(step) =>
              helpers.setValue({
                ...meta.value,
                steps: [...meta.value.steps, step],
              })
            }
            buttonProps={{ startIcon: <Add />, children: 'Add Step' }}
          />
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
