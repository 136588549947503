import {
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { Avatar } from '@mui/material';
import React from 'react';

export function SetupStepTimelineItem({
  stepIndex,
  stepContent,
}: {
  stepIndex: number;
  stepContent: React.ReactElement;
}) {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <Avatar>{stepIndex + 1}</Avatar>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ mb: 4 }}>{stepContent}</TimelineContent>
    </TimelineItem>
  );
}
