import {
  createAsyncAppThunk,
  TestResultsThunkOutput,
} from '../../../../app/redux/thunk';
import GQL from '../../../../GQL';
import {
  getPodtaTest,
  listPodtaTests,
  podtaResultsByTestId,
} from '../../../../graphql/queries';
import {
  createPodtaTest,
  deletePodtaResult,
  deleteTest,
  updatePodtaTest,
} from '../../../../graphql/mutations';
import {
  CreatePodtaTestInput,
  CreatePodtaTestMutationVariables,
  DeletePodtaResultMutationVariables,
  DeleteTestMutationVariables,
  FunctionBodyResponse,
  GetPodtaTestQueryVariables,
  PodtaResultsByTestIdQueryVariables,
  TestStatus,
  UpdatePodtaTestInput,
  UpdatePodtaTestMutationVariables,
} from '../../../../API';
import { removeKeysDeep } from '../../testAdmin/utils';
import { Podta, PodtaResult } from '../types';

export const fetchPodtaTest = createAsyncAppThunk<Podta, string>(
  'podta/get',
  (id) => GQL.get<GetPodtaTestQueryVariables, Podta>(getPodtaTest, { id }),
);

export const fetchPodtaTests = createAsyncAppThunk('podta/list', () =>
  GQL.list<Podta>(listPodtaTests),
);

export const fetchPodtaResults = createAsyncAppThunk<
  TestResultsThunkOutput<PodtaResult>,
  { testId: string }
>('podta/results', async ({ testId }) => {
  const results = await GQL.list<
    PodtaResult,
    PodtaResultsByTestIdQueryVariables
  >(podtaResultsByTestId, { testId });
  return { testId, results };
});

export const updatePodtaAsync = createAsyncAppThunk<
  Podta,
  UpdatePodtaTestInput
>('podta/update', (input) =>
  GQL.mutate<Podta, UpdatePodtaTestMutationVariables>(updatePodtaTest, {
    input: { ...(removeKeysDeep(input, 'tableData') as UpdatePodtaTestInput) },
  }),
);

export const updatePodtaStatusAsync = createAsyncAppThunk<
  Podta,
  { testId: string; status: TestStatus }
>('podta/update/status', ({ status, testId }) =>
  GQL.mutate<Podta, UpdatePodtaTestMutationVariables>(updatePodtaTest, {
    input: { id: testId, status },
  }),
);

export const createPodtaAsync = createAsyncAppThunk<
  Podta,
  CreatePodtaTestInput
>('podta/create', (arg) => {
  return GQL.mutate<Podta, CreatePodtaTestMutationVariables>(createPodtaTest, {
    input: arg,
  });
});

export const deletePodtaResultsAsync = createAsyncAppThunk<
  { testId: string; resultsIds: string[] },
  { testId: string; resultsIds: string[] }
>('podta/delete/results', async (arg) => {
  await Promise.all(
    arg.resultsIds.map((id) =>
      GQL.mutate<PodtaResult, DeletePodtaResultMutationVariables>(
        deletePodtaResult,
        { input: { id } },
      ),
    ),
  );
  return arg;
});

export const deletePodtaAsync = createAsyncAppThunk<string, string>(
  'podta/delete',
  async (testId) => {
    await GQL.mutate<FunctionBodyResponse, DeleteTestMutationVariables>(
      deleteTest,
      {
        testId,
        testType: 'PODTA',
      },
    );
    return testId;
  },
);
