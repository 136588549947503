import { ContainerScaler } from './container-scaler';
import { SizeState } from './size-state';
import {
  DefaultMeasureContainerProvider,
  MeasureContainerProvider,
} from '../../dom-measure/measure-container-provider';
import { SizedArea } from '../../../media/drawable-dimensions';
import { View } from '../views/view';
import { ViewInstanceController } from '../screen-instance/view-instance-controller';
import {
  ContainerProvider,
  DefaultRenderContainerProvider,
} from '../views/render-container-provider';

import { MeasurePhases } from '../../measure-phases';

export class DefaultContainerScaler implements ContainerScaler {
  constructor(
    public code: string,
    protected sizeState: SizeState,
    protected mode: 'fixed' | 'growing',
  ) {}

  createMeasureProvider(phase: MeasurePhases): MeasureContainerProvider {
    const styleMap = {
      fixed: {
        min: () => ({
          ...SizedArea.dimPx(this.sizeState.maxSize).toSizeModeStyle('max'),
        }),
        inner: () => ({
          ...SizedArea.dimPx(this.sizeState.maxSize).toSizeModeStyle('max'),
        }),
        size: () => ({
          ...this.sizeState.innerSize.toSizeModeStyle(),
        }),
      },
      growing: {
        min: () => ({
          ...SizedArea.dimPx(this.sizeState.maxSize).toSizeModeStyle('max'),
        }),
        inner: () => ({
          ...SizedArea.dimPx(this.sizeState.maxSize).toSizeModeStyle('max'),
        }),
        size: () => ({
          ...this.sizeState.innerSize.toSizeModeStyle(),
        }),
      },
    };
    return new DefaultMeasureContainerProvider(
      this.code,
      phase,
      styleMap[this.mode][phase](),
    );
  }

  createRenderProvider(
    viewControllerMap: Map<View, ViewInstanceController>,
  ): ContainerProvider {
    return new DefaultRenderContainerProvider(
      this.code,
      this.sizeState.innerSize,
    );
  }
}
