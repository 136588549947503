import React, { useMemo } from 'react';
import { BaseTest, TestResult } from '../types';
import { TestType } from '../../subject/types';
import { TestStatus } from '../../../API';
import { TestResultBaseProps } from './TestResults';
import { Route, Routes } from 'react-router-dom';
import { TestStep } from './testEditor/types';
import { TestList } from './testList/TestList';
import { TestDetails } from './testDetails/TestDetails';
import { TestThunks } from './types';
import { TestEditor } from './testEditor/TestEditor';

interface TestAdminProps<T, C, U, R extends TestResult> {
  templates: T[];
  type: TestType;
  thunks: TestThunks<T, C, U>;
  steps: TestStep[];
  editor: {
    initialState: C;
  };
  results: TestResultBaseProps<R>;
}

function TestAdmin<
  T extends BaseTest,
  C extends { status: TestStatus; id?: string | null },
  U,
  R extends TestResult,
>(props: TestAdminProps<T, C, U, R>): JSX.Element {
  const testList = useMemo(
    () => (
      <TestList
        type={props.type}
        templates={props.templates}
        loadTestsThunk={props.thunks.loadTests}
        deleteTestThunk={props.thunks.deleteTest}
      />
    ),
    [
      props.templates,
      props.thunks.deleteTest,
      props.thunks.loadTests,
      props.type,
    ],
  );

  return (
    <Routes>
      <Route index element={testList} />
      <Route path={`/tests`} element={testList} />
      <Route
        path={`/:testId`}
        element={
          <TestDetails
            type={props.type}
            steps={props.steps}
            thunks={props.thunks}
          />
        }
      ></Route>
      <Route
        path={`/:testId/editor/:mode`}
        element={
          <TestEditor
            testType={props.type}
            thunks={props.thunks}
            steps={props.steps}
          />
        }
      />
    </Routes>
  );
}

export default TestAdmin;
