import { CategoryVariant, TextAlignment } from '../../../../../API';
import { Iat } from '../../types';
import {
  createCounterBalancingArranger,
  StandardOptionArranger,
  TestOptionCategories,
} from '../../../../subject/testRunner/media/options-test';
import { TestResourceAccessor } from '../../../../subject/testRunner/media/test-resource-loader';
import { TextStyleDescriptor } from '../../../../subject/testRunner/media/text-drawer';
import { createDefaultFontStyle } from '../../../testAdmin/testEditor/initialEditorStates';
import { IatMediaPool } from '../loading/load-iat-media';
import { IatOptionsType } from './iat-options-type';
import { IatBlockConfig } from './helper-types';
import { IatPicker } from './iat-picker';
import {
  describeIatBlock,
  IatBlockDescriptor,
  IatBlockPhase,
  prepareIatBlockTexts,
} from './iat-block-structure';
import {
  determineCounterBalancingGroup,
  determineLROptionsTestPickingStrategy,
  extractDeviceOptionsPosition,
  extractTextContent,
  TestInstructionsPhase,
  optionCategoryBuilder,
} from '../../../../subject/testRunner/utils/structure-utils';

export interface IatStructure {
  accessor: TestResourceAccessor<Iat>;
  pool: IatMediaPool;
  options: TestOptionCategories<IatOptionsType>;
  welcomePhase: TestInstructionsPhase<{
    categoryPreview: 'none' | 'on-welcome' | 'single';
  }>;
  blockPhases: IatBlockPhase[];
  endPhase: TestInstructionsPhase;
}

function creatIatOptionsArrangment(
  accessor: TestResourceAccessor<Iat>,
  pool: IatMediaPool,
) {
  const cbGroup = determineCounterBalancingGroup(
    accessor.modelData.counterbalancing,
  );
  const arranger = createCounterBalancingArranger(cbGroup);

  return new TestOptionCategories(
    {
      attribute: optionCategoryBuilder(
        'attribute',
        pool.test.options.categories.attribute,
        new StandardOptionArranger(CategoryVariant.DEFAULT),
      ),
      target: optionCategoryBuilder(
        'target',
        pool.test.options.categories.target,
        arranger,
      ),
    },
    {
      horizontal: 'out',
      vertical: extractDeviceOptionsPosition(
        accessor.testContext,
        accessor.modelData.style,
      ),
    },
    arranger as StandardOptionArranger,
    pool.test.options.divider.value,
  );
}

export function createIatStructure(
  accessor: TestResourceAccessor<Iat>,
  pool: IatMediaPool,
): IatStructure {
  const iatOptions = creatIatOptionsArrangment(accessor, pool);

  const iatPicker = new IatPicker(
    determineLROptionsTestPickingStrategy(
      accessor.modelData.probabilityWithoutReplacement,
    ),
    iatOptions,
    pool.test.presentations,
  );

  function createIatBlockPhase(block: IatBlockConfig): IatBlockPhase {
    const blockDescriptor: IatBlockDescriptor = describeIatBlock(
      iatOptions,
      block,
    );
    const trials = iatPicker.pickBlock(blockDescriptor);
    const instructionTexts = prepareIatBlockTexts(
      accessor.testContext,
      block,
      blockDescriptor,
    );

    return {
      blockDescriptor,
      trialStimuli: trials as any,
      instructions: {
        welcomeScreenContent: {
          content: instructionTexts.welcomeScreenContent as string,
          nextButton:
            accessor.testContext.deviceInfo.mode === 'mobile'
              ? accessor.modelData.nextLabel
              : null,
          withOptions: block.categoryLabelsInInstructions,
        },
        tips: block.tips
          ? {
              text: instructionTexts.tips ?? '',
              style: accessor.testContext.logic.deriveTextStyle(
                TextStyleDescriptor.ofFontStyle(
                  block.tips.fontStyle ??
                    createDefaultFontStyle(TextAlignment.CENTER),
                ),
              ),
            }
          : null,
      },
    };
  }

  return {
    accessor,
    pool,
    options: iatOptions,
    welcomePhase: {
      content: {
        content: extractTextContent(
          accessor.testContext,
          accessor.modelData.instructions,
        ),
        nextButton: accessor.modelData.startLabel ?? 'Start',
      },
      categoryPreview:
        accessor.modelData.previewCategories === 'NONE'
          ? 'none'
          : accessor.modelData.previewCategories === 'STAND_ALONE' ||
            accessor.testContext.deviceInfo.mode === 'mobile'
          ? 'single'
          : 'on-welcome',
    },
    blockPhases: accessor.modelData.blocks.map(createIatBlockPhase),
    endPhase: {
      content: {
        content: extractTextContent(
          accessor.testContext,
          accessor.modelData.endScreen,
        ),
        nextButton: accessor.modelData.finishLabel ?? 'Finish',
      },
    },
  };
}
