import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  TextField,
} from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import { roles } from '../../auth/user-role';
import { GQLQuery, NewGQL } from '../../GQL';
import { AdminAddUserMutationVariables, FunctionBodyResponse } from '../../API';
import { adminAddUser } from '../../graphql/mutations';
import { Add } from '@mui/icons-material';

function createUserMutation(email: string, groups: string[]) {
  return NewGQL.DEFAULT_CLIENT.execute(
    GQLQuery.Mutate<AdminAddUserMutationVariables, FunctionBodyResponse>(
      adminAddUser,
    ).create({
      email,
      groups,
    }),
  );
}

export function useCreateUser() {
  const [loading, setLoading] = useState(false);
  const createUser = async (email: string, groups: string[]) => {
    setLoading(true);
    await createUserMutation(email, groups);
    setLoading(false);
  };
  return { createUser, loading };
}

export const CreateUserDialog: FC<{ onSuccess: () => void }> = ({
  onSuccess,
}) => {
  const [open, setOpen] = useState(false);
  const { createUser, loading } = useCreateUser();

  const [newUser, setNewUser] = useState({
    email: '',
    groups: ['intern'],
  });

  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) =>
    setNewUser({ ...newUser, email: event.target.value });

  const onChangeGroup = (group: string) =>
    setNewUser({ ...newUser, groups: [group] });

  const handleCreateUser = async () => {
    await createUser(newUser.email, newUser.groups);
    setOpen(false);
    onSuccess();
  };

  return (
    <>
      <Button
        size="small"
        sx={{ ml: 1 }}
        startIcon={<Add />}
        variant="contained"
        onClick={() => setOpen(true)}
      >
        Create User
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Create User</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <TextField
              label="E-Mail"
              value={newUser.email}
              onChange={onChangeEmail}
            />
            <Autocomplete
              disableClearable
              renderInput={(params) => <TextField {...params} label="Groups" />}
              options={roles}
              value={newUser.groups[0]}
              onChange={(_, newGroup) => onChangeGroup(newGroup as string)}
            />
          </Stack>
        </DialogContent>
        {loading ? (
          <LinearProgress />
        ) : (
          <DialogActions>
            <Button
              onClick={() => setOpen(false)}
              variant="contained"
              color="inherit"
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleCreateUser}>
              Create User
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
