import { PodtStructure } from '../../loader/structure/podt-structure';
import {
  Optional,
  OptionalValue,
} from '../../../../subject/testRunner/screens/selectors/data-wrapper';
import React, { MutableRefObject } from 'react';
import { Stack, Typography } from '@mui/material';

export function PodtResultComponentFactory(structure: PodtStructure) {
  return ({
    value,
    containerRef,
  }: {
    value: OptionalValue<{
      points: number;
      text:
        | undefined
        | 'noResponseText'
        | 'missText'
        | 'hitText'
        | 'correctRejectionText'
        | 'falseAlarmText';
    }>;
    containerRef: MutableRefObject<any | null>;
  }) => {
    if (
      !structure.config.trialFeedback ||
      !Optional.isValue(value) ||
      value[1].text === undefined
    ) {
      return <>No presentable state</>;
    }

    const feedbackEntry = structure.config.trialFeedback![value[1].text];

    return (
      <Stack
        ref={containerRef}
        style={{
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        spacing={2}
      >
        <Typography style={feedbackEntry.style.createStyle()}>
          {feedbackEntry.text} (
          {feedbackEntry.pointDifference > 0
            ? '+' + feedbackEntry.pointDifference
            : '' + feedbackEntry.pointDifference}
          )
        </Typography>
        <Typography
          style={structure.config.trialFeedback!.pointStyle.createStyle()}
        >
          {Optional.isValue(value) ? 'Points: ' + value[1].points : ''}
        </Typography>
      </Stack>
    );
  };
}
