import { BoxMeasureProvider } from './box-measure-provider';
import { MeasurableComponentHolder } from '../../dom-measure/measurable-holder';
import { BoxHandler } from './box-handler';
import { ObjectTransformer } from '../../../media/drawable/drawable-collector';
import { BasicDimension } from '../../../media/drawable-dimensions';
import { awaitFirst } from '../../../utils/rxjs-utils';
import React from 'react';
import { StyleCB } from '../views/render-container-provider';
import { isReallyDefined } from '../../../../utils';
import { MeasurePhases } from '../../measure-phases';

export class DefaultBoxMeasureProvider<T extends string>
  implements BoxMeasureProvider<T>
{
  private boxMap: Record<T, MeasurableComponentHolder>;

  constructor(
    name: string,
    protected phase: MeasurePhases | 'zero',
    boxes: Record<string, BoxHandler>,
  ) {
    this.boxMap = ObjectTransformer.of(boxes).mapValues(
      (v) =>
        new MeasurableComponentHolder(
          name + '[' + phase + ']',
          phase === 'zero'
            ? (cb) => v.createZeroSizeComponent(cb)
            : (cb) => v.createMeasureComponent(phase, cb),
        ),
    ).result;
  }

  createBox(name: T, styleCB?: StyleCB): JSX.Element {
    const BoxComponent = this.boxMap[name].create(styleCB);
    return <BoxComponent />;
  }

  async measure(): Promise<Record<T, BasicDimension>> {
    const entries = await Promise.all(
      ObjectTransformer.typedEntries(this.boxMap).map(
        async ([k, v]) =>
          [
            k,
            await v.componentSignal.then(() =>
              awaitFirst(v.measurableComponent.sizeSubscriber, isReallyDefined),
            ),
          ] as const,
      ),
    );
    return ObjectTransformer.fromTypedEntries(entries);
  }
}
