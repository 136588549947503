import { Auth } from 'aws-amplify';
import { NewGQL } from '../GQL';

export async function getGraphQL() {
  try {
    await Auth.currentAuthenticatedUser();
    return NewGQL.DEFAULT_CLIENT;
  } catch (e) {
    return NewGQL.NO_AUTH_CLIENT;
  }
}
