import React, { FC } from 'react';
import { Button, ButtonBase, Chip, Grid, Typography } from '@mui/material';
import packageInfo from '../../../package.json';
import dcbLogo from './dcbLogo.png';
import { useAppAuthenticator } from '../../auth/app-authenticator.hook';
import { useNavigate } from 'react-router-dom';
import { RequireAuth } from '../../auth/RequiredAuth';

export const SettingsScreen: FC = () => {
  const { user, signOut } = useAppAuthenticator();

  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut();
    localStorage.clear();
    navigate('/login');
  };

  return (
    <RequireAuth>
      <Grid
        container
        spacing={3}
        alignContent="space-between"
        style={{ height: window.innerHeight * 0.96 }}
      >
        <Grid item>
          <h2>Settings</h2>
          {user && user.groups.includes('admin') && (
            <Typography>
              Role: <Chip label={'Admin'} />
            </Typography>
          )}
        </Grid>

        <Grid item container>
          <Grid item xs={2}>
            <h4>{user?.attributes?.email}</h4>
            <Button variant="contained" onClick={handleSignOut}>
              Sign out
            </Button>
          </Grid>
          <Grid
            item
            xs={10}
            container
            spacing={1}
            justifyContent="flex-end"
            alignContent="center"
          >
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Typography>version</Typography>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Chip label={`${packageInfo.version}`} />
            </Grid>
            <ButtonBase href="https://devconsultberlin.de/" target="_blank">
              <img width={60} src={dcbLogo} alt="dcb-logo" />
            </ButtonBase>
          </Grid>
        </Grid>
      </Grid>
    </RequireAuth>
  );
};

export default SettingsScreen;
