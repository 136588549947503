import { TextMediaStyle } from '../../../../subject/testRunner/media/text-drawer';
import { IatMediaPool } from '../loading/load-iat-media';
import {
  OptionCategoriesArrangement,
  TestOptionCategories,
} from '../../../../subject/testRunner/media/options-test';
import { IatOptionsType } from './iat-options-type';
import { IatBlockConfig, IatBlockInstructionsConfig } from './helper-types';
import { BlockType } from '../../../../../API';
import { TestBaseContext } from '../../../../subject/testRunner/state/baseTestContext/context/ContextTypes';
import { IatVariables } from '../../../text-replacer/textReplacer';
import {
  extractTextContent,
  InstructionScreenContent,
} from '../../../../subject/testRunner/utils/structure-utils';

export interface IatBlockDescriptor {
  blockIndex: number;
  blockType: BlockType;
  blockName: string;
  options: OptionCategoriesArrangement<IatOptionsType>;
  amountTrials: number;
}

export interface IatBlockPhase {
  blockDescriptor: IatBlockDescriptor;
  instructions: {
    welcomeScreenContent: InstructionScreenContent<{ withOptions: boolean }>;
    tips: { text: string; style: TextMediaStyle } | null;
  };
  trialStimuli: IatMediaPool['test']['presentations']['values'];
}

export function prepareIatBlockTexts(
  testContext: TestBaseContext,
  blockInstructions: IatBlockInstructionsConfig,
  phaseOptions: IatBlockDescriptor,
): Record<keyof IatBlockPhase['instructions'], string | null> {
  const replacer = IatVariables.defineInstance({
    attributeLeft:
      phaseOptions.options.attribute?.left?.mediaInstance?.data?.textValue ??
      '',
    attributeRight:
      phaseOptions.options.attribute?.right?.mediaInstance?.data?.textValue ??
      '',
    targetLeft:
      phaseOptions.options.target?.left?.mediaInstance?.data?.textValue ?? '',
    targetRight:
      phaseOptions.options.target?.right?.mediaInstance?.data?.textValue ?? '',
  });
  return {
    welcomeScreenContent: replacer.replace(
      extractTextContent(testContext, blockInstructions.instructions),
    ),
    tips: blockInstructions.tips
      ? replacer.replace(
          extractTextContent(testContext, blockInstructions.tips),
        )
      : null,
  };
}

export function describeIatBlock(
  options: TestOptionCategories<IatOptionsType>,
  block: IatBlockConfig,
): IatBlockDescriptor {
  return {
    options: options.arrange({
      attribute: block.attributeCategoryVariant ?? null,
      target: block.targetCategoryVariant ?? null,
    }),
    amountTrials: block.amountTrials,
    blockType: block.type,
    blockIndex: block.index,
    blockName: block.name,
  };
}
