import { PickingSet } from '../PickingSet';
import { Picker } from '../Picker';
import { PickingBehaviour } from '../picking-behaviours';
import { SetValidConstraint } from '../setConstraints/SetValidConstraint';
import { InvalidStateError } from '../error/InvalidStateError';
import { ValidConstraints } from '../setConstraints/valid-constraints';

export class PlainPickingSet<T> implements PickingSet<T> {
  private pickedAmount = 0;
  private picker: Picker<T>;
  constructor(
    private universe: T[],
    pickingBehaviour: PickingBehaviour,
    private validConstraint: SetValidConstraint,
    public readonly name?: string,
  ) {
    this.picker = pickingBehaviour.createPicker(universe);
  }

  pick(): T {
    const item = this.picker.next();
    this.pickedAmount = this.pickedAmount + 1;
    return item;
  }

  validate(): void {
    if (
      !this.validConstraint.checkValid(this.pickedAmount, this.universe.length)
    ) {
      throw new InvalidStateError(
        this,
        this.validConstraint.describeError(
          this.pickedAmount,
          this.universe.length,
        ),
      );
    }
  }

  static Create<T>(
    name: string,
    behaviour: PickingBehaviour,
    universe: T[],
    constraint?: SetValidConstraint,
  ) {
    return new PlainPickingSet<T>(
      universe,
      behaviour,
      constraint ?? ValidConstraints.All,
      name,
    );
  }
  static CreateUnnamed<T>(
    behaviour: PickingBehaviour,
    universe: T[],
    constraint?: SetValidConstraint,
  ) {
    return new PlainPickingSet<T>(
      universe,
      behaviour,
      constraint ?? ValidConstraints.All,
    );
  }
}
