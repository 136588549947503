import { MediaDrawable } from '../../media/MediaData';
import React from 'react';
import { SizedArea } from '../../media/drawable-dimensions';

export const displayRatio = window.devicePixelRatio;

export function DrawableMediaComponent({ media }: { media: MediaDrawable }) {
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);

  React.useEffect(() => {
    const context = canvasRef.current!.getContext('2d')!;
    context.drawImage(media.imageSource, 0, 0);
  }, [media.imageSource]);

  return (
    <canvas
      width={media.dimension.width * displayRatio}
      height={media.dimension.height * displayRatio}
      style={{ ...SizedArea.dimPx(media.dimension).toSizeModeStyle() }}
      ref={canvasRef}
    />
  );
}
