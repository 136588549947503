import React, { FC } from 'react';
import { Avatar, Box, Card, CardContent, Chip, Stack } from '@mui/material';
import MediaItemComponent from '../../../../media/MediaItemComponent';
import { Iat, IatBlock } from '../../types';
import { CategoryVariant } from '../../../../../API';
import { TextDisplay } from '../../../../../components/TextDisplay';
import { BorderBox } from '../../../../../components/BorderBox';

const getCategoriesDisplayed = (block: IatBlock) => {
  if (block.targetCategoryVariant && block.attributeCategoryVariant) {
    return 'Targets and Attributes';
  }
  if (block.targetCategoryVariant && !block.attributeCategoryVariant) {
    return 'Targets';
  }
  if (!block.targetCategoryVariant && block.attributeCategoryVariant) {
    return 'Attributes';
  }
  return 'Targets and Attributes';
};

export const IatBlockSettingsView: FC<{ test: Iat }> = ({ test }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {test.blocks.map((block, i) => {
        const categoriesDisplayed = getCategoriesDisplayed(block);
        return (
          <Card
            variant="outlined"
            key={`block-${i}`}
            sx={{
              m: 1,
              position: 'relative',
              overflow: 'visible',
              width: '100%',
            }}
          >
            <Chip
              size="small"
              label={block.type === 'PRACTICE' ? 'Practice' : 'No Practice'}
              style={{ position: 'absolute', top: 8, right: 8 }}
            />
            <Avatar
              sx={{
                height: 24,
                width: 24,
                bgcolor: 'darkGrey',
                position: 'absolute',
                top: -8,
                left: -8,
              }}
            >
              {i + 1}
            </Avatar>
            <CardContent>
              <Stack spacing={1}>
                <TextDisplay label={'Name'} value={block.name} />
                <TextDisplay
                  label={'# Trials'}
                  value={`${block.amountTrials} Trials`}
                />
                {categoriesDisplayed !== 'Attributes' && (
                  <Stack direction="row" spacing={3}>
                    <Box width={160}>
                      <MediaItemComponent
                        color={test.targetCategories.fontStyle.color}
                        mediaItem={
                          block.targetCategoryVariant ===
                          CategoryVariant.DEFAULT
                            ? test.targetCategories.categoryA.label
                            : test.targetCategories.categoryB.label
                        }
                        bgColor={test.style.backgroundColour}
                        label={'Target Left'}
                      />
                    </Box>
                    <Box width={160}>
                      <MediaItemComponent
                        color={test.targetCategories.fontStyle.color}
                        mediaItem={
                          block.targetCategoryVariant ===
                          CategoryVariant.DEFAULT
                            ? test.targetCategories.categoryB.label
                            : test.targetCategories.categoryA.label
                        }
                        bgColor={test.style.backgroundColour}
                        label={'Target Right'}
                      />
                    </Box>
                  </Stack>
                )}
                {categoriesDisplayed === 'Targets and Attributes' && (
                  <TextDisplay
                    label="Category Divider"
                    value={test.categoryDivider?.desktop}
                  />
                )}
                {categoriesDisplayed !== 'Targets' && (
                  <Stack direction="row" spacing={3}>
                    <Box width={160}>
                      <MediaItemComponent
                        color={test.attributeCategories.fontStyle.color}
                        mediaItem={
                          block.targetCategoryVariant ===
                          CategoryVariant.DEFAULT
                            ? test.attributeCategories.categoryA.label
                            : test.attributeCategories.categoryB.label
                        }
                        bgColor={test.style.backgroundColour}
                        label={'Attribute Left'}
                      />
                    </Box>
                    <Box width={160}>
                      <MediaItemComponent
                        color={test.attributeCategories.fontStyle.color}
                        mediaItem={
                          block.targetCategoryVariant ===
                          CategoryVariant.DEFAULT
                            ? test.attributeCategories.categoryB.label
                            : test.attributeCategories.categoryA.label
                        }
                        bgColor={test.style.backgroundColour}
                        label={'Attribute Right'}
                      />
                    </Box>
                  </Stack>
                )}
                <TextDisplay
                  label="Show Label in Instructions"
                  value={block.categoryLabelsInInstructions ? 'Yes' : 'No'}
                />
                <BorderBox label="Instructions">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: block.instructions.desktop,
                    }}
                  />
                </BorderBox>
                {block.tips?.desktop && (
                  <BorderBox label="Tips">
                    <div
                      dangerouslySetInnerHTML={{ __html: block.tips.desktop }}
                    />
                  </BorderBox>
                )}
              </Stack>
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
};
