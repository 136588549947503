import { useField } from 'formik';
import {
  SetupDocumentationStepType,
  SurveyProviderSetupDocumentationStep,
} from '../../API';
import { Box, Stack } from '@mui/material';
import CodeSnippet from '../../components/SetupInstructions/CodeSnippet';
import { createCodeSnippet } from '../../components/SetupInstructions/utils';
import { TestType } from '../subject/types';
import StyledTextInput from '../../components/input/StyledTextInput';
import { DocumentationStepImageInput } from './DocumentationStepImageInput';

function DocumentationStepCodeSnippetPreview() {
  return (
    <CodeSnippet
      codeSnippet={createCodeSnippet(
        TestType.IAT,
        `https://mindset.dezim-institut.de/example-test-url`,
      )}
    />
  );
}

export function SurveyProviderDocumentationStepInput({
  stepIndex,
}: {
  stepIndex: number;
}) {
  const stepFieldName = `setupDocumentation.steps[${stepIndex}]`;
  const [, meta] =
    useField<SurveyProviderSetupDocumentationStep>(stepFieldName);

  return (
    <Stack spacing={2}>
      <StyledTextInput
        name={`${stepFieldName}.textBeforeContent`}
        label={'Explanation'}
        multiline
        rows={4}
      />
      {meta.value.type === SetupDocumentationStepType.IMAGE && (
        <Box>
          <DocumentationStepImageInput stepFieldName={stepFieldName} />
        </Box>
      )}
      {meta.value.type === SetupDocumentationStepType.SNIPPET && (
        <Box>
          <DocumentationStepCodeSnippetPreview />
        </Box>
      )}
      {/*      <StyledTextInput
        name={`${stepFieldName}.textAfterContent`}
        label={'Further explanation'}
        multiline
        rows={4}
      />*/}
    </Stack>
  );
}
