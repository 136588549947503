import { IatStructure } from '../loader/structure/iat-structure';
import { ViewBoxes } from '../../../subject/testRunner/screens/layouting-view';
import { IATSelectorTree } from './iat-selectors';
import { StimulusCanvasStrategy } from '../../../subject/testRunner/screens/composer/scaler/canvas-strategy';
import { IATFailComponent } from './iat-fail-component';
import { StateHolder } from '../../../subject/testRunner/graph-state/state-holder';
import { Optional } from '../../../subject/testRunner/screens/selectors/data-wrapper';
import { IATStimulusSlice } from '../loader/tree/iat-tree-state';
import { TestInstructionsView } from '../../../subject/testRunner/screens/basic-views/test-instructions-view';
import { OptionsStereotypes } from '../../../subject/testRunner/controls/control-stereotypes';
import React from 'react';
import { ViewDefinitionCollection } from '../../../subject/testRunner/screens/composer/view-definition-collection';
import { HTMLStateView } from '../../../subject/testRunner/screens/composer/views/html-state-view';
import { CanvasContentView } from '../../../subject/testRunner/screens/composer/views/canvas-content-view';
import { LayoutingView } from '../../../subject/testRunner/screens/composer/views/layouting-view';

export function createOptionsContentView(structure: IatStructure) {
  return LayoutingView.growing({
    selector: IATSelectorTree.rootSelector.options,
    layout: {
      subComponents: ViewDefinitionCollection.Create({
        stimulus: CanvasContentView.growing(
          IATSelectorTree.rootSelector.options.stimulus,
          new StimulusCanvasStrategy(),
        ),
        failView: HTMLStateView.growing(
          IATSelectorTree.rootSelector.options.fail,
          IATFailComponent,
          {
            selectState: (holder: StateHolder) =>
              Optional.value(holder.get(IATStimulusSlice).data.fail),
          },
        ),
        instructions: TestInstructionsView({
          delay:
            structure.accessor.testContext.deviceInfo.mode === 'mobile' ? 3 : 0,
          selector: IATSelectorTree.rootSelector.options.instructions,
          control:
            structure.accessor.testContext.deviceInfo.mode === 'mobile'
              ? (OptionsStereotypes.Confirm.Control as any)
              : undefined,
        }),
      }),
      boxes: ViewBoxes.Define(
        (builder) =>
          ({
            content: builder.growing('stimulus', 'instructions', 'failView'),
          }) as const,
      ),
    } as const,
    contentComponent: (containerProvider, boxProvider) => {
      return () => {
        return containerProvider.createContainer(
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ width: '100%', flexGrow: 1 }}>
              {boxProvider.createBox('content')}
            </div>
          </div>,
        );
      };
    },
  });
}
