import { ViewBoxesScaler } from './view-boxes-scaler';
import { BoxHandler } from '../boxes/box-handler';
import { View } from '../views/view';
import { ViewScaler } from './view-scaler';
import { ObjectTransformer } from '../../../media/drawable/drawable-collector';
import { BasicDimension } from '../../../media/drawable-dimensions';
import { BoxMeasureProvider } from '../boxes/box-measure-provider';
import { DefaultBoxMeasureProvider } from '../boxes/default-box-measure-provider';
import { ViewInstanceController } from '../screen-instance/view-instance-controller';
import { DefaultBoxRenderProvider } from '../boxes/default-box-render-provider';
import { BoxRenderProvider, ViewBoxes } from '../../layouting-view';
import { MeasurePhases } from '../../measure-phases';

export class DefaultViewBoxesScaler implements ViewBoxesScaler {
  viewBoxHandlers: Record<string, BoxHandler>;
  readonly code: string;

  constructor(
    public viewBoxes: ViewBoxes<any>,
    viewScalerMap: Map<View, ViewScaler>,
  ) {
    this.code = viewBoxes.name;
    this.viewBoxHandlers = ObjectTransformer.of(viewBoxes.boxMap).mapValues(
      (v) => v.createBoxHandler(viewScalerMap),
    ).result;
  }

  maxSize(size: BasicDimension) {
    Object.values(this.viewBoxHandlers).forEach((v) => v.maxSize(size));
  }

  determineMin() {
    Object.values(this.viewBoxHandlers).forEach((v) => v.determineMin());
  }

  createMeasureProvider<T extends string>(
    type: MeasurePhases | 'zero',
  ): BoxMeasureProvider<T> {
    return new DefaultBoxMeasureProvider(this.code, type, this.viewBoxHandlers);
  }

  createRenderProvider<T extends string>(
    viewControllerMap: Map<View, ViewInstanceController>,
  ): BoxRenderProvider<T> {
    return new DefaultBoxRenderProvider(
      this.code,
      viewControllerMap,
      this.viewBoxHandlers,
    );
  }
}
