import React, { FC } from 'react';
import {
  MediaItemSnapshotInput,
  WSWAttributeCategory,
} from '../../../../../API';
import { Avatar, Box, Card, Stack } from '@mui/material';
import { TextDisplay } from '../../../../../components/TextDisplay';
import MediaItemList from '../../../../media/MediaItemList';
import { MediaItemTextList } from '../../../../media/MediaItemTextList';

interface WswAttributeCategoriesViewProps {
  attributeCategories: WSWAttributeCategory[];
}

export const WswAttributeCategoriesView: FC<WswAttributeCategoriesViewProps> = (
  props,
) => {
  return (
    <Stack spacing={2} sx={{ mt: 1, width: '80%' }}>
      {props.attributeCategories.map((ac, aci) => (
        <Card
          variant="outlined"
          key={`wsw-attribute-${aci}`}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            pl: 2,
            py: 2,
          }}
        >
          <Avatar sx={{ width: 24, height: 24, mt: 2, mr: 2 }}>
            {aci + 1}
          </Avatar>
          <TextDisplay
            label="Name"
            value={ac.name}
            valueProps={{ sx: { mr: 1 } }}
          />
          <Box sx={{ flexGrow: 1 }}>
            {ac?.mediaSnaps && ac?.mediaSnaps[0]?.image ? (
              <MediaItemList items={(ac.mediaSnaps as any) ?? []} />
            ) : (
              <MediaItemTextList
                mediaItems={
                  ac.mediaSnaps as unknown as MediaItemSnapshotInput[]
                }
              />
            )}
          </Box>
        </Card>
      ))}
    </Stack>
  );
};
