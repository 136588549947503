import { filter, fromEvent, map, Observable, of, Subject, timer } from 'rxjs';
import {
  TimedValue,
  TimeMeasuredValue,
  timeValue,
} from '../../../events/TimedEvent';
import {
  ControlOccurrence,
  ControlStereotype,
  StereotypeTrigger,
} from './control-stereotypes';
import { TimeoutEvent } from '../../../events/TimeoutEvent';
import { first } from 'rxjs/operators';
import { BaseSyntheticEvent } from 'react';

export class ControlInputMonitor {
  public keyInputMonitor: KeyInputMonitor;
  public domUiMonitor: DomUiEventMonitor;
  public timeoutMonitor: TimeoutMonitor;

  constructor(public rootNode: HTMLElement) {
    this.keyInputMonitor = new KeyInputMonitorV1(rootNode);
    this.domUiMonitor = new DomUiEventMonitorV1(rootNode);
    this.timeoutMonitor = new TimeoutEventMonitorV1();
  }

  unsubscribe() {
    this.keyInputMonitor.unsubscribe();
    this.domUiMonitor.unsubscribe();
  }
}

export interface EventDuration<E> extends TimedValue<E> {
  readonly start: TimedValue<E>;
  readonly end: TimedValue<E>;

  readonly duration: number;
}

export class PunctualEventDuration<E> implements EventDuration<E> {
  constructor(public trigger: TimedValue<E>) {}

  get start() {
    return this.trigger;
  }

  get end() {
    return this.trigger;
  }
  get occurredOn() {
    return this.trigger.occurredOn;
  }
  get value() {
    return this.trigger.value;
  }
  readonly duration = 0;
}

interface InputMonitor {
  unsubscribe(): void;
}

interface KeyInputMonitor extends InputMonitor {
  subscribeKey(key: string): Observable<EventDuration<KeyboardEvent>>;
}

export class KeyInputMonitorV1 implements KeyInputMonitor {
  keyDownEvents: Subject<EventDuration<KeyboardEvent>>;

  constructor(private rootNode: HTMLElement) {
    this.keyDownEvents = new Subject<EventDuration<KeyboardEvent>>();
    fromEvent(document, 'keydown')
      .pipe(
        map((k) => new PunctualEventDuration(timeValue(k as KeyboardEvent))),
      )
      .subscribe(this.keyDownEvents);
  }

  subscribeKey(key: string): Observable<EventDuration<KeyboardEvent>> {
    return this.keyDownEvents.pipe(filter((k) => k.start.value.code === key));
  }

  unsubscribe() {
    this.keyDownEvents.unsubscribe();
  }
}

export class ControlOccurrenceEvent<T> extends CustomEvent<{
  occurrence: ControlOccurrence<T>;
}> {
  static EventName = '_TestControlEvents';

  constructor(occurrence: ControlOccurrence<T>) {
    super(ControlOccurrenceEvent.EventName, {
      bubbles: true,
      composed: false,
      detail: {
        occurrence,
      },
      cancelable: false,
    });
  }

  static dispatchControlOccurrence(
    occurence: ControlOccurrence<any>,
    eventTarget?: EventTarget,
  ) {
    const event = new ControlOccurrenceEvent(occurence);
    const target = eventTarget ?? occurence.trigger.value.target;
    if (!target) {
      throw new Error('event ');
    }
    target.dispatchEvent(event);
  }
}

export function ControlEventListener<
  E extends Event,
  C extends ControlStereotype<Event, any>,
>(
  listener: (
    timedEvent: TimeMeasuredValue<E | BaseSyntheticEvent<Event>>,
  ) =>
    | Observable<ControlOccurrence<StereotypeTrigger<C>>>
    | ControlOccurrence<StereotypeTrigger<C>>,
): (event: E | BaseSyntheticEvent<Event>) => void {
  return (event: E | BaseSyntheticEvent<Event>) => {
    const measured = TimeMeasuredValue.now(event);
    const occurrence = listener(measured);
    (occurrence instanceof ControlOccurrence
      ? (of(occurrence) as Observable<ControlOccurrence<StereotypeTrigger<C>>>)
      : occurrence
    )
      .pipe(first())
      .subscribe({
        next: (value) => {
          ControlOccurrenceEvent.dispatchControlOccurrence(value);
        },
      });
  };
}
interface DomUiEventMonitor extends InputMonitor {
  subscribeKey<T, K>(
    controlStereotype: ControlStereotype<T, K>,
  ): Observable<ControlOccurrence<T>>;
}

export class DomUiEventMonitorV1 implements DomUiEventMonitor {
  domUiEvents: Subject<ControlOccurrence<any>>;

  constructor(private rootNode: HTMLElement) {
    this.domUiEvents = new Subject<ControlOccurrence<any>>();
    fromEvent(rootNode, ControlOccurrenceEvent.EventName)
      .pipe(map((k) => (k as ControlOccurrenceEvent<any>).detail.occurrence))
      .subscribe(this.domUiEvents);
  }

  subscribeKey<T, K>(
    controlStereotype: ControlStereotype<T, K>,
  ): Observable<ControlOccurrence<T>> {
    return this.domUiEvents.pipe(
      filter((k) => k.stereotype === controlStereotype),
    );
  }

  unsubscribe(): void {
    this.domUiEvents.unsubscribe();
  }
}

interface TimeoutMonitor {
  subscribe(time: number): Observable<TimeoutEvent>;
}

export class TimeoutEventMonitorV1 implements TimeoutMonitor {
  subscribe(time: number): Observable<TimeoutEvent> {
    return timer(time).pipe(map(() => TimeoutEvent.Create(time)));
  }
}
