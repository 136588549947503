import { defineRunnerMap } from './runner-configuration';
import { PodtaRunnerConfig } from '../../tests/PODTA/runner/podta-runner-config';
import { PodtRunnerConfig } from '../../tests/PODT/runner/podt-runner-config';
import { WswRunnerConfig } from '../../tests/WSW/runner/wsw-runner-config';
import { AmpRunnerConfig } from '../../tests/AMP/runner/amp-runner-config';
import { IatRunnerConfig } from '../../tests/IAT/runner/iat-runner-config';
import { TestType } from '../types';
import { ExampleRunnerConfig } from '../../tests/examples/example1';

export const RunnerConfigMap = defineRunnerMap(
  PodtaRunnerConfig,
  PodtRunnerConfig,
  WswRunnerConfig,
  AmpRunnerConfig,
  IatRunnerConfig,
  ExampleRunnerConfig,
);

export function hasRunnerConfiguration(testType: TestType) {
  return RunnerConfigMap[testType] !== undefined;
}
export function getRunnerConfiguration(testType: TestType) {
  return RunnerConfigMap[testType];
}
