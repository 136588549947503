import { View } from '../views/view';
import { TestFlowSnapshot } from '../../../graph-state/test-director';
import { Observable } from 'rxjs';
import { FC } from 'react';
import { OptionalValue } from '../../selectors/data-wrapper';
import { ScreenNodeDataMap } from '../../selectors/selector-tree';
import { ViewScaler } from '../scaler/view-scaler';
import { ViewInstanceController } from './view-instance-controller';
import { MeasurableValue } from './measurable-value';

export class ViewInstance {
  constructor(
    public readonly viewScaler: ViewScaler,
    protected readonly nodeDataMap: ScreenNodeDataMap<any>,
    protected contentFactory: (
      viewControllerMap: Map<View, ViewInstanceController>,
      dataStream: Observable<MeasurableValue<any>>,
      stateStream: Observable<MeasurableValue<OptionalValue<any>>>,
    ) => FC,
  ) {}

  createInstanceController(
    viewControllerMap: Map<View, ViewInstanceController>,
    flowStream: Observable<TestFlowSnapshot>,
  ): ViewInstanceController {
    return new ViewInstanceController(
      this,
      (dataStream, stateStream) =>
        this.contentFactory(viewControllerMap, dataStream, stateStream),
      this.nodeDataMap,
      flowStream,
    );
  }
}
