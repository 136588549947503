import React, { FunctionComponent } from 'react';
import {
  Box,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useField } from 'formik';
import EditIcon from '@mui/icons-material/AddPhotoAlternate';
import { UiInput } from '../../../components/input/types';
import StyledTextInput from '../../../components/input/StyledTextInput';
import StyledIconButton from '../../../components/input/StyledIconButton';
import { MediaItemSnapshotInput, MediaItemSnapshotScope } from '../../../API';
import { MediaItemsSinglePickTable } from './MediaItemsSinglePickTable';
import MediaItemPickerPreview from './MediaItemPickerPreview';
import { BurstMode as UploadIcon } from '@mui/icons-material';
import PublicIcon from '@mui/icons-material/Public';
import { useUploadPrivateImage } from '../uploader/uploadPrivateImage.hook';
import { v4 as uuid } from 'uuid';
import { LoadingIcon } from '../../../components/utils/LoadingIcon';

interface MediaItemPickerProps extends UiInput<MediaItemSnapshotInput> {
  imageMode?: boolean;
  colorFieldName?: string;
  bgColorFieldName?: string;
}

function ImageChooserMenuButton({
  label,
  saveAction,
  currentValue,
}: {
  currentValue?: MediaItemSnapshotInput | null;
  label?: string;
  saveAction: (mediaItem: MediaItemSnapshotInput) => void;
}) {
  const [showMenu, setShowMenu] = React.useState<null | HTMLElement>(null);
  const handleCloseMenu = () => setShowMenu(null);
  const [showPickerDialog, setShowPickerDialog] = React.useState(false);
  const toggleShowPicker = () => setShowPickerDialog((prevState) => !prevState);
  const hiddenFileInputRef = React.useRef<HTMLInputElement | null>(null);

  const { uploadImages, uploadState } = useUploadPrivateImage();

  const onFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] ?? null;
    if (file) {
      uploadImages([file]).then(([res]) => {
        handleCloseMenu();
        saveAction(res);
      });
    }
  };

  return (
    <>
      <Stack direction="row">
        <StyledIconButton
          label={currentValue?.id ? `Pick another ${label}` : `Pick ${label}`}
          color="primary"
          // disabled={Boolean(meta.value?.id) || Boolean(meta.value?.image)}
          onClick={(evt) => setShowMenu(evt.currentTarget)}
          style={{ boxShadow: 'none' }}
          disabled={uploadState === 'loading'}
        >
          {uploadState === 'loading' ? (
            <LoadingIcon />
          ) : (
            <EditIcon fontSize="small" />
          )}
        </StyledIconButton>
      </Stack>
      <Menu
        anchorEl={showMenu}
        open={Boolean(showMenu)}
        onClose={() => handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            setShowPickerDialog(true);
          }}
        >
          <ListItemIcon>
            <PublicIcon />
          </ListItemIcon>
          <ListItemText primary="Choose from public MIND.set Pool" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            hiddenFileInputRef.current?.click();
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <UploadIcon />
          </ListItemIcon>
          <ListItemText primary="Upload Image" />
        </MenuItem>
      </Menu>
      <input
        ref={hiddenFileInputRef}
        accept={'image/*'}
        multiple={false}
        type="file"
        style={{ display: 'none' }}
        onChange={onFilesChange}
      />
      <MediaItemsSinglePickTable
        value={currentValue?.id ? currentValue : undefined}
        title={label}
        onConfirm={(mediaItem) => saveAction(mediaItem)}
        onClose={toggleShowPicker}
        open={showPickerDialog}
      />
    </>
  );
}

const MediaItemPicker: FunctionComponent<MediaItemPickerProps> = ({
  label,
  name,
  imageMode,
  value,
  // color,
  // bgColor,
  colorFieldName,
  bgColorFieldName,
}) => {
  const [, { value: color }] = useField<string | undefined>(
    colorFieldName || 'anyField',
  );
  const [, { value: bgColor }] = useField<string | undefined>(
    bgColorFieldName || 'style.backgroundColour',
  );

  const [, meta, helpers] = useField<typeof value>(name);

  const saveAction = (s: MediaItemSnapshotInput) => {
    helpers.setValue(s);
  };

  const imageModeButton = (
    <ImageChooserMenuButton label={label} saveAction={saveAction} />
  );

  const textInput = (
    <StyledTextInput
      fullWidth
      name={name}
      onChange={(e: any) =>
        helpers.setValue({
          id: uuid(),
          scope: MediaItemSnapshotScope.PRIVATE,
          tags: [],
          text: e.target.value,
        })
      }
      label={label}
      value={meta.value?.text || ''}
      error={meta.touched && meta.error !== undefined}
      helperText={(meta.touched && meta.error) || ''}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{imageModeButton}</InputAdornment>
        ),
      }}
    />
  );
  const inputEl = imageMode ? imageModeButton : textInput;

  return (
    <Box>
      {inputEl}
      {meta.value && (
        <Box
          sx={{
            border: 1,
            bgcolor: bgColor,
            borderRadius: '4px',
            borderTop: 'none',
            borderColor: 'rgba(173,167,167,0.6)',
            borderWidth: '0.1px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& button': {
              position: 'absolute',
              top: 0,
              left: 0,
            },
            '& .hidden-text': {
              display: 'none',
            },
            '&:hover .hidden-text': {
              display: 'flex',
            },
          }}
        >
          <Typography
            color="textSecondary"
            variant="caption"
            className="hidden-text"
            sx={{
              position: 'absolute',
              left: 13,
              top: 4,
            }}
          >
            Preview
          </Typography>
          <MediaItemPickerPreview
            mediaItem={meta.value}
            color={color}
            bgColor={bgColor}
          />
        </Box>
      )}
    </Box>
  );
};

export default MediaItemPicker;
