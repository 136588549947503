import { TestBaseContext } from '../../../../subject/testRunner/state/baseTestContext/context/ContextTypes';
import { AmpBlockConfig, AmpBlockInstructionsConfig } from './helper-types';
import { AmpVariables } from '../../../text-replacer/textReplacer';
import { AmpMediaPool } from '../loading/amp-media-loader';
import { TextMediaStyle } from '../../../../subject/testRunner/media/text-drawer';
import {
  OptionCategoriesArrangement,
  TestOptionCategories,
} from '../../../../subject/testRunner/media/options-test';
import { BlockType } from '../../../../../API';

import { AmpOptionsType } from './amp-options-type';
import {
  extractTextContent,
  InstructionScreenContent,
} from '../../../../subject/testRunner/utils/structure-utils';

export interface AmpBlockDescriptor {
  blockName: string;
  blockIndex: number;
  blockType: BlockType;
  options: OptionCategoriesArrangement<'attribute'>;
  amountTrials: number;
}

export interface AmpBlockPhase {
  fixation?: {
    interval: number;
    mediaItem: AmpMediaPool['fixation'];
  };
  mask: AmpMediaPool['mask'];
  blockDescriptor: AmpBlockDescriptor;
  instructions: {
    welcomeScreenContent: InstructionScreenContent;
    tips: { text: string; style: TextMediaStyle } | null;
  };
  trialStimuli: {
    prime: AmpMediaPool['primes']['values'][number];
    target: AmpMediaPool['targets']['values'][number];
  }[];
}

export function prepareAmpBlockTexts(
  testContext: TestBaseContext,
  blockInstructions: AmpBlockInstructionsConfig,
  phaseOptions: AmpBlockDescriptor,
): Record<keyof AmpBlockPhase['instructions'], string | null> {
  const replacer = AmpVariables.defineInstance({
    attributeLeft:
      phaseOptions.options.attribute?.left?.mediaInstance?.data?.textValue ??
      '',
    attributeRight:
      phaseOptions.options.attribute?.right?.mediaInstance?.data?.textValue ??
      '',
  });
  return {
    welcomeScreenContent: replacer.replace(
      extractTextContent(testContext, blockInstructions.instructions),
    ),
    tips: blockInstructions.tips
      ? replacer.replace(
          extractTextContent(testContext, blockInstructions.tips),
        )
      : null,
  };
}

export function describeAmpBlock(
  options: TestOptionCategories<AmpOptionsType>,
  block: AmpBlockConfig,
): AmpBlockDescriptor {
  return {
    blockName: block.name,
    options: options.arrange({
      attribute: block.attributeVariant ?? null,
    }),
    amountTrials: block.amountTrials,
    blockType: block.type,
    blockIndex: block.index,
  };
}
