import { TimeoutEvent } from '../../../events/TimeoutEvent';
import { ControlStereotype } from '../../../subject/testRunner/controls/control-stereotypes';

import { QuestionAnswer } from './model/questions/question-answer';
export interface QuestionAnswerEvent {
  answer: QuestionAnswer;
  event: MouseEvent;
}
export const WswStereotypes = {
  Answer: {
    Proceed: new ControlStereotype<QuestionAnswerEvent, void>('proceed'),
    Skip: new ControlStereotype<QuestionAnswerEvent, void>('skip'),
    Answer: new ControlStereotype<QuestionAnswerEvent, void>('answer'),
  },
  Confirm: new ControlStereotype<MouseEvent, void>('confirm'),
  TimeoutEvent: new ControlStereotype<TimeoutEvent, number>('timeout'),
};
