import React, { FC } from 'react';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { ConditionalQuestionSet } from '../conditionalQuestionSet/ConditionalQuestionSet';
import { BorderBox } from '../../../../../components/BorderBox';
import { useField } from 'formik';
import { DistractionPhaseInput } from '../../../../../API';
import { createEmptyPhase } from '../../../testAdmin/testEditor/initialEditorStates';
import { InfoOutlined } from '@mui/icons-material';
import { WswVariables } from '../../../text-replacer/textReplacer';
import { OptionalInstructionsScreen } from '../../../../subject/testRunner/screens/OptionalInstructionsScreen';
import { FormikNullableCheckbox } from '../../../../../components/input/FormikNullableCheckbox';

export const DistractionPhaseInputComponent: FC<{
  fieldName: string;
  label: string;
}> = ({ fieldName, label }) => {
  const [, meta, helpers] = useField<DistractionPhaseInput | undefined | null>(
    fieldName,
  );

  return (
    <BorderBox
      showContent={Boolean(meta.value)}
      label={
        <Typography
          variant="h6"
          color="textSecondary"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {label}&nbsp;
          <FormikNullableCheckbox
            label={''}
            onChange={(checked) =>
              helpers.setValue(checked ? createEmptyPhase() : null)
            }
            value={Boolean(meta.value)}
          />
          <Tooltip
            placement={'right'}
            title={
              'Includes distraction phase in the test, that is an additional task or questions between learning and test phase; you can add text and/or questions.'
            }
          >
            <InfoOutlined
              sx={{
                color: 'warning.dark',
                bgcolor: 'white',
                position: 'relative',
                left: '-1em',
              }}
            />
          </Tooltip>
        </Typography>
      }
      boxProps={{ sx: { p: 2 } }}
    >
      <Stack direction="row" spacing={3} sx={{ mb: 2, width: '100%' }}>
        <Box width={'49%'}>
          <OptionalInstructionsScreen
            fieldName={`${fieldName}.instructionsScreen`}
            checkboxLabel="Show Instructions Screen"
            dialogLabel="Instructions Screen"
            variables={WswVariables}
          />
        </Box>
        <Box width={'49%'}>
          <OptionalInstructionsScreen
            fieldName={`${fieldName}.feedbackScreen`}
            checkboxLabel="Show Feedback Screen"
            dialogLabel="Feedback Screen"
            variables={WswVariables}
          />
        </Box>
      </Stack>
      <ConditionalQuestionSet
        fieldName={`${fieldName}.questionsSet`}
        label={'Questionset'}
        withImageInQuestions={true}
        withConditionalQuestion={false}
        withQuestionTimeout={true}
      />
    </BorderBox>
  );
};
