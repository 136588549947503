import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { TestPermissionRow } from './TestPermissionRow';
import React, { FC, useState } from 'react';
import { Cancel, CheckCircle, Edit } from '@mui/icons-material';
import { testPermissions, TestPermissionsProps } from './types';
import { testPermissionDescriptionMap } from './testPermissionDescriptionMap';
import Divider from '@mui/material/Divider';
import { useTestPermissions } from './TestPermissionsHook';

export const TestPermissions: FC<TestPermissionsProps> = (props) => {
  if (!props.ownerId) {
    throw new Error('this should not happen');
  }

  const [inputValue, setInputValue] = useState('');

  const {
    loading,
    handleInviteUsers,
    handleRemoveEmail,
    userIdEmailsMap,
    showForm,
    toggleForm,
    permissionToAdd,
    setPermissionToAdd,
    closeForm,
    emailsToAdd,
    setEmailsToAdd,
  } = useTestPermissions(props);

  return (
    <div>
      <Stack direction="row" spacing={6}>
        <TestPermissionRow
          permission="owner"
          emails={[userIdEmailsMap[props.ownerId]]}
        />
        <TestPermissionRow
          permission="view-test"
          emails={
            props.testViewersIds?.map((id) => userIdEmailsMap[id as string]) ??
            []
          }
          onRemoveEmail={handleRemoveEmail}
        />
        <TestPermissionRow
          permission="edit-test"
          emails={
            props.testEditorsIds?.map((id) => userIdEmailsMap[id as string]) ??
            []
          }
          onRemoveEmail={handleRemoveEmail}
        />
        <TestPermissionRow
          permission={'view-results'}
          emails={
            props.resultsViewersIds?.map(
              (id) => userIdEmailsMap[id as string],
            ) ?? []
          }
          onRemoveEmail={handleRemoveEmail}
        />
      </Stack>
      {!showForm && (
        <Button
          sx={{ mt: 2 }}
          startIcon={<Edit />}
          variant="contained"
          size="small"
          onClick={toggleForm}
        >
          Edit Permissions
        </Button>
      )}
      <Collapse in={showForm}>
        <Box sx={{ pt: 3 }}>
          <Typography variant="h6">Invitation</Typography>
          <Typography sx={{ mb: 2 }} color="text.secondary" variant="body2">
            Invite users to the test. They will see the test in their test-list
            afterwards.
          </Typography>
          <Stack spacing={3}>
            <FormGroup>
              <Stack spacing={1}>
                {testPermissions
                  .filter((p) => p !== 'owner')
                  .map((permission) => (
                    <div key={permission}>
                      <FormControlLabel
                        value={permission}
                        checked={permissionToAdd.includes(permission)}
                        onChange={(_, checked) => {
                          if (checked) {
                            setPermissionToAdd([
                              ...permissionToAdd,
                              permission,
                            ]);
                          } else {
                            setPermissionToAdd(
                              permissionToAdd.filter((p) => p !== permission),
                            );
                          }
                        }}
                        control={<Checkbox />}
                        label={
                          <Box>
                            <Typography variant="body1">
                              {permission}
                            </Typography>
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {testPermissionDescriptionMap[permission]}
                            </Typography>
                            <Divider />
                          </Box>
                        }
                      />
                    </div>
                  ))}
              </Stack>
            </FormGroup>
            <Autocomplete
              freeSolo
              options={[]}
              multiple
              value={emailsToAdd}
              inputValue={inputValue}
              onInputChange={(_, v) => {
                setInputValue(v);
              }}
              onChange={(_, value) => {
                setEmailsToAdd(value as string[]);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Press enter after you added an email."
                  onBlur={(e) => {
                    if (
                      !emailsToAdd.includes(e.target.value) &&
                      e.target.value !== ''
                    ) {
                      setEmailsToAdd([...emailsToAdd, e.target.value]);
                      setInputValue('');
                    }
                  }}
                />
              )}
            />
            <Stack direction="row" spacing={1} sx={{ pt: 2 }}>
              <Button
                startIcon={<Cancel />}
                variant="contained"
                color="inherit"
                size="small"
                onClick={closeForm}
              >
                Cancel
              </Button>
              <Button
                startIcon={<CheckCircle />}
                variant="contained"
                size="small"
                onClick={handleInviteUsers}
                disabled={
                  emailsToAdd.length === 0 || permissionToAdd.length === 0
                }
              >
                Invite Users
              </Button>
            </Stack>
            {loading && <LinearProgress />}
          </Stack>
        </Box>
      </Collapse>
    </div>
  );
};
