import { useEffect, useState } from 'react';

export default function useIframeListener() {
  const [onFinished, setOnFinished] = useState<() => void | undefined>();

  useEffect(() => {
    const iframeParentListener = (ev: MessageEvent) => {
      // set callback to send finish message to parent
      if (ev.data === 'ci') {
        const { source, origin } = ev;
        setOnFinished(
          () =>
            function () {
              (source as WindowProxy).postMessage(
                { type: 'finish' },
                origin as string,
              );
            },
        );
      }
    };
    window.addEventListener('message', iframeParentListener);
    return () => {
      window.removeEventListener('message', iframeParentListener);
    };
  }, []);

  return onFinished;
}
