import React from 'react';
import { BaseTest } from '../../../../tests/types';
import { RunnerConfigMap } from '../../runner-config-map';
import { StagePreviewTest } from './StagePreview';
import { omit } from 'lodash';
import InfoMessage from '../../../../../components/InfoMessage';
import { Box } from '@mui/material';
import { TestType } from '../../../types';

export function createPreviewStage(testType: TestType, test: BaseTest) {
  if (!RunnerConfigMap[testType]) {
    throw new Error(
      'invalid testtype' + testType + '. Cannot find Runner Config in map',
    );
  }
  return () => {
    return (
      <StagePreviewTest
        test={test}
        resultPreviewTable={RunnerConfigMap[testType].resultPreviewTable}
        testLoader={omit(RunnerConfigMap[testType], 'resultPreviewTable')}
      />
    );
  };
}

export function StagePreviewContainer({
  test,
  testType,
}: {
  test: BaseTest;
  testType: TestType;
}) {
  const PreviewComponent = React.useMemo(() => {
    return createPreviewStage(testType, test);
  }, [testType, test]);
  return (
    <Box position="relative">
      <Box
        position="absolute"
        top={0}
        right={'2%'}
        zIndex={99999}
        sx={{ width: 180 }}
      >
        <InfoMessage message={"Press 'Escape' to leave"} />
      </Box>
      <PreviewComponent />
    </Box>
  );
}
