import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import {
  Attachment,
  Group,
  Groups2,
  Logout,
  Mail,
  PermMedia,
  Poll,
  Style,
} from '@mui/icons-material';
import AdminAppNavigation from '../features/admin/AdminAppNavigation';
import { TestsMenu } from '../features/tests/TestsMenu';
import { SettingsScreen } from '../features/settings/SettingsScreen';
import { TagsSettings } from '../features/media/pool/TagsSettings';
import MediaPool from '../features/media/pool/MediaPool';
import { TestsMetaData, TestType } from '../features/subject/types';
import IatAdmin from '../features/tests/IAT/IatAdmin';
import SubjectApp from '../features/subject/SubjectApp';
import { Dashboard } from '../features/Dashboard';
import Files from '../features/files/Files';
import { UsersScreen } from '../features/users/UsersScreen';
import AmpAdmin from '../features/tests/AMP/AmpAdmin';
import PodtAdmin from '../features/tests/PODT/PodtAdmin';
import PodtaAdmin from '../features/tests/PODTA/PodtaAdmin';
import WswAdmin from '../features/tests/WSW/WswAdmin';
import { LoginScreen } from '../auth/LoginScreen';
import { SurveyProvidersScreen } from '../features/survey-providers/SurveyProvidersScreen';
import { UserRole } from '../auth/user-role';
import { LogoutScreen } from '../features/settings/LogoutScreen';
import { RegistrationScreen } from '../auth/RegistrationScreen';
import { RegistrationsRequestsScreen } from '../features/users/RegistrationsRequestScreen';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { BroadcastMailScreen } from '../features/admin/BroadcastMailScreen';
import {
  NavigationGroupEntry,
  NavigationPageLinkEntry,
} from '../features/admin/NavigationEntry';
import { useAuth } from '@aws-amplify/ui-react/internal';
import { ShareCodeSnippetScreen } from '../features/tests/ShareCodeSnippetScreen';

const primaryPageLinks = [
  {
    type: 'page-link',
    label: 'Tests',
    tabPath: '/tests',
    icon: <ConfirmationNumberIcon sx={{ color: 'rgba(30, 95, 70, 0.87)' }} />,
  },
  {
    type: 'page-link',
    label: 'Media Pool',
    tabPath: '/media',
    icon: <PermMedia sx={{ color: 'rgba(30, 95, 70, 0.87)' }} />,
  },
  {
    type: 'page-link',
    label: 'Media Tags',
    tabPath: '/tags',
    icon: <Style sx={{ color: 'rgba(30, 95, 70, 0.87)' }} />,
    forRoles: ['admin'] as UserRole[],
  },
  {
    type: 'page-link',
    label: 'Files',
    tabPath: '/files',
    icon: <Attachment sx={{ color: 'rgba(30, 95, 70, 0.87)' }} />,
  },
  {
    type: 'page-link',
    label: 'Survey Provider',
    tabPath: '/survey-provider',
    icon: <Poll sx={{ color: 'rgba(30, 95, 70, 0.87)' }} />,
    forRoles: ['admin'] as UserRole[],
  },
  {
    type: 'page-group',
    label: 'User Management',
    icon: <Groups2 sx={{ color: 'rgba(30, 95, 70, 0.87)' }} />,
    forRoles: ['admin'] as UserRole[],
    children: [
      {
        type: 'page-link',
        label: 'Users',
        tabPath: '/users',
        icon: <Group sx={{ color: 'rgba(30, 95, 70, 0.87)' }} />,
        forRoles: ['admin'] as UserRole[],
      },
      {
        type: 'page-link',
        label: 'Registrations',
        tabPath: '/registrations',
        icon: <AppRegistrationIcon sx={{ color: 'rgba(30, 95, 70, 0.87)' }} />,
        forRoles: ['admin'] as UserRole[],
      },
      {
        type: 'page-link',
        label: 'Broadcast-Mail',
        forRoles: ['admin'] as UserRole[],
        icon: <Mail sx={{ color: 'rgba(30, 95, 70, 0.87)' }} />,
        tabPath: '/broadcast-mail',
      },
    ],
  },
] satisfies (NavigationPageLinkEntry | NavigationGroupEntry)[];

const secondaryPageLinks = [
  /*{
    label: 'Settings',
    tabPath: '/settings',
    icon: <SettingsIcon />,
  },*/
  {
    type: 'page-link',
    label: 'Logout',
    tabPath: '/logout',
    icon: <Logout sx={{ color: 'rgba(30, 95, 70, 0.87)' }} />,
  },
] satisfies NavigationPageLinkEntry[];

function App() {
  const authState = useAuth();

  React.useEffect(() => {
    const refreshSession = async () => {
      const currentUser = authState.user;
      console.log('auth', currentUser, authState.isLoading);
      if (currentUser && !authState.isLoading) {
        const refreshToken = currentUser
          .getSignInUserSession()
          ?.getRefreshToken();

        if (refreshToken) {
          try {
            await new Promise((res, rej) => {
              currentUser.refreshSession(refreshToken, (err, result) => {
                if (err) {
                  rej(err);
                }
                return res(result);
              });
            });
          } catch (e) {
            // console.error('Error refreshing session', e);
          }
        }
      }
    };
    refreshSession();
    // CAUTION: This effect should only run once, as it is only used to refresh the session at the beginning. This is kind of a hack, but it works and i haven't found a better solution yet.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.isLoading]);

  const mainNav = React.useMemo(() => {
    return (
      <AdminAppNavigation
        primaryPages={primaryPageLinks}
        secondaryPages={secondaryPageLinks}
      />
    );
  }, []);

  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/share-snippet" element={<ShareCodeSnippetScreen />} />
        <Route path="/register" element={<RegistrationScreen />} />
        <Route path="/" element={mainNav}>
          <Route index element={<Navigate replace to="/tests" />} />
          <Route index path="/dashboard" element={<Dashboard />} />
          <Route path="/tags" element={<TagsSettings />} />
          <Route path="/tests/*">
            <Route index element={<TestsMenu />} />
            <Route
              path={`${TestsMetaData[TestType.IAT].identifier}/*`}
              element={<IatAdmin />}
            />
            <Route
              path={`${TestsMetaData[TestType.AMP].identifier}/*`}
              element={<AmpAdmin />}
            />
            <Route
              path={`${TestsMetaData[TestType.PODT].identifier}/*`}
              element={<PodtAdmin />}
            />
            <Route
              path={`${TestsMetaData[TestType.PODTA].identifier}/*`}
              element={<PodtaAdmin />}
            />
            <Route
              path={`${TestsMetaData[TestType.WSW].identifier}/*`}
              element={<WswAdmin />}
            />
          </Route>
          <Route path="media" element={<MediaPool />} />
          <Route path="broadcast-mail" element={<BroadcastMailScreen />} />
          <Route path="survey-provider" element={<SurveyProvidersScreen />} />
          <Route path="files" element={<Files />} />
          <Route path="settings" element={<SettingsScreen />} />
          <Route path="users" element={<UsersScreen />} />
          <Route
            path="registrations"
            element={<RegistrationsRequestsScreen />}
          />
          <Route path="logout" element={<LogoutScreen />} />
        </Route>
        <Route path="/run/:testType/:testId" element={<SubjectApp />} />
      </Routes>
    </>
  );
}

export default App;
