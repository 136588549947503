import React, { FC } from 'react';
import { BaseTest } from '../../types';
import { TestStatus } from '../../../../API';
import { Alert, AlertTitle } from '@mui/lab';
import { TestType } from '../../../subject/types';
import { AlertColor } from '@mui/material/Alert/Alert';
import { DownloadTestResultsWidget } from './DownloadTestResultsWidget';
import { Box } from '@mui/material';

interface TestResultsAlertPros {
  test?: BaseTest;
  userOwnsTest: boolean;
  testType: TestType;
}

const statusMap: Record<
  TestStatus,
  { label: string; description: string; color: AlertColor }
> = {
  CLOSED: {
    label: 'Closed',
    description: 'No new results can be created',
    color: 'error',
  },
  DRAFT: { label: 'Draft', description: 'Test not setup yet', color: 'info' },
  LIVE: {
    label: 'Live',
    description:
      'Results of respondents can be distinguished with prior results of scientists.',
    color: 'success',
  },
  PUBLISHED: {
    label: 'Published',
    description:
      "Test set up correctly. Results of respondents can NOT be distinguished with prior results of scientists. Click 'Go Live' to make test live",
    color: 'warning',
  },
};

export const TestResultsAlert: FC<TestResultsAlertPros> = ({
  test,
  userOwnsTest,
  testType,
}) => {
  if (!test) {
    return <React.Fragment />;
  }
  return (
    <React.Fragment>
      {test.status !== TestStatus.DRAFT && userOwnsTest && (
        <Alert
          sx={{ mt: 1 }}
          variant="filled"
          color={statusMap[test.status].color}
          action={
            <Box sx={{ width: '30vw' }}>
              <DownloadTestResultsWidget testId={test.id} testType={testType} />
            </Box>
          }
        >
          <AlertTitle>Test {statusMap[test.status].label}</AlertTitle>
          {statusMap[test.status].description}
        </Alert>
      )}
    </React.Fragment>
  );
};
