import { FontFace } from '../API';

export const FontFaceFamilyMap: { [e in FontFace]: string } =
  Object.fromEntries(
    Object.values(FontFace).map(
      (f) =>
        [
          f,
          f
            .split('_')
            .map((v) => v.substr(0, 1) + v.substr(1).toLowerCase())
            .join(' '),
        ] as [FontFace, string],
    ),
  ) as { [e in FontFace]: string };

// export const createFontStyles = (
//   style: FontStyleInput | undefined,
//   useAlign: boolean,
// ) =>
//   style
//     ? {
//         fontSize: style.fontSize + 'px',
//         color: style.color,
//         fontFamily: FontFaceFamilyMap[style.fontFace],
//         ...(useAlign ? { textAlign: style.alignment.toLowerCase() } : {}),
//       }
//     : {};
