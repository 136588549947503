import { WswStructure } from '../../loader/structure/wsw-structure';
import { WSWSelectorTree } from '../wsw-selectors';
import { ViewBoxes } from '../../../../subject/testRunner/screens/layouting-view';
import React from 'react';
import { createWswQuestionContentView } from './wsw-question-content-view';
import { createWswQuestionStimulusView } from './wsw-question-stimulus-view';
import { ViewDefinitionCollection } from '../../../../subject/testRunner/screens/composer/view-definition-collection';
import { LayoutingView } from '../../../../subject/testRunner/screens/composer/views/layouting-view';

export function createWswQuestionsView(structure: WswStructure) {
  return LayoutingView.growing({
    selector: WSWSelectorTree.rootSelector.phaseQuestions,
    layout: {
      subComponents: ViewDefinitionCollection.Create({
        stimulus: createWswQuestionStimulusView(structure),
        question: createWswQuestionContentView(),
      }),
      boxes: ViewBoxes.Define((builder) => {
        return {
          stimulus: builder.growing('stimulus'),
          content: builder.fixed('question'),
        };
      }),
    },
    contentComponent: (containerProvider, boxProvider) => {
      return () => {
        return containerProvider.createContainer(
          <>
            <div
              style={{
                flex: 'auto',
                display: 'flex',
                flexBasis: 0,
                width: '100%',
                padding: '8px',
              }}
            >
              {boxProvider.createBox('stimulus', (p, style) =>
                p === 'render'
                  ? style
                  : {
                      ...style,
                      height: undefined,
                      flex: 'auto',
                    },
              )}
            </div>
            <div style={{ flex: 'none', flexBasis: 0, width: '100%' }}>
              {boxProvider.createBox('content')}
            </div>
          </>,
          (_, style) => ({
            ...style,
            display: 'flex',
            flexDirection: 'column',
          }),
        );
      };
    },
  });
}
