import { TestBaseContext } from '../state/baseTestContext/context/ContextTypes';
import {
  PickingStrategy,
  StyleableTextTemplate,
  TestStyle,
} from '../../../../API';
import { DrawableMediaInstance } from '../media/MediaData';
import { OptionArranger, TestOptionCategory } from '../media/options-test';
import { CounterBalancingGroup } from '../../../tests/counter-balancing-group';
import { MindSetRandomUtils } from '../../../tests/pickingSet/picking-utils';

export function extractTextContent(
  testContext: TestBaseContext,
  styleableTextTemplate: StyleableTextTemplate | null | undefined,
  fallbackValue = '',
) {
  const resultText =
    testContext.deviceInfo.mode === 'mobile'
      ? styleableTextTemplate?.mobile ?? styleableTextTemplate?.desktop
      : styleableTextTemplate?.desktop;
  return resultText ?? fallbackValue;
}

export function optionCategoryBuilder<T extends string>(
  type: T,
  attributePool: {
    first: Record<'value', DrawableMediaInstance>;
    second: Record<'value', DrawableMediaInstance>;
  },
  arranger: OptionArranger,
) {
  return new TestOptionCategory<T>(
    {
      optionCategory: type,
      optionVariant: 'first',
      mediaInstance: attributePool.first.value,
    },
    {
      optionCategory: type,
      optionVariant: 'second',
      mediaInstance: attributePool.second.value,
    },
    arranger,
  );
}

export function determineCounterBalancingGroup(
  counterBalancing: boolean,
): CounterBalancingGroup {
  if (counterBalancing) {
    return MindSetRandomUtils.choseFromDistribution({
      [CounterBalancingGroup.GROUP_A]: 1,
      [CounterBalancingGroup.GROUP_B]: 1,
    });
  }
  return CounterBalancingGroup.GROUP_A;
}

export function determineLROptionsTestPickingStrategy(
  probabilityWithoutReplacement: boolean,
) {
  return probabilityWithoutReplacement
    ? PickingStrategy.REFILLING_WITHOUT_REPLACEMENT
    : PickingStrategy.WITH_REPLACEMENT;
}

export function extractDeviceOptionsPosition(
  testContext: TestBaseContext,
  testStyle: TestStyle,
) {
  return testContext.deviceInfo.mode === 'mobile' &&
    testStyle.optionsPositionMobile
    ? testStyle.optionsPositionMobile
    : testStyle.optionsPosition;
}

export type InstructionScreenContent<Additional extends {} = {}> = {
  content: string;
  nextButton: string | null;
} & Additional;

export type TestInstructionsPhase<Additional extends {} = {}> = {
  content: InstructionScreenContent;
} & Additional;
