import React, { useState } from 'react';
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useUploadFileMutation } from './filesApi';
import { DialogActions } from '../../components/dialog/DialogActions';
import { styled } from '@mui/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const CreateDocumentationFileDialog = () => {
  const [description, setDescription] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);

  const dialogOpen = Boolean(file);
  const openDialog = (file: File) => setFile(file);
  const closeDialog = () => {
    setFile(null);
    setDescription('');
  };

  const onChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      openDialog(files[0]);
    }
  };

  const [uploadFile, { isLoading }] = useUploadFileMutation();

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDescription(event.target.value);
  };

  const handleConfirm = async () => {
    if (file) {
      await uploadFile({ description, file });
      closeDialog();
    }
  };

  return (
    <React.Fragment>
      <Button
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        size="small"
        sx={{ ml: 1 }}
      >
        Upload file
        <VisuallyHiddenInput type="file" onChange={onChangeFiles} />
      </Button>
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>Create Documentation File</DialogTitle>
        <DialogContent>
          <Stack>
            {/*{file && (*/}
            {/*  <img*/}
            {/*    style={{ maxWidth: '100%' }}*/}
            {/*    src={URL.createObjectURL(file)}*/}
            {/*    alt={file.name}*/}
            {/*  />*/}
            {/*)}*/}
            {file && (
              <div>
                <Typography variant="caption" color="text.secondary">
                  New File
                </Typography>
                <div>
                  <Chip label={file.name} />
                </div>
              </div>
            )}
            <TextField
              label="Description"
              value={description}
              onChange={handleChangeDescription}
              sx={{ mt: 2 }}
            />
          </Stack>
        </DialogContent>
        <DialogActions
          loading={isLoading}
          onConfirm={handleConfirm}
          onCancel={closeDialog}
          confirmDisabled={isLoading || !description}
        />
      </Dialog>
    </React.Fragment>
  );
};
