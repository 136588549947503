import { useAuthenticator, UseAuthenticator } from '@aws-amplify/ui-react';
import { UserRole } from './user-role';

export type AuthUser = UseAuthenticator['user'] & {
  groups: UserRole[];
};

type UseAppAuthenticator = Omit<UseAuthenticator, 'user'> & {
  user?: AuthUser;
  accessToken?: string;
};

/**
 * Hook to get the current user from the amplify context provider
 */
export function useAppAuthenticator(): UseAppAuthenticator {
  // hook is only reevaluated when `user` changes
  // -> only re-renders when the user changes
  const { user, ...rest } = useAuthenticator((context) => [context.user]);

  const accessToken = user?.getSignInUserSession()?.getAccessToken().payload;
  const groupOfUser = (accessToken?.['cognito:groups'] ?? []) as UserRole[];

  return {
    ...rest,
    accessToken: user?.getSignInUserSession()?.getAccessToken().getJwtToken(),
    user: user
      ? (Object.assign(user, { groups: groupOfUser }) as AuthUser)
      : undefined,
  };
}

export function useAccessToken(): string | undefined {
  const { user } = useAuthenticator((context) => [context.user]);
  return user?.getSignInUserSession()?.getAccessToken().getJwtToken();
}
