import { IatStructure } from '../structure/iat-structure';
import { IATModifiers } from './iat-modifiers';
import { IatOptionsData } from '../view-data/iat-options-data';
import { InstructionsContent } from '../../../../subject/testRunner/screens/basic-views/test-instructions-view';
import { IatInstructionsData } from '../view-data/iat-instructions-data';
import { OptionsStereotypes } from '../../../../subject/testRunner/controls/control-stereotypes';
import { DefaultTransitionAction } from '../../../../subject/testRunner/graph-state/graph-action';
import { IatBlockPhase } from '../structure/iat-block-structure';
import { createBlockTrialsPhaseTree } from './iat-trial-tree';
import { BlockPhaseDescriptor } from '../../../../subject/testRunner/utils/tree-utils';
import { MapScreenTreeNode } from '../../../../subject/testRunner/graph/nodes/map-screen-tree-node';
import { MapPhaseTreeNode } from '../../../../subject/testRunner/graph/nodes/map-phase-tree-node';

export function createIatBlockPhaseTrees(
  iatStructure: IatStructure,
  root: MapPhaseTreeNode,
) {
  const blocks = new MapPhaseTreeNode('blocks', root)
    .connectParent()
    .withDisplayName('Blocks');

  const blockNodes = iatStructure.blockPhases.map((block, blockIndex) =>
    createIatSingleBlockPhaseTree(iatStructure, {
      block,
      blockIndex,
      blockNode: new MapPhaseTreeNode('block-' + blockIndex, blocks)
        .withDisplayName(`Block ${blockIndex + 1}`)
        .connectParent(blockIndex === 0 ? IATModifiers.default : undefined),
    }),
  );
  blockNodes.reduce((acc, c) => c.transitionFrom(IATModifiers.default, acc));
  return blocks;
}

function createIatSingleBlockPhaseTree(
  iatStructure: IatStructure,
  blockDescriptor: BlockPhaseDescriptor<IatBlockPhase>,
) {
  const { block, blockNode } = blockDescriptor;

  const blockInstructionsContent = new InstructionsContent(
    block.instructions.welcomeScreenContent.content,
    block.instructions.welcomeScreenContent.nextButton,
  );

  const instructionsNode = new MapScreenTreeNode(
    'instructions',
    blockNode,
    block.instructions.welcomeScreenContent.withOptions
      ? new IatOptionsData(
          block.instructions.tips
            ? {
                content: block.instructions.tips.text,
                textStyle: block.instructions.tips.style,
              }
            : null,
          block.blockDescriptor.options,
          blockInstructionsContent,
        )
      : new IatInstructionsData(blockInstructionsContent, 'block'),
  )
    .withDisplayName(`Block ${blockDescriptor.blockIndex + 1} Instructions`)
    .connectParent(IATModifiers.default)
    .controlRequest(
      OptionsStereotypes.Confirm.Control.defineTransition(undefined, () =>
        DefaultTransitionAction.next(IATModifiers.default),
      ),
    );

  createBlockTrialsPhaseTree(iatStructure, blockDescriptor).transitionFrom(
    IATModifiers.default,
    instructionsNode,
  );

  return blockNode;
}
