import {
  ControlOccurrence,
  ControlRequest,
  ControlStereotype,
} from './control-stereotypes';
import { first, map, mergeAll, Observable, of, Subject } from 'rxjs';
import { ControlInputMonitor } from './control-monitor';
import { TimeoutEvent } from '../../../events/TimeoutEvent';
import { timeValue } from '../../../events/TimedEvent';
import { ReactionTimeProcessor } from './reaction-time-processor';

export interface ControlHandler<TriggerType, RP> {
  stereotype: ControlStereotype<TriggerType, RP>;

  subscribeRequest(
    request: ControlRequest<RP>,
  ): Observable<ControlOccurrence<TriggerType>>;

  tearDown(): void;
}

export class KeyControlHandler<TriggerType extends KeyboardEvent, RP>
  implements ControlHandler<TriggerType, RP>
{
  keyOccurrences: Subject<ControlOccurrence<TriggerType>>;
  constructor(
    private inputMonitor: ControlInputMonitor,
    public stereotype: ControlStereotype<TriggerType, RP>,
    public key: string,
  ) {
    this.keyOccurrences = new Subject<ControlOccurrence<TriggerType>>();
    this.inputMonitor.keyInputMonitor
      .subscribeKey(key)
      .pipe(map((k) => stereotype.controlOccurred(k as any)))
      .subscribe(this.keyOccurrences);
  }

  subscribeRequest(
    request: ControlRequest<RP>,
  ): Observable<ControlOccurrence<TriggerType>> {
    return this.keyOccurrences;
  }

  tearDown(): void {
    this.keyOccurrences.unsubscribe();
  }
}

export class DOMEventControlHandler implements ControlHandler<any, any> {
  eventOccurrences: Subject<ControlOccurrence<any>>;
  constructor(
    private inputMonitor: ControlInputMonitor,
    public stereotype: ControlStereotype<any, any>,
  ) {
    this.eventOccurrences = new Subject<ControlOccurrence<any>>();
    this.inputMonitor.domUiMonitor
      .subscribeKey(stereotype)
      .subscribe(this.eventOccurrences);
  }

  subscribeRequest(
    request: ControlRequest<any>,
  ): Observable<ControlOccurrence<MouseEvent>> {
    return this.eventOccurrences;
  }

  tearDown(): void {
    this.eventOccurrences.unsubscribe();
  }
}

export class TimeoutControlHandler
  implements ControlHandler<TimeoutEvent, number>
{
  // eventOccurrences: Subject<ControlOccurrence<TimeoutEvent>> = new Subject<ControlOccurrence<TimeoutEvent>>();
  constructor(
    private inputMonitor: ControlInputMonitor,
    public stereotype: ControlStereotype<TimeoutEvent, any>,
  ) {}

  subscribeRequest(
    request: ControlRequest<number>,
  ): Observable<ControlOccurrence<TimeoutEvent>> {
    return this.inputMonitor.timeoutMonitor
      .subscribe(request.trigger)
      .pipe(map((e) => this.stereotype.controlOccurred(timeValue(e))));
    // return this.eventOccurrences;
  }

  tearDown(): void {}
}
export class ControlHandlerManager {
  constructor(public handlers: ControlHandler<any, any>[]) {}

  raceRequests(
    controlRequests: ControlRequest<any>[],
    timeProcessor: ReactionTimeProcessor,
  ): Observable<ControlOccurrence<any>> {
    const raceObservables = controlRequests.map((r) => {
      return (
        this.handlers.find(
          (h) => h.stereotype === r.stereotype,
        ) as ControlHandler<any, any>
      ).subscribeRequest(r);
    });
    timeProcessor.processTime('raceStart');
    return of(...raceObservables).pipe(mergeAll(), first());
  }
}
