import { createRunnerConfiguration } from '../../../subject/testRunner/runner-configuration';
import { TestType } from '../../../subject/types';
import { PODTSelectorTree } from '../../PODT/runner/podt-selectors';
import { PodtaStructure } from '../../PODT/loader/structure/podt-structure';
import { createPodtViewMap } from '../../PODT/runner/podt-views';
import { DefaultPhaseRunner } from '../../../subject/testRunner/media/sequence-tasks';
import { ResultTableColumn } from '../../IAT/runner/result-preview-table';
import { PodtResult } from '../../PODT/types';
import { CreateOptionTestsControlHandlerFactory } from '../../../subject/testRunner/controls/options-control-handler-factory';
import { PodtaLoaderSequence } from '../loader/podta-loader-sequence';
import { PodtaResourceLoader } from '../loader/loading/podta-loader';

export const PodtaResultPreviewGroups: ResultTableColumn<PodtResult>[] = [
  {
    name: 'test id',
    accessor: (r) => r.testId,
  },
  {
    name: 'proband id',
    accessor: (r) => r.survey.probandId,
  },
  {
    name: 'provider',
    accessor: (r) => r.survey.testProviderName,
  },
  {
    name: 'blockIndex',
    accessor: (r) => r.blockIndex + '',
  },
  {
    name: 'blockName',
    accessor: (r) => r.blockName + '',
  },
  {
    name: 'trialIndex',
    accessor: (r) => r.trialIndex + '',
  },
  {
    name: 'category',
    accessor: (r) => r.target.category,
  },
  {
    name: 'dangerous',
    accessor: (r) => r.target.dangerous + '',
  },
  {
    name: 'trigger start',
    accessor: (r) => r.timing.startTriggerDelay + 'ms',
  },
  {
    name: 'action',
    accessor: (r) => r.actionResultName,
  },
  {
    name: 'points',
    accessor: (r) => r.accumulatedPoints + '',
  },
  {
    name: 'race start',
    accessor: (r) => (r.timing.controlRaceDelay ?? '-') + 'ms',
  },
  {
    name: 'render start',
    accessor: (r) => (r.timing.renderStartDelay ?? '-') + 'ms',
  },
  {
    name: 'render end',
    accessor: (r) => (r.timing.renderEndDelay ?? '-') + 'ms',
  },
];

export const PodtaRunnerConfig = createRunnerConfiguration(TestType.PODTA, {
  resultPreviewTable: PodtaResultPreviewGroups,
  selectorTree: PODTSelectorTree,
  controlFactory: (structure: PodtaStructure) =>
    CreateOptionTestsControlHandlerFactory(
      structure.accessor.testContext,
      structure.accessor.modelData.keyboard,
    ),
  createViews: createPodtViewMap,
  load: (testContext, gql, mediaManager) => {
    return PodtaLoaderSequence.performSequence(
      {
        load: {
          testContextFactory: async () => testContext,
          loader: PodtaResourceLoader.init(gql, mediaManager),
        },
        arrange: ({ accessor, parameters, pool }) => ({
          pool,
          accessor,
          parameters: parameters.determine(accessor.testContext),
        }),
      },
      new DefaultPhaseRunner(),
    );
  },
});
