import { MediaItemSnapshotInput } from '../../API';
import React, { FC } from 'react';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  Typography,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

interface MediaItemTextListProps {
  mediaItems: MediaItemSnapshotInput[];
  onRemove?: (i: number) => void;
}

export const MediaItemTextList: FC<MediaItemTextListProps> = ({
  mediaItems,
  onRemove,
}) => {
  const [showAll, setShowAll] = React.useState(false);
  const displayItems = mediaItems?.slice(0, showAll ? undefined : 2);
  return (
    <Box>
      <Typography
        sx={{ color: 'text.secondary', position: 'absolute', top: 0, right: 8 }}
      >
        {mediaItems?.length}
      </Typography>
      <List dense>
        {displayItems?.map((mI, i) => (
          <React.Fragment>
            <ListItem dense key={`text-media-item-${i}`}>
              <ListItemIcon>{i + 1}</ListItemIcon>
              <Typography>{mI.text}</Typography>
              {onRemove && (
                <ListItemSecondaryAction>
                  <IconButton size="small" onClick={() => onRemove(i)}>
                    <Delete fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            {displayItems?.length !== i - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
      {mediaItems?.length > 2 && (
        <React.Fragment>
          <Button size="small" onClick={() => setShowAll((p) => !p)}>
            {showAll ? 'Show Less' : '... Show All'}
          </Button>
        </React.Fragment>
      )}
    </Box>
  );
};
