import { PickingSet } from '../PickingSet';
import { InvalidStateError } from '../error/InvalidStateError';
import { PickingError } from '../error/PickingError';

export class CombinedPickingSet<T extends Record<string, any>>
  implements PickingSet<T>
{
  constructor(
    private attributeSets: { [t in keyof T]: PickingSet<T[t]> },
    public readonly name?: string,
  ) {
    this.attributeSets = attributeSets;
  }

  pick(): T {
    return Object.fromEntries(
      Object.entries(this.attributeSets).map(([k, as]) => [k, as.pick()]),
    ) as T;
  }

  validate(): void {
    try {
      Object.values(this.attributeSets).forEach((as) => as.validate());
    } catch (e) {
      throw new InvalidStateError(
        this,
        `Attribute Set is invalid: ${e}`,
        e instanceof PickingError ? e : undefined,
      );
    }
  }
}
