import {
  AppBar,
  Box,
  Button,
  LinearProgress,
  Toolbar,
  Typography,
} from '@mui/material';
import React from 'react';
import { EditorMode, TestStep } from './types';
import { TestType } from '../../../subject/types';
import MuiStepper from '@mui/material/Stepper';
import MuiStep from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Close, Save } from '@mui/icons-material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { FormikValues, useFormikContext } from 'formik';
import _ from 'lodash';
import { validateAllSteps } from './utils';
import { usePrompt } from '../../../../components/reactRouterFills.hook';

interface TestEditorBarProps<C> {
  testId: string;
  type: TestType;
  mode: EditorMode;
  errors: boolean[];
  stepIndex: number;
  steps: TestStep[];
  loading: boolean;
  nextStep: () => void;
  prevStep: () => void;
  goToStep: (i: number) => void;
  onClickCancel: () => void;
  initialValues: any;
  setErrors: (errors: boolean[]) => void;
  saveTest: (t: C) => any;
}

export function TestEditorBar<C extends FormikValues>(
  props: TestEditorBarProps<C>,
) {
  const editorContext = useFormikContext<C>();

  console.log('errors', editorContext.errors);

  const hasChanges =
    props.mode === 'edit' &&
    !_.isEqual(editorContext.values, props.initialValues);

  usePrompt(
    `
    Unsaved Changes!\n
    * Click on 'Ok' to leave the editor and loose all changes!\n
    * Click on 'Cancel' to stay, then save your test!
  `,
    hasChanges,
  );

  const handleSubmit = async () => {
    await editorContext.submitForm();
    const validationErrorResult = validateAllSteps(
      editorContext.values,
      props.steps.map((s) => s.editor),
    );
    props.setErrors(validationErrorResult);

    // we send the whole context values,
    // but we can't save the owner, viewers, editors, resultViewers via api,
    // but must use the lambda
    const strippedEditorContextValues = _.omit(editorContext.values, [
      'owner',
      'viewers',
      'editors',
      'resultViewers',
    ]) as C;

    if (validationErrorResult.some((e) => e)) {
      if (
        window.confirm(
          'Test not complete or has errors! Do you still want to save?',
        )
      ) {
        props.saveTest(strippedEditorContextValues);
      }
    } else {
      props.saveTest(strippedEditorContextValues);
    }
  };

  const handleNext = () => {
    editorContext
      .submitForm()
      .then(editorContext.validateForm)
      .then((res) => {
        if (Object.values(res).length === 0) {
          const newErrors = [...props.errors];
          newErrors[props.stepIndex] = false;
          props.setErrors(newErrors);
          props.nextStep();
        } else {
          const newErrors = [...props.errors];
          newErrors[props.stepIndex] = true;
          props.setErrors(newErrors);
        }
      });
  };

  return (
    <Box sx={{ flexGrow: 1, mb: 2, position: 'relative' }}>
      {props.loading && (
        <LinearProgress
          sx={{ position: 'absolute', width: '100%', top: 0, left: 0 }}
        />
      )}
      <AppBar
        position="fixed"
        color="inherit"
        sx={{ left: 216, mr: 2, width: 'auto' }}
      >
        <Toolbar sx={{ height: 112 }}>
          <div>
            <Typography color="text.secondary">
              {`${props.mode === 'edit' ? 'Editing' : 'Creating'}`}
            </Typography>
            <Typography variant="h6" color="text.primary">
              {props.initialValues.name}
            </Typography>
          </div>
          <MuiStepper
            sx={{ ml: 1, flexGrow: 1 }}
            activeStep={props.stepIndex}
            alternativeLabel
          >
            {props.steps.map((s, i) => (
              <MuiStep key={s.name}>
                <StepLabel
                  error={props.errors[i]}
                  onClick={() => props.goToStep(i)}
                  style={{ cursor: 'pointer' }}
                >
                  {s.name}
                </StepLabel>
              </MuiStep>
            ))}
          </MuiStepper>
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <Button
              disabled={props.stepIndex === 0 || props.loading}
              onClick={props.prevStep}
              startIcon={<NavigateBeforeIcon fontSize="large" />}
              title="Go to previous step"
            >
              Back
            </Button>
            <Button
              disabled={
                props.stepIndex === props.steps.length - 1 || props.loading
              }
              onClick={handleNext}
              endIcon={<NavigateNextIcon fontSize="large" />}
              title="Go to next step"
            >
              Next
            </Button>
          </Box>
          {hasChanges && (
            <Button
              variant="text"
              disabled={props.loading}
              sx={{ color: 'warning.dark', mr: 2 }}
              onClick={() => editorContext.resetForm()}
            >
              Discard All Changes
            </Button>
          )}
          <Button
            sx={{ color: 'success.light' }}
            disabled={props.loading}
            startIcon={<Save />}
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            sx={(theme) => ({ color: theme.palette.action.active })}
            disabled={props.loading}
            startIcon={<Close />}
            onClick={props.onClickCancel}
          >
            Go Back
          </Button>
        </Toolbar>
      </AppBar>
      <Toolbar sx={{ height: 96 }} />
    </Box>
  );
}
