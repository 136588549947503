import React, { FunctionComponent } from 'react';
import { Grid } from '@mui/material';
import * as Yup from 'yup';
import Section from '../display/Section';
import KeyboardButtonPicker from './keyboardPicker/KeyboardButtonPicker';

export const keyboardSchema = Yup.object()
  .required('required')
  .shape({
    left: Yup.string().required('Select the left button.'),
    right: Yup.string().required('Select the right button.'),
    confirm: Yup.string().required('Select the start button.'),
  });

type KeyboardPikckerProps = {
  leftButtonToolTip?: string;
  rightButtonToolTip?: string;
  startButtonToolTip?: string;
};
const KeyboardPicker: FunctionComponent<KeyboardPikckerProps> = (props) => {
  return (
    <Section label="Keyboard Settings" bigLabel>
      <Grid container spacing={3} style={{ marginTop: -12 }}>
        <Grid item xs={12} sm={4}>
          <KeyboardButtonPicker
            name="keyboard.left"
            label="Left Button"
            description="Press a keyboard button to set the LEFT BUTTON."
            toolTip={props.leftButtonToolTip}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <KeyboardButtonPicker
            name="keyboard.right"
            label="Right Button"
            description="Press a keyboard button to set the RIGHT BUTTON."
            toolTip={props.rightButtonToolTip}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <KeyboardButtonPicker
            name="keyboard.confirm"
            label="Start Button"
            description="press a keyboard button to set the start Button"
            toolTip={props.startButtonToolTip}
          />
        </Grid>
      </Grid>
    </Section>
  );
};

export default KeyboardPicker;
