import { Dialog, DialogTitle, Typography } from '@mui/material';
import { SurveyProviderForm } from './SurveyProviderForm';
import React, { useState } from 'react';
import {
  CreateSurveyProviderInput,
  UpdateSurveyProviderInput,
} from '../../API';

export function UpsertSurveyProviderDialog({
  title,
  onSave,
  actionLabel,
  isLoading,
  button,
  initialValues,
}: {
  title: string;
  isLoading: boolean;
  onSave: (input: CreateSurveyProviderInput) => Promise<unknown> | void;
  actionLabel: string;
  button: (onClick: () => void) => React.ReactNode;
  initialValues?: Partial<UpdateSurveyProviderInput>;
}) {
  const [open, setOpen] = useState(false);
  const buttonElement = React.useMemo(
    () => button(() => setOpen(true)),
    [button],
  );
  return (
    <>
      {buttonElement}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h5">{title}</Typography>
        </DialogTitle>
        <SurveyProviderForm
          initialValues={initialValues}
          onSave={async (input) => {
            await onSave(input);
            setOpen(false);
          }}
          actionLabel={actionLabel}
          loading={isLoading}
          onCancel={() => setOpen(false)}
        />
      </Dialog>
    </>
  );
}
