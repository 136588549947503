import {
  Button,
  ButtonProps,
  DialogActions as MuiDialogActions,
  LinearProgress,
} from '@mui/material';

type DialogActionsProps = {
  loading: boolean;
  onConfirm: () => void;
  confirmLabel?: string;
  confirmDisabled?: boolean;
  confirmColor?: ButtonProps['color'];
  onCancel: () => void;
};

export const DialogActions = ({
  loading,
  onConfirm,
  confirmLabel = 'Save',
  confirmDisabled,
  confirmColor,
  onCancel,
}: DialogActionsProps) =>
  loading ? (
    <LinearProgress />
  ) : (
    <MuiDialogActions>
      <Button onClick={onCancel} variant="contained" color="inherit">
        Cancel
      </Button>
      <Button
        variant="contained"
        disabled={confirmDisabled}
        color={confirmColor ?? 'primary'}
        onClick={onConfirm}
      >
        {confirmLabel}
      </Button>
    </MuiDialogActions>
  );
