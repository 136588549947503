import { Form, Formik } from 'formik';
import React, { FC } from 'react';
import { RegisterUserMutationVariables } from '../../API';
import * as yup from 'yup';
import { Button, Stack, Typography } from '@mui/material';
import StyledTextInput from '../../components/input/StyledTextInput';

const userRegistrationValidationSchema = yup.object<
  Omit<RegisterUserMutationVariables, 'confirmedUsagePolicy'>
>({
  name: yup
    .string()
    .min(2, 'Needs to be at least 2 characters long')
    .required('Is required'),
  email: yup.string().email('Invalid Email').required('Is required'),
  institution: yup
    .string()
    .min(2, 'Needs to be at least 2 characters long')
    .required('Is required'),
  researchGoal: yup
    .string()
    .min(5, 'Needs to be at least 5 characters long')
    .required('Is required'),
  additionalInformation: yup.string(),
});

export const RegistrationDataForm: FC<{
  name: string;
  currentData: Partial<RegisterUserMutationVariables>;
  back?: (dataUpdate?: Partial<RegisterUserMutationVariables>) => void;
  next: (
    updateData: Partial<RegisterUserMutationVariables>,
    done: boolean,
  ) => void;
}> = ({ next, currentData, back }) => {
  return (
    <Stack spacing={2}>
      <Typography variant="subtitle1">Your data</Typography>
      <Typography variant="body2">
        Please provide us with the following information:
      </Typography>
      <Formik<Omit<RegisterUserMutationVariables, 'confirmedUsagePolicy'>>
        initialValues={
          {
            name: '',
            email: '',
            additionalInformation: '',
            institution: '',
            researchGoal: '',
            ...currentData,
          } as Omit<RegisterUserMutationVariables, 'confirmedUsagePolicy'>
        }
        validationSchema={userRegistrationValidationSchema}
        onSubmit={(res) => {
          next(res, false);
        }}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Stack spacing={2} sx={{ width: '100%' }}>
              <StyledTextInput required name={'name'} label="Name" />
              <StyledTextInput
                required
                type="email"
                name={'email'}
                label="E-Mail"
              />
              <StyledTextInput
                required
                name="institution"
                label="Institution"
              />
              <StyledTextInput
                multiline
                rows={5}
                name="researchGoal"
                required
                label="Research Goal"
              />
              <StyledTextInput
                multiline
                rows={4}
                name="additionalInformation"
                label="Additional Information"
              />
            </Stack>
            <Stack
              direction="row"
              sx={{
                width: '100%',
                justifyContent: 'space-between',
                marginTop: '1.5rem',
              }}
            >
              <Button
                onClick={() => {
                  back?.(props.values);
                }}
              >
                Back
              </Button>
              <Button
                onClick={() => {
                  props.submitForm();
                }}
              >
                Next
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};
