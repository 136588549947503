import React from 'react';
import { Box, Stack } from '@mui/material';
import * as Yup from 'yup';
import StyledNumberInput from '../../../../../components/input/StyledNumberInput';
import { EditorStepTitle } from '../../../../../components/grid';
import StyledTextInput from '../../../../../components/input/StyledTextInput';
import StyledSelect from '../../../../../components/input/StyledSelect';
import { OptionsPosition } from '../../../../../API';
import ColorInput from '../../../../../components/input/ColorInput';
import { BasicGameSettingsSchema } from '../../../testAdmin/testEditor/utils';
import { Wysiwyg } from '../../../../../components/input/wysiwyg/Wysiwyg';
import KeyboardButtonPicker from '../../../../../components/input/keyboardPicker/KeyboardButtonPicker';
import { AmpVariables } from '../../../text-replacer/textReplacer';
import { TestLanguagePicker } from '../../../../../components/input/TestLanguagePicker';

export const GameSettingsSchema = Yup.object().shape({
  ...BasicGameSettingsSchema,
  endScreen: Yup.object({
    desktop: Yup.string().required('create a End Screen'),
  }),
});

const AmpGameSettings = () => {
  return (
    <Box>
      <EditorStepTitle prefix={1} title="Test Settings" />
      <Stack spacing={4}>
        <Stack spacing={3} direction="row">
          <Box flex={1}>
            <StyledTextInput name="name" label="Name" />
          </Box>
          <Box flex={1}>
            <StyledTextInput
              name="description"
              label="Description"
              multiline
              toolTip={'test_editor_description_help'}
            />
          </Box>
          <Box flex={1}>
            <TestLanguagePicker />
          </Box>
        </Stack>
        <Stack direction="row" spacing={3}>
          <Wysiwyg
            name="instructions"
            label="Welcome Screen"
            toolTip={'amp_editor_welcome_screen_help'}
            variables={AmpVariables}
          />
          <Wysiwyg
            name="endScreen"
            label="End Screen"
            toolTip={'amp_editor_endscreen_screen_help'}
            variables={AmpVariables}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <Box width="50%">
            <Stack spacing={4} direction="column">
              <Stack direction="row" spacing={3}>
                <StyledSelect
                  name="style.optionsPosition"
                  label="Attributes-Position"
                  // toolTip={t("test_editor_tooltip_style_options_positions")}
                >
                  <option value={OptionsPosition.TOP}>Top</option>
                  <option value={OptionsPosition.MIDDLE}>Mid</option>
                  <option value={OptionsPosition.BOTTOM}>Bottom</option>
                </StyledSelect>
                <StyledSelect
                  name="style.optionsPositionMobile"
                  label="Attributes-Position Mobile"
                >
                  <option value={OptionsPosition.TOP}>top</option>
                  <option value={OptionsPosition.MIDDLE}>middle</option>
                  <option value={OptionsPosition.BOTTOM}>bottom</option>
                </StyledSelect>
                <Box sx={{ width: 300 }}>
                  <StyledNumberInput
                    name="style.maxWidth"
                    label="Width"
                    unit="%"
                  />
                </Box>
              </Stack>
              <ColorInput name="style.backgroundColour" label="Background" />
            </Stack>
          </Box>
          <Box width="50%">
            <Stack spacing={4}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={'space-between'}
              >
                <KeyboardButtonPicker
                  name="keyboard.left"
                  label="Left Button"
                  description="Press a keyboard button to set the LEFT BUTTON."
                />
                <KeyboardButtonPicker
                  name="keyboard.right"
                  label="Right Button"
                  description="Press a keyboard button to set the RIGHT BUTTON."
                />
                <KeyboardButtonPicker
                  name="keyboard.confirm"
                  label="Start Button"
                  description="Press a keyboard button to set the START BUTTON."
                />
              </Box>
            </Stack>
            <Box
              sx={{ pt: 4, display: 'flex', justifyContent: 'space-between' }}
            >
              <StyledTextInput
                fullWidth
                name={'startLabel'}
                label={'Start Label'}
              />
              <StyledTextInput
                fullWidth
                name={'nextLabel'}
                label={'Next Label'}
              />
              <StyledTextInput
                fullWidth
                name={'finishLabel'}
                label={'Finish Label'}
              />
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AmpGameSettings;
