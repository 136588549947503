import { DisplayData, RenderDataType } from './data-wrapper';
import { ScreenTreeNode } from '../../graph/nodes/screen-tree-node';
import { SelectorDefinition } from './selector-definition';
import { Selector } from './selector';

export class SelectorTree<D extends SelectorDefinition<any, any, any>> {
  readonly rootSelector: D extends SelectorDefinition<any, any, infer S>
    ? S extends Selector<any>
      ? S
      : never
    : never;
  readonly selectors: Selector<any>[];
  constructor(name: string, selectorDefinition: D) {
    // console.log("creae root selector", selectorDefinition);
    this.rootSelector = selectorDefinition.toSelector(name, undefined);
    const walkSelectors = (s: Selector<any>): Selector<any>[] => {
      return [
        s,
        ...s.selectorInstance.children.flatMap((c) => walkSelectors(c)),
      ];
    };
    this.selectors = walkSelectors(this.rootSelector);
  }
  processNodes(nodes: ScreenTreeNode[]): SelectorNodeMap {
    return new SelectorNodeMap(this.selectors, nodes);
  }
}
export class SelectorNodeMap {
  private map: Map<Selector<any>, ScreenNodeDataMap<any>> = new Map();

  constructor(selectors: Selector<any>[], nodes: ScreenTreeNode[]) {
    this.map = new Map(
      selectors.map((s) => [s, new ScreenNodeDataMap(s, nodes)]),
    );
  }

  getNodeMap<S>(selector: Selector<S>): ScreenNodeDataMap<S> {
    return this.map.get(selector)!;
  }
}
export class ScreenNodeDataMap<R> {
  public selector: Selector<R>;
  public dataSet: Set<RenderDataType<R>> = new Set();
  private map: Map<ScreenTreeNode, RenderDataType<R>> = new Map();

  constructor(selector: Selector<R>, nodes: ScreenTreeNode[]) {
    this.selector = selector;
    const processNode = (node: ScreenTreeNode) => {
      // console.log("process node", node);
      const nodeData = selector.selectorInstance.selectRenderData(
        node.screenData,
      );
      const dataProto = Array.from(this.dataSet).find((dp) =>
        DisplayData.compare(dp, nodeData),
      );
      if (!dataProto) {
        this.dataSet.add(nodeData);
        return nodeData;
      }
      return dataProto;
    };
    this.map = new Map(nodes.map((data) => [data, processNode(data)] as const));
  }

  getData(node: ScreenTreeNode): RenderDataType<R> {
    return this.map.get(node)!;
  }

  getDisplayDataSet(): ['display', ['value', R]][] {
    return Array.from(this.dataSet).filter(DisplayData.isDisplayData);
  }
}
