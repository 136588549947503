import React from 'react';
import { Box, Stack } from '@mui/material';
import * as Yup from 'yup';
import StyledSwitch from '../../../../../components/input/StyledSwitch';
import StyledTextInput from '../../../../../components/input/StyledTextInput';
import StyledNumberInput from '../../../../../components/input/StyledNumberInput';
import ColorInput from '../../../../../components/input/ColorInput';
import StyledSelect from '../../../../../components/input/StyledSelect';
import {
  CategoryPreviewDisplay,
  IatFeedbackType,
  OptionsPosition,
} from '../../../../../API';
import { EditorStepTitle } from '../../../../../components/grid';
import { Wysiwyg } from '../../../../../components/input/wysiwyg/Wysiwyg';
import { BasicGameSettingsSchema } from '../../../testAdmin/testEditor/utils';
import KeyboardButtonPicker from '../../../../../components/input/keyboardPicker/KeyboardButtonPicker';
import { IatVariables } from '../../../text-replacer/textReplacer';
import { useField } from 'formik';
import { TestLanguagePicker } from '../../../../../components/input/TestLanguagePicker';

export const IatGameSettingsSchema = Yup.object().shape({
  ...BasicGameSettingsSchema,
  endScreen: Yup.object({
    desktop: Yup.string().required('create a End Screen'),
  }),
});

const IatGameSettings = () => {
  const [meta] = useField<IatFeedbackType>('feedback');
  console.log(meta);
  return (
    <Box>
      <EditorStepTitle prefix={1} title="Test Settings" />
      <Stack spacing={4}>
        <Stack spacing={3} direction="row">
          <Box flex={1}>
            <StyledTextInput
              name="name"
              label="Name"
              toolTip="Name of the Test."
            />
          </Box>
          <Box flex={1}>
            <StyledTextInput
              name="description"
              label="Description"
              multiline
              toolTip={
                'Text will be shown only in MINDset, not visible for respondents.'
              }
            />
          </Box>
          <Box flex={1}>
            <TestLanguagePicker />
          </Box>
        </Stack>
        <Stack direction="row" spacing={3}>
          <Wysiwyg
            name="instructions"
            label="Welcome Screen"
            toolTip={
              'Text will be shown as Welcome message on the first page when entering the test'
            }
            variables={IatVariables}
          />
          <Wysiwyg
            name="endScreen"
            label="End Screen"
            toolTip={
              'Text will be shown at the end of the test (e.g., last screen before redirection to survey)'
            }
            variables={IatVariables}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <Box width="50%">
            <Stack spacing={4} direction="column">
              <Stack direction="row" spacing={3}>
                <StyledSelect
                  name={'previewCategories'}
                  label={'Category Preview'}
                  toolTip={
                    'Choose whether categories and stimuli should be presented or not, and if so, whether with the intro screen or on a seperate screen.'
                  }
                >
                  <option value={CategoryPreviewDisplay.NONE}>None</option>
                  <option value={CategoryPreviewDisplay.INSTRUCTIONS}>
                    In Instructions
                  </option>
                  <option value={CategoryPreviewDisplay.STAND_ALONE}>
                    In separate screen
                  </option>
                </StyledSelect>
                <StyledSwitch
                  name="counterbalancing"
                  label="Counterbalancing"
                  left="Off"
                  right="On"
                  tooltip={
                    'Counterbalancing varies on which side of the screen the target categories A and B are displayed, so that half of the sample starts with category A on the left and right side of the screen.'
                  }
                />
              </Stack>
              <Stack direction="row" spacing={3}>
                <StyledSelect
                  name="style.optionsPosition"
                  label="Attributes-Position"
                  // toolTip={t("test_editor_tooltip_style_options_positions")}
                >
                  <option value={OptionsPosition.TOP}>Top</option>
                  <option value={OptionsPosition.MIDDLE}>Mid</option>
                  <option value={OptionsPosition.BOTTOM}>Bottom</option>
                </StyledSelect>
                <StyledSelect
                  name="style.optionsPositionMobile"
                  label="Attributes-Position Mobile"
                >
                  <option value={OptionsPosition.TOP}>top</option>
                  <option value={OptionsPosition.MIDDLE}>middle</option>
                  <option value={OptionsPosition.BOTTOM}>bottom</option>
                </StyledSelect>
                <Box sx={{ width: 300 }}>
                  <StyledNumberInput
                    name="style.maxWidth"
                    label="Width"
                    unit="%"
                  />
                </Box>
              </Stack>
              <ColorInput name="style.backgroundColour" label="Background" />
            </Stack>
          </Box>
          <Box width="50%">
            <Stack spacing={4}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={'space-between'}
              >
                <KeyboardButtonPicker
                  name="keyboard.left"
                  label="Left Button"
                  description="Press a keyboard button to set the LEFT BUTTON."
                  active="left"
                />
                <KeyboardButtonPicker
                  name="keyboard.right"
                  label="Right Button"
                  description="Press a keyboard button to set the RIGHT BUTTON."
                  active="right"
                />
                <KeyboardButtonPicker
                  name="keyboard.confirm"
                  label="Start Button"
                  description="Press a keyboard button to set the START BUTTON."
                />
              </Box>
            </Stack>
            <Box
              sx={{ pt: 4, display: 'flex', justifyContent: 'space-between' }}
            >
              <StyledTextInput
                fullWidth
                name={'startLabel'}
                label={'Start Label'}
              />
              <StyledTextInput
                fullWidth
                name={'nextLabel'}
                label={'Next Label'}
              />
              <StyledTextInput
                fullWidth
                name={'finishLabel'}
                label={'Finish Label'}
              />
            </Box>
            <Box sx={{ pt: 4 }}>
              <StyledSelect
                name={'feedback'}
                label={'Feedback'}
                toolTip="Specify whether respondents are shown a red X for incorrect responses and whether respondents must correct incorrect response."
              >
                <option value={IatFeedbackType.BLOCKING}>
                  Respondent is not shown a red X after an incorrect response,
                  next trial after correct response
                </option>
                <option value={IatFeedbackType.FEEDBACK_BLOCKING}>
                  Respondent is shown a red X after an incorrect response, next
                  trial after correct response
                </option>
                <option value={IatFeedbackType.NON_BLOCKING}>
                  Respondent is not shown a red X after an incorrect response,
                  next trial without correct response
                </option>

                <option value={IatFeedbackType.FEEDBACK_NON_BLOCKING}>
                  Respondent is shown a red X after an incorrect response, next
                  trial without correct response
                </option>
              </StyledSelect>
            </Box>
            {meta.value === IatFeedbackType.FEEDBACK_NON_BLOCKING && (
              <Box sx={{ pt: 4 }}>
                <StyledNumberInput
                  name="feedbackInterval"
                  label="Red X Duration"
                  unit="ms"
                />
              </Box>
            )}
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default IatGameSettings;
