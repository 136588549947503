import { AmpResult } from "../types";
import { ResultTableColumn } from "../../IAT/runner/result-preview-table";

export const AmpResultPreviewTable: ResultTableColumn<AmpResult>[] = [
    {
      name: "blockIndex",
      accessor: (r) => r.blockIndex + ""
    },
    {
      name: "blockName",
      accessor: (r) => r.blockName + ""
    },
    {
      name: "trialIndex",
      accessor: (r) => r.trialIndex + ""
    },
    {
      name: "trigger start",
      accessor: (r) => r.timing.startTriggerDelay + "ms"
    }, {
      name: "action",
      accessor: (r) => r.actionResultName
    }, {
      name: "race start",
      accessor: (r) => (r.timing.controlRaceDelay ?? "-") + "ms"
    }, {
      name: "render start",
      accessor: (r) => (r.timing.renderStartDelay ?? "-") + "ms"
    }, {
      name: "render end",
      accessor: (r) => (r.timing.renderEndDelay ?? "-") + "ms"
    }, {
      name: "primeLabel",
      accessor: (r) => r.prime.label
    },
    {
      name: "primeMediaItemId",
      accessor: (r) => r.prime.mediaItem?.id ?? ""
    },
    {
      name: "targetLabel",
      accessor: (r) => r.target.label
    },
    {
      name: "targetMediaItemId",
      accessor: (r) => r.target.mediaItem?.id ?? ""
    },
    {
      name: "actionResultName",
      accessor: (r)  =>  r.actionResultName
    },
    {
      name: "attributeLeft",
      accessor: (r)  =>  r.attributeLeft
    },
    {
      name: "attributeRight",
      accessor: (r)  =>  r.attributeRight
    }
];
