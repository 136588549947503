import { SurveyProvider } from '../../../API';
import { TestType } from '../../subject/types';
import { BaseTest } from '../types';
import { useState } from 'react';
import {
  isValidSurveyIdInput,
  SurveyProviderStaticSurveyIdInput,
} from './SurveyProviderStaticSurveyIdInput';
import { SurveyProviderInstructionSteps } from './SurveyProviderInstructionSteps';
import { SetupStepTimelineItem } from './SetupStepTimelineItem';
import { Stack, Typography } from '@mui/material';

export function SurveyProviderInstructionsView({
  surveyProvider,
  testType,
  test,
}: {
  surveyProvider: SurveyProvider;
  testType: TestType;
  test: BaseTest;
}) {
  const [surveyId, setSurveyId] = useState(
    surveyProvider.snippetConfiguration.surveyIdReplacer ?? '',
  );
  return (
    <>
      {Boolean(surveyProvider.snippetConfiguration.surveyIdReplacer) || (
        <SetupStepTimelineItem
          stepIndex={1}
          stepContent={
            <Stack spacing={1}>
              <Typography variant="h5">
                Please enter the id of the survey
              </Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ pb: 2 }}
              >
                This survey tool does not support automatic linking between the
                id of the survey in the tool and MIND.set.
              </Typography>
              <SurveyProviderStaticSurveyIdInput
                currentId={surveyId}
                setCurrentId={setSurveyId}
              />
            </Stack>
          }
        />
      )}
      {!surveyId &&
        !(
          surveyProvider.snippetConfiguration.surveyIdReplacer ||
          isValidSurveyIdInput(surveyId)
        ) && <SetupStepTimelineItem stepIndex={3} stepContent={<>...</>} />}
      {surveyId &&
        (surveyProvider.snippetConfiguration.surveyIdReplacer ||
          isValidSurveyIdInput(surveyId)) && (
          <SurveyProviderInstructionSteps
            test={test}
            testType={testType}
            surveyId={surveyId}
            surveyProvider={surveyProvider}
          />
        )}
    </>
  );
}
