import { createRunnerConfiguration } from '../../../subject/testRunner/runner-configuration';
import { TestType } from '../../../subject/types';
import { WSWSelectorTree } from './wsw-selectors';
import { createWswViewMap } from './views/wsw-views';
import { WSWLoaderSequence } from '../loader/wsw-loader-sequence';
import { WSWResourceLoader } from '../loader/loading/wsw-loader';
import { DefaultPhaseRunner } from '../../../subject/testRunner/media/sequence-tasks';
import { WswResultPreviewGroups } from './wsw-result-preview-groups';
import { WSWControlFactory } from './wsw-control-factory';

export const WswRunnerConfig = createRunnerConfiguration(TestType.WSW, {
  resultPreviewTable: WswResultPreviewGroups,
  selectorTree: WSWSelectorTree,
  controlFactory: WSWControlFactory,
  createViews: createWswViewMap,
  load: (testContext, gql, mediaManager) => {
    return WSWLoaderSequence.performSequence(
      {
        load: {
          testContextFactory: async () => testContext,
          loader: WSWResourceLoader.init(gql, mediaManager),
        },
        arrange: ({ accessor, parameters, pool }) => ({
          pool,
          accessor,
          parameters: parameters.determine(accessor.testContext),
        }),
      },
      new DefaultPhaseRunner(),
    );
  },
});
