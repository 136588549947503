import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import StyledNumberInput from '../../../../../components/input/StyledNumberInput';
import { useField } from 'formik';
import { GroupConstructionConditionsInput } from '../../../../../API';
import InfoMessage from '../../../../../components/InfoMessage';

const probabilityNumberFormat = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
  minimumIntegerDigits: 1,
});

const getProbabilities = (
  conditions: GroupConstructionConditionsInput | undefined | null,
): [number, number, number] => {
  if (!conditions) {
    return [0, 0, 0];
  }
  const total =
    conditions.condition1 + conditions.condition2 + conditions.condition3;
  const prob1 = (conditions.condition1 / total) * 100;
  const prob2 = (conditions.condition2 / total) * 100;
  const prob3 = (conditions.condition3 / total) * 100;

  return [prob1, prob2, prob3];
};

export const ConditionsInput: FC<{ fieldName: string }> = ({ fieldName }) => {
  const [, meta] = useField<
    GroupConstructionConditionsInput | undefined | null
  >(fieldName);

  const [prob1, prob2, prob3] = getProbabilities(meta.value);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <InfoMessage
          message={
            'There is the possibility to randomly assign respondents to one of the experimental groups.\n' +
            'Below you can define relative probabilities for three different conditions that should or should not be exercised in the test construction.\n' +
            'This means if the probability for each condition is 1 (~33%) then each condition is equally likely for each respondent.'
          }
        />
      </Grid>
      <Grid item xs={12} container spacing={3}>
        <Grid item xs={4}>
          <StyledNumberInput
            onFocus={(event) => {
              event.target.select();
            }}
            name={`${fieldName}.condition1`}
            label={'Condition 1 (no group assignment)'}
            toolTip={
              'In this condition, targets will be categorized in learning and test phase, but respondents will not be assigned to any of the manipulated groups.'
            }
            unit={`(${probabilityNumberFormat.format(prob1)}%)`}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledNumberInput
            name={`${fieldName}.condition2`}
            label={'Condition 2 (group assignment before learning phase)'}
            onFocus={(event) => {
              event.target.select();
            }}
            toolTip={
              'In this condition, targets AND respondents will be categorized as group members in learning and test phase (group assignment of respondents before learning phase)'
            }
            unit={`(${probabilityNumberFormat.format(prob2)}%)`}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledNumberInput
            name={`${fieldName}.condition3`}
            label={'Condition 3 (group assignment after learning phase)'}
            onFocus={(event) => {
              event.target.select();
            }}
            toolTip={
              'In this condition, targets will be categorized in learning and test phase, and respondents will be categorized only in test phase (group assignment of respondents after learning phase and before test phase)'
            }
            unit={`(${probabilityNumberFormat.format(prob3)}%)`}
          />
        </Grid>
        {meta.error && (
          <Grid item xs={12}>
            <Typography color="error">{meta.error}</Typography>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};
