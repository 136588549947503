import React, { ReactNode } from 'react';
import {
  CssBaseline as MuiCssBaseline,
  ThemeProvider as MuiThemeProvider,
  Theme,
  StyledEngineProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: "'Overpass Mono', 'Overpass'",
      fontWeightLight: 300,
    },
    palette: {
      primary: {
        main: 'rgba(0, 110, 120,1)',
        light: '#54a8dd',
        dark: '#004d7c',
      },
      secondary: {
        main: 'rgba(0,110,120,1)',
      },
      background: {
        default: '#EFEFEF',
        paper: '#FFFFFF',
      },
      warning: {
        main: '#E6B100',
      },
      error: {
        main: '#B01A1F',
      },
      success: {
        main: '#005400',
      },
      text: {
        primary: 'rgba(30, 95, 70, 0.87)',
        secondary: 'rgba(30, 95, 70, 0.60)',
        disabled: '#C4C4C4',
      },
      grey: {
        '300': '#E5E5E5',
        '400': '#C4C4C4',
        '500': '#767676',
        '600': '#4D4D4D',
        '700': 'rgba(0, 0, 0, 0.7)',
        '900': '#1C1C1C',
      },
    },
    components: {
      MuiDialogContent: {
        styleOverrides: { root: { overflow: 'visible' } },
      },
    },
  }),
);

const ThemeProvider = (props: { children: ReactNode }): JSX.Element => (
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={theme}>
      <MuiCssBaseline />
      {props.children}
    </MuiThemeProvider>
  </StyledEngineProvider>
);

export default ThemeProvider;
