import {
  WswConstructedGroup,
  WswGroupConstructionPhase,
  WswStructure,
} from '../structure/wsw-structure';
import {
  ControlOccurrenceProcessor,
  DefaultTransitionAction,
} from '../../../../subject/testRunner/graph-state/graph-action';
import { QuestionAnswerEvent, WswStereotypes } from '../wsw-controls';
import {
  createGroupIndicationResult,
  createWSWResultQuery,
} from './wsw-tree-utils';
import {
  CreateWswResultMutationVariables,
  WswContentType,
  WswResult,
  WswTestPhase,
} from '../../../../../API';
import { WSWModifiers } from './wsw-modifiers';
import { GQLQueryInstance } from '../../../../../GQL';
import { createQuestionSetSubTree } from './wsw-tree-question-set';
import { chooseTemplate } from '../structure/utils';
import { WswInstructionsData } from '../view-data/wsw-instructions-data';
import { MapScreenTreeNode } from '../../../../subject/testRunner/graph/nodes/map-screen-tree-node';
import { MapPhaseTreeNode } from '../../../../subject/testRunner/graph/nodes/map-phase-tree-node';

function createManipulationCheckQuestionsSubTree(
  groupPhase: WswGroupConstructionPhase,
  parentPhaseNode: MapPhaseTreeNode,
  constructuedGroup: WswConstructedGroup,
  createResultQuery: (
    control: ControlOccurrenceProcessor<QuestionAnswerEvent>,
    preDetermination: boolean,
    trial: number,
  ) => GQLQueryInstance<CreateWswResultMutationVariables, WswResult>,
  nodeAfter?: MapPhaseTreeNode | MapScreenTreeNode,
) {
  return groupPhase.manipulationCheckQuestionSet.questionsBefore.length > 0
    ? createQuestionSetSubTree(
        groupPhase.manipulationCheckQuestionSet,
        parentPhaseNode,
        {
          groupIndication: constructuedGroup,
          showPlainStimulusBeforeQuestion: true,
          resultQuery: (control, order, questionIndex) =>
            createResultQuery(control, false, questionIndex),
        },
        {
          nodeAfter,
        },
      )
    : undefined;
}

function createFakeQuestionsSubTree(
  groupPhase: WswGroupConstructionPhase,
  parentPhaseNode: MapPhaseTreeNode,
  createResultQuery: (
    control: ControlOccurrenceProcessor<QuestionAnswerEvent>,
    preDetermination: boolean,
    trial: number,
  ) => GQLQueryInstance<CreateWswResultMutationVariables, WswResult>,
  nodeAfter: MapPhaseTreeNode | MapScreenTreeNode,
) {
  return groupPhase.fakeQuestionSet.questionsBefore.length > 0
    ? createQuestionSetSubTree(
        groupPhase.fakeQuestionSet,
        parentPhaseNode,
        {
          resultQuery: (control, order, questionIndex) =>
            createResultQuery(control, true, questionIndex),
          showPlainStimulusBeforeQuestion: true,
        },
        {
          nodeAfter: nodeAfter,
          parentConnector: groupPhase.startInstructions
            ? undefined
            : WSWModifiers.default,
        },
      ).questionSetPhaseNode
    : undefined;
}

export function createGroupPhaseTree(
  root: MapPhaseTreeNode,
  structure: WswStructure,
  groupPhase: WswGroupConstructionPhase,
) {
  const constructedGroup = groupPhase.resultingGroup;
  const groupConstructionPhaseNode = new MapPhaseTreeNode(
    'group-construction',
    root,
  ).connectParent();

  const createResultQuery = (
    control: ControlOccurrenceProcessor<QuestionAnswerEvent>,
    preDetermination: boolean,
    trial: number,
  ) => {
    return createWSWResultQuery(structure, control, {
      groupIndication: createGroupIndicationResult(
        groupPhase.resultingGroup,
        !preDetermination,
      ),
      phase: preDetermination
        ? WswTestPhase.PreGroupDetermination
        : WswTestPhase.PostGroupDetermination,
      position: groupPhase.position === 'before-learning' ? 0 : 1,
      trialIndex: trial,
      contentType: WswContentType.Stimulus,
    });
  };

  const feedbackScreenPhase = new MapPhaseTreeNode(
    'group-feedback',
    groupConstructionPhaseNode,
  );

  const questionsPhaseNode = createFakeQuestionsSubTree(
    groupPhase,
    groupConstructionPhaseNode,
    createResultQuery,
    feedbackScreenPhase,
  );

  feedbackScreenPhase.connectParent(
    groupPhase.startInstructions || questionsPhaseNode
      ? undefined
      : WSWModifiers.default,
  );

  new MapScreenTreeNode(
    'feedback-screen',
    feedbackScreenPhase,
    new WswInstructionsData(
      {
        content: chooseTemplate(
          structure.accessor.testContext,
          groupPhase.resultingGroup.group.feedbackScreen as any,
          structure.config.groups?.groups ?? null,
        ),
        nextButton: structure.accessor.modelData.nextLabel,
      },
      constructedGroup,
    ),
  )
    .controlRequest(
      WswStereotypes.Confirm.defineTransition(undefined, () =>
        DefaultTransitionAction.next(WSWModifiers.default),
      ),
    )
    .connectParent(WSWModifiers.default);

  const manipulationCheckPhase = createManipulationCheckQuestionsSubTree(
    groupPhase,
    groupConstructionPhaseNode,
    constructedGroup,
    createResultQuery,
  );

  if (groupPhase.startInstructions) {
    new MapScreenTreeNode(
      'start-instructions',
      groupConstructionPhaseNode,
      new WswInstructionsData(groupPhase.startInstructions),
    )
      .controlRequest(
        WswStereotypes.Confirm.defineTransition(undefined, () =>
          DefaultTransitionAction.next(WSWModifiers.default),
        ),
      )
      .connectParent(WSWModifiers.default)
      .transition(
        WSWModifiers.default,
        questionsPhaseNode ?? feedbackScreenPhase,
      );
  }
  if (groupPhase.endInstructions) {
    new MapScreenTreeNode(
      'end-instructions',
      groupConstructionPhaseNode,
      new WswInstructionsData(groupPhase.endInstructions, constructedGroup),
    )
      .connectParent()
      .controlRequest(
        WswStereotypes.Confirm.defineTransition(undefined, () =>
          DefaultTransitionAction.next(WSWModifiers.default),
        ),
      )
      .transitionFrom(
        WSWModifiers.default,
        manipulationCheckPhase?.questionSetPhaseNode ?? feedbackScreenPhase,
      );
  }
  return groupConstructionPhaseNode;
}
