import React, { FC } from 'react';
import { Card, CardHeader, Grid, Link, Typography } from '@mui/material';
import { TestsMetaData, TestType } from '../subject/types';
import { Link as RouterLink } from 'react-router-dom';
import { RequireAuth } from '../../auth/RequiredAuth';

interface TestMenuCardProps {
  type: TestType;
}

const TestMenuCard: FC<TestMenuCardProps> = ({ type }) => {
  return (
    <Card>
      <CardHeader
        title={
          <Link
            variant="h5"
            color="primary"
            component={RouterLink}
            to={`${TestsMetaData[type].identifier}`}
          >
            {TestsMetaData[type].shortName}
          </Link>
        }
        subheader={TestsMetaData[type].longName}
      />
    </Card>
  );
};

export const TestsMenu: FC = () => {
  return (
    <RequireAuth>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="textSecondary" variant="h6">
            Indirect measures:
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TestMenuCard type={TestType.IAT} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TestMenuCard type={TestType.AMP} />
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography color="textSecondary" variant="h6">
            Shooter task:
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TestMenuCard type={TestType.PODT} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TestMenuCard type={TestType.PODTA} />
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography color="textSecondary" variant="h6">
            Memory Tests:
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TestMenuCard type={TestType.WSW} />
        </Grid>
      </Grid>
    </RequireAuth>
  );
};
