import React, { FC } from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useCreateSurveyProviderMutation } from './surveyProviderApi';
import { UpsertSurveyProviderDialog } from './UpsertSurveyProviderDialog';

export const CreateSurveyProviderDialog: FC<{ onSuccess: () => void }> = ({
  onSuccess,
}) => {
  const [createSurveyProvider, { isLoading }] =
    useCreateSurveyProviderMutation();
  return (
    <UpsertSurveyProviderDialog
      button={(onClick) => (
        <Button
          size="small"
          startIcon={<Add />}
          variant="contained"
          onClick={onClick}
        >
          Create Survey Provider
        </Button>
      )}
      title="Create Survey Provider"
      actionLabel="Create Survey Provider"
      isLoading={isLoading}
      onSave={(input) =>
        createSurveyProvider({
          input,
        })
          .unwrap()
          .then((res) => {
            onSuccess();
            return res;
          })
      }
    />
  );
};
