import React, { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material';
import {
  GetMediaItemStatusMutationVariables,
  MediaItemStatusResponse,
} from '../../API';
import { GQLQuery, NewGQL } from '../../GQL';
import { getMediaItemStatus } from '../../graphql/mutations';
import { MediaItem } from './types';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { Delete } from '@mui/icons-material';
import { DialogActions } from '../../components/dialog/DialogActions';
import TestStatus from '../tests/testAdmin/testDetails/TestStatus';
import { useDeleteMediaItemMutation } from './redux/mediaApi';

type DeleteMediaItemsDialogProps = {
  selectedMediaItem: MediaItem;
};

const getMediaItemStatusQuery = GQLQuery.Mutate<
  GetMediaItemStatusMutationVariables,
  MediaItemStatusResponse
>(getMediaItemStatus);
// const removeMediaItemQuery = GQLQuery.Mutate<
//   RemoveMediaItemMutationVariables,
//   string
// >(removeMediaItem);

export const DeleteMediaItemDialog: FC<DeleteMediaItemsDialogProps> = ({
  selectedMediaItem,
}) => {
  const [open, setOpen] = React.useState(false);

  const [deleteMediaItem, { isLoading: deleteLoading }] =
    useDeleteMediaItemMutation();

  const [testUsages, setTestUsages] = React.useState({
    loading: true,
    data: null as MediaItemStatusResponse | null,
  });

  const [confirmDelete, setConfirmDelete] = React.useState('');
  const confirmed = confirmDelete === 'permanently delete';

  React.useEffect(() => {
    if (open) {
      NewGQL.DEFAULT_CLIENT.execute(
        getMediaItemStatusQuery.create({ mediaItemId: selectedMediaItem.id }),
      ).then((res) => {
        setTestUsages({ loading: false, data: res });
      });
    }
  }, [open, selectedMediaItem]);

  const handleDeleteMediaItems = () =>
    deleteMediaItem({
      id: selectedMediaItem.id,
      fullSizeKey: selectedMediaItem.image.fullSize.key,
      thumbNailKey: selectedMediaItem.image.thumbNail.key,
    }).then(() => setOpen(false));

  return (
    <React.Fragment>
      <GridActionsCellItem
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
        showInMenu={false}
        placeholder=""
        label={'Delete'}
        icon={<Delete />}
        onClick={() => setOpen(true)}
      />
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg">
        <DialogTitle>Delete MediaItem</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ whiteSpace: 'pre-wrap' }}>
            {testUsages.loading
              ? 'Searching for usages of the Image'
              : `${
                  testUsages.data && testUsages.data.usingTests.length > 0
                    ? `The Image is used in the following tests.\n\n
                 Make sure you contact the test owners before deleting the image.\n
                 Tests with deleted Images will not load anymore.`
                    : 'No tests are using this image'
                }`}
            {(testUsages.loading || testUsages.data === null) && (
              <LinearProgress sx={{ my: 1 }} />
            )}
          </DialogContentText>

          {testUsages.data !== null && (
            <List>
              {testUsages.data.usingTests.map((usingTest) => {
                return (
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          Testname: {usingTest.testType} {usingTest.testName}
                          <TestStatus
                            value={usingTest.testStatus}
                            chipProps={{ size: 'small', sx: { ml: 1 } }}
                          />
                        </>
                      }
                      secondary={`Testowner: ${usingTest.testOwnerMail}`}
                    />
                  </ListItem>
                );
              })}
            </List>
          )}
          {testUsages.data && (
            <TextField
              fullWidth
              sx={{ minWidth: 350 }}
              placeholder={'Type "permanently delete" to confirm'}
              onChange={(e) => setConfirmDelete(e.target.value)}
            />
          )}
        </DialogContent>
        <DialogActions
          loading={testUsages.loading || deleteLoading}
          confirmDisabled={!confirmed}
          onConfirm={handleDeleteMediaItems}
          onCancel={() => setOpen(false)}
          confirmColor={'error'}
        />
      </Dialog>
    </React.Fragment>
  );
};
