import { PickingStrategy } from '../../../API';
import { PickingBehaviour, PickingBehaviours } from './picking-behaviours';
import { ValidConstraints } from './setConstraints/valid-constraints';
import { SetValidConstraint } from './setConstraints/SetValidConstraint';
import { PickingSet } from './PickingSet';
import { PlainPickingSet } from './PlainPickingSet/PlainPickingSet';

export const StrategyFactories: Record<
  PickingStrategy,
  [PickingBehaviour, SetValidConstraint]
> = {
  PERMUTATION: [PickingBehaviours.WithRefill, ValidConstraints.EqualsExactly],
  REFILLING_WITHOUT_REPLACEMENT: [
    PickingBehaviours.WithRefill,
    ValidConstraints.All,
  ],
  WITHOUT_REPLACEMENT: [
    PickingBehaviours.WithRefill,
    ValidConstraints.LessEquals,
  ],
  WITH_REPLACEMENT: [PickingBehaviours.WithReplace, ValidConstraints.All],
};

export function createPickingSet<T>(
  name: string,
  strategy: PickingStrategy,
  universe: T[],
): PickingSet<T> {
  return PlainPickingSet.Create(
    name,
    StrategyFactories[strategy][0],
    universe,
    StrategyFactories[strategy][1],
  );
}
