import React, { PropsWithChildren } from 'react';
import { ErrorMessage, useField } from 'formik';
import { Grid, Typography, Switch as MuiSwitch } from '@mui/material';
import { UiInput } from './types';

type ToggleProps<T> = UiInput<T | null> & {
  left: string;
  right: string;
  onToggleOn: () => T;
  hideError?: boolean;
};

function Toggle<T>(props: PropsWithChildren<ToggleProps<T>>): JSX.Element {
  // const { mode } = useContext(EditorContext);
  const { name, label, left, right } = props;

  const [, meta, helpers] = useField<T | null>(props.name);

  const handleChangeSwitch = (
    ev: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (checked) {
      helpers.setValue(props.onToggleOn());
    } else {
      helpers.setValue(null);
    }
  };

  return (
    <div>
      <Typography variant="caption" color="textSecondary">
        {label}
      </Typography>
      <Grid container spacing={1} sx={{ alignItems: 'center' }}>
        <Typography variant="body2" component={Grid} item>
          {left}
        </Typography>
        <Grid item>
          <MuiSwitch
            color="primary"
            checked={Boolean(meta.value)}
            onChange={handleChangeSwitch}
            name={name}
          />
        </Grid>
        <Typography variant="body2" component={Grid} item>
          {right}
        </Typography>
      </Grid>
      {!props.hideError && meta.error && (
        <Typography variant="caption" color="error">
          <ErrorMessage name={props.name} />
        </Typography>
      )}
    </div>
  );
}

export default Toggle;
