import { TestType } from '../../features/subject/types';

export const createLimeSurveyUrl = (
  type: TestType,
  id: string,
  preview: boolean,
  stepIndex?: number,
) => {
  let urlResult = `${window.location.origin}/run/${type}/${id}`;

  if (stepIndex !== undefined) {
    urlResult = urlResult + `?stepIndex=${stepIndex}`;
  }

  if (!preview) {
    urlResult =
      urlResult +
      `${
        stepIndex !== undefined ? '&' : '?'
      }surveyId={SID}&savedId={SAVEDID}&lang={LANG}`;
  } else {
    urlResult =
      urlResult + `${stepIndex !== undefined ? '&' : '?'}preview=true`;
  }
  return urlResult;
};

export const createUniParkUrl = (
  type: TestType,
  id: string,
  preview: boolean,
  stepIndex?: number,
) => {
  let urlResult = `${window.location.origin}/run/${type}/${id}`;

  if (stepIndex !== undefined) {
    urlResult = urlResult + `?stepIndex=${stepIndex}`;
  }

  if (!preview) {
    urlResult =
      urlResult +
      `${
        stepIndex !== undefined ? '&' : '?'
      }uniparkSurveyId=#syid#&uniparkLfdn=#lfdn#`;
  } else {
    urlResult =
      urlResult + `${stepIndex !== undefined ? '&' : '?'}preview=true`;
  }
  return urlResult;
};

export interface ImageTrackerCodeSnippetInfo {
  stepIndex: number;
  imageDimensions: { width: number; height: number };
  prompt?: string;
  promptSize: number;
  hint?: string | null;
  hintSize?: number | null;
}

export const createCodeSnippet = (
  type: TestType,
  url: string,
  info?: ImageTrackerCodeSnippetInfo,
) => {
  const fullScreenStyles =
    'position:fixed;top:0;left:0;width:100%;height:100%;border:none;background:white;z-index:10000';

  return `
    <iframe 
      allowfullscreen="true"
      id="superFrame" 
      name="superFrame"
      onerror="alert('Failed to load test, please check you browser settings')"
      src="${window.location.origin}${url}" 
      style="${fullScreenStyles}"
    > 
    </iframe>
    <script>
        window.onload = function() {
        window.addEventListener("message", (e)=>{
            if (typeof e.data==="object" && e.data.type==="finish") {
                document.getElementById("superFrame").style.display="none";
                document.querySelector("body").style.overflow='auto';
            }
        });
        setTimeout(() => {
          document.querySelector("body").style.overflow='hidden';
          window.frames.superFrame.postMessage("ci", "*");
        }, 500);
    }
    </script>`;
};
