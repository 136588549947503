import { FC } from 'react';
import { UserRole } from '../../auth/user-role';
import { Chip, ChipProps } from '@mui/material';

const userRoleChipMap: Record<
  UserRole,
  { label: string; color: ChipProps['color'] }
> = {
  admin: { label: 'Admin', color: 'primary' },
  intern: { label: 'Intern', color: 'secondary' },
  extern: { label: 'Extern', color: 'secondary' },
};

export const UserRoleChip: FC<{ role: UserRole; size?: ChipProps['size'] }> = ({
  role,
  size,
}) => {
  const { label, color } = userRoleChipMap[role];
  return <Chip label={label} color={color} size={size} />;
};
