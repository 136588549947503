import { Iat, IatResult } from '../types';
import GQL from '../../../../GQL';
import {
  CreateIatTestInput,
  CreateIatTestMutationVariables,
  DeleteIatTestMutationVariables,
  DeleteTestMutationVariables,
  FunctionBodyResponse,
  GetIatTestQueryVariables,
  IatResultsByTestIdQueryVariables,
  TestStatus,
  UpdateIatTestInput,
  UpdateIatTestMutationVariables,
} from '../../../../API';
import {
  getIatTest,
  iatResultsByTestId,
  listIatTests,
} from '../../../../graphql/queries';
import {
  createIatTest as createIatTestMutation,
  deleteIatResult,
  deleteTest,
  updateIatTest as updateIatTestMutation,
} from '../../../../graphql/mutations';
import {
  createAsyncAppThunk,
  TestResultsThunkOutput,
} from '../../../../app/redux/thunk';

export const fetchIatTest = createAsyncAppThunk<Iat, string>('iat/get', (id) =>
  // Todo neu_manu -> war vorher NO_AUTH
  GQL.get<GetIatTestQueryVariables, Iat>(getIatTest, { id }),
);

export const fetchIatTests = createAsyncAppThunk('iat/list', () =>
  GQL.list<Iat>(listIatTests),
);

export const fetchIatResults = createAsyncAppThunk<
  TestResultsThunkOutput<IatResult>,
  { testId: string }
>('iat/results', async ({ testId }) => {
  const results = await GQL.list<IatResult, IatResultsByTestIdQueryVariables>(
    iatResultsByTestId,
    { testId },
  );
  return { testId, results };
});

export const updateIatAsync = createAsyncAppThunk<Iat, UpdateIatTestInput>(
  'iat/update',
  (input) =>
    GQL.mutate<Iat, UpdateIatTestMutationVariables>(updateIatTestMutation, {
      input,
    }),
);

export const updateIatStatusAsync = createAsyncAppThunk<
  Iat,
  { testId: string; status: TestStatus }
>('iat/update/status', ({ status, testId }) =>
  GQL.mutate<Iat, UpdateIatTestMutationVariables>(updateIatTestMutation, {
    input: { id: testId, status },
  }),
);

export const createIatAsync = createAsyncAppThunk<Iat, CreateIatTestInput>(
  'iat/create',
  (input) => {
    return GQL.mutate<Iat, CreateIatTestMutationVariables>(
      createIatTestMutation,
      {
        input,
      },
    );
  },
);

export const deleteIatResultsAsync = createAsyncAppThunk<
  { testId: string; resultsIds: string[] },
  { testId: string; resultsIds: string[] }
>('iat/delete/results', async (arg) => {
  await Promise.all(
    arg.resultsIds.map((id) =>
      GQL.mutate<IatResult, DeleteIatTestMutationVariables>(deleteIatResult, {
        input: { id },
      }),
    ),
  );
  return arg;
});

export const deleteIatAsync = createAsyncAppThunk<string, string>(
  'iat/delete',
  async (testId) => {
    await GQL.mutate<FunctionBodyResponse, DeleteTestMutationVariables>(
      deleteTest,
      {
        testId,
        testType: 'IAT',
      },
    );
    return testId;
  },
);
