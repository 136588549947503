import { TransitionModifier } from '../../graph-state/transition-modifiers';
import { ScreenTreeNode } from './screen-tree-node';
import { PhaseTreeNode } from './phase-tree-node';
import { MapTreeNode } from './map-tree-node';
import { ConstructionDebugger } from '../../debug/construction-debugger';

export class MapPhaseTreeNode extends MapTreeNode implements PhaseTreeNode {
  readonly type = 'phase';
  children: (PhaseTreeNode | ScreenTreeNode)[] = [];
  startMap: Map<TransitionModifier, PhaseTreeNode | ScreenTreeNode> = new Map<
    TransitionModifier,
    PhaseTreeNode | ScreenTreeNode
  >();

  addChild(
    node: PhaseTreeNode | ScreenTreeNode,
    startModifier?: TransitionModifier,
  ): MapPhaseTreeNode {
    this.children.push(node);
    if (startModifier) {
      if (this.startMap.has(startModifier)) {
        ConstructionDebugger.logDebugInformation(
          'warn',
          'Tree Validation Warning',
          `Added child node ${node.pathName} to ${
            this.pathName
          } as start-node with modifier ${
            startModifier.code
          }, but another node ${
            this.startMap.get(startModifier)!.pathName
          } has already been added as start-node for this modifier`,
        );
      }
      this.startMap.set(startModifier, node);
    }
    return this;
  }

  screen(modifier: TransitionModifier): [PhaseTreeNode[], ScreenTreeNode] {
    const directInner = this.startMap.has(modifier)
      ? this.startMap.get(modifier)!
      : [...this.startMap.entries()].find(([k]) => k.code === undefined)![1];
    if (directInner.type === 'phase') {
      const nestedInner = directInner.screen(modifier);
      return [[this, ...nestedInner[0]], nestedInner[1]];
    } else {
      return [[this], directInner];
    }
  }
}
