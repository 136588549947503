import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  Tooltip,
} from '@mui/material';
import React, { FunctionComponent } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    boxShadow:
      '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
  },
}));

type StyledIconButtonProps = MuiIconButtonProps & {
  label: string;
  onClick: (e?: any) => void;
  withShadow?: boolean;
};

const StyledIconButton: FunctionComponent<StyledIconButtonProps> = (props) => {
  const classes = useStyles();
  const { label, children, withShadow } = props;
  return (
    <Tooltip aria-label={label} title={label}>
      <span>
        <MuiIconButton
          className={clsx(withShadow && classes.iconButton)}
          {..._.omit(props, 'withShadow')}
        >
          {children}
        </MuiIconButton>
      </span>
    </Tooltip>
  );
};

export default StyledIconButton;
