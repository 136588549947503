import * as Yup from 'yup';
import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { EditorStepTitle } from '../../../../../components/grid';
import StyledTextInput from '../../../../../components/input/StyledTextInput';
import StyledNumberInput from '../../../../../components/input/StyledNumberInput';
import StyledSwitch from '../../../../../components/input/StyledSwitch';
import { PodtTrialFeedbackInput } from '../../../../../API';
import ColorInput from '../../../../../components/input/ColorInput';
import Toggle from '../../../../../components/input/Toggle';
import { InitialPodtCreatorState } from '../initialState';
import { BasicGameSettingsSchema } from '../../../testAdmin/testEditor/utils';
import { Wysiwyg } from '../../../../../components/input/wysiwyg/Wysiwyg';
import { PodtFeedbackMessageInput } from './PodtFeedbackMessageInput';
import { useField } from 'formik';
import KeyboardButtonPicker from '../../../../../components/input/keyboardPicker/KeyboardButtonPicker';
import { PodtVariables } from '../../../text-replacer/textReplacer';
import { TestLanguagePicker } from '../../../../../components/input/TestLanguagePicker';

export const PodtGameSettingsSchema = Yup.object().shape({
  ...BasicGameSettingsSchema,
  endScreen: Yup.object({
    desktop: Yup.string().required('create a End Screen'),
  }),
});

const PodtGameSettings: FC<{ counterbalancing: boolean }> = ({
  counterbalancing,
}) => {
  const [, { value: trialFeedback }] = useField<
    PodtTrialFeedbackInput | undefined | null
  >('trialFeedback');
  const [, { value: bgColor }] = useField<string | undefined>(
    'style.backgroundColour',
  );

  return (
    <Box>
      <EditorStepTitle prefix={1} title="Test Settings" />
      <Stack spacing={3}>
        <Stack spacing={4}>
          <Stack spacing={3} direction="row">
            <Box flex={2}>
              <StyledTextInput
                name="name"
                label="Name"
                toolTip="Name of the Test."
              />
            </Box>
            <Box flex={2}>
              <StyledTextInput
                name="description"
                label="Description"
                multiline
                toolTip={
                  'Text will be shown only in MINDset, not visible for respondents.'
                }
              />
            </Box>
            <Box flex={1}>
              <TestLanguagePicker />
            </Box>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Wysiwyg
              name="instructions"
              label="Welcome Screen"
              variables={PodtVariables}
              toolTip={
                'Text will be shown as Welcome message on the first page when entering the test'
              }
            />
            <Wysiwyg
              name="endScreen"
              label="End Screen"
              variables={PodtVariables}
              toolTip={
                'Text will be shown at the end of the test (e.g., last screen before redirection to survey)'
              }
            />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={3}>
          <Box width="50%">
            <Stack spacing={4} direction="column">
              {counterbalancing && (
                <StyledSwitch
                  name="counterbalancing"
                  label="Counterbalancing"
                  left="Off"
                  right="On"
                  tooltip={
                    'Counterbalancing in this case switches the response targets for 50% of the respondents.'
                  }
                />
              )}
              <Stack direction="row" spacing={3}>
                <StyledNumberInput
                  name="style.maxWidth"
                  label="Width"
                  unit="%"
                />
                <ColorInput name="style.backgroundColour" label="Background" />
              </Stack>
            </Stack>
          </Box>
          <Box width="50%">
            <Stack spacing={4}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={'space-between'}
              >
                <KeyboardButtonPicker
                  name="keyboard.left"
                  label="Weapon Button"
                  description="Press a keyboard button to set the Weapon Button."
                />
                <KeyboardButtonPicker
                  name="keyboard.right"
                  label="Object Button"
                  description="Press a keyboard button to set the Object Button."
                />
                <KeyboardButtonPicker
                  name="keyboard.confirm"
                  label="Start Button"
                  description="Press a keyboard button to set the START BUTTON."
                />
              </Box>
            </Stack>
            <Box
              sx={{ pt: 4, display: 'flex', justifyContent: 'space-between' }}
            >
              <StyledTextInput
                fullWidth
                name={'startLabel'}
                label={'Start Label'}
              />
              <StyledTextInput
                fullWidth
                name={'nextLabel'}
                label={'Next Label'}
              />
              <StyledTextInput
                fullWidth
                name={'finishLabel'}
                label={'Finish Label'}
              />
            </Box>
          </Box>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Toggle<PodtTrialFeedbackInput>
              name="trialFeedback"
              label="Trial-Feedback"
              left="No"
              right="Yes"
              hideError
              onToggleOn={() =>
                InitialPodtCreatorState.trialFeedback as PodtTrialFeedbackInput
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary">Feedback Messages:</Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledTextInput
              disabled={!Boolean(trialFeedback)}
              fullWidth={false}
              InputProps={{ endAdornment: ' px' }}
              name="trialFeedback.pointsFontsize"
              label={!Boolean(trialFeedback) ? '' : 'Points Font Size'}
            />
          </Grid>
          <Grid item xs={3}>
            <PodtFeedbackMessageInput
              textValueDisabled={!Boolean(trialFeedback)}
              name="trialFeedback.hitText"
              label="Hit"
              bgcolor={bgColor}
            />
          </Grid>
          <Grid item xs={3}>
            <PodtFeedbackMessageInput
              textValueDisabled={!Boolean(trialFeedback)}
              name="trialFeedback.missText"
              label="Miss"
              bgcolor={bgColor}
            />
          </Grid>
          <Grid item xs={3}>
            <PodtFeedbackMessageInput
              textValueDisabled={!Boolean(trialFeedback)}
              name="trialFeedback.correctRejectionText"
              label="Correct Rejection"
              bgcolor={bgColor}
            />
          </Grid>
          <Grid item xs={3}>
            <PodtFeedbackMessageInput
              textValueDisabled={!Boolean(trialFeedback)}
              name="trialFeedback.falseAlarmText"
              label="False Alarm"
              bgcolor={bgColor}
            />
          </Grid>
          <Grid item xs={3}>
            <PodtFeedbackMessageInput
              textValueDisabled={!Boolean(trialFeedback)}
              name="trialFeedback.noResponseText"
              label="No Response"
              bgcolor={bgColor}
            />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default PodtGameSettings;
