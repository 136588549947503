import { TextField } from '@mui/material';

export function isValidSurveyIdInput(idInput: string) {
  return /^[0-9]+$/.test(idInput);
}

export function SurveyProviderStaticSurveyIdInput({
  currentId,
  setCurrentId,
}: {
  currentId: string;
  setCurrentId: (value: string) => void;
}) {
  const isValidId = isValidSurveyIdInput(currentId);
  return (
    <TextField
      error={!isValidId}
      helperText={isValidId ? undefined : 'Has to be a number'}
      value={currentId}
      onChange={(e) => setCurrentId(e.target.value)}
      label="Survey ID"
      required
    />
  );
}
