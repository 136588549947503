import { WswTestQuestionInstance } from './wsw-test-question-instance';
import { WswQuestionType } from '../../../../../../API';
import { MediaInstance } from '../../../../../subject/testRunner/media/MediaData';
import { QuestionLocator } from './question-locator';
import React from 'react';
import { Box, Button, ButtonGroup, Link, Typography } from '@mui/material';
import { QuestionAnswerListener } from './question-answer-listener';
import { WswStereotypes } from '../../wsw-controls';

export class WswTestScaleQuestionInstance extends WswTestQuestionInstance {
  readonly type: WswQuestionType.RatingScala = WswQuestionType.RatingScala;

  constructor(
    questionText: string,
    stimulus: MediaInstance | null,
    public readonly sides: Record<'left' | 'right', string>,
    public readonly values: { value: number; position: number }[],
    public readonly noAnswer: string | null,
    questionLocator: QuestionLocator,
    timeout: number,
  ) {
    super(
      WswQuestionType.RatingScala,
      questionText,
      stimulus,
      questionLocator,
      timeout,
    );
  }

  createComponent(): React.FC {
    return () => {
      return (
        <React.Fragment>
          <Box>
            <ButtonGroup
              variant="outlined"
              color="inherit"
              size="large"
              sx={{ mb: this.noAnswer ? 1 : undefined }}
            >
              {this.values.map((value, i) => (
                <Button
                  key={'scale-answer-' + i}
                  onClick={QuestionAnswerListener((e) => {
                    return WswStereotypes.Answer.Answer.controlOccurred(
                      e.map((c) => ({
                        event: (c as any).nativeEvent as MouseEvent,
                        answer: this.toAnswer(
                          value.value + '',
                          false,
                          value.position,
                        ),
                      })),
                    );
                  })}
                >
                  {value.value}
                </Button>
              ))}
            </ButtonGroup>
            <Box display="flex">
              <Typography sx={{ flex: 1 }}>{this.sides.left}</Typography>
              {this.noAnswer && (
                <Link
                  onClick={QuestionAnswerListener((e) => {
                    return WswStereotypes.Answer.Answer.controlOccurred(
                      e.map((c) => ({
                        event: (c as any).nativeEvent as MouseEvent,
                        answer: this.toAnswer(this.noAnswer!, true, -1),
                      })),
                    );
                  })}
                  sx={{
                    color: 'text.secondary',
                    cursor: 'pointer',
                    flex: 1,
                    textAlign: 'center',
                  }}
                >
                  {this.noAnswer}
                </Link>
              )}
              <Typography sx={{ flex: 1, textAlign: 'right' }}>
                {this.sides.right}
              </Typography>
            </Box>
          </Box>
        </React.Fragment>
      );
    };
  }
}
