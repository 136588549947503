import { API, Auth } from 'aws-amplify';
import { Severity } from '../API';
import GqlError from './errorHandeling/GqlError';
import { detect as detectBrowser } from 'detect-browser';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';

const isLocal = () => process.env.NODE_ENV === 'development';
export async function getGraphQLAuth() {
  try {
    await Auth.currentAuthenticatedUser();
    return GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS;
  } catch (e) {
    return GRAPHQL_AUTH_MODE.AWS_IAM;
  }
}
export const Log = {
  async createCloudWatchLog<E extends Error>(
    severity: Severity,
    name: string,
    message?: any,
    error?: E,
  ) {
    const browser = detectBrowser();
    const user = await Auth.currentUserInfo().catch(() => Promise.resolve());
    const authMode = await getGraphQLAuth();
    API.graphql({
      query: `mutation MindSetLoggingAdapter(
  $severity: Severity!
  $name: String!
  $message: String!
  $context: String
) {
  mindSetLoggingAdapter(
    severity: $severity
    name: $name
    message: $message
    context: $context
  ) {
    __typename
  }
}`,
      variables: {
        severity,
        name,
        message: `"url: ${window.location.href} ${
          window.location.search
        }", "browser_name: ${browser?.name} browser_version: ${browser?.version} browser_os: ${browser?.os} user_id: ${
          user?.username ?? 'none'
        } error_name: ${name} error_message: ${JSON.stringify(
          message,
        )} error_stack: ${error?.stack} agent: ${navigator.userAgent}`,
      },
      authMode,
    });
  },
  warn(name: string, message: string) {
    if (isLocal()) {
      console.warn(name, message);
    } else {
      this.createCloudWatchLog(Severity.warn, name, message);
    }
  },
  error<E extends Error>(name: string, error?: E) {
    if (isLocal()) {
      console.error(name, error);
    } else {
      this.createCloudWatchLog(Severity.error, name, {}, error as E);
    }
  },
  apiError(error: GqlError) {
    const name = error.query.split('(')[0];
    if (isLocal()) {
      console.error(name, error.variables, error);
    } else {
      this.createCloudWatchLog(Severity.error, name, error.variables, error);
    }
  },
  info(name: string, message: string) {
    if (isLocal()) {
      console.info(name, message);
    } else {
      this.createCloudWatchLog(Severity.info, name, message);
    }
  },
};

export default Log;
