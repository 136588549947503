import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import {
  AsyncAppThunk,
  RootState,
  useAppDispatch,
} from '../../../../../app/redux/store';
import { TestStatus } from '../../../../../API';
import { TestStep } from '../../testEditor/types';
import { useSelector } from 'react-redux';
import { BaseTest } from '../../../types';
import { TestType } from '../../../../subject/types';
import { validateAllSteps } from '../../testEditor/utils';
import { DialogActions } from '../../../../../components/dialog/DialogActions';
import { TestErrors } from './TestErrors';

interface PublishTestDialogProps<T> {
  id?: string | null;
  type: TestType;
  onClose: () => void;
  updateTestStatus: AsyncAppThunk<T, { testId: string; status: TestStatus }>;
  onPublished?: () => void;
  steps: TestStep[];
}

export function PublishTestDialog<T>(props: PublishTestDialogProps<T>) {
  const dispatch = useAppDispatch();

  const [errors, setErrors] = React.useState<boolean[]>([]);
  const hasErrors = errors.some((e) => e);
  const [loading, setLoading] = React.useState(true);

  const test = useSelector(
    (state: RootState) =>
      state[props.type].tests[props.id ?? ''] as BaseTest | undefined,
  );

  const [confirmation, setConfirmation] = React.useState('');
  const confirmed = confirmation === test?.name;

  const handleClose = () => {
    props.onClose();
    setErrors([]);
    setConfirmation('');
  };

  const handlePublishTest = () => {
    if (props.id) {
      dispatch(
        props.updateTestStatus({
          testId: props.id,
          status: TestStatus.PUBLISHED,
        }),
      )
        .then(() => {
          setLoading(false);
          handleClose();
          if (typeof props.onPublished === 'function') {
            props.onPublished();
          }
        })
        .catch(() => {});
    }
  };

  React.useEffect(() => {
    if (test !== undefined) {
      const validationErrorResult = validateAllSteps(
        test,
        props.steps.map((s) => s.editor),
      );
      setErrors(validationErrorResult);
      setLoading(false);
    }
  }, [props.steps, test]);

  if (!test) return null;

  return (
    <Dialog open={Boolean(props.id)} onClose={handleClose}>
      <DialogTitle>Publish Test</DialogTitle>
      <DialogContent>
        {hasErrors ? (
          <TestErrors
            testId={test.id}
            errors={errors}
            testType={props.type}
            steps={props.steps}
          />
        ) : (
          <React.Fragment>
            <DialogContentText>
              Do you want to publish the test <b>{test.name}</b> ?
              <br />
              After publishing, you can not change parameters of the Test!
            </DialogContentText>
            <br />
            <TextField
              fullWidth
              placeholder={'type the name of the test'}
              value={confirmation}
              onChange={(e) => setConfirmation(e.target.value)}
            />
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions
        loading={loading}
        onCancel={handleClose}
        onConfirm={handlePublishTest}
        confirmLabel="Publish"
        confirmDisabled={hasErrors || loading || !confirmed}
      />
    </Dialog>
  );
}
